const IconFileCode = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24}>
    <title>{"20DAD902-3625-4257-8FDA-0C0CB19E2A3D"}</title>
    <g fill="var(--color-action-default)" fillRule="evenodd">
      <path d="M13.724 14.053a.5.5 0 0 0-.671.224l-2 4a.5.5 0 0 0 .895.447l2-4a.5.5 0 0 0-.224-.671M9.854 14.146a.5.5 0 0 0-.707 0l-2 2a.5.5 0 0 0 0 .707l2 2a.498.498 0 0 0 .707 0 .5.5 0 0 0 0-.707l-1.646-1.645 1.646-1.648a.5.5 0 0 0 0-.707M15.146 14.146a.5.5 0 0 0 0 .707l1.646 1.648-1.646 1.645a.5.5 0 0 0 .707.707l2-2a.5.5 0 0 0 0-.707l-2-2a.5.5 0 0 0-.707 0" />
      <path d="M19 20.5c0 .275-.225.5-.5.5h-12a.5.5 0 0 1-.5-.5V11h6c.551 0 1-.449 1-1V7c0-.551-.449-1-1-1H6V3.5a.5.5 0 0 1 .5-.5H14v3.5c0 .827.673 1.5 1.5 1.5H19v12.5ZM4 10h8V7H4v3Zm14.586-3H15.5a.5.5 0 0 1-.5-.5V3.414L18.586 7Zm.975-.439-4.122-4.122A1.5 1.5 0 0 0 14.379 2H6.5A1.5 1.5 0 0 0 5 3.5V6H4c-.551 0-1 .449-1 1v3c0 .551.449 1 1 1h1v9.5A1.5 1.5 0 0 0 6.5 22h12a1.5 1.5 0 0 0 1.5-1.5V7.621a1.5 1.5 0 0 0-.439-1.06Z" />
    </g>
  </svg>
);

export default IconFileCode;