
import React from 'react'
import PropTypes from 'prop-types'
import intl from 'react-intl-universal'

const IncotermAlteradoPedido = ({
  incotermAnterior,
  incoterm
}) => (
  <div>    
    <div className="negotiation-read-offer">
      <div className="negotiation-read-column">
        <span>{intl.get('stocks.timeline.previousIncoterm')}</span>
        <span id="incotermAnterior">{incotermAnterior}</span>
      </div>
      <div className="negotiation-read-column">
        <span />
        <span />
      </div>
    </div>   
    <div className="negotiation-read-offer">
      <div className="negotiation-read-column">
        <span>{intl.get('stocks.timeline.currentIncoterm')}</span>
        <span id="incoterm">{incoterm}</span>
      </div>
      <div className="negotiation-read-column">
        <span />
        <span />
      </div>
    </div>   
  </div>
  );

IncotermAlteradoPedido.propTypes = {
  incotermAnterior: PropTypes.string.isRequired,
  incoterm: PropTypes.string.isRequired,  
}

export default IncotermAlteradoPedido