import styled from "styled-components";

export const ListHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ListItem = styled.div`
  display: grid;
  grid-template-columns: 5% 70% 25%;
  gap: 10px;
  padding: 20px 0px;
  width: 100%;
  justify-content: space-between;
  border-top: 1px solid var(--color-neutral-400, #ABB7CB);
  border-bottom: 1px solid var(--color-neutral-400, #ABB7CB);
  cursor: pointer;

  span{
    ${p => !p.isRead && 'font-weight: bold;'};
  }

  &:hover{
      background-color: var(--color-neutral-background);
  }
`;

export const ButtonInconDiv = styled.div`
  width: 64px;
  padding: 6px 8px;
`;

export const NewNotificationsNumber = styled.span`
  position: relative;
  background-color: ${p => p.hasNewNotification ? 'red' : 'initial'};
  color: white;
  border-radius: 5px;
  padding: 4px;
  display: flex;
  justify-content: center;
  right: 30px;
  top: -8px;
  font-size: 12px;
`;

export const Ball = styled.b`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${p => p.isRead ? 'var(--color-neutral-200)' : 'var(--color-brand-300)'};
  margin-right: 5px;
  margin-top: 5px;
`;

export const Description = styled.span`
  font-size: 14px;
  color: var(--color-neutral-600);
`;

export const DateItem = styled.span`
  font-size: 14px;
  color: var(--color-neutral-600);
`;