import PropTypes from "prop-types"
import intl from "react-intl-universal";
import * as S from "./styled";
import moment from "moment";
import { formatNumber } from "utils/format";

const formatNumberOptions = {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
};

export const MainInvoicesOrder = ({ data }) => (
  <S.WrapperMain>
    <header>
      <span>
        {intl.get("requestOrders.codPortalRequest")}:{" "}
        {data.Orders.map((x) => x.Id).join(", ")}
      </span>
      <span>
        {intl.get("commons.plant")}
        {": "}
        {data.Plant}
      </span>
      <span>
        {intl.get("commons.contractNumber")}
        {": "}
        {data.ContractNumber}
      </span>
    </header>
    <div>
      <table>
        <tr>
          <th>{intl.get("commons.item")}</th>
          <th>{intl.get("commons.materialCode")}</th>
          <th>{intl.get("stocks.qty")}</th>
          <th>{intl.get("master-data.general-configuration.um")}</th>
          <th>{intl.get("commons.description")}</th>
          <th>{intl.get("commons.documentNumber")}</th>
          <th>{intl.get("commons.deliveryDate")}</th>
          <th>{intl.get("commons.unitPrice")}</th>
          <th>{intl.get("commons.total")}</th>
        </tr>
        {data.Orders.map((x) => (
          <tr>
            <td>{x.SapOrderCodeItem}</td>
            <td>{x.MaterialCode}</td>
            <td>{formatNumber(x.Quantity, formatNumberOptions)}</td>
            <td>{x.Um}</td>
            <td>{x.MaterialDescription}</td>
            <td>{x.InvoiceNumber}</td>
            <td>{moment(x.DeliveryDate).format("L")}</td>
            <td>{formatNumber(x.UnitPrice, formatNumberOptions)}</td>
            <td>{formatNumber(x.Total, formatNumberOptions)}</td>
          </tr>
        ))}
      </table>
    </div>
    <footer>
      {intl.get("commons.total")}{" "}
      {formatNumber(
        data.Orders
          .map((x) => x.Total)
          .reduce((accumulator, value) => accumulator + parseFloat(value), 0),
        formatNumberOptions
      )}{" "}
      {data.Currency}
    </footer>
  </S.WrapperMain>
);

MainInvoicesOrder.propTypes = {
  data: PropTypes.shape({
    ContractNumber: PropTypes.any,
    Currency: PropTypes.any,
    Orders: PropTypes.shape({
      map: PropTypes.func
    }),
    Plant: PropTypes.any
  }).isRequired
}
