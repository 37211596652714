import Fetch from "utils/fetch";

export const ObterUnidadeNegocioComGarrafa = () => {
  return Fetch.get(`unidadeNegocio/obterUnidadeNegocioComGarrafa`).then(({ data }) => data)
}

export const ObterTodasComGarrafa = () => {
  return Fetch.get(`/labeled-families:all-with-bottles`).then(({ data }) => data)
}

