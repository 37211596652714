import React from 'react'
import MenuMui from "@material-ui/core/Menu";
import { withStyles } from '@material-ui/core';

export const Menu = withStyles({
  paper: {
    border: "var(--color-brand-300)",
    backgroundColor: "var(--color-brand-600)",
    marginTop: '28px'
  },
})((props) => (
  <MenuMui
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    {...props}
  />
));
