export enum NotificationAction {
  NEW_CONTRACT = 1,
  NEW_LOAD_COMPOSITION = 2,
  UPDATED_LOAD_COMPOSITION = 3,
  CANCELED_ORDER = 4
}

export enum NotificationStatus {
  Read = 1,
  Unread = 2
}

export enum NotificationCriticality {
  INFORMATION = 1,
  OK = 2,
  WARNING = 3,
  URGENT = 4
}

export enum NotificationType {
  ORDER = 1,
  MATERIAL = 2,
  UPLOAD = 3,
  BUSINESS_UNIT = 4,
  CONTRACT = 5,
  LOAD_COMPOSITION = 6
}