import Fetch from 'utils/fetch';
import { generateQueryParams } from "utils/custom-functions";
import { formatCompositionByOrderData, formatCompositionData } from './utils';

export const consultarConfiguracaoGeral = (parametro) => {
  return Fetch.get(`/configuracaogeral/consultar/${parametro}`).then(({ data }) => data)
}

export const buscarUnidades = (orderStatus, orderTypes) => {
  const _params = {
    orderStatus: orderStatus || [],
    orderTypes: orderTypes || []
  };
  
  return Fetch.get(`/pedido:business-units?${generateQueryParams(_params)}`).then(({ data }) => data)
}

export const buscarFornecedores = (idUser, idUnidadeNegocio, modo) => {
  return Fetch.get(`/suppliers:by-user-and-businessunit?UserId=${idUser}&BusinessUnitId=${idUnidadeNegocio}&ReadMode=${modo}`).then(({ data }) => data)
}

export const buscarComposicaoPorPedido = (idPedido) => {
  return Fetch.get(`/composicaocarga/porpedido/${idPedido}`).then(({ data }) => formatCompositionByOrderData(data))
}

export const searchComposition = (id) => {
  return Fetch.get(`/load-compositions/${id}`).then(({ data }) => formatCompositionData(data))
}

export const generateGRN = (loadCompositionId, model) => (
  Fetch.post(`/load-compositions/${loadCompositionId}:create-goods-receipt-note`, model).then(({ data }) => data)
)
