import { DELIVERY_OPTION } from ".."
import intl from 'react-intl-universal'

export const validate = (pedidos, note) => {
  let validated = {
    isValid: true,
    message: ''
  }

  if (pedidos.some(x => !x.deliveryOption)) {
    validated = {
      isValid: false,
      message: intl.get("composition.grn.validates.deliveryOptionRequired")
    }
  } else if (!note) {
    validated = {
      isValid: false,
      message: intl.get("composition.grn.validates.deliveryNoteRequired")
    }
  } else if (pedidos.some(x => x.deliveryOption === DELIVERY_OPTION.PartialDelivery && !x.quantidadeGRN)) {
    validated = {
      isValid: false,
      message: intl.get("composition.grn.validates.quantityRequired")
    }
  }
  
  return validated;
}
