import styled from "styled-components"
import HeaderDefault from 'components/header'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
export const Header = styled(HeaderDefault)`
`
