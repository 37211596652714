import { DayOfWeek } from "enum";
import { SchedulingAutoPilotDto } from "./scheduling-auto-pilot-dto"
import { TypeOfAutoPilotScheduling } from "enum/labeled-family-center/auto-pilot";

export interface SchedulingAutoPilotGeneralDto {
  IdLabeledFamilyCenter: number,
  IdBusinessUnit: number,
  BusinessUnitDescription: string,
  IdBusinessUnitDoor: number | null,
  AutoPilot: boolean,
  TypeOfAutoPilotScheduling: TypeOfAutoPilotScheduling,
  AutomaticDeliveryAdjustment: boolean,
  SchedulingAutoPilots: SchedulingAutoPilotDto[],
  OverStockManagerIsActive: boolean,
  OverStockManagerDaysToSkip: number,
  DaysOfWeekEnable: boolean[],
  DaysOfWeekEnableDictionary: Map<DayOfWeek, boolean>,
  NumberDaysAdvanceForSuggestionGeneration: number,
  IsHourlyPlan: boolean,
  TruckSize: number,
  LabeledFamilyDescription: string,
}

export function emptySchedulingAutoPilotGeneralDto(): SchedulingAutoPilotGeneralDto {
  return {
    IdLabeledFamilyCenter: 0,
    IdBusinessUnit: 0,
    BusinessUnitDescription: '',
    IdBusinessUnitDoor: 0,
    AutoPilot: false,
    TypeOfAutoPilotScheduling: TypeOfAutoPilotScheduling.Custom,
    AutomaticDeliveryAdjustment: false,
    SchedulingAutoPilots: [],
    OverStockManagerIsActive: false,
    OverStockManagerDaysToSkip: 0,
    DaysOfWeekEnable: [],
    DaysOfWeekEnableDictionary: new Map(),
    NumberDaysAdvanceForSuggestionGeneration: 0,
    IsHourlyPlan: false,
    TruckSize: 0,
    LabeledFamilyDescription: '',
  }
}