import intl from 'react-intl-universal'
import {
  ClbTableTHeadCell,
  ClbText,
} from '@celebration/design-system-react'

export const ActionsTableHead = () => (
  <div className='border-left'>
    <ClbTableTHeadCell className='action-head'>
      <ClbText slot="content">{intl.get('commons.action')}</ClbText>
    </ClbTableTHeadCell>
  </div>
)