import { ConsolidatedStocksFilter } from "../consolidated-stocks-filter"
import intl from 'react-intl-universal'
import Header from "components/header"
import { ConsolidatedStocksPagination } from "../consolidated-stocks-pagination"
import { ConsolidatedStocksTable } from "../consolidated-stocks-table"
import * as S from "./styled"

export const ConsolidatedStocks = () => (
  <>
    <Header title={intl.get('commons.consolidatedStocks')}></Header>
    <S.Wrapper>
      <ConsolidatedStocksFilter />
      <ConsolidatedStocksTable />
      <ConsolidatedStocksPagination />
    </S.Wrapper>
  </>
)
