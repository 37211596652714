import React, { Component } from 'react'
import VisaoTarefasMesterCard from './mester'
import PropTypes from 'prop-types'

class VisaoTarefasCard extends Component {
  render() {
    return (
      <div style={{ height: 'calc(100% - 5px)' }}>
        <VisaoTarefasMesterCard
          dataCard={this.props.dataCard}
          refreshCard={this.props.refreshCard}
          redirect={this.props.redirect}
        />
      </div>
    )
  }
}

VisaoTarefasCard.propTypes = { 
  dataCard: PropTypes.object,
  refreshCard: PropTypes.func,
  redirect: PropTypes.func,
}

export default VisaoTarefasCard
