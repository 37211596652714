import Fetch from "utils/fetch";
import { generateQueryParams } from "utils/custom-functions";

export const searchWallets = () => {
    return Fetch.get(`/material-groups:all`).then(({ data }) => data)
}

export const searchFamilies = (value) => {
    return Fetch.get(`/families:by-material-group?MaterialGroupId=${value}`).then(({ data }) => data)
}

export const searchLabeledFamilies = (value) => {
    return Fetch.get(`/labeled-families:by-family?${generateQueryParams({ familyId: value })}`).then(({ data }) => data)
}

export const searchCenters = (value) => {
    return Fetch.get(`/unidadeNegocio/obterTodasAsUnidadesPorFamilia/${value}`).then(({ data }) => data)
}

export const searchCoordination = () => {
    return Fetch.get(`/coordenacao`).then(({ data }) => data)
}

export const searchPeriodicidadeList = () => {
    return Fetch.get(`/labeled-families:periodicities`).then(({ data }) => data)
}

export const searchTipoAlgoritmoList = () => {
    return Fetch.get(`/labeled-family-centers:stock-policy-types`).then(({ data }) => data)
}

export const searchPeriodicidade = (id) => {
    return Fetch.get(`/labeled-families/${id}:periodicity`).then(({ data }) => data)
}

export const searchSaneamentoVolumesPendentes = (id) => {
    return Fetch.get(`/labeled-families/${id}:pending-volumes-sanitation`).then(({ data }) => data)
}

export const searchPilotoAutomatico = (id) => {
    return Fetch.get(`/labeled-families/${id}:auto-pilot`).then(({ data }) => data)
}

export const searchAcionamentoTransporteCIF = (id) => {
    return Fetch.get(`/labeled-families/${id}:cif-transportation`).then(({ data }) => data)
}

export const buscarFamiliaRotuladaCentro = (id) => {
    return Fetch.get(`/labeled-family-centers/${id}`).then(({ data }) => data)
}