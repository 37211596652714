import "moment/locale/pt-br";

const momentPtBr = {
  parentLocale: "pt-br",
  longDateFormat: {
    "[DM]": "DD/MM",
    "[DDMMYY]": "DD/MM/YY"
  }
};

export default momentPtBr;
