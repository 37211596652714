import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Fetch from 'utils/fetch'
import { Row, Col } from 'react-flexbox-grid'
import { SelectBox, Button} from "components/uikit-adapter/index"
import StyledFilterGrupoFornecedor from './StyledFilterGrupoFornecedor'
import intl from 'react-intl-universal'
import { formatErrorMessage } from 'utils/handle-error';

class FiltroGrupoFornecedor extends Component {
    constructor(props) {
        super(props)

        this.state = {
            IdUsuario: '',
            usuarios: [],
            showFilter: props.showFilter,
            hidden: true,
            isFetching: false
        }

        this.startFetching = this.startFetching.bind(this)
        this.stopFetching = this.stopFetching.bind(this)
        this.searchUsers = this.searchUsers.bind(this)
        this.processUsersData = this.processUsersData.bind(this)
        this.handleCloseSearch = this.handleCloseSearch.bind(this)
        this.handleCleanClick = this.handleCleanClick.bind(this)
        this.changeValue = this.changeValue.bind(this)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const hidden = !nextProps.showFilter

        this.setState({
            showFilter: nextProps.showFilter,
            hidden,
        })

        if (this.state.initialData !== nextProps.initialData) {
            this.setState({
                initialData: nextProps.initialData,
                IdUsuario: nextProps.initialData?.IdUsuario,
            }, () => {
                this.searchUsers()
            })
        }
    }

    startFetching() {
        this.setState({
            isFetching: true
        })
    }

    stopFetching() {
        this.setState({
            isFetching: false
        })
    }

    proccessErro(exception) {
        this.setState({
            showMsg: true,
            titleMsg: intl.get('stocks.timeline.feedback.errorFound'),
            textMsg: (exception.response && exception.response.status === 400)
                ? formatErrorMessage(exception)
                : intl.get('stocks.timeline.feedback.serverErrorOrder'),
            handleClose: () => {
                this.setState({ showMsg: false })
            }
        })
    }

    changeValue = (name, value) => {
        this.setState({
            [name]: value,
        })
    }

    handleCleanClick = () => {
        const params = {
            IdUsuario: null,
        }
        this.setState(params, () => this.props.handleClose(params))
    }

    searchUsers() {
        this.startFetching()

        Fetch.get(`/usuario/buscaUsuariosFornecedor`)
            .then((response) => this.processUsersData(response.data))
            .then(this.stopFetching())
            .catch((e) => this.proccessErro(e))
    }

    processUsersData(data) {
        this.setState({
            usuarios: data
        })
    }

    handleCloseSearch() {
        const params = {
            IdUsuario: this.state.IdUsuario,
        }
        this.props.handleClose(params)
    }

    render() {
        const { usuarios } = this.state

        return (
            <StyledFilterGrupoFornecedor>
                <div className="filter-container">
                    <div className="sideFilterFields">
                        <Row>
                            <Col xs={12} lg={12} md={12}>
                                <SelectBox
                                    required
                                    name='grupoFornecedor'
                                    label={intl.get('master-data.general-configuration.userGroupSupplier')}
                                    placeholder={intl.get('master-data.general-configuration.userGroupSupplier')}
                                    valueKey='Id'
                                    labelKey='Nome'
                                    value={this.state.IdUsuario}
                                    options={usuarios}
                                    onChange={value => this.changeValue('IdUsuario', value === null ? null : value.Id)}
                                    searchable
                                    openOnFocus
                                    clearable
                                />
                            </Col>
                        </Row>
                    </div>

                    <Row className="footerFilterContainer no-margin">
                        <Col xs={12}>
                            <Row>
                                <Col xs={12} lg={12} md={12}>
                                    <hr className="filter-divisor" />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={6} lg={6} md={6}>
                                    <Button type="primary" onClick={this.handleCloseSearch} value={intl.get('filters.search')} />
                                </Col>
                                <Col xs={6} lg={6} md={6}>
                                    <Button type="secondary" onClick={this.handleCleanClick} value={intl.get('filters.cleanSearch')} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </StyledFilterGrupoFornecedor>
        )
    }
}

FiltroGrupoFornecedor.propTypes = {
    showFilter: PropTypes.bool,
    initialData: PropTypes.object,
    transitionTime: PropTypes.number,
    handleClose: PropTypes.func
}

FiltroGrupoFornecedor.defaultProps = {
    showFilter: false,
    transitionTime: 500,
    initialData: {}
}

export default FiltroGrupoFornecedor
