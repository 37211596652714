import PropTypes from "prop-types"
import React from 'react'
import intl from 'react-intl-universal'
import ButtonOrderAccept from 'components/button-accept-order'
import { useOrdersContext } from '../../context'
import { postAcceptOrders } from 'components/cards-obsolete/orders-vision.service'
import Session from 'utils/user-storage'
import { useToastContext } from 'hooks/useToastContext'

const OrdersResend = ({ refresh }) => {
  const {
    items,
    checkbox,
    setCheckbox,
    setLoading,
  } = useOrdersContext();

  const { openToast } = useToastContext();
  const acceptableItems =
    items.filter(item => item.CanAcceptRange && checkbox.includes(item.Id))
      .map(item => item.Id)

  const disabled = acceptableItems.length > 0

  const resendOrder = async () => {
    setLoading(true)
    try {
      const orders = {
        Pedidos: acceptableItems,
        PedidosVolume: []
      }

      await postAcceptOrders(Session.get().Id, orders)
      openToast(intl.get('orders.ordersSendingBySAP'));
      setCheckbox([])
      refresh();
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  return (
    <ButtonOrderAccept
      title={intl.get("orders.resendSAP")}
      disabled={!disabled}
      accept={resendOrder}
    />
  )
}

OrdersResend.propTypes = {
  refresh: PropTypes.func.isRequired
}

export default OrdersResend
