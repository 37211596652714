import { getHostNPS } from "../../../utils/config-environment";

const baseApi = getHostNPS();
const _autorization = "f74cfbee-ca49-48d0-90fc-3d57591d517d";
const _headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  Authorization: _autorization
};

export const checkNps = async (user, systemId, acceptLanguage) => {
  const response = await fetch(
    `${baseApi}question/check?user=${user}&systemId=${systemId}`,
    {
      headers: {
        ..._headers,
        "Accept-Language": acceptLanguage
      },
    }
  );

  if (response.status === 200)
    return await response.json();

  else
    return "";
};

export const checkFeedback = async (id, checked, useful, acceptLanguage) => {
  return await fetch(
    `${baseApi}survey/checkfeedback?id=${id}&checkFeedback=${checked}&useFulFeedback=${useful}
    `,
    {
      headers: {
        ..._headers,
        "Accept-Language": acceptLanguage
      },
      method: "PATCH",
    }
  );
};

export const saveNpsVote = async (data, acceptLanguage) => {
  const _data = JSON.stringify(data);
  return await fetch(`${baseApi}survey/create`, {
    headers: {
      ..._headers,
      "Accept-Language": acceptLanguage
    },
    method: "POST",
    body: _data
  });
};

export const getCategories = async (acceptLanguage) => {
  const response = await fetch(`${baseApi}surveycategory`, {
    headers: {
      ..._headers,
      "Accept-Language": acceptLanguage
    },
    method: "get",
  });

  return response.json();
};

export const getQuestionPeriod = async (acceptLanguage) => {
  const response = await fetch(`${baseApi}questionperiod`, {
    headers: {
      ..._headers,
      "Accept-Language": acceptLanguage
    },
    method: "get",
  });

  return response.json();
};

export const getFeedbacks = async (user, systemId, periodId, acceptLanguage) => {
  const response = await fetch(`${baseApi}/survey/feedback?user=${user}&systemId=${systemId}&periodId=${periodId}`, {
    headers: {
      ..._headers,
      "Accept-Language": acceptLanguage
    },
    method: "get",
  });

  return response;
};
