import React, { useEffect, useState } from 'react'
import intl from 'react-intl-universal'
import { BottlesSupplierTabs } from '../bottles-tabs/supplier'
import { FilterEditMode } from '../bottles-filter/editMode'
import { BottlesBreweryTabs } from '../bottles-tabs/brewery'
import { getBottlesPlan } from '../../services/bottles-plan.service'
import { useBottlesContext } from '../../context'
import { DetailMessage } from './styled'
import { dataMapper } from './utils'
import LoadingProgress from 'components/circular-progress'

export const EditScreen = () => {
  const {
    openFilter,
    setOpenFilter,
    filter,
    setData,
    setLastUpdated
  } = useBottlesContext()

  const [isLoading, setIsLoading] = useState(false)

  const bottlesPlan = async () => {
    setIsLoading(true)
    try {

      const response = await getBottlesPlan(filter)
      setData(dataMapper(response.Items))
      setLastUpdated(response.LastUpdated)
      setIsLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (filter.labeledFamilyId.length > 0) {
      bottlesPlan()
    }
  }, [filter])

  if (filter.labeledFamilyId.length === 0) {
    return <DetailMessage>{intl.get('bottles.detailMessageEditMode')}</DetailMessage>
  }

  return (
    isLoading ? <LoadingProgress />
      :
      <>
        <BottlesSupplierTabs />
        <BottlesBreweryTabs />
        <FilterEditMode
          open={openFilter}
          close={() => setOpenFilter(false)}
        />
      </>
  )
}
