import PropTypes from "prop-types"
import React from 'react'
import TableList from '../../../../components/table-list';
import { builderSuggestionList } from '../../utils';
import { Columns } from './columns';
import * as S from './styled'
import { useSuggestionContext } from 'pages/suggestions/context/suggestionsContext';

const ListSuggestion = ({ data }) => {
  const { suggestionsSelecteds, setSuggestionsSelecteds } = useSuggestionContext();
  
  const handleCheck = (checked, record) => {
    if (record) {
      if (!suggestionsSelecteds.some(item => item.Id === record.Id)) {
        setSuggestionsSelecteds(prevState => [...prevState, record])
      } else {
        const selecteds = suggestionsSelecteds.filter(item => item.Id !== record.Id)
        setSuggestionsSelecteds([...selecteds])
      }
    }

    if (checked && !record) {
      setSuggestionsSelecteds([...data])
    }

    if (!checked && !record) {
      setSuggestionsSelecteds([])
    }
  }

  return (
    <S.Wrapper>
      <TableList
        scope='Suggestions'
        columns={Columns()}
        data={data.map(x => builderSuggestionList(x))}
        onChange={handleCheck}
        selected={suggestionsSelecteds.map(x => x.Id)}
      />
    </S.Wrapper>
  )
}

ListSuggestion.propTypes = {
  data: PropTypes.shape({
    map: PropTypes.func
  }).isRequired
}

export default ListSuggestion;
