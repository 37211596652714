import styled from 'styled-components'
import * as Components from 'components/uikit-adapter/index'

export const Wrapper = styled.div`
  color: white;
  top: -3px;
  padding: 16px 72px 0px 45px;
`

export const TitlesWrapper = styled.div`
`

export const Title = styled.span`
  font-weight: var(--font-weight-semibold);
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
  color: var(--color-contrast-black);
`

export const ContentWrapper = styled.div`
  color: white;
  display: flex;
  position: sticky;
  z-index: 100;
  background: var(--color-neutral-background);
  height: 52px;
  padding: 4px 0px;
  span{
    cursor: auto;
  }
`

export const Subtitle = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: var(--color-contrast-black);
  margin-right: 16px;
  white-space: nowrap;
`

export const ButtonFocus = styled.div`
  .active {
    font-weight: var(--font-weight-semibold);
    font-size: 14px;
    line-height: 13px;
    color: var(--color-contrast-black);
    border-bottom: solid 2px var(--color-action-default);
    cursor: pointer;
  }

  .notActive{
    cursor: pointer;
  }
`

export const ButtonWrapper = styled.div`
  width: 200px;
  margin-left: auto;
`

export const Button = styled(Components.Button)`
  width: 200px;
  span{
    cursor: pointer;
  }

  ${p => p.hide && 'display: none;'}
`

export const ButtonFilter = styled(Components.Button)`
  width: 40px !important;
  margin-left: 12px;
`
export const SelectBox = styled(Components.SelectBox)`
  font-style: normal !important; 
  font-weight: var(--font-weight-semibold) !important; 
  font-size: 14px !important; 
  line-height: 14px !important; 
  color: var(--color-contrast-black);
`