import styled from 'styled-components'

const StyledHeaderIcon = styled.div`
  width: 100%;
  height: 75px;
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;

  .desc {
    display: inline-block;
    position: relative;
    bottom: 5px;
    left: 15px;
    width: 50%;
  }
  
  .title {
    color: #808080;
    font-size: 20px;
  }
  
  .subtitle {
    color: #b3b3b3;
    font-size: 14px;
  }

  .div-children {
    display: flex;
    align-content: right;
    width: 50%;
    justify-content: flex-end;
  }
`
export default StyledHeaderIcon
