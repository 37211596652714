import intl from 'react-intl-universal'
import { INotificationFilter } from './interface';
import { NotificationStatus } from 'enum/notifications';

export const initialFilterValues: INotificationFilter = {
  Unread: [],
  Criticalities: [],
  Types: [],
  CreatedDateMin: '',
  CreatedDateMax: '',
  _page: 0,
  _size: 50
}

export const getStatusNotificationOptions = () => [
  {
    Id: NotificationStatus.Read,
    Description: intl.get("notifications.read"),
  },
  {
    Id: NotificationStatus.Unread,
    Description: intl.get("notifications.unread"),
  }
];

export const normalizeUnreadValueByStatus = (statusList: Array<NotificationStatus>): boolean | null => {
  switch (true) {
    case (statusList.length === 2 || statusList.length === 0):
      return null;
    case statusList.includes(NotificationStatus.Read):
      return false;
    case statusList.includes(NotificationStatus.Unread):
      return true;
  }
  return null
}