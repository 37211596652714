import React from 'react'
import intl from 'react-intl-universal'
import MenuIcon from 'material-ui/svg-icons/navigation/menu'
import { useBottlesContext } from '../../context'
import * as S from './styled'

export const ConfigMode = () => {
  const { setOpenFilter } = useBottlesContext()

  return (
    <S.ConfigWrapper
      title={intl.get("bottles.configModeTooltip")}>
      <MenuIcon
        onClick={() => setOpenFilter(prevState => !prevState)}
        color="white"
      />
    </S.ConfigWrapper>
  )
}

