import intl from "react-intl-universal";
import { isUserSupplier } from "utils/user";
import { IObjectDefault, IProductionPlan, ISupplierProductionPlanFilters } from "./interface";
import { generateQueryParams } from "utils/custom-functions";

export const supplierProductionPlanFiltersDefault = {
  SupplierGroupId: null,
  Suppliers: null,
  Materials: null,
  BusinessUnits: null  
};

export const supplierProductionPlanSelectsFilters = () => ([
  {
    name: "SupplierGroupId",
    label: intl.get("commons.supplierGroup"),
    placeholder: intl.get("commons.supplierGroup"),
    valueKey: "Id",
    labelKey: "Description",
    searchable: true,
    clearable: true,
    disabled: isUserSupplier(),
    hidden: false
  },
  {
    name: "Suppliers",
    label: intl.get("bottles.suppliers"),
    placeholder: intl.get("bottles.suppliers"),
    valueKey: "Id",
    labelKey: "Description",
    searchable: true,
    clearable: true,
    multi: true,
    hidden: false
  },
  {
    name: "Materials",
    label: intl.get("commons.material"),
    placeholder: intl.get("commons.material"),
    valueKey: "Id",
    labelKey: "Description",
    searchable: true,
    clearable: true,
    multi: true,
    hidden: false
  },
  {
    name: "BusinessUnits",
    label: intl.get('supplierProductionPlan.table.plant'),
    placeholder: intl.get('supplierProductionPlan.table.plant'),
    valueKey: "Id",
    labelKey: "Description",
    searchable: true,
    clearable: true,
    multi: true,
    hidden: false
  }
]);

export const mountQuery = (obj: ISupplierProductionPlanFilters) => {
  const _query =  {
    "Filters.SupplierGroupId": obj.SupplierGroupId?.Id,
    "Filters.Suppliers": obj.Suppliers?.map(item => item.Id),
    "Filters.Materials": obj.Materials?.map(item => item.Id),
    "Filters.BusinessUnits": obj.BusinessUnits?.map(item => item.Id)  
  }
  
  return generateQueryParams(_query);
}

export const mapperProductionPlan = (data: IProductionPlan[]) =>
  data.map((element) => ({
    ...element,
    Items: element.Items.map((item) => ({
      ...item,
      ProductionQuantity: item.ProductionQuantity ?? 0,
      PendingQuantityPallets: item.PendingQuantityPallets ?? 0,
      PendingQuantity: item.PendingQuantity ?? 0,
    })),
  }));

export const validateFilters = (filters: Array<IObjectDefault> | null, options: Array<IObjectDefault> | []) => {
  if(!filters) return null;
  
  const _options = options?.map((option) => option.Id);
  const _result = filters.filter((filter) => _options.includes(filter.Id));
  return _result.length ? _result : null;
}
