import styled from 'styled-components'

export const StyledValuesOptimization = styled.div`
  width: 30px;
  display: flex;
  ${p => p.onClick && `
      cursor: pointer;
     
      &:hover {
        .etb-column,
        .etb-label {
          background-color: #f1f1f1;
        }
      }
    `};
`;
