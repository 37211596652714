import moment from 'moment';
import intl from 'react-intl-universal'
import { ISO_DATE_FORMAT } from './format-date';

export const mapperOptionsToSelectBoxClb = (data, key, label) => data.map(x => ({
  "key": x[key],
  "value": x[key],
  "label": x[label]
}));

export const getMonths = () =>
  [
    intl.get("months.january"),
    intl.get("months.february"),
    intl.get("months.march"),
    intl.get("months.april"),
    intl.get("months.may"),
    intl.get("months.june"),
    intl.get("months.july"),
    intl.get("months.august"),
    intl.get("months.september"),
    intl.get("months.october"),
    intl.get("months.november"),
    intl.get("months.december"),
  ]

export const normalizeValueDatePicker = (date) => {
  if (!date) {
    return '';
  }
  return moment(date, 'DD/MM/YYYY').format(ISO_DATE_FORMAT)
}