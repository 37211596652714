import PropTypes from "prop-types"
import React from 'react'
import AppRoutes from 'config/routes'
import { AppProviderKc } from 'context/appContextKc'
import { MuiThemeProvider } from 'material-ui/styles'
import { AuthProvider } from 'oidc-react'
import { getAuthorityKCThird, getAuthorityKCAmbev, getClientIdKc, getRedirectUri } from 'utils/config-environment'
import { ThemeProvider } from 'context/themeContext'

// Não tentar dinamizar métodos aqui, não vai funcionar!
const KcAuthProvider = ({ realm, url }) => {
  const oidcConfAmbev = {
    redirectUri: `${getRedirectUri()}/${url}`,
    clientId: getClientIdKc(),
    scopes: 'openid',
    authority: getAuthorityKCAmbev()
  }

  const oidcConfThird = {
    redirectUri: `${getRedirectUri()}/${url}`,
    clientId: getClientIdKc(),
    scopes: 'openid',
    authority: getAuthorityKCThird()
  }

  const returnChildren = () => (
    <ThemeProvider>
      <MuiThemeProvider>
        <AppProviderKc>
          <AppRoutes />
        </AppProviderKc>
      </MuiThemeProvider>
    </ThemeProvider>
  )

    const onSignInCallBack = () => {
      const path = window.location.href.split("?state")
      window.history.replaceState(null, null, path[0])
    }

  //Precisa armazenar o state realm, quando logar, se não
  //não será possível alterar entre realms
  const stateRealm = localStorage.getItem('realm')

  return (
    realm === 'Ambev' || stateRealm === 'Ambev' ?
      <AuthProvider
        {...oidcConfAmbev}
        onSignIn={onSignInCallBack}
      >
        {returnChildren()}
      </AuthProvider>
      :
      <AuthProvider
        {...oidcConfThird}
        onSignIn={onSignInCallBack}
      >
        {returnChildren()}
      </AuthProvider>
  )
}

KcAuthProvider.propTypes = {
  realm: PropTypes.string.isRequired,
  url: PropTypes.any.isRequired
}

export default KcAuthProvider