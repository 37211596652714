import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  padding-right: 0px;
  align-items: flex-end;
  gap: 13px;

  .select{
    width: 320px;
  }

  .btn{
    align-self: flex-start;
    margin-top: 30px;
  }

  .btn-build{
    margin-left: auto;
  }
`