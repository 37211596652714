import Fetch from 'utils/fetch';

export const saveSchedulingAutoPilot = (id, data) => {
  return Fetch.post(`labeled-family-centers/${id}:save-auto-pilot-schedule`, data)
}

export const getSchedulingAutoPilot = (id) => {
  return Fetch.get(`labeled-family-centers/${id}:auto-pilot-schedule`).then(({ data }) => data)
}

export const getWallets = async () => {
  const request = await Fetch.get(`/material-groups:all-with-materials`);
  return request.data;
}

export const getFamilies = async () => {
  const request = await Fetch.get(`/families:all-with-labeled-family-centers`);
  return request.data;
}

export const getLabeledFamilies = async () => {
  const request = await Fetch.get(`/labeled-families:all-with-labeled-family-centers`);
  return request.data;
}

export const getCenters = async () => {
  const request = await Fetch.get(`/unidadeNegocio/unidadegeral`);
  return request.data;
}

export const getCoordinations = async () => {
  const request = await Fetch.get(`/coordinations:all-with-labeled-family-centers`);
  return request.data;
}