import PropTypes from "prop-types"
import React from 'react'
import OkStatus from 'images/ok-status.svg'
import OverStatus from 'images/over-status.svg'
import OutStatus from 'images/out-status.svg'
import StyledCardHeader from '../styled-card-header'
import { CardContent } from '../card-content'

const updateStatusStock = (status) => {
  const event = {
    0: OkStatus,
    1: OverStatus,
    2: OutStatus,
  }
  return event[status]
}

const updateStatusPriority = (status) => {
  switch (status) {
    case 0:
      return {
        colorStatus: '#2DA78F',
        colorStatusArrow: '#2DA78F'
      }
    case 1:
      return {
        colorStatus: '#2C2C2C',
        colorStatusArrow: '#2C2C2C'
      }
    case 2:
      return {
        colorStatus: '#FF5E53',
        colorStatusArrow: '#FF5E53'
      }
    default:
      return {
        colorStatus: '#2870b2',
        colorStatusArrow: '#1e5281'
      }
  }
}

const HeaderCardStocks = ({
  showIcon,
  title,
  subtitle,
  status,
  customOptions,
  programado
}) => {

  const {
    colorStatus,
    colorStatusArrow,
  } = updateStatusPriority(status)
  
  const icon = updateStatusStock(status)

  return (
    <StyledCardHeader className="Card-header">
      <CardContent
        colorStatus={colorStatus}
        colorStatusArrow={colorStatusArrow}
        customOptions={customOptions}
        showIcon={showIcon}
        title={title}
        subtitle={subtitle}
        programado={programado}
        icon={icon}
      />
    </StyledCardHeader>
  )
}

HeaderCardStocks.propTypes = {
  customOptions: PropTypes.any.isRequired,
  programado: PropTypes.any.isRequired,
  showIcon: PropTypes.any.isRequired,
  status: PropTypes.any.isRequired,
  subtitle: PropTypes.any.isRequired,
  title: PropTypes.any.isRequired
}

export default HeaderCardStocks
