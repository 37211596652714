import PropTypes from "prop-types"
import { Component } from 'react'
import Fetch from 'utils/fetch'
import Dialog from 'material-ui/Dialog'
import Loading from 'components/center-loading'
import { Button, Input, SelectBox } from 'components/uikit-adapter/index'
import intl from 'react-intl-universal'
import StyledForm from './styled-form'
import { formatErrorMessage } from 'utils/handle-error';

class UnidadeArredondamentoForm extends Component {
  static propTypes = {
    editMode: PropTypes.any.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleFeedback: PropTypes.func.isRequired,
    handleRefresh: PropTypes.any.isRequired,
    idEdit: PropTypes.any.isRequired,
    open: PropTypes.any.isRequired,
    profileAdminOrMasterData: PropTypes.any.isRequired
  }

  constructor() {
    super()

    this.state = {
      UnidadeArredondamento: {},
      statusTypes: [],
      isSaving: false
    }
  }

  componentDidMount() {
    this.searchStatusTypes()

    const editMode = this.props.editMode
    if (editMode) {
      this.searchUnidadeArredondamentoById()
    }
  }

  startSaving = () => {
    this.setState({
      isSaving: true
    })
  }

  stopSaving = () => {
    this.setState({
      isSaving: false
    })
  }

  proccessErro = (exception) => {
    this.props.handleFeedback(formatErrorMessage(exception))
  }

  searchUnidadeArredondamentoById = () => {
    const idUnidadeArredondamento = this.props.idEdit
    Fetch.get(`/unidadeArredondamento/${idUnidadeArredondamento}`)
      .then((response) => this.proccessData(response.data))
      .catch((e) => this.proccessErro(e))
  }

  searchStatusTypes() {
    Fetch.get(`/unidadeArredondamento/listaStatusUnidadeArredondamento`)
      .then((response) => this.proccessDataStatusTypes(response.data))
      .catch((e) => this.proccessErro(e))
  }

  proccessDataStatusTypes(data) {
    this.setState({
      statusTypes: data
    })
  }

  proccessData = (data) => {
    this.setState({
      UnidadeArredondamento: {
        Id: data.Id,
        Descricao: data.Descricao,
        UM: data.UnidadeMedida,
        Status: data.Status,
        StatusEnumValue: data.StatusEnumValue
      }
    })
  }

  changeValue = (event) => {
    const prop = event.target.name
    let valueInitial = event.target.value
    if (prop === 'UM')
      valueInitial = event.target.value.toUpperCase()

    const value = valueInitial
    this.setState(prevState => ({
      UnidadeArredondamento: {
        ...prevState.UnidadeArredondamento,
        [prop]: value
      }
    }))
  }

  updateSelectValueStatus(prop, value) {
    this.setState(prevState => ({
      UnidadeArredondamento: {
        ...prevState.UnidadeArredondamento,
        [prop]: value
      }
    }))
  }

  save = () => {

    if (this.validarDescription(this.state.UnidadeArredondamento)) {
      this.startSaving()
      const editMode = this.props.editMode

      let model = this.state.UnidadeArredondamento

      model.Id = !!editMode ? this.props.idEdit : undefined
      model.Status = model.StatusEnumValue
      model.StatusEnumValue = this.state.UnidadeArredondamento.StatusEnumValue
      model.UnidadeMedida = model.UM

      Fetch.post(`/unidadeArredondamento`, model)
        .then(this.props.handleRefresh)
        .then(() => this.props.handleFeedback(`${intl.get('feedbacks.register')} ${!editMode ? intl.get('feedbacks.saved') : intl.get('feedbacks.updated')} ${intl.get('feedbacks.withSuccess')}`))
        .catch((e) => this.proccessErro(e))
        .finally(this.stopSaving)
    }
  }

  validarDescription(unidadeArredondamento) {
    if (!unidadeArredondamento.Descricao) {
      this.props.handleFeedback(intl.get('feedbacks.messageRoundingUnity1'))
      return false
    } else if (!unidadeArredondamento.UM) {
      this.props.handleFeedback(intl.get('feedbacks.messageRoundingUnity2'))
      return false
    } else if (unidadeArredondamento.StatusEnumValue !== 0 && unidadeArredondamento.StatusEnumValue !== 1) {
      this.props.handleFeedback(intl.get('feedbacks.messageRoundingUnity3'))
      return false
    }
    return true
  }

  render() {
    let { UnidadeArredondamento, isSaving, statusTypes } = this.state
    let { open, editMode, profileAdminOrMasterData } = this.props

    return (
      <div>
        <Loading isLoading={isSaving} />
        <Dialog
          title={editMode ? intl.get('master-data.configurations.roundedUnitys.actions.editRoundedUnity') : intl.get('master-data.configurations.roundedUnitys.actions.newRoundedUnity')}
          contentStyle={{ width: '600px', height: '700px' }}
          bodyClassName='modalBodyClass'
          open={open}
          autoScrollBodyContent
        >
          <StyledForm>
              <div>
                <Input
                  required
                  name='Descricao'
                  onChange={this.changeValue}
                  value={UnidadeArredondamento.Descricao}
                  label={intl.get('commons.description')}
                  disabled={!profileAdminOrMasterData}
                  className="input"
                />
              </div>
              <div className='label-input'>
                <Input
                  required
                  name='UM'
                  onChange={this.changeValue}
                  value={UnidadeArredondamento.UM}
                  label={intl.get('master-data.general-configuration.um')}
                  className="input"
                />
              </div>
              <div>
                <SelectBox
                  required
                  label='Status'
                  placeholder='Status'
                  name='Status'
                  value={UnidadeArredondamento.StatusEnumValue}
                  valueKey='Key'
                  labelKey='Value'
                  onChange={value => this.updateSelectValueStatus('StatusEnumValue', value.Key)}
                  openOnFocus={true}
                  options={statusTypes}
                  width="100%"
                />
              </div>
              <div className='footer-dialog-buttons-unidade-arredondamento'>
                <Button
                  value={intl.get('geral.buttonsDefault.cancel')}
                  type="default"
                  onClick={() => { this.props.handleClose() }}
                  className="button"
                />
                {profileAdminOrMasterData &&
                  <Button
                    value={intl.get('geral.buttonsDefault.save')}
                    type="primary"
                    className="button"
                    onClick={this.save}
                  />
                }
              </div>
          </StyledForm>
        </Dialog>
      </div>
    )
  }
}
export default UnidadeArredondamentoForm
