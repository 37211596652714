import React, { useState, useEffect } from 'react'
import { Button } from 'components/uikit-adapter/index'
import Loading from 'components/center-loading'
import SnackBar from 'material-ui/Snackbar'
import intl from 'react-intl-universal'
import { loadGeneralSettings, saveGeneralSettings } from './general-settings.service'
import { formatErrorMessage } from 'utils/handle-error'
import { updateGeneralSetting } from '../../../../services/general-settings/general-settings'
import { OptionsGeneralSettings, OptionsOrderSettings } from './models/generalSettingsModel'
import GeneralSettingItem from './components/generalSetting'
import * as S from './styled'
import { Row, Col } from "react-flexbox-grid";
import { getValueForCondition } from "utils/custom-functions";
import { GeneralSettingParameter } from 'models/generalSetting/generalSetting'
import IconTruckDarkBlue from 'components/icons/icn-truck-dark-blue.icon'
import IconPaperSheet from 'components/icons/icn-paper-sheet.icon'

const GeneralSettings = () => {

  const [isLoading, setIsLoading] = useState(false)
  const [feedback, setFeedback] = useState({
    messageFeedback: "",
    showFeedback: false
  })

  const [generalOptionsSide, setGeneralOptionsSide] = useState({
    id: 1,
    optionOne: {
      name: intl.get(`master-data.configurations.general-settings.content.span01`),
      value: OptionsGeneralSettings.Supplier,
      isChecked: true
    },
    optionTwo: {
      name: intl.get(`master-data.configurations.general-settings.content.span02`),
      value: OptionsGeneralSettings.Brewery,
      isChecked: false
    }
  })

  const [orderOptionsSide, setOrderOptionsSide] = useState({
    id: 2,
    optionOne: {
      name: intl.get(`master-data.configurations.order-settings.content.span01`),
      value: OptionsOrderSettings.PurchaseOrder,
      isChecked: true
    },
    optionTwo: {
      name: intl.get(`master-data.configurations.order-settings.content.span02`),
      value: OptionsOrderSettings.ScheduleAgreementLine,
      isChecked: false
    }
  })

  const setOptions = (prevState, value) => (
    {
      ...prevState,
      optionOne: {
        ...prevState.optionOne,
        isChecked: prevState.optionOne.value === parseInt(value)
      },
      optionTwo: {
        ...prevState.optionTwo,
        isChecked: prevState.optionTwo.value === parseInt(value)
      }
    }
  )

  const updateGeneralOptionsSide = (value) => {
    setGeneralOptionsSide(prevState => (setOptions(prevState, value)));
  }

  const updateOrderOptionsSide = (value) => {
    setOrderOptionsSide(prevState => (setOptions(prevState, value)));
  }

  const save = async () => {
    setIsLoading(true)
    const data = [
      { Parametro: GeneralSettingParameter.LoadCompositionNewFlow, Valor: getValueForCondition(generalOptionsSide.optionOne.isChecked, "0", "1") },
      { Parametro: GeneralSettingParameter.OrderSettings, Valor: getValueForCondition(orderOptionsSide.optionOne.isChecked, "0", "1") }
    ]

    try {
      saveGeneralSettings(data)
      updateGeneralSetting(GeneralSettingParameter.LoadCompositionNewFlow, getValueForCondition(data.find(x => x.Parametro === GeneralSettingParameter.LoadCompositionNewFlow).Valor === "0", false, true))
      updateGeneralSetting(GeneralSettingParameter.OrderSettings, getValueForCondition(data.find(x => x.Parametro === GeneralSettingParameter.OrderSettings).Valor === "0", false, true))
      openFeedback(intl.get("feedbacks.savedRegister"))
    } catch (err) {
      processError(err)
    } finally {
      setIsLoading(false)
    }
  }

  const initConfigs = async () => {
    const settings = await loadGeneralSettings()

    const loadCompositionNewFlow = settings.data.find(x => x.Parametro === GeneralSettingParameter.LoadCompositionNewFlow)
    const orderSettings = settings.data.find(x => x.Parametro === GeneralSettingParameter.OrderSettings)

    if (settings && loadCompositionNewFlow.Parametro === 0) {
      setGeneralOptionsSide(prevState => ({
        ...prevState,
        optionOne: {
          ...prevState.optionOne,
          isChecked: loadCompositionNewFlow.Valor === '0'
        },
        optionTwo: {
          ...prevState.optionTwo,
          isChecked: loadCompositionNewFlow.Valor === '1'
        }
      }))
    }

    if (settings && orderSettings.Parametro === 4) {
      setOrderOptionsSide(prevState => ({
        ...prevState,
        optionOne: {
          ...prevState.optionOne,
          isChecked: orderSettings.Valor === "0"
        },
        optionTwo: {
          ...prevState.optionTwo,
          isChecked: orderSettings.Valor === "1"
        }
      }))
    }
  }

  const openFeedback = message => {
    setFeedback({
      messageFeedback: message,
      showFeedback: true
    });
  }

  const closeFeedback = () => {
    setFeedback({
      messageFeedback: "",
      showFeedback: false
    });
  }

  const processError = e => {
    const errorMessage = formatErrorMessage(e)
    openFeedback(errorMessage)
  }

  useEffect(() => {
    initConfigs()
  }, [])

  return (
    <React.Fragment>
      <Loading isLoading={isLoading} />
      <S.GeneralSettingsContent>
        <Row>
          <Col sm={10}>
            <GeneralSettingItem
              image={<IconTruckDarkBlue />}
              name={'general-settings'}
              hasDescription={true}
              updateOptionsSide={updateGeneralOptionsSide}
              optionsSide={generalOptionsSide}
            />
            <GeneralSettingItem
              image={<IconPaperSheet />}
              name={'order-settings'}
              hasDescription={false}
              updateOptionsSide={updateOrderOptionsSide}
              optionsSide={orderOptionsSide}
            />
            <SnackBar
              message={feedback.messageFeedback}
              open={feedback.showFeedback}
              autoHideDuration={3000}
              onRequestClose={closeFeedback}
            />
          </Col>
          <Col sm={2} className='gereral-settings-save-button'>
            <Button
              value={intl.get(`master-data.configurations.general-settings.header.button`)}
              width={"152px"}
              height={"40px"}
              onClick={save}
            />
          </Col>
        </Row>
      </S.GeneralSettingsContent>
    </React.Fragment>
  )
}

export default GeneralSettings
