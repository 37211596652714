import styled from "styled-components";

export const IconWrapper = styled.span`
  ${p => p.isExpanded && "transform: rotate(180deg);"}
`

export const Wrapper = styled.div`
`
export const ListWrapper = styled.div`
  padding-left: 28px;
  display: flex;
  flex-direction: column;
  gap: 1px;
`