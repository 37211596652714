import ImportExport from 'components/import-export'
import intl from 'react-intl-universal'
import moment from 'moment'
import { downloadFile } from 'utils/custom-functions'
import { useGlobalContext } from 'hooks/useGlobalContext';
import { formatErrorMessage } from '../../utils/handle-error'
import { downloadData, downloadModel } from './import-export-garrafas.service'
import PropTypes from 'prop-types'

const ImportExportGarrafas = ({ isOpen, close }) => {
  const { showFeedback } = useGlobalContext();

  const data = {
    description: intl.get('master-data.data-upload.data.bottles'),
    title: intl.get(`exportImportModal.bottles.title`),
    contextModal: 'bottles',
    importUrl: "cadenciafornecedor/importar"
  }

  const proccessErro = (e) => {
    showFeedback(formatErrorMessage(e))
  }

  const exportData = () => {
    return downloadData()
      .then((response) => {
        const datafile = moment(new Date()).format('YYYYMMDD_HHmmss')
        downloadFile(`${datafile}_${data.contextModal}.csv`, 'csv', response)
      })
      .catch(e => proccessErro(e));
  }

  const exportModel = () => {
    return downloadModel()
      .then((response) => {
        downloadFile(`${data.contextModal}_model.csv`, 'csv', response)
      })
      .catch(e => proccessErro(e));
  }

  return (
    <ImportExport
      title={data.title}
      contextModal={data.contextModal}
      open={isOpen}
      handleClose={close}
      downloadModelo={exportModel}
      importArquivo={data.importUrl}
      downloadArquivo={exportData}
      type='CSV'
    />
  )
}

ImportExportGarrafas.propTypes = {
  close: PropTypes.func,
  isOpen: PropTypes.bool
}

export default ImportExportGarrafas;
