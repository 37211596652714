import { Button } from 'components/uikit-adapter';
import { CheckCircle } from 'components/icons/check-circle.icon';
import intl from 'react-intl-universal';
import { useSuggestionContext } from 'pages/suggestions/context/suggestionsContext';
import { useState } from 'react';
import ModalSuggestionsMassAcceptance from './modal-suggestions-mass-acceptance';
import Session from 'utils/user-storage';
import { ModalSuggestionsInOver } from './modal-suggestions-in-over';
import { searchJustificationsRequestInOver } from 'services/suggestion/suggestion.service';
import {
  disableButtonAcceptSuggestions,
  getExternalSuggestionRequest,
  getSuggestionRequest,
  getSuggestionsForAcceptance,
  getSuggestionsForAcceptanceInOver
} from './utils';
import { useGlobalContext } from 'hooks/useGlobalContext';

export const SuggestionsMassAcceptance = () => {
  const { suggestionsSelecteds } = useSuggestionContext();
  const { startOperation, operation, showFeedback } = useGlobalContext();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openJustificationModal, setOpenJustificationModal] = useState(false);
  const [justificationForOver, setJustificationForOver] = useState(null);
  const [justificationsList, setJustificationsList] = useState([]);

  const handleCloseConfirmationModal = () => setOpenConfirmationModal(false);

  const handleSuggestionsMassAcceptance = async () => {
    if (getSuggestionsForAcceptanceInOver(suggestionsSelecteds).length > 0) {
      const _justifications = await searchJustificationsRequestInOver();
      setJustificationsList(_justifications);
      handleCloseConfirmationModal();
      setOpenJustificationModal(true);
    } else {
      acceptSuggestionsInMass();
    }
  }

  const acceptSuggestionsInMass = () => {
    try {
      const _suggestions = getSuggestionsForAcceptance(suggestionsSelecteds);
      const _userId = Session.get().Id;

      const _requests = _suggestions.map((item) => item.ExternalSuggestion ? getExternalSuggestionRequest(item, _userId, justificationForOver) : getSuggestionRequest(item, _userId, justificationForOver));

      const _text = intl.get('suggestions.operationText');
      startOperation(_requests, _text);
    } catch (error) {
      console.error(error);
    } finally {
      handleCloseConfirmationModal();
      setOpenJustificationModal(false);
    }
  }

  return (
    <>
      <Button
        buttonIcon={<CheckCircle />}
        buttonText={intl.get('suggestions.acceptSuggestions')}
        className='btnAcept'
        type='secondary'
        disabled={disableButtonAcceptSuggestions(suggestionsSelecteds, operation.inProgress)}
        onClick={() => setOpenConfirmationModal(true)}
      />

      <ModalSuggestionsMassAcceptance
        open={openConfirmationModal}
        close={handleCloseConfirmationModal}
        onClick={handleSuggestionsMassAcceptance}
        suggestions={getSuggestionsForAcceptance(suggestionsSelecteds)}
        suggestionsInOver={getSuggestionsForAcceptanceInOver(suggestionsSelecteds)}
      />

      <ModalSuggestionsInOver
        open={openJustificationModal}
        close={() => setOpenJustificationModal(false)}
        justificationForOver={justificationForOver}
        setJustificationForOver={setJustificationForOver}
        justificationsList={justificationsList}
        acceptSuggestionsInMass={acceptSuggestionsInMass}
        showFeedback={showFeedback}
      />
    </>
  )
}
