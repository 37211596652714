import styled from 'styled-components';

export const Title = styled.h1`
    color:var(--color-action-default) !important;
    font-family: ProximaNova !important;
    font-size: 21px !important;
`

export const SubTitle = styled.h3`
    color:var(--color-action-default) !important;
    font-family: ProximaNova !important;
    font-size: 24px !important;
    font-weight: normal;
    margin: 0;
    padding-top: 10px;
`

export const Header = styled.div`
    display: flex;
    padding-bottom: 10px;
`
export const ContainerTitles = styled.div`
`

export const ContainerSelect = styled.div`
    margin-left: auto;
    width: 30%;
`

export const ContainerActions = styled.div`
width: 100%;
display: flex;
justify-content: flex-end;
align-items: right;
padding-right: 15px;

.buttons {
  width: 200px;
  margin-left: 7px;
}

.last-button {
    margin-right: 12px;
}
`