import styled from 'styled-components'

export const GeneralFilterWrapper = styled.div`
  display: flex;
  margin-bottom: 14px;
  width: 99%;
  margin: 14px auto;

  .select-box {
    width: 263px;
    position: relative;
    z-index: 9999;
    margin-right: 12px;
  }

  .dropdown-container {
    border-radius: 1px !important;

    &:focus-within {
      box-shadow: var(--color-brand-400) 0 0 0 1px !important;
      border-color: var(--color-brand-400) !important;
    }
  }
`

export const GeneralFilterBlockSeparator = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .buttomdeadline {
    margin-top: 0 !important;

    .date-separator-text {
      height: 95%;
    }
  }

  #row-deadline-control {
    position: relative;
    z-index: 100;
  }

  .label {
    line-height: 1.8rem;
    font-size: 12px;
    font-weight: var(--font-weight-semibold);
    text-transform: uppercase;
    color: var(--color-contrast-brand);
  }

  .last {
    justify-content: flex-end;
  }

`
export const GeneralFilterBlock = styled.div`
  display: flex;

  .select-filters {
    div {
      margin-right: 6px;
    }
  }

  .btn-save-header{
    align-self: end;
    margin-left: 20px;
  }
`
export const GeneralFilterSelectWrapper = styled.div`
`
