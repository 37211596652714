import ptBR from "./data/pt-BR.json";
import enUS from "./data/en-US.json"
import esEs from "./data/es-ES.json"
import Session from "utils/user-storage";

const getFormatToParts = () => new Intl.NumberFormat(getLocaleCode()).formatToParts(555555.555);
export const getDecimalSeparator = () => getFormatToParts().find(x => x.type === 'decimal').value;
export const getGroupSeparator = () => getFormatToParts().find(x => x.type === 'group').value;
export const getLocaleCode = () => Session.get()?.LocaleCode ?? 'en-US';

export default {
  ...ptBR,
  ...enUS,
  ...esEs
}
