export const OptionsGeneralSettings = {
  Supplier: 0,
  Brewery: 1
}

export const OptionsOrderSettings = {
  PurchaseOrder: 0,
  ScheduleAgreementLine: 1,
}

export const OptionsTheme = {
  Legado: 0,
  DesignSystem: 1
}
