import { formatErrorMessage } from 'utils/handle-error.js'
import intl from 'react-intl-universal'

export const errorMessage = (e) => typeof e === 'string' ? e : formatErrorMessage(e);
export const validVolume = (volumes) => volumes && volumes.Schedules && volumes.Schedules.length > 0
export const validVolumeForColorRow = (volume) => volume.Errors && volume.Errors.length > 0 ? 'var(--color-feedback-danger-200)' : '#AFD485'

export const formatErrors = (errors) => {
  let msg = []
  if (errors) {
    msg = errors.map(err => err.Message)
  }
  return msg.join("\n")
}

export const validSelectAll = (e, volumes) => {
  if (e.target.checked) {
    return volumes.Schedules.map(x => x.Id)
  }
  else {
    return []
  }
}

export const validTime = (time) => {
  const split = time.split(":")
  let formatted = time

  if(split.length < 3) {
    formatted = split.join(":") + ":00"
  }
  return formatted
}

export const validDate = (date) => {
  if (date && date.includes("T")) {
    return date.split("T")[0]
  }
  return date
}


export const validateNewLine = (newLineData, processError) => {
  if (newLineData.Material === "" || !newLineData.Material) {
    processError(intl.get('autoLoadOptimization.validate.requiredMaterial'))
    return true;
  }
  if (newLineData.QuantityPallets === "") {
    processError(intl.get('autoLoadOptimization.validate.requiredQuantityPallets'))
    return true;
  }
  if (newLineData.DeliveryDate === "") {
    processError(intl.get('autoLoadOptimization.validate.requiredDeliveryDate'))
    return true;
  }
  if (newLineData.NewShift === "") {
    processError(intl.get('autoLoadOptimization.validate.requiredNewShift'))
    return true;
  }

  if (newLineData.Interval === "00:00") {
    processError(intl.get('autoLoadOptimization.validate.validateNewInterval'))
    return true;
  }
  else {
    return false;
  }
}
