import styled from 'styled-components'
import * as Compoonents from 'components/uikit-adapter/index'

export const Wrapper = styled.div`
  span{
    ${p => p.isDeleted && 'text-decoration:line-through'}
  }

  .body-card{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0px, 1fr));
  }

  .divisor-container {
    margin-top: 5px;
  }

  .card-label{
    align-content: flex-start;
    span:first-child{
      line-height: 12px;
    }
    span:last-child{
      line-height: 14px;
      margin-top: 4px;
    }
  }

  .insumo-desc {
    top: -3px;
  }

  .description-bold{
    font-weight: var(--font-weight-semibold) !important;
    white-space: nowrap;
  }

  .card-shop-mester {
    height: 232px !important;
    margin: 10px 45px 0px 53px;
  }

  span {
    cursor: pointer;
  }

  .warning-waiting-return {
    color: #AE00FF;
    text-align: right;
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: var(--font-weight-semibold);
    line-height: normal;
    text-transform: uppercase;
    margin: 7px 15px -13px 0px;
  }
`

export const ActionsWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 5px !important;
  width: calc(100% - 16px);
  justify-content: end;
`

export const Button = styled(Compoonents.Button)`
  width: 112px;
  height: 26px;
  border: 1px solid var(--color-action-default) !important;
  margin-left: 6px;
  span{
    color: var(--color-action-default) !important;
    font-size: 10px !important;
    text-decoration:none;
  }
`

export const ButtonEdit = styled(Compoonents.Button)`
  width: 112px;
  height: 26px;
  span{
    color: var(--color-contrast-white) !important;
    font-size: 10px !important;
    text-decoration:none;
  }
`
