const IconOrderCalendar = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" width={24} height={24}>
    <path
      stroke="var(--color-action-default)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19 4H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2ZM16 2v4M8 2v4M3 10h18"
    />
  </svg>
);

export default IconOrderCalendar;