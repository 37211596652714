import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  gap: 5px;
  align-self: self-end;
`
export const WrapperActions = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 6px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  margin-left: auto;
  .btn-filter{
    margin-top: 28px;
    height: 37px;
    width: 132px;
    margin-left: 5px;
  }
`

export const DeadlineControlWrapper = styled.div`
  margin-top: 6px;
  z-index: 2;
  
  .date-separator-text{
    height: calc(100% - 2px);
  }
  .buttomdeadline{
    margin-top: 0px !important;
  }

  .label{
    font-size: 12px;
    font-weight: var(--font-weight-semibold);
    text-transform: uppercase;
    color: var(--color-contrast-brand);
  }
  
  label{
    position: fixed;
    top: -99999px;
  }

  input{
    height: 35px !important;
    background: none !important;
  }
`
