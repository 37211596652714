import PropTypes from 'prop-types'
import { Checkbox } from '@hbsis.uikit/react'

import StyledSuggestionsListHeader, { StyledExpandButton } from './suggestions-list-header.styled'
import * as S from "./styled"
import intl from "react-intl-universal"


function renderExpandButton(expandAllItems, itemsExpanded, hasIcon, highlighted, itemDimmed) {
  if (!hasIcon) return null

  let className = itemsExpanded ? 'minimize-item' : 'maximize-item'

  if (highlighted && !itemDimmed) {
    className += '-highlighted'
  }

  if (itemDimmed) {
    className += '-dimmed'
  }

  return (
    <StyledExpandButton
      onClick={(event) => expandAllItems(event.target)}
      className={className}
      data-testid='expandAllItems-span'
    />
  )
}

const SuggestionsListHeader = ({
  children,
  checked,
  checkItem,
  enableCheck,
  expandAllItems,
  itemsExpanded,
  idCheckbox,
  hasIcon,
  isItem,
  isOrder,
  highlighted,
  itemDimmed,
  alertLoadComposition,
  hideMargin
}) => (
  <StyledSuggestionsListHeader className={`suggestion-list-header ${isItem ? 'isItem' : ''} ${highlighted ? 'item-highlighted' : ''} ${itemDimmed ? 'item-dimmed' : ''}`}>
    {!isOrder &&
      <Checkbox
        name={`checkbox-${idCheckbox}`}
        text=" "
        checked={checked}
        onChange={checkItem}
        disabled={enableCheck}
      />
    }
    {
      (isOrder && alertLoadComposition) ?
        <S.Alert>
          <S.AlertContent title={intl.get("stocks.suggestionsListItem.alertMessage")}>
            !
          </S.AlertContent>
        </S.Alert>
        :
        !hideMargin && <span style={{ width: '29px' }} ></span>
    }
    {children}
    <div className='header-item header-actions'>
      {renderExpandButton(expandAllItems, itemsExpanded, hasIcon, highlighted, itemDimmed)}
    </div>
  </StyledSuggestionsListHeader>
)

SuggestionsListHeader.propTypes = {
  isItem: PropTypes.bool,
  hasIcon: PropTypes.bool,
  idCheckbox: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  checked: PropTypes.bool,
  enableCheck: PropTypes.bool,
  itemsExpanded: PropTypes.bool,
  isOrder: PropTypes.bool,
  highlighted: PropTypes.bool,
  itemDimmed: PropTypes.bool,
  checkItem: PropTypes.func,
  expandAllItems: PropTypes.func,
  children: PropTypes.any,
  alertLoadComposition: PropTypes.bool,
  hideMargin: PropTypes.bool
}

export default SuggestionsListHeader
