import PropTypes from "prop-types"
import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog';
import intl from 'react-intl-universal'
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Row, Col } from "react-flexbox-grid";
import * as S from './styled'
import { Input } from "components/uikit-adapter/index"
import { Textarea } from '@hbsis.uikit/react'
import { sendBlockChange, formatWithId } from '../../../../../primary-material-schedule.service';
import { formatErrorMessage } from 'utils/handle-error.js'
import { useGlobalContext } from 'hooks/useGlobalContext';
import { useZapmslContext } from '../../../../../context/zapmslContext';
import Loading from 'components/center-loading';
import { sortTable } from "../../../../../utils/format"
import {
  blockChangeType,
  typesForComments,
  typesForDeliveryDate,
  typesForDeliveryTime,
  typesForDoor,
  typesForOffsetDeliveryDate,
  typesForOffsetDeliveryTime,
  typesForPallets
} from '../../../../../../../models/primaryMaterialSchedule/blockChangeType';
import { initalRadioValues, normalizeHandleChangeRadio, verifyReturnItemsSap } from './utils';
import ActionsFooterDialog from './components/actionsFooterDialog'
import RadioValues from './components/radioValues';
import { getTextAreaStyle } from 'components/suggestions-list/suggestions-list-item/suggestions-list-item.styled';

const ModalEditRange = ({ open, close, openReturnListData }) => {
  const {
    showFeedback
  } = useGlobalContext();

  const {
    selectedLines,
    zapmslData,
    clearSelectedLines,
    setZapmslData
  } = useZapmslContext();

  const [door, setDoor] = useState()
  const [pallets, setPallets] = useState()
  const [note, setNote] = useState()
  const [deliveryDate, setDeliveryDate] = useState()
  const [offsetDate, setOffsetDate] = useState()
  const [offsetTime, setOffsetTime] = useState()
  const [deliveryTime, setDeliveryTime] = useState()
  const [radioValues, setRadioValues] = useState(initalRadioValues())
  const [radioValueSelected, setRadioValueSelected] = useState(blockChangeType.Set)
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeRadio = (value, name) => {
    const _radioValues = normalizeHandleChangeRadio(value, name, radioValues)
    const _value = parseInt(value)
    setRadioValues([..._radioValues])
    setRadioValueSelected(_value)
  }

  const processData = (resultChanges) => {
    const _zapmslData = [...zapmslData];
    resultChanges.filter(res => res.Status === "S").forEach((res) => {
      const indexOf = zapmslData.findIndex(data => verifyReturnItemsSap(data, res))
      _zapmslData[indexOf] = res
    });
    clearSelectedLines();
    const sortData = sortTable(_zapmslData)
    setZapmslData([...formatWithId(sortData)]);
    const _openReturnListData = {
      isOpen: true,
      data: resultChanges.map(res => res.Message || res.ErrorMessage),
      title: 'Block Change Status'
    }
    openReturnListData(_openReturnListData)
  }

  const save = async () => {
    setIsLoading(true);
    const dataLineSelected = zapmslData
      .filter(x => selectedLines.includes(x.Id))
      .map(x => ({
        ...x,
        Material: x.MaterialNumber,
        SchedulingAgreement: x.ScheduleAgreement
      }))

    const dto = {
      Door: door,
      Pallets: pallets,
      Comments: note,
      DeliveryDate: deliveryDate,
      OffsetDeliveryDate: offsetDate,
      OffsetDeliveryTime: offsetTime,
      DeliveryTime: deliveryTime,
      Type: radioValueSelected,
      Lines: dataLineSelected
    }

    try {
      const resultChanges = await sendBlockChange(dto)
      processData(resultChanges)
    }
    catch (e) {
      showFeedback(formatErrorMessage(e))
    }
    setIsLoading(false)
    close()
  }

  return (
    <Dialog
      open={open}
      onClose={close}
    >
      <DialogTitle id="form-dialog-title">{intl.get('primaryMaterialScheduleList.modal.editRange.title')}</DialogTitle>
      <DialogContent style={{ overflowY: 'auto', maxHeight: '65vh', minHeight: '40vh', width: '550px' }}>
        <S.Wrapper>
          <Row>
            <Col xs={6}>
              <Input
                className="input"
                id='door'
                label={intl.get('primaryMaterialScheduleList.list.columns.door')}
                value={door}
                placeholder={intl.get('primaryMaterialScheduleList.list.columns.door')}
                onChange={e => setDoor(e.target.value)}
                disabled={!typesForDoor.includes(radioValueSelected)}
                data-testid='input-door-edit-range'
              />
            </Col>
            <Col xs={6}>
              <Input
                className="input"
                id='pallets'
                label={intl.get('primaryMaterialScheduleList.list.columns.pallets')}
                value={pallets}
                placeholder={intl.get('primaryMaterialScheduleList.list.columns.pallets')}
                onChange={e => setPallets(e.target.value)}
                htmlType='number'
                disabled={!typesForPallets.includes(radioValueSelected)}
                data-testid='input-pallets-edit-range'
              />
            </Col>
          </Row>

          <Row>
            <Col xs={6} style={{ height: '100px' }}>
              <Textarea
                label={intl.get('manual-suggestion-order-form.observation')}
                name="comments"
                value={note}
                onChange={e => setNote(e.target.value)}
                style={getTextAreaStyle({ height: '115px' })}
                disabled={!typesForComments.includes(radioValueSelected)}
                data-testid='area-comments-edit-range'
              />
            </Col>
            <Col xs={6} className='col-offset-delivery-time'>
              <Row className='row-delivery-date'>
                <Col xs={8}>
                  <Input
                    id={'deliveryDate'}
                    value={deliveryDate}
                    onChange={e => setDeliveryDate(e.target.value)}
                    htmlType="date"
                    label={intl.get('primaryMaterialScheduleList.list.columns.deliveryDate')}
                    disabled={!typesForDeliveryDate.includes(radioValueSelected)}
                    data-testid='input-delivery-date'
                    className="input"
                  />
                </Col>
                <Col xs={4}>
                  <Input
                    id='offsetdate'
                    label={intl.get('primaryMaterialScheduleList.list.columns.offsetDate')}
                    onChange={e => setOffsetDate(e.target.value)}
                    value={offsetDate}
                    htmlType='number'
                    disabled={!typesForOffsetDeliveryDate.includes(radioValueSelected)}
                    data-testid='input-offsetdate-date'
                    className="input"
                  />
                </Col>
              </Row>
              <Row className='row-offset-delivery-time'>
                <Col xs={6}>
                  <Input
                    id='delivery-time'
                    label={intl.get('primaryMaterialScheduleList.list.columns.deliveryTime')}
                    onChange={e => setDeliveryTime(e.target.value)}
                    placeholder='delivery-time'
                    htmlType="time"
                    value={deliveryTime}
                    disabled={!typesForDeliveryTime.includes(radioValueSelected)}
                    data-testid='input-delivery-time'
                    className='input'
                  />
                </Col>
                <Col xs={6}>
                  <Input
                    className='input'
                    id='offset-time'
                    label={intl.get('primaryMaterialScheduleList.list.columns.offsetDeliveryTime')}
                    disabled={!typesForOffsetDeliveryTime.includes(radioValueSelected)}
                    onChange={e => setOffsetTime(e.target.value)}
                    value={offsetTime}
                    mask='time'
                    data-testid='input-offset-time'
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <RadioValues
            radioValues={radioValues}
            handleChangeRadio={handleChangeRadio}
          />
        </S.Wrapper>
      </DialogContent>
      <DialogActions>
        <ActionsFooterDialog
          save={save}
          close={close}
        />
      </DialogActions>
      <Loading isLoading={isLoading} fullHeightParent />
    </Dialog >
  )
}

ModalEditRange.propTypes = {
  close: PropTypes.func.isRequired,
  open: PropTypes.any.isRequired,
  openReturnListData: PropTypes.func.isRequired
}

export default ModalEditRange;
