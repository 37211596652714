import styled from "styled-components"
import Header from 'components/header'

export const BottlesWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
export const BottlesHeader = styled(Header)`
`
