import { ClbPagination } from 'libs/celebration';
import { useLoadBuildingContext } from 'features/load-building/context';
import { Wrapper } from './styled';

export const LoadBuildingPagination = () => {
  const { filter, setFilter, quantityRegisters } = useLoadBuildingContext();

  const handlePagination = (value) => {
    setFilter(prevState => ({
      ...prevState,
      _page: value
    }))
  }

  const handleChangeLinesPerPage = (value) => {
    setFilter(prevState => ({
      ...prevState,
      _size: value,
      _page: 0
    }))
  }

  return (
    <Wrapper>
      <ClbPagination
        showResult
        size="lg"
        data-testid='load-building-pagination'
        currentPage={filter._page}
        totalItems={quantityRegisters}
        itemsPerPage={filter._size}
        onClbChangePage={(e) => handlePagination(e.detail.currentPage)}
        onClbSelectChange={(e) => handleChangeLinesPerPage(e.detail.value)}
      />
    </Wrapper>
  )
}