import styled from "styled-components";
import * as Components from "components/uikit-adapter/index";
import { Textarea } from '@hbsis.uikit/react'

export const ButtonCancel = styled(Components.Button)`
  width: 108px;
  height: 43px;
  border-radius: 3px;
  border: 1px solid var(--color-neutral-300) !important;
  background: var(--color-contrast-white);
  span {
    color: var(--color-contrast-brand) !important;
    font-size: 14px !important;
    font-weight: var(--font-weight-semibold) !important;
  }
`;

export const ButtonAdd = styled(Components.Button)`
  width: 108px;
  height: 43px;
  border-radius: 3px;
  background: var(--color-action-default) !important;
  margin-left: 12px;
  span {
    color: var(--color-contrast-white) !important;
    font-size: 14px !important;
    font-weight: var(--font-weight-semibold) !important;
  }
`;

export const ActionsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
`;

export const TitleWrapper = styled.div`
  padding: 20px;
  color: var(--color-contrast-brand);
  font-weight: var(--font-weight-medium);
  font-size: 24px;
  display: flex;
  align-items: center;
  line-height: normal;

  > {
    &:last-child {
      margin-bottom: auto;
    }
  }
`;

export const NotesTextarea = styled(Textarea)``

export const ContentWrapper = styled.div`
  padding: 0px 20px 20px 20px;

  label {
    font-weight: var(--font-weight-semibold);
    font-size: 12px;
  }

  span {
    font-style: normal;
    font-weight: var(--font-weight-regular);
    font-size: 12px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2px;
    color: #9E9E9E;
  }
`;
