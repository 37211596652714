
import React from 'react'
import PropTypes from 'prop-types'
import intl from 'react-intl-universal'

import './antecipar-atrasar-pedido.css'

import { formatDeliveryTime, showDeliveryTime } from 'utils/delivery-time'


const AnteciparAtrasarPedido = ({
  dataAnterior,
  dataAtual,
  horaEntrega
}) => (
  <div>
    <div className="negotiation-read-offer">
      <div className="negotiation-read-column">
        <span>{intl.get('stocks.timeline.previousDate')}</span>
        <span>{dataAnterior}</span>
      </div>
      <div className="negotiation-read-column">
        <span>{intl.get('stocks.timeline.currentDate')}</span>
        <span>{dataAtual}</span>
      </div>
    </div>
    {
      showDeliveryTime(horaEntrega) &&
      <div className="negotiation-read-column">
        <span>{intl.get('commons.deliveryTime')}:</span>
        <span>{formatDeliveryTime(horaEntrega)}</span>
      </div>
    }
  </div>
);

AnteciparAtrasarPedido.propTypes = {
  dataAnterior: PropTypes.string.isRequired,
  dataAtual: PropTypes.string.isRequired,
  horaEntrega: PropTypes.any.isRequired
}

export default AnteciparAtrasarPedido
