import { useState, useEffect, useCallback } from "react"
import AccordionWrapper from './components/accordion'
import Loading from "components/center-loading"
import Filtro from "components/filters/filter-estoque-fornecedor"
import { Layout } from '@hbsis.uikit/react'
import { Button as B, Button } from 'components/uikit-adapter/index'
import { useGlobalContext } from 'hooks/useGlobalContext';
import intl from "react-intl-universal"
import * as S from "./styled"
import { buscaEstoqueCompartilhado, getDownloadSupplierStock, getDownloadSupplierStockModel, salvarAnotacoes, salvarMateriaisPorFornecedor } from './estoque-fornecedor.service'
import { separaListaColunaItem, retornaPosicaoPorIdFornecedor, validaExibicaoMensagemErroFornecedor, showAnnotations, supplierStockFilterDefault } from "./utils"
import IconEmpty from "images/iconEmpty.icon"
import IconFilterGray from "images/icn-filter-gray.svg"
import IconFilterBlue from 'images/icn-filter-blue.svg';
import Session from "utils/user-storage"
import ImportExport from "../../components/import-export"
import { downloadFile, verifyIsFiltered } from "../../utils/custom-functions"
import moment from "moment"
import { userFornecedor } from "../../services/permissions/getPermissions"
import StorePersist from "utils/store-persist"
import { getTextAreaStyle } from "components/suggestions-list/suggestions-list-item/suggestions-list-item.styled"
import IconCalendarScheduleRefresh from "components/icons/icn-calendar-refresh.icon"
import { formatErrorMessage } from "utils/handle-error"

const EstoqueFornecedor = () => {
  const [fornecedores, setFornecedores] = useState(undefined)
  const [carregou, setCarregou] = useState(null)
  const [anotacoes, setAnotacoes] = useState('')
  const [fetching, setFetching] = useState(false)
  const [mostrarFiltro, setMostrarFiltro] = useState(false)
  const [filtros, setFiltros] = useState(supplierStockFilterDefault)
  const [mensagemUsuario, setMensagemUsuario] = useState(false)
  const [importExportSupplierStock, setImportExportSupplierStock] = useState(false)
  const { showFeedback } = useGlobalContext();

  const estoqueCompartilhado = useCallback(async () => {
    setFetching(true)
    if (Session.get().TipoUsuario === 1 && !verifyIsFiltered(filtros)) {
      setMensagemUsuario(true)
      setCarregou(true)
    } else {
      const f = await buscaEstoqueCompartilhado()
      setCarregou(f.IsLoad)
      setFornecedores(separaListaColunaItem(f))
      setAnotacoes(f.Notes)
      setMensagemUsuario(false)
    }
    setFetching(false)
  }, [])

  const mudaValorAnotacao = (event) => {
    setAnotacoes(event.target.value)
  }

  const salvaAnotacoes = async () => {
    try {
      await salvarAnotacoes({ Notes: anotacoes })
      showFeedback(intl.get("stocks.timeline.feedback.notesSavedSuccessfully"))
    } catch (error) {
      showFeedback(formatErrorMessage(error))
    }
  }

  const modificaValorInput = (values, IdFornecedor, i, coluna) => {
    const step = { ...fornecedores }
    const fornecedor = fornecedores && fornecedores[coluna]
    const posicaoFornecedor = retornaPosicaoPorIdFornecedor(IdFornecedor, fornecedor)
    const posicaoMaterial = i
    step[coluna][posicaoFornecedor].Materials[posicaoMaterial].Quantity = values
    setFornecedores({ ...step })
  }

  const salvar = async (IdFornecedor, event, posicao, coluna) => {
    event.stopPropagation()

    const step = { ...fornecedores }
    const posicaoFornecedor = posicao

    step[coluna][posicaoFornecedor].Salvando = !step[coluna][posicaoFornecedor].Salvando
    setFornecedores({ ...step })

    const materiaisParaSalvar = step[coluna][posicaoFornecedor].Materials
    await salvarMateriaisPorFornecedor(IdFornecedor, materiaisParaSalvar)

    step[coluna][posicaoFornecedor].Salvando = !step[coluna][posicaoFornecedor].Salvando
    setFornecedores({ ...step })
  }

  const filtrar = async (filtro, showFilter = !mostrarFiltro) => {
    setFetching(true)
    setFornecedores(undefined)

    if (Session.get().TipoUsuario === 1 && !verifyIsFiltered(filtro)) {
      setFornecedores(undefined)
      setMensagemUsuario(true)
    } else {
      const f = await buscaEstoqueCompartilhado(filtro)
      setCarregou(f.IsLoad)
      setFornecedores(separaListaColunaItem(f))
      setMensagemUsuario(false)
      setAnotacoes(f.Notes)
    }
    setMostrarFiltro(showFilter)
    setFiltros(filtro)
    StorePersist.setValuesJSON("filtersSupplierStockMulti", filtro);
    setFetching(false)
  }

  const atualizarFiltro = (estado) => {
    setMostrarFiltro(estado)
  }

  const openSupplierStockImportExport = () => {
    setImportExportSupplierStock(prevState => !prevState)
  }

  const downloadSupplierStock = async () => {
    try {
      const data = await getDownloadSupplierStock();
      const datafile = moment(new Date()).format('YYYYMMDD_HHmmss')
      downloadFile(`${datafile}_EstoqueFornecedor.csv`, 'csv', data)
    } catch (err) {
      console.log(err)
    }
  }

  const downloadSupplierStockModel = async () => {
    try {
      const data = await getDownloadSupplierStockModel();
      const datafile = moment(new Date()).format('YYYYMMDD_HHmmss')
      downloadFile(`${datafile}_ModeloEstoqueFornecedor.csv`, 'csv', data)
    } catch (err) {
      console.log(err)
    }
  }

  const getFilterIcon = () => {
    if(localStorage.getItem("THEME") != 1 ){
      if(verifyIsFiltered(filtros))
        return IconFilterBlue;
      else 
        return IconFilterGray;
    }else
      return undefined;
  }

  useEffect(() => {
    estoqueCompartilhado()
    const _filters = StorePersist.getValuesJSON("filtersSupplierStockMulti");
    if(_filters !== filtros) {
      filtrar(_filters, false);
    }
  }, [])

  return (
    <S.EstoqueFornecedorWrapper>
      <S.EstoqueFornecedorHeader title={intl.get("menu.subMenuGeneral.supplierStock")}>
        <div style={{ display: 'flex', justifyContent: 'flex-end', width: "100%" }}>
          {
            userFornecedor() &&
            <div
              title={intl.get("exportImportModal.supplierStock.title")}
              className="button-actions">
              <Button
                onClick={() => openSupplierStockImportExport()}
                type='default'
                buttonIcon={<IconCalendarScheduleRefresh />}
              />
            </div>
          }
          <Button
            onClick={() => atualizarFiltro(true)}
            type='default'
            value={intl.get('filters.filter')}
            className='button-filter'
            icon={getFilterIcon()}
          />
        </div>
        <Layout.Sidebar
          width="450px"
          visible={mostrarFiltro}
          triggerClose={() => atualizarFiltro(false)}
          background="var(--color-contrast-white)"
          offsetTop="96px"
          side="right"
          block
          float
          icon={IconEmpty}
        >
          <Filtro emitter={filtrar} />
        </Layout.Sidebar>
      </S.EstoqueFornecedorHeader>
      <S.EstoqueFornecedorContent espacamento="1rem">
        {
          (validaExibicaoMensagemErroFornecedor(fornecedores)) &&
          <div className="errorMessage">{intl.get("commons.noRecordsFound")}</div>
        }
        <S.EstoqueFornecedorFirstAccordion>
          {
            mensagemUsuario &&
            <div className="errorMessage__user">
              {intl.get("supplierStock.message")}
            </div>
          }
          {fornecedores &&
            <AccordionWrapper
              dados={fornecedores.primeira}
              coluna={'primeira'}
              salvar={salvar}
              valorInput={modificaValorInput}
            />
          }
        </S.EstoqueFornecedorFirstAccordion>
        <S.EstoqueFornecedorSecondAccordion>
          {fornecedores &&
            <AccordionWrapper
              dados={fornecedores.segunda}
              coluna={'segunda'}
              salvar={salvar}
              valorInput={modificaValorInput}
            />
          }
        </S.EstoqueFornecedorSecondAccordion>
      </S.EstoqueFornecedorContent>
      <div>
        <Loading isLoading={fetching} />
        {
          showAnnotations(fornecedores) && carregou &&
          <div>
            <S.EstoqueFornecedorActionsWrapper>
              <S.EstoqueFornecedorTextarea
                style={getTextAreaStyle()}
                data-testid="anotacoes"
                label={intl.get("stocks.stockDetail.annotations")} rows={4}
                width="49%" value={anotacoes}
                onChange={(event) => mudaValorAnotacao(event)}
              />
            </S.EstoqueFornecedorActionsWrapper>
            <S.EstoqueFornecedorActionsWrapper>
              <div className="button-wrapper">
                <B
                  type="primary"
                  value={intl.get("stocks.stockDetail.saveAnnotations")}
                  width="150px" onClick={() => salvaAnotacoes()}
                />
              </div>
            </S.EstoqueFornecedorActionsWrapper>
          </div>
        }
      </div>
      <ImportExport
        title={intl.get("exportImportModal.supplierStock.title")}
        contextModal="supplierStock"
        open={importExportSupplierStock}
        handleClose={openSupplierStockImportExport}
        downloadArquivo={downloadSupplierStock}
        downloadModelo={downloadSupplierStockModel}
        importArquivo="supplierStock/import"
        type='csv'  
      />
    </S.EstoqueFornecedorWrapper>
  )
}

export default EstoqueFornecedor
