import {
  ClbTableTBody,
} from 'libs/celebration'
import BodyLine from './body-line';
import { IProductionItem } from 'features/supplier-production-plan/interface';

interface IBody {
  data: IProductionItem[]
}

const Body = ({ data }: IBody) => (
  <ClbTableTBody>
    {data.map(plan => (
      <BodyLine item={plan} key={`${plan.BusinessUnitId}-${plan.MaterialId}`} />
    ))}
  </ClbTableTBody>
);

export default Body;