export const separaListaColunaItem = (lista) => {
  const colunas = {
    primeira: [],
    segunda: []
  }
  
  lista.Suppliers.map((item, posicao) => posicao % 2 === 0 ? colunas.primeira.push(item) : colunas.segunda.push(item))
  return colunas
}

export const retornaPosicaoPorIdFornecedor = (IdFornecedor, lista) => {
  return lista.findIndex((m) => m.SupplierId === IdFornecedor)
}

export const formataNumeroMaterial = (numeroMaterial) => {
  return numeroMaterial ? numeroMaterial.slice(10)
    : undefined
}

export const validaExibicaoMensagemErroFornecedor = (fornecedor) => {
  return fornecedor && fornecedor.primeira.length === 0 && fornecedor.segunda.length === 0
}

export const supplierStockFilterDefault = {
  Carteiras: [],
  Familias: [],
  FamiliasRotuladas: [],
  GrupoFornecedor: []
};

export const showAnnotations = (fornecedores) => {
  return fornecedores && (fornecedores.primeira.length > 0 || fornecedores.segunda.length > 0);
}
