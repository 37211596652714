import { IProductionItem } from "features/supplier-production-plan/interface";
import { useEffect, useState } from "react";
import {
  ClbTableTBodyRow,
  ClbTableTBodyCell,
  ClbButtonIcon
} from "libs/celebration";
import { isUserSupplier } from "utils/user";
import { DATA_FORMAT_TYPES, getDataFormat, ISO_DATE_FORMAT } from "utils/format-date";
import * as S from './styled'
import { ModalConfirmAction } from "components/modal-confirm-action";
import intl from "react-intl-universal";
import { useSupplierProductionPlanContext } from "features/supplier-production-plan/context";
import { useToastContext } from "hooks/useToastContext";
import { formatErrorMessage } from "utils/handle-error";
import { createSupplierProductionPlan, deleteSupplierProductionPlan, editSupplierProductionPlan } from "services/supplier-production-plan.service";
import DateInput from "components/date-input";
import { formatNumber } from "utils/format";
import InputNumber from "components/input-number";
import { validateSupplierProductionPlan } from "./utils";
import moment from "moment";

interface IBodyLine {
  item: IProductionItem;
}

const BodyLine = ({ item }: IBodyLine) => {
  const { filters, setLoading, searchProductionPlanData } = useSupplierProductionPlanContext();
  const { openToast } = useToastContext();
  const [plan, setPlan] = useState(item);
  const [isEditing, setIsEditing] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);

  const validateAndSave = async() => {
    try {
      setLoading(true);
      await validateSupplierProductionPlan(plan);
      await handleSave();
    } catch (error: any) {
      openToast(error.message, 'negative');
    } finally {
      setLoading(false);
    }
  }

  const handleSave = async () => {
    try {
      let bodyRequest = { supplierProductionPlan: plan };
      if(plan.SupplierProductionPlanId == null || plan.SupplierProductionPlanId == 0)
      {
        await createSupplierProductionPlan(bodyRequest);
      }
      else
      {
        await editSupplierProductionPlan(plan.SupplierProductionPlanId, bodyRequest);
      }
      await searchProductionPlanData(filters);
      openToast(intl.get('feedbacks.savedRegister'));
      setIsEditing(false);
    } catch (error) {
      openToast(formatErrorMessage(error), 'negative');
    } 
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      const _id = plan.SupplierProductionPlanId ?? 0;
      await deleteSupplierProductionPlan(_id);
      await searchProductionPlanData(filters);
      openToast(intl.get('feedbacks.deleteRegister'));
    } catch (error) {
      openToast(formatErrorMessage(error), 'negative')
    } finally {
      setOpenModalDelete(false);
      setLoading(false);
    }
  };

  const handleCancelEdit = () => {
    setPlan(item);
    setIsEditing(false);
  }

  useEffect(() => {
    setPlan(item);
  }, [item])

  const handleChangeValue = (field: string, value: any) => {
    setPlan(plan => ({
      ...plan,
      [field]: value
    }))
  }

  return (
    <>
      <ClbTableTBodyRow>
        <ClbTableTBodyCell> {plan.MaterialDescription}  </ClbTableTBodyCell>
        <ClbTableTBodyCell> {plan.BusinessUnit} </ClbTableTBodyCell>
        <ClbTableTBodyCell> 
        { isEditing ?
          <InputNumber
            //@ts-ignore
            type="default"
            value={plan.PendingQuantity}
            onChange={(value: number) => handleChangeValue("PendingQuantity", value)}
            data-testid='input-number-pendingquantity'
          /> 
          :
          formatNumber(plan.PendingQuantity)
        } 
      </ClbTableTBodyCell>
        <ClbTableTBodyCell> 
        { isEditing ?
          <InputNumber
          //@ts-ignore
            type="default"
            value={plan.PendingQuantityPallets}
            onChange={(value: number) => handleChangeValue("PendingQuantityPallets", value)}
            data-testid='input-number-pendingquantitypl'
          /> 
          :
          formatNumber(plan.PendingQuantityPallets)
        } 
      </ClbTableTBodyCell>
      <ClbTableTBodyCell>
        { isEditing ?
        /* @ts-ignore */
          <DateInput
            value={plan.ProductionDate}
            onChange={value => handleChangeValue('ProductionDate', value)}
            minDate={moment().format(ISO_DATE_FORMAT)}
            id='date-picker-production-plan'
          />
          :
          getDataFormat(plan.ProductionDate, DATA_FORMAT_TYPES.WITH_YEAR)
        }
      </ClbTableTBodyCell>
        <ClbTableTBodyCell>
          { isEditing ?
          <InputNumber
            //@ts-ignore
            type="default"
            value={plan.ProductionQuantity}
            onChange={(value: number) => handleChangeValue("ProductionQuantity", value)}
            data-testid='input-number-productionquantity'
          /> 
          :
          formatNumber(plan.ProductionQuantity)  
        } 
        </ClbTableTBodyCell>
          {isUserSupplier() && (
          <ClbTableTBodyCell>
            {isEditing ? (
              <S.ButtonsWrapper>
                <ClbButtonIcon 
                  onClbClick={validateAndSave} 
                  icon={"Save"} 
                  size="sm" 
                  tooltipLabel={intl.get("geral.buttonsDefault.save")} 
                  tooltipPosition="top" 
                  data-testid="button-save"
                />
                <ClbButtonIcon 
                  onClbClick={handleCancelEdit} 
                  icon={"Cancel"} 
                  size="sm" 
                  tooltipLabel={intl.get("geral.buttonsDefault.cancel")} 
                  tooltipPosition="top" 
                  data-testid="button-cancel-edit"
                />
              </S.ButtonsWrapper>
            ) : (
              <S.ButtonsWrapper>
                <ClbButtonIcon
                  onClbClick={() => setIsEditing(true)}
                  icon="Edit"
                  size="sm"
                  tooltipLabel={intl.get("geral.buttonsDefault.edit")}
                  tooltipPosition="top"
                  data-testid="button-edit"
                />
                {item.SupplierProductionPlanId &&
                  <ClbButtonIcon
                    onClbClick={() => setOpenModalDelete(true)}
                    icon="Delete"
                    size="sm"
                    tooltipLabel={intl.get("geral.buttonsDefault.delete")}
                    tooltipPosition="top"
                    data-testid="button-delete"
                  />
                }
              </S.ButtonsWrapper>
            )}
          </ClbTableTBodyCell>
        )}
      </ClbTableTBodyRow>

      <ModalConfirmAction
        isOpen={openModalDelete}
        title={intl.get("supplierProductionPlan.modalDelete.title")}
        textMessage={intl.get("supplierProductionPlan.modalDelete.description")}
        handleClose={() => setOpenModalDelete(false)}
        handleConfirm={handleDelete}
      />
    </>
  );
};

export default BodyLine;
