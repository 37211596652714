import PropTypes from "prop-types"
import React from 'react'
import ListStock from './list-stock'
import StyledListStocks from './styled'

const ListStocks = ({ onClick, data }) => (
  <StyledListStocks>
    <ListStock
      data={data}
      onClick={onClick}
    />
  </StyledListStocks>
)

ListStocks.propTypes = {
  data: PropTypes.any.isRequired,
  onClick: PropTypes.any.isRequired
}
export default ListStocks
