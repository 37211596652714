import { StatusVolume } from "models/volumes/status"

export const StatusRequest = {
  NotStarted: 0,
  Loading: 1,
  Success: 2,
  Error: 3
}

export const initialFilterLoadBuilding = {
  BusinessUnitId: null,
  SupplierId: null,
  MaterialNumber: [],
  _minDeliveryDate: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(),
  _maxDeliveryDate: "",
  _minCollectDate: '',
  _maxCollectDate: '',
  Status: [StatusVolume.AguardandoInformacoesTransporte],
  _page: 0,
  _size: 100,
}

export const validateValidFilter = (filter) => filter?.BusinessUnitId && filter.SupplierId

export const initialLoadSettingsData = {
  Door: null,
  TruckSize: "",
  AvailableDeliverySlots: false
};
