import PropTypes from "prop-types"
import React from 'react'
import {
  Radio,
  RadioGroup
} from '@hbsis.uikit/react'
import { supplierOrigin, supplierValues } from '../../supplier-utils';
import { usesReturnableAssets, usesTransferOrder } from 'utils/validations-general-settings';

function RadioGroupMaterialForm({ handleChange, label, materialOrigin }) {
  return (
    <RadioGroup
      directionItems='row'
      label={label}
      name='OrigemMaterial'
      onChange={handleChange}
    >
      <Radio
        defaultChecked={supplierOrigin.IsPurchase(materialOrigin)}
        text={supplierOrigin.getName(supplierValues.COMPRA_DE_INSUMOS)}
        value={supplierValues.COMPRA_DE_INSUMOS}
      />
      {usesTransferOrder() ?
        <Radio
          defaultChecked={supplierOrigin.IsTransfer(materialOrigin)}
          text={supplierOrigin.getName(supplierValues.TRANSFERENCIA)}
          value={supplierValues.TRANSFERENCIA}
        /> :
        <div />
      }
      {usesReturnableAssets() ?
        <Radio
          defaultChecked={supplierOrigin.IsAtgPurchase(materialOrigin)}
          text={supplierOrigin.getName(supplierValues.COMPRA_ATG)}
          value={supplierValues.COMPRA_ATG}
        /> :
        <div />
      }
    </RadioGroup>
  )
}

RadioGroupMaterialForm.propTypes = {
  handleChange: PropTypes.any.isRequired,
  label: PropTypes.any.isRequired,
  materialOrigin: PropTypes.any.isRequired
}

export default RadioGroupMaterialForm;
