import intl from 'react-intl-universal'
import { ClbFullPageFlow } from 'libs/celebration';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { formatErrorMessage } from 'utils/handle-error';
import Loading from "components/center-loading";
import { useEffect, useState } from 'react';
import { Wrapper } from '../../styled';
import { getSchedulingAutoPilot, saveSchedulingAutoPilot } from 'services/labeled-family-center';
import { AutoPilotAutomaticRoutines } from '../auto-pilot-automatic-routines';
import { SchedulingAutoPilotGeneralDto, emptySchedulingAutoPilotGeneralDto } from 'types/labeled-family-center/auto-pilot/scheduling-auto-pilot-general-dto';
import { AutoPilotScheduleConfiguration } from '../auto-pilot-schedule-configuration';
import { useToastContext } from 'hooks/useToastContext'

export interface IMatchParams {
  id: string
}

export const AutoPilotFullPageFlowComponent = ({ history, match }: RouteComponentProps<IMatchParams>) => {
  const idLabeledFamilyCenter = Number(match.params.id);

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<SchedulingAutoPilotGeneralDto>(emptySchedulingAutoPilotGeneralDto());
  const { openToast } = useToastContext();

  const getData = async () => {
    setIsLoading(true);
    const _data = await getSchedulingAutoPilot(idLabeledFamilyCenter);
    setData(_data);
    setIsLoading(false);
  }

  useEffect(() => {
    getData();
  }, [])

  if ((!data.IdLabeledFamilyCenter) && isLoading) {
    return (<Loading isLoading={isLoading} />)
  }

  if (!data.IdLabeledFamilyCenter) {
    return null;
  }

  const handleCancel = () => {
    history.goBack();
  }

  const normalizaDataToSave = (data: SchedulingAutoPilotGeneralDto) => {
    data.IdBusinessUnitDoor = data.IdBusinessUnitDoor === 0 ? null : data.IdBusinessUnitDoor
  }

  const handleSave = async () => {
    setIsLoading(true);
    try {
      normalizaDataToSave(data);
      await saveSchedulingAutoPilot(idLabeledFamilyCenter, data);
      openToast(intl.get('master-data.feedback.savedSuccessfully'), 'positive')
      history.goBack();
    } catch (error) {
      openToast(formatErrorMessage(error), 'negative')
    }
    setIsLoading(false);
  }
  
  return (
    <ClbFullPageFlow
      title={`${intl.get('commons.autopilot')}: ${data.BusinessUnitDescription} - ${data.LabeledFamilyDescription}`}
      opened={true}
      steps={0}
      activeStep={0}
      onClbClose={handleCancel}
      buttonsList={[
        {
          id: "btn-cancel",
          label: intl.get('commons.cancel'),
          title: intl.get('commons.cancel'),
          callback: handleCancel,
          dataTestid: "btn-cancel",
          ariaLabel: intl.get('commons.cancel')
        },
        {
          id: "btn-save",
          label: intl.get('geral.buttonsDefault.save'),
          title: intl.get('geral.buttonsDefault.save'),
          callback: () => { handleSave() },
          dataTestid: "btn-save",
          ariaLabel: intl.get('geral.buttonsDefault.save')
        },
      ]}
    >
      <Wrapper>
        <AutoPilotAutomaticRoutines
          data={data}
          setData={setData}
        />

        <AutoPilotScheduleConfiguration
          data={data}
          setData={setData}
        />
      </Wrapper>

      <Loading isLoading={isLoading} />
    </ClbFullPageFlow>
  )
};

const AutoPilotFullPageFlowWithRouter = withRouter(AutoPilotFullPageFlowComponent);
export { AutoPilotFullPageFlowWithRouter as AutoPilotFullPageFlow };