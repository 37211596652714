import styled from 'styled-components'
import { Button as ButtonUI } from 'components/uikit-adapter/index'

export const BusinessUnitWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  float: right;

  .bussinesUnit {
    display: flex;
    align-items: center;
    
    .input-unit {
      width: 250px;
      margin-bottom: 30px;
    }
  }

  svg{
    padding-right: 20px;
  }
`

export const InitialWrapper = styled.div`
    background-color: white;
    height: calc(100vh - 320px);
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Button = styled(ButtonUI)`
  width: 150px !important;
`
