import styled from 'styled-components'
import { Button } from 'components/uikit-adapter/index';

export const InfosWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 10px 12px 10px;
    height: 80px;
    overflow-y: ${p => p.noScroll ? 'hidden' : 'auto'};
    
  .footer-dialog-buttons{
    margin-top: 18px;
  }

  .title{
    font-weight: var(--font-weight-semibold);
    font-size: 12px;
    line-height: 12px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #9CA4AC;
  }

  .description{
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    color: var(--color-action-default);
  }

  .second-content{
    margin-top: 8px;
  }

  .content-right{
    margin-left: 5px;
  }

  .material-description{
    display: block;
    width: 200px;
  }

  .content{
    margin-top: 12px;
  }

  ${p => p.isLast && 'margin-bottom: 12px;'}
  ${p => p.isFooter && 'margin-top: 0px;'}
`

export const TitleWrapper = styled.div`
  border-bottom: 1px solid var(--color-neutral-300);
  padding: 0px 10px 12px 0px;
  margin-top: 12px;
  .title{
    color: #9CA4AC;
  }

  .description{
    color: var(--color-action-default);
  }
`

export const ObservacoesWrapper = styled.div`
   margin-top: 12px;
   margin-bottom: 2px;
  .title{
    color: #9CA4AC;
  }

  .description{
    color: var(--color-contrast-black);
  }
`

export const SecondTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const VectorWrapper = styled.span`
  ${p => !p.showDrop && 'display: none;'}
  img{
    ${p => !p.hideInfos && 'transform: rotate(180deg);'}
  }
`

export const SecondTitle = styled.div`
  font-size: 24px;
  line-height: 24px;
  color: var(--color-contrast-black);
  ${p => !p.hideInfos && 'margin-bottom: 12px'};
`

export const Vector = styled.span`
  color: var(--color-action-default);
`

export const Title = styled.span`
  font-size: 24px;
  line-height: 24px;
  color: var(--color-action-default);
`
export const Wrapper = styled.div`
  position: fixed;
  top: -105px;
  left: 320px;
  width: 95vw;
  height: 100vh;
  display: flex;
  justify-content: center;

  .styledCenterLoading__StyledCenterLoading-hwbsbr-0{
    width: 100vw;
    left: 300px;
    top: -104px;
  }
`
export const InfosNegotiationWrapper = styled.div`
  background: var(--color-contrast-white);
  position: absolute;
  bottom: 0;
  padding: 20px;
  height: 308px;
  width: 480px;
  border-radius: 6px 6px 0px 0px;

  @media(max-height: 700px){
    height: auto;
  }

`
export const InfosCompositionWrapper = styled.div`
  background: var(--color-contrast-white);
  position: absolute;
  top: 0;
  padding: 15px 18px 32px 28px;
  margin-top: 20px;

  @media(max-height: 700px){
    margin-top: 3px;
  }

  .row-wrapper{
    width: 100%;
  }

  .containerLista {
    height: auto;
    max-height: 19vh;
  }
`

export const InfosContentWrapper = styled.div`
  overflow-y: auto;
  @media(max-height: 700px){
    height: 28vh;
  }
  @media(max-height: 590px){
    height: 20vh;
  }
`

export const WrapperDialog = styled.div`
`

export const ButtonEdit = styled(Button)`
  width: 140px;
  margin: 0;
  margin-left: 12px;

  ${p => p.padding && `padding: ${p.padding}`}
`
export const ButtonCancel = styled(Button)`
  width: 108px;
  margin: 0;
`
