import { useState } from 'react'
import {
  ClbTableTBodyCell,
  ClbButtonIcon,
} from '@celebration/design-system-react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import intl from 'react-intl-universal'
import * as S from './styled'
import { ConsolidatedStocksSuggestionsModal } from '../consolidated-stocks-suggestions-modal'
import { generateSuggestions, runStockProjection, updateStockFromSap } from 'services/labeled-family-center.service'
import PropTypes from 'prop-types'
import { formatErrorMessage } from 'utils/handle-error'
import { useGlobalContext } from 'hooks/useGlobalContext'

export const ActionsTableBody = ({ labeledFamilyCenterId, lastUpdate, handleLoad, setIsLoading }) => {
  const [openSuggestionModal, setOpenSuggestionModal] = useState(false)
  const { showFeedback } = useGlobalContext();

  const handleUpdateProjection = async () => {
    try {
      setIsLoading(true);
      await updateStockFromSap({ "IdLabeledFamilyCenter": labeledFamilyCenterId });
      await runStockProjection(labeledFamilyCenterId);
      await generateSuggestions({ "IdLabeledFamilyCenter": labeledFamilyCenterId });
      await handleLoad();
      setIsLoading(false);
    } catch (e) {
      showFeedback(formatErrorMessage(e));
      setIsLoading(false);
    }
  }

  return (
    <>
      <div className='border-left flex'>
        <ClbTableTBodyCell className='actions'>
          <S.WrapperActions>
            <Link to={`/stocksDetail/${labeledFamilyCenterId}`}>
              <ClbButtonIcon
                icon="Eye1"
                size="sm"
              />
            </Link>
            <ClbButtonIcon
              icon="Edit"
              size="sm"
              onClbClick={() => setOpenSuggestionModal(!openSuggestionModal)}
            />
            <ClbButtonIcon
              icon="Refresh"
              tooltipLabel={`${intl.get('commons.lastUpdate')}: ${moment(lastUpdate).format("L LT")}`}
              tooltipPosition="top-end"
              onClick={handleUpdateProjection}
              size="sm"
              data-testid='refresh-projection'
            />
          </S.WrapperActions>
        </ClbTableTBodyCell>
      </div>
      <ConsolidatedStocksSuggestionsModal
        labeledFamilyCenterId={labeledFamilyCenterId}
        open={openSuggestionModal}
        close={() => setOpenSuggestionModal(false)}
      />
    </>
  )
}

ActionsTableBody.propTypes = {
  labeledFamilyCenterId: PropTypes.number, 
  lastUpdate: PropTypes.string, 
  handleLoad: PropTypes.func, 
  setIsLoading: PropTypes.func,
}