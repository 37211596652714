import { firstSection } from "./first-section";
import { secondSection } from "./secondSection";
const keyName = "columnPrimaryMaterialConfiguration";

const visible = [...firstSection, ...secondSection];

const hidden = [

];

const ColumnsMaterialPrimary = {
  Visible: visible,
  Hidden: hidden,
};

export default ColumnsMaterialPrimary;
export const saveConfiguredColumn = (cols) =>
  localStorage.setItem(keyName, JSON.stringify(cols));
