import React, { useEffect, useState } from 'react'
import { getFormattedHours, getFormattedWeekday } from './components/utils'
import { useDeliveryScheduleConfigurationContext } from './context/deliveryScheduleConfigurationContext'
import { getData } from './deliveryScheduleConfiguration.service'
import { useGlobalContext } from 'hooks/useGlobalContext';
import { formatErrorMessage } from '../../../../utils/handle-error'
import { DeliveryScheduleConfigurationIcon } from '../../../../components/icons/icn-index.icon'
import Table from './components/table'
import FilterDeliveryScheduleConfiguration from './components/filter'
import intl from 'react-intl-universal'
import Loading from "components/center-loading";
import HeaderIcon from '../../../../components/header-icon/index'
import * as S from './styled'

const DeliveryScheduleConfiguration = () => {
  const {
    filter,
    clearChanges,
    isLoading,
    stopLoading,
    setData,
  } = useDeliveryScheduleConfigurationContext()

  const { showFeedback } = useGlobalContext();

  const [showTable, setShowTable] = useState(false);

  const loadData = async () => {
    try {
      const dtoRequest = {
        IdUnidadeNegocio: filter.center.IdUnidadeNegocio,
        Periodo: filter.period.key,
        Doca: filter.door.value
      }
      const _data = await getData(dtoRequest)
      setData([..._data])
    }
    catch (e) {
      proccessErro(e)
    }
    setShowTable(true);
    stopLoading();
  }

  const proccessErro = (e) => {
    showFeedback(formatErrorMessage(e))
  }

  useEffect(() => {
    setShowTable(false);
    if (filter.center) {
      clearChanges();
      loadData();
    }
  }, [filter])

  return (
    <S.Wrapper>
      <HeaderIcon
        title={intl.get('master-data.managementUnity.deliverySchedule.header.title')}
        subtitle={intl.get('master-data.managementUnity.deliverySchedule.header.subtitle')}
        icon={<DeliveryScheduleConfigurationIcon width='48px' height='48px' color='var(--color-brand-500)' />}
      />

      <FilterDeliveryScheduleConfiguration />
      <S.MessageAlert>{intl.get('master-data.managementUnity.deliverySchedule.alertMessage')}</S.MessageAlert>
      {showTable &&
        <Table
          days={getFormattedWeekday()}
          keys={getFormattedHours(filter.period)}
        />
      }
      <Loading isLoading={isLoading} />
    </S.Wrapper>
  )
}

export default DeliveryScheduleConfiguration;
