import styled from 'styled-components'
import { Button } from 'components/uikit-adapter/index';

export const Wrapper = styled.div`
  min-height: 250px;
  background: white;
  .styledCenterLoading__StyledCenterLoading-hwbsbr-0{
    position: absolute;
    max-height: 700px;
    top: 0;
  }

  .row-filter {
    position: relative;
    z-index: 998;
    max-width: 400px;
  }
`

export const SelectDatesContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: relative;
  top: 10px;
  z-index: 998;

  margin: 10px 8px 0px 8px;
`

export const SelectDatesButton = styled(Button)`
  width: 20px;
  border: none !important;

  position: relative;  
  &.select-date-button-previous {
    transform: rotate(90deg);
  }

  &.select-date-button-previous:hover {
    background-color: white;
  }

  &.select-date-button-previous:active {
    box-shadow: none;
  }

  &.select-date-button-next {
    transform: rotate(-90deg);
  }

  &.select-date-button-next:hover {
    background-color: white;
  }

  &.select-date-button-next:active {
    box-shadow: none;
  }
`
export const IconFullScreen = styled.div`
  z-index: 999;
  position: absolute;
  display: flex;
  justify-content: end;
  top: 25px;
  right: 10px;
`