import styled from 'styled-components'

const StyledPagination = styled.div`
    color: rgb(158, 158, 158);
    font-size: 13px;
    text-align: right;

    > .page {
        margin-right: 30px;
    }

    > .pages {
        margin-right: 30px;
    }

    > .arrow {
        font-size: 16px;
        font-weight: bold;
        color: rgb(158, 158, 158);
    }
`
export default StyledPagination
