import styled from 'styled-components'

export const IconFullScreen = styled.div`
  z-index: 999;
  position: absolute;
  display: flex;
  justify-content: end;
  top: 25px;
  right: 10px;
  cursor: pointer;
  padding: 5px;
  :hover{
    transform: scale(1.15);
  }
`
export const Wrapper = styled.div`
  background: white;
`

export const Content = styled.div`
  position: relative;
  background: white;
  top: calc(50% - 250px);

  canvas{
    &:active {
      cursor: grabbing;
    }
  }
`

interface WrapperChartProps {
  isHovered: boolean
}

export const WrapperChart = styled.div<WrapperChartProps>`
  ${(p) => p.isHovered ? `
      canvas{
        cursor: grab;
        &:active {
          cursor: grabbing;
        }
      }` : ''
  }
`
