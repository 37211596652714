import Fetch from 'utils/fetch'

export const searchWallets = () => {
    return Fetch.get(`/material-groups:all-with-users`).then(({ data }) => data)
}

export const searchCoordinations = () => {
    return Fetch.get(`/coordinations:all-with-users`).then(({ data }) => data)
}

export const searchUsers = () => {
    return Fetch.get(`/usuario/buscaUsuariosAmbev`).then(({ data }) => data)
}

export const searchUserTypes = () => {
    return Fetch.get(`/users:types-except-supplier`).then(({ data }) => data)
}

export const searchAccessProfileTypes = () => {
    return Fetch.get(`/users:access-profiles`).then(({ data }) => data)
}

export const searchEmails = () => {
    return Fetch.get(`/users:all-emails-except-supplier`).then(({ data }) => data)
}
