import styled from 'styled-components'
import ScrollContainer from 'react-indiana-drag-scroll'

export const Table = styled.div`
  display:flex;
`
export const TableBody = styled(ScrollContainer)`
  display:flex;
  margin-bottom: 30px;
`
export const TableRow = styled.div`
  display: flex;
  flex-flow: column;

  div:first-child {
    background-color: var(--color-brand-400);
  }

`
export const TableCell = styled.div`
  width: 104px;
  height: 27px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  text-align: center;
  border-right: 1px dashed var(--color-neutral-300);
  align-items: center;
  border-bottom: 1px dashed var(--color-neutral-300);

  span {
    color: white;
    font-weight: var(--font-weight-semibold);
    font-size: 14px;
    cursor: auto;
  }
`

export const TableHeading = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  background-color: var(--color-brand-400);
  color: white;
  font-weight: bold;
  font-size: 14px;
  width: 120px;
  box-shadow:5px 0px 16px -11px rgb(0 0 0 / 65%);
  margin-bottom: 30px;

  div:last-child {
    border-bottom: none;
  }

`
export const TableHeadingTitle = styled.div`
  width: 120px;
  height: 27px;
  border-bottom: 1px solid white;
  display:flex;
  flex-flow: column;
  justify-content: center;

  span{
    margin-left: 5px;
    cursor: auto;
  }
`

export const TableCellEditable = styled.input`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 50px;
  height: 20px;
  background: var(--color-neutral-200);
  border: 1px solid var(--color-neutral-300);
  box-sizing: border-box;
  border-radius: 2px;
  text-align: center;

  &:focus {
    border-color: var(--color-brand-400);
    outline: none;
  }
`
