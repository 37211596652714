import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 170px;
  margin: 10px;

  .sidebar-close {
    display: none;
  }
`
