import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 32px 0px;
  width: 100%;
  height: calc(44vh - 65px);
  overflow-y: auto;
`
export const TableWrapper = styled.div`
`

export const WrapperRow = styled.tr`
  box-shadow: inset 4px 0 var(--color-brand-200);
  height: 55px;
  font-size: 0.8rem;
  background: var(--color-contrast-white);
  padding: 5px 0;
  min-height: 60px;
  max-height: 60px;
  padding-left: 4px;
  border-bottom: 1px dashed var(--color-neutral-300);
  text-decoration: none;
  color: inherit;
  display: table-row-group;
  &:hover{
    background-color: #F4F5F8;
  }
  
  ${p => p.clickable && 'cursor: pointer;'}

  td{
    ${p => p.isDeleted && 'text-decoration:line-through'}
  }
`

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
  padding: 10px;

  span{
    cursor: auto;
  }

  .icon-label-list-vision{
    justify-content: center;
  }

  .material-field{
    text-align: -webkit-center;
    text-align: -moz-center;
  }
`

export const TableRow = styled.tr`
  height: 55px;
  font-size: 0.8rem;
  background: var(--color-contrast-white);
  padding: 5px 0;
  min-height: 60px;
  max-height: 60px;
  padding-left: 4px;
  border-bottom: 1px dashed var(--color-neutral-300);
  position: sticky;
  top: -1px;
  z-index: 1;
  &:hover{
    background-color: #F4F5F8;
  }
  
`

export const TableHeader = styled.th`
  padding: 8px;
  text-align: center;
  margin: 0px;
  font-size: 0.7rem;
  color: #9ba2ac;
  background: var(--color-contrast-white);
  border-bottom: 1px dashed var(--color-neutral-300);
  text-transform: uppercase;
  font-weight: var(--font-weight-medium);
`

export const TableData = styled.td`
  padding: 3px;
  text-align: center;
  vertical-align: middle;

  .red-background{
  background-color : var(--color-feedback-danger-100);
  }

  .yellow-background {
    background-color: var(--color-feedback-warning-100);
  }

  ${props => props.displayTableCell && 'display: table-cell;'}
  ${props => props.disabled && 'pointer-events: none;'}
`
export const IconAdd = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
