import { getTodayDate } from "utils/date";

export const mapperDataDtoRequestSave = (data, labeledFamilyId) => {
  let normalizedData = [];

  data.factories.forEach(factory => {
    factory.Data.forEach(_data => {
      _data.entry.forEach(x => {
        normalizedData.push({
          "Date": _data.Date,
          "BusinessUnitId": _data.BusinessUnitId,
          "SupplierId": x.SupplierId,
          "SupplierName": x.SupplierName,
          "LabeledFamilyId": labeledFamilyId,
          "BreweryMerchandiseEntry": x.outgoing,
          "BreweryInitialStock": _data.BreweryInitialStock,
          "BreweryFinalStock": _data.BreweryFinalStock,
          "Necessity": _data.Necessity,
          "RealProduction": _data.RealProduction,
          "WeeklyPlan": _data.HectoliterPerThousand > 0 ? _data.WeeklyPlan / _data.HectoliterPerThousand: _data.WeeklyPlan
        })
      })
    })
  })

  data.suppliers.forEach(supplier => {
    supplier.Data.forEach(_data => {
      _data.outgoingData.forEach(x => {
        const idx = normalizedData.findIndex(y =>
          y.BusinessUnitId === x.BusinessUnitId
          && y.Date === _data.Date
          && y.SupplierId === _data.SupplierId
          && y.SupplierName === _data.SupplierName
        )
        normalizedData[idx] = {
          ...normalizedData[idx],
          "OutgoingMerchandiseSupplier": x.outgoing,
          "SupplierInitialStock": _data.SupplierInitialStock,
          "SupplierFinalStock": _data.SupplierFinalStock,
          "SupplierProduction": _data.SupplierProduction
        }
      })
    })
  })
  return {
    Items: normalizedData.filter(x => (new Date(x.Date)) >= getTodayDate())
  }
}
