import React from "react";

export const CheckCircle = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24}>
    <defs>
      <style>{".b{fill:var(--color-action-default)}"}</style>
    </defs>
    <path
      d="M0 0h24v24H0z"
      style={{
        fill: "none",
      }}
    />
    <path
      d="M11.042 22a9 9 0 0 1 0-18v2a7 7 0 1 0 7 7h2a9.01 9.01 0 0 1-9 9Z"
      className="b"
    />
    <path
      d="m12.134 16.86-4.976-4.976 1.767-1.768 3.024 3.024 8.125-9.932 1.935 1.584Z"
      className="b"
    />
  </svg>
);




