import StorePersist from 'utils/store-persist'

export const filtersClearAllFilters = () => {
  StorePersist.remove('filterMyItemsOnly')
  StorePersist.remove('filtersTasks')
  StorePersist.remove('filters')
  StorePersist.remove('filtersStocks')
  StorePersist.remove('filtersStocksMulti')
  StorePersist.remove('filtersDataEstoque')
  StorePersist.remove('filterSchedule')
  StorePersist.remove('filtersComposition')
  StorePersist.remove('filtersFamiliaRotuladaCentro')
  StorePersist.remove('filtersFamiliaRotuladaCentroMaterial')
  StorePersist.remove('filtersLabeledFamily')
  StorePersist.remove('filtersUsuario')
  StorePersist.remove('filtersFornecedor')
  StorePersist.remove('filtersUsers')
  StorePersist.remove('filtersMaterial')
  StorePersist.remove('filtersProjects')
  StorePersist.remove('filtersOptimization')
  StorePersist.remove('filtersOptimizationHistory')
  StorePersist.remove('tipoVisualizacao')
  StorePersist.remove('tipoVisualizacaoEstoque')
  StorePersist.remove('filtersGradeDescarga')
  StorePersist.remove('filtersGradeCarregamento')
  StorePersist.remove('columnConfiguration')
  StorePersist.remove('columnConfigurationItensCriticos');
  StorePersist.remove('columnPrimaryMaterialConfiguration');
  StorePersist.remove('settings')
  StorePersist.remove('filterChart')
  StorePersist.remove('filtersTasksMulti')
  StorePersist.remove("ValidationToken")
  StorePersist.remove("flags")
  StorePersist.remove('realm')
  StorePersist.remove('url')
}

export const filtersClearChangeMyItemsOnly = () => {
  StorePersist.remove('filtersTasks')
  StorePersist.remove('filterSchedule')
  StorePersist.remove('filters')
  StorePersist.remove('filtersStocks')
  StorePersist.remove('filtersComposition')
  StorePersist.remove('filtersTasksMulti')
}
