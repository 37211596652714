import PropTypes from "prop-types"
import React from 'react'
import { CardContent } from '../card-content'
import pedidoPacoteIcon from 'images/pedido-pacote.png'
import pedidoAguardoIcon from 'images/pedido-aguardo.png'
import pedidoEntregaIcon from 'images/pedido-entrega.png'
import pedidoEmpty from 'images/pedido-empty.png'
import StyledCardHeader from '../styled-card-header'

function updateStatusTask(status) {
  switch (status) {
    case 0:
      return pedidoPacoteIcon
    case 1:
      return pedidoAguardoIcon
    case 2:
      return pedidoEntregaIcon
    case 3:
      return pedidoEmpty
    default:
      return pedidoEmpty
  }
}

function updateStatusPriority(criticidade) {
  switch (criticidade) {
    case 0:
      return {
        colorStatus: '#ff5e53',
        colorStatusArrow: '#c64138'
      }
    case 1:
      return {
        colorStatus: '#fbba49',
        colorStatusArrow: '#c3913b'
      }
    case 2:
      return {
        colorStatus: '#9bc73b',
        colorStatusArrow: '#86ac32'
      }
    default:
      return {
        colorStatus: '#2870b2',
        colorStatusArrow: '#1e5281'
      }
  }
}

const HeaderCardStocksDetail = ({
  showIcon,
  title,
  subtitle,
  status,
  criticidade,
  customOptions,
  programado
}) => {
  const {
    colorStatus,
    colorStatusArrow,
  } = updateStatusPriority(criticidade)

  const icon = updateStatusTask(status)

  return (
    <StyledCardHeader className="Card-header">
      <CardContent
        colorStatus={colorStatus}
        colorStatusArrow={colorStatusArrow}
        customOptions={customOptions}
        showIcon={showIcon}
        title={title}
        subtitle={subtitle}
        programado={programado}
        icon={icon}
      />
    </StyledCardHeader>
  )
}

HeaderCardStocksDetail.propTypes = {
  criticidade: PropTypes.any.isRequired,
  customOptions: PropTypes.any.isRequired,
  programado: PropTypes.any.isRequired,
  showIcon: PropTypes.any.isRequired,
  status: PropTypes.any.isRequired,
  subtitle: PropTypes.any.isRequired,
  title: PropTypes.any.isRequired
}

export default HeaderCardStocksDetail
