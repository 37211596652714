import PropTypes from "prop-types"
import { useMemo, useState } from 'react'
import Dialog from 'material-ui/Dialog';
import intl from 'react-intl-universal'
import { Button } from "components/uikit-adapter/index"
import { useGlobalContext } from 'hooks/useGlobalContext';
import ModalFeedback from 'components/modal-feedback'
import Loading from "components/center-loading";
import HeaderForm from 'pages/composicao-carga/components/header-form';
import { formatErrorMessage } from 'utils/handle-error';
import { buildEmergencyRenegotiateDto, generateNewItem, getHasEditRenegotiation } from './utils';
import { ModalReviewEmergencyRenegotiate } from './modal-review-emergency-renegotiate';
import { TitleModalFeedback } from './title-modal-feedback';
import { createEmergencyNegotiation } from 'pages/load-composition-building/load-composition-building.service';
import { validateDate } from './validations';
import { OrderListEditable } from './order-list-editable';
import { ClbButtonIcon } from '@celebration/design-system-react';

export const EmergencyRenegotiate = ({
  open,
  composicao,
  unidadesNegocio,
  fornecedores,
  SituacaoValor,
  pedidos,
  handleCloseAll
}) => {
  const [loadComposition, setLoadComposition] = useState(composicao);
  const [orders, setOrders] = useState([...pedidos]);
  const ordersCached = useMemo(() => pedidos.map(x => ({ ...x })), [])
  const [isLoading, setIsLoading] = useState(false);
  const [modalFeedback, setModalFeedback] = useState({
    isOpen: false,
    title: <TitleModalFeedback idLoadComposition={loadComposition.IdComposicao} />,
    message: intl.get("composition.actions.successfullySendEmergencyNegotiate")
  })
  const [openModalReviewEmergencyRenegotiate, setOpenModalReviewEmergencyRenegotiate] = useState(false)
  const { showFeedback } = useGlobalContext()
  const handleCloseModalFeedback = () => {
    setModalFeedback(prevState => ({
      ...prevState,
      isOpen: false
    }));
    handleCloseAll()
  }

  const handleChange = (field, value) => {
    setLoadComposition(prevState => ({
      ...prevState,
      [field]: value
    }))
  }

  const findIndexOrderById = (id) => {
    const _orderIdx = orders.findIndex(x => x.IdPedido == id);
    if (_orderIdx > -1) {
      return _orderIdx
    } else {
      return orders.findIndex(x => x.IdCard == id);
    }
  }

  const findIndexCachedOrderById = (id) => {
    const _orderIdx = ordersCached.findIndex(x => x.IdPedido == id);
    if (_orderIdx > -1) {
      return _orderIdx
    } else {
      return ordersCached.findIndex(x => x.IdCard == id);
    }
  }

  const handleChangeOrder = (field, value, id) => {
    const _orderIdx = findIndexOrderById(id);
    setOrders(prevState => {
      const _orders = [...prevState];
      _orders[_orderIdx][field] = value;
      _orders[_orderIdx]["IsEdited"] = true;
      return [..._orders];
    })
  }

  const handleRefreshOrderValue = (id) => {
    const _orderIdx = findIndexOrderById(id);
    const _orderInitialValue = ordersCached[findIndexCachedOrderById(id)];
    setOrders(prevState => {
      prevState[_orderIdx] = { ..._orderInitialValue };
      return [...prevState];
    });
  }

  const addNewOrder = () => {
    setOrders(prevState => (
      [...prevState, generateNewItem()]
    ))
  }

  const handleSend = async () => {
    setIsLoading(true);
    try {
      const dto = buildEmergencyRenegotiateDto(loadComposition, orders);
      await createEmergencyNegotiation(dto);
      setModalFeedback(prevState => ({ ...prevState, isOpen: true }))
    }
    catch (err) {
      showFeedback(formatErrorMessage(err))
    }
    setIsLoading(false);
  }

  const handleOpenModalReviewEmergencyRenegotiate = () => {
    const hasUpdates = getHasEditRenegotiation(orders, ordersCached, loadComposition, composicao)
    if (!hasUpdates) {
      showFeedback(intl.get('commons.noChange'));
      return;
    }
    const validated = validateDate(loadComposition, orders)
    if (validated) {
      showFeedback(validated)
      return
    }

    setOpenModalReviewEmergencyRenegotiate(true);
  }

  const removeNewOrder = (id) =>
    setOrders(prevState => prevState.filter(x => x.IdCard !== id))

  return (
    <>
      <Dialog
        title={`${intl.get('menu.subMenuGeneral.loadComposition')} ${loadComposition.IdComposicao}`}
        contentStyle={{ width: '98%', maxWidth: 'none' }}
        open={open}
        onRequestClose={handleCloseAll}
        actionsContainerClassName="container-action-buttons__composition"
        actions={
          <>
            <ClbButtonIcon
              className='btn-close-modal'
              onClick={handleCloseAll}
              icon='Close'
            />
            <Button
              type="primary"
              value={intl.get("composition.actions.sendEmergencyNegotiate")}
              onClick={handleOpenModalReviewEmergencyRenegotiate}
              className="button__composicao-carga_emergency_negotiation"
            />
            <Button
              type="default"
              value={intl.get("composition.actions.cancelEmergencyNegotiate")}
              onClick={handleCloseAll}
              className="button__composicao-carga_emergency_negotiation"
            />
          </>
        }
      >
        <div style={{ zIndex: 2, position: 'relative' }}>
          <HeaderForm
            composicao={loadComposition}
            unidadesNegocio={unidadesNegocio}
            fornecedores={fornecedores}
            SituacaoValor={SituacaoValor}
            handleChangeDate={handleChange}
            handleChangeNote={e => handleChange("Observacao", e.target.value)}
            modoEdicao
            modoLeitura
            allowChangingDates
            showNote
            hideObs
          />
        </div>
        {orders.length > 0 &&
          <OrderListEditable
            orders={orders}
            handleChange={handleChangeOrder}
            handleRefreshOrderValue={handleRefreshOrderValue}
            addNewOrder={addNewOrder}
            removeNewOrder={removeNewOrder}
            loadComposition={loadComposition}
          />
        }

        <Loading isLoading={isLoading} fullHeightParent />
      </Dialog>
      <ModalReviewEmergencyRenegotiate
        open={openModalReviewEmergencyRenegotiate}
        loadComposition={loadComposition}
        handleSend={handleSend}
        handleClose={() => setOpenModalReviewEmergencyRenegotiate(false)}
        isLoading={isLoading}
        orders={orders}
        ordersCached={ordersCached}
      />
      <ModalFeedback
        message={modalFeedback.message}
        open={modalFeedback.isOpen}
        title={modalFeedback.title}
        handleClose={handleCloseModalFeedback}
      />
    </>
  )
}

EmergencyRenegotiate.propTypes = {
  SituacaoValor: PropTypes.any.isRequired,
  composicao: PropTypes.any.isRequired,
  fornecedores: PropTypes.any.isRequired,
  handleCloseAll: PropTypes.func.isRequired,
  open: PropTypes.any.isRequired,
  pedidos: PropTypes.shape({
    map: PropTypes.func
  }).isRequired,
  unidadesNegocio: PropTypes.any.isRequired
}
