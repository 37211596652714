import React from 'react'
import PropTypes from 'prop-types'
import { EstoqueStatus } from 'constants/index'

const StatusSpan = ({ priority, label }) => (
  <span style={EstoqueStatus[priority]}>
    {label}
  </span>
)

StatusSpan.propTypes = {
  priority: PropTypes.oneOf([0, 1, 2, 3]).isRequired,
  label: PropTypes.string
}

StatusSpan.defaultProps = {
  priority: 1
}

export default StatusSpan
