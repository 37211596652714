import PropTypes from 'prop-types'
import styled from 'styled-components'

export const StyledHeaderOptimization = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  
  .etb-btn {
    width: 15px;
    height: 15px;
    border: none;
    cursor: pointer;
    background-color: Transparent;
  }
  
  .etb-btn:active{
    border: none;
  }
  
  .etb-btn:focus {
    outline:0;
  }

  .etb-values {
    width: 100%;
    display: flex;

    .etb-column {
      color: var(--color-contrast-black);
    }
  }

  .etb-column-optimization {
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 4px;
    font-size: 0.7rem;
  }
`;

StyledHeaderOptimization.propTypes = {
  showCheckbox: PropTypes.bool,
}

