import { SchedulingAutoPilotGeneralDto } from "types/labeled-family-center/auto-pilot/scheduling-auto-pilot-general-dto"
import intl from 'react-intl-universal'

export const getOptionsDaysOfWeek = (data: SchedulingAutoPilotGeneralDto) => [
  {
    key: 1,
    label: intl.get("weekdays.sunday"),
    value: '1',
    isSelected: data.DaysOfWeekEnable[0]
  },
  {
    key: 2,
    label: intl.get("weekdays.monday"),
    value: '2',
    isSelected: data.DaysOfWeekEnable[1]
  },
  {
    key: 3,
    label: intl.get("weekdays.tuesday"),
    value: '3',
    isSelected: data.DaysOfWeekEnable[2]
  },
  {
    key: 4,
    label: intl.get("weekdays.wednesday"),
    value: '4',
    isSelected: data.DaysOfWeekEnable[3]
  },
  {
    key: 5,
    label: intl.get("weekdays.thursday"),
    value: '5',
    isSelected: data.DaysOfWeekEnable[4]
  },
  {
    key: 6,
    label: intl.get("weekdays.friday"),
    value: '6',
    isSelected: data.DaysOfWeekEnable[5]
  },
  {
    key: 7,
    label: intl.get("weekdays.saturday"),
    value: '7',
    isSelected: data.DaysOfWeekEnable[6]
  }
]

export const orderCutTypeOptions = () => [
  {
    key: 1,
    label: intl.get('master-data.general.auto-pilot.Daily'),
    value: "1",
    disabled: false
  },
  {
    key: 2,
    label: intl.get('master-data.general.auto-pilot.Weekly'),
    value: "2",
    disabled: false
  }
];