import styled from 'styled-components'

interface IStyledTitleHeader {
  color?: string,
  width?: number,
  noBackground?: boolean
}
const StyledTitleHeader = styled.h1<IStyledTitleHeader>`
  font-size: 30px;
  font-weight: var(--font-weight-semibold);
  line-height: 0.77;
  text-align: left;
  color: ${props => props.color ? props.color : 'var(--color-contrast-white)'};
  width: ${props => props.width ? props.width : 355}px;
  margin-top: auto;
  margin-bottom: auto;

  ${props => props.noBackground ? 'color: var(--Contrast-Brand, #3B495E);' : ''};
`

export default StyledTitleHeader
