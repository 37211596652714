import PropTypes from "prop-types"
import { createContext, useContext, useState } from "react";
import { initialFilterLoadBuilding } from "./utils";

const LoadBuildingContext = createContext();

const LoadBuildingProvider = ({ children }) => {
  const [filter, setFilter] = useState(initialFilterLoadBuilding);
  const [quantityRegisters, setQuantityRegisters] = useState(0);
  const [volumesSelected, setVolumesSelected] = useState([])
  const [openFlowBuild, setOpenFlowBuild] = useState(false);
  const [businessUnitsRegisters, setBusinessUnitsRegisters] = useState([]);
  const [loadsSending, setLoadsSending] = useState([]);
  const [loadsOptimized, setLoadsOptimized] = useState({});

  const handleDelete = (index, label) => {
    const _data = loadsOptimized[label];
    const _newData = _data.filter((_, i) => i != index);

    setLoadsOptimized(previousValue => ({
      ...previousValue,
      [label]: _newData
    }))
  }

  return (
    <LoadBuildingContext.Provider
      value={{
        filter,
        setFilter,
        quantityRegisters,
        setQuantityRegisters,
        volumesSelected,
        setVolumesSelected,
        openFlowBuild,
        setOpenFlowBuild,
        businessUnitsRegisters,
        setBusinessUnitsRegisters,
        loadsSending,
        setLoadsSending,
        loadsOptimized,
        setLoadsOptimized,
        handleDelete
      }}
    >
      {children}
    </LoadBuildingContext.Provider>
  )
}

LoadBuildingProvider.propTypes = {
  children: PropTypes.any.isRequired
}

export const useLoadBuildingContext = () => useContext(LoadBuildingContext);

export { LoadBuildingProvider, LoadBuildingContext };