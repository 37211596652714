const colorPickers = {
  cardVioleta: {
    ColorStatus: '#673ab7',
    ColorStatusArrow: '#512da8'
  },
  cardLilas: {
    ColorStatus: '#9c27b0',
    ColorStatusArrow: '#7b1fa2'
  },
  cardAzul: {
    ColorStatus: '#6197C6',
    ColorStatusArrow: '#2870B2'
  },
  cardAzulEscuro: {
    ColorStatus: '#122273',
    ColorStatusArrow: '#0c1852'
  },
  cardAzulPetroleo: {
    ColorStatus: '#607d8b',
    ColorStatusArrow: '#455a64'
  },
  cardMarron: {
    ColorStatus: '#795548',
    ColorStatusArrow: '#5d4037'
  },
  cardVermelhoFarol: {
    ColorStatus: '#ff5e53',
    ColorStatusArrow: '#c64138'
  },
  cardVermelhoAr: {
    ColorStatus: '#DE4246',
    ColorStatusArrow: '#DE4246'
  },
  cardLaranjaForte: {
    ColorStatus: '#d35400',
    ColorStatusArrow: '#d35a05',
  },
  cardLaranjaMedio: {
    ColorStatus: '#ff6900',
    ColorStatusArrow: '#ff6900',
  },
  cardAmareloFarol: {
    ColorStatus: '#fbba49',
    ColorStatusArrow: '#c3913b'
  },
  cardAmarelo: {
    ColorStatus: '#EBBA3E',
    ColorStatusArrow: '#EBBA3E'
  },
  cardVerdeFarol: {
    ColorStatus: '#9bc73b',
    ColorStatusArrow: '#86ac32'
  },
  cardVerdeGrama: {
    ColorStatus: '#689f38',
    ColorStatusArrow: '#33691e'
  },
  cardAmareloVivo: {
    ColorStatus: '#b3b300',
    ColorStatusArrow: '#808000'
  },
  collorButtom: '#2870B2',
  release: {
    title: '#3b495e',
    subTitle: '#496072',
  },
  blueSecond: '#2870b2'
}
export default colorPickers
