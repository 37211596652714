import Fetch from "utils/fetch"
import Session from "utils/user-storage"
import { generateQueryList, convertToQueryParameters } from "../../utils/custom-functions"
import { builderLoadOrderByIdDto, builderLoadsSuggestionsByIdDto } from "./utils/builder"

export const createSuggestion = (model) => {
  return Fetch.post(`sugestoes/CriarSugestao`, model).then(({ data }) => data)
}

export const editSuggestion = (model) => {
  return Fetch.put(`sugestoes/Editar`, model).then(({ data }) => data)
}

export const deleteSuggestions = (idFamiliaRotuladaCentro, sugestoes) => {
  return Fetch.post(`/sugestoes/excluirSugestao/${idFamiliaRotuladaCentro}`, { Sugestoes: sugestoes });
}

export const deleteOrder = (idFamiliaRotulada, pedido) => {
  const model = {
    ...pedido,
    IdPedido: pedido.Id,
    IdUsuario: Session.get().Id,
    ForcedCancel: true
  }
  return Fetch.put(`/pedido/cancelar/${idFamiliaRotulada}`, model);
}

export const editOrder = (model) => {
  return Fetch.post(`/timeline/proposta/editarProposta/comprador`, model);
}

export const getCardInfo = (identificador) => {
  return identificador.name === "IdSugestao" ?
    Fetch.get(`/sugestoes/ObterSugestaoPorId/${identificador.value}`).then(({ data }) => data) :
    Fetch.get(`/pedido/${identificador.value}`).then(({ data }) => data)
}

export const getOrderCardById = (orderId) => Fetch.get(`/pedido/${orderId}`).then(({ data }) => data)

export const loadMaterialsWithContract = (idUnidadeNegocio) => {
  return Fetch.get(`/material/ObterMateriaisComContratoAtivo/${idUnidadeNegocio}`).then(({ data }) => data)
}

export const getSupplierInternalExternalByMaterial = (materialId, materialNumber, businessUnitId) => (
  Fetch.get(`suppliers/get-internal-external?MaterialId=${materialId}&MaterialNumber=${materialNumber}&BusinessUnitId=${businessUnitId}`).then(({ data }) => data)
)

export const buscarFornecedores = () => {
  return Fetch.get(`/fornecedor/buscaFornecedores`).then(({ data }) => data)
}

export const getData = (dtoFilter) => {
  const status = generateQueryList(dtoFilter.Status, "StatusSugestoes");
  const destino = `&IdUnidadeNegocio=${dtoFilter.DestinoId}`
  const fornecedor = dtoFilter.IdFornecedor ? `&IdFornecedor=${dtoFilter.IdFornecedor}` : ''
  const dataEntrega = dtoFilter.DataEntrega ? `&DataSugestoes=${dtoFilter.DataEntrega}` : ''
  const material = dtoFilter.NumeroMaterial ? `&NumeroMaterial=${dtoFilter.NumeroMaterial}` : ''
  const suggestion = dtoFilter.SuggestionId ? `&SuggestionId=${dtoFilter.SuggestionId}` : ''
  const carteira = dtoFilter.IdCarteira ? `&IdCarteira=${dtoFilter.IdCarteira}` : ''
  const initialDeliveryDate = dtoFilter.InitialDeliveryDate ? `&InitialDeliveryDate=${dtoFilter.InitialDeliveryDate}` : ''
  const doorDescription = dtoFilter.doorDescription ? `&DoorDescription=${dtoFilter.doorDescription}` : '';
  const paginacao = `&Page=${dtoFilter.pagination.page}&Take=${dtoFilter.pagination.take}`;
  const queryFilters = `?${status}${destino}${suggestion}${dataEntrega}${initialDeliveryDate}${material}${carteira}${paginacao}${fornecedor}${doorDescription}`
  return Fetch.get(`/suggestions:cards-load-composition${queryFilters}`)
    .then(({ data }) => formatData(data))
}

export const getReleased = (dtoFilter) => {
  const status = generateQueryList(dtoFilter.Status, "StatusPedido");
  const destino = `&IdUnidadeNegocio=${dtoFilter.DestinoId}`
  const composicao = `&IdComposicao=${dtoFilter.IdComposicao}`
  const fornecedor = dtoFilter.IdFornecedor ? `&IdFornecedor=${dtoFilter.IdFornecedor}` : ''
  const material = dtoFilter.NumeroMaterial ? `&NumeroMaterial=${dtoFilter.NumeroMaterial}` : ''
  const pedido = dtoFilter.IdPedido ? `&IdPedido=${dtoFilter.IdPedido}` : ''
  const carteira = dtoFilter.IdCarteira ? `&IdCarteira=${dtoFilter.IdCarteira}` : ''
  const doorDescription = dtoFilter.doorDescription ? `&DoorDescription=${dtoFilter.doorDescription}` : '';
  const paginacao = `&Page=${dtoFilter.pagination.page}&Take=${dtoFilter.pagination.take}`;
  const queryFilters = `?${status}${destino}${pedido}${material}${fornecedor}${carteira}${paginacao}${dtoFilter.IdComposicao ? composicao : ''}${doorDescription}`
  return Fetch.get(`/orders:cards-load-composition${queryFilters}`)
    .then(({ data }) => formatReleasedData(data))
}

export const criarComposicao = (model) => {
  return Fetch.post(`/load-compositions:create-by-suggestions-and-orders`, model)
    .then(({ data }) => data)
}

export const editarComposicao = (loadCompositionId, model) => {
  return Fetch.post(`/load-compositions/${loadCompositionId}:change-with-suggestions-and-orders`, model)
    .then(({ data }) => data)
}

export const formatReleasedData = (data) => {
  return {
    ...data,
    Pedidos: normalizeIdsPedidos(data.Pedidos)
  }
}

export const formatData = (data) => {
  return {
    ...data,
    Sugestoes: data.Sugestoes.map(sugestao => ({ ...sugestao, IdCard: sugestao.IdSugestao + 'S' }))
  }
}

export const getMateriais = (model) => {
  return Fetch.get(`/materials/for-filters?${convertToQueryParameters(model)}`).then(({ data }) => data)
}

export const getWallets = () => {
  return Fetch.get(`/material-groups:all`).then(({ data }) => data)
}

export const getDoors = (businessUnitId) => {
  return Fetch.get(`/business-units/${businessUnitId}/doors`).then(({ data }) => data)
}

export const fetchUpdateDate = (loadCompositionId, dto) => (
  Fetch.post(`/load-compositions/${loadCompositionId}:change-dates`, dto)
    .then(({ data }) => data)
)

export const fetchDesvincularPedidos = (loadCompositionId, dto) => (
  Fetch.post(`/load-compositions/${loadCompositionId}:unlink-orders`, dto)
    .then(({ data }) => data)
)

export const normalizeIdsPedidos = (_data) => _data.map(pedido => ({ ...pedido, IdCard: pedido.IdPedido + 'P' }))

export const getItemForComposition = async (orderId, suggestionId, params) => {
  if (orderId) {
    return (await getReleased(builderLoadOrderByIdDto(orderId, params))).Pedidos
  } else if (suggestionId) {
    return (await getData(builderLoadsSuggestionsByIdDto(suggestionId, params))).Sugestoes
  }
}

export const cancelLoadComposition = async (url, body) => {
  return await Fetch.post(url, body);
}

export const createEmergencyNegotiation = (dto) => (
  Fetch.post('/load-compositions/negotiations:create-emergency', dto).then(({ data }) => data)
)