import React from 'react'
import intl from 'react-intl-universal'
import Toggle from 'react-toggle'
import { useBottlesContext } from '../../context'
import * as S from './styled'

export const BottleToggle = () => {
  const { isEditMode, setIsEditMode, setFilter } = useBottlesContext()

  const handleToggle = () => {
    setIsEditMode(!isEditMode)
    setFilter(prevState => ({...prevState}))
  } 
  return (
    <React.Fragment>
      <S.ToggleWrapper>
        <p className={isEditMode ? "notActive" : "active"}>{intl.get("bottles.viewMode")}</p>
        <S.ToggleStyle>
          <label>
            <Toggle
              defaultChecked={isEditMode}
              onChange={handleToggle}
              icons={false}
              aria-labelledby='aria'
              data-testid="bottle-toggle"
            />
          </label>
        </S.ToggleStyle>
        <p className={isEditMode ? "active" : "notActive"}>{intl.get("bottles.editMode")}</p>
      </S.ToggleWrapper>
    </React.Fragment>
  )
}
