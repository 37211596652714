import { ILabeledFamilyCenterMaterialResponse, IMaterialsByBusinessUnitAndLabeledFamilyFilter } from "types/labeled-family-center-materials/labeled-family-center-materials-response";
import { IOption } from "types/option";
import { generateQueryParams } from "utils/custom-functions";
import Fetch from "utils/fetch";

export const getDiscontinuedJustifications = (): Promise<Array<IOption>> => 
  Fetch.get('/labeled-family-center-materials:discontinued-justifications').then(({ data }) => data);

export const exportLabeledFamilyCenterMaterials = () => Fetch.get('/labeled-family-center-materials:export').then(({ data }) => data);

export const getLabeledFamilyCenterMaterialById = (id: number): Promise<ILabeledFamilyCenterMaterialResponse> => 
  Fetch.get(`/labeled-family-center-materials/${id}`).then(({ data }) => data);

export const getMaterialsByBusinessUnitAndLabeledFamily = (filters: IMaterialsByBusinessUnitAndLabeledFamilyFilter) => 
  Fetch.get(`/labeled-family-center-materials:by-business-unit-and-labeled-family?${generateQueryParams(filters)}`).then(({ data }) => data);

export const saveLabeledFamilyCenterMaterials = (body: ILabeledFamilyCenterMaterialResponse) => 
  Fetch.post(`/labeled-family-center-materials`, body);