import PropTypes from 'prop-types'
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
  TableFooter
} from 'components/uikit-adapter/index'
import PaginationFooter from 'components/pagination/components/pagination-footer'
import TableRownColumnUnclickable from 'components/table-row-column-unclickable'
import DeleteIcon from 'images/icn-trash-bin.svg'
import IconButton from 'material-ui/IconButton'
import intl from 'react-intl-universal'

const SupplierList = ({ data, countData, refreshSearch, handleEdit, qtRegsPerPage, handleDelete, profileAdminOrMasterData }) => {
  const formatSupplierCode = (supplierCode) => isNaN(supplierCode) ? supplierCode : parseInt(supplierCode);

  return (
    <Table
      fixedHeader
      fixedFooter
      multiSelectable={false}
      selectable={false}
      wrapperStyle={{ height: '100%' }}
      bodyStyle={{ height: 'calc(100% - 110px)', borderTop: '1px solid rgb(224, 224, 224)', backgroundColor: 'var(--color-contrast-white)' }}
      onCellClick={handleEdit}
    >
      <TableHeader
        displaySelectAll={false}
        adjustForCheckbox={false}
        style={{ borderBottom: 'none' }}>
        <TableRow style={{ borderBottom: 'none' }}>
          <TableHeaderColumn>{intl.get('master-data.general-configuration.codSupplierSAP')}</TableHeaderColumn>
          <TableHeaderColumn>{intl.get('master-data.general-configuration.factory')}</TableHeaderColumn>
          <TableHeaderColumn>{intl.get('master-data.general-configuration.supplierName')}</TableHeaderColumn>
          <TableHeaderColumn>{intl.get('master-data.general-configuration.supplierCNPJ')}</TableHeaderColumn>
          <TableHeaderColumn>{intl.get('commons.user')}</TableHeaderColumn>
          <TableHeaderColumn style={{ width: '100px' }} />
        </TableRow>
      </TableHeader>
      <TableBody
        displayRowCheckbox={false}
        showRowHover
      >
        {data.map((v, i) => (
          <TableRow key={i} style={{ cursor: 'pointer' }}>
              {v.HasAuroraBusinessUnities ? (
                  <TableRowColumn>
                    {v.SupplierCodeMdg} <br></br> 
                    <span style={{color: '#666'}}><i>{intl.get('master-data.general-configuration.legacy')}: {formatSupplierCode(v.SupplierCode)}</i></span>
                  </TableRowColumn>
                ) : (
                  <TableRowColumn>
                    {formatSupplierCode(v.SupplierCode)}
                  </TableRowColumn>
                )}
            <TableRowColumn>
              {v.SupplierNickName}
            </TableRowColumn>
            <TableRowColumn>
              {v.SupplierCompanyName}
            </TableRowColumn>
            <TableRowColumn>
              {v.SupplierCnpj}
            </TableRowColumn>
            <TableRowColumn>
              {v.UserName}
            </TableRowColumn>
            <TableRownColumnUnclickable style={{ positon: 'relative', textAlign: 'right', width: '100px' }}>
              {profileAdminOrMasterData() &&
                <div title={intl.get('master-data.suppliers.ableSupplier.actions.disabledSupplier')} >
                  <IconButton onClick={() => handleDelete(v.SupplierId)} >
                    <img src={DeleteIcon} />
                  </IconButton>
                </div>
              }
            </TableRownColumnUnclickable>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <PaginationFooter
          countPerPage={qtRegsPerPage}
          countData={countData}
          refreshSearch={refreshSearch}
        />
      </TableFooter>
    </Table>
  )
}

SupplierList.propTypes = {
  countData: PropTypes.number.isRequired,
  data: PropTypes.shape({
    map: PropTypes.func
  }).isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  profileAdminOrMasterData: PropTypes.func.isRequired,
  qtRegsPerPage: PropTypes.number.isRequired,
  refreshSearch: PropTypes.func.isRequired
}

export default SupplierList
