const IconDeliveryTruckWithBackground = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={50} height={50}>
    <g data-name="Grupo 39003" transform="translate(-.229 -.23)">
      <circle
        cx={25}
        cy={25}
        r={25}
        fill="var(--color-neutral-200)"
        data-name="Elipse 51"
        transform="translate(.229 .23)"
      />
      <g data-name="Grupo 38542">
        <g fill="var(--color-action-default)" data-name="Grupo 38541">
          <path
            stroke="var(--color-action-default)"
            strokeWidth={0.2}
            d="m41.544 27.678-1.66-2.563a3.953 3.953 0 0 0-3.333-1.808h-4.294v-2.493a2.75 2.75 0 0 0-2.735-2.759h-8.037a5.1 5.1 0 0 0-10.112.969 5.158 5.158 0 0 0 2.276 4.283H9.351a.525.525 0 0 0 0 1.051h5.206v1.05h-2.083a.525.525 0 0 0 0 1.05h2.083v1.05H9.351a.525.525 0 0 0 0 1.051h5.206v2.326a2.635 2.635 0 0 0-1.562 2.407v.518a.523.523 0 0 0 .521.525h4.614a2.87 2.87 0 0 0 5.6 0h10.021a2.87 2.87 0 0 0 5.6 0h1.754a.523.523 0 0 0 .52-.525v-5.845a.529.529 0 0 0-.085-.287Zm-29.131-8.654a4.062 4.062 0 1 1 4.062 4.1 4.085 4.085 0 0 1-4.062-4.1Zm3.185 9.535h1.041a.525.525 0 0 0 0-1.051h-1.041v-1.05h3.644a.525.525 0 0 0 0-1.05h-3.644v-1.051h6.768a.525.525 0 0 0 0-1.051h-3.065a5.156 5.156 0 0 0 2.274-4.2h7.948a1.7 1.7 0 0 1 1.694 1.709v9.845H15.598Zm15.619 4.727h-7.434a2.9 2.9 0 0 0-.74-1.576h8.174ZM15.604 31.71h3.226a2.9 2.9 0 0 0-.74 1.576h-4.054a1.577 1.577 0 0 1 1.568-1.576Zm5.326 3.827a1.853 1.853 0 1 1 1.837-1.853 1.847 1.847 0 0 1-1.837 1.853Zm15.619 0a1.853 1.853 0 1 1 1.837-1.853 1.847 1.847 0 0 1-1.833 1.853Zm4.035-2.247h-1.183a2.871 2.871 0 0 0-5.7 0h-1.444v-8.933h4.294a2.919 2.919 0 0 1 2.461 1.332l1.575 2.432Zm0 0"
            data-name="Caminho 2139"
          />
          <path
            d="M37.374 33.684a.858.858 0 1 1-.858-.858.858.858 0 0 1 .858.858Zm0 0"
            data-name="Caminho 2140"
          />
          <path
            d="M21.793 33.684a.858.858 0 1 1-.858-.858.858.858 0 0 1 .858.858Zm0 0"
            data-name="Caminho 2141"
          />
          <path
            d="M17.173 19.016v-2.294a.524.524 0 0 0-1.049 0v2.123l-1.07 1.468a.524.524 0 1 0 .847.618l1.171-1.606a.525.525 0 0 0 .1-.309Zm0 0"
            data-name="Caminho 2142"
          />
          <path
            d="M36.339 24.887h-1a.524.524 0 0 0-.525.525v3.67a.524.524 0 0 0 .525.525h3.529a.911.911 0 0 0 .791-1.362l-1.225-2.143a2.416 2.416 0 0 0-2.095-1.215Zm-.479 3.671v-2.622h.479a1.364 1.364 0 0 1 1.182.686l1.106 1.936Zm0 0"
            data-name="Caminho 2143"
          />
        </g>
      </g>
    </g>
  </svg>
)
export default IconDeliveryTruckWithBackground;
