import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Dialog from "material-ui/Dialog"
import * as S from './styled'
import Session from "utils/user-storage";
import { getPedidos } from "pages/load-composition/load-composition.service"
import { useGlobalContext } from 'hooks/useGlobalContext';
import { formatErrorMessage } from "utils/handle-error"
import { formatWithoutSpaces } from "utils/format";
import { normalizeOrdersByNegotiation } from "pages/load-composition/components/modalLoadInNegotiation/utils";
import ModalInfosLoadComposition from "./components/modal-infos-load-composition";
import ModalInfosNegotiationLoadComposition from "./components/modal-infos-negotiation-load-composition";
import { isUserAmbev } from "utils/user";
import { EmergencyRenegotiate } from "pages/composicao-carga/form/emergency-renegotiate";
import { SituacaoComposicaoCarga } from "models/composicaoCarga/situacaoComposicaoCarga";
import { buscarUnidades } from "pages/composicao-carga/form/composicao-carga-form.service";

const ModalReviewLoadInEmergencyNegotiation = ({ isOpen, handleClose, composicao, filters, redirectRoute, handleRefresh }) => {
  const fornecedor = [{ Id: composicao.IdFornecedor, Descricao: composicao.Fornecedor }]
  const unidadeNegocio = [{ Id: composicao.IdUnidadeNegocio, Descricao: composicao.UnidadeNegocio }]
  const [pedidos, setPedidos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openEmergencyRenegotiate, setOpenEmergencyRenegotiate] = useState(false);
  const [businessUnits, setBusinessUnits] = useState([]);
  const { showFeedback } = useGlobalContext();

  const loadPedidos = async () => {
    setIsLoading(true);
    try {
      const dtoRequest = {
        IdUnidadeNegocio: composicao.IdUnidadeNegocio,
        IdFornecedor: composicao.IdFornecedor,
        IdComposicao: composicao.IdComposicao,
        idUsuario: Session.get().Id
      }
      let _orders = await getPedidos(dtoRequest);
      let _businessUnits = await buscarUnidades();
      if (composicao.OrdersNegotiations) {
        _orders = normalizeOrdersByNegotiation(_orders, composicao.OrdersNegotiations);
      }
      setPedidos([..._orders])
      setBusinessUnits(_businessUnits);
    }
    catch (e) {
      proccessError(e)
    }
    setIsLoading(false);
  }

  const handleEditLoad = () => isUserAmbev() ? handleEditLoadUserAmbev() : handleEditLoadSupplier();

  const handleEditLoadUserAmbev = () => {

    const filtersRoute = `${filters.center.IdUnidadeNegocio}/${formatWithoutSpaces(filters.center.Descricao)}`
    const filtersRouteDates = `/date/${composicao.date}/${composicao.hour}/${composicao.IdComposicao}/${composicao.IdFornecedor}`
    redirectRoute(`/loadCompositionbuilding/destino/${filtersRoute}${filtersRouteDates}`)
  }

  const handleEditLoadSupplier = () => {
    setOpenEmergencyRenegotiate(true);
  }

  const proccessError = (e) => {
    showFeedback(formatErrorMessage(e))
  }

  useEffect(() => {
    loadPedidos();
  }, [])

  return (
    <div>
      <S.WrapperDialog>
        <Dialog
          open={isOpen}
          onRequestClose={handleClose}
          contentStyle={{
            position: 'fixed', width: '0px !important', height: '0px !important',
            margin: '0px important', padding: '0px important', left: '-300px', top: '40px'
          }}
        >
          <S.Wrapper>
            <ModalInfosLoadComposition
              fornecedor={fornecedor}
              unidadeNegocio={unidadeNegocio}
              pedidos={pedidos}
              isLoading={isLoading}
              composicao={composicao}
              redirectRoute={redirectRoute}
            />
            <ModalInfosNegotiationLoadComposition
              composicao={composicao}
              handleClose={handleClose}
              handleEditLoad={handleEditLoad}
              handleRefresh={handleRefresh}
              handleFeedback={showFeedback}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </S.Wrapper>
        </Dialog >
      </S.WrapperDialog>
      {openEmergencyRenegotiate &&
          <EmergencyRenegotiate
            open={openEmergencyRenegotiate}
            composicao={composicao}
            unidadesNegocio={businessUnits}
            fornecedores={fornecedor}
            SituacaoValor={SituacaoComposicaoCarga}
            pedidos={pedidos}
            handleCloseAll={handleClose}
          />
        }
    </div>
  )
}

ModalReviewLoadInEmergencyNegotiation.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  composicao: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  redirectRoute: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func.isRequired
}

export default ModalReviewLoadInEmergencyNegotiation;
