import Fetch from "utils/fetch";

export const verificarNotaVersao = (UsuarioConfiguracao) => {
  return Fetch.post("/usuario/verificarnotaversao", UsuarioConfiguracao);
};

export const verifyFilterUser = (UserId) => {
  return Fetch.get(`/usuario/verificaSePossuiFiltro/${UserId}`);
};

export const verifyUser = () => {
  return Fetch.get("users:current")
    .then(({ data }) => data)
    .catch(() => false);
};
