import PropTypes from "prop-types"
import React from 'react'
import intl from 'react-intl-universal'

import AceiteReadOnlyRenegociacaoPedidoStyled
  from './aceite-read-only-renegociacao-pedido.styled'
import { showDeliveryTime, formatDeliveryTime } from 'utils/delivery-time'
import { formatNumber } from 'utils/format';

const AceiteReadOnlyRenegociacaoPedido = ({
  dataColeta,
  dataEntrega,
  horaEntrega,
  quantidadeAtual,
}) => (
  <AceiteReadOnlyRenegociacaoPedidoStyled>
    <div className="renegotiation-order-read-offer">
      <div className="renegotiation-order-read-column">
        <span>{intl.get('stocks.collectionDate')}</span>
        <span>{dataColeta}</span>
      </div>
      <div className="renegotiation-order-read-column">
        <span>{intl.get('stocks.deliveryDate')}</span>
        <span>{dataEntrega}</span>
      </div>
    </div>
    <div className="renegotiation-order-read-offer">
      <div className="renegotiation-order-read-column" style={{ marginLeft: '0' }}>
        <span>{intl.get('commons.quantity')}:</span>
        <span>{formatNumber(quantidadeAtual)}</span>
      </div>
      {
        showDeliveryTime(horaEntrega) &&
        <div className="renegotiation-order-read-column">
          <span>{intl.get('commons.deliveryTime')}:</span>
          <span>{formatDeliveryTime(horaEntrega)}</span>
        </div>
      }
    </div>

  </AceiteReadOnlyRenegociacaoPedidoStyled>
);

AceiteReadOnlyRenegociacaoPedido.propTypes = {
  dataColeta: PropTypes.any.isRequired,
  dataEntrega: PropTypes.any.isRequired,
  horaEntrega: PropTypes.any.isRequired,
  quantidadeAtual: PropTypes.any.isRequired
}

export default AceiteReadOnlyRenegociacaoPedido
