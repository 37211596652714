import PropTypes from "prop-types"
import React from 'react'
import FilterTaks from './filter-task';

// ------------
// The behavior of this component serves to correctly render the component after system login
// ------------

const LazyLoadFilterTask = ({ searchTasks }) => {
  const _isUseFilterDefault = JSON.parse(localStorage.getItem('filterMyItemsOnly'));
  if (_isUseFilterDefault == null) return null;
  return <FilterTaks searchTasks={searchTasks} isUseFilterDefault={_isUseFilterDefault} />
}

LazyLoadFilterTask.propTypes = {
  searchTasks: PropTypes.any.isRequired
}

export default LazyLoadFilterTask;