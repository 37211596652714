import {
  SupplierProductionPlan as SupplierProductionPlanComponent,
  SupplierProductionPlanProvider,
} from "features/supplier-production-plan";

const SupplierProductionPlan = () => (
  <SupplierProductionPlanProvider>
    <SupplierProductionPlanComponent />
  </SupplierProductionPlanProvider>
);

export default SupplierProductionPlan;
