export const initialVolumes = {
  Schedules: [],
  Errors: [],
  HasErrors: false
}

export const shiftValues = [1, 2, 3].map(shift => ({
  value: shift
}))

export const dataType = {
  Shift: 1,
  DeliveryDate: 2,
  Interval: 3
}

export const initialBusinessUnit = {
  Centro: "",
  Email: "",
  Id: 0,
  Nome: ""
}

export const initialDate = {
  deliveryDateFrom: '',
  deliveryDateTo: ''
}
