import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { Checkbox } from '@hbsis.uikit/react'
import { PlusIcon, MinusIcon } from 'components/icons/icn-index.icon'
import {
  StyledHeaderOptimization,
  StyledRowOptimization,
  StyledValuesOptimization
} from './components'

import './expandable-table-optimization.css'

const TableRow = ({
  childLevel,
  childrenKey,
  labelKey,
  code,
  item,
  labelsList,
  children,
  expandedItems = [],
  resolveValue,
  resolveTitle,
  showCheckbox,
  onClick,
  onSelectItem,
  onExpandItem,
}) => {
  const isExpanded = expandedItems.includes(code)
  const hasChildren = !!children && children.length > 0

  return (
    <StyledRowOptimization isExpanded={isExpanded} hasChildren={hasChildren}>
      <StyledHeaderOptimization>
        <StyledValuesOptimization>
          {hasChildren && (
            <button className='etb-btn' onClick={() => onExpandItem(code)}>
              {isExpanded ? <MinusIcon width='11px' height='11px' /> : <PlusIcon width='11px' height='11px' />}
            </button>
          )}
          {showCheckbox() && (
            <Checkbox
              name={`checkbox-${item.Id}`}
              className="align-check"
              checked={item.checked || false}
              onChange={(e) => { onSelectItem(!item.checked, item); }}
            />
          )}
        </StyledValuesOptimization>

        <div className={`etb-values ${(hasChildren && childLevel === 1) ? 'etb-bold' : ''}`}>
          {labelsList.map((value, index) => (
            <div
              key={index}
              className='etb-column-optimization'
              style={value.customStyle}
              title={resolveTitle ? resolveTitle(value.key, item) : null}
            >
              {resolveValue ? resolveValue(value.key, item) : item[value.key]}
            </div>
          ))}
        </div>
      </StyledHeaderOptimization>

      {hasChildren &&
        <div className={cx('etb-children', { expanded: isExpanded })}>
          {children.map((it, index) => (
            <TableRow
              key={`${code}-${it.Id}-${index}`}
              childLevel={childLevel + 1}
              code={`${code}-${it.Id}`}
              labelKey={labelKey}
              childrenKey={childrenKey}
              item={children[index]}
              label={it[labelKey]}
              labelsList={labelsList}
              children={it[childrenKey]}
              expandedItems={expandedItems}
              resolveValue={resolveValue}
              resolveTitle={resolveTitle}
              showCheckbox={showCheckbox}
              onClick={onClick}
              onSelectItem={onSelectItem}
              onExpandItem={onExpandItem}
            />
          ))}
        </div>
      }
    </StyledRowOptimization>
  );
};

TableRow.defaultProps = { childLevel: 1 }

TableRow.propTypes = {
  childLevel: PropTypes.number,
  labelKey: PropTypes.string,
  childrenKey: PropTypes.string,
  code: PropTypes.string,
  item: PropTypes.object,
  labelsList: PropTypes.array,
  children: PropTypes.array,
  expandedItems: PropTypes.array,
  resolveValue: PropTypes.func,
  resolveTitle: PropTypes.func,
  showCheckbox: PropTypes.func,
  onClick: PropTypes.func,
  onSelectItem: PropTypes.func,
  onExpandItem: PropTypes.func,
}

export default TableRow
