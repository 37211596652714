import { useEffect, useState } from 'react';
import { ClbButton, ClbSelect } from 'libs/celebration';
import { getCenters } from 'pages/master-data/general-configuration/general/general-master-data.service';
import { searchSuppliers } from 'services/filters.service';
import { isUseFilterDefault } from 'utils/user';
import { mapperOptionsToSelectBoxClb } from 'utils/celebration';
import { useLoadBuildingContext } from 'features/load-building/context';
import intl from 'react-intl-universal'
import * as S from './styled'
import { validateValidFilter } from 'features/load-building/utils';
import { generateQueryParams } from 'utils/custom-functions';

export const LoadBuildingFilter = () => {
  const [businessUnits, setBusinessUnits] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [filterComponent, setFilterComponent] = useState({});

  const {
    setFilter,
    setVolumesSelected,
    volumesSelected,
    setOpenFlowBuild,
    setBusinessUnitsRegisters
  } = useLoadBuildingContext();

  const loadSuppliersFilter = async (value) => {
    const query = {IdUnidadeNegocio: [value], UserInternalSupplier: true}
    const _suppliers = await searchSuppliers(isUseFilterDefault(), generateQueryParams(query))
    setSuppliers(mapperOptionsToSelectBoxClb(_suppliers, "Id", "Description"))
  }

  const loadBusinessUnitsFilter = async () => {
    const _businessUnits = await getCenters();
    setBusinessUnitsRegisters(_businessUnits);
    setBusinessUnits(mapperOptionsToSelectBoxClb(_businessUnits, "IdUnidadeNegocio", "Descricao"));
  }

  const handleUpdateFilter = (field, value) => {
    setFilterComponent(prevState => ({
      ...prevState,
      [field]: value
    }))

    if (field === 'BusinessUnitId') {
      loadSuppliersFilter(value)
    }
  }

  const handleFilter = () => {
    setVolumesSelected([]);
    setFilter(prevState => ({
      ...prevState,
      ...filterComponent
    }))
  }

  useEffect(() => { loadBusinessUnitsFilter(); }, [])

  const quantityVolumesSelected = volumesSelected?.length;

  return (
    <S.Wrapper>
      <ClbSelect
        id='destination'
        data-testid='destiny-select'
        className="select"
        label={intl.get("commons.destiny")}
        size={"lg"}
        placeholder={intl.get("commons.destiny")}
        requiredMessage={intl.get('commons.required')}
        options={businessUnits}
        value={filterComponent.BusinessUnitId}
        onClbChange={e => handleUpdateFilter("BusinessUnitId", e.detail.value)}
        showMessage
        required
      />
      <ClbSelect
        id='supplier'
        data-testid='supplier-select'
        className="select"
        label={intl.get("commons.supplier")}
        size={"lg"}
        placeholder={intl.get("commons.supplier")}
        requiredMessage={intl.get('commons.required')}
        options={suppliers}
        value={filterComponent.SupplierId}
        onClbChange={e => handleUpdateFilter("SupplierId", e.detail.value)}
        showMessage
        required
        disabled={!suppliers?.length}
      />
      <ClbButton
        size='md'
        styleType='secondary'
        className='btn'
        onClbClick={handleFilter}
        data-testid='search-btn'
        disabled={!validateValidFilter(filterComponent)}
      >
        {intl.get('filters.search')}
      </ClbButton>

      <ClbButton
        onClbClick={() => setOpenFlowBuild(true)}
        size='md'
        styleType='primary'
        className='btn btn-build'
        disabled={!quantityVolumesSelected}
        data-testid='btn-build'
      >
        {intl.get('commons.loadBuild')}
        <p>({quantityVolumesSelected})</p>
      </ClbButton>
    </S.Wrapper>
  )
}