import React, { Component } from "react"
import PropTypes from "prop-types"
import Dialog from "material-ui/Dialog"
import { Row, Col } from "react-flexbox-grid"
import { Input } from "@hbsis.uikit/react"
import { SelectBox, Button } from "components/uikit-adapter/index"

import moment from 'moment'
import intl from 'react-intl-universal'

import IconAddEvent from 'images/add-event.svg'
import StyledFilter from "./StyledFilter"
import './modal-iniciar-programacao.css'
import { Switcher } from "components/toggle"

class FiltroProgramacao extends Component {
  constructor(props) {
    super(props)

    this.state = {
      idUsuarioProgramado: props.initialData.idUsuarioProgramado,
      programados: props.initialData.programados,
      naoProgramados: props.initialData.naoProgramados,
      usuarioSelecionado: null,
      showFilter: props.showFilter,
      hidden: true,
      requestCounter: 0,
      showModalConfirmacaoProgramacao: false,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      showFilter: nextProps.showFilter,
      idUsuarioProgramado: nextProps.initialData.idUsuarioProgramado,
      programados: nextProps.initialData.programados,
      naoProgramados: nextProps.initialData.naoProgramados,
      usuarioSelecionado: this.searchUserSelected()
    })
  }

  searchUserSelected() {
    const idUsuarioProgramado = this.props.initialData.usuarioSelecionado ? this.props.initialData.usuarioSelecionado.Id : this.state.idUsuarioProgramado

    if (this.props.usuarios.length) {
      const usuarioSelecionado = this.props.usuarios.find((usuario) => usuario.Id === idUsuarioProgramado)
      return usuarioSelecionado || this.props.usuarios[0]
    }
  }

  changeUser = value => {
    this.setState({
      usuarioSelecionado: value
    })
  }

  changeValue = (property, value) => {
    this.setState({
      [property]: value
    })
  }

  formatLastSchedule = (dataUltimaProgramacao, NomeUsuario) => {
    return dataUltimaProgramacao ? `${moment(dataUltimaProgramacao).format("L - LT")} - ${intl.get('commons.by')} ${NomeUsuario}` : intl.get('stocks.noInitProgramation')
  }

  handleShowModal = () => {
    this.setState(prevState => ({
      showModalConfirmacaoProgramacao: !prevState.showModalConfirmacaoProgramacao
    }))
  }

  clearFilters = () => {
    const params = {
      idUsuarioProgramado: '',
      usuarioSelecionado: { Descricao: intl.get('stocks.header.actions.userNotSelected') },
      programados: true,
      naoProgramados: true
    }

    this.setState({ ...params }, () => this.props.handleClose(params))
  }

  handleIniciarProgramacao = () => {
    this.handleShowModal()
    const params = this.getParmsForSchedule()
    this.setState({ ...params }, () => this.props.handleIniciarProgramacao(params))
  }

  handleCloseSearch = () => {
    const params = this.getParmsForSchedule()
    this.setState({ ...params }, () => this.props.handleClose(params))
  }

  getParmsForSchedule = () => {
    return {
      idUsuarioProgramado: this.state.usuarioSelecionado.Id,
      usuarioSelecionado: this.state.usuarioSelecionado,
      programados: this.state.programados,
      naoProgramados: this.state.naoProgramados
    }
  }

  validateSearchButton = () => {
    const { programados, naoProgramados, usuarioSelecionado } = this.state
    return !(programados || naoProgramados) || !usuarioSelecionado
  }

  render() {
    const { usuarios } = this.props

    const {
      usuarioSelecionado,
      showModalConfirmacaoProgramacao,
      programados,
      naoProgramados
    } = this.state

    return (
      <StyledFilter>
          <div className="filter-container">
            <div className="sideFilterFields">
              <Row>
                <Col xs={12}>
                  <div className="qtde-itens-programados">
                    {(usuarioSelecionado && usuarioSelecionado.Programacao != null)
                      ? `${usuarioSelecionado.Programacao.QuantidadeProgramada}
                      ${intl.get('stocks.of')} ${usuarioSelecionado.Programacao.TotalProgramacao}
                      ${intl.get('stocks.programmedItens')}`
                      : intl.get('stocks.noInitProgramation')}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={12} md={12}>
                  <SelectBox
                    name="usuario"
                    label={intl.get('commons.user')}
                    options={usuarios}
                    valueKey="Id"
                    labelKey="Descricao"
                    value={usuarioSelecionado}
                    onChange={this.changeUser}
                    placeholder={intl.get('commons.user')}
                    searchable
                    openOnFocus
                    clearable
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={10} lg={10} md={10}>
                  <Input
                    name="ultimaProgramacao"
                    label={intl.get('stocks.initLastProgramation')}
                    value={(usuarioSelecionado && usuarioSelecionado.Programacao != null)
                      ? `${this.formatLastSchedule(usuarioSelecionado.Programacao.InicioProgramacao, usuarioSelecionado.Programacao.NomeUsuarioAcao)}`
                      : intl.get('stocks.noInitProgramation')}
                    readOnly
                    className='input'
                  />
                </Col>
                <Col xs={2} lg={2} md={2}>
                  <div title={intl.get('stocks.initProgramation')} className="button-start-search">
                    <Button
                      width='50px'
                      type="secondary"
                      onClick={this.handleShowModal}
                      icon={IconAddEvent}
                      disabled={this.validateSearchButton()}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xs={6}>
                  <Switcher
                    name="programados"
                    label={intl.get('stocks.programmed')}
                    value={programados}
                    onChange={value =>
                      this.changeValue(
                        "programados",
                        value.checked
                      )
                    }
                    thumbSwitchedStyle={{ backgroundColor: 'var(--color-action-default)' }}
                    trackSwitchedStyle={{ backgroundColor: 'var(--color-neutral-200)' }}
                  />
                </Col>
                <Col xs={6}>
                  <Switcher  
                    name="naoProgramado"
                    label={intl.get('stocks.notProgrammed')}
                    value={naoProgramados}
                    onChange={value =>
                      this.changeValue(
                        "naoProgramados",
                        value.checked
                      )
                    }
                  />
                </Col>
              </Row>

              <Row style={{ width: "100%", height: "102px" }}>
                {/*Esse é um espaço em branco por causa dos botões fixos no filtro*/}
              </Row>
            </div>
            <div className="filter-container">
              <Row className="footerFilterContainer no-margin">
                <Col xs={12}>
                  <Row>
                    <Col xs={12} lg={12} md={12}>
                      <hr className="filter-divisor" />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} lg={6} md={6}>
                      <Button
                        type="primary"
                        onClick={this.handleCloseSearch}
                        disabled={this.validateSearchButton()}
                        value={intl.get('filters.search')}
                      />
                    </Col>
                    <Col xs={6} lg={6} md={6}>
                      <Button
                        type="secondary"
                        onClick={this.clearFilters}
                        value={intl.get('filters.cleanSearch')}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>

        <Dialog
          open={showModalConfirmacaoProgramacao}
          contentStyle={{ width: "600px" }}>
          <p className="mensagem-modal-confirmacao">{intl.get('stocks.initProgramationMessage')}</p>
          <p className="mensagem-modal-mensagem">{intl.get('stocks.initProgramationMessage2')}</p>
          <div className="botoes-modal">
            <Button
              width="150px"
              value={intl.get('geral.modalConfirm.no')}
              onClick={this.handleShowModal}
              className="btn"
            />
            <Button
              width="150px"
              type="primary"
              value={intl.get('geral.modalConfirm.yes')}
              onClick={this.handleIniciarProgramacao}
              className="btn margin-botao"
            />
          </div>
        </Dialog>
      </StyledFilter>
    );
  }
}

FiltroProgramacao.propTypes = {
  initialData: PropTypes.object,
  usuarios: PropTypes.array.isRequired,
  showFilter: PropTypes.bool,
  handleClose: PropTypes.func,
  handleIniciarProgramacao: PropTypes.func,
};

FiltroProgramacao.defaultProps = {
  showFilter: false,
  initialData: {},
};

export default FiltroProgramacao;
