import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  background: var(--color-contrast-white);
  border: 1px solid #95C511;
  box-sizing: border-box;
  border-radius: 3px;
  width: 170px;
  min-height: 63px;
  margin: 4px 11px;
  cursor: pointer;

  ${p => p.isSelected && styleFreeSlotSelected}
`

export const Hora = styled.div`
  border-right: solid 1px #D1D4D7;
  span{
    font-weight: var(--font-weight-semibold);
    margin-right: 4px;
    font-size: 12px;
    line-height: 12px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--color-contrast-black);
  }
`

export const Content = styled.div`
  margin-left: 4px;
  span{
    font-size: 11px;
    line-height: 12px;
    display: flex;
    color: #95C511;
    text-transform: capitalize;
  }
`

const styleFreeSlotSelected = `
  background: #95C511 !important;
  span{
    color: white !important;
  }
`
