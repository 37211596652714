import { Component } from "react";
import Fetch from 'utils/fetch'
import Paper from "material-ui/Paper";
import Divisor from "../body-card/card-divisor";
import { Button } from 'components/uikit-adapter/index';
import moment from 'moment'

import pedidoPacoteIcon from 'images/pedido-pacote.png'

import StatusCard from './../body-card/status-card-generico'
import StyledConfiguracoesIntegracoes from "./styledConfiguracoesIntegracoes";
import FiltroIntegracaoGradeDescarga from "./filtros/filtro-integracao-grade-descarga";
import FiltroIntegracaoSugestaoAutomatica from "./filtros/filtro-integracao-sugestao-automatica";
import ModalConfirmacaoExecucaoIntegracao from "./modal-confirmacao-execucao-integracao";
import ModalLogIntegracao from "./modal-log-integracao";
import ModalAjustesTarefa from "./modal-ajustes-tarefa";
import intl from 'react-intl-universal'
import { formatErrorMessage } from 'utils/handle-error';
import { profileAdminOrMasterData } from "services/permissions/getPermissions"; 
import IconLog from "components/icons/icn-log.icon";
import IconControls from "components/icons/icn-controls.icon";

const INTEGRACAO_CONTINUA = 1;
const INTEGRACAO_SUGESTOES_AUTOMATICAS = 4;
const INTEGRACAO_GRADE_DESCARGA_AMBEV = 14;
const INTEGRACAO_TAREFAS = 13;

const STATUS_STANDBY = 1;

//Status da integração
const STATUS_VERMELHO = { Id: 0, ColorStatus: "#ff5e53", ColorStatusArrow: "#c64138" } // Parado = 0
const STATUS_CINZA = { Id: 1, ColorStatus: "#95a3b7", ColorStatusArrow: "#496072" }    // StandBy = 1
const STATUS_AZUL = { Id: 2, ColorStatus: "#6197C6", ColorStatusArrow: "#2870B2" }     // Agendado = 2
const STATUS_AMARELO = { Id: 3, ColorStatus: "#fbba49", ColorStatusArrow: "#c3913b" }  // Preparando Execucao = 3
const STATUS_VERDE = { Id: 4, ColorStatus: "#9bc73b", ColorStatusArrow: "#86ac32" }    // Em Execucao = 4

class ConfiguracoesIntegracoesCard extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showModalConfirmacaoExecucao: false,
      showModalLogIntegracao: false,
      showModalAjusteTarefa: false,
      filters: null,
      integracao: props.integracao,
      configuracaoTarefa: {
        baseLineHorario: '',
        baselineSomenteSemana: false,
      }
    }
  }

  executeAndCloseModalConfirmacaoExecucao = integracao => {
    this.handleShowModalConfirmacaoExecucao();

    Fetch.post(`/integracao/salvarDadosIntegracao/`, {
      IdIntegracao: integracao.Id,
      Parametros: this.state.filters === null  ? null : JSON.stringify(this.state.filters)
    })
      .then(() => this.props.proccessFeedback(intl.get('master-data.configurations.integrations.integrationScheduled')))
      .then(() => this.props.obterIntegracoes())
      .catch((err) => { this.props.proccessFeedback(formatErrorMessage(err)) })
  }

  searchIntegrationLog = integracao => {
    Fetch.get(`/loghistory/obterLogsIntegracao/${integracao.Id}/${1}`)
      .then((response) =>
        this.setState(({
          integracao: {
            ...integracao,
            Logs: response.data
          }
        })))
      .catch((err) => this.props.proccessFeedback(formatErrorMessage(err)))
  }

  handleShowModalConfirmacaoExecucao = () => {
    this.setState(prevState => ({
      showModalConfirmacaoExecucao: !prevState.showModalConfirmacaoExecucao,
      filters: null
    }))
  }

  changeValue = (name, value) => {
    this.setState({
      [name]: value
    })
  }

  generateFilters = (integracao, carteiras, familiasRotuladas) => {

    if (integracao.Id === INTEGRACAO_SUGESTOES_AUTOMATICAS) {
      return (
        <FiltroIntegracaoSugestaoAutomatica
          familiasRotuladas={familiasRotuladas}
          changeValue={this.changeValue}
          IdFamiliaRotulada={this.state.filters !== null ? this.state.filters.IdFamiliaRotulada : null}
        />
      )
    }
    if (integracao.Id === INTEGRACAO_GRADE_DESCARGA_AMBEV) {
      return (
        <FiltroIntegracaoGradeDescarga
          carteiras={carteiras}
          changeValue={this.changeValue}
          IdsCarteiras={this.state.filters !== null ? this.state.filters.IdsCarteiras : null}
        />
      )
    }
  }

  hasFilters = (integracao) => {
    return integracao.Id === INTEGRACAO_SUGESTOES_AUTOMATICAS || integracao.Id === INTEGRACAO_GRADE_DESCARGA_AMBEV;
  }

  toggleOpenModalLogIntegracao = integracao => {

    if (integracao){
      this.searchIntegrationLog(integracao)
    }

    this.setState(prevState => ({
      showModalLogIntegracao: !prevState.showModalLogIntegracao
    }))
  }

  toggleOpenModalAjusteTarefa = () => {
    if (!this.state.showModalAjusteTarefa) {
      this.searchSettingsIntegrationTasks()
    }

    this.setState(prevState => ({
      showModalAjusteTarefa: !prevState.showModalAjusteTarefa
    }))
  }

  searchSettingsIntegrationTasks = () => {
    Fetch.get(`/integracao/obterConfiguracoesIntegracaoTarefas`)
      .then((response) =>
        this.setState(({
          configuracaoTarefa: {
            baseLineHorario: response.data.BaselineHorario,
            baselineSomenteSemana: response.data.BaselineSomenteSemana
          }
        })))
      .catch((err) => this.props.proccessFeedback(formatErrorMessage(err)))
  }

  saveAjustesTarefa = () => {
    this.toggleOpenModalAjusteTarefa()

    const horario = this.state.configuracaoTarefa.baseLineHorario.split(':')
    Fetch.post(`/integracao/salvarConfiguracaoTarefas/`, {
      BaselineHora: horario[0],
      BaselineMinuto: horario[1],
      BaselineSomenteSemana: this.state.configuracaoTarefa.baselineSomenteSemana
    })
      .then(() => this.props.proccessFeedback(intl.get('master-data.configurations.integrations.integrationConfigured')))
      .catch((err) => this.props.proccessFeedback(formatErrorMessage(err)))
  }

  changeValueTarefas = (prop, value) => {
    this.setState(prevState => ({
      configuracaoTarefa: {
        ...prevState.configuracaoTarefa,
        [prop]: value
      }
    }))
  }

  montagemStatus = (integracao) => {
    if (integracao.Id === 1 && integracao.Status !== 0) {
      return (
        <div className="status-label">
          <span>Status: </span>
          <span>{intl.get('master-data.configurations.integrations.running')}</span>
        </div>
      )
    } else {
      return (
        <div className="status-label">
          <span>Status: </span>
          <span>{integracao.DescricaoStatus}</span>
        </div>
      )
    }
  }

  montaCorStatus = (integracao) => {
    if (integracao.Status !== 0 && integracao.Id === 1) {
      return STATUS_VERDE
    }
    if (integracao.Status === STATUS_VERMELHO.Id) {
      return STATUS_VERMELHO
    }
    if (integracao.Status === STATUS_CINZA.Id) {
      return STATUS_CINZA
    }
    if (integracao.Status === STATUS_AMARELO.Id) {
      return STATUS_AMARELO
    }
    if (integracao.Status === STATUS_VERDE.Id) {
      return STATUS_VERDE
    }
    return STATUS_AZUL
  }

  render() {
    const {
      showModalConfirmacaoExecucao,
      showModalLogIntegracao,
      showModalAjusteTarefa,
      integracao,
      configuracaoTarefa
    } = this.state;

    const { carteiras, familiasRotuladas } = this.props;

    return (
      <StyledConfiguracoesIntegracoes
        corStatus={this.montaCorStatus(integracao)}
      >
        <Paper
          zDepth={1}
          className="card-integracoes"
          style={{ height: `170px` }}
        >
          <div className='card-area'>
            <div className='header-card'>
              <StatusCard
                Titulo={integracao.DescricaoTipoIntegracao}
                SubTitulo={this.montagemStatus(integracao)}
                Icon={pedidoPacoteIcon}
                Status={this.montaCorStatus(integracao)}
              />
            </div>

            <Divisor />

            <div className="body-card">
              <div className="card-label">
                <span>{intl.get('master-data.configurations.integrations.lastUpdate')}</span>
                <span>{
                  integracao.DataUltimaExecucao ? moment(integracao.DataUltimaExecucao).format("L LT")
                    : intl.get('master-data.configurations.integrations.notExecuted')
                }</span>
              </div>
            </div>

            <Divisor />

            <div className="body-card">
              <div className="card-actions">
                {integracao.Id === INTEGRACAO_TAREFAS &&
                  <div title={intl.get('master-data.configurations.integrations.integrationAdjust')}>
                    <Button
                      buttonIcon={<IconControls width={"32px"} height={"24px"} />}
                      className="icon-button"
                      onClick={this.toggleOpenModalAjusteTarefa}
                    />
                  </div>
                }
                <div title={`Log`}>
                  <div title={intl.get('master-data.configurations.integrations.log')}>
                    <Button
                      buttonIcon={<IconLog width={24} height={24} />}
                      className="icon-button"
                      onClick={() => this.toggleOpenModalLogIntegracao(integracao)}
                    />
                  </div>
                </div>
                {profileAdminOrMasterData() && integracao.Id != INTEGRACAO_CONTINUA && integracao.Status === STATUS_STANDBY &&
                  <div title={intl.get('master-data.configurations.integrations.execute')}>
                    <Button
                      type="secondary"
                      value={intl.get('master-data.configurations.integrations.execute')}
                      className="execute-button"
                      onClick={this.handleShowModalConfirmacaoExecucao}
                    />
                  </div>
                }
              </div>
            </div>
          </div>
        </Paper>

        {showModalConfirmacaoExecucao &&
          <ModalConfirmacaoExecucaoIntegracao
            showModalConfirmation={showModalConfirmacaoExecucao}
            handleShowModal={this.handleShowModalConfirmacaoExecucao}
            executeAndCloseModal={() => this.executeAndCloseModalConfirmacaoExecucao(integracao)}
            descricaoIntegracao={integracao.DescricaoTipoIntegracao}
            desabilitarBotaoOk={this.hasFilters(integracao) && this.state.filters === null}
          >
            {this.generateFilters(integracao, carteiras, familiasRotuladas)}
          </ModalConfirmacaoExecucaoIntegracao>
        }

        {showModalLogIntegracao &&
          <ModalLogIntegracao
            openModalLogIntegracao={showModalLogIntegracao}
            toggleOpenModalLogIntegracao={this.toggleOpenModalLogIntegracao}
            integracao={integracao}
          />
        }

        {showModalAjusteTarefa &&
          <ModalAjustesTarefa
            openModalAjustesTarefa={showModalAjusteTarefa}
            toggleOpenModalAjustesTarefa={this.toggleOpenModalAjusteTarefa}
            handleSaveAjustesTarefa={this.saveAjustesTarefa}
            integracao={integracao}
            profileAdminOrMasterData={profileAdminOrMasterData()}
            configuracaoTarefa={configuracaoTarefa}
            changeValueTarefas={this.changeValueTarefas}
          />
        }

      </StyledConfiguracoesIntegracoes>
    );
  }
}

export default ConfiguracoesIntegracoesCard;
