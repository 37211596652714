export const calculateProjection = (data, date, value, businessUnitId, outgoingValues, tabIndex, supplierName) => {
  let _data = { ...data }

  const suppliersData = _data['suppliers'][tabIndex].Data
  const dataProjection = generateProjectionSupplier(suppliersData, value, businessUnitId, date, "outgoingData")
  _data['suppliers'][tabIndex].Data = dataProjection

  const factory = outgoingValues.find(x => x.BusinessUnitId === businessUnitId).BusinessUnitPlant
  generateProjectionFactory(data['factories'], value, supplierName, date, factory, "outgoingData")
  return _data;
}

export const generateProjectionSupplier = (suppliersData, value, businessUnitId, date, field) => {
  const indexValueChange = suppliersData.findIndex(x => x.Date === date);
  let dataProjection = [...suppliersData];

  dataProjection.forEach((_, i) => {
    if (i < indexValueChange) { return }
    if (i == indexValueChange && field === "outgoingData") {
      dataProjection[i]
        .outgoingData
        .find(x => x.BusinessUnitId === businessUnitId)
        .outgoing = value
    }

    const outgoingData = dataProjection[i]
      .outgoingData.reduce((accumulator, object) => accumulator + parseFloat(object.outgoing), 0);

    dataProjection[i].OutgoingMerchandiseSupplier = outgoingData;
    if (i == indexValueChange && field !== "outgoingData") {
      dataProjection[i][field] = value
    } else if (i !== 0 && i > indexValueChange) {
      dataProjection[i].SupplierInitialStock = dataProjection[i - 1].SupplierFinalStock
    }
    dataProjection[i].SupplierFinalStock = dataProjection[i].SupplierInitialStock
      + dataProjection[i].SupplierProduction
      - outgoingData
  });

  return dataProjection;
}

export const generateProjectionFactory = (data, value, supplierName, date, factory, field) => {
  const factorieData = data.find(x => x.Name === factory).Data
  const indexValueChange = factorieData.findIndex(x => x.Date === date)

  let dataProjection = [...factorieData];

  dataProjection.forEach((_, i) => {
    if (i < indexValueChange) { return }
    if (i == indexValueChange && field === "outgoingData") {
      dataProjection[indexValueChange]
        .entry
        .find(x => x.SupplierName === supplierName).outgoing = value
    }
    const entryData = dataProjection[i]
      .entry.reduce((accumulator, object) => accumulator + parseFloat(object.outgoing), 0);

    dataProjection[i].BreweryMerchandiseEntry = entryData;
    if (i == indexValueChange && field !== "outgoingData") {
      dataProjection[i][field] = value
    } else if (i !== 0 && i > indexValueChange) {
      dataProjection[i].BreweryInitialStock = dataProjection[i - 1].BreweryFinalStock
    }
    dataProjection[i].BreweryFinalStock = dataProjection[i].BreweryInitialStock
      - dataProjection[i].Necessity
      + entryData
  });

  return dataProjection;
}
