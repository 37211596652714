import intl from 'react-intl-universal'
import { buscarMateriaisPrimariosPorUnidadeNegocio } from '../../primary-material-schedule/primary-material-schedule.service'

export const validateData = (data) => {

  if (data.plant.Centro === '') {
    return intl.get('autoLoadOptimization.feedbacks.requiredPlant')
  }
  else if (!data.productionVersion) {
    return intl.get('autoLoadOptimization.feedbacks.requiredProductionLine')
  }
  else if (data.date.deliveryDateFrom === '') {
    return intl.get('autoLoadOptimization.feedbacks.requiredPickUp')
  }
  else if (data.date.deliveryDateTo === '') {
    return intl.get('autoLoadOptimization.feedbacks.requiredDeliveryDate')
  }
  else if (!data.shift) {
    return intl.get('autoLoadOptimization.feedbacks.requiredShift')
  }
  return "";
}

export const validClickBack = (haveUnsavedChanges, handleOpenModal, handleDiscardChanges) => haveUnsavedChanges ? handleOpenModal() : handleDiscardChanges()
export const validGetPrimaryMaterial = async (validator, id) => validator === "" ? buscarMateriaisPrimariosPorUnidadeNegocio(id): ""
