import { getTokenAuthorization } from "./authService";
import jwt from "jsonwebtoken";

export const tokenAuthorization = async () => {
  try {
    const token = localStorage.getItem("ValidationToken");
    const { exp } = jwt.decode(token);
    const dataTokenCalculation = exp * 1000;

    if (Date.now() >= dataTokenCalculation) {
      const newToken = await getTokenAuthorization();
      localStorage.setItem("ValidationToken", newToken.Token);
      return newToken.Token;
    }

    return token;
  } catch (e) {
    console.log(`Error: ${e}`);
    return "";
  }
};
