import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from 'components/pagination/components/button'

import StyledPagination from './styledPagination'
import intl from 'react-intl-universal'


class Pagination extends Component {
    constructor(props) {
        super(props)

        this.state = {
            amountOf: 0,
            amountUntil: 0,
        }
    }

    getData = (page) => {
        this.props.refreshSearch(page)
    }

    getMaximoDaPagina = () => {
        const { page, pageSize, amount } = this.props

        let quantidadeFinal = (page + 1) * pageSize

        if (quantidadeFinal > amount) {
            quantidadeFinal = amount
        }

        return quantidadeFinal
    }

    getMinimoDaPagina = () => {
        const { page, pageSize, amount } = this.props

        const existeRegistros = (amount === 0)

        return ((page) * pageSize) + (existeRegistros ? 0 : 1)
    }

    render() {
        const { page, pageSize, amount } = this.props
        const isLastPage = (Math.ceil(amount / pageSize)) === (page + 1)

        return (
            <StyledPagination>
                <span className="page">{intl.get('commons.table.page')}: {page + 1}</span>
                <span className="pages"> {this.getMinimoDaPagina()} - {this.getMaximoDaPagina()} {intl.get('commons.table.of')} {amount} {intl.get('commons.table.records')}</span>
                <Button className="arrow" value="<" width="50" height="50" onClick={() => this.getData(page - 1)} disabled={page === 0} />
                <Button className="arrow" value=">" width="50" height="50" onClick={() => this.getData(page + 1)} disabled={isLastPage} />
            </StyledPagination>
        )
    }
}

Pagination.propTypes = {
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    amount: PropTypes.number.isRequired,
    refreshSearch: PropTypes.func.isRequired
}

export default Pagination
