import React from 'react'
import * as S from './styled'
import intl from 'react-intl-universal'

const Header = () => {
  return (
    <S.Wrapper>
      <S.Header title={intl.get('commons.composition')}>
      </S.Header>
    </S.Wrapper>
  )
}

export default Header;
