import styled from "styled-components";

export const styleLink = {
  textDecoration: 'none',
  height: '100%',
  display: 'flex'
}

export const style = {
  ...styleLink,
  fontSize: "14px",
  fontWeight: "var(--font-weight-semibold)",
  fontStyle: "normal",
  fontStretch: "normal",
  lineHeight: "24px",
  letterSpacing: "normal",
  color: "var(--color-contrast-white)",
  textTransform: "none",
  textDecoration: "none",
  padding: "0px 10px",
};

export const ContainerMenu = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  ${p => p.isCurrentPage && 'background-color: var(--color-brand-300);'};

  :hover{
    background-color: var(--color-brand-300);
    ${p => p.isCurrentPage && 'background-color: var(--color-brand-300);'};
  }
`

export const Navbar = styled.div``
export const Main = styled.div`
  width: 100%;
  height: 45px;
  background-color: var(--color-brand-600);
  position: relative;
  display: flex;
  align-items: center;

  .active {
    button {
      background-color:  var(--color-brand-300) !important;
    }
  }
`
