import {
  ClbButtonIcon,
  ClbCheckBoxChangeEvent,
  ClbCheckbox,
  ClbTable,
  ClbTableTBody,
  ClbTableTBodyCell,
  ClbTableTBodyRow,
  ClbTableTHead,
  ClbTableTHeadCell,
  ClbTableTHeadRow,
  ClbText
} from "@celebration/design-system-react";
import { useNotificationContext } from "features/notifications/context";
import { getNotifications } from "services/notifications.service";
import { useEffect, useState } from "react";
import Loading from "components/center-loading";
import intl from 'react-intl-universal'
import moment from "moment";
import { TagCriticality } from "components/tag-criticality";
import { getActionNotificationUrl } from "utils/notifications";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { normalizeUnreadValueByStatus } from "features/notifications/utils";
import { validatesArrayHasValue } from "utils/custom-functions";

import * as S from './styled'
import { INotificationResponse } from "types/notifications/notification-response";
import { IPaginatedResult } from "types/pagination";
import { formatErrorMessage } from "utils/handle-error";

const NotificationCenterTable = ({ history }: RouteComponentProps) => {
  const {
    filter,
    setFilter,
    setQuantityRegisters,
    notificationSelected,
    setNotificationSelected,
    handleNotificationsMarkAsRead
  } = useNotificationContext();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<IPaginatedResult<INotificationResponse[]>>();

  const loadNotificationCenter = async () => {
    setIsLoading(true);
    try {
      const _data = await getNotifications({
        ...filter,
        Unread: normalizeUnreadValueByStatus(filter.Unread)
      });
      if (!validatesArrayHasValue(_data.Data) && filter._page > 0) {
        setFilter(prevState => ({ ...prevState, _page: prevState._page - 1 }))
      }
      setQuantityRegisters(_data.TotalItems);
      setData(_data);
    } catch (e) {
      console.error(formatErrorMessage(e))
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadNotificationCenter();
  }, [filter]);

  if (!data?.TotalItems && !isLoading) {
    return <S.WrapperNoContent> <span className='noRecordsFound'>{intl.get('commons.noRecordsFound')}</span> </S.WrapperNoContent>
  }

  if (!data?.TotalItems && isLoading) {
    return <S.WrapperNoContent>
      <Loading
        isLoading
        fullHeightParent={false}
      />
    </S.WrapperNoContent>
  }

  if (!data) return null;

  const handleCheckItem = (value: number) => {
    setNotificationSelected(prevState => {
      if (prevState.includes(value)) {
        return prevState.filter(x => x !== value);
      } else {
        return [...prevState, value]
      }
    })
  }

  const handleCheckAllItems = (e: ClbCheckBoxChangeEvent) => {
    if (e.detail.checked) {
      setNotificationSelected(prevState => [...prevState,
      ...data.Data.map(x => x.Id).filter(x => !prevState.includes(x))
      ])
    } else {
      setNotificationSelected(prevState => (
        prevState.filter(x => !data?.Data?.map(x => x.Id).includes(x))
      ));
    }
  }

  const redirectNotification = async (item: INotificationResponse) => {
    const route = getActionNotificationUrl(item.MetaData);
    if (route == null) {
      return
    };

    if (!item.IsRead) {
      await handleNotificationsMarkAsRead([item.Id]);
    }

    history.push(route);
  };

  return (
    <S.Wrapper>
      <ClbTable>
        <ClbTableTHead>
          <ClbTableTHeadRow>
            <ClbTableTHeadCell>
              <ClbText slot="content">
                <ClbCheckbox
                  id='checkbox-all'
                  data-testid='checkbox-all'
                  checked={data?.Data?.every(x => notificationSelected.includes(x.Id))}
                  onClbChange={handleCheckAllItems}
                />
              </ClbText>
            </ClbTableTHeadCell>
            <ClbTableTHeadCell>
              <ClbText slot="content">{intl.get('commons.criticality')}</ClbText>
            </ClbTableTHeadCell>
            <ClbTableTHeadCell>
              <ClbText slot="content">{intl.get("master-data.general-configuration.type")}</ClbText>
            </ClbTableTHeadCell>
            <ClbTableTHeadCell>
              <ClbText slot="content">{intl.get('commons.date')}</ClbText>
            </ClbTableTHeadCell>
            <ClbTableTHeadCell>
              <ClbText slot="content">{intl.get('commons.description')}</ClbText>
            </ClbTableTHeadCell>
            <ClbTableTHeadCell />
          </ClbTableTHeadRow>
        </ClbTableTHead>
        <ClbTableTBody>
          {data?.Data?.map(item => (
            <ClbTableTBodyRow key={item.Id}>
              <S.WrapperRowItem isRead={item.IsRead}>
                <ClbTableTBodyCell style={{
                  borderLeft: `solid 5px ${item.IsRead ? '#cacaca' : 'var(--color-brand-300)'}`
                }}>
                  <ClbCheckbox
                    id={`checkbox-${item.Id}`}
                    data-testid={`checkbox-${item.Id}`}
                    checked={notificationSelected.includes(item.Id)}
                    onClbChange={() => handleCheckItem(item.Id)}
                  />
                </ClbTableTBodyCell>
                <ClbTableTBodyCell>
                  <TagCriticality
                    criticality={item.Criticality}
                    title={item.CriticalityDescription}
                  />
                </ClbTableTBodyCell>
                <ClbTableTBodyCell>
                  {item.TypeDescription}
                </ClbTableTBodyCell>
                <ClbTableTBodyCell>
                  {moment(item.CreatedDate).format("L LT")}
                </ClbTableTBodyCell>
                <ClbTableTBodyCell style={{ maxWidth: '400px' }}>
                  {item.Description.split('\\n').map(x => <div key={x}>{x}</div>)}
                </ClbTableTBodyCell>
                <ClbTableTBodyCell>
                  <ClbButtonIcon
                    icon="Eye1"
                    size="sm"
                    data-testid={`eye-${item.Id}`}
                    onClbClick={() => redirectNotification(item)}
                  />
                </ClbTableTBodyCell>
              </S.WrapperRowItem>
            </ClbTableTBodyRow>
          ))}
        </ClbTableTBody>
      </ClbTable>
    </S.Wrapper >
  );
};

const NotificationCenterTableWithRouter = withRouter(NotificationCenterTable);

export { NotificationCenterTableWithRouter as NotificationCenterTable }