export const mudarValorCampo = (valores) => {
  const novosValoresCampo = []
  valores.map((valor) => novosValoresCampo.push(valor))
  return novosValoresCampo
}

export const padronizaSaidaId = (valores) => {
  const ids = []
  valores?.map((item) => ids.push(item.Id))
  return ids
}
