import { useLoadBuildingContext } from "features/load-building/context";
import { LoadAccordionList } from "../load-accordion-list";
import { AccordionHeader, BlankSpace, SubTitle, Title, WrapperAccordions, WrapperTable, SubTitleBold, WrapperSubtitle } from "../styled"
import intl from 'react-intl-universal'

export const PartialLoadsList = () => {
  const { loadsOptimized } = useLoadBuildingContext();

  if (!loadsOptimized?.LoadNotFull?.length) {
    return null;
  }

  return (
    <>
      <Title style={{ marginTop: '24px', fontSize: '20px' }}>{intl.get('load-building.page-flow.partial-loads.title')}</Title>
      <WrapperSubtitle>
        <SubTitle style={{ fontSize: '14px' }}>{intl.get('load-building.page-flow.partial-loads.sub-title-one')}</SubTitle>
        <SubTitleBold style={{ fontSize: '14px', marginLeft: '1px' }}>{intl.get('load-building.page-flow.partial-loads.sub-title-two')}</SubTitleBold>
      </WrapperSubtitle>
      <WrapperAccordions>
        <WrapperTable data-testid="not-full-truck-table">
          <AccordionHeader>
            <tr>
              <th />
              <th>{intl.get("stocks.suggestionsListItem.origin")}</th>
              <th>{intl.get("commons.destiny")}</th>
              <th>{intl.get("commons.orderVolume")}</th>
              <th>{intl.get("commons.material")}</th>
              <th>{intl.get('commons.lot')}</th>
              <th>{intl.get('stocks.qty')}</th>
              <th>{intl.get("commons.pickup")}</th>
              <th>{intl.get("commons.delivery")}</th>
              <th>{intl.get("commons.deliveryTime")}</th>
              <th>{intl.get("commons.door")}</th>
              <th></th>
              <th className="btnDelete"></th>
            </tr>
          </AccordionHeader>
          <BlankSpace height='8px' />
          {
            loadsOptimized.LoadNotFull.map((x, i) => (
              <LoadAccordionList key={i} index={i} data={x} itemNumber={loadsOptimized.LoadCompositions.length + i + 1} listLabel={"LoadNotFull"} />
            ))
          }
        </WrapperTable >
      </WrapperAccordions>
    </>
  )
}