import { ClbDatePicker } from "@celebration/design-system-react";
import { getMonths } from "utils/celebration";
import intl from "react-intl-universal";

export const ClbDatePickerAdapter = (
  props: React.ComponentProps<typeof ClbDatePicker>
) => (
  <ClbDatePicker
    tooltipLabel={intl.get("commons.currentDate")}
    showMessage={false}
    showHelperText={false}
    required={false}
    calendarOpened={false}
    monthsArray={getMonths()}
    {...props}
  />
);
