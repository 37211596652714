import intl from 'react-intl-universal'
import {
  ClbTableTHeadCell,
  ClbText,
} from '@celebration/design-system-react'

export const HeaderTableHead = () => (
  <>
    <ClbTableTHeadCell className='materialGroupDescriptionCell'>
      <ClbText slot="content">{intl.get("commons.wallet")}</ClbText>
    </ClbTableTHeadCell>
    <ClbTableTHeadCell className='statusStock'>
      <ClbText slot="content">{intl.get("commons.status")}</ClbText>
    </ClbTableTHeadCell>
    <ClbTableTHeadCell className='destiny'>
      <ClbText slot="content">{intl.get("commons.destiny")}</ClbText>
    </ClbTableTHeadCell>
    <ClbTableTHeadCell className='labeledFamilyDescription'>
      <ClbText slot="content">{intl.get("commons.labeledFamily")}</ClbText>
    </ClbTableTHeadCell>
  </>
)