import PropTypes from "prop-types"
import React from 'react'

const OrderVolumeMessage = ({ message }) =>
  <div className='no-records-message'>{message}</div>

OrderVolumeMessage.propTypes = {
  message: PropTypes.any.isRequired
}

export default OrderVolumeMessage
