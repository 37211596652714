import intl from 'react-intl-universal';
import { isUserAmbev } from '../../../utils/user';
import { perfilPcpUnidadeLogado } from 'services/permissions/getPermissions';

const selectPropsDefault = {
  valueKey: 'Id',
  labelKey: 'Descricao',
  value: [],
  options: [],
  searchable: true,
  clearable: true,
  multi: true,
  hidden: false
}


export const filtersTasksDefault = {
  TasksType: [],
  Source: [],
  Destination: [],
  IdUsuario: [],
  MaterialGroup: [],
  LabeledFamily: [],
  Family: [],
  IsBaseLine: false,
}

export const selectsFiltersDefault = (useFilterDefault) => ([
  {
    ...selectPropsDefault,
    name: 'IdUsuario',
    label: intl.get('commons.user'),
    placeholder: intl.get('commons.user'),
    valueKey: 'Id',
    labelKey: 'Nome',
    hidden: !isUserAmbev(),
    disabled: useFilterDefault
  },
  {
    ...selectPropsDefault,
    name: 'TasksType',
    label: intl.get('tasks.taskType'),
    placeholder: intl.get('tasks.taskType'),
    labelKey: 'Description'
  },
  {
    ...selectPropsDefault,
    name: 'Source',
    label: intl.get('transports.compoCharge.actions.source'),
    placeholder: intl.get('transports.compoCharge.actions.source'),
    labelKey: 'Description'
  },
  {
    ...selectPropsDefault,
    name: 'Destination',
    label: intl.get('commons.destiny'),
    placeholder: intl.get('commons.destiny'),
    labelKey: 'Description',
    disabled: perfilPcpUnidadeLogado()
  },
  {
    ...selectPropsDefault,
    name: 'MaterialGroup',
    label: intl.get('commons.wallet'),
    labelKey: 'Description',
    placeholder: intl.get('commons.wallet'),
  },
  {
    ...selectPropsDefault,
    name: 'Family',
    label: intl.get('commons.family'),
    placeholder: intl.get('commons.family'),
    labelKey: 'Name',
  },
  {
    ...selectPropsDefault,
    name: 'LabeledFamily',
    label: intl.get('commons.labeledFamily'),
    placeholder: intl.get('commons.labeledFamily'),
    labelKey: 'Description'
  },
]);

export const toogleFilter = () => [
  {
    name: 'IsBaseLine',
    label: intl.get('tasks.baseLine'),
    style: { marginTop: '10px' },
    hidden: !isUserAmbev()
  }
]

export const validateIsFilteredTasks = (filters) => {
  delete filters["Page"]
  delete filters["Take"]
  let validate = false;
  Object.keys(filters).forEach(x => {
    if (Array.isArray(filters[x])) {
      if (filters[x].length > 0) {
        validate = true;
      }
    }
    else if (!!filters[x]) {
      validate = true;
    }
  })
  return validate;
}
