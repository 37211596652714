import React from 'react';
import moment from 'moment'
import * as S from './styled'
import intl from 'react-intl-universal'
import { formatNumber } from 'utils/format';

export const renderData = (data, title) => (
  <React.Fragment>
    {data.length > 0 &&
      <S.ContentRow>
        <S.Row>
          <S.TitleCatergory>{title}</S.TitleCatergory>
        </S.Row>
        {
          data.map((row, i) => (
            <S.Row key={i}>
              <S.ColFirst xs={6}>
                <S.InfosWrapper>
                  <S.DepositoTitle>{intl.get('commons.stock')} {row.Deposito}</S.DepositoTitle>
                  <S.LastUpdate>
                    {intl.get('commons.lastUpdate')}: {moment(row.DataLancamento).format("[DM]")}
                    {` ${intl.get('commons.at')} `}
                    {moment(row.HoraLancamento, "HH:mm:ss").format("HH:mm")}
                  </S.LastUpdate>
                </S.InfosWrapper>
              </S.ColFirst>
              <S.ColLast className='content-col' xs={6}>
                <S.InfosWrapper>
                  <S.StockTitle>{intl.get('commons.stock')}</S.StockTitle>
                  <span className='stock-content'>{formatNumber(row.Quantidade)} {row.UnidadeMedida}</span>
                </S.InfosWrapper>
              </S.ColLast>
            </S.Row>
          )
          )
        }
      </S.ContentRow>
    }
  </React.Fragment>
)
