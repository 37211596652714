import styled from 'styled-components'

export const Title = styled.span`
  font-weight: bold;
  font-size: 17px;
  line-height: 19px;
  text-transform: uppercase;
  color: var(--color-action-default);
`
export const Text = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: var(--color-contrast-brand);
`

export const Alert = styled.p`
  font-weight: normal;
  font-size: 17px;
  color: var(--color-feedback-danger-200);
  cursor: auto;
  > * {
    margin-right: 5px ;
}
`
