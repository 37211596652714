import PropTypes from "prop-types"
import React, { useState } from 'react'
import * as S from './styled'
import ModalReturnSapList from '../../modalReturnSapList'
import BtnSaveTranId from './components/btnSaveTranId'
import BtnSendToIvec from './components/btnSendIvec'
import BtnReleaseSave from './components/btnReleaseSave';
import BtnMoreLine from './components/btnMoreLine';
import BtnDeleteLine from './components/btnDeleteLine';
import BtnEditRange from './components/btnEditRange'
import ModalEditRange from './components/modalEditRange'

const HeaderList = ({
  businessUnit,
}) => {
  const [modalReturnSapListData, setModalReturnSapListData] = useState({
    isOpen: false,
    data: [],
    title: ''
  });
  const closeModalAssign = () => {
    setModalReturnSapListData({
      data: [],
      isOpen: false
    });
  }
  const [openModalEditRange, setOpenModalEditRange] = useState(false);
  const handleOpenModalEditRange = () => setOpenModalEditRange(true);

  return (
    <React.Fragment>
      <S.WrapperHeader>
        <S.Info>
          <h1>{businessUnit}</h1>
        </S.Info>
        <div className='separatordiv'>
          -
        </div>
        <BtnMoreLine />
        <BtnEditRange
          openModal={handleOpenModalEditRange}
        />
        <BtnReleaseSave />
        <BtnDeleteLine />
        <div className='separatordiv'>
          -
        </div>
        <BtnSaveTranId
          closeModalAssign={closeModalAssign}
          setModalAssignReturnListData={setModalReturnSapListData}
        />
        <BtnSendToIvec />
      </S.WrapperHeader>
      <ModalReturnSapList
        open={modalReturnSapListData.isOpen}
        data={modalReturnSapListData.data}
        closeModalAssign={closeModalAssign}
        title={modalReturnSapListData.title}
      />
      {openModalEditRange &&
        <ModalEditRange
          open={openModalEditRange}
          close={() => setOpenModalEditRange(false)}
          openReturnListData={setModalReturnSapListData}
        />
      }
    </React.Fragment>
  )
}

HeaderList.propTypes = {
  businessUnit: PropTypes.any.isRequired
}

export default HeaderList;
