import styled from 'styled-components'
import ColorPickers from 'colorpickers/index'

const StylesLastUpdate = styled.div`      
  display: inline-block;
  min-width: 330px;
  color: ${ColorPickers.blueSecond};
  font-size: 16px;

  .text {
    margin-left: 4px;
  }
  
  .data {
    font-weight: bold;
  }
`

export default StylesLastUpdate
