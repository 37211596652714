import PropTypes from "prop-types"
import React, { useEffect, useState } from 'react'
import Session from 'utils/user-storage'
import Filters from 'components/filter';
import { filtersTasksDefault, selectsFiltersDefault, toogleFilter } from '../../utils';
import {
  searchTiposTarefas,
  searchUsuariosMasterDataPCISolutions,
} from '../../tasks-vision.service';
import { searchFamily, searchLabeledFamily, searchMaterialGroup, searchSuppliers, searchBusinessUnit } from 'services/filters.service';

import * as S from './styled'

const FilterTaks = ({ searchTasks, isUseFilterDefault }) => {
  const idUsuario = Session.get().Id;
  const [selectsFilters, setSelectsFilters] = useState(selectsFiltersDefault(isUseFilterDefault));

  const getInitialFilters = () => {
    const cacheFilters = JSON.parse(localStorage.getItem('filtersTasksMulti'));
    const result = !!cacheFilters ? cacheFilters : filtersTasksDefault;

    if (isUseFilterDefault) {
      result.IdUsuario = [{ Id: idUsuario, Nome: Session.get().Nome }]
    }

    return result;
  }

  const proccessData = (filtersData) => {
    const _filters = selectsFilters.map(filter => ({
      ...filter,
      options: filtersData.find(x => x.name === filter.name).data
    }));

    setSelectsFilters([..._filters]);
  }

  const loadDataFilters = async () => {
    const [users, tasksType, source, destination, materialGroup, family, labeledFamily] =
      await Promise.all([
        searchUsuariosMasterDataPCISolutions(isUseFilterDefault, idUsuario),
        searchTiposTarefas(),
        searchSuppliers(isUseFilterDefault),
        searchBusinessUnit(isUseFilterDefault),
        searchMaterialGroup(isUseFilterDefault),
        searchFamily(isUseFilterDefault),
        searchLabeledFamily(isUseFilterDefault)
      ]);

    const filtersData = [
      { name: 'IdUsuario', data: users },
      { name: 'TasksType', data: tasksType },
      { name: 'Source', data: source },
      { name: 'Destination', data: destination },
      { name: 'MaterialGroup', data: materialGroup },
      { name: 'Family', data: family },
      { name: 'LabeledFamily', data: labeledFamily },
    ];

    proccessData(filtersData);
  }

  useEffect(() => {
    loadDataFilters()
  }, [])

  return (
    <S.Wrapper>
      <Filters
        handleClear={() => searchTasks(filtersTasksDefault)}
        handleSearch={filters => searchTasks(filters)}
        selectsFilters={selectsFilters}
        filtersDefault={getInitialFilters()}
        tooglesFilters={toogleFilter()}
      />
    </S.Wrapper>
  )
}

FilterTaks.propTypes = {
  isUseFilterDefault: PropTypes.any.isRequired,
  searchTasks: PropTypes.func.isRequired
}

export default FilterTaks;
