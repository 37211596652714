import intl from "react-intl-universal"

export const validarFiltros = (filtros) => {
  if (!filtros.plant) {
    return intl.get('autoLoadOptimization.feedbacks.requiredPlant')
  }
  else if (filtros.datas.deliveryDateFrom === '') {
    return intl.get('autoLoadOptimization.feedbacks.requiredPickUp')
  }
  else if (filtros.datas.deliveryDateTo === '') {
    return intl.get('autoLoadOptimization.feedbacks.requiredDeliveryDate')
  }
  return ""
}

export const validData = (data) => data && data.length > 0;


export const validSelectAll = (e, data) => {
  if (e.target.checked) {
    return data.map(x => x.Id)
  }
  else {
    return []
  }
}

export const validSendToIvec = (lines) => {
  if (lines.length === 0) {
    return intl.get('primaryMaterialScheduleList.feedbacks.mandatoryOneRecord')
  }
  if (lines.length > 1) {
    return intl.get('primaryMaterialScheduleList.feedbacks.onlyAllowedOneAtTime')
  }
  if (!lines[0].WmsTransactionId) {
    return intl.get('primaryMaterialScheduleList.feedbacks.necessaryTransactionId')
  }
  return "";
}


export const validSaveRelease = (lines) => {
  if (lines.length === 0 || lines.some(x => !x.WmsTransactionId)) {
    return intl.get('primaryMaterialScheduleList.feedbacks.necessaryTransactionId')
  }
  return "";
}

export const validarDadosInsert = (linha, processError) => {
  if (linha.Material === "" || !linha.Material) {
    processError(intl.get("feedbacks.messageMaterial"))
    return true
  }
  else if (linha.ScheduleAgreement === "" || !linha.SchedulingAgreement) {
    processError(intl.get("feedbacks.messageScheduleAgreement"))
    return true
  }
  else {
    return false
  }
}

export const validarDadosUpdate = (linha, processError) => {
  if (!linha.Material) {
    processError(intl.get("feedbacks.messageMaterial"))
    return true
  }
  else if (!linha.ScheduleAgreement) {
    processError(intl.get("feedbacks.messageScheduleAgreement"))
    return true
  }
  else {
    return false
  }
}
