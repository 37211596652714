export enum TypeOfAutoPilotScheduling {
  Default = 0,
  Custom = 1
}

export enum AutoPilotPeriodEnum {
  AllDay = 0,
  Scheduled = 1
}

export enum AutoPilotOrderCutType {
  Daily = 1,
  Weekly = 2
}