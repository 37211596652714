import PropTypes from "prop-types"
import React from 'react'
import { Button } from 'components/uikit-adapter/index'
import { AcceptCancel, RefuseCancel } from '../em-cancelamento.service'
import { useGlobalContext } from 'hooks/useGlobalContext';
import { formatErrorMessage } from 'utils/handle-error';
import intl from 'react-intl-universal'

const ActionsInCancellation = ({ stopLoading, startLoading, refresh, idPedido }) => {
  const { showFeedback } = useGlobalContext();

  const handleAccept = async () => {
    startLoading()
    try {
      await AcceptCancel(idPedido);
      refresh()
      showFeedback(intl.get('stocks.timeline.feedback.orderCanceledSuccessfully'))
    }
    catch (e) {
      showFeedback(formatErrorMessage(e))
    }
    stopLoading()
  }

  const handleRefuse = async () => {
    try {
      startLoading()
      await RefuseCancel(idPedido)
      showFeedback(intl.get('stocks.timeline.feedback.cancelRefuse'))
      refresh()
    }
    catch (e) {
      showFeedback(formatErrorMessage(e))
    }
    stopLoading()
  }

  return (
    <React.Fragment>
      <Button
        value={intl.get('privacyPolicyModal.rejectButton')}
        type="default"
        onClick={handleRefuse}
        width={"166px"}
        className="button_timeline_cancel_reject"
      />
      <Button
        value={intl.get('stocks.suggestionsListItem.cancelOrder')}
        type="primary"
        onClick={handleAccept}
        width={"166px"}
        className="button_timeline_cancel"
      />
    </React.Fragment>
  )
}

ActionsInCancellation.propTypes = {
  idPedido: PropTypes.any.isRequired,
  refresh: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  stopLoading: PropTypes.func.isRequired
}

export default ActionsInCancellation;
