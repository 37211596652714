import styled from 'styled-components'

export const Wrapper = styled.div`
${p => p.type === 'default' && renderStyledDefault()}
${p => p.disabled && `
  input{
    background-color: var(--color-neutral-200);
    box-shadow: inset 0 2px 0 0 rgb(149 163 183 / 30%);
  }
`}
`
export const Label = styled.label`
  line-height: 1.8rem;
  font-size: 12px;
  font-weight: var(--font-weight-semibold);
  text-transform: uppercase;
  color: var(--color-contrast-brand);
`

const renderStyledDefault = () => `input{
    background-color: var(--color-contrast-white);
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    font-size: 14px;
    color: var(--color-contrast-brand);
    height: 40px;
    outline: 0;
    border: 1px;
    border-radius: 2px;
    box-shadow: inset -1px 0 0 0 var(--color-neutral-300), inset 0 -1px 0 0 var(--color-neutral-300), inset 1px 0 0 0 var(--color-neutral-300), inset 0 2px 0 0 var(--color-action-hover);
    -webkit-transition: .25s box-shadow;
    transition: .25s box-shadow;
  }
`
