import { NotificationAction } from "models/notifications/notification-action";

export const getActionNotificationUrl = (metadata) => {
  switch(metadata.Action)
  {
   case NotificationAction.NEW_CONTRACT:
      return "/master-data/material";

    case NotificationAction.NEW_LOAD_COMPOSITION:
    case NotificationAction.UPDATED_LOAD_COMPOSITION:
      return `composicao/${metadata.OrderId}`;

    case NotificationAction.CANCELED_ORDER:
      return `ordersVisionDetail/${metadata.OrderId}/P`;

    default:
      return null;
  }
};