import React from "react";
import intl from "react-intl-universal";
import { StyledProductionPlanReportDisclaimer } from "./styled";

const ProductionPlanReportDisclaimer = () => {
  return (
    <StyledProductionPlanReportDisclaimer className="production-plan-report-disclaimer">
      <p>{intl.get("reports.messages.productionPlanDisclaimer")}</p>
    </StyledProductionPlanReportDisclaimer>
  );
};

export default ProductionPlanReportDisclaimer;
