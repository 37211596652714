import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './screen-default.css'

class ScreenDefault extends Component {
  constructor() {
    super()
    this.state = {
    }
  }

  render() {
    const { screenLeft, screenRight } = this.props

    return (
      <div className='screen-container'>
        <div className='screen-left'>
          {screenLeft}
        </div>        
        <div className='screen-right'>
            {screenRight}
        </div>
      </div>
    )
  }
}

ScreenDefault.propTypes = {
  screenLeft: PropTypes.object.isRequired,
  screenRight: PropTypes.object.isRequired,
}

export default ScreenDefault
