import Fetch from "utils/fetch";

export const getAllSuppliersService = (useFilterDefault, userId) => {
  return Fetch.get(`/fornecedor/obterTodoFornecedoresComposicao/${useFilterDefault}/${userId}`).then(({ data }) => data)
}

export const getAllBusinessUnitsService = (userId) => {
  return Fetch.get(`/unidadeNegocio/obterTodasUnidadesNegocioComposicao/${userId}`).then(({ data }) => data)
}

export const getAllSituationsService = ( ) => {
  return Fetch.get(`/load-compositions:statuses`).then(({ data }) => data)
}

export const getAllWalletsService = (useFilterDefault, userId) => {
  return Fetch.get(`/carteira/todasPorUsuario/${useFilterDefault}/${userId}`).then(({ data }) => data)
}

export const getAllLabeledFamiliesService = (useFilterDefault, userId) => {
  return Fetch.get(`/familiaRotulada/familiaRotuladaDoEstoque/${useFilterDefault}/${userId}`).then(({ data }) => data)
}

export const getAllScheduleAgreementsService = () => {
  return Fetch.get(`/orders:scheduling-agreements`).then(({ data }) => data)
}