import intl from 'react-intl-universal'
import { perfilPcpUnidadeLogado, userAmbev } from 'services/permissions/getPermissions'; 
import { selectPropsDefault } from './utils';
import { isPurchaseOrderFlow, isScheduleAgreementFlow } from "utils/validations-general-settings";

export const selectsFilter = () => ([
  {
    ...selectPropsDefault,
    name: 'Status',
    label: intl.get('requestOrders.step'),
    placeholder: intl.get('requestOrders.step'),
    labelKey: 'Description',
  },
  {
    ...selectPropsDefault,
    name: 'SupplierIds',
    placeholder: intl.get('commons.source'),
    label: intl.get('commons.source'),
    valueKey: 'Id',
    labelKey: 'Description',
    hidden: !userAmbev()
  },
  {
    ...selectPropsDefault,
    name: 'BusinessUnitIds',
    placeholder: intl.get('transports.compoCharge.actions.unityBussines'),
    label: intl.get('requestOrders.destiny'),
    labelKey: 'Description',
    disabled: perfilPcpUnidadeLogado()
  },
  {
    ...selectPropsDefault,
    name: 'MaterialGroupIds',
    placeholder: intl.get('commons.wallet'),
    label: intl.get('commons.wallet'),
    labelKey: 'Description'
  },
  {
    ...selectPropsDefault,
    name: 'MaterialsNumber',
    label: intl.get("commons.material"),
    placeholder: intl.get("commons.material"),
    labelKey: 'Description',
  }
]);

export const switchesFilter = () => [
  {
    name: 'PendingItems',
    label: intl.get('requestOrders.pedingItens'),
    style: { marginTop: '10px' },
    styleForm: { order: '-1' },
    labelPosition: "left"
  }
]

export const inputsMultiFilter = (isOrderVolume) => [
  {
    name: isOrderVolume ? 'VolumeOrderId' : 'OrdersId',
    labelText: isOrderVolume ? intl.get('loadOptimization.orderCodeVolume') : intl.get('requestOrders.codPortalRequest'),
    className: 'portal-order-code-filter'
  },
  {
    name: 'WmsTransactionsId',
    labelText: intl.get('requestOrders.WmsTransactionId'),
    hidden: isPurchaseOrderFlow()
  },
  {
    name: 'PoNumber',
    labelText: intl.get('requestOrders.PoSapCode'),
    hidden: isScheduleAgreementFlow() || isOrderVolume
  }
]

export const dateRangeFilter = () => ([
  {
    labelDe: intl.get('commons.collectDateDE'),
    labelAte: intl.get('commons.collectDateAT'),
    dataDe: 'CollectDateMin',
    dataAte: 'CollectDateMax',
    noMinDate: true,
    hidden: userAmbev()
  },
  {
    labelDe: intl.get('requestOrders.deliveryDateDE'),
    labelAte: intl.get('requestOrders.deliveryDateAT'),
    dataDe: 'DeliveryDateMin',
    dataAte: 'DeliveryDateMax',
    noMinDate: true,
  }
]);

export const hourRangeFilter = () => ([
  {
    name: "hourRange",
    labelFrom: intl.get('commons.deliveryTimeFrom'),
    labelTo: intl.get('commons.deliveryTimeTo'),
    hourFrom: 'DeliveryTimeMin',
    hourTo: 'DeliveryTimeMax',
  },
]);
