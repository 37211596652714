import React from 'react'
import { BottlesHeader} from '../bottles-header'
import { EditScreen } from '../bottles-edit-screen'
import { ViewScreen } from '../bottles-view-screen'
import { GeneralFilter } from '../bottles-filter/general'
import { useBottlesContext } from '../../context'
import { Types } from '../../models/types'

export const Bottles = () => {
  const { isEditMode } = useBottlesContext()

  return (
    <React.Fragment>
      <BottlesHeader />
      <GeneralFilter
        type={isEditMode ? Types.Edicao : Types.Visualizacao}
      />
      {isEditMode ?
        <EditScreen /> :
        <ViewScreen />
      }
    </React.Fragment>
  )
}



