import styled from "styled-components";

export const Wrapper = styled.div`
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  padding: 32px;
  background: #ffffff;
  min-height: calc(100vh - 110px);
  table {
    padding: 16px 0px;
    width: 100%;
    text-align: justify;
  }
  th,
  td {
    padding: 4px;
    color: var(--Contrast-Brand, #081826);
    font-size: 12px;
    font-weight: 400;
  }

  th {
    background: #f2f5f9 !important;
    font-weight: 600;
  }
  span{
    cursor: auto;
  }
`;

export const Logo = styled.img`
  width: 174px;
`;

export const WrapperHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 5px;
  align-items: center;
`;

export const WrapperMain = styled.div`
  footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f2f5f9;
    height: 40px;
    font-size: 16px;
    font-weight: 600;
  }
  header {
    font-size: 12px;
    font-weight: 400;
    display: flex;
    span {
      width: 50%;
      background: #f2f5f9;
      padding: 4px;
      margin-left: 10px;

      &:first-child {
        margin-left: 0px;
      }
    }
  }
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OrderInfos = styled.div`
  display: flex;
  flex-direction: column;
  div {
    padding: 4px 8px;
    align-items: center;
  }
  > div {
    &:first-child {
      span {
        font-weight: 600;
      }
      background: #f2f5f9;
    }
  }
`;
