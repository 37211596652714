import styled from "styled-components";

export const StyledValues = styled.div`
  width: 100%;
  display: flex;
  ${p =>
    p.onClick &&
    `
      cursor: pointer;
      &:hover {
        .etb-column,
        .etb-label {
          background-color: #f1f1f1;
        }
      }
    `};
`;

