import abInbevLogo from 'images/abinbev_logo.png';
import abOntimeLogoBlue from 'images/abOntimeLogoWithoutDS.png';
import abOntimeLogoPurple from 'images/abOntimeLogoDS.png'
import * as S from './styled'
import { OptionsTheme } from 'pages/master-data/configurations/general-settings/models/generalSettingsModel';

export const NavbarLogo = () => {
  const getLogoOntime = () => {
    const theme = localStorage.getItem("THEME");

    if (!theme || theme == OptionsTheme.Legado) {
      return abOntimeLogoBlue;
    }
    if (theme == OptionsTheme.DesignSystem) {
      return abOntimeLogoPurple;
    }
  };

  return (
    <S.Wrapper>
      <S.Logo src={abInbevLogo} />
      <S.Separator />
      <S.LogoOntime src={getLogoOntime()} />
    </S.Wrapper>
  )
};