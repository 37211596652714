import React from 'react'
import PropTypes from 'prop-types'
import intl from 'react-intl-universal'
import moment from 'moment'
import { formatDeliveryTime } from 'utils/delivery-time'

const PickUpDeliveryDateDeliveryTime = ({
  pickUpDate,
  deliveryDate,
  deliveryTime
}) => (
  <div>
    <div className="negotiation-read-offer">
      <div className="negotiation-read-column">
        <span>{intl.get('stocks.collectionDate')}</span>
        <span>{moment(pickUpDate).format('L')}</span>
      </div>
    </div>
    <div className="negotiation-read-offer">
      <div className="negotiation-read-column">
        <span>{intl.get('stocks.timeline.deliveryDate')}</span>
        <span>{moment(deliveryDate).format('L')}</span>
      </div>
    </div>
    <div className="negotiation-read-column">
      <span>{intl.get('commons.deliveryTime')}:</span>
      <span>{formatDeliveryTime(deliveryTime)}</span>
    </div>
  </div>
);

PickUpDeliveryDateDeliveryTime.propTypes = {
  pickUpDate: PropTypes.string.isRequired,
  deliveryDate: PropTypes.string.isRequired,
  deliveryTime: PropTypes.string.isRequired,
}

export default PickUpDeliveryDateDeliveryTime
