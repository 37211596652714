import Fetch from 'utils/fetch'
import { generateQueryParams } from 'utils/custom-functions'

export const getVolumesPaginated = (filters) => (
  Fetch.get(`volume-orders:paginated?${generateQueryParams(filters)}`).then(({ data }) => data)
)

export const getLoadsOptimizedByVolumes = (_body) =>
  Fetch.post('load-compositions:build-optimization', _body).then(({ data }) => data)

export const getCalculatedVolumesOptimization = (_params) => (
  Fetch.get(`load-compositions:optimize?${generateQueryParams(_params)}`).then(({ data }) => data)
)

export const updateVolumeOrder = (id, body) => (
  Fetch.patch(`volume-orders/${id}`, body).then(({ data }) => data)
)
