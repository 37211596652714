import TipoUsuario from 'models/usuarios/tipo-usuario'
import Session from 'utils/user-storage'
import TipoPerfilAcesso from 'models/usuarios/tipo-perfil-acesso'

export const userAmbev = () => Session.get().TipoUsuario === TipoUsuario.Ambev

export const userFornecedor = () => Session.get().TipoUsuario === TipoUsuario.Fornecedor

export const userSistema = () => Session.get().TipoUsuario === TipoUsuario.Sistema

export const usaCarteira = () => Session.get().UsaCarteiraDiaria

export const perfilPcpUnidadeLogado = () => Session.get().PerfilAcesso === TipoPerfilAcesso.PcpUnidade

export const perfilPciLogado = () => Session.get().PerfilAcesso === TipoPerfilAcesso.PciSolutions

export const perfilGestorLogado = () => Session.get().PerfilAcesso === TipoPerfilAcesso.Gestor

export const profileAdminOrMasterData = () =>
  Session.get().PerfilAcesso === TipoPerfilAcesso.Administrador ||
  Session.get().PerfilAcesso === TipoPerfilAcesso.MasterData

export const verifyUserByProfile = (profiles) =>
  profiles.some(x => x === Session.get().PerfilAcesso)
