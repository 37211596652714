import Fetch from 'utils/fetch';
import { SituacaoComposicaoCarga } from "models/composicaoCarga/situacaoComposicaoCarga";
import { buscarComposicaoPorPedido, searchComposition } from "pages/composicao-carga/form/composicao-carga-form.service";
import { usesEmergencyNegotiation } from 'utils/validations-general-settings';

export const getLoadCompositionAndNegotiations = async (id) => {
  const _load = await searchComposition(id);
  if (_load.SituacaoValor == SituacaoComposicaoCarga.NegociacaoEmergencial && usesEmergencyNegotiation()) {
    return Fetch.get(`/load-compositions/${id}/negotiations:active`)
      .then(({ data }) => data)
      .then(x => normalizeData(x, _load))
  } else {
    return _load;
  }
}

export const getLoadByOrderId = async (orderId) => {
  const _load = await buscarComposicaoPorPedido(orderId);
  if (_load.SituacaoValor == SituacaoComposicaoCarga.NegociacaoEmergencial && usesEmergencyNegotiation()) {
    return Fetch.get(`/load-compositions/${_load.IdComposicao}/negotiations:active`)
      .then(({ data }) => data)
      .then(x => normalizeData(x, _load))
  } else {
    return _load;
  }
}

const normalizeData = (_data, _load) => ({
  ..._load,
  "IdComposicao": _data.IdLoadComposition,
  "DataColeta": _data.PickupDateSuggested,
  "DataEntrega": _data.DeliveryDateSuggested,
  "DataEntregaSugerida": _data.DeliveryDateSuggested,
  "HoraEntrega": _data.DeliveryTimeSuggested,
  "HoraEntregaSugerida": _data.DeliveryTimeSuggested,
  "QuantidadeTotal": _data.Quantity,
  "SituacaoValor": _load.SituacaoValor,
  "IdFornecedor": _data.IdSupplier,
  "IdUnidadeNegocio": _data.IdBusinessUnit,
  "BreweryNote": _data.BreweryNote,
  "Centro": _data.Center,
  "Door": _data.Door,
  "Fornecedor": _data.Supplier,
  "Observacao": _data.Observation,
  "ObservacaoSugerida": _data.Observation,
  "UnidadeNegocio": _data.BusinessUnit,
  "OrdersNegotiations": _data.Orders,
  "UserTypeNextStep": _data.UserTypeNextStep,
  "OriginalCompositionValues": _load
})

export const acceptLoadCompositionEmergencyNegotiation = (idLoadComposition) => {
  return Fetch.post(`/load-compositions/negotiations:accept-emergency`,
    { LoadId: idLoadComposition }).then(({ data }) => data);
}

export const normalizeDataInEnglish = (_data) => ({
  "LoadCompositionId": _data.IdComposicao,
  "PickupDateSuggested": _data.DataColeta,
  "DeliveryDateSuggested": _data.DataEntrega,
  "DeliveryDateSuggested": _data.DataEntregaSugerida,
  "DeliveryTimeSuggested": _data.HoraEntrega,
  "DeliveryTimeSuggested": _data.HoraEntregaSugerida,
  "Quantity": _data.QuantidadeTotal,
  "SituationValue": _data.SituacaoValor,
  "IdSupplier": _data.IdFornecedor,
  "IdBusinessUnit": _data.IdUnidadeNegocio,
  "BreweryNote": _data.BreweryNote,
  "Center": _data.Centro,
  "Door": _data.Door,
  "UserTypeNextStep": _data.UserTypeNextStep,
  "Supplier": _data.Fornecedor,
  "Observation": _data.Observacao,
  "BusinessUnit": _data.UnidadeNegocio,
  "Orders": _data.Pedidos,
  "OrdersSAP": _data.PedidosSap,
  "ReadOnly": _data.ModoLeitura,
  "ResponsavelProximaAcao": _data.ResponsavelProximaAcao
})