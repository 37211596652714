
import PropTypes from "prop-types"
import React from 'react'
import intl from 'react-intl-universal'
import moment from 'moment'
import * as S from './styled'
import { styles } from './styled'
import { getTodayDate } from 'utils/date'
import { getKeys, shiftElement } from '../bottles-tabs/utils'
import { stageOfWeek, validateEditRow } from './utils'

export const RowSimulated = ({ d, keys }) => {
  return (
    <React.Fragment>
      <S.TableCell>
        <span>{intl.get(`weekdays.${moment(d.Date).weekday()}`)}</span>
        <span>{moment(d.Date).format("DD/MM/YYYY")}</span>
      </S.TableCell>
      {
        keys.map((value) => (
          <S.TableCell key={value}><S.TableCellEditable className="input" placeholder="-" type="number" /></S.TableCell>
        ))
      }
    </React.Fragment>
  )
}

RowSimulated.propTypes = {
  d: PropTypes.shape({
    Date: PropTypes.any
  }).isRequired,
  keys: PropTypes.shape({
    map: PropTypes.func
  }).isRequired
}

const renderCollapsedItems = (d, type, handleRecalculateValues) => {
  const keys = type === 'supplier' ?
    {
      key: 'outgoingData',
      prop: 'outgoing'
    } :
    {
      key: 'entry',
      prop: 'outgoing'
    }

  return d[keys.key].map((prop, index) => (
    <S.TableCell className='collapsed-cell' key={`${prop.BusinessUnitId}-${d.Date}`}>
      {type === 'factorie' ? prop[keys.prop] :
        <S.TableCellEditable
          data-testid={`input-edit-row-${index}`}
          defaultValue={prop[keys.prop]}
          className="input"
          placeholder="-"
          type="number"
          disabled={(new Date(d.Date)) < getTodayDate()}
          onChange={(e) => handleRecalculateValues(prop.BusinessUnitId, d.Date, e.target.value, d[keys.key])}
        />
      }
    </S.TableCell>
  ))
}

export const RowTable = ({
  d,
  isAdvancedMode,
  keysForCollapse,
  collapsed,
  type,
  handleRecalculateValues,
  handleChangeAdvancedMode
}) => {

  const day = intl.get(`weekdays.${moment(d.Date).weekday()}`)

  return (
    <React.Fragment>
      <S.TableCell>
        <span>{day}</span>
        <span>{moment(d.Date).format("DD/MM/YYYY")}</span>
      </S.TableCell >
      {
        shiftElement(getKeys(type)).map((key, index) => (
          <React.Fragment key={`${key}-${d.Date}`}>
            {ReturnCells(key, index, d, isAdvancedMode, handleChangeAdvancedMode)}
            {(collapsed && keysForCollapse(key)) && renderCollapsedItems(d, type, handleRecalculateValues)}
          </React.Fragment>
        ))
      }
    </React.Fragment>
  )
}

RowTable.propTypes = {
  collapsed: PropTypes.func.isRequired,
  d: PropTypes.shape({
    Date: PropTypes.any
  }).isRequired,
  handleChangeAdvancedMode: PropTypes.any.isRequired,
  handleRecalculateValues: PropTypes.any.isRequired,
  isAdvancedMode: PropTypes.any.isRequired,
  keysForCollapse: PropTypes.func.isRequired,
  type: PropTypes.any.isRequired
}

export const ReturnCells = (
  key,
  index,
  d,
  isAdvancedMode,
  handleChangeAdvancedMode,
) => {

  const keys = key !== 'WeeklyPlan' ? 'Default' : 'WeeklyPlan'

  const components = {
    'Default': () => CellsDefault(validateEditRow(key, isAdvancedMode), d, key, index, handleChangeAdvancedMode),
    'WeeklyPlan': () => CellsWeeklyPlan(isAdvancedMode, d, key, handleChangeAdvancedMode),
  }

  return components[keys]()
}

export const CellsDefault = (isEditMode, d, key, index, handleChangeAdvancedMode) => (
  isEditMode ?
    <S.TableCell key={key}>
      <S.TableCellEditable
        data-testid={`input-edit-row-${index}`}
        value={d[key]}
        className="input"
        placeholder="-"
        type="number"
        onChange={(e) => handleChangeAdvancedMode(d, e.target.value, key)}
        disabled={(new Date(d.Date)) < getTodayDate()}
      />
    </S.TableCell>
    :
    <S.TableCell
      data-testid={`tabble-cell-${index}`}
      key={index}
    >
      {d[key]}
    </S.TableCell >
)


export const CellsWeeklyPlan = (isAdvancedMode, d, key, handleChangeAdvancedMode) => {
  const { position, arr, calc } = stageOfWeek(d.Date)
  const styleElement = calc === 0 ? styles.firstElement : styles.emptyElement

  if (position === (calc === 0 ? calc : calc - 1)) {
    return WeeklyPlanEditableCell(isAdvancedMode, d, key, handleChangeAdvancedMode, styleElement)
  }
  else if ((position !== calc) && position === 0) {
    return WeeklyPlanCell(styles.firstElement)
  }
  else if ((position !== calc) && position === (arr.length - 1)) {
    return WeeklyPlanCell(styles.lastElement)
  }
  else {
    return WeeklyPlanCell(styles.emptyElement)
  }
}


const WeeklyPlanCell = (styles, value) => (
  <S.TableCell
    style={{ ...styles }}>
    {value ? value : ' - '}
  </S.TableCell>
)

const WeeklyPlanEditableCell = (isAdvancedMode, d, key, handleChangeAdvancedMode, element) => (
  isAdvancedMode ?
    <S.WeeklyPlanEditableCell
      value={d[key]}
      className="input"
      placeholder="-"
      type="number"
      onChange={(e) => handleChangeAdvancedMode(d, e.target.value, key)}
      disabled={(new Date(d.Date)) < getTodayDate()}
    />
    : WeeklyPlanCell(element, d[key])
)

