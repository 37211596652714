import PropTypes from "prop-types"
import React from 'react'
import SelectOption from 'components/select-option';
import intl from 'react-intl-universal'
import Option from 'components/select-option/option';
import { ImgGrid, ImgLista } from "components/icons/icn-index.icon";

export const visionTypes = {
  Grid: "Grid",
  List: "List"
}

const VisionOption = ({ visionType, changeVision }) => {
  return (
    <div className={`div-filters align-tipo-visualizacao`}>
      <div className="btn-tipo-visualizacao">
        <div className="selectOptions">
          <SelectOption>
            <div title={intl.get('commons.vision.listView')}>
              <Option
                active={visionType === visionTypes.List ? 'active' : ''}
                value={visionTypes.List}
                handleClickIcon={() => changeVision(visionTypes.List)}
                className="btnOption"
                data-testid='btn-vision-list'
              >
                <ImgLista/>
              </Option>
            </div>
            <div title={intl.get('commons.vision.cardView')}>
              <Option
                active={visionType === visionTypes.Grid ? 'active' : ''}
                value={visionTypes.Grid}
                handleClickIcon={() => changeVision(visionTypes.Grid)}
                className="btnOption"
              >
                <ImgGrid />
              </Option>
            </div>
          </SelectOption>
        </div>
      </div>
    </div>
  )
}

VisionOption.propTypes = {
  changeVision: PropTypes.func.isRequired,
  visionType: PropTypes.any.isRequired
}

export default VisionOption;
