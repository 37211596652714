import PropTypes from "prop-types"
import React from 'react'
import { Button } from 'components/uikit-adapter/index'
import intl from 'react-intl-universal'
import * as S from './styled'

const CancelDialog = ({ title, close, open, message, confirm }) => {
  return (
    <S.CancelDialogWrapper
      className='load-composition-modal-cancel'
      title={title}
      contentStyle={{ width: '400px' }}
      open={open}
      onRequestClose={close}
      actionsContainerClassName="container-action-buttons__deleteModal"
      actions={
        [
          <Button
            value={intl.get('geral.modalConfirm.goBack')}
            data-testid="buttonCancel"
            type='default'
            onClick={close}
            className='container-action-buttons__deleteModal--buttons back'
          />,
          <Button
            value={intl.get('geral.modalConfirm.yes')}
            type='danger'
            onClick={confirm}
            className='container-action-buttons__deleteModal--buttons delete'
          />
        ]
      } >
      <div className='container-message'>
        {message}
      </div>
    </S.CancelDialogWrapper>
  )
}

CancelDialog.propTypes = {
  close: PropTypes.any.isRequired,
  confirm: PropTypes.any.isRequired,
  message: PropTypes.any.isRequired,
  open: PropTypes.any.isRequired,
  title: PropTypes.any.isRequired
}
export default CancelDialog
