import React from 'react'

const IconEmpty = () => (
	<svg viewBox="0 0 24 24" version="1.1" xmlns="https://www.w3.org/2000/svg">
    <title>089F7B8B-B2A5-4AFE-B5C0-19BA573D28AC</title>
    <desc>Created with sketchtool.</desc>
    <defs></defs>
</svg>
)

export default IconEmpty
