import { Fragment } from 'react'
import { menuData } from '../../data/menu-data';
import { NavbarMenuItem } from '../navbar-menu-item';
import { NavbarSubmenus } from '../navbar-submenus';
import * as S from './styled';

export const NavbarMenu = () => {
  const data = menuData();
  const baseUrl = window.location.pathname?.split('/')[1];

  return (
    <S.Wrapper>
      {data.map((menu, idx) => (
        menu.visible && (
          <Fragment key={idx}>
            {!menu.submenus ?
              <NavbarMenuItem
                label={menu.label}
                redirectPath={menu.path}
                baseUrl={baseUrl}
                dataTestId={menu.dataTestId}
              />
              :
              <NavbarSubmenus
                label={menu.label}
                submenus={menu.submenus}
                baseUrl={baseUrl}
                idx={idx}
                dataTestId={menu.dataTestId}
              />
            }
          </Fragment>
        )))}
    </S.Wrapper>
  )
}
