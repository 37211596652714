import { Label } from "./styled";
import PropTypes from 'prop-types'

export const TextSpan = ({ color, display, children, ...rest }) =>
  <Label
    title={children}
    color={color}
    display={display}
    {...rest}
  >
    {children}
  </Label>

  TextSpan.propTypes = {
    display: PropTypes.any,
    color: PropTypes.any,
    children: PropTypes.any
  }