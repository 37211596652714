import React, { useState, useEffect } from 'react'
import intl from 'react-intl-universal'
import LoadingProgress from 'components/circular-progress'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { BottleTable } from '../../bottles-table'
import { BottlesNewTab } from '../../bottles-new-tab'
import { SupplierDropdown } from '../../bottles-supplier-dropdown'
import BottlesGhostSupplierDropdown from '../../bottles-ghost-supplier-dropdown'
import { Vector } from '../../bottles-dropdown/vector'
import { getFontColor, getSupplierName } from '../utils'
import { addSupplierBottles, getBottlesPlan } from '../../../services/bottles-plan.service'
import { dataMapper } from '../../bottles-edit-screen/utils'
import { useGlobalContext } from 'hooks/useGlobalContext'
import { useBottlesContext } from '../../../context'
import AlertVector from 'images/AlertVector.svg'
import * as S from '../styled'

export const BottlesSupplierTabs = () => {
  const { showFeedback } = useGlobalContext()
  const { data, setData, filter, setSelectedSupplier } = useBottlesContext()
  const [tabIndex, setTabIndex] = useState(0)
  const [suppliers, setSuppliers] = useState([]);
  const [isLoading, setIsLoading] = useState(false)

  const getHasSupplier = () => suppliers.length > 0
  const isGhostSupplier = (supplierData) => supplierData.find((data) => data.SupplierId === null)

  const addNewTab = async (event) => {
    setIsLoading(true)
    try {
      const paramsAddSupplier = {
        LabeledFamilyId: filter.labeledFamilyId[0],
        SupplierId: event.idSupplier,
        SupplierName: event.target.value,
        Period: new Date(filter.periodDate.year, filter.periodDate.month, 1),
      }

      const registeredSupplierNames = getSupplierName(suppliers)

      if (registeredSupplierNames.includes(paramsAddSupplier.SupplierName.toUpperCase())) {
        showFeedback(intl.get('bottles.addSupplierValidationMessage'))
        setIsLoading(false)
        return
      }

      await addSupplierBottles(paramsAddSupplier)
      const response = await getBottlesPlan(filter)

      const _newData = dataMapper(response.Items)
      setData(_newData)
      setTabIndex(_newData.suppliers.length - 1)

    } catch (e) {
      console.error(e)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (data) {
      setSuppliers(data.suppliers)
    }
  }, [data])


  useEffect(() => {
    if (getHasSupplier()) {
      setSelectedSupplier(suppliers[tabIndex].Data[0].SupplierId)
    }
  }, [tabIndex])


  useEffect(() => {
    if (getHasSupplier() && tabIndex === 0) {
      setSelectedSupplier(suppliers[0].Data[0].SupplierId)
    }
  }, [suppliers])

  return (
    <S.TabsWrapper>
      <Tabs
        selectedTabClassName="active"
        selectedIndex={tabIndex}
        onSelect={(index) => setTabIndex(index)}
      >
        <S.TabsTitle>
          <div>
            <S.TabTitleText>{intl.get('commons.supplier')}</S.TabTitleText>
            {
              suppliers[tabIndex]?.Data[0].SupplierId === null &&
              <S.TabTitleTextWarning>
                <Vector width="16px" heigth="16px" vector={AlertVector} /> {`A Aba ${suppliers[tabIndex].Name} é uma simulação de um ${intl.get('commons.supplier')}!`}
              </S.TabTitleTextWarning>
            }
          </div>
          <SupplierDropdown />
        </S.TabsTitle>
        <S.TabsHeader>
          <TabList >
            {
              suppliers.map((tab, index) => (
                <Tab key={tab.Name} data-testid={`tab__${index}`}>
                  <S.TabTitle color={getFontColor(isGhostSupplier(tab.Data), tabIndex === index)}>
                    {tab.Name}
                  </S.TabTitle>
                  <BottlesGhostSupplierDropdown
                    suppliers={suppliers}
                    selectedSupplier={{
                      Name: suppliers[tabIndex].Name,
                      Id: suppliers[tabIndex].Data[0]?.SupplierId
                    }}
                  />
                </Tab>
              ))
            }
          </TabList>
          {isLoading ? <LoadingProgress id='loading-tab' /> : <BottlesNewTab addNewTab={addNewTab} />}
        </S.TabsHeader>
        <S.TabsContent>
          {
            suppliers.map((row, index) => (
              <TabPanel key={index}>
                <BottleTable
                  data={row.Data}
                  simulado={row.IsGhostSupplier}
                  type={'supplier'}
                  tabIndex={tabIndex}
                  supplier={suppliers[tabIndex]}
                />
              </TabPanel>
            ))
          }
        </S.TabsContent>
      </Tabs >
    </S.TabsWrapper >
  )
}
