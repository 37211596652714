import Fetch from "utils/fetch";

export const getIntegracoes = async () => {
    const response = await Fetch.get(`/integracao/obterIntegracao`);
    return response.data;
};

export const getWallets = async () => {
    const response = await Fetch.get(`/material-groups:all`);
    return response.data;
};

export const getLabeledFamilies = async () => {
    const response = await Fetch.get(`/labeled-families:all-with-labeled-family-centers`);
    return response.data;
};




