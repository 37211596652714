import styled from 'styled-components';
import PropTypes from 'prop-types';

const RoundButtonStyled = styled.button`
  display: flex;
  justify-content: end;
  cursor: pointer;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  border: transparent;
  background-color: transparent;
  border-radius: 50%;

  :hover{
    background-color: var(--color-action-hover);
  }
`;

export const RoundButton = ({ iconHeight, children, ...rest }) => (
  <RoundButtonStyled {...rest}>
    <span style={{ height: iconHeight }}>{children}</span>
  </RoundButtonStyled>
)

RoundButton.propTypes = {
  iconHeight: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired
}
