import "moment/locale/es";

const momentEsEs = {
  parentLocale: "es",
  longDateFormat: {
    "[DM]": "DD/MM",
    "[DDMMYY]": "DD/MM/YY"
  }
};

export default momentEsEs;
