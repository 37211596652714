import intl from 'react-intl-universal';

export const validationExports = (contextModal, filter) => {
  switch (contextModal) {
    case "masterData":
      return validationsToExportMasterData(filter);
    case "planningBottles":
      return validationsToExportPlanningBottles(filter);
    default:
      return null;
  }
}

const validationsToExportMasterData = (filter) => {
  let validation;
  if (filter?.materialGroup?.length <= 0) {
    validation = intl.get('exportImportModal.masterData.formValidation.material-group');
  }
  return validation;
}

const validationsToExportPlanningBottles = (filter) => {
  let validation;
  if (filter?.supplier?.length <= 0) {
    validation = intl.get('manual-suggestion-order-form.formValidation.supplier')
  }
  return validation;
}