import styled from "styled-components";

export const Wrapper = styled.div`
  width: 170px;
  margin: 10px;

  .sidebar-close {
    display: none;
  }
`;

export const FilterContainer = styled.div`
  overflow-y: scroll;
  height: calc(100vh - 110px) !important;
  background: var(--color-contrast-white);
  padding: 15px 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const FilterContainerFields = styled.div`
  white-space: normal;
`

export const FilterContainerFooter = styled.div`
  padding: 8px 0px;
  border-top: 1px solid var(--color-neutral-300);
`