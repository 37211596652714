import styled from 'styled-components'

export const Wrapper = styled.div`
  button{
    margin-top: 0;
  }

`

export const Title = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: var(--color-action-default);
  padding: 20px 20px 14px 20px;
  border-bottom: 1px solid var(--color-neutral-200);
`
export const Message = styled.div`
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  font-size: 16px;
  line-height: 14px;
  color: var(--color-contrast-black);
  padding: 12px 20px;
  border-bottom: 1px solid var(--color-neutral-200);
`

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 0px;
`
