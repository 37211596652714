import styled from 'styled-components'
import StyledGrid from 'components/styled-grid'

const StyledModalErroShipment = styled(StyledGrid)`  

  width: 100%;
  padding: 0px 8px 0 8px;

  .align-center {
    text-align: center;
  }
  
  .containerList {
    background-color: white;
    height: calc(50vh - 143px);
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .rowListHeader {
    width: 100%;
  }
  
  .pagination {
    justify-content: flex-end;
  }
  
  .no-results {
    font-size: 14px;
  }
  
  .pt-10 {
    padding-top: 10px;
  }
  
  .pb-10 {
    padding-bottom: 10px;
  }

  .hintList:hover .hintListComponent {
    display: block;
  }

  .text-format {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .footer-dialog-buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.line-buttons {
  display: flex;
  justify-content: space-between;
  justify-content: flex-end;
  width: 450px;
  position: relative;
  top: 16px;
  margin-right: 15px !important;
}

div[class="row"] {
  padding-top: 5px !important;
}
`

export default StyledModalErroShipment