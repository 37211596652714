export const normalizeMaterials = (data) => (
  data.map((item) => ({
    Id: item.Id,
    MaterialPesquisa: item.MaterialSearch,
    IdUnidadeArredondamento: item.RoundingUnitId,
    DescricaoUnidadeArredondamento: item.RoundingUnitDescription,
    Numero: item.Number,
    NomeMaterialComCodigoLegado: item.MaterialNameWithLegacyCode,
    NomeMaterialCadastro: item.MaterialNameRegistration,
    NumeroMaterialEcc: item.MaterialNumberEcc,
    NumeroMaterialS4H: item.MaterialNumberS4H,
    Rollout: item.Rollout,
    Fornecedores: item.Suppliers?.map((i) => ({
      Id: i.Id,
      Descricao: i.Description,
      TransitTime: i.TransitTime,
      Arredondamento: i.Rounding,
      LoteMinimo: i.MinimumLot,
      Origem: i.Origin,
      TipoFornecimentoSigla: i.SupplyTypeAcronym,
      TipoFornecimentoDescrição: i.SupplyTypeDescription,
      TipoFornecimentoValue: i.SupplyTypeValue,
      ResponsavelEntrega: i.ResponsibleForDelivery,
      TipoUnidade: i.UnitType,
      QuebraEntrega: i.DeliveryBreak,
      PrioridadeXadrez: i.Priority,
      CodigoImposto: i.TaxCode,
      GrupoCompradores: i.BuyersGroup,
      OrganizacaoCompras: i.PurchasingOrganization
    }))
  }))
);