import styled from 'styled-components'

const StyledFilter = styled.div`
  position: relative; 
 
  .closeDialog {
    right: 4px;
    padding: 0;
    width: 14px;
    height: 14px;
  }

  .input {
    box-shadow: inset -1px 0 0 0 var(--color-neutral-300), inset 0 -1px 0 0 var(--color-neutral-300), inset 1px 0 0 0 var(--color-neutral-300), inset 0 2px 0 0 var(--color-action-hover) 
    !important;
  }

  .filter-container {
    position: relative;

    .sideFilterFields {
      overflow-y: scroll;
      height: calc(100vh - 110px);
      background: var(--color-contrast-white);
      padding: 15px 20px 0;

      .row {
        margin-bottom: 5px;
      }

      .qtde-itens-programados {
        text-align: center;
        font-weight: bold;
      }
    }

    .footerFilterContainer {
      width: 100%;
      position: absolute;
      bottom: 0px;
      background: var(--color-contrast-white);
      padding: 15px 5px 15px 20px;

      .filter-divisor {
        background-color: rgba(197,208,225,0.6);
        height: 1px;
        width: 100%;
        color: rgba(197,208,225,0.6);
        border: none;
      }
    }
  }

  .modal-footer {
    .btn {
      margin-left: 10px;
    }
  }
`

export default StyledFilter
