import PropTypes from "prop-types"
import React from 'react'
import * as S from '../styled'
import moment from 'moment'
import { Tag } from '../tag'
import PurchaseOrderList from '../purchaseOrdersList'

const CardPendingReview = ({ data, handleClick, idCompositionSelected }) => (
  <>
    {data.map(composicao => (
      <S.Wrapper
        isSelected={composicao.Id === idCompositionSelected}
        background='var(--color-contrast-white)'
        color='#FBBA49'
      >
        <S.Hora isSelected={idCompositionSelected === composicao.Id}>
          {composicao && <span> {moment(composicao.Hour, "HH:mm:ss").format("HH:mm")}</span>}
        </S.Hora>
        <Tag
          onClick={() => handleClick(composicao)}
          status={composicao.Situation}
          data-testid={`pending-supplier-review-${composicao.Id}`}
        />
        <S.Content color='#FBBA49'>
          <PurchaseOrderList loadComposition={composicao} />
        </S.Content>
      </S.Wrapper>
    ))}
  </>
)

CardPendingReview.propTypes = {
  data: PropTypes.shape({
    map: PropTypes.func
  }).isRequired,
  handleClick: PropTypes.func.isRequired,
  idCompositionSelected: PropTypes.any.isRequired
}

export default CardPendingReview;
