import intl from 'react-intl-universal'

/**
  * @param {*} exception Exception to be formatted.
  * @returns Formatting exception received from endpoint, if not validated returns default message.
*/
export const formatErrorMessage = (exception) => {
  switch(exception?.response?.status){
    case 400:
      return exception.response.data.Errors[0]?.Detail;
    case 403:
      return intl.get('commons.unauthorized')
    default:
      return intl.get('stocks.timeline.feedback.serverError');
  }
}
