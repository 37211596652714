import {
  ClbTableTHead,
  ClbTableTHeadCell,
  ClbTableTHeadRow,
  ClbText,
} from "libs/celebration";
import intl from "react-intl-universal";
import { isUserSupplier } from "utils/user";

const Header = () => (
  <ClbTableTHead>
    <ClbTableTHeadRow>
      <ClbTableTHeadCell>
        <ClbText slot="content">{intl.get("commons.material")}</ClbText>
      </ClbTableTHeadCell>
      <ClbTableTHeadCell>
        <ClbText slot="content">{intl.get("supplierProductionPlan.table.plant")}</ClbText>
      </ClbTableTHeadCell>
      <ClbTableTHeadCell>
        <ClbText slot="content">{intl.get("supplierProductionPlan.table.pendingQuantityUn")}</ClbText>
      </ClbTableTHeadCell>
      <ClbTableTHeadCell>
        <ClbText slot="content">{intl.get("supplierProductionPlan.table.pendingQuantityPl")}</ClbText>
      </ClbTableTHeadCell>
      <ClbTableTHeadCell>
        <ClbText slot="content">{intl.get("supplierProductionPlan.table.productionDate")}</ClbText>
      </ClbTableTHeadCell>
      <ClbTableTHeadCell>
        <ClbText slot="content">{intl.get("supplierProductionPlan.table.producedQuantity")}</ClbText>
      </ClbTableTHeadCell>
      {isUserSupplier() && (
        <ClbTableTHeadCell>
          <ClbText slot="content">
            {intl.get("supplierProductionPlan.table.actions")}
          </ClbText>
        </ClbTableTHeadCell>
      )}
    </ClbTableTHeadRow>
  </ClbTableTHead>
);

export default Header;
