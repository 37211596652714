import styled from 'styled-components'

const StyledSiloManagement = styled.div`  

  width: 100%;

  .footer-dialog-buttons {
    margin-top: 5px;
    display: flex;
    justify-content: flex-end;
  }

  .footer-button {
    width: 200px;
  }

  .head-buttons-filter {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .head-buttons-icon {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .head-buttons-filter > div {
    width: 100%;
  }

  .btn-add-new-silo {
    margin-left: 10px;
    margin-right: 10px;
    width: 50px;
  }
`

export default StyledSiloManagement
