import { ClbMultiSelect } from "@celebration/design-system-react"
import intl from 'react-intl-universal'

export const ClbMultiSelectAdapter = ({ ...rest }) => (
  <ClbMultiSelect
    maxTagCount={1}
    applyButtonLabel={intl.get('loadComposition.building.applyButton')}
    clearButtonLabel={intl.get('loadComposition.building.clearButton')}
    {...rest}
  />
)