import Fetch from "utils/fetch"
import { convertToArrayQuery } from '../gerencial/gerencial.service'

export const buscaEstoqueCompartilhado = async (filter) => {

  const fetchedFilters = await filter ? filter : {}
  let query = undefined

  if (fetchedFilters) {
    query = convertToArrayQuery(
      [
        fetchedFilters.GrupoFornecedor,
        fetchedFilters.Familias,
        fetchedFilters.FamiliasRotuladas,
        fetchedFilters.Carteiras,
      ],
      [
        'SupplierGroupIds',
        'FamilyIds',
        'LabeledFamilyIds',
        'WalletIds',
      ]
    )
  }

  const url =
    query
      ? `/shared-stocks:suppliers?${query}`
      : `/shared-stocks:suppliers`

  return Fetch.get(url).then(({ data }) => {

    return {
      Notes: data.Notes,
      Suppliers: data.Suppliers,
      IsLoad: data.Suppliers.length > 0 ? true : false
    }
  })
}

export const salvarMateriaisPorFornecedor = (supplierId, materials) => {
  return Fetch.post(`/shared-stocks:save-for-supplier?SupplierId=${supplierId}`, materials).then(({ data }) => data)
}

export const getDownloadSupplierStock = () => {
  return Fetch.get("supplierStock/export").then(({ data }) => data)
}

export const getDownloadSupplierStockModel = () => {
  return Fetch.get("supplierStock/export-model").then(({ data }) => data)
}

export const salvarAnotacoes = (anotacoes) => {
  return Fetch.post(`/shared-stocks:record-notes`, anotacoes).then(({ data }) => data)
}
