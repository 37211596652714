import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100vh;
  background-color: var(--color-contrast-white);
`

export const Content = styled.div`
  width: 100%;
  height: calc(100vh - 56px);
  display: flex;
  justify-content: space-around;
`

export const LogoSection = styled.div`
  width: 50%;
  height: -webkit-fill-available;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;

  #ontimeMrp-logo {
    width: 40vw;
  }
`

export const CredentialsSection = styled.div`
  width: 50%;
  height: -webkit-fill-available;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
`

export const CredentialsBox = styled.div`
  display: flex;
  width: 40%;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 10px;
  background: var(--color-contrast-white);
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.16);

  #credentials-box-title {
    margin: 0px;
    color: var(--Neutral-600, #0E0E0E);
    font-size: 24px;
    font-style: normal;
    font-weight: var(--font-weight-semibold);
    line-height: 150%; /* 36px */
    display: flex;
  }

  #credentials-box-tooltip-info {
    margin-left: 8px;
  }

  #button-tooltip-info {
    width: 36px;
  }

  #credentials-box-subtitle {
    margin: 4px 0px 32px 0px;
    color: #0E0E0E;
    font-size: 16px;
    font-style: normal;
    font-weight: var(--font-weight-regular);
    line-height: 150%; /* 24px */
  }

  #button-sso-ambev {
    margin-bottom: 8px;
    width: 100%;
  }

  #button-sso-terceiros {
    margin-bottom: 16px;
    width: 100%;
  }
`

export const Divider = styled.div`
  margin-bottom: 16px;
  border: 1px solid #D9D9D9;
  width: -webkit-fill-available;
`

export const Footer = styled.div`
  width: 100%;
  height: 56px;
  background-color: var(--color-action-default);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: center;

  span {
    margin-left: 25px;
    color: var(--color-contrast-brand);
    font-size: 14px;
    font-style: normal;
    font-weight: var(--font-weight-medium);
    line-height: 120%; 
  }

  #ambevHbsis-logo {
    margin-right: 25px;
    width: 120px;
    height: 16px;
  }
`

export const CredentialsBoxLink = styled.button`
  padding: 0px;
  cursor: pointer;
  font-size: 13px;
  font-weight: var(--font-weight-semibold);
  align-self:center;
  background: transparent;
  border: none;
  text-decoration: none;
  border-bottom: 1.8px solid transparent;

  :hover{
    border-bottom: 1.8px solid var(--Neutral-600, #0E0E0E);
  }
`