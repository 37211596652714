import Fetch from 'utils/fetch';
import { convertToQueryParameters } from 'utils/custom-functions';
import { convertToArrayQuery } from '../gerencial/gerencial.service'

export const getGridVision = async (days, IsFilterDefault, filters) => {
  
  const filtros = {    
    ...days,
    IsFilterDefault
  }

  const fetchedFilters = await filters
  let query = undefined

  if (fetchedFilters)
    query = convertToArrayQuery(
      [
        fetchedFilters.Destinos?.map(x => x.value),
        fetchedFilters.Coordenacoes,
        fetchedFilters.Carteiras,
        fetchedFilters.Familias,
        fetchedFilters.FamiliasRotuladas
      ],
      [
        'Destinos',
        'Coordenacoes',
        'Carteiras',
        'Familias',
        'FamiliasRotuladas'
      ]
  )

  const urlToFetch =
    query
    ? `gridvisibility/get-grid-visibility?${convertToQueryParameters(filtros)}&${query}`
    : `gridvisibility/get-grid-visibility?${convertToQueryParameters(filtros)}`

  return Fetch.get(urlToFetch).then(({ data }) => convertDataToFormat(data));
}

/**
 * 
 * @param {*} data = Response
 * 
 * Converte os dados retornados da API de visibilidade 
 * de Grid para um formato mais apropriado de manipulação.
 *   
 */
export const convertDataToFormat = (data) => {
  return {
    ...data.Data[0],
    length : data.Data[0].Factories.length
  };
}

export const getBusinessUnits = () => {
  return Fetch.get(`/unidadeNegocio/unidadesCadastradasNoGeral/`).then(({ data }) => data)
}

export const getLabeledFamilies = (businessUnitId) => {
  return Fetch.get(`/labeled-families:by-business-unit?${convertToQueryParameters({ businessUnitId: businessUnitId})}`).then(({ data }) => data)
}

export const saveOrderSuggestion = (pedido) => {
   return Fetch.post(`/pedido/criarPedidoPlanoDiario`, pedido);
}

export const getMaterialContracts = (idMaterial) => {
  return Fetch.get(`/ContratoFornecedor/getMaterialContacts/${idMaterial}`).then(({ data }) => data);
}