import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  background-color: white;
  margin-top: 5px;
  color: #475055;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  padding-top: 10px;
  font-size: 14px;
  z-index: 999;
`
export const Message = styled.div`
  color: var(--color-brand-400);
  font-size: 12px;
  font-weight: var(--font-weight-regular);
  font-style: italic;
  padding: 8px;
  display: flex;
  align-items: center;

  img {
    margin-right: 5px;
  }
`