import styled from "styled-components";

export const ModalTitle = styled.p`
  font-weight: var(--font-weight-semibold);
  font-size: 18px;
  line-height: 19px;
  color: var(--color-action-default);
  margin: 8px 0px 12px 0px;
`
export const ModalText = styled.p`
  font-size: 14px;
  font-weight: var(--font-weight-semibold);
  color: var(--color-contrast-brand);
  margin: 12px 0px;
  padding: 4px 0px 4px 0px;
`
export const WrapperButtons = styled.div`
  display: flex;
  justify-content: end;
`