import intl from 'react-intl-universal'

export const validatedDto = (dto) => {
  switch (true) {
    case (dto.VolumeAceito <= 0):
      return {
        isValid: false,
        message: intl.get('stocks.timeline.feedback.quantityFieldRequired')
      }
    case dto.DataColetaProposta > dto.DataEntregaProposta:
      return {
        isValid: false,
        message: intl.get("requestOrders.invalidDateRange")
      }
    case dto.Observacao === '':
      return {
        isValid: false,
        message: intl.get('stocks.timeline.feedback.observationMandatory')
      }
    case !dto.IdMaterial:
    case dto.IdMaterial <= 0:
      return {
        isValid: false,
        message: intl.get('feedbacks.messageMaterial')
      }
    case !dto.IdFornecedor:
    case dto.IdFornecedor <= 0:
      return {
        isValid: false,
        message: intl.get('feedbacks.messageMaterialBusiness4')
      }
    default:
      return {
        isValid: true,
        message: ''
      }
  }
}
