import PropTypes from 'prop-types'
import {
	Table,
	TableBody,
	TableHeader,
	TableHeaderColumn,
	TableRow,
	TableRowColumn,
	TableFooter
} from 'material-ui/Table'
import PaginationFooter from 'components/pagination/components/pagination-footer'
import TableRownColumnUnclickable from 'components/table-row-column-unclickable'
import DeleteIcon from 'images/icn-trash-bin.svg'
import IconButton from 'material-ui/IconButton'
import intl from 'react-intl-universal';
import { getStockMaterial } from './utils'
import { formatNumber } from 'utils/format'

const config = 'master-data.general.table.actions.editMaterial.table.header';

const MaterialList = ({ data, countData, refreshSearch, handleEdit, handleDelete, qtRegsPerPage }) => (
	<Table
		fixedHeader
		fixedFooter
		multiSelectable={false}
		selectable={false}
		wrapperStyle={{ height: '100%' }}
		bodyStyle={{ height: 'calc(100% - 110px)', borderTop: '1px solid rgb(224, 224, 224)', backgroundColor: 'var(--color-contrast-white)' }}
		onCellClick={handleEdit}>
		<TableHeader
			displaySelectAll={false}
			adjustForCheckbox={false}
			enableSelectAll={false}
			style={{ borderBottom: 'none' }}>
			<TableRow style={{ borderBottom: 'none' }}>
				<TableHeaderColumn style={{width: "10%"}}>{intl.get(`${config}.material`)}</TableHeaderColumn>
				<TableHeaderColumn style={{width: "10%"}}>{intl.get(`${config}.responsible`)}</TableHeaderColumn>
				<TableHeaderColumn style={{width: "5%"}}>{intl.get(`${config}.active`)}</TableHeaderColumn>
				<TableHeaderColumn style={{paddingLeft: 0, paddingRight: 0}}>{intl.get(`${config}.production`)}</TableHeaderColumn>
				<TableHeaderColumn style={{paddingLeft: 0, paddingRight: 0}}>{intl.get(`${config}.providerXA`)}</TableHeaderColumn>
				<TableHeaderColumn >{intl.get('master-data.general-configuration.incoterm')}</TableHeaderColumn>
				<TableHeaderColumn >{intl.get(`master-data.general-configuration.arredondamento`)}</TableHeaderColumn>
				<TableHeaderColumn >{intl.get(`master-data.general-configuration.minimumLot`)}</TableHeaderColumn>
				<TableHeaderColumn >{intl.get(`master-data.general-configuration.sla`)}</TableHeaderColumn>
				<TableHeaderColumn style={{width: '2%',}}>{intl.get(`${config}.tt`)}</TableHeaderColumn>
				<TableHeaderColumn style={{width: '2%'}}>{intl.get(`${config}.lt`)}</TableHeaderColumn>
				<TableHeaderColumn style={{width: "1%"}} ></TableHeaderColumn>
			</TableRow>
		</TableHeader>
		<TableBody
			displayRowCheckbox={false}
			deselectOnClickaway={false}
			showRowHover
			stripedRows={false}
		>
			{data.map((v, i) => (
				<TableRow key={i} style={{ cursor: 'pointer' }}>
					<TableRowColumn style={{width: "10%"}} className='paddingPersonalizado' title={v.MaterialName}>
						{v.MaterialName}
					</TableRowColumn>
					<TableRowColumn style={{width: "10%"}} className='paddingPersonalizado' title={v.Responsible}>
						{v.Responsible}
					</TableRowColumn>
					<TableRowColumn style={{width: "5%"}}className='paddingPersonalizado' title={v.ActiveMaterial}>
						{v.ActiveMaterial ? intl.get("geral.modalConfirm.yes") : intl.get("geral.modalConfirm.no")}
					</TableRowColumn>
					<TableRowColumn className='paddingPersonalizado' style={{paddingLeft: 0, paddingRight: 0}}>
						<div style={{ display: "flex", alignItems: "center" }} title={intl.get('master-data.general-configuration.toolTipMaterialWithoutDate')}>
							{!!v.ShowIndicator && <div style={{ color: "red", fontWeight: "bold", fontSize: "14px", marginRight: "3px" }}>!</div>}
							<div>{getStockMaterial(v.StockMaterial, v.DiscontinuedDate)}</div>
						</div>
					</TableRowColumn>
					<TableRowColumn style={{paddingLeft: 0, paddingRight: 0}} className='paddingPersonalizado' title={v.Supplier}>
						{v.Supplier}
					</TableRowColumn>
					<TableRowColumn className='paddingPersonalizado' title={v.Incoterm}>
						{v.Incoterm}
					</TableRowColumn>
					<TableRowColumn className='paddingPersonalizado' title={v.Rounding}>
						{formatNumber(v.Rounding)}
					</TableRowColumn>
					<TableRowColumn className='paddingPersonalizado' title={v.MinimumLot}>
						{formatNumber(v.MinimumLot)}
					</TableRowColumn>
					<TableRowColumn className='paddingPersonalizado' title={v.Sla}>
						{v.Sla}
					</TableRowColumn>
					<TableRowColumn  style={{width: '2%'}} className='paddingPersonalizado' title={v.TransitTime}>
						{v.TransitTime}
					</TableRowColumn>
					<TableRowColumn style={{width: '2%'}} className='paddingPersonalizado' title={v.LeadTime}>
						{v.LeadTime}
					</TableRowColumn>
					<TableRownColumnUnclickable style={{ positon: 'relative', textAlign: 'right', width: "1%" }}>
						<div title={intl.get('master-data.general.materials.title')}>
							<IconButton onClick={() => handleDelete(v.Id)} >
								<img src={DeleteIcon} />
							</IconButton>
						</div>
					</TableRownColumnUnclickable>
				</TableRow>
			))}
		</TableBody>
		<TableFooter adjustForCheckbox={false}>
			<PaginationFooter
				countPerPage={qtRegsPerPage}
				countData={countData}
				refreshSearch={refreshSearch}
			/>
		</TableFooter>
	</Table>
)

MaterialList.propTypes = {
  countData: PropTypes.number.isRequired,
  data: PropTypes.shape({
    map: PropTypes.func
  }).isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  qtRegsPerPage: PropTypes.number.isRequired,
  refreshSearch: PropTypes.func.isRequired
}

export default MaterialList
