const IconCopy = ({ width, height }) => (
  <svg
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width={width ? width : "20px"}
    height={height ?  height : "20px"}
    viewBox="0 0 24 24"
    style={{
      enableBackground: "new 0 0 437.348 437.348",
    }}
    xmlSpace="preserve"
    transform="matrix(6.123233995736766e-17,1,1,-6.123233995736766e-17,0,0)"
  >
    <g>
      <g>
        <path
          style={{
            fill: "var(--color-action-default)",
          }}
          points="428.233,324.297 350.983,324.297 350.983,312.587 416.523,312.587 416.523,11.711  115.647,11.711 115.647,95.474 103.936,95.474 103.936,0 428.233,0  "
          d="M23.5 17.796L19.261 17.796L19.261 17.154L22.857 17.154L22.857 0.643L6.346 0.643L6.346 5.239L5.704 5.239L5.704 0L23.5 0Z"
        />
      </g>
      <g>
        <path
          style={{
            fill: "var(--color-action-default)",
          }}
          points="333.417,437.348 9.115,437.348 9.115,113.04 86.371,113.04 86.371,124.75 20.825,124.75  20.825,425.638 321.707,425.638 321.707,341.863 333.417,341.863  "
          d="M18.297 24L0.5 24L0.5 6.203L4.74 6.203L4.74 6.846L1.143 6.846L1.143 23.357L17.654 23.357L17.654 18.76L18.297 18.76Z"
        />
      </g>
      <g>
        <path
          style={{
            fill: "var(--color-action-default)",
          }}
          points="333.417,341.863 321.707,341.863 321.707,124.75 86.371,124.75 86.371,113.04  333.417,113.04  "
          d="M18.297 18.76L17.654 18.76L17.654 6.846L4.74 6.846L4.74 6.203L18.297 6.203Z"
        />
      </g>
    </g>
  </svg>
);

export default IconCopy;
