import MenuItem from '@material-ui/core/MenuItem';
import Session from 'utils/user-storage';
import { filtersClearAllFilters } from 'utils/filters';
import { useAuth } from 'oidc-react';
import { getRedirectUri } from 'utils/config-environment';
import PropTypes from 'prop-types';

const NavbarLogoutFromKc = ({ itemLabel }) => {
  const auth = useAuth();

  const logout = () => {
    Session.clear()
    filtersClearAllFilters()
    auth.signOut()
    window.location.href = getRedirectUri()
  }

  return (
    <MenuItem onClick={logout}>
      {itemLabel}
    </MenuItem>
  )
}

NavbarLogoutFromKc.propTypes = {
  itemLabel: PropTypes.string.isRequired
}

export default NavbarLogoutFromKc;