import PropTypes from "prop-types"
import intl from 'react-intl-universal'
import ButtonOrderAccept from 'components/button-accept-order'
import { useOrderVolumeContext } from '../../context'
import { accept } from '../../services/order-volume.service'
import { useToastContext } from 'hooks/useToastContext'
import { formatErrorMessage } from 'utils/handle-error'
import { runStockProjection } from 'services/labeled-family-center.service'

const OrderVolumeAccept = ({ refresh, volumeOrdersIds, volumeOrders }) => {
  const { setCheckbox, setLoading } = useOrderVolumeContext();
  const { openToast } = useToastContext();

  const acceptOrder = async () => {
    setLoading(true)
    try {
      await accept(volumeOrdersIds);
      const labeledFamilyCenterIds = getLabeledFamilyCenterIds();
      for(const labeledFamilyCenterId of labeledFamilyCenterIds){
        await runStockProjection(labeledFamilyCenterId);
      }
      setCheckbox([])
      refresh();
      openToast(intl.get('stocks.timeline.feedback.volumeSuccessfullyAccepted'));
    } catch (error) {
      openToast(formatErrorMessage(error), 'negative')
    }
    setLoading(false)
  }

  const getLabeledFamilyCenterIds = () => {
    let _labeledFamilyCenterIds = [];

    for(const volume of volumeOrders){
      if(volumeOrdersIds.includes(volume.Id) && !_labeledFamilyCenterIds.includes(volume.LabeledFamilyCenterId)){
        _labeledFamilyCenterIds.push(volume.LabeledFamilyCenterId);
      }
    }

    return _labeledFamilyCenterIds;
  }

  return (
    <ButtonOrderAccept
      title={intl.get("requestOrders.acceptOrders")}
      disabled={volumeOrdersIds.length === 0}
      accept={acceptOrder}
    />
  )
}

OrderVolumeAccept.propTypes = {
  refresh: PropTypes.func.isRequired,
  volumeOrders: PropTypes.any.isRequired,
  volumeOrdersIds: PropTypes.shape({
    includes: PropTypes.func,
    length: PropTypes.number
  }).isRequired
}

export default OrderVolumeAccept
