import PropTypes from "prop-types"
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Card from 'components/card';
import Header from 'components/header';
import Option from 'components/select-option/option';
import SelectOption from 'components/select-option';
import Loading from 'components/center-loading';
import Pagination from 'components/pagination';
import StorePersist from 'utils/store-persist';
import Session from 'utils/user-storage';
import Counter from 'components/counter';
import { Layout } from "@hbsis.uikit/react";
import { Button } from 'components/uikit-adapter';
import IconEmpty from 'images/iconEmpty.icon';
import IconFilterGray from 'images/icn-filter-gray.svg';
import IconFilterBlue from 'images/icn-filter-blue.svg';
import SnackBar from 'material-ui/Snackbar';
import ListTasks from './list-tasks';
import intl from 'react-intl-universal';
import * as service from './tasks-vision.service';
import './tasks-vision.css';
import { formatErrorMessage } from '../../utils/handle-error';
import FilterTaks from './components/filter';
import { verifyFilterUser } from 'config/user/user.service';
import { filtersTasksDefault, validateIsFilteredTasks } from './utils';
import { perfilPcpUnidadeLogado } from 'services/permissions/getPermissions';
import { ImgGrid, ImgLista } from "components/icons/icn-index.icon";
import TipoPerfilAcesso from 'models/usuarios/tipo-perfil-acesso';

export const TIPO_VISUALIZACAO_GRID = 'Grid'
export const PERFIL_PCI_SOLUTIONS = 1
export const PERFIL_MASTER_DATA_SOLUTIONS = 2
export const PERFIL_GESTOR_SOLUTIONS = 5
export const TIPOS_TAREFA = {
  TAREFA_FURO_COLETA: 1,
  TAREFA_FURO_ENTREGA: 2,
  TAREFA_INCLUSAO_PEDIDO_URGENTE: 3,
  TAREFA_PEDIDO_AGUARDANDO_DESCARGA: 4,
  TAREFA_PEDIDO_AGUARDANDO_COMPOSICAO_CARGA_FORNECEDOR: 5,
  TAREFA_COBRANCA_ACEITE_FORNECEDOR: 6,
  TAREFA_PEDIDO_AGUARDANDO_COMPOSICAO_CARGA_AMBEV: 7,
  TAREFA_PEDIDO_EM_OVER: 8,
  TAREFA_ESTOQUE_EM_OVER: 9,
  TAREFA_AGUARDANDO_CIENCIA_PEDIDO_OVER_RECUSADO: 10,
  TAREFA_RENEGOCIACAO_EMERGENCIAL_DATA_RECUSADA: 11,
  TAREFA_RENEGOCIACAO_EMERGENCIAL_DATA: 12,
  TAREFA_AR_NAO_CRIADO: 13,
  TAREFA_CANCELAMENTO_AGUARDANDO_CIENCIA: 14,
  TAREFA_AGUARDANDO_ANALISE_CANCELAMENTO: 15,
  TAREFA_CANCELAMENTO_RECUSADO_AGUARDANDO_CIENCIA: 16,
  TAREFA_COMPOSICAO_CARGA_AGUARDANDO_REVISAO: 17,
  TAREFA_COMPOSICAO_EM_NEGOCIACAO: 18,
  TAREFA_COMPOSICAO_EM_RENEGOCIACAO_EMERGENCIAL: 19,
  TAREFA_ESTOQUE_EM_OVER_CRITICO: 20,
  TAREFA_COMPOSICAO_PARCIALMENTE_PREENCHIDA_AUTOPILOT: 21,
  TAREFA_VOLUME_PENDENTE_ACEITE: 22,
  TAREFA_VOLUME_CIENCIA_PENDENTE: 23,
  TAREFA_MASS_ARRIVAL_RISK: 24,
};

export const scrollToRef = ref =>
  ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

class TasksVision extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func
    }).isRequired
  }

  constructor() {
    super()

    this.contentScroll = React.createRef();

    this.state = {
      tasks: [],
      requestCount: 0,
      loadRegs: 0,
      regsPerRequest: 50,
      totalTasksCount: 0,
      currentTasksCount: 0,
      totalTasksBaselineCount: 0,
      currentTasksBaselineCount: 0,
      totalTarefasAberta: 0,
      filters: filtersTasksDefault,
      showFilterCards: false,
      tipoVisualizacao: TIPO_VISUALIZACAO_GRID,
      showMsg: false,
      textMsg: ''
    }
  }

  usuarioAmbev = () => [
    TipoPerfilAcesso.PciSolutions,
    TipoPerfilAcesso.MasterData, 
    TipoPerfilAcesso.Gestor, 
    TipoPerfilAcesso.Administrador
  ].includes(Session.get().PerfilAcesso)

  UNSAFE_componentWillMount() {
    const filterMyItemsOnly = StorePersist.getValuesJSON('filterMyItemsOnly')
    this.loadPage(filterMyItemsOnly);
  }

  loadPage = async (filterMyItemsOnly) => {
    const tipoVisualizacao = StorePersist.getValuesJSON('tipoVisualizacao')
    this.setState(prevState => ({
      tipoVisualizacao: tipoVisualizacao ?? prevState.tipoVisualizacao
    }))

    let filters = StorePersist.getValuesJSON('filtersTasksMulti')

    if (!filters) {
      filters = filtersTasksDefault
    }

    if (perfilPcpUnidadeLogado()) {
      filters.UseFilterDefault = true
    } else {
      if (filterMyItemsOnly === null) {
        const myItemsOnly = await verifyFilterUser(Session.get().Id)
        filters.UseFilterDefault = myItemsOnly.data
      } else {
        filters.UseFilterDefault = filterMyItemsOnly
      }
    }

    this.setState({ filters }, () => this.searchTasksByProfile(filters, 0))
  }


  handleFetch = (isFetching = true) => {
    this.setState(prevState => ({
      requestCount: isFetching ? prevState.requestCount + 1 : prevState.requestCount - 1,
    }));
  }

  showFilterCards = () => {
    this.setState(prevState => ({
      showFilterCards: !prevState.showFilterCards,
    }))
  }

  searchFilters = (filters, name) => {
    StorePersist.setValuesJSON(name, filters);
    this.showFilterCards()

    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        ...filters
      },
      tasks: [],
      loadRegs: 0
    }), () => this.routeSearch())
  }

  updateAmbevProfileTasks = async (model) => {
    try {
      const data = await service.searchTasks(model);
      const countData = await service.searchCountTasks(model.DefaultFilter);
      this.setState({ tasks: [] }, () => { 
        this.proccessDataAmbevProfile(data, countData); 
      }); 
    } catch (e) {
      this.proccessErro(formatErrorMessage(e));
    } finally {
      this.handleFetch(false);
    }
  }

  searchAmbevProfileTasks = (loadRegs, filters) => {
    this.handleFetch();
    const model = this.createModelAmbevProfile(loadRegs, filters)

    this.setState({ loadRegs }, () => { this.updateAmbevProfileTasks(model) });
  }


  filtersArrayPropHasValueToMap = (prop, mapper) =>
    this.state.filters[prop] ? this.state.filters[prop].map((_) => _[mapper]) : []

  createModelAmbevProfile = (loadRegs, filters) => {
    const { regsPerRequest } = this.state;

    let IdUsuario = [];

    if (this.usuarioAmbev() && filters.IdUsuario && filters.IdUsuario.length > 0) {
      IdUsuario = filters.IdUsuario.map(x => x.Id)
    }
    return {
      TaskType: this.filtersArrayPropHasValueToMap('TasksType', 'Id'),
      IsBaseLine: filters.IsBaseLine,
      DefaultFilter: !!filters.UseFilterDefault,
      OriginId: this.filtersArrayPropHasValueToMap('Source', 'Id'),
      DestinationId: this.filtersArrayPropHasValueToMap('Destination', 'Id'),
      UserId: filters.UseFilterDefault ? [] : IdUsuario,
      MaterialGroupId: this.filtersArrayPropHasValueToMap('MaterialGroup', 'Id'),
      FamilyId: this.filtersArrayPropHasValueToMap('Family', 'Id'),
      LabeledFamilyId: this.filtersArrayPropHasValueToMap('LabeledFamily', 'Id'),
      _page: loadRegs,
      _size: regsPerRequest,
    }
  }

  proccessDataAmbevProfile = ({ Data, CurrentPage, TotalItems, TotalPages }, countData) => {
    let tasks = [];

    if (Data.Tasks.length > 0) {
      tasks = Data.Tasks.map((v) => ({
        ...v,
        Id: v.Id,
        TipoTarefa: v.TaskType,
        Etapa: v.Step,
        Titulo: v.Title,
        SubTitulo: v.SubTitle,
        Prioridade: v.Priority,
        Situacao: v.Situation,
        Acao: v.Action,
        IdOrigem: v.OriginId,
        IdFamiliaRotuladaCentro: v.LabeledFamilyCenterId,
        MaterialAtivo: v.ActiveMaterial
      }));
    }

    this.setState({
      tasks: tasks,
      totalTarefasAberta: Data.TotalOpenTasks,
      currentPage: CurrentPage,
      totalItems: TotalItems,
      totalPages: TotalPages,
      totalTasksCount: countData.TotalTasksCount,
      currentTasksCount: countData.CurrentTasksCount,
      totalTasksBaselineCount: countData.TotalTasksBaselineCount,
      currentTasksBaselineCount: countData.CurrentTasksBaselineCount
    });
  }

  proccessErro = (message) => {
    this.setState({
      showMsg: true,
      textMsg: message,
    })
  }

  finalizeProccessError = () => {
    this.setState({
      showMsg: false,
      textMsg: ''
    })
  }

  loadMore = (page) => {
    scrollToRef(this.contentScroll);

    this.setState(({
      loadRegs: page,
    }), () => this.routeSearch(this.state.loadRegs))
  }
  getFilterIcon = () =>{ 
    if(localStorage.getItem("THEME") != 1 )
      if(this.verifyFilter()) 
       return IconFilterBlue 
      else
       return IconFilterGray;
    else
      return undefined
  }
  verifyFilter = () => {
    const { filters } = this.state;
    return validateIsFilteredTasks(filters);
  }

  searchTasksByProfile(filters, page = 0) {
    this.searchAmbevProfileTasks(page, filters);
  }

  routeSearch = (page = 0) => {
    const { filters } = this.state
    this.searchTasksByProfile(filters, page);
  };

  changeValue = (name, value) => {
    StorePersist.setValuesJSON('tipoVisualizacao', value)
    this.setState({
      [name]: value,
    })
  }

  redirect = (url) => {
    this.props.history.push(url)
  }

  render() {
    const {
      tasks,
      textMsg,
      showMsg,
      filters,
      loadRegs,
      requestCount,
      regsPerRequest,
      showFilterCards,
      tipoVisualizacao,
      totalTasksCount,
      currentTasksCount,
      totalTarefasAberta,
      totalTasksBaselineCount,
      currentTasksBaselineCount
    } = this.state

    return (
      <div className='container-tasks' ref={this.contentScroll}>
        <SnackBar
          message={textMsg}
          open={showMsg}
          autoHideDuration={4000}
          onRequestClose={this.finalizeProccessError}
          style={{ width: '100%', textAlign: 'center', display: 'block' }}
        />

        <Loading isLoading={requestCount > 0} />

        <Header title={intl.get('menu.menuGeneral.tasks')} titleWidth={130} >
          <div className='header__counters'>
          <Counter
            value={currentTasksBaselineCount}
            total={totalTasksBaselineCount}
            legend={this.usuarioAmbev() ? 'Base Line' : 'Real Time'}
          />
          <Counter
            value={currentTasksCount}
            total={totalTasksCount}
            type='Small'
            legend={this.usuarioAmbev() ? 'Real Time' : 'Base Line'}
          />
          </div>
          <div className="buttons-header-container">
            <div className='div-user-name'>{filters.NomeUsuario}</div>
            <div className='filterContainer'>
              <Button
                onClick={this.showFilterCards}
                type='default'
                value={intl.get("filters.filter")}
                icon={this.getFilterIcon()}
                className="button-filter"
              />
            </div>
          </div>
        </Header>

        <Layout.Sidebar width="450px"
          visible={showFilterCards}
          triggerClose={this.showFilterCards}
          background="var(--color-contrast-white)"
          offsetTop="96px"
          side="right"
          block
          float
          icon={<IconEmpty />}
        >
          <FilterTaks
            searchTasks={values => this.searchFilters(values, 'filtersTasksMulti')}
          />
        </Layout.Sidebar>

        <div className={`div-filters align-tipo-visualizacao`}>
          <div className="btn-tipo-visualizacao">
            <div className="selectOptions">
              <SelectOption>
                <div title={intl.get('commons.vision.listView')}>
                  <Option
                    active={tipoVisualizacao === 'Lista' ? 'active' : ''}
                    value="Lista"
                    handleClickIcon={() => this.changeValue('tipoVisualizacao', 'Lista')}
                    className="btnOption"
                  >
                    <ImgLista />
                  </Option>
                </div>
                <div title={intl.get('commons.vision.cardView')}>
                  <Option
                    active={tipoVisualizacao === TIPO_VISUALIZACAO_GRID ? 'active' : ''}
                    value="Grid"
                    handleClickIcon={() => this.changeValue('tipoVisualizacao', 'Grid')}
                    className="btnOption"
                  >
                    <ImgGrid />
                  </Option>
                </div>
              </SelectOption>
            </div>
          </div>
        </div>

        <div>
          {tipoVisualizacao === TIPO_VISUALIZACAO_GRID ? <div className='cards-container'>
            {tasks && tasks.length > 0 && tasks.map((v, i) => (
              <Card
                key={i}
                dataCard={v}
                typeCard={'CARD_TASKS'}
                filters={filters}
                refreshCard={this.loadPage}
                redirect={this.redirect}
              />
            ))}
          </div>
            : <div className="card-container-task">
              {tasks && tasks.length > 0 &&
                <ListTasks
                  data={tasks}
                  redirect={this.redirect}
                />
              }
            </div>
          }
          {(tasks && tasks.length > 0) &&
            <div className="paginationTasks" style={{ paddingRight: tipoVisualizacao === TIPO_VISUALIZACAO_GRID ? '17%' : '4%' }}>
              <Pagination
                refreshSearch={this.loadMore}
                page={loadRegs}
                pageSize={regsPerRequest}
                amount={totalTarefasAberta}
              />
            </div>
          }
          {(tasks && tasks.length === 0 && tipoVisualizacao === TIPO_VISUALIZACAO_GRID && requestCount <= 0) &&
            <div className="div-sem-registro">{intl.get('commons.noRecordsFound')}.</div>
          }
        </div>
      </div >
    )
  }
}
export default withRouter(TasksVision)
