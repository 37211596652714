import React from 'react'
import PropTypes from 'prop-types'
import StyledStep from './styledStep'

class Step extends React.Component {

  render () {
    return (
      <StyledStep>
        {this.props.children}
      </StyledStep>
    );
  }
}

Step.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired
}

export default Step

