import React, { Component } from 'react'
import PropTypes from 'prop-types'

import StyledProgressBar from './styledProgressBar.js'

class ProgressBar extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { value, type, legend } = this.props;

    return (
      <StyledProgressBar>
        <div className={`progress vertical ${type}`} title={legend}>
          <div
            className="progress-bar progress-bar-info"
            role="progressbar"
            aria-valuenow="50"
            aria-valuemin="0"
            aria-valuemax="50"
            style={{ width: value + '%' }}>
          </div>
        </div>
      </StyledProgressBar>
    )
  }
}

ProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
  type: PropTypes.number,
  legend: PropTypes.string
}

export default ProgressBar
