import { useState, useEffect } from 'react'
import { withRouter } from 'react-router'
import HeaderIcon from 'components/header-icon'
import {
    Container,
    HeaderContainer
} from './styled-critical-items'
import intl from 'react-intl-universal';
import { Button } from 'components/uikit-adapter/index'
import { CriticalItemIcon } from 'components/icons/icn-index.icon'
import ImportExport from 'components/import-export'
import moment from 'moment';
import { downloadFile } from 'utils/custom-functions';
import * as service from './critical-items.service';
import Loading from 'components/center-loading'
import SnackBar from 'material-ui/Snackbar'
import CriticalItemsList from './list'
import CriticalItemsForm from './form'
import { formatErrorMessage } from 'utils/handle-error';
import { profileAdminOrMasterData } from "services/permissions/getPermissions";
import { exportLabeledFamilyCenterMaterials } from 'services/labeled-family-center-materials.service';

const CriticalItems = () => {
    const [openImportExportItensCriticos, setOpenImportExportItensCriticos] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [configurationsCriticalItems, setConfigurationsCriticalItems] = useState({})
    const [familiesData, setFamiliesData] = useState({
        families: [{ Id: '' }],
        countFamilies: 0
    });
    const [paginationControl, setPaginationControl] = useState({
        quantityPerPage: 100,
        page: 0
    });
    const [feedback, setFeedback] = useState({
        showFeedback: false,
        messageFeedBack: ""
    });

    useEffect(() => {
        searchFamily();
    }, [paginationControl]);

    const proccessData = (data) => {
        const orderedFamilies = data.Data.sort((a, b) => a.Name.localeCompare(b.Name));
        setFamiliesData({
            families: orderedFamilies,
            countFamilies: data.TotalRecordCount
        });
    }

    const searchPage = (page) => {
        setPaginationControl(prevState => ({
            ...prevState,
            page: page
        }));
    }

    const searchFamily = () => {
        startFetching();
        service.getFamily(paginationControl)
            .then((response) => proccessData(response))
            .then(() => stopFetching())
            .catch(err => proccessErro(err));
    }

    const exportarItensCriticos = () => {
        setOpenImportExportItensCriticos(true);
    }

    const closeImportExportItensCriticos = () => {
        setOpenImportExportItensCriticos(false);
    }

    const downloadArquivoItensCriticos = async () => {
        startFetching()
        try {
            const data = await exportLabeledFamilyCenterMaterials();
            const datafile = moment(new Date()).format('YYYYMMDD_HHmmss')
            downloadFile(`${datafile}_Material.csv`, 'csv', data)
        } catch (err) {
            proccessErro(err)
        } finally {
            stopFetching();
        }
    }

    const startFetching = () => {
        setIsFetching(true);
    }

    const stopFetching = () => {
        setIsFetching(false);
    }

    const proccessErro = (exception) => {
        stopFetching();
        const messageError = formatErrorMessage(exception);
        showFeedback(messageError)
    }

    const showFeedback = (message) => {
        setFeedback({
            showFeedback: true,
            messageFeedBack: message
        })
    }

    const closeFeedback = () => {
        setFeedback({
            showFeedback: false,
            messageFeedBack: ""
        });
    }

    const handleSituation = (value) => {
        if (value === 1) {
            let criticalItems = configurationsCriticalItems;
            const removeSufficiencyDays = configurationsCriticalItems.ConfiguracaoItensCritico.map(items => ({
                ...items,
                DiasSuficiencia: null
            }));
            criticalItems.ConfiguracaoItensCritico = removeSufficiencyDays;
            criticalItems.RelevanciaItensCritico = value === 0;
            setConfigurationsCriticalItems({ ...criticalItems });
        }
        else {
            setConfigurationsCriticalItems(prevState => ({
                ...prevState,
                RelevanciaItensCritico: value === 0
            }));
        }
    }

    const proccessCriticalItemsData = (data) => {
        const configuracaoItensCritico = data.ConfiguracaoItensCritico.sort((a, b) => a.CentroUnidadeNegocio.localeCompare(b.CentroUnidadeNegocio));
        data.ConfiguracaoItensCritico = configuracaoItensCritico;
        return data;
    }

    const openEdit = async (row) => {
        startFetching();
        try {
            const familyInEditingId = familiesData.families[row].Id;
            const response = await service.getCriticalItemsByFamilyId(familyInEditingId).then(res => proccessCriticalItemsData(res));
            stopFetching();
            setConfigurationsCriticalItems(response);
            setIsEditing(true);
        }
        catch (err) {
            proccessErro(err)
        }
    }

    const closeEdit = () => {
        setIsEditing(false);
    }

    const saveSufficiencyConfigurationDays = async () => {
        startFetching();
        try {
            const data = configurationsCriticalItems;
            await service.save(data).then(() => stopFetching());
            closeEdit();
            showFeedback(intl.get('master-data.configurations.critical-items.feedbacks.save'));
            searchFamily();
        }
        catch (err) {
            proccessErro(err)
        }
    }

    const handleSufficiencyDays = (value, index) => {
        let newSufficienccy = configurationsCriticalItems.ConfiguracaoItensCritico;
        newSufficienccy[index].DiasSuficiencia = value;
        setConfigurationsCriticalItems(prevState => ({
            ...prevState,
            ConfiguracaoItensCritico: [...newSufficienccy]
        }));
    }

    return (
        <Container>
            <Loading isLoading={isFetching} />
            <HeaderContainer>
                <HeaderIcon
                    title={intl.get('master-data.configurations.critical-items.header.title')}
                    subtitle={intl.get('master-data.configurations.critical-items.header.subtitle')}
                    icon={<CriticalItemIcon width='40px' height='40px' color='var(--color-brand-500)' />}
                >
                    {profileAdminOrMasterData() &&
                        <div title={intl.get('master-data.general.actions.updateCriticalItems')}>
                            <Button
                                onClick={exportarItensCriticos}
                                type='default'
                                buttonIcon={<CriticalItemIcon color='var(--color-brand-500)' />}
                                buttonText={intl.get('master-data.general.actions.criticalItems')}
                                className={"button-img-estoque"}
                            />
                        </div>}
                </HeaderIcon>
            </HeaderContainer>
            <ImportExport
                title={intl.get('exportImportModal.criticalItems.title')}
                contextModal='criticalItems'
                open={openImportExportItensCriticos}
                handleClose={closeImportExportItensCriticos}
                downloadArquivo={downloadArquivoItensCriticos}
                importArquivo='labeled-family-center-materials:import'
                type="CSV"
                noMessageExample
            />
            <CriticalItemsList
                data={familiesData.families}
                paginationControl={paginationControl}
                countData={familiesData.countFamilies}
                search={searchPage}
                handleEdit={openEdit}
            />
            {isEditing &&
                <CriticalItemsForm
                    data={configurationsCriticalItems}
                    open={isEditing}
                    handleClose={closeEdit}
                    save={saveSufficiencyConfigurationDays}
                    handleSufficiencyDays={handleSufficiencyDays}
                    handleSituation={handleSituation}
                    profileAdminOrMasterData={profileAdminOrMasterData()}
                />}
            <SnackBar
                message={feedback.messageFeedBack}
                open={feedback.showFeedback}
                autoHideDuration={3000}
                onRequestClose={closeFeedback}
                id="SnackBar"
            />
        </Container>
    )
}

export default withRouter(CriticalItems);
