import PropTypes from "prop-types"
import React from 'react'
import { GetRows } from './render'
import intl from 'react-intl-universal'
import * as S from './styled'
import moment from 'moment'

const BottleTable = ({ keys, days }) => (
  <S.Table>
    <S.TableHeading>
      <S.TableHeadingTitle>
        <span>{intl.get('master-data.managementUnity.deliverySchedule.table.title')}</span>
      </S.TableHeadingTitle>
      {
        keys.map((key, i) => (
          <S.TableHeadingTitle key={i}>
            <span>{moment(key, "HH:mm").format("LT")}</span>
          </S.TableHeadingTitle>
        ))
      }
    </S.TableHeading>
    <S.TableBody ignoreElements="input">
      {days.map((d, index) => (
        <S.TableRow key={index}>
          {GetRows(d, keys)}
        </S.TableRow>
      ))}
    </S.TableBody>
  </S.Table>
)

BottleTable.propTypes = {
  days: PropTypes.shape({
    map: PropTypes.func
  }).isRequired,
  keys: PropTypes.shape({
    map: PropTypes.func
  }).isRequired
}

export default BottleTable
