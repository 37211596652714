import React, { Component } from 'react'
import SnackBar from 'material-ui/Snackbar'
import PropTypes from 'prop-types'
import EventTimeLine from './event-timeline'
import SessionUser from 'utils/user-storage'
import Loading from 'components/center-loading'

import { searchTimelineByOrder, searchTimelineByVolumeOrder } from './timeline.service'

import 'components/message'
import './timeline.css'
import { formatErrorMessage } from 'utils/handle-error';
import { getGeneralSettingValue } from '../../services/general-settings/general-settings'
import { GeneralSettingParameter } from 'models/generalSetting/generalSetting'

class TimeLine extends Component {
  constructor() {
    super()

    this.getIsNewFlow()

    this.state = {
      events: [],
      showMsgError: false,
      textMsgError: '',
      requestCounter: 0,
      isLoading: false,
      idFamiliaRotuladaCentro: 0,
      isNewFlow: false
    }

    this.refresh = false;
  }

  componentDidMount() {
    const { idPedido } = this.props
    if (idPedido) {
      this.searchEvents(this.props)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.idPedido && nextProps.idPedido !== this.props.idPedido
      || !this.refresh && nextProps.searchTimeLine) {
      this.searchEvents(nextProps)
      if (!this.refresh && nextProps.searchTimeLine) {
        this.props.searchTimeLineFinished()
      }
    }
    if (nextProps.idFamiliaRotuladaCentro && nextProps.idFamiliaRotuladaCentro !== this.state.idFamiliaRotuladaCentro) {
      this.setState({
        idFamiliaRotuladaCentro: nextProps.idFamiliaRotuladaCentro
      })
    }
  }

  showErrorMessage = (message) => {
    this.setState({
      showMsgError: true,
      textMsgError: message
    })
  }

  handleFetch = (isFetching = true) => {
    this.setState(prevState => ({
      requestCounter: isFetching ? prevState.requestCounter + 1 : prevState.requestCounter - 1,
    }))
  }

  searchEvents = async (props) => {
    const user = SessionUser.get().Id
    const { idPedido, isPedidoVolume } = props
    if (idPedido) {
      this.handleFetch()
      try {
        let data;
        if (isPedidoVolume) {
          data = await searchTimelineByVolumeOrder(idPedido, user);
        } else {
          data = await searchTimelineByOrder(idPedido, user);
        }
        this.setState({ events: data });
      } catch (err) {
        const errorMessage = formatErrorMessage(err);
        this.showErrorMessage(errorMessage)
      } finally {
        this.handleFetch(false)
      }
    }
  }

  refreshTimeline = () => {
    this.searchEvents(this.props)
    if (this.props.refreshCardDetail)
      this.props.refreshCardDetail();
  }

  disableShowMsgError = () => {
    this.setState({
      showMsgError: false
    })
  }

  getIsNewFlow = async () => {
    const isNewFlow = getGeneralSettingValue(GeneralSettingParameter.LoadCompositionNewFlow)
    this.setState({ isNewFlow })
  }

  render() {
    const { events, requestCounter, showMsgError, textMsgError, isNewFlow } = this.state
    const { handleFeedback, maxHeight } = this.props

    return (
      <div className='container-timeline' style={{ maxHeight }}>
        <Loading isLoading={requestCounter > 0} />

        <div className='body-timeline'>
          <div className='items-timeline'>
            {events.map((event, index) => (
              <EventTimeLine
                key={index}
                data={event}
                idPedido={event.IdPedido}
                idUser={SessionUser.get().Id}
                refreshTimeLine={this.refreshTimeline}
                handleFeedback={handleFeedback}
                handleFetch={this.handleFetch}
                idFamiliaRotuladaCentro={this.state.idFamiliaRotuladaCentro}
                isNewFlow={isNewFlow}
              />
            ))}
          </div>
        </div>

        <SnackBar
          message={textMsgError}
          open={showMsgError}
          autoHideDuration={3000}
          onRequestClose={this.disableShowMsgError}
        />
      </div>
    )
  }
}

TimeLine.propTypes = {
  handleFeedback: PropTypes.func.isRequired,
  idPedido: PropTypes.number.isRequired,
  maxHeight: PropTypes.string.isRequired,
  refreshCardDetail: PropTypes.func.isRequired,
  searchTimeLine: PropTypes.bool.isRequired,
  searchTimeLineFinished: PropTypes.func.isRequired
}

export default TimeLine
