import intl from 'react-intl-universal'

export const resolvePath = (path, object) => {
  return path.split(".").reduce((prev, curr) => {
    return prev ? prev[curr] : null;
  }, object)
}

export const getTitle = (item, hasChildren) => {
  let title = ''
  if (!hasChildren && item.Bloqueado) {
    const noHasJustificationValues = !item?.Justificativa?.Observacao && !item?.Justificativa?.Tipo
    title = noHasJustificationValues ? intl.get('commons.insertJustification') : intl.get('commons.editJustification')
  }
  return title
}