import React from 'react'
import { formatNumber } from 'utils/format';

export const getCurrentStockForOrdersOrSuggestions = (prioridade, quantidadeAtual, unidadeMedida) => {
  let color = "#000000";
  let titulo = "";
  switch (prioridade) {
    case 0:
      color = "#6f9e32";
      titulo = "Ok";
      break;
    case 1:
      titulo = "Over";
      break;
    case 2:
      color = "#de4246";
      titulo = "Out";
      break;
    default:
      break;
  }

  return (
    <div style={{ color: color }}>
      <strong>{titulo}</strong>
      <br />
      <span>{`${formatNumber(quantidadeAtual)} ${unidadeMedida}`}</span>
    </div>
  );
}

export const getValue = value => value ? value : '-'
