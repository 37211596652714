import PropTypes from "prop-types"
import Dialog from '@material-ui/core/Dialog';
import FlatButton from "material-ui/FlatButton";
import intl from 'react-intl-universal'
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const ModalReturnSapList = ({ open, data, closeModalAssign, title }) => (
  <Dialog
    open={open}
    onClose={closeModalAssign}
  >
    <DialogTitle id="form-dialog-title">{title}</DialogTitle>
    <DialogContent style={{ overflowY: 'auto', maxHeight: '65vh', whiteSpace: 'pre-wrap' }}>
      <ul style={{ listStylePosition: 'outside', margin: '0px', paddingInlineStart: '16px' }}>
        {data.map((message, i) => (
          <li 
            key={i} 
            title={message} 
            style={{ margin: '6px 0px', width: '100%', wordBreak: 'break-word', textAlign: 'justify' }}
          >
            {message}
          </li>
        ))}
      </ul>
    </DialogContent>
    <DialogActions>
      <FlatButton label={intl.get('geral.buttonsDefault.close')} onClick={closeModalAssign} />
    </DialogActions>
  </Dialog>
)

ModalReturnSapList.propTypes = {
  closeModalAssign: PropTypes.any.isRequired,
  data: PropTypes.array,
  open: PropTypes.any.isRequired,
  title: PropTypes.any.isRequired
}

ModalReturnSapList.defaultProps = {
  data: []
}

export default ModalReturnSapList;
