import intl from 'react-intl-universal'
import React, { useState, useEffect } from 'react'
import TitleHeader from 'components/title-header'
import { ClbRadioButton, ClbRadioGroup } from '@celebration/design-system-react';
import { Col, Row, TableRow, TableTitle, WrapperScheduleConfiguration } from '../../styled';
import { SchedulingAutoPilotGeneralDto } from 'types/labeled-family-center/auto-pilot/scheduling-auto-pilot-general-dto';
import { TypeOfAutoPilotScheduling } from 'enum/labeled-family-center/auto-pilot';
import { AutoPilotPeriodEnum } from 'enum/labeled-family-center/auto-pilot/'
import { getDaysWeekInitialValue, normalizeDaysWeek } from './utils';
import { IDaysWeek } from 'features/auto-pilot/types';
import { AutoPilotScheduleConfigurationTableLine } from './auto-pilot-schedule-configuration-table-line';

interface IAutoPilotScheduleConfiguration {
  data: SchedulingAutoPilotGeneralDto,
  setData: React.Dispatch<React.SetStateAction<SchedulingAutoPilotGeneralDto>>
}

export const AutoPilotScheduleConfiguration = ({ data, setData }: IAutoPilotScheduleConfiguration) => {
  const [daysWeek, setDaysWeek] = useState<IDaysWeek[]>(getDaysWeekInitialValue(data.IdLabeledFamilyCenter))
  const isTypeOfAutoPilotSchedulingDefault = data.TypeOfAutoPilotScheduling == TypeOfAutoPilotScheduling.Default
  const isTypeOfAutoPilotSchedulingCustom = data.TypeOfAutoPilotScheduling == TypeOfAutoPilotScheduling.Custom


  const updateData = <TValue,>(field: string, value: TValue) => {
    setData(prevState => ({
      ...prevState,
      [field]: value
    }))
  }

  const updateTypeOfAutoPilotSchedulingToDefault = () => {
    setData(prevState => ({
      ...prevState,
      TypeOfAutoPilotScheduling: TypeOfAutoPilotScheduling.Default,
      SchedulingAutoPilots: []
    }))
  }

  const normalizeData = async () => {
    const { newData, typeRecurrence } = normalizeDaysWeek(data, daysWeek)
    updateData("TypeOfAutoPilotScheduling", typeRecurrence)
    setDaysWeek(newData)
  }

  useEffect(() => {
    if (isTypeOfAutoPilotSchedulingDefault) {
      let normalize: IDaysWeek[] = []
      daysWeek.forEach(x => {
        const value: IDaysWeek = {
          ...x,
          Selected: false,
          StartTime: "00:00",
          FinishTime: "12:00",
          AutoPilotPeriod: AutoPilotPeriodEnum.AllDay
        }
        normalize.push(value)
      });
      setDaysWeek([...normalize]);
    }
  }, [data.TypeOfAutoPilotScheduling])

  useEffect(() => {
    normalizeData();
  }, [])

  return (
    <WrapperScheduleConfiguration>
      <TitleHeader
        className="headerModal"
        color={"black"}
      >
        {intl.get('master-data.general.auto-pilot.recurrencePattern')}
      </TitleHeader>

      <React.Fragment>
        <Row style={{ paddingBottom: '32px' }}>
          <div style={{ height: '20px' }}>
            <ClbRadioGroup orientation='line'>
              <div style={{ minWidth: '110px' }}>
                <ClbRadioButton
                  id="radio-01"
                  name="radio-01"
                  value='1'
                  checked={isTypeOfAutoPilotSchedulingDefault}
                  onClbChange={updateTypeOfAutoPilotSchedulingToDefault}
                  disabled={!data.AutoPilot}
                  data-testid="radio-button-recurrence-pattern-default"
                >
                  {intl.get('commons.default')}
                </ClbRadioButton>
              </div>
              <ClbRadioButton
                id="radio-02"
                name="radio-02"
                value='2'
                checked={isTypeOfAutoPilotSchedulingCustom}
                onClbChange={() => updateData("TypeOfAutoPilotScheduling", TypeOfAutoPilotScheduling.Custom)}
                disabled={!data.AutoPilot}
                data-testid="radio-button-recurrence-pattern-custom"
              >
                {intl.get('master-data.general.auto-pilot.custom')}
              </ClbRadioButton>
            </ClbRadioGroup>
          </div>
        </Row>
        <TableRow >
          <Col>
            <TableTitle>
              {intl.get('master-data.general.auto-pilot.period.daysWeek')}
            </TableTitle>
          </Col>
          <Col>
            <TableTitle>
              {intl.get('master-data.general.auto-pilot.period.period')}
            </TableTitle>
          </Col>
          <Col>
            <TableTitle>
              {intl.get('master-data.general.auto-pilot.period.start')}
            </TableTitle>
          </Col>
          <Col>
            <TableTitle>
              {intl.get('master-data.general.auto-pilot.period.end')}
            </TableTitle>
          </Col>
        </TableRow>
        {daysWeek.map((value, index) => (
          <AutoPilotScheduleConfigurationTableLine
            key={value.DayOfWeek}
            index={index}
            data={data}
            setData={setData}
            daysWeek={daysWeek}
            setDaysWeek={setDaysWeek}
          />
        ))}
      </React.Fragment>
    </WrapperScheduleConfiguration>
  )
};