
import React from 'react'
import PropTypes from 'prop-types'

import ellipseIcon from 'images/timeline/ellipse.png'
import './stop-point.css'

const StepPoint = ({ stopNow }) => (
  <div className={`stop-point`}>
    <img src={ellipseIcon} alt='atual' />
  </div>
)

StepPoint.propTypes = {
  stopNow: PropTypes.bool
}

StepPoint.defaultProps = {
  stopNow: false
}

export default StepPoint
