import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './app'
import KcApp from './kcApp'
import reportWebVitals from './reportWebVitals'
import { AzureAD } from "react-aad-msal"
import { authProvider, authorityForgotPasswordPage, authorityLoginPage } from "./providers/authProvider"
import { BrowserRouter } from "react-router-dom"
import { getAuthenticationProvider } from "./utils/config-environment";
import 'styles.css'

const initialize = () => {
  const authenticationProvider = getAuthenticationProvider();
  const errorAuthProvider = authProvider().getError()
  const isForgotPasswordPage = errorAuthProvider && errorAuthProvider.errorMessage.search("AADB2C90118") !== -1
  const authority = isForgotPasswordPage ? authorityForgotPasswordPage : authorityLoginPage
  const rootElement = document.getElementById('root');

  const root = createRoot(rootElement);

  if (authenticationProvider !== "B2C") {
    root.render(
      <BrowserRouter>
        <KcApp />
      </BrowserRouter>
    );
  } else {
    root.render(
      <AzureAD provider={authProvider(authority)} forceLogin={true} >
        {({ accountInfo, error }) => {
          return (
            <BrowserRouter>
              <App accountInfo={accountInfo} error={error} />
            </BrowserRouter>
          )
        }}
      </AzureAD>
    )
  }
}

initialize()
reportWebVitals()
