import { getGeneralSettings } from "pages/master-data/configurations/general-settings/general-settings.service";
import { normalizeGeneralSettingsToSetLocalStorage } from "./normalize-object";

/**
 * this method initializes the default general settings in local storage
 */
export const initGeneralSettings = async () => {
  const _generalSettings = await getGeneralSettings();
  localStorage.setItem('generalSettings', normalizeGeneralSettingsToSetLocalStorage(_generalSettings));
};

/**
 * @param {*} parameter general configuration to update.
 * @param {*} value new value to set in general configuration.
 * this method updates the value of a general setting in local storage
 */
export const updateGeneralSetting = (parameter, value) => {
  const generalSettingsList = JSON.parse(localStorage.getItem('generalSettings'));
  const generalSettingIdx = generalSettingsList.findIndex(x => x.parameterValue === parameter);
  generalSettingsList[generalSettingIdx] = { ...generalSettingsList[generalSettingIdx], parameterValue: parameter, value: value }

  localStorage.setItem('generalSettings', JSON.stringify(generalSettingsList));
}

/**
 * @param {*} generalSettings General setting to return value.
 * @returns Get value set in local storage and returns if it is active or not
 */
export const validateFeatureFlag = (parameter) => {
  const value = getGeneralSettingValue(parameter);
  return value ? value : false;
}

/**
 * @param {*} parameter General setting to return value.
 * @returns Get value set in local storage, format it and return.
 */
export const getGeneralSettingValue = (parameter) => {
  try {
    const generalSettingsList = JSON.parse(localStorage.getItem('generalSettings'));
    const generalSetting = generalSettingsList.find(x => x.parameterValue == parameter);
    return generalSetting?.value
  } catch (e) {
    return null;
  }
}
