import React from 'react'
import PropTypes from 'prop-types'
import StyledSteps from './styledSteps'

class Steps extends React.Component {

  constructor(props) {
    super(props)
    this.state = { selected: props.selected }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.selected !== this.props.selected) {
      this.setState({
        selected: nextProps.selected,
      })
    }
  }

  handleClick(index, event) {
    event.preventDefault()

    if(this.props.blockedSteps === false) {
      this.props.onChangeStep && this.props.onChangeStep(index);
      this.setState({
        selected: index,
      })
    }
  }

  _renderTitles() {
    const children = React.Children.toArray(this.props.children)

    return (
      <ul className="steps__labels">
        {children.map((child, indexChildren) => {
          const activeClass = (this.state.selected === indexChildren ? 'active' : '')
          const completedClass = (child.props.completed === true ? 'completed ' : '')
          return (
            <li key={indexChildren} className={`${completedClass} ${activeClass}`}>
              <a className="steps__labels__link"
                href="#"
                 onClick={this.handleClick.bind(this, indexChildren)}
              >
                {
                  child.props.icon &&
                  <img className="steps__labels__link__image" src={child.props.icon} alt={child.props.label} />
                }
                <div className="numberTab">{indexChildren+1}</div>
                <p className="labelTab">{child.props.label}</p>
              </a>
            </li>
          );
        })}
      </ul>
    );
  }

  _renderContent () {
    const children = React.Children.toArray(this.props.children)

    return (
      <div className="steps__content">
        {children[this.state.selected]}
      </div>
    );
  }

  render () {
    return (
    <StyledSteps blockedSteps={this.props.blockedSteps} offsetTop={this.props.offsetTop}>
      <div className="steps">
        {this._renderTitles()}
        {this._renderContent()}
      </div>
    </StyledSteps>
    );
  }
}

Steps.propTypes = {
  selected: PropTypes.number,
  offsetTop: PropTypes.string,
  blockedSteps: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element
  ]).isRequired,
  onChangeStep: PropTypes.func
}

Steps.defaultProps = {
    selected: 0,
    offsetTop: '150px',
    blockedSteps: false
}


export default Steps

