import intl from 'react-intl-universal'

export const npsInternationalizationMapper = () => ({
	Outros: intl.get("survey.options.others"),
	Travas: intl.get("survey.options.locks"),
	Lentidão: intl.get("survey.options.slowness"),
	Interface: intl.get("survey.options.interface"),
	"Bugs/Erros": intl.get("survey.options.bugsErrors"),
	"Internet/Conectividade": intl.get("survey.options.internetConectivity"),
})

export const npsDefaultTexts = () => ({
	survey: {
		title: intl.get("survey.title"),
		message: intl.get("survey.message"),
		complement: intl.get("survey.complement"),
		rememberLater: intl.get("survey.rememberLater"),
		confirm: intl.get("survey.confirm"),
	},
	success: {
		message: intl.get("survey.success.message"),
		finalize: intl.get("survey.success.finalize"),
	},
	error: {
		title: intl.get("survey.error.title"),
		message: intl.get("survey.error.message"),
		solution: intl.get("survey.error.solution"),
		finalize: intl.get("survey.error.finalize"),
	},
	feedback: {
		title: intl.get("survey.feedback.title"),
		contentMessage: intl.get("survey.feedback.contentMessage"),
		question: intl.get("survey.feedback.question"),
	},
})