import React from 'react'
import { Button } from 'components/uikit-adapter/index'
import SaveIcn from "images/save-icn.svg";
import { useZapmslContext } from '../../../../../context/zapmslContext';
import { useGlobalContext } from 'hooks/useGlobalContext';
import { errorMessage } from '../../../../../utils/format';
import { releaseSave } from '../../../../../primary-material-schedule.service';
import intl from 'react-intl-universal'
import { validSaveRelease } from '../../../../../utils/validate';

const BtnReleaseSave = () => {
  const {
    selectedLines,
    clearSelectedLines,
    startLoading,
    stopLoading,
    getCurrentFilterFormated,
    zapmslData,
    updateTableWithData
  } = useZapmslContext();

  const {
    showFeedback
  } = useGlobalContext();

  const verifyReturnItemsSapForSaveRelease = (data, res) => (data.ScheduleLineCounter === res.ScheduleLineCounter
    && data.ScheduleAgreement === res.ScheduleAgreement
    && data.MaterialNumber === res.MaterialNumber)

  const validateSaveRelease = async () => {
    const dataLineSelected = zapmslData
      .filter(x => selectedLines.includes(x.Id));
    const validated = validSaveRelease(dataLineSelected)
    if (validated === "") {
      await handleSaveRelease();
    } else {
      showFeedback(validated)
    }
  }

  const handleSaveRelease = async () => {
    try {
      startLoading()
      const _filters = getCurrentFilterFormated();

      const dataLinesSelected = zapmslData
        .filter(x => selectedLines.includes(x.Id))

      const dtoRequestSaveRelease = {
        ..._filters,
        DateTo: _filters.DateRangeTo,
        DateFrom: _filters.DateRangeFrom,
        TransactionId: dataLinesSelected.map(x => x.WmsTransactionId),
      }

      const realeaseSaveResponse = await releaseSave(dtoRequestSaveRelease);
      const _zapmslData = [...zapmslData];

      realeaseSaveResponse.filter(res => res.Status === "Success").forEach((res) => {
        const indexOf = zapmslData.findIndex(data => verifyReturnItemsSapForSaveRelease(data, res))
        _zapmslData[indexOf].ReleaseStatus = res.ReleaseStatus
      });
      clearSelectedLines();
      updateTableWithData([..._zapmslData])
      showFeedback(intl.get('primaryMaterialScheduleList.feedbacks.releaseSuccessfully'))
    }
    catch (e) {
      showFeedback(errorMessage(e))
    }
    stopLoading();
  }

  return (
    <div className='btn-wrapper' data-testid='wrapper-btn-save' title='Release and Save Schedule'>
      <Button
        type="secondary"
        icon={SaveIcn}
        className='btn-icon'
        onClick={validateSaveRelease}
      />
    </div>
  )
}

export default BtnReleaseSave
