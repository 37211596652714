import React, { useEffect, useState } from 'react'
import intl from 'react-intl-universal'
import ModalDiscarChanges from '../../../../../auto-load-optimization/modal-discard'
import { Row, Col } from "react-flexbox-grid"
import { useDeliveryScheduleConfigurationContext } from '../../context/deliveryScheduleConfigurationContext'
import { Button, SelectBox } from 'components/uikit-adapter/index'
import { optionsHours } from '../utils'
import { getCenters, saveChanges } from '../../deliveryScheduleConfiguration.service'
import { useGlobalContext } from 'hooks/useGlobalContext';
import { formatErrorMessage } from '../../../../../../utils/handle-error'
import { getDoorOptions, getValueDoor, isDisabledSelectDoor } from './utils'
import { initialValueDoor } from '../../utils'

const FilterDeliveryScheduleConfiguration = () => {
  const [centers, setCenters] = useState([]);
  const [periods] = useState(optionsHours());

  useEffect(() => {
    loadInfos();
  }, [])

  const {
    changes,
    filter,
    setFilter,
    clearChanges,
    startLoading,
    stopLoading
  } = useDeliveryScheduleConfigurationContext()

  const { showFeedback } = useGlobalContext();

  const [modalConfirm, setOpenModalConfirm] = useState({
    isOpen: false,
    change: ""
  });

  const closeModalConfirm = () => setOpenModalConfirm({
    isOpen: false,
    change: ""
  });

  const proccessErro = (e) => {
    showFeedback(formatErrorMessage(e))
  }

  const loadInfos = async () => {
    startLoading()
    try {
      const _centers = await getCenters();
      setCenters(_centers);
      setFilter(prevState => ({
        ...prevState,
        period: periods[0]
      }))
    }
    catch (e) {
      proccessErro(e)
    }
    stopLoading()
  }

  const handleSave = async () => {
    startLoading();
    try {
      const dto = {
        CronogramaEntregas: changes,
        Periodo: filter.period.key,
        IdUnidadeNegocio: filter.center.IdUnidadeNegocio,
        Doca: filter.door.value
      }
      await saveChanges(dto)
      clearChanges();
      showFeedback(intl.get('master-data.feedback.savedSuccessfully'))
    }
    catch (e) {
      proccessErro(e)
    }
    stopLoading();
  }

  const handleChangeValue = (value, field) => {
    if (value !== filter[field]) {
      if (changes.length === 0) {
        setFilter(prevState => ({
          ...prevState,
          [field]: value
        }))
      }
      else {
        setOpenModalConfirm({
          isOpen: true,
          change: () => setFilter(prevState => ({
            ...prevState,
            [field]: value
          }))
        })
      }
    }
  }

  const handleChange = () => {
    startLoading();
    modalConfirm.change();
    closeModalConfirm();
  }

  const handleRefuseDiscad = () => {
    stopLoading();
    closeModalConfirm();
  }

  const handleChangeBrewery = (value) => {
    handleChangeValue(value, 'center')
    handleChangeValue(initialValueDoor, 'door')
  }

  return (
    <React.Fragment>
      <Row>
        <Col xs={4} style={{ maxWidth: '400px' }}>
          <SelectBox
            name='brewery'
            label={intl.get('commons.brewery')}
            placeholder={intl.get('commons.brewery')}
            valueKey="IdUnidadeNegocio"
            labelKey="Descricao"
            value={filter.center}
            onChange={value => handleChangeBrewery(value)}
            options={centers}
            searchable
            openOnFocus
            data-testid='selectbox-center'
          />
        </Col>
        <Col xs={2} style={{ maxWidth: '300px' }}>
          <SelectBox
            label={intl.get("commons.door")}
            name="door"
            value={getValueDoor(filter)}
            options={getDoorOptions(filter.center)}
            valueKey="value"
            labelKey="label"
            onChange={value => handleChangeValue(value, 'door')}
            disabled={isDisabledSelectDoor(filter)}
            searchable
          />
        </Col>
        <Col xs={2} style={{ maxWidth: '300px' }}>
          <SelectBox
            label={intl.get("commons.period")}
            name="period"
            value={filter.period}
            options={periods}
            valueKey="key"
            labelKey="hora"
            onChange={value => handleChangeValue(value, 'period')}
            searchable
            openOnFocus
            data-testid='selectbox-period'
          />
        </Col>
        <Col xs={3} style={{ alignSelf: 'self-end', maxWidth: '200px' }}>
          <Button
            onClick={handleSave}
            type='default'
            value={intl.get('geral.buttonsDefault.saveChanges')}
            disabled={changes.length === 0}
          />
        </Col>
      </Row>
      <ModalDiscarChanges
        open={modalConfirm.isOpen}
        onClose={handleRefuseDiscad}
        discardChanges={handleChange}
        message={intl.get('commons.unsavedChanges')}
      />
    </React.Fragment>
  )
}

export default FilterDeliveryScheduleConfiguration
