import intl from 'react-intl-universal'

export const Columns = () => ([
  {
    prop: 'Checkbox',
    name: '',
    visible: true
  },
  {
    prop: 'Step',
    name: intl.get("commons.stage"),
    visible: true
  },
  {
    prop: 'SupplierName',
    name: intl.get("commons.source"),
    visible: true
  },
  {
    prop: 'BusinessUnit',
    name: intl.get("commons.destiny"),
    visible: true
  },
  {
    prop: 'MaterialName',
    name: intl.get("commons.material"),
    visible: true
  },
  {
    prop: 'Quantity',
    name: intl.get("commons.quantity"),
    visible: true
  },
  {
    prop: 'Lot',
    name: intl.get("commons.lot"),
    visible: true
  },
  {
    prop: 'SuggestionCollectDate',
    name: intl.get("commons.colDate"),
    visible: true
  },
  {
    prop: 'SuggestionDeliveryDate',
    name: intl.get('commons.deliveryDate'),
    visible: true
  },
  {
    prop: 'SuggestionDeliveryTime',
    name: intl.get("commons.deliveryTime"),
    visible: true
  },
  {
    prop: 'StockUnitCurrent',
    name: intl.get("commons.current"),
    visible: true
  },
  {
    prop: 'SuggestionStatusDescription',
    name: intl.get("commons.status"),
    visible: true
  }
])
