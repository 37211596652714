import React, { Component } from "react";
import PropTypes from "prop-types";
import Fetch from "utils/fetch";
import moment from "moment";
import SessionUser from "utils/user-storage";
import Message from "components/message";
import { Row, Col } from "react-flexbox-grid";
import { Input, Textarea } from "@hbsis.uikit/react";
import { Button } from 'components/uikit-adapter';
import {
  formatErrorMessage,
} from "utils/handle-error";
import intl from 'react-intl-universal';
import "./editar-pedido-em-negociacao.css";
import DateInput from "../../date-input";
import Loading from 'components/center-loading';
import InputQuantidade from "../components/input-quantidade";
import { parseFloatByLocale } from "locales/utils";
import { getTextAreaStyle } from "components/suggestions-list/suggestions-list-item/suggestions-list-item.styled";

class EditarPedidoEmNegociacao extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showMsg: false,
      titleMsg: "",
      textMsg: "",
      dataColetaPrevista: "",
      dataEntregaPrevista: "",
      horaEntrega: "",
      observacao: "",
      unidadeMedida: "",
      quantidade: 0,
      fornecedor: null,
      fornecedores: [],
      statusAlteracaoFornecedor: {
        tituloFornecedorBloqueado: '',
        fornecedorDesabilitado: false,
      },
      isLoading: false,
    };
  }

  componentDidMount() {
    const formatDate = "YYYY-MM-DD";

    this.setState({
      dataColetaPrevista: moment(this.props.data.DataColetaPrevista).format(formatDate),
      dataEntregaPrevista: moment(this.props.data.DataEntregaPrevista).format(formatDate),
      horaEntrega: this.props.horaEntrega,
      unidadeMedida: this.props.unidadeMedida,
      fornecedor: this.props.idFornecedor,
      observacao: "",
      quantidade: this.props.quantidade,
    });
  }

  startLoading = () => { this.setState({ isLoading: true }) }

  stopLoading = () => { this.setState({ isLoading: false }) }

  changeValue = (event) => {

    let value = event.target.value
    const prop = event.target.name

    this.setState({ [prop]: value });
  }

  changeValueData = (name, data) => {
    this.setState({ [name]: data });
  }

  changeValueHoraEntrega = (event) => {
    this.setState({ horaEntrega: event.target.value });
  }

  save = (e) => {

    e.preventDefault();
    const user = SessionUser.get();

    const {
      quantidade,
      unidadeMedida,
      dataColetaPrevista,
      dataEntregaPrevista,
      observacao,
      fornecedor,
      horaEntrega
    } = this.state;

    const {
      idPedido,
      refreshTimeLine,
      handleFeedback
    } = this.props;

    const urlPost = "proposta/editarProposta"
    const userType = "comprador";

    if (!dataEntregaPrevista) {
      handleFeedback(intl.get('feedbacks.mandatoryDeliveryDate'))
    } else if (!dataColetaPrevista) {
      handleFeedback(intl.get('feedbacks.mandatoryPickupDate'))
    } else if (new Date(dataColetaPrevista) > new Date(dataEntregaPrevista)) {
      handleFeedback(intl.get('feedbacks.errorMandatoryPickupDate'))
    } else {

      this.startLoading();

      Fetch.post(`/timeline/${urlPost}/${userType}`, {
        IdPedido: idPedido,
        IdUsuario: user.Id,
        idFornecedor: fornecedor,
        DataColeta: dataColetaPrevista,
        DataEntrega: dataEntregaPrevista,
        HoraEntrega: horaEntrega,
        Comentarios: observacao,
        UnidadeMedida: unidadeMedida,
        Quantidade: parseFloatByLocale(quantidade),
      })
        .then(refreshTimeLine)
        .then(() => {
          const dateToStorage = {
            DataColeta: dataColetaPrevista,
            DataEntrega: dataEntregaPrevista
          };

          localStorage.setItem(`editarPedidoEmNegocicacaoDatas_${idPedido}`, JSON.stringify(dateToStorage));
          handleFeedback(intl.get('stocks.timeline.feedback.editOrder'));
        })

        .catch(ex => this.proccessError(ex))
        .finally(() => this.stopLoading())
    }
  }

  proccessError = (exception) => {
    this.setState({
      showMsg: true,
      titleMsg:
        exception.response.status === 400 ? intl.get('feedbacks.atention') : intl.get('feedbacks.error'),
      textMsg:
        exception.response.status === 400 ? formatErrorMessage(exception) : intl.get('feedbacks.offServer')
    });
  }

  render() {

    const {
      dataColetaPrevista,
      dataEntregaPrevista,
      horaEntrega,
      observacao,
      quantidade,
    } = this.state;

    const dateFormat = 'YYYY-MM-DD';

    return (

      <div className="negotiation">
        <Loading isLoading={this.state.isLoading} />
        <div className="negotiation-line">
          <Row>

            <Col xs={6} className="components-childs">
              <DateInput
                label={intl.get('commons.colDate')}
                value={dataColetaPrevista}
                onChange={(date) => this.changeValueData('dataColetaPrevista', date)}
                minDate={moment().format(dateFormat)}
                maxDate="9999-12-31"
                className="data-coleta-picker"
              />
            </Col>

            <Col xs={6} className="components-childs">
              <DateInput
                label={intl.get('commons.deliveryDate')}
                value={dataEntregaPrevista}
                onChange={(date) => this.changeValueData('dataEntregaPrevista', date)}
                minDate={moment().format(dateFormat)}
                maxDate="9999-12-31"
                className="data-entrega-picker"
              />
            </Col>

            <InputQuantidade
              changeValue={this.changeValue}
              quantidade={quantidade}
            />

            <Col xs={6}>
              <Input
                label={intl.get('commons.deliveryTime')}
                name='HoraEntrega'
                htmlType='time'
                value={horaEntrega}
                onChange={event => this.changeValueHoraEntrega(event)}
                className='txtHoraEntrega input'
              />
            </Col>

          </Row>

        </div>

        <div className="negotiation-line">
          <Row>
            <Col xs={12}>
              <Textarea
                label={intl.get('manual-suggestion-order-form.observation')}
                value={observacao || ''}
                name="observacao"
                style={getTextAreaStyle({ width: '400px' })}
                onChange={this.changeValue}
              />
            </Col>
          </Row>
        </div>

        <div className="negotiation-line">
          <div className="event-action-offer">
            <Row>
              <Col xs={6}>
                <Button
                  className="button"
                  type="default"
                  onClick={this.props.handleCancel}
                  value={intl.get('geral.buttonsDefault.close')}
                />
              </Col>

              <Col xs={6}>
                <Button
                  className="button"
                  type="primary"
                  onClick={this.save}
                  value={intl.get('feedbacks.confirmOrderUpdate')}
                />
              </Col>
            </Row>

          </div>
        </div>


        <Message
          title={this.state.titleMsg}
          text={this.state.textMsg}
          show={this.state.showMsg}
          handleClose={() => { this.setState({ showMsg: false }); }}
        />

      </div>
    );
  }
}

EditarPedidoEmNegociacao.propTypes = {
  data: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleFeedback: PropTypes.func.isRequired,
  horaEntrega: PropTypes.any.isRequired,
  idFornecedor: PropTypes.any.isRequired,
  idPedido: PropTypes.any.isRequired,
  quantidade: PropTypes.number.isRequired,
  refreshTimeLine: PropTypes.any.isRequired,
  unidadeMedida: PropTypes.string.isRequired
}

export default EditarPedidoEmNegociacao;
