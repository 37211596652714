import PropTypes from "prop-types"
import React from 'react'
import { TableListPagination } from "@hbsis-planning/hbsis-planning-core";
import intl from 'react-intl-universal'
import { getColumns } from '../../utils/table-columns';
import HeaderList from './header';
import { WrapperList } from './style';

const ListZAPMSL = ({
  data,
  selectAllLines,
  filters,
  saveTransactionIds
}) => (
  <WrapperList>
    <HeaderList
      businessUnit={filters.plant.Nome}
      
      saveTransactionIds={saveTransactionIds}
    />
    <TableListPagination
      fetchedData={data}
      columns={getColumns(selectAllLines)}
      showFooterColumns={false}
      showClearOrder={false}
      renderPageSize={false}
      intlText={true}
      perPage={1000}
      clearOrderText={intl.get('capitalEmployed.clearOrderText')}
      clearFilterTex={intl.get('capitalEmployed.clearFilterText')}
    />
  </WrapperList>
)

ListZAPMSL.propTypes = {
  data: PropTypes.any.isRequired,
  filters: PropTypes.shape({
    plant: PropTypes.shape({
      Nome: PropTypes.any
    })
  }).isRequired,
  saveTransactionIds: PropTypes.any.isRequired,
  selectAllLines: PropTypes.any.isRequired
}

export default ListZAPMSL;
