import PropTypes from "prop-types"
import React from 'react'
import * as S from './styled'
import intl from 'react-intl-universal'

const CardFreeSlot = ({ quantity, hour, handleClick, dia, selectedFreeSlot }) => (
  <>
    {[...Array(quantity).keys()].map((x, i) => (
      <S.Wrapper
        onClick={() => handleClick({ Hour: hour }, i)}
        data-testid={`free-slot-${dia}-${hour}-${i}`}
        isSelected={selectedFreeSlot === `${dia}-${hour}-${i}`}
      >
        <S.Hora>
          {hour && <span> {hour}</span>}
        </S.Hora>
        <S.Content>
          <span>{intl.get("loadComposition.filter.freeSlot")}</span>
        </S.Content>
      </S.Wrapper>
    ))}
  </>
)

CardFreeSlot.propTypes = {
  dia: PropTypes.any.isRequired,
  handleClick: PropTypes.func.isRequired,
  hour: PropTypes.any.isRequired,
  quantity: PropTypes.any.isRequired,
  selectedFreeSlot: PropTypes.any.isRequired
}

export default CardFreeSlot;
