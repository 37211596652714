import React from 'react'

import './card-divisor.css'

const CardDivisor = () => (
  <div className='divisor-container'>
    <div className='card-divisor' />
  </div>
)

export default CardDivisor
