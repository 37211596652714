import { Component, Fragment } from "react";
import { Row, Col } from "react-flexbox-grid";
import PropTypes from "prop-types";
import Fetch from "utils/fetch";

import intl from "react-intl-universal"
import { Button, Input, SelectBox, Dialog, SnackBar, Toggle } from "components/uikit-adapter/index";

import Loading from "components/center-loading";
import StyledForm from "./styledForm";

import {
  searchWallets,
  searchFamilies,
  searchLabeledFamilies,
  searchCenters,
  searchCoordination,
  searchPeriodicidadeList,
  searchTipoAlgoritmoList,
  searchPeriodicidade,
  searchSaneamentoVolumesPendentes,
  searchPilotoAutomatico,
  searchAcionamentoTransporteCIF,
  buscarFamiliaRotuladaCentro
} from "./general-form.service";
import InsumosInput from "components/insumos-input";
import { validaReplanejamentosPedidosDX } from "./validations";
import { getValueForCondition } from "utils/custom-functions";
import { Switcher } from "components/toggle";
import { formatNumber, formatOnlyNumber } from "utils/format";
import InputNumber from "components/input-number";
import { parseFloatByLocale } from "locales/utils";
import { hideSomeSettingsLabeledFamily, isLoadCompositionFlowBrewerySide, usesHourlyPlan, usesOORRelevance, usesTmsIntegration, usesVolume } from "utils/validations-general-settings";
import Feature from "components/feature";
import SessionUser from 'utils/user-storage'
import TipoPerfilAcesso from "models/usuarios/tipo-perfil-acesso";
import { formatErrorMessage } from "utils/handle-error";

const TIPO_ALGORITMO_POLITICA_OBJECTIVA = 1;
const TIPO_ALGORITMO_CAPACIDADE_ARMAZENAGEM = 2;
const TIPO_ALGORITMO_POLITICA_SILOS = 3;

const PERIODICIDADE_MENSAL = 1;
const PERIODICIDADE_DIARIA = 2;

const INTL_GERAL = 'master-data.general-configuration';

class GeneralForm extends Component {
  constructor() {
    super();
    this.state = {
      _isMounted: false,
      labeledFamily: {},
      families: [],
      wallets: [],
      labeledFamilies: [],
      centers: [],
      coordinations: [],
      selectedWallet: "",
      selectedFamily: "",
      selectedLabeledFamily: "",
      selectedCenter: "",
      selectedCoordination: [],
      selectedPeriodicidade: "",
      selectedSaneamentoVolumesPendentes: true,
      selectedReplanejamentoDataEntrega: false,
      selectedCreateChargeCompositionAutomatic: false,
      selectedExternalSuggestion: false,
      selectedUseHourlyPlan: false,
      selectedFutureNeed: false,
      selectedReschedulingOrderByCapacity: false,
      selectedPilotoAutomatico: false,
      selectedDeliveryAutomaticRefresh: false,
      selectedAcionamentoTransporteCIF: false,
      selectedLoadMixReleasedRefresh: false,
      selectedOORRelevance: false,
      selectedSaneamentoPedidos: false,
      selectedBreakInFullTruck: false,
      selectedNegotiateLoadCompositions: false,
      periodicidades: [],
      selectedTipoAlgoritmo: "",
      tiposAlgoritmo: [],
      isFetching: false,
      showMsg: false,
      textMsg: "",
      pontoAtualPercAtt: false,
      pontoFuturoPercAtt: false,
      idPeriodicidadeFamiliaRotulada: "",
      saneamentoVolumesPendentesFamiliaRotulada: null,
      pilotoAutomaticoFamiliaRotulada: null,
      acionamentoTransporteCIFFamiliaRotulada: null,
      relevanciaOORFamiliaRotulada: null,
      isDailyWallet: true,
      campoPercentual: null,
      selectedAutopilotScheduleType: ""
    };
  }

  componentDidMount() {
    this.setState({ _isMounted: true });
    const editMode = this.props.editMode;

    if (editMode) {
      this.buscarFamiliaRotuladaCentro(this.props.idEdit);
    } else {
      if (usesVolume() && isLoadCompositionFlowBrewerySide()) this.setState({ selectedLoadMixReleasedRefresh: true });
      this.searchWallets();
    }

    this.searchCoordination();
    this.searchPeriodicidadeList();
    this.searchTipoAlgoritmoList();
  }

  componentWillUnmount() {
    this.setState({ _isMounted: false });
  }

  startFetching = () => {
    this.setState({
      isFetching: true
    });
  };

  stopFetching = () => {
    this.setState({
      isFetching: false
    });
  };

  userCanEditStockPolicies = () => {
    const editableUserProfiles = [
      TipoPerfilAcesso.Gestor,
      TipoPerfilAcesso.MasterData,
      TipoPerfilAcesso.Administrador
    ]
    return editableUserProfiles.includes(SessionUser.get().PerfilAcesso);
  }

  buscarFamiliaRotuladaCentro = async () => {
    try {
      const data = await buscarFamiliaRotuladaCentro(this.props.idEdit);
      this.setState({
        labeledFamily: {
          ...data,
          DataInicioPoliticaEstoqueFuturo: data.FutureStockPolicyStartDate
            ? new Date(data.FutureStockPolicyStartDate)
            : null,
          EstoqueMinimoAtual: data.CurrentMinimumStock,
          EstoqueMaximoAtual: data.CurrentMaximumStock,
          PontoReabastecimentoAtual: data.StockPolicyTypeId == TIPO_ALGORITMO_POLITICA_SILOS
            ? data.CurrentMaximumStock
            : data.CurrentRefuelingPoint,
          orderSanitation: data.NumberOfDaysToProcessOrders,
          HorizonteProgramacao: data.ProgrammingHorizon,
          ReplanejamentoPedidosDX: data.ReplanningOrdersDX,
          Centro: data.Destination,
          FamiliaRotulada: data.LabeledFamily,
          Familia: data.Family,
          Carteira: data.MaterialGroup
        },
        campoPercentual: Math.ceil(data.CurrentRefuelingPointPercent),
        familyId: data.Id,
        selectedWallet: data.MaterialGroupId,
        selectedFamily: data.FamilyId,
        selectedLabeledFamily: data.LabeledFamilyId,
        selectedCoordination: data.Coordinations,
        selectedCenter: data.BusinessUnitId,
        selectedPeriodicidade: data.PeriodicityId,
        idPeriodicidadeFamiliaRotulada: data.LabeledFamilyPeriodicityId,
        saneamentoVolumesPendentesFamiliaRotulada: data.LabeledFamilySanitationPendingVolumes,
        pilotoAutomaticoFamiliaRotulada: data.LabeledFamilyAutopilot,
        acionamentoTransporteCIFFamiliaRotulada: data.LabeledFamilyTransportDriveCIF,
        selectedTipoAlgoritmo: data.StockPolicyTypeId,
        selectedSaneamentoVolumesPendentes: data.SanitationPendingVolumes,
        selectedReplanejamentoDataEntrega: data.ReplanningDeliveryDate,
        selectedCreateChargeCompositionAutomatic: data.CreateAutomaticLoadComposition,
        selectedExternalSuggestion: data.ExternalSuggestion,
        selectedUseHourlyPlan: data.UseHourlyPlan,
        selectedFutureNeed: data.FutureNeed,
        selectedReschedulingOrderByCapacity: data.ReschedulingOrderByCapacity,
        selectedPilotoAutomatico: data.Autopilot,
        selectedAcionamentoTransporteCIF: data.TransportDriveCIF,
        selectedDeliveryAutomaticRefresh: data.AutomaticDeliveryAdjustment,
        selectedLoadMixReleasedRefresh: data.LoadMixAllowed,
        selectedOORRelevance: data.RelevancyOOR,
        selectedSaneamentoPedidos: data.OrdersSanitation,
        selectedBreakInFullTruck: data.SuggestionsForDeliveryBreak,
        selectedNegotiateLoadCompositions: data.NegotiateLoadCompositions,
        relevanciaOORFamiliaRotulada: data.LabeledFamilyRelevancyOOR,
        numberOfDaysInAdvanceForSuggestionGeneration: data.NumberOfDaysInAdvanceForSuggestionGeneration,
        isDailyWallet: data.PeriodicityId === PERIODICIDADE_DIARIA,
        selectedAutopilotScheduleType: data.AutopilotScheduleType
      });
    }
    catch (e) {
      this.proccessErro(e)
    }
  }

  proccessErro = (e) => {
    this.setState({
      showMsg: true,
      textMsg: formatErrorMessage(e)
    });
  };

  proccessEstoqueError = mensagem => {
    this.setState({
      showMsg: true,
      textMsg: mensagem
    });
  };

  finalizeProccessEstoqueError = () => {
    this.setState({
      showMsg: false,
      textMsg: ""
    });
  };

  searchWallets = async () => {
    try {
      const data = await searchWallets()
      this.proccessData(data, "wallets")
    }
    catch (e) {
      this.proccessErro(e)
    }
  }

  searchFamilies = async (value) => {
    try {
      const data = await searchFamilies(value)
      this.proccessData(data, "families")
    }
    catch (e) {
      this.proccessErro(e)
    }
  }

  searchLabeledFamilies = async (value) => {
    try {
      const data = await searchLabeledFamilies(value)
      this.proccessData(data, "labeledFamilies")
    }
    catch (e) {
      this.proccessErro(e)
    }
  }

  searchCenters = async (value) => {
    try {
      const data = await searchCenters(value)
      this.proccessData(data, "centers")
    }
    catch (e) {
      this.proccessErro(e)
    }
  }

  searchCoordination = async () => {
    try {
      const data = await searchCoordination()
      this.proccessData(data, "coordinations")
    }
    catch (e) {
      this.proccessErro(e)
    }
  }

  searchPeriodicidadeList = async () => {
    try {
      const data = await searchPeriodicidadeList()
      this.proccessData(data, "periodicidades")
    }
    catch (e) {
      this.proccessErro(e)
    }
  }

  searchTipoAlgoritmoList = async () => {
    try {
      const data = await searchTipoAlgoritmoList()
      this.proccessData(data, "tiposAlgoritmo")
    }
    catch (e) {
      this.proccessErro(e)
    }
  }

  searchPeriodicidade = async (id) => {
    try {
      const data = await searchPeriodicidade(id)

      this.setState({
        idPeriodicidadeFamiliaRotulada: data,
        selectedPeriodicidade: data
      })

      this.updateSelectValue("selectedPeriodicidade", data)
    }
    catch (e) {
      this.proccessErro(e)
    }
  }

  searchSaneamentoVolumesPendentes = async (id) => {
    try {
      const data = await searchSaneamentoVolumesPendentes(id)
      if (this.state._isMounted) {
        this.setState({
          saneamentoVolumesPendentesFamiliaRotulada: data,
          selectedSaneamentoVolumesPendentes: data
        })
      }
    }
    catch (e) {
      this.proccessErro(e)
    }
  }

  searchPilotoAutomatico = async (id) => {
    try {
      const data = await searchPilotoAutomatico(id)
      if (this.state._isMounted) {
        this.setState({
          pilotoAutomaticoFamiliaRotulada: data,
          selectedPilotoAutomatico: data
        })
      }
    }
    catch (e) {
      this.proccessErro(e)
    }
  }
  searchAcionamentoTransporteCIF = async (id) => {
    try {
      const data = await searchAcionamentoTransporteCIF(id)
      if (this.state._isMounted) {
        this.setState({
          acionamentoTransporteCIFFamiliaRotulada: data,
          selectedAcionamentoTransporteCIF: data
        })
      }
    }
    catch (e) {
      this.proccessErro(e)
    }
  }

  proccessData = (data, prop) => {
    if (this.state._isMounted)
      this.setState({ [prop]: data });
  };

  changeValue = event => {
    const prop = event.target.name;
    let value = event.target.value;

    if (prop == 'HorizonteProgramacao' || prop == 'ReplanejamentoPedidosDX' || prop === 'orderSanitation') {
      value = formatOnlyNumber(value)
    }

    this.setState(prevState => ({
      labeledFamily: {
        ...prevState.labeledFamily,
        [prop]: value
      }
    }));
  };

  changeValueDecimal = (prop, value) => {
    this.setState(prevState =>
      ({
        labeledFamily: {
          ...prevState.labeledFamily,
          [prop]: value
        }
      }),
      () => {
        if (prop === "EstoqueMinimoAtual" || prop === "EstoqueMaximoAtual" || prop === "PontoReabastecimentoAtualPerc") {
          this.calculaPontoReabastecimentoAtual();
        }
        if (prop === "EstoqueMinimoFuturo" || prop === "EstoqueMaximoFuturo" || prop === "PontoReabastecimentoFuturoPerc") {
          this.calculaPontoReabastecimentoFuturo();
        }
      }
    );
  }

  changeDecimalValue = event => {
    const prop = event.target.name
    let value = event.target.value
    if (value) {
      value = value.toString()
      value = value.length > 3 ? value.slice(0, 3) : value
    }

    this.setState({
      campoPercentual: value
    })

    this.changeValueDecimal(prop, value)
  };

  calculaPontoReabastecimentoAtual = () => {
    const { pontoAtualPercAtt, labeledFamily } = this.state;
    const { editMode } = this.props;

    const estoqueMinimoAtual = parseFloatByLocale(labeledFamily.EstoqueMinimoAtual);
    const estoqueMaximoAtual = parseFloatByLocale(labeledFamily.EstoqueMaximoAtual);

    const pontoReabastecimentoAtualPerc = !pontoAtualPercAtt && !editMode ? 100
      : parseFloatByLocale(labeledFamily.PontoReabastecimentoAtualPerc)

    const intervalo = estoqueMaximoAtual - estoqueMinimoAtual;
    const pontoReabastecimentoAtual = ((Number(pontoReabastecimentoAtualPerc) / 100) * Number(intervalo)) + Number(estoqueMinimoAtual);

    this.setState(prevState => ({
      labeledFamily: {
        ...prevState.labeledFamily,
        PontoReabastecimentoAtual: !pontoReabastecimentoAtual ? estoqueMinimoAtual : pontoReabastecimentoAtual,
        PontoReabastecimentoAtualPerc: pontoReabastecimentoAtualPerc
      },
      pontoAtualPercAtt: true
    }));
  };

  calculaPontoReabastecimentoFuturo = () => {
    const { pontoFuturoPercAtt, labeledFamily } = this.state;
    const { editMode } = this.props;

    const estoqueMinimoFuturo = parseFloatByLocale(labeledFamily.EstoqueMinimoFuturo);

    const estoqueMaximoFuturo = parseFloatByLocale(labeledFamily.EstoqueMaximoFuturo);

    const pontoReabastecimentoFuturoPerc = (!pontoFuturoPercAtt && !editMode) ? 50
      : parseFloat(labeledFamily.PontoReabastecimentoFuturoPerc);

    const pontoReabastecimentoFuturo = (pontoReabastecimentoFuturoPerc / 100) * (estoqueMaximoFuturo - estoqueMinimoFuturo) + estoqueMinimoFuturo;

    this.setState(prevState => ({
      labeledFamily: {
        ...prevState.labeledFamily,
        PontoReabastecimentoFuturo: !pontoReabastecimentoFuturo ? estoqueMinimoFuturo : pontoReabastecimentoFuturo,
        PontoReabastecimentoFuturoPerc: pontoReabastecimentoFuturoPerc
      },
      pontoFuturoPercAtt: true
    }));
  };

  save = () => {
    const {
      selectedLabeledFamily,
      selectedCenter,
      selectedCoordination,
      selectedPeriodicidade,
      selectedTipoAlgoritmo,
      selectedSaneamentoVolumesPendentes,
      selectedReplanejamentoDataEntrega,
      selectedCreateChargeCompositionAutomatic,
      selectedExternalSuggestion,
      selectedFutureNeed,
      selectedReschedulingOrderByCapacity,
      selectedUseHourlyPlan,
      selectedPilotoAutomatico,
      selectedAcionamentoTransporteCIF,
      selectedDeliveryAutomaticRefresh,
      selectedLoadMixReleasedRefresh,
      selectedOORRelevance,
      selectedSaneamentoPedidos,
      selectedBreakInFullTruck,
      selectedNegotiateLoadCompositions,
      campoPercentual,
      numberOfDaysInAdvanceForSuggestionGeneration,
      selectedAutopilotScheduleType
    } = this.state;
    let model = this.state.labeledFamily;

    model = {
      FutureStockPolicyStartDate: model.DataInicioPoliticaEstoqueFuturo,
      ProgrammingHorizon: model.HorizonteProgramacao,
      ReplanningOrdersDX: model.ReplanejamentoPedidosDX,
      Destination: model.Centro,
      LabeledFamily: model.FamiliaRotulada,
      Family: model.Familia,
      MaterialGroup: model.Carteira,
      LabeledFamilyId: selectedLabeledFamily,
      BusinessUnitId: selectedCenter,
      Coordinations: selectedCoordination,
      CurrentMinimumStock: parseFloatByLocale(model.EstoqueMinimoAtual),
      CurrentMaximumStock: parseFloatByLocale(model.EstoqueMaximoAtual),
      CurrentRefuelingPoint: model.PontoReabastecimentoAtual,
      CurrentRefuelingPointPercent: parseFloatByLocale(campoPercentual),
      MinimumFutureStock: model.EstoqueMinimoFuturo
        ? parseFloatByLocale(model.EstoqueMinimoFuturo)
        : null,
      MaximumFutureStock: model.EstoqueMaximoFuturo
        ? parseFloatByLocale(model.EstoqueMaximoFuturo)
        : null,
      FutureRefuelingPointPercent: model.PontoReabastecimentoFuturoPerc
        ? parseFloatByLocale(model.PontoReabastecimentoFuturoPerc)
        : null,
      FutureRefuelingPoint: model.PontoReabastecimentoFuturo
        ? parseFloatByLocale(model.PontoReabastecimentoFuturo)
        : null,
      Id: this.props.idEdit,
      PeriodicityId: selectedPeriodicidade,
      StockPolicyTypeId: selectedTipoAlgoritmo,
      SanitationPendingVolumes: selectedSaneamentoVolumesPendentes,
      ReplanningDeliveryDate: selectedReplanejamentoDataEntrega,
      CreateAutomaticLoadComposition: selectedCreateChargeCompositionAutomatic,
      ExternalSuggestion: selectedExternalSuggestion,
      FutureNeed: selectedFutureNeed,
      ReschedulingOrderByCapacity: selectedReschedulingOrderByCapacity,
      UseHourlyPlan: selectedUseHourlyPlan,
      Autopilot: selectedPilotoAutomatico,
      TransportDriveCIF: selectedAcionamentoTransporteCIF,
      AutomaticDeliveryAdjustment: selectedDeliveryAutomaticRefresh,
      LoadMixAllowed: selectedLoadMixReleasedRefresh,
      RelevancyOOR: selectedOORRelevance,
      OrdersSanitation: selectedSaneamentoPedidos,
      SuggestionsForDeliveryBreak: selectedBreakInFullTruck,
      NegotiateLoadCompositions: selectedNegotiateLoadCompositions,
      NumberOfDaysInAdvanceForSuggestionGeneration: numberOfDaysInAdvanceForSuggestionGeneration,
      TruckSize : model.TruckSize,
      NumberOfDaysToProcessOrders: model.orderSanitation
        ? parseInt(model.orderSanitation)
        : null,
      AutopilotScheduleType: selectedAutopilotScheduleType
    };

    if (this.validarInformacoesCadastro(model)) {
      this.startFetching();
      Fetch.post(`/labeled-family-centers`, model)
        .then(this.props.handleRefresh)
        .then(() =>
          this.props.handleFeedback(
            `${intl.get("feedbacks.register")} ${!this.props.editMode ? intl.get("feedback.saved") : intl.get("feedbacks.updated")
            } ${intl.get("feedbacks.withSuccess")}`
          )
        )
        .catch(e => this.proccessErro(e))
        .finally(() => this.stopFetching());
    }
  };

  validarInformacoesCadastro = model => {
    if (!model.LabeledFamilyId) {
      this.proccessEstoqueError(intl.get("master-data.general.familyLabeledCenter.feedback.message1"));
      return false;
    } else if (!model.BusinessUnitId) {
      this.proccessEstoqueError(intl.get("master-data.general.familyLabeledCenter.feedback.message2"));
      return false;
    } else if (model.Coordinations.length <= 0) {
      this.proccessEstoqueError(intl.get("master-data.general.familyLabeledCenter.feedback.message3"));
      return false;
    } else if (!model.StockPolicyTypeId) {
      this.proccessEstoqueError(intl.get("master-data.general.familyLabeledCenter.feedback.message4"));
      return false;
    } else if (validaReplanejamentosPedidosDX(model.ReplanningOrdersDX)) {
      this.proccessEstoqueError(intl.get("master-data.general.familyLabeledCenter.feedback.message5"));
      return false;
    } else if (!model.ProgrammingHorizon) {
      this.proccessEstoqueError(intl.get("master-data.general.familyLabeledCenter.feedback.message6"));
      return false;
    } else if (model.CurrentMaximumStock < model.CurrentMinimumStock
      && model.StockPolicyTypeId != TIPO_ALGORITMO_POLITICA_SILOS) {
      this.proccessEstoqueError(intl.get("master-data.general.familyLabeledCenter.feedback.message8"));
      return false;
    } else if (Math.ceil(model.CurrentRefuelingPointPercent) > 100) {
      this.proccessEstoqueError(intl.get("master-data.general.familyLabeledCenter.feedback.message12"));
      return false;
    } else if ((Math.ceil(model.CurrentRefuelingPointPercent) < 0)) {
      this.proccessEstoqueError(intl.get("feedbacks.messageLabeledFamilyCenter01"))
      return false;
    } else if (!model.CurrentRefuelingPointPercent && model.CurrentRefuelingPointPercent !== 0) {
      this.proccessEstoqueError(intl.get("master-data.general.familyLabeledCenter.feedback.message13"))
      return false;
    } else if (model.UseHourlyPlan && model.ProgrammingHorizon > 15) {
      this.proccessEstoqueError(intl.get("master-data.general.familyLabeledCenter.feedback.message14"))
      return false;
    } else {
      return true;
    }
  };

  updateSelectValue = (prop, value) => {
    this.setState({
      [prop]: value
    });

    if (prop == "selectedPeriodicidade" && value == PERIODICIDADE_MENSAL) {
      this.setState(prevState => ({
        selectedTipoAlgoritmo: TIPO_ALGORITMO_POLITICA_OBJECTIVA,
        selectedSaneamentoVolumesPendentes: false,
        selectedReplanejamentoDataEntrega: false,
        isDailyWallet: false,
        labeledFamily: {
          ...prevState.labeledFamily,
          ReplanejamentoPedidosDX: 0,
          EstoqueMinimoAtual: getValueForCondition(this.props.editMode, prevState.labeledFamily.EstoqueMinimoAtual, 0),
          EstoqueMaximoAtual: getValueForCondition(this.props.editMode, prevState.labeledFamily.EstoqueMaximoAtual, 0),
          PontoReabastecimentoAtualPerc: 100,
          PontoReabastecimentoAtual: getValueForCondition(this.props.editMode, prevState.labeledFamily.PontoReabastecimentoAtual, 0)
        },
        campoPercentual: 100
      }), () => this.calculaPontoReabastecimentoAtual());
    } else if (prop == "selectedPeriodicidade" && value == PERIODICIDADE_DIARIA) {
      this.setState(prevState => ({
        selectedSaneamentoVolumesPendentes: true,
        selectedCreateChargeCompositionAutomatic: false,
        isDailyWallet: true,
        labeledFamily: {
          ...prevState.labeledFamily,
          ReplanejamentoPedidosDX: getValueForCondition(this.props.editMode, prevState.labeledFamily.ReplanejamentoPedidosDX, 0),
          EstoqueMinimoAtual: getValueForCondition(this.props.editMode, prevState.labeledFamily.EstoqueMinimoAtual, 0),
          EstoqueMaximoAtual: getValueForCondition(this.props.editMode, prevState.labeledFamily.EstoqueMaximoAtual, 0),
          PontoReabastecimentoAtualPerc: 100,
          PontoReabastecimentoAtual: getValueForCondition(this.props.editMode, prevState.labeledFamily.PontoReabastecimentoAtual, 0)
        },
        campoPercentual: 100
      }), () => this.calculaPontoReabastecimentoAtual());
    }

    if (prop == "selectedTipoAlgoritmo" && value == TIPO_ALGORITMO_CAPACIDADE_ARMAZENAGEM) {
      this.setState(prevState => ({
        labeledFamily: {
          ...prevState.labeledFamily,
          PontoReabastecimentoAtualPerc: 100
        },
        campoPercentual: 100
      }), () => this.calculaPontoReabastecimentoAtual());
    }

    if (prop == "selectedTipoAlgoritmo" && value == TIPO_ALGORITMO_POLITICA_SILOS) {
      this.setState(prevState => ({
        labeledFamily: {
          ...prevState.labeledFamily,
          EstoqueMaximoAtual: formatNumber(0),
          PontoReabastecimentoAtualPerc: 100,
        },
        campoPercentual: 100
      }), () => this.calculaPontoReabastecimentoAtual());
    }
  };

  updateCoordinationSelectValue = event => {
    this.setState({
      selectedCoordination: event
    });
  };

  updateWalletSelectedValue = (prop, value) => {
    this.setState({
      selectedWallet: value,
      selectedFamily: "",
      selectedLabeledFamily: "",
      selectedCenter: ""
    });

    if (value > 0) {
      this.searchFamilies(value);
    }
  };

  updateFamilySelectedValue = (prop, value) => {
    this.setState({
      selectedFamily: value,
      selectedLabeledFamily: "",
      selectedCenter: ""
    });

    if (value > 0) {
      this.searchLabeledFamilies(value);
    }
  };

  updateLabeledFamilySelectedValue = (prop, value) => {
    this.setState({
      selectedLabeledFamily: value,
      selectedCenter: ""
    });

    if (value > 0) {
      this.searchCenters(value);
      this.searchPeriodicidade(value);
      this.searchSaneamentoVolumesPendentes(value);
      this.searchPilotoAutomatico(value);
      this.searchAcionamentoTransporteCIF(value);
    }
  };

  montarMensagemDivergenciaFamiliaRotulada = () => {
    return (
      !hideSomeSettingsLabeledFamily() &&
      <span className="span-message">
        {intl.get(`${INTL_GERAL}.optionDiffersLabeledFamily`)}
      </span>
    )
  }

  verificarSaneamentoDeVolume = () => {
    const {
      selectedPeriodicidade,
      saneamentoVolumesPendentesFamiliaRotulada,
      selectedSaneamentoVolumesPendentes
    } = this.state

    return (selectedPeriodicidade == PERIODICIDADE_DIARIA && saneamentoVolumesPendentesFamiliaRotulada != selectedSaneamentoVolumesPendentes)
  }

  montarToogle = (name, label, value, changeProp, disabled) => {
    const style = {
      labelStyle: {
        color: 'var(--color-contrast-black)'
      },
      thumbSwitched: {
        backgroundColor: 'var(--color-action-default)',
      },
      trackSwitched: {
        backgroundColor: 'var(--color-neutral-200)',
      },
    }

    return (
      <Toggle
        name={name}
        label={label}
        value={value}
        onChange={val => this.updateSelectValue(changeProp, val.checked)}
        thumbSwitchedStyle={style.thumbSwitched}
        trackSwitchedStyle={style.trackSwitched}
        labelStyle={style.labelStyle}
        labelPosition={"right"}
        disabled={disabled}
      />
    )
  }

  obterTitulo(editMode) {
    return editMode ? intl.get('master-data.general.actions.updateRotuledFamily') : intl.get('master-data.general.actions.newRotuledFamily');
  }

  renderTmsConfiguration(
    selectedAcionamentoTransporteCIF,
    selectedLabeledFamily,
    acionamentoTransporteCIFFamiliaRotulada
  ) {
    return <Fragment>
      <label className="label-style">{intl.get(`${INTL_GERAL}.tmsConfiguration`)}</label>
      <div className="container__general-form">
        <Row>
          <Col xs={6} lg={6} md={6}>
            <Switcher
              name="AcionamentoTransporteCIF"
              label={intl.get(`${INTL_GERAL}.triggerTransportCif`)}
              value={selectedAcionamentoTransporteCIF}
              onChange={val => this.updateSelectValue("selectedAcionamentoTransporteCIF", val.checked)}
            />
            {selectedLabeledFamily && acionamentoTransporteCIFFamiliaRotulada !== selectedAcionamentoTransporteCIF && (
              this.montarMensagemDivergenciaFamiliaRotulada()
            )}
          </Col>
        </Row>
      </div>
    </Fragment>
  }

  renderGeneralSettings(
    isDailyWallet,
    selectedExternalSuggestion,
    selectedFutureNeed,
    selectedReschedulingOrderByCapacity,
    selectedOORRelevance,
    relevanciaOORFamiliaRotulada,
    selectedUseHourlyPlan,
    selectedBreakInFullTruck,
    selectedLoadMixReleasedRefresh,
    selectedNegotiateLoadCompositions
  ) {
    return <Fragment>
      <label className="label-style">
        <span>
          {intl.get(`${INTL_GERAL}.generalSettings`)}
        </span>
      </label>

      <div className="container__general-form">
        <Row>
          <Col xs={6} lg={6} md={6} className="bulk-pending">
            <Switcher
              name="SugestaoExterna"
              label={intl.get(`${INTL_GERAL}.externalSuggestion`)}
              value={selectedExternalSuggestion}
              onChange={(value) => this.updateSelectValue("selectedExternalSuggestion", value.checked)}
            />
          </Col>
          <Col xs={6} lg={6} md={6} className="bulk-pending">
            {this.montarToogle(
              "NecessidadeFutura",
              intl.get(`${INTL_GERAL}.futureNeed`),
              selectedFutureNeed,
              "selectedFutureNeed",
            )}
          </Col>
          {usesOORRelevance() &&
            <Col xs={6} lg={6} md={6}>
              <Switcher
                name="RelevanciaOOR"
                label={intl.get(`${INTL_GERAL}.relevanceOOR`)}
                value={selectedOORRelevance}
                onChange={(value) => this.updateSelectValue("selectedOORRelevance", value.checked)}
              />
              {selectedOORRelevance !== relevanciaOORFamiliaRotulada && (
                this.montarMensagemDivergenciaFamiliaRotulada()
              )}
            </Col>
          }
          {isDailyWallet &&
            <Col xs={6} lg={6} md={6} className="bulk-pending">
              <Switcher
                name="ReschedulingOrderByCapacity"
                label={intl.get(`${INTL_GERAL}.reschedulingOrderByCapacity`)}
                value={selectedReschedulingOrderByCapacity}
                onChange={(value) => this.updateSelectValue("selectedReschedulingOrderByCapacity", value.checked)}
              />

            </Col>
          }
          {usesHourlyPlan() &&
            <Col xs={6} lg={6} md={6} className="bulk-pending">
              <Switcher
                name="UseHourlyPlan"
                label={intl.get(`${INTL_GERAL}.useHourlyPlan`)}
                value={selectedUseHourlyPlan}
                onChange={(value) => this.updateSelectValue("selectedUseHourlyPlan", value.checked)}
              />
            </Col>
          }
          <Col xs={6} lg={6} md={6} className="bulk-pending">
            <Switcher
              name="BreakInFullTruck"
              label={intl.get(`${INTL_GERAL}.breakInFullTruck`)}
              value={selectedBreakInFullTruck}
              onChange={(value) => this.updateSelectValue("selectedBreakInFullTruck", value.checked)}
            />
          </Col>
          {usesVolume() &&
            <Col xs={6} lg={6} md={6}>
              <Switcher
                name="MixCargaLiberado"
                label={intl.get(`${INTL_GERAL}.loadMixReleased`)}
                value={selectedLoadMixReleasedRefresh}
                onChange={val => this.updateSelectValue("selectedLoadMixReleasedRefresh", val.checked)}
              />
            </Col>
          }
          {isDailyWallet &&
            <Col xs={6} lg={6} md={6} className="bulk-pending">
              <Switcher
                name="NegotiateLoadCompositions"
                label={intl.get(`${INTL_GERAL}.negotiateLoadCompositions`)}
                value={selectedNegotiateLoadCompositions}
                onChange={(value) => this.updateSelectValue("selectedNegotiateLoadCompositions", value.checked)}
              />
            </Col>}
        </Row>
      </div>
    </Fragment>
  }

  defineTipoAlgoritmoOptions(tiposAlgoritmo) {
    return tiposAlgoritmo.filter(x => x.Id !== TIPO_ALGORITMO_POLITICA_SILOS)
  }

  defineTipoAlgoritmoDisabled(selectedPeriodicidade) {
    return selectedPeriodicidade !== 2;
  }

  definePontoReabastecimentoAtualPercDisabled(selectedTipoAlgoritmo) {
    const algorithmTypeToDisable = [TIPO_ALGORITMO_CAPACIDADE_ARMAZENAGEM, TIPO_ALGORITMO_POLITICA_SILOS]
    return (algorithmTypeToDisable.includes(selectedTipoAlgoritmo) || !this.userCanEditStockPolicies())
  }

  render() {
    const {
      labeledFamily,
      wallets,
      families,
      labeledFamilies,
      centers,
      coordinations,
      isFetching,
      selectedWallet,
      selectedFamily,
      selectedLabeledFamily,
      selectedCenter,
      selectedCoordination,
      selectedPeriodicidade,
      selectedSaneamentoVolumesPendentes,
      selectedReplanejamentoDataEntrega,
      selectedCreateChargeCompositionAutomatic,
      selectedExternalSuggestion,
      selectedUseHourlyPlan,
      selectedFutureNeed,
      selectedReschedulingOrderByCapacity,
      selectedLoadMixReleasedRefresh,
      selectedOORRelevance,
      selectedSaneamentoPedidos,
      relevanciaOORFamiliaRotulada,
      selectedAcionamentoTransporteCIF,
      selectedBreakInFullTruck,
      selectedNegotiateLoadCompositions,
      periodicidades,
      selectedTipoAlgoritmo,
      tiposAlgoritmo,
      showMsg,
      textMsg,
      idPeriodicidadeFamiliaRotulada,
      acionamentoTransporteCIFFamiliaRotulada,
      isDailyWallet,
      campoPercentual
    } = this.state

    const { open, editMode, handleClose } = this.props;
    return (
      <Fragment>
        <Loading isLoading={isFetching} />
        <Dialog
          title={this.obterTitulo(editMode)}
          open={open}
          autoScrollBodyContent
          actions={
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'right' }}>
              <Button
                value={intl.get("geral.buttonsDefault.cancel")}
                onClick={handleClose}
                type="default"
                className="button"
              />
              <Button
                value={intl.get("geral.buttonsDefault.save")}
                type="primary"
                onClick={this.save}
                className="button"
              />
            </div>
          }
        >
          <StyledForm>
            <Row>
              <Feature validation={!editMode}>
                <Col xs={6} lg={6} md={6}>
                  <SelectBox
                    required
                    name="Carteira"
                    className="carteira"
                    label={intl.get(`${INTL_GERAL}.wallet`)}
                    placeholder={intl.get(`${INTL_GERAL}.wallet`)}
                    valueKey="Id"
                    labelKey="Description"
                    value={selectedWallet}
                    options={wallets}
                    onChange={value =>
                      this.updateWalletSelectedValue("Carteira", value.Id)
                    }
                    searchable
                    openOnFocus
                  />
                </Col>
              </Feature>
              <Feature validation={editMode}>
                <Col xs={6} lg={6} md={6}>
                  <Input
                    disabled
                    label={intl.get(`${INTL_GERAL}.wallet`)}
                    name="Carteira"
                    value={labeledFamily.Carteira}
                    className="input"
                  />
                </Col>
              </Feature>

              <Feature validation={!editMode}>
                <Col xs={6} lg={6} md={6}>
                  <SelectBox
                    required
                    name="Familia"
                    className="familia"
                    label={intl.get(`${INTL_GERAL}.family`)}
                    placeholder={intl.get(`${INTL_GERAL}.family`)}
                    valueKey="Id"
                    labelKey="Name"
                    value={selectedFamily}
                    options={families}
                    onChange={value =>
                      this.updateFamilySelectedValue("Carteira", value.Id)
                    }
                    searchable
                    openOnFocus
                  />
                </Col>
              </Feature>
              <Feature validation={editMode}>
                <Col xs={6} lg={6} md={6}>
                  <Input
                    disabled
                    label={intl.get(`${INTL_GERAL}.family`)}
                    name="Familia"
                    value={labeledFamily.Familia}
                    className="input"
                  />
                </Col>
              </Feature>
            </Row>

            <Row>
              <Feature validation={!editMode}>
                <Col xs={6} lg={6} md={6}>
                  <SelectBox
                    required
                    name="FamiliaRotulada"
                    className="familiaRotulada"
                    label={intl.get(`${INTL_GERAL}.labeledFamily`)}
                    placeholder={intl.get(`${INTL_GERAL}.labeledFamily`)}
                    valueKey="Id"
                    labelKey="Description"
                    value={selectedLabeledFamily}
                    options={labeledFamilies}
                    onChange={value =>
                      this.updateLabeledFamilySelectedValue(
                        "FamiliaRotulada",
                        value.Id
                      )
                    }
                    searchable
                    openOnFocus
                  />
                </Col>
              </Feature>
              <Feature validation={editMode}>
                <Col xs={6} lg={6} md={6}>
                  <Input
                    disabled
                    label={intl.get(`${INTL_GERAL}.labeledFamily`)}
                    name="FamiliaRotulada"
                    value={labeledFamily.FamiliaRotulada}
                    className="input"
                  />
                </Col>
              </Feature>

              <Feature validation={!editMode}>
                <Col xs={6} lg={6} md={6}>
                  <SelectBox
                    required
                    name="Centro"
                    className="centro"
                    label={intl.get(`${INTL_GERAL}.center`)}
                    placeholder={intl.get(`${INTL_GERAL}.center`)}
                    valueKey="Id"
                    labelKey="Nome"
                    value={selectedCenter}
                    options={centers}
                    onChange={value => this.updateSelectValue("selectedCenter", value.Id)}
                    searchable
                    openOnFocus
                  />
                </Col>
              </Feature>
              <Feature validation={editMode}>
                <Col xs={6} lg={6} md={6}>
                  <Input
                    disabled
                    label={intl.get(`${INTL_GERAL}.center`)}
                    name="Centro"
                    value={labeledFamily.Centro}
                    className="input"
                  />
                </Col>
              </Feature>
            </Row>

            <Row>
              <Col xs={12} lg={12} md={12}>
                <SelectBox
                  required
                  name="Coordenacao"
                  className="coordenacao"
                  label={intl.get(`${INTL_GERAL}.coordination`)}
                  placeholder={intl.get(`${INTL_GERAL}.coordination`)}
                  valueKey="Id"
                  labelKey="Description"
                  value={selectedCoordination}
                  options={coordinations}
                  onChange={this.updateCoordinationSelectValue}
                  searchable
                  openOnFocus
                  multi
                />
              </Col>
            </Row>

            <Row>
              <Col xs={6}>
                <div className="auto-complete">
                  <SelectBox
                    required
                    name="Periodicidade"
                    className="periodicidade"
                    label={intl.get(`${INTL_GERAL}.frequency`)}
                    placeholder={intl.get(`${INTL_GERAL}.frequency`)}
                    valueKey="Id"
                    labelKey="Name"
                    value={selectedPeriodicidade}
                    options={periodicidades}
                    onChange={value => this.updateSelectValue("selectedPeriodicidade", value.Id)}
                    searchable
                    openOnFocus
                  />
                </div>
                {idPeriodicidadeFamiliaRotulada != selectedPeriodicidade && !hideSomeSettingsLabeledFamily() && (
                  <div className='span-message-container'>
                    {this.montarMensagemDivergenciaFamiliaRotulada()}
                  </div>
                )}
              </Col>
              <Col xs={6}>
                <div className="auto-complete">
                  <SelectBox
                    disabled={this.defineTipoAlgoritmoDisabled(selectedPeriodicidade)}
                    required
                    name="TipoAlgoritmo"
                    className="tipoAlgoritmo"
                    label={intl.get(`${INTL_GERAL}.typeAlgorithm`)}
                    placeholder=""
                    valueKey="Id"
                    labelKey="Description"
                    value={selectedTipoAlgoritmo}
                    options={this.defineTipoAlgoritmoOptions(tiposAlgoritmo)}
                    onChange={value => this.updateSelectValue("selectedTipoAlgoritmo", value.Id)}
                    searchable
                    openOnFocus
                  />
                </div>
              </Col>
            </Row>

            <Feature validation={isDailyWallet}>
              <Fragment>
                <label className="label-style">
                  <span>
                    {intl.get(`${INTL_GERAL}.configurationDailyWallet`)}
                  </span>
                </label>
                <div className="container__general-form">
                  <Row className="daily-wallet">
                    <Col xs={6} lg={6} md={6}>
                      <Input
                        label={intl.get(`${INTL_GERAL}.orderGridReplanning`)}
                        name="ReplanejamentoPedidosDX"
                        value={labeledFamily.ReplanejamentoPedidosDX}
                        onChange={this.changeValue}
                        className='input-dx input' />
                    </Col>
                  </Row>
                  {usesTmsIntegration() &&
                    <Row>
                      <Col xs={6} lg={6} md={6} className="bulk-pending">
                        <Switcher
                          name="ReplanejamentoDataEntrega"
                          label={intl.get(`${INTL_GERAL}.deliveryDateReplanning`)}
                          value={selectedReplanejamentoDataEntrega}
                          onChange={(value) => this.updateSelectValue("selectedReplanejamentoDataEntrega", value.checked)}
                        />
                      </Col>
                      <Col xs={6} lg={6} md={6} className="bulk-pending">
                        <Switcher
                          name="SaneamentoVolumesPendentes"
                          label={intl.get(`${INTL_GERAL}.cleanseBulkPending`)}
                          value={selectedSaneamentoVolumesPendentes}
                          onChange={(value) => this.updateSelectValue("selectedSaneamentoVolumesPendentes", value.checked)}
                        />
                        {this.verificarSaneamentoDeVolume() && (this.montarMensagemDivergenciaFamiliaRotulada())}
                      </Col>
                    </Row>
                  }
                  <Row>
                    {usesTmsIntegration() &&
                      <Col xs={6} lg={6} md={6}>
                        <Switcher
                          className="SaneamentoPedidos"
                          name="SaneamentoPedidos"
                          label={intl.get(`${INTL_GERAL}.orderSanitation`)}
                          value={selectedSaneamentoPedidos}
                          onChange={(value) => this.updateSelectValue("selectedSaneamentoPedidos", value.checked)}
                        />
                      </Col>
                    }
                  </Row>
                  {usesTmsIntegration() &&
                    selectedSaneamentoPedidos &&
                    <Row className="daily-wallet">
                      <Col xs={6} lg={6} md={6}>
                        <Input
                          label={intl.get(`${INTL_GERAL}.orderSanitation`)}
                          name="orderSanitation"
                          value={labeledFamily.orderSanitation}
                          onChange={this.changeValue}
                          className='input-order-sanitation input'
                        />
                        <legend className='input-legend'>{intl.get(`${INTL_GERAL}.onlyIntegerNumbers`)}</legend>
                      </Col>
                    </Row>
                  }
                </div>
              </Fragment>
            </Feature>

            <Feature validation={!isDailyWallet}>
              <Fragment>
                <label className="label-style">
                  <span>
                    {intl.get(`${INTL_GERAL}.configurationMonthlyWallet`)}
                  </span>
                </label>
                <div className="container__general-form">
                  <Row>
                    <Col xs={12} lg={12} md={12} className="bulk-pending">
                      <Switcher
                        name="CriarComposicaoDeCargaAutomatica"
                        label={intl.get(`${INTL_GERAL}.acceptChargeCompositionAutomatic`)}
                        value={selectedCreateChargeCompositionAutomatic}
                        onChange={(value) => this.updateSelectValue("selectedCreateChargeCompositionAutomatic", value.checked)}
                      />
                    </Col>
                  </Row>
                </div>
              </Fragment>
            </Feature>

            <Row>
              <Col xs={12} lg={12} md={12}>
                <Input
                  required
                  label={intl.get(`${INTL_GERAL}.programmingHorizon`)}
                  name="HorizonteProgramacao"
                  value={labeledFamily.HorizonteProgramacao}
                  onChange={this.changeValue}
                  className="input"
                />
              </Col>
            </Row>
            <label className="label-style">{intl.get(`${INTL_GERAL}.currentInventory`)}</label>
            <div className="container__general-form">
              <Row>
                <Col xs={6} lg={6} md={6}>
                  <InputNumber
                    type='default'
                    required
                    label={intl.get(`${INTL_GERAL}.minimumStock`)}
                    name="EstoqueMinimoAtual"
                    value={labeledFamily.EstoqueMinimoAtual}
                    onChange={_value => this.changeValueDecimal("EstoqueMinimoAtual", _value)}
                    disabled={!this.userCanEditStockPolicies()}
                  />
                </Col>
                <Col xs={6} lg={6} md={6}>
                  <InputNumber
                    type='default'
                    required
                    label={intl.get(`${INTL_GERAL}.maximumStock`)}
                    name="EstoqueMaximoAtual"
                    value={labeledFamily.EstoqueMaximoAtual}
                    onChange={_value => this.changeValueDecimal("EstoqueMaximoAtual", _value)}
                    disabled={(selectedTipoAlgoritmo == TIPO_ALGORITMO_POLITICA_SILOS) || !this.userCanEditStockPolicies()}
                  />
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <label className="label-style label-style-title">
                    {intl.get(`${INTL_GERAL}.refuelingPoint`)}
                  </label>
                </Col>
              </Row>

              <Row>
                <Col xs={4}>
                  <InsumosInput
                    disabled={this.definePontoReabastecimentoAtualPercDisabled(selectedTipoAlgoritmo)}
                    required
                    type="number"
                    maxLength="3"
                    value={campoPercentual}
                    name="PontoReabastecimentoAtualPerc"
                    onChange={(event) => this.changeDecimalValue(event)}
                  />
                </Col>
                <Col xs={8}>
                  <Input
                    disabled
                    label=""
                    name="PontoReabastecimentoAtual"
                    value={formatNumber(labeledFamily.PontoReabastecimentoAtual)}
                    className="input"
                  />
                </Col>
              </Row>
            </div>
            {usesTmsIntegration() && isDailyWallet &&
              this.renderTmsConfiguration(
                selectedAcionamentoTransporteCIF,
                selectedLabeledFamily,
                acionamentoTransporteCIFFamiliaRotulada
              )
            }
            {this.renderGeneralSettings(
              isDailyWallet,
              selectedExternalSuggestion,
              selectedFutureNeed,
              selectedReschedulingOrderByCapacity,
              selectedOORRelevance,
              relevanciaOORFamiliaRotulada,
              selectedUseHourlyPlan,
              selectedBreakInFullTruck,
              selectedLoadMixReleasedRefresh,
              selectedNegotiateLoadCompositions
            )}
          </StyledForm>
        </Dialog>

        <SnackBar
          message={textMsg}
          open={showMsg}
          autoHideDuration={3000}
          onRequestClose={this.finalizeProccessEstoqueError}
        />
      </Fragment>
    )
  }
}

GeneralForm.propTypes = {
  idEdit: PropTypes.number,
  open: PropTypes.bool.isRequired,
  editMode: PropTypes.bool.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleFeedback: PropTypes.func.isRequired
};

export default GeneralForm;
