import styled from 'styled-components'

export const DialogActions = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 22px;

  button:first-child {
    margin-right: 15px;
  }
`
export const DialogTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px;
  font-size: 18px;
`

export const DialogTitleText = styled.span`
  color: #3B4A5D;
  font-weight: var(--font-weight-semibold);
`
export const DialogTitleIcon = styled.span`
  font-weight: var(--font-weight-semibold);

  ::before {
    content:'X';
  }
`
export const DialogContentText = styled.div`
  color: #3B4A5D;
  font-size: 18px;
  font-weight: var(--font-weight-semibold);
  margin-bottom: 8px;
`

export const DialogContentSection = styled.div`
  display: flex;
  align-items: center;
  margin: 8px auto;
  justify-content: space-between;

  .dialog__content--text {
    width: 50%;
    margin-bottom: 8px;

    h4 {
      margin-top: 30px;
      margin-bottom: 0px;
    }

    p {
      margin-top: 5px;
      font-weight: var(--font-weight-regular);
      font-size: 14px;
    }
  }

  .dialog__content--action {
    width: 49%;
  }
`


