import React from 'react'
import Dialog from 'material-ui/Dialog'
import { Button } from 'components/uikit-adapter/index'
import intl from 'react-intl-universal'
import 'components/delete-dialog/delete-dialog.css'

export const ModalAlert = ({ open, handleClose, handleConfirm }) => (
  <Dialog
    title={<div data-testid='warning-create-lc'>{intl.get("modalLcAlert.warning")}</div>}
    contentStyle={{ width: '560px' }}
    open={open}
    onRequestClose={handleClose}
    actionsContainerClassName="container-action-buttons__deleteModal"
    actions={
      [
        <Button
          value={intl.get('master-data.general.materials.cancel')}
          id="btn-cancel-modal-alert-transit-time-lc"
          type='default'
          onClick={handleClose}
          className='container-action-buttons__deleteModal--buttons'
        />,
        <Button
          value={intl.get('modalLcAlert.confirm')}
          id="btn-confirm-modal-alert-transit-time-lc"
          type='danger'
          onClick={handleConfirm}
          className='container-action-buttons__deleteModal--buttons'
        />
      ]
    } >
    <div className='container-message'>
      <p>{intl.get("modalLcAlert.message01")}</p>
      <p>{intl.get("modalLcAlert.message02")}</p>
    </div>
  </Dialog>
)

