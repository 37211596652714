import styled from "styled-components"
import Header from "components/header"

import { makeStyles } from "@material-ui/core/styles"
import { Textarea } from '@hbsis.uikit/react'

export const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'none'
    }
  }
});

export const EstoqueFornecedorWrapper = styled.main`
  .errorMessage {
    position: relative;
    left: 50%;
    transform: translate(-50%,-50%);
    margin-top: 1rem;
    width: 200px;
  }

  .errorMessage__user {
    position: absolute;
    left: 50%;
    transform: translate(-50%,-50%);
    margin-top: 1rem;
    width: 350px;
    font-size: 13px;
  }
`
export const EstoqueFornecedorHeader = styled(Header)`
   .button {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

`
export const EstoqueFornecedorContent = styled.section`
  font-size: 13px;
  width: 95%;
  margin: ${prop => prop.espacamento} auto;
  margin-bottom: .5rem;
  display: flex;
  justify-content: space-between;

  @media(max-width: 1280px) {
    font-size: 11px;
  }

    .accordion {
      width: 90%;
      display: flex;
      justify-content: space-between;
      max-height: 350px;
      margin-bottom: 1rem;
      overflow: auto;

      @media (max-width: 1680px) {
        flex-direction: column;
      }
    }

    .MuiAccordionSummary-content {
      justify-content: space-between;
      align-items: center;
    }

    .info-separator {
      display:flex;
      width: 50%;
      justify-content: space-between;
      align-items: center;

      @media(max-width: 1280px) {
        width: 60%;
      }
    }

    .MuiButton-root {
      width: 36px;
      height: 36px;
      min-width: 36px;
      background-color: white ;
      color: #var(--color-brand-300);
      border: 1px solid;
      border-radius: 100%;
      border-color : var(--color-action-hover);
    }

    .MuiButton-startIcon {
      margin: 0;
      margin-right: -2px;
    }

    .messages {
      font-size: 16px;
      font-weight: bold;
      text-align: center;
    }
`
export const FlexContainerColumn = styled.div`
  width: 49%;
  display:flex;
  flex-direction: column;
`

export const EstoqueFornecedorFirstAccordion = styled(FlexContainerColumn)``
export const EstoqueFornecedorSecondAccordion = styled(FlexContainerColumn)``
export const FornecedorMateriaisFirstAccordion = styled(FlexContainerColumn)`
  width: 100%;
`


export const InputContainer = styled.div`
  display:flex;
  width: 100%;
  margin: 5px 0;
  justify-content: space-around;
  align-items: center;

  p {
    width: 70%;
  }

  input {
    width: 100%;
  }
`
export const EstoqueFornecedorActionsWrapper = styled.div`
  width: 95%;
  margin: ${prop => prop.espacamento} auto;
  display: flex;
  justify-content: ${prop => prop.alinhamento};
  align-items: center;

  .button-wrapper {
    display: flex;
    width: 49%;
    justify-content: flex-end;
  }
`
export const EstoqueFornecedorTextarea = styled(Textarea)``
export const EstoqueFornecedorSaveImage = styled.img`
  width: 16px;
  heigth: 16px;
`

export const EstoqueFornecedorIconWrapper = styled.div``
export const AccordionHeaderInfoWrapper = styled.div`
  display:flex;
  flex-direction: column;
  font-size: 12px;
  width: 50%;

  h5 {
    margin-top: 0;
    margin-bottom: 5px;
  }
`
