import intl from 'react-intl-universal'
import { blockChangeType } from "../../../../../../../../models/primaryMaterialSchedule/blockChangeType";
export const initalRadioValues = () => [
  {
    id: 0,
    name: 'Options0',
    optionOne: {
      name: intl.get('primaryMaterialScheduleList.modal.editRange.radio.set'),
      value: blockChangeType.Set,
      isChecked: true,
    },
    optionTwo: {
      name: intl.get('primaryMaterialScheduleList.modal.editRange.radio.pushBlockFurtherOut'),
      value: blockChangeType.PushBlockFurtherOut,
      isChecked: false,
    }
  },
  {
    id: 1,
    name: 'Options1',
    optionOne: {
      name: intl.get('primaryMaterialScheduleList.modal.editRange.radio.pullBlockCloserIn'),
      value: blockChangeType.PushBlockCloserIn,
      isChecked: false,
    },
    optionTwo: {
      name: intl.get('primaryMaterialScheduleList.modal.editRange.radio.timeIntervalOfBlock'),
      value: blockChangeType.TimeIntervalOfBlocks,
      isChecked: false,
    }
  }
]

export const verifyReturnItemsSap = (data, res) => (data.ScheduleLineCounter === res.ScheduleLineCounter
  && data.ScheduleAgreement === res.ScheduleAgreement
  && data.MaterialNumber === res.MaterialNumber
)

export const normalizeHandleChangeRadio = (value, name, radioValues) => {
  const _value = parseInt(value)
  const radioChangeIndex = radioValues.findIndex(x => x.name === name)
  const _radioValues = radioValues.map((radio) => ({
    ...radio,
    optionOne: {
      ...radio.optionOne,
      isChecked: false
    },
    optionTwo: {
      ...radio.optionTwo,
      isChecked: false
    }
  }
  ))

  _radioValues[radioChangeIndex].optionOne = {
    ..._radioValues[radioChangeIndex].optionOne,
    isChecked: _radioValues[radioChangeIndex].optionOne.value === _value
  }
  _radioValues[radioChangeIndex].optionTwo = {
    ..._radioValues[radioChangeIndex].optionTwo,
    isChecked: _radioValues[radioChangeIndex].optionTwo.value === _value
  }

  return _radioValues;
}
