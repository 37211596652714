import PropTypes from "prop-types"
import Feature from 'components/feature'
import InputNumber from 'components/input-number'
import { TextSpan } from 'components/text-label'
import intl from 'react-intl-universal'
import { formatNumber, formatValueByCurrency, formatWithoutLeadingZeros } from 'utils/format'
import { isScheduleAgreementFlow, usesViewPriceColumnsOnLoadCompositionView } from 'utils/validations-general-settings'
import { Wrapper, WrapperRow } from './styled'
import { ClbButtonIcon, ClbIcon } from '@celebration/design-system-react'
import { OrderListEditableHeader } from './header'
import { SelectBox } from 'components/uikit-adapter/index'
import { useEffect, useState } from 'react'
import { fetchMateriaisByUnitAndSupplier } from 'services/material.service'
import { getHeaders } from '../utils'

import * as S from './styled'
import { getValueForCondition, getValueOrDefault } from 'utils/custom-functions'

const OrderListEditable = ({
  orders,
  handleChange,
  handleRefreshOrderValue,
  addNewOrder,
  removeNewOrder,
  loadComposition
}) => {
  const [materials, setMaterials] = useState([]);

  const getMaterials = async () => {
    const _materials = await fetchMateriaisByUnitAndSupplier(loadComposition.IdUnidadeNegocio, loadComposition.IdFornecedor);
    setMaterials([..._materials]);
  }

  useEffect(() => {
    getMaterials();
  }, [])

  const getIdCard = (order) => order.IsNew ? order.IdCard : order.IdPedido

  const handleDelete = (order) => {
    if (order.IsNew || order.IdSugestao) {
      removeNewOrder(order.IdCard)
    } else {
      handleChange("IsDeleted", true, getIdCard(order))
    }
  }

  return (
    <Wrapper>
      <S.TableWrapper>
        <S.Table>
          <S.TableRow>
            <OrderListEditableHeader data={getHeaders()} />
          </S.TableRow>
          {orders.map(order => (
            <WrapperRow isDeleted={order.IsDeleted} key={order.IdPedidoSap}>
              <S.TableData>
                <TextSpan>
                  {order.IdPedidoSap}
                </TextSpan>
              </S.TableData>
              <S.TableData>{order.IdItemPedidoSap}</S.TableData>
              <S.TableData>
                {order.IsNew ? " - " : order.IdPedido}
              </S.TableData>
              <S.TableData className='material-field'>
                <Feature validation={!order.IsNew}>
                  <TextSpan style={{ display: 'block', width: '250px' }}>
                    {`${formatWithoutLeadingZeros(order.NumeroMaterial)} - ${order.Material}`}
                  </TextSpan>
                </Feature>
                <Feature validation={!!order.IsNew}>
                  <div style={{ width: '250px' }}>
                    <SelectBox
                      placeholder={intl.get('commons.material')}
                      value={order.MaterialSelect}
                      options={materials}
                      onChange={value => handleChange("MaterialSelect", value, getIdCard(order))}
                      valueKey='Id'
                      labelKey='Descricao'
                      searchable
                      label={`material-select-${getIdCard(order)}`}
                      dontShowLabel
                    />
                  </div>
                </Feature>
              </S.TableData>
              <Feature flags={usesViewPriceColumnsOnLoadCompositionView()}>
                <S.TableData>{getValueForCondition(order.PrecoLiquido == null, "-", formatValueByCurrency(order.PrecoLiquido, order.Moeda))}</S.TableData>
                <S.TableData>{getValueForCondition(order.UnidadePreco == null, "-", formatNumber(order.UnidadePreco))}</S.TableData>
              </Feature>
              <Feature flags={isScheduleAgreementFlow()}>
                <S.TableData>{getValueOrDefault(order.SchedulingAgreement, '-')}</S.TableData>
              </Feature>
              <S.TableData>{order.Lote}</S.TableData>
              <S.TableData>
                {order.IsDeleted ? formatNumber(order.Quantidade) :
                  <InputNumber
                    type='default'
                    style={{ width: '100px' }}
                    value={order.Quantidade}
                    onChange={value => handleChange("Quantidade", value, getIdCard(order))}
                    allowNegativeValue={false}
                    allowDecimals
                  />
                }
              </S.TableData>
              <S.TableData>{order.UnidadeMedida}</S.TableData>
              <S.TableData>
                <Feature validation={!order.IsNew}>
                  <ClbButtonIcon
                    icon="Refresh"
                    size="sm"
                    onClbClick={() => handleRefreshOrderValue(getIdCard(order))}
                    data-testid={`btn-refresh-${getIdCard(order)}`}
                  />
                </Feature>
              </S.TableData>
              <S.TableData>
                {!order.IsDeleted ?
                  <ClbButtonIcon
                    icon="Delete"
                    size="sm"
                    onClbClick={() => handleDelete(order)}
                    data-testid={`btn-delete-${getIdCard(order)}`}
                  /> :
                  <ClbButtonIcon
                    icon="AddRounded"
                    size="sm"
                    onClbClick={() => handleChange("IsDeleted", false, getIdCard(order))}
                    data-testid={`btn-add-${getIdCard(order)}`}
                  />
                }
              </S.TableData>
            </WrapperRow>
          ))}
          <WrapperRow clickable onClick={addNewOrder}>
            <S.TableData>
              <S.IconAdd>
                <ClbIcon icon='Add' />
                <div style={{ marginBottom: '4px' }}>{intl.get("commons.addItem")}</div>
              </S.IconAdd>
            </S.TableData>
            <S.TableData />
            <S.TableData />
            <S.TableData />
            <S.TableData />
            <S.TableData />
            <S.TableData />
            <S.TableData />
            <S.TableData />
            <S.TableData />
            <S.TableData />
          </WrapperRow>
        </S.Table >
      </S.TableWrapper>
    </Wrapper >
  )
}

OrderListEditable.propTypes = {
  addNewOrder: PropTypes.any.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleRefreshOrderValue: PropTypes.func.isRequired,
  loadComposition: PropTypes.shape({
    IdFornecedor: PropTypes.any,
    IdUnidadeNegocio: PropTypes.any
  }).isRequired,
  orders: PropTypes.array,
  removeNewOrder: PropTypes.func.isRequired
}

OrderListEditable.defaultProps = {
  orders: [],
}

export { OrderListEditable };