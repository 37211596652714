import { formatErrorMessage } from 'utils/handle-error.js'
export const validValue = value => !value || value === 'NULL' ? '-' : value
export const validDefaultValue = value => !value || value === 'NULL' ? null : value


export const normalizaDadosDaLinhaProducaoCentro = data =>
  data ? data.ProductionLine.split(';').map((value, index) => ({
    key: index,
    productionLine: value
  })) : []

export const errorMessage = (e) => typeof e === 'string' ? e : formatErrorMessage(e);
export const formatNewDataId = data => data.map((valor) => ({ ...valor, Id: valor.Id + 1 }))

export const sortRecords = (data) => (
  data.filter(x => typeof (x.Line) !== 'function')
    .sort((a, b) => a.TruckLoadOrder.localeCompare(b.TruckLoadOrder))
    .sort((a, b) => a.DeliveryTime.localeCompare(b.DeliveryTime))
    .sort((a, b) => a.DeliveryDate.localeCompare(b.DeliveryDate))
    .sort((a, b) => a.Unit.localeCompare(b.Unit))
    .sort((a, b) => a.Line.localeCompare(b.Line))
)

export const sortTable = (data) => {
  const newData = [...sortRecords(data)]
  data.filter(x => typeof (x.Line) === 'function').forEach((x) => {
    newData.splice(x.Id, 0, x)
  });
  return newData;
}
