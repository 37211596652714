import styled from 'styled-components'

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    box-sizing: border-box;
  }

  .card-content {
    padding: 5px 10px;
    cursor: ${props => props.cursor ? props.cursor : 'pointer' };
  }

  &.Card {
    cursor: pointer;
    padding-bottom: 10px;
    background-color: #fcfcfc;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;

    & > div {
      flex: 0 0 auto;
      width: 100%;
    }

    .body-card-stock {
      &:not(:last-child) {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        position: relative;
        margin-top: 5px;
        margin-bottom: 10px;
        width: 100%;
        border-bottom: solid 1px var(--color-neutral-200);
        padding-bottom: 6px;
      }

      &.items-3 {
        .data-card {
          max-width: 33.33%;
        }
      }

      &.items-2 {
        .data-card {
          max-width: 50%;
        }
      }
    }

    .data-card {
      display: flex;
      flex-direction: column;
      padding: 0 5px;
      max-width: 100%;
      flex-shrink: 0;

      .text-card {
        font-size: 14px;
        font-weight: var(--font-weight-regular);
        line-height: 1.07;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .label-card {
        display: block;
        margin-bottom: 2px;
        font-size: 11px;
        font-weight: 600;
        color: #9aa4ad;
        font-weight: bold;
        text-transform: uppercase;
      }

      &.same-block{
        padding-top: 8px;
      }
    }
  }

  &.headerCardNoIconHeight {
    height: 27px;
  }
`

export default StyledCard
