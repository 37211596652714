import React from 'react'
import intl from 'react-intl-universal'
import { Button } from 'components/uikit-adapter/index'
import Dialog from "material-ui/Dialog"
import PropTypes from 'prop-types'

const Modal = ({ open, close, onClick }) => (
  <Dialog
    open={open}
    contentStyle={{ width: "600px" }}
  >
    <p classame="mensagem-modal-confirmacao">{intl.get('orderPlanning.messageModalConfirmation')}</p>
    <p className="mensagem-modal-mensagem">{intl.get('orderPlanning.messageModalMessage')}</p>
    <div className="botoes-modal">
      <Button
        width="150px"
        value={intl.get('geral.modalConfirm.no')}
        onClick={close}
        className="btn"
      />
      <Button
        width="150px"
        type="primary"
        value={intl.get('geral.modalConfirm.yes')}
        onClick={onClick}
        className="btn margin-botao"
      />
    </div>
  </Dialog>
)

Modal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  onClick: PropTypes.func
}

export default Modal

