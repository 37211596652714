import styled from "styled-components"

export const FilterWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;

  .Select-value-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 140px;
  }
`

export const FirstSectionWrapper = styled.section`
  display:flex;
  align-items: center;

  > * {
    margin: 0px 8px 0px 12px;
  }

  .date {
    width: 180px;
  }

  .date-separator-text {
    height: 95%;
  }

  #row-deadline-control {
    position: relative;
    top: -5px;
    min-width: 150px;
    max-width: 400px;

    .columnsSize {
      flex-basis: calc(50% - 30px) !important;
      max-width: calc(50% - 30px) !important;
    }
  }
`
export const SecondSectionWrapper = styled.section`
  display: flex;
  align-items: flex-start;

  > * {
    margin: 0px 8px 0px 12px;
  }
`

export const ButtonSection = styled.section`
  display: flex;
  align-items: center;
  margin-left: 8px;
  margin-top: 29px;

  .button {
    margin-top: 0px;
  }

  .search {
    margin-left: 5px;
  }
`
