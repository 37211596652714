import styled from "styled-components";

export const Wrapper = styled.div`
  position: absolute;
  bottom: 32px;
  width: calc(100% - 64px);
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  align-items: center;

  .text-lines-per-page{
    align-self: center;
    margin-left: 8px;
    color: rgb(158,158,158);
    font-size: 13px;
    text-align: right;
  }

` 