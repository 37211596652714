import styled from "styled-components";

export const Wrapper = styled.div`
  @media (max-width: 1024px) {
    padding-left: 6%;
  }

  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .menu__button {
    height: 45px !important;
    min-width: 15px !important;
  }
`
