export const formatCompositionData = (data) => ({
  BreweryNote: data?.BreweryNote,
  Centro: data?.Center,
  DataColeta: data?.CollectDate,
  DataCriacaoGrn: data?.CreationDateGrn,
  DataEntrega: data?.DeliveryDate,
  DataEntregaSugerida: data?.SuggestedDeliveryDate,
  Door: data?.Door,
  Fornecedor: data?.Supplier,
  HoraEntrega: data?.DeliveryTime,
  HoraEntregaSugerida: data?.SuggestedDeliveryTime,
  IdComposicao: data?.LoadCompositionId,
  IdFornecedor: data?.SupplierId,
  IdUnidadeNegocio: data?.BusinessUnitId,
  Observacao: data?.Observation,
  ObservacaoSugerida: data?.SuggestedObservation,
  Pedidos: data?.Orders,
  QuantidadeTotal: data?.TotalAmount,
  SituacaoValor: data?.ValueSituation,
  TransitTime: data?.TransitTime,
  UnidadeNegocio: data?.BusinessUnit
});

export const formatCompositionByOrderData = (data) => ({
  BreweryNote: data?.BreweryNote,
  Centro: data?.Center,
  CnpjTransportadora: data?.CnpjShippingCompany,
  CodigoPoc: data?.CodePoc,
  CodigoTransportadoraSap: data?.CodeShippingCompany,
  CodigoTransporte: data?.TransportCode,
  DataColeta: data?.CollectDate,
  DataEntrega: data?.DeliveryDate,
  Fornecedor: data?.Supplier,
  HoraEntrega: data?.DeliveryTime,
  IdComposicao: data?.LoadCompositionId,
  IdFornecedor: data?.SupplierId,
  IdUnidadeNegocio: data?.BusinessUnitId,
  IdUsuario: data?.UserId,
  IdUsuarioAtualizacao: data?.UserIdUpdate,
  IdsFamiliaRotuladaCentro: data?.LabeledFamilyCenterIds,
  ModoLeitura: data?.ReadOnly,
  Observacao: data?.Observation,
  Pedidos: data?.OrdersIds,
  PedidosSAP: data?.OrdersSap,
  Placa: data?.Plate,
  QuantidadePedidos: data?.OrdersQuantity,
  QuantidadeTotal: data?.TotalAmount,
  ResponsavelProximaAcao: data?.IsResponsibleNextAction,
  Situacao: data?.Situation,
  SituacaoValor: data?.SituationValue,
  TarifaTrecho: data?.ExcerptFare,
  Transportadora: data?.ShippingCompany,
  UnidadeNegocio: data?.BusinessUnit
});
