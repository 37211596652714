import styled from 'styled-components'

export const Input = styled.div`
  width: 261px;
  background-color: var(--color-contrast-white);
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--color-neutral-300);
  border-top: 2px solid var(--color-action-hover);
  cursor: pointer;

  :hover {
    border-top-color: var(--color-action-default);
  }
`
export const Select = styled.div``
export const Option = styled.div`
  position: absolute;
  margin-top: 5px;
  z-index: 30;
  display: flex;
  flex-direction: column;
  background-color: var(--color-contrast-white);
  width: 280px;
  border: 1px solid #C7D0DF;

  span {
    padding: 10px;

    :hover {
      background-color: #EFF5FE;
    }
  }

`
export const ArrowDownIcon = styled.img``
export const Carret = styled.div``
export const Period = styled.span``

