import React, { Component } from 'react'
import Card from './card'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import StatusStock from 'components/status-stock'
import "./index.css"
import intl from 'react-intl-universal'
import moment from 'moment';
import { statusStock } from '../../utils'
import { formatNumber } from 'utils/format'

class Cards extends Component {
  getNextDate = (stock) => {
    const date = stock.ProximaDataEntregaPedido ? moment(stock.ProximaDataEntregaPedido, "DD/MM").format("[DM]") : ""
    if (date) {
      return (`${date}`)
    } else {
      return ("-")
    }
  }

  render() {
    const { dataCard } = this.props

    return (
      <Link
        to={`stocksDetail/${dataCard.Id}`}
        style={{ textDecoration: 'none', textDecorationColor: 'red' }}
      >
        <Card
          type={'Stocks'}
          title={`${statusStock(dataCard.StatusEstoque)}`}
          subtitle={`${dataCard.Fabrica.Codigo} - ${dataCard.LabeledFamily}`}
          status={dataCard.StatusEstoque}
          programado={dataCard.Programado}
        >
          <div className="body-card-stock">
            <div className="data-card">
              <span className="label-card">{intl.get('requestOrders.dateOut')}</span>
              <span className="text-card">{!!dataCard.DataOut ? moment(dataCard.DataOut, "DD/MM").format("[DM]") : '-'}</span>
            </div>
            <div className="data-card">
              <span className="label-card">{intl.get('commons.sufficiency')}</span>
              <span className="text-card">{!!dataCard.Suficiencia ? moment(dataCard.Suficiencia, "DD/MM").format("[DM]") : '-'}</span>
            </div>
            <div className="data-card">
              <span className="label-card">{intl.get('stocks.stockDetail.nextDelivery')}</span>
              <span className="text-card">{this.getNextDate(dataCard)}</span>
            </div>
          </div>
          <div className='body-card-stock'>
            <div className="data-card data-card-estoque">
              <span className="label-card">{intl.get('commons.stock')}</span>
              <StatusStock
                priority={dataCard.StatusEstoque}
                label={`${formatNumber(!!dataCard.EstoqueValor ? dataCard.EstoqueValor : 0)} ${dataCard.EstoqueUnidadeMedida}`}
              />
            </div>
          </div>
          <div className="body-card-stock">
            <div className="data-card">
              <span className="label-card">{intl.get('commons.activeMaterial')}</span>
              <span title={dataCard.MaterialAtivo} className="text-card">{dataCard.MaterialAtivo}</span>
            </div>
          </div>
        </Card>
      </Link>
    )
  }
}

Cards.propTypes = {
  dataCard: PropTypes.object.isRequired
}

export default Cards
