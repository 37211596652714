export const blockChangeType = {
  Set: 1,
  PushBlockFurtherOut: 2,
  PushBlockCloserIn: 3,
  TimeIntervalOfBlocks: 4
}

export const typesForOffsetDeliveryDate = [
  blockChangeType.PushBlockCloserIn,
  blockChangeType.PushBlockFurtherOut,
]

export const typesForOffsetDeliveryTime = [
  blockChangeType.PushBlockCloserIn,
  blockChangeType.PushBlockFurtherOut,
  blockChangeType.TimeIntervalOfBlocks,
]


export const typesForComments = [
  blockChangeType.Set
]

export const typesForDoor = [
  blockChangeType.Set
]

export const typesForDeliveryDate = [
  blockChangeType.Set
]

export const typesForDeliveryTime = [
  blockChangeType.Set
]

export const typesForPallets = [
  blockChangeType.Set
]

