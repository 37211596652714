const ReleaseVersionIcon = ({ width, height, color }) => (
    <svg xmlns="https://www.w3.org/2000/svg"
        xmlnsXlink="https://www.w3.org/1999/xlink"
        version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512"
        style={{ enableBackground: "new 0 0 512 512" }}
        xmlSpace="preserve" className=""
        width={width ? width : "24px"}
        height={height ? height : "24px"}>
        <path d="m508.21 87.043c-2.805-44.32-38.337-79.651-82.653-82.182-56.476-3.225-113.524-4.861-169.557-4.861s-113.081 1.636-169.558 4.861c-44.315 2.532-79.848 37.862-82.652 82.182-5.042 79.673-5.036 160.558.019 240.406 2.805 44.322 38.338 79.655 82.653 82.188 27.881 1.594 55.893 2.796 83.849 3.609v38.304h-24.9c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h261.84c4.064 0 7.89 1.584 10.761 4.448 2.875 2.882 4.459 6.708 4.459 10.772 0 8.398-6.828 15.23-15.22 15.23h-302.49c-4.071 0-7.898-1.585-10.776-4.464-2.872-2.872-4.454-6.695-4.454-10.766 0-8.393 6.832-15.22 15.23-15.22h7.37c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-7.37c-16.669 0-30.23 13.557-30.23 30.22 0 8.078 3.142 15.669 8.847 21.373 5.711 5.711 13.305 8.857 21.383 8.857h302.49c16.664 0 30.22-13.561 30.22-30.23 0-8.064-3.14-15.653-8.853-21.379-5.714-5.702-13.303-8.841-21.367-8.841h-65.56v-38.304c27.955-.813 55.968-2.015 83.849-3.609 44.315-2.533 79.849-37.866 82.653-82.188 5.054-79.848 5.06-160.733.017-240.406zm-181.52 364.507h-141.38v-37.919c23.667.569 47.271.873 70.69.873s47.023-.303 70.69-.873zm166.532-125.048c-2.327 36.757-31.792 66.059-68.54 68.159-56.213 3.213-112.966 4.842-168.682 4.842s-112.469-1.629-168.683-4.842c-36.748-2.1-66.213-31.402-68.54-68.159-5.015-79.221-5.021-159.467-.019-238.512 2.326-36.755 31.791-66.055 68.539-68.154 56.194-3.209 112.954-4.836 168.703-4.836s112.509 1.627 168.702 4.837c36.748 2.099 66.212 31.399 68.539 68.154 5.002 79.044 4.996 159.291-.019 238.511z"
            data-original="#000000" className="active-path" data-old_color="#71aedf"
            fill={color ? color : "#71aedf"}/>
        <path d="m423.847 34.813c-55.909-3.194-112.381-4.813-167.847-4.813-55.467 0-111.939 1.619-167.847 4.813-29.18 1.667-52.577 24.936-54.424 54.125-4.963 78.415-4.957 158.023.019 236.616 1.848 29.192 25.246 52.463 54.425 54.131 55.926 3.197 112.392 4.818 167.827 4.818s111.9-1.621 167.827-4.818c29.181-1.668 52.578-24.939 54.425-54.132 4.976-78.596 4.981-158.205.019-236.616-1.848-29.188-25.245-52.457-54.424-54.124zm39.435 289.794c-1.368 21.627-18.698 38.868-40.312 40.104-55.642 3.181-111.819 4.793-166.97 4.793s-111.328-1.613-166.97-4.793c-21.613-1.235-38.943-18.476-40.312-40.103-4.935-77.964-4.941-156.936-.019-234.722 1.369-21.625 18.698-38.863 40.31-40.097 55.624-3.178 111.808-4.789 166.991-4.789 55.182 0 111.366 1.611 166.991 4.789 21.612 1.234 38.941 18.473 40.309 40.097 4.923 77.782 4.917 156.753-.018 234.721z"
            data-original="#000000" className="active-path" data-old_color="#71aedf"
            fill={color ? color : "#71aedf"}/>
        <path d="m276.682 294.384c-.893-5.971-6.308-10.138-12.32-9.483-23.595 2.56-46.771-5.615-63.583-22.427-22.318-22.318-28.77-56.19-16.979-84.968l11.427 8.393c2.879 2.113 6.7 2.534 9.969 1.097s5.543-4.533 5.935-8.082l11.249-101.95c.392-3.55-1.153-7.069-4.032-9.183s-6.698-2.536-9.969-1.1l-93.915 41.233c-3.27 1.436-5.544 4.532-5.937 8.082-.392 3.55 1.152 7.069 4.031 9.185l11.211 8.234c-15.185 27.261-21.65 59.08-18.244 90.367 3.716 34.131 19.125 66.291 43.389 90.555 17.015 17.015 38.059 29.862 60.858 37.152 14.913 4.769 30.496 7.187 46.316 7.187 6.14 0 12.326-.375 18.386-1.114 2.986-.365 5.744-1.947 7.566-4.342 1.823-2.396 2.613-5.476 2.168-8.453zm-20.592 49.291c-14.268 0-28.313-2.178-41.748-6.474-20.532-6.565-39.488-18.14-54.82-33.471-21.856-21.856-35.736-50.826-39.083-81.572-3.298-30.295 3.663-61.131 19.602-86.826 2.063-3.327 1.221-7.681-1.934-9.999l-9.729-7.146 78.148-34.311-9.36 84.833-11.782-8.654c-1.743-1.279-3.953-1.745-6.063-1.277s-3.917 1.824-4.955 3.72c-19.747 36.055-13.247 81.528 15.808 110.582 19.185 19.185 45.323 28.929 72.202 27.054l6.418 42.952c-4.212.392-8.469.589-12.704.589z"
            data-original="#000000" className="active-path" data-old_color="#71aedf"
            fill={color ? color : "#71aedf"}/>
        <path d="m374.726 113.229c-2.574-3.245-7.291-3.789-10.537-1.216-3.245 2.574-3.79 7.292-1.216 10.537 35.622 44.915 39.232 107.858 8.983 156.626-2.063 3.327-1.221 7.681 1.935 9.999l9.726 7.142-78.14 34.314 9.354-84.841 11.791 8.656c1.743 1.279 3.952 1.744 6.062 1.276s3.916-1.823 4.954-3.719c19.748-36.046 13.245-81.517-15.814-110.577-19.187-19.187-45.329-28.942-72.193-27.055l-6.42-42.953c4.202-.391 8.458-.588 12.7-.588 14.254 0 28.3 2.178 41.745 6.474 15.6 4.991 30.08 12.724 43.04 22.986 3.247 2.571 7.964 2.023 10.536-1.224 2.571-3.247 2.023-7.964-1.224-10.536-14.38-11.387-30.456-19.971-47.784-25.514-14.925-4.769-30.506-7.186-46.312-7.186-6.156 0-12.344.375-18.379 1.114-2.985.36-5.743 1.94-7.567 4.335-1.826 2.397-2.618 5.481-2.171 8.459l7.527 50.356c.873 5.97 6.281 10.149 12.319 9.512 23.591-2.559 46.768 5.616 63.579 22.427 22.324 22.325 28.78 56.194 16.986 84.965l-11.422-8.385c-2.867-2.113-6.678-2.541-9.948-1.116-3.274 1.427-5.56 4.517-5.967 8.092l-11.237 101.926c-.403 3.55 1.13 7.073 4.002 9.195 1.749 1.292 3.847 1.958 5.966 1.958 1.365 0 2.738-.276 4.03-.839l93.906-41.238c3.273-1.435 5.549-4.534 5.94-8.087.391-3.552-1.157-7.07-4.032-9.177l-11.209-8.23c29.703-53.217 24.59-119.829-13.509-167.868z"
            data-original="#000000" className="active-path" data-old_color="#71aedf"
            fill={color ? color : "#71aedf"}/>
    </svg>
)
export { ReleaseVersionIcon };
