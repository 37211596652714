import { createContext, useContext, useState } from "react";
import { initialFilterValues } from "./utils";
import { notificationsMarkAsRead } from "services/notifications.service";
import { INotificationFilter, NotificationContextType, NotificationProviderProps } from "./interface";

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

const NotificationProvider = ({ children }: NotificationProviderProps) => {
  const [filter, setFilter] = useState<INotificationFilter>(initialFilterValues);
  const [quantityRegisters, setQuantityRegisters] = useState<number>(0);
  const [notificationSelected, setNotificationSelected] = useState<Array<number>>([]);

  const handleNotificationsMarkAsRead = async (data: Array<number>) =>
    await notificationsMarkAsRead({ NotificationIds: data });

  return (
    <NotificationContext.Provider
      value={{
        filter,
        setFilter,
        quantityRegisters,
        setQuantityRegisters,
        notificationSelected,
        setNotificationSelected,
        handleNotificationsMarkAsRead
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = () => {
  const _context = useContext(NotificationContext);
  if (_context === undefined) {
    throw new Error("useNotificationContext can only be used in a NotificationProvider tree");
  }
  return _context;
}

export { NotificationProvider, NotificationContext };