import styled from 'styled-components'

const StyledTabs = styled.li`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  border: 1px solid var(--color-neutral-300);
  border-right: none;
  cursor: pointer;
  background: var(--color-contrast-white);
  
  &:first-child {
    border-radius: 3px 0 0 3px;
  }
  
  &:last-child {
    border-radius: 0 3px 3px 0 ;
  }
  
  font-size: 0.7rem;
  font-weight: bold;
  line-height: 1.5rem;
  
  &:last-child:not(.active) {
    border-right: 1px solid var(--color-neutral-300);
  }
  
  &.active + li {
    border-left: none;
  }
  
  &.active {
    background: #f4f5f8;
    color: var(--color-action-default);
    border: 1px solid var(--color-neutral-300);
    
    -webkit-box-shadow: inset 0 1px 3px 0px rgba(149, 163, 183, 0.8);
    -moz-box-shadow: inset 0 1px 3px 0px rgba(149, 163, 183, 0.8);
    box-shadow: inset 0 1px 3px 0px rgba(149, 163, 183, 0.8);
    
    &:first-child {
      -webkit-box-shadow: inset 1px 2px 3px -1px rgba(149, 163, 183, 0.8);
      -moz-box-shadow: inset 1px 2px 3px -1px rgba(149, 163, 183, 0.8);
      box-shadow: inset 1px 2px 3px -1px rgba(149, 163, 183, 0.8);
    }

    &:last-child {
      -webkit-box-shadow: inset -1px 2px 3px -1px rgba(149, 163, 183, 0.8);
      -moz-box-shadow: inset -1px 2px 3px -1px rgba(149, 163, 183, 0.8);
      box-shadow: inset -1px 2px 3px -1px rgba(149, 163, 183, 0.8);
    }
  }
`

export default StyledTabs
