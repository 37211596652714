import styled from "styled-components";

export const ModalTitle = styled.p`
  font-weight: var(--font-weight-semibold);
  font-size: 18px;
  line-height: 19px;
  color: var(--color-action-default);
  margin: 8px 0px 12px 0px;
`
export const ModalText = styled.p`
  font-size: 14px;
  font-weight: var(--font-weight-semibold);
  color: var(--color-contrast-brand);
  margin: 12px 0px 2px 0px;
  padding: 4px 0px 4px 0px;

  ${p => p.isLast && 'margin: 12px 0px 24px 0px;'}
`

export const ModalTextAttention = styled.p`
  font-size: 12px;
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  line-height: normal;
  text-transform: uppercase;
  color: var(--color-feedback-danger-400);
  margin: 6px 0px 32px 0px;
`