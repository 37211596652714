import { createContext, useContext, useMemo, useState } from "react";
import StorePersist from 'utils/store-persist'
import { mapperProductionPlan, mountQuery, supplierProductionPlanFiltersDefault } from "./utils";
import Loading from "components/center-loading";
import { IProductionPlan, ISupplierProductionPlanFilters, SupplierProductionPlanContextProps, SupplierProductionPlanProviderProps } from "./interface";
import { getSupplierProductionPlan } from "services/supplier-production-plan.service";

const SupplierProductionPlanContext = createContext<SupplierProductionPlanContextProps | undefined>(undefined);

const SupplierProductionPlanProvider: React.FC<SupplierProductionPlanProviderProps> = ({ children }) => {
  const [filters, setFilters] = useState<ISupplierProductionPlanFilters>({
    ...(StorePersist.getValuesJSON("filtersSupplierProductionPlan") ?? supplierProductionPlanFiltersDefault)
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [productionPlanData, setProductionPlanData] = useState<IProductionPlan[] | null>(null);

  const searchProductionPlanData = async (filters: ISupplierProductionPlanFilters) => {
    StorePersist.setValuesJSON("filtersSupplierProductionPlan", filters);
    const _query = mountQuery(filters);
    const _result = await getSupplierProductionPlan(_query);
    setProductionPlanData(mapperProductionPlan(_result));    
  }

  const values = useMemo(() => ({
    filters,
    setFilters,
    loading,
    setLoading,
    productionPlanData,
    setProductionPlanData,
    searchProductionPlanData
  }), [filters, loading, productionPlanData]);

  return (
    <SupplierProductionPlanContext.Provider value={values}>
      {children}
      <Loading isLoading={loading} />
    </SupplierProductionPlanContext.Provider>
  );
};

const useSupplierProductionPlanContext = () => {
  const context = useContext(SupplierProductionPlanContext);
  if (!context) {
    throw new Error("useSupplierProductionPlanContext must be used within a SupplierProductionPlanProvider");
  }
  return context;
};

export {
  SupplierProductionPlanProvider,
  SupplierProductionPlanContext,
  useSupplierProductionPlanContext,
};
