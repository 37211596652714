import Fetch from "utils/fetch"
import { convertToQueryParameters } from "utils/custom-functions"

export const fetchVolums = (filters) => {
  const {
    date,
    plant,
    productionVersion,
    recalculate,
    shift
  } = filters

  const filtersToQuery = {
    plant: plant.Centro,
    productionVersion: productionVersion.productionLine,
    deliveryDateFrom: date.deliveryDateFrom,
    deliveryDateTo: date.deliveryDateTo,
    shift,
    recalculate
  }

  return Fetch.get(`/zsag/autogenerateschedules?${convertToQueryParameters(filtersToQuery)}`)
    .then(({ data }) => formataSchedulesComId(data))
}

export const formataSchedulesComId = (data) => {
  const Schedules = data.Schedules.map((schedule, index) => retornaObjetoComId(schedule, index))
  return {
    ...data,
    Schedules
  }
}

export const retornaObjetoComId = (schedule, index) => {
  return { ...schedule, Id: index }
}

export const autoGenerateSchedulesInsert = (requestDto) => {
  return Fetch.post(`/zsag/insertnewschedule`, requestDto).then(({ data }) => data)
}

export const autoGenerateSchedulesUpdate = (requestDto) => {
  return {
    ...requestDto,
    Quantity: requestDto.QuantityPerPallet * requestDto.QuantityPallets
  }
}

export const generateZSAG = (filters, schedules) => {
  const autoGenerateSchedulesFilterDto = {
    Plant: filters.plant.Centro,
    ProductionVersion: filters.productionVersion.productionLine,
    DeliveryDateFrom: filters.date.deliveryDateFrom,
    DeliveryDateTo: filters.date.deliveryDateTo,
    Shift: filters.shift,
    Recalculate: false
  }
  const generateDto = {
    AutoGenerateSchedulesFilterDto: autoGenerateSchedulesFilterDto,
    Lines: schedules.map(schedule => ({
      Material: schedule.MaterialNumber,
      DeliveryDate: schedule.OldDeliveryDate || schedule.DeliveryDate,
      NewDeliveryDate: schedule.DeliveryDate,
      QuantityPallets: schedule.QuantityPallets,
      NewShift: schedule.Shift,
      Process: schedule.Process,
      Interval: schedule.Interval

    }))
  }
  return Fetch.post(`/zsag/generatesag`, generateDto).then(({ data }) => formataSchedulesComId(data))
}
