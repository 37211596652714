const IconAddSmall = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width={ "32px" }
      height={ "24px" }
      style={{
        enableBackground: "new 0 0 31.059 31.059",
      }}
      viewBox="0 0 31.059 31.059"
    >
      
      <path
        fill="var(--color-action-default)"
        d="M15.529 31.059C6.966 31.059 0 24.092 0 15.529 0 6.966 6.966 0 15.529 0c8.563 0 15.529 6.966 15.529 15.529.001 8.563-6.966 15.53-15.529 15.53zm0-29.285c-7.585 0-13.755 6.171-13.755 13.755s6.17 13.754 13.755 13.754c7.584 0 13.754-6.17 13.754-13.754S23.113 1.774 15.529 1.774z"
        data-old_color="#010002"
        data-original="#010002"
      />
      <path
        fill="var(--color-action-default)"
        d="M21.652 16.416H9.406a.887.887 0 1 1 0-1.775h12.246a.888.888 0 0 1 0 1.775z"
        data-old_color="var(--color-action-default)"
        data-original="var(--color-action-default)"
      />
      <path
        fill="var(--color-action-default)"
        d="M15.529 22.539a.888.888 0 0 1-.888-.887V9.406a.889.889 0 0 1 1.775 0v12.246a.886.886 0 0 1-.887.887z"
        data-old_color="#010002"
        data-original="#010002"
      />
    </svg>
  )
  export default IconAddSmall