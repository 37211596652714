import styled from 'styled-components'

export const customStyle = (menuListStyle) => ({

  menuList: (base) => ({
    ...base,
    "::-webkit-scrollbar": {
      width: '4px',
      backgroundColor: '#E0E0E0'
    },
    "::-webkit-scrollbar-thumb": {
      borderRadius: '2px',
      boxShadow: 'inset 0 0 6px rgba(0, 0, 0, .3)',
      backgroundColor: '#BDBDBD'
    },
    ...menuListStyle
  }),
  control: (base, state) => ({
    ...base,
    borderRadius: 0,
    fontSize: "15px",
    color: '#AAA',
    boxShadow: 'none',
    borderColor: state.isFocused ? 'var(--color-neutral-300)' : '#c5d0e2',
    borderTop: '2px solid var(--color-action-hover)',
    '&:hover': {
      borderTop: '2px solid var(--color-action-default)'
    }
  }),
  option: (styles, { isDisabled, isSelected })  => ({
    ...styles,
    height: '37px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '20px',
    color: isDisabled ? "gray" : "#333",
    fontSize: "13px",
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    backgroundColor: isSelected ? "var(--color-action-hover)" : 'white',
    '&:hover': {
      backgroundColor: 'var(--color-neutral-300)'
    }
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: '#ebf5ff',
    borderRadius: '2px',
    border: '1px solid #c2e0ff',
    color: '#007eff',
    marginLeft: '5px',
    marginTop: '5px',
    marginBottom: '5px'
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: '#007eff',
    fontSize: '0.9em',
    lineHeight: 1.4
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    ':hover': {
      backgroundColor: '#d8eafd',
      color: '#0071e6'
    },
  }),
})

export const OntimeSelectWrapper = styled.div`

  width: ${props => props.width ? props.width : "100%"};
  ${p => (p.dontShowLabel && p.isPrimaryMaterial) && 'margin-bottom: 25px;'}

  label {
    line-height: 1.8rem;
    font-size: 12px;
    font-weight: var(--font-weight-semibold);
    text-transform: uppercase;
    color: var(--color-contrast-brand);
  }

  .css-1flyd4j-control{
    background: var(--color-neutral-200);
  }
  .css-107lb6w-singleValue{
    color: var(--color-contrast-brand);
  }
`

export const OntimeSelectLabel = styled.label`
  ${p => p.dontShowLabel && `top: -999px; position: fixed;`}
`
