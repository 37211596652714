import React from 'react';
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    position: 'absolute',
    bottom: 0,
    left: 0,
    alignItems: 'center',
    justifyContent: 'center',
    height: 'calc(100vh - 100px)',
    color: 'var(--color-brand-300)',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}));

export default function LoadingProgress({ ...rest }) {
  const classes = useStyles();

  return (
    <div className={classes.root} {...rest}>
      <CircularProgress className='loading-tab' color='inherit' />
    </div>
  )
}