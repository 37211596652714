import React from 'react'

import StyledTitle from './styledTitleHeader'

interface ITitleHeader {
  children?: React.ReactNode,
  className?: string,
  color?: string,
  width?: number,
  noBackground?: boolean
}
const TitleHeader = ({ children, className, color, width, noBackground }: ITitleHeader) => {
  return (
    <StyledTitle className={className} color={color} width={width} noBackground={noBackground}>
      {children}
    </StyledTitle>
  )
}

export default TitleHeader
