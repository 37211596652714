import PropTypes from "prop-types"
import React from 'react'
import * as S from '../../styled'
import intl from 'react-intl-universal'
import { useLoadCompositionBuildingContext } from '../../../../context/load-composition-building-context'
import { getValidDate } from 'pages/load-composition/components/filter/utils'
import { formatWithoutSpaces } from 'utils/format'

const ButtonEditDateTimeDoor = ({ disabled, oldParams, queryParams }) => {
  const {
    setIsEditDateMode,
    isEditDateMode,
    handleReloadDataCards,
    isFullTruckLoad,
    params,
    replaceRoute
  } = useLoadCompositionBuildingContext();

  const handleSaveEditDate = () => {
    setIsEditDateMode(false);
    handleReloadDataCards({})
  }

  const handleCancelEditDate = () => {
    if (oldParams.dia !== params.dia || oldParams.hora !== params.hora || oldParams.door !== queryParams.get('doorDescription')) {
      const filtersRoute = `${oldParams.destinoId}/${formatWithoutSpaces(oldParams.destinoName)}/date/${oldParams.dia}/${oldParams.hora}`;
      const editCompositionInfosRoute = oldParams.composicaoId ? `/${oldParams.composicaoId}/${oldParams.fornecedorId}` : '';
      const queryParams = !!oldParams.door ? `?doorDescription=${oldParams.door}` : '';
      replaceRoute(`/loadCompositionbuilding/destino/${filtersRoute}${editCompositionInfosRoute}${queryParams}`);
    }
    setIsEditDateMode(false);
  }

  if (isFullTruckLoad) {
    return null
  }

  return (
    <React.Fragment>
      {isEditDateMode &&
        <S.WrapperEditDateActionsButtons>
          <S.ButtonCancelEditDate
            onClick={handleCancelEditDate}
            type='default'
            value={intl.get('geral.buttonsDefault.cancel')}
            disabled={!getValidDate(oldParams.dia)}
          />
          <S.ButtonDoneEditDate
            onClick={handleSaveEditDate}
            type='primary'
            value={intl.get('geral.buttonsDefault.accept')}
            disabled={disabled}
          />
        </S.WrapperEditDateActionsButtons>
      }
    </React.Fragment>
  )
}

ButtonEditDateTimeDoor.propTypes = {
  disabled: PropTypes.any.isRequired,
  oldParams: PropTypes.shape({
    composicaoId: PropTypes.any,
    destinoId: PropTypes.any,
    destinoName: PropTypes.any,
    dia: PropTypes.any,
    door: PropTypes.func,
    fornecedorId: PropTypes.any,
    hora: PropTypes.any
  }).isRequired,
  queryParams: PropTypes.shape({
    get: PropTypes.func
  }).isRequired
}
export default ButtonEditDateTimeDoor;
