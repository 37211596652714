export const dataMapper = (response) => {
	const suppliers = [...new Set(response.map(supplier => supplier.SupplierName))]

	const suppliersFormat = suppliers.map((name) => {
		const formattedSuppliers = response.filter(({ SupplierName }) => SupplierName === name)
		const days = [...new Set(formattedSuppliers.map((formattedSupplier) => formattedSupplier.Date))]
		const format = []

		days.forEach((day) => {
			const data = formattedSuppliers.filter((formattedSupplier) => formattedSupplier.Date === day)
			const outgoing = data.reduce((acc, cur) => acc + cur.OutgoingMerchandiseSupplier, 0)
			format.push({
				Date: day,
				SupplierInitialStock: data[0].SupplierInitialStock,
				SupplierProduction: data[0].SupplierProduction,
				OutgoingMerchandiseSupplier: outgoing,
				SupplierFinalStock: data[0].SupplierFinalStock,
				SupplierId: data[0].SupplierId,
				SupplierName: data[0].SupplierName,
				outgoingData: data.map(outgoing => ({
					BusinessUnitId: outgoing.BusinessUnitId,
					BusinessUnitPlant: outgoing.BusinessUnitPlant,
					outgoing: outgoing.OutgoingMerchandiseSupplier
				}))
			})
		})
		return {
			Name: name,
			Data: format
		}
	})

	const factories = [...new Set(response.map(supplier => supplier.BusinessUnitPlant))]
	const factoriesFormat = factories.map((factorie) => {
		const formattedFactories = response.filter(({ BusinessUnitPlant }) => BusinessUnitPlant === factorie)
		const days = [...new Set(formattedFactories.map((formattedFactory) => formattedFactory.Date))]
		const format = []

		days.forEach((day) => {
			const data = formattedFactories.filter((formattedFactory) => formattedFactory.Date === day)
			const entry = data.reduce((acc, cur) => acc + cur.BreweryMerchandiseEntry, 0)

			format.push({
				Date: day,
				BreweryInitialStock: data[0].BreweryInitialStock,
				BreweryMerchandiseEntry: entry,
				BreweryFinalStock: data[0].BreweryFinalStock,
				BusinessUnitId: data[0].BusinessUnitId,
				BusinessUnitPlant: data[0].BusinessUnitPlant,
				RealProduction: data[0].RealProduction,
				WeeklyPlan: data[0].HectoliterPerThousand > 0 ? (data[0].WeeklyPlan * data[0].HectoliterPerThousand) : data[0].WeeklyPlan,
				HectoliterPerThousand: data[0].HectoliterPerThousand,
				Necessity: data[0].Necessity,
				entry: data.map(entry => ({
					SupplierName: entry.SupplierName,
					outgoing: entry.OutgoingMerchandiseSupplier,
					SupplierId: entry.SupplierId
				}))
			})
		})

		return {
			Name: factorie,
			Data: format
		}
	})

	return {
		suppliers: suppliersFormat,
		factories: factoriesFormat
	}
}
