import intl from 'react-intl-universal'
import moment from "moment"

export const getStockMaterial = (stockMaterial, discontinuedDate) => {
  if (!stockMaterial)
  {
      return intl.get("geral.modalConfirm.no");
  }
  else if (!discontinuedDate)
  {
      return intl.get("geral.modalConfirm.yes");
  }
  else
  {
      return intl.get("geral.modalConfirm.yes") + " - " + intl.get("master-data.general-configuration.until")  + " " +         
      moment(discontinuedDate).format("[DDMMYY]");        
  }
}