import PropTypes from "prop-types"
import React, { useState } from 'react'
import intl from 'react-intl-universal'
import OptionGroup from 'components/option-group'
import { Layout } from "@hbsis.uikit/react"
import { Button } from 'components/uikit-adapter'
import { Row, Col } from "react-flexbox-grid"
import { useBottlesContext } from '../../../context'
import { EditingModes } from '../../../models/editingModeModel'
import IconEmpty from "images/iconEmpty.icon"
import * as S from './styled'

export const FilterEditMode = ({ open, close }) => {
  const { setEditMode, editMode } = useBottlesContext()

  const handleFilter = () => {
    setEditMode(
      optionsRecurrencePattern.optionOne.isChecked ?
      optionsRecurrencePattern.optionOne.value :
      optionsRecurrencePattern.optionTwo.value
    )
    close()
  }

  const [optionsRecurrencePattern, setOptionsRecurrencePattern] = useState({
    id: 1,
    optionOne: {
      name: intl.get('bottles.filter.edit.normal'),
      value: EditingModes.Normal,
      isChecked: editMode === EditingModes.Normal
    },
    optionTwo: {
      name: intl.get('bottles.filter.edit.advanced'),
      value: EditingModes.Advanced,
      isChecked: editMode === EditingModes.Advanced
    }
  })

  const updateRecurrencePattern = (newValue) => {
    setOptionsRecurrencePattern(prevState => ({
      optionOne: {
        ...prevState.optionOne,
        isChecked: prevState.optionOne.value === parseInt(newValue)
      },
      optionTwo: {
        ...prevState.optionTwo,
        isChecked: prevState.optionTwo.value === parseInt(newValue)
      }
    }))
  }
  return (
    <Layout.Sidebar
      width="300px"
      visible={open}
      triggerClose={close}
      background="var(--color-contrast-white)"
      offsetTop="96px"
      side="right"
      block
      float
      icon={IconEmpty}
    >
      <S.Wrapper>
        <Row>
          <Col xs={12}>
            <S.Title>{intl.get('bottles.filter.edit.viewConfig')}</S.Title>
          </Col>
        </Row>
        <Row style={{ marginTop: '22px' }}>
          <Col xs={12}>
            <S.SubTitle>{intl.get('bottles.filter.edit.editMode')}</S.SubTitle>
          </Col>
          <Row>
            <Col xs={12} className='col-description'>
              <S.DescriptionTitle>{intl.get('bottles.filter.edit.normal')} - </S.DescriptionTitle>
              <S.Description>{intl.get('bottles.filter.edit.normalDescription')}</S.Description>
            </Col>
            <Col xs={12} className='col-description'>
              <S.DescriptionTitle>{intl.get('bottles.filter.edit.advanced')} - </S.DescriptionTitle>
              <S.Description>{intl.get('bottles.filter.edit.advancedDescription')}</S.Description>
            </Col>
          </Row>
        </Row>
        <Row>
          <Col xs={12}>
            <OptionGroup
              handleSelect={event => updateRecurrencePattern(event.currentTarget.value)}
              options={optionsRecurrencePattern}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Button
              type="default"
              htmlType="button"
              value={intl.get('commons.okay')}
              onClick={handleFilter}
            />
          </Col>
        </Row>
      </S.Wrapper>
    </Layout.Sidebar >
  )
}

FilterEditMode.propTypes = {
  close: PropTypes.func.isRequired,
  open: PropTypes.any.isRequired
}

