import styled from 'styled-components'

const StyledSuggestionsListItem = styled.div`
  padding: 5px 0;
  box-shadow: 0 1px #e6e6e6;

  .input {
    box-shadow: inset -1px 0 0 0 var(--color-neutral-300), inset 0 -1px 0 0 var(--color-neutral-300), inset 1px 0 0 0 var(--color-neutral-300), inset 0 2px 0 0 var(--color-action-hover) 
    !important;

    &:disabled {
      background-color: var(--color-neutral-200);
      box-shadow: inset 0 2px 0 0 rgb(149 163 183 / 30%) !important;
    }

    &:focus {
      box-shadow: inset -1px 0 0 0 var(--color-neutral-300), inset 0 -1px 0 0 var(--color-neutral-300), inset 1px 0 0 0 var(--color-neutral-300), inset 0 2px 0 0 var(--color-action-default);
    }
  }

  .header-actions .header-actions--collapse {
    border: 0;
    color: var(--color-contrast-white);
    outline: 0;
    padding: 5px 10px;
    cursor: pointer;
    background-color: var(--color-brand-200);
  }

  .suggestion-list-header {
    height: 50px;
    box-sizing: border-box;
  }

  .item-highlighted {
    background-color: var(--color-brand-100);
    color: var(--color-action-default);
    font-weight: bold;
  }

  .item-dimmed {
    background-color: #f2f2f2;
    color: var(--color-neutral-500);
    font-weight: bold;
  }

  .table-list-item-body {
    padding-top: 10px;

    .table-list-subitem-header {
      display: flex;
      color: var(--color-contrast-white);
      background-color: var(--color-brand-300);

      .header-item {
        flex: 1;
        padding: 10px;
      }
    }

    .table-list-subitem:not(:last-child) {
      box-shadow: 0 1px var(--color-contrast-white);
    }
  }

  .container{
    width:100% !important;
  }

  .label-style{
    background: white;
    margin-left: 0px;
  }

`

export default StyledSuggestionsListItem