import React from "react";
import PropTypes from 'prop-types';
import styled from "styled-components";

export const StyledCheckboxOptimization = styled.span`
  position: absolute;
  left: 5px;
  width: 18px;
  height: 18px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-right: 7px;
  border-radius: 50%;
  border: 2px solid var(--color-brand-200);
  cursor: pointer;

  ${p =>
    p.on &&
    `
      &:before {
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: var(--color-brand-200);
      }
    `};
`;

StyledCheckboxOptimization.propTypes = {
  on: PropTypes.bool
}
