import styled from 'styled-components'
import StyledGrid from 'components/styled-grid'

const GradeListStyled = styled(StyledGrid)`  

  width: 100%;
  padding: 0px 8px 0 8px;
  
  .containerListaGrade {
    background-color: white;
    height: calc(100vh - 320px);
    overflow-y: auto;
  }
  
  .pagination {
    justify-content: flex-end;
  }
  
  input {
    width:70px;
    flex-shrink: 0;
  }

  .no-results {
    font-size: 14px;
  }
  
  .pt-10 {
    padding-top: 10px;
  }
  
  .pb-10 {
    padding-bottom: 10px;
  }

  .hintList:hover .hintListComponent {
    display: block;
  }  
`

export default GradeListStyled