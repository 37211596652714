import PropTypes from "prop-types"
import React, { useEffect, useState } from 'react';
import { Container, Content, CredentialsBox, CredentialsBoxLink, CredentialsSection, Divider, Footer, LogoSection } from './login.styled';
import intl from 'react-intl-universal';
import ambevHbsisLogo from 'images/login/ambevHbsis.png';
import ontimeMrpLogo from 'images/login/OntimeMRP-logo-white.png';
import { ClbTooltip, ClbButton, ClbButtonIcon } from '@celebration/design-system-react';
import { getLocaleForLanguageSheet } from 'utils/userLanguage';
import withLanguage from 'utils/withLanguage';
import locales from 'locales'
import { getSupportEmail } from 'utils/config-environment';

const KcLogin = ({ toAmbev, toThird }) => {
  const [initDone, setInitDone] = useState(false)
  const [showSignUp, setShowSignUp] = useState(false)

  const loadLocales = async () => {
    const language = getLocaleForLanguageSheet(withLanguage(navigator))
    await intl.init({
      currentLocale: language,
      locales,
    })

    setInitDone(true)
  }

  useEffect(() => {
    loadLocales()
  }, [])

  if (!initDone) {
    return null
  }

  return (
    !showSignUp ? <Container className='l4'>
      <Content>
        <LogoSection>
          <img src={ontimeMrpLogo} id='ontimeMrp-logo'></img>
        </LogoSection>
        <CredentialsSection>
          <CredentialsBox>
            <p id="credentials-box-title">
              {intl.get('login.login')}
              <ClbTooltip label={intl.get('login.tooltipMesage')} position='top-start' id="credentials-box-tooltip-info">
                <ClbButtonIcon size={"sm"} icon={"Info"} baseIcon={false} />
              </ClbTooltip>
            </p>
            <p id='credentials-box-subtitle'>{intl.get('login.accessWithYourCredentials')}</p>
            <ClbButton onClbClick={() => toAmbev('Ambev')} size='md' styleType='primary' blockedWidth={true} id='button-sso-ambev'> {intl.get('login.ssoAmbev')} </ClbButton>
            <ClbButton onClbClick={() => toThird('Third')} size='md' styleType='secondary' blockedWidth={true} id='button-sso-terceiros'> {intl.get('login.ssoThirdPart')} </ClbButton>
            <Divider />
            <CredentialsBoxLink
              className='credentials-box-link'
              onClick={() => setShowSignUp(!showSignUp)}
            >
              {intl.get('login.iDontHaveARegistry')}
            </CredentialsBoxLink>
          </CredentialsBox>
        </CredentialsSection>
      </Content>
      <Footer>
        <span>{intl.get('login.allRightsReserved')}</span>
        <img src={ambevHbsisLogo} id='ambevHbsis-logo' />
      </Footer>
    </Container>
      :
      <Container className='l4'>
        <Content>
          <LogoSection>
            <img src={ontimeMrpLogo} id='ontimeMrp-logo'></img>
          </LogoSection>
          <CredentialsSection>
            <CredentialsBox>
              <p id="credentials-box-title">
                {intl.get('signUp.title')}
              </p>

              <p id="credentials-box-text"> {intl.get('signUp.text.paragraphOne')} </p>
              <p id="credentials-box-text">
                {intl.get('signUp.text.paragraphTwoPartOne')} <a href={`mailto:${getSupportEmail()}`}>{getSupportEmail()}</a> {intl.get('signUp.text.paragraphTwoPartTwo')}
              </p>
              <p id="credentials-box-text"> {intl.get('signUp.text.paragraphThree')} </p>
              <p id="credentials-box-text"> {intl.get('signUp.text.paragraphFour')} </p>

              <CredentialsBoxLink
                className='credentials-box-link'
                onClick={() => setShowSignUp(!showSignUp)}
              >
                {intl.get('signUp.back')}
              </CredentialsBoxLink>
            </CredentialsBox>
          </CredentialsSection>
        </Content>
        <Footer>
          <span>{intl.get('login.allRightsReserved')}</span>
          <img src={ambevHbsisLogo} id='ambevHbsis-logo' />
        </Footer>
      </Container>

  )
}

KcLogin.propTypes = {
  toAmbev: PropTypes.func.isRequired,
  toThird: PropTypes.func.isRequired
}

export default KcLogin;