const IconBrewery = ({ width, height }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "24px"}
      height={height ? height : "24px"}
      fill="none"
      viewBox="0 0 20 20"
    >
      <g id="icn-fabrica-blue-semi-light"/>
      <g id="Symbols"/>
      <g id="icn fabrica blue semi light"/>
      <g id="Group"></g>
      <g fill="var(--color-action-default)" fillRule="evenodd" clipRule="evenodd">
        <path d="m1.955 8-.953 10.454a.493.493 0 0 0 .13.383A.493.493 0 0 0 1.5 19h16.998a.5.5 0 0 0 .5-.5v-5.117l-4.243 2.546a.502.502 0 0 1-.757-.429v-2.117l-4.242 2.546a.502.502 0 0 1-.758-.429v-2.117l-4.242 2.546a.5.5 0 0 1-.754-.367L3.057 8H1.955Zm16.543 12H1.5c-.42 0-.824-.178-1.108-.488a1.507 1.507 0 0 1-.386-1.148L1 7.454A.5.5 0 0 1 1.498 7h2c.252 0 .465.188.497.438l.904 7.239 4.342-2.606a.5.5 0 0 1 .757.429v2.117l4.244-2.546a.5.5 0 0 1 .756.429v2.117l4.244-2.546a.5.5 0 0 1 .756.429v6c0 .827-.672 1.5-1.5 1.5ZM2.5 5.997a.5.5 0 0 1-.5-.5C2 3.35 3.416 1.374 5.607.465c1.958-.812 4.016-.544 5.246.682a.5.5 0 1 1-.706.708C9.205.915 7.574.732 5.99 1.388 4.545 1.988 3 3.383 3 5.497a.5.5 0 0 1-.5.5ZM15.362 4.997c-1.3 0-2.47-.411-3.214-1.147a.5.5 0 1 1 .703-.711c.644.636 1.801.956 3.04.832 1.375-.138 2.538-.777 3.194-1.753a.501.501 0 0 1 .83.557c-.822 1.225-2.25 2.023-3.924 2.191a6.396 6.396 0 0 1-.629.031Z" />
      </g>
    </svg>
  )
  export default IconBrewery