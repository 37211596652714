import PropTypes from "prop-types"
import React from 'react'
import { useZapmslContext } from '../../../../../context/zapmslContext';
import * as S from '../../styled'
import intl from 'react-intl-universal'
import { assignSaveTransactionIds } from '../../../../../primary-material-schedule.service';
import { errorMessage } from '../../../../../utils/format';
import { useGlobalContext } from 'hooks/useGlobalContext';

const BtnSaveTranId = ({ setModalAssignReturnListData }) => {
  const {
    selectedLines,
    clearSelectedLines,
    startLoading,
    stopLoading,
    filters,
    zapmslData,
    setZapmslData,
  } = useZapmslContext();

  const {
    showFeedback
  } = useGlobalContext()

  const verifyReturnItemsSap = (data, res) => (data.ScheduleLineCounter === res.ScheduleLineCounter
    && data.ScheduleAgreement === res.SchedulingAgreement
    && data.Material === res.Material
  )

  const saveTransactionIds = async () => {
    startLoading()
    try {
      const dataLinesSelected = zapmslData
        .filter(x => selectedLines.includes(x.Id))
        .map(line => ({
          ScheduleLineCounter: line.ScheduleLineCounter,
          SchedulingAgreement: line.ScheduleAgreement,
          Material: line.MaterialNumber
        }));

      const dtoSaveTransactionIds = {
        Plant: filters.plant.Centro,
        DateRangeFrom: filters.datas.deliveryDateFrom,
        DateRangeTo: filters.datas.deliveryDateTo,
        Items: dataLinesSelected
      }

      const responseSaveAssign = await assignSaveTransactionIds(dtoSaveTransactionIds);

      const _modalAssignReturnListData = {
        isOpen: true,
        data: responseSaveAssign.map(res => res.Message),
        title: 'Transaction ID Status'
      }

      const _zapmslData = [...zapmslData];

      responseSaveAssign.filter(res => res.Status === "Success").forEach((res) => {
        const indexOf = zapmslData.findIndex(data => verifyReturnItemsSap(data, res))
        _zapmslData[indexOf].WmsTransactionId = res.WmsTransactionId
      });

      setModalAssignReturnListData(_modalAssignReturnListData)
      clearSelectedLines([]);
      setZapmslData([..._zapmslData]);
    }
    catch (e) {
      showFeedback(errorMessage(e))
    }
    stopLoading()
  }

  return (
    <S.ButtonGenerate
      width="150px"
      value={intl.get('primaryMaterialScheduleList.list.header.btnSaveTranId')}
      onClick={saveTransactionIds}
    />
  )
}

BtnSaveTranId.propTypes = {
  setModalAssignReturnListData: PropTypes.func.isRequired
}

export default BtnSaveTranId;
