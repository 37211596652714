import styled from 'styled-components'

const StyledForm = styled.div`

/* form */
.footer-dialog-buttons-unidade-arredondamento{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: space-between;
  padding-top: 35px;
}

.label-input {
  text-transform: uppercase;
}

.button{
  margin: 2px;
  width: 100%;
}
`
export default StyledForm