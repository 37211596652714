import PropTypes from "prop-types"
import React, { useState } from 'react'
import intl from 'react-intl-universal'
import { withStyles } from '@material-ui/styles'
import { Checkbox } from '@hbsis.uikit/react'
import { Button } from 'components/uikit-adapter'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { OrdersCancelDataTable } from './table'
import { useGlobalContext } from 'hooks/useGlobalContext'
import Session from 'utils/user-storage'
import TipoPerfilAcesso from '../../../../models/usuarios/tipo-perfil-acesso'
import * as S from './styled'
import { getDataToCancel } from './utils'

const StyledDialogContent = withStyles(() => ({
  root: {
    borderBottom: 'none'
  }
}))(DialogContent)

export const OrdersCancelModal = ({ open, close, data }) => {
  const { startOperation } = useGlobalContext()
  const { TipoUsuario, PerfilAcesso } = Session.get()
  const permissions = [
    TipoPerfilAcesso.PciSolutions,
    TipoPerfilAcesso.MasterData,
    TipoPerfilAcesso.Administrador
  ]

  const [forcedCancel, setForcedCancel] = useState(false)

  const hasPermissionToForcedCancel = () =>
    TipoUsuario === 1 && permissions.includes(PerfilAcesso)


  const isBilled = (status) => status === 7 || status === 8 || status === 9 

  const billedOrder = data.
    find((order) => isBilled(order.OrderStatus)) ? true : false

  const cancelType = {
    All: 1,
    NotBilled: 2
  }

  const handleSave = (type) => {
    const items = type === cancelType.All ?
      data : data.filter((item) => item.OrderStatus !== 9 && item.OrderStatus !== 8)

    const requests = items.map(item => getDataToCancel(item, forcedCancel))
    const text = intl.get('orders.operationText02')
    startOperation(requests, text)
    setForcedCancel(false)
    close()
  }

  return (
    <Dialog
      PaperProps={{
        style: { overflow: 'visible', maxWidth: '720px' }
      }}
      fullWidth
      open={open}
    >
      <S.DialogTitle>
        <S.DialogTitleText>
          {billedOrder ? intl.get('orders.billedOrdersTitle') : intl.get('orders.cancelOrders')}
        </S.DialogTitleText>
        <S.DialogTitleIcon onClick={close} />
      </S.DialogTitle>
      <StyledDialogContent dividers>
        {billedOrder ?
          <React.Fragment>
            <S.DialogContentSection>
              <p>
                {intl.get('orders.billedOrdersDescription')}
              </p>
            </S.DialogContentSection>
            <S.DialogContentText>
              {intl.get('orders.billedOrdersText')}
            </S.DialogContentText>
          </React.Fragment>
          :
          <S.DialogContentText>
            {intl.get('orders.selectedOrders')}
          </S.DialogContentText>
        }
        <OrdersCancelDataTable data={data} />
      </StyledDialogContent>
      <S.DialogActions>
        {
          hasPermissionToForcedCancel() &&
          <Checkbox
            text={intl.get('stocks.stockDetail.dangerDialog.forcedCancel')}
            name='order-cancel-forced-checkbox'
            checked={forcedCancel}
            onChange={() => setForcedCancel(!forcedCancel)}
          />
        }
        {billedOrder ?
          <div className='billed-events'>
            <Button
              type='danger'
              value={intl.get('orders.cancelAllSelectedOrders')}
              onClick={() => handleSave(cancelType.All)}
            />
            <Button
              value={intl.get('orders.cancelNotBilledOrders')}
              onClick={() => handleSave(cancelType.NotBilled)}
            />
          </div>
          :
          <React.Fragment>
            <Button
              type='primary'
              value={intl.get('orders.cancelOrders')}
              onClick={() => handleSave(cancelType.All)}
            />
          </React.Fragment>
        }
        <Button
          type='default'
          value={intl.get('geral.modalConfirm.goBack')}
          onClick={close}
        />
      </S.DialogActions>
    </Dialog >
  )
}

OrdersCancelModal.propTypes = {
  close: PropTypes.func.isRequired,
  data: PropTypes.shape({
    filter: PropTypes.func,
    find: PropTypes.func
  }).isRequired,
  open: PropTypes.any.isRequired
}
