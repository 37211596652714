import styled from 'styled-components'

const StyledSuggestionsListHeader = styled.div`
  display: flex;
  align-items: stretch;

  .row {
    flex: 1;
  }

  .checkbox-items-list {
    margin-top: 9px;
    background-color: var(--color-contrast-white) !important;
    width: 70%;
    height: 50%;
  }

  .header-item {
    display: flex;
    flex: 1;
    font-size: 14px;
    align-items: center;
    padding: 0 5px;
    font-size: 14px;
    display: block;
    width: 120%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .header-actions {
    max-width: 30px;
    justify-content: center;
    align-self: center;

    .minimize-item,
    .maximize-item,
    .minimize-item-dimmed,
    .maximize-item-dimmed,
    .minimize-item-highlighted,
    .maximize-item-highlighted {
      position: relative;
      top: 2px;
      width: 24px;
      display: block;
      height: 15px;

      &::before,
      &::after,
      &::before,
      &::after {
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
      }
    }

    .item-highlighted {
      background-color: var(--color-brand-100);
    }

    .item-highlighted-color {
      color: var(--color-action-default);
      font-weight: bold;
    }


    .maximize-item-highlighted::before {
      border-width: 12px 12px 0 12px;
      border-color: var(--color-action-default) transparent transparent transparent;
    }

    .maximize-item-highlighted::after {
      left: 2px;
      border-width: 10px 10px 0 10px;
      border-color: var(--color-brand-100) transparent transparent transparent;
    }

    .minimize-item-highlighted::before {
      border-width: 0 12px 12px 12px;
      border-color: transparent transparent var(--color-action-default) transparent;
    }

    .minimize-item-highlighted::after {
      left: 2px;
      top: 2px;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent var(--color-brand-100) transparent;
    }

    .maximize-item::before {
      border-width: 12px 12px 0 12px;
      border-color: var(--color-action-default) transparent transparent transparent;
    }

    .maximize-item::after {
      left: 2px;
      border-width: 10px 10px 0 10px;
      border-color: var(--color-contrast-white) transparent transparent transparent;
    }

    .minimize-item::before {
      border-width: 0 12px 12px 12px;
      border-color: transparent transparent var(--color-action-default) transparent;
    }

    .minimize-item::after {
      left: 2px;
      top: 2px;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent var(--color-contrast-white) transparent;
    }

    /*--------------------------------*/
    .item-dimmed {
      background-color: #f2f2f2
    }

    .item-dimmed-color {
      color: var(--color-action-default);
      font-weight: bold;
    }

    .maximize-item-dimmed::before {
      border-width: 12px 12px 0 12px;
      border-color: var(--color-action-default) transparent transparent transparent;
    }

    .maximize-item-dimmed::after {
      left: 2px;
      border-width: 10px 10px 0 10px;
      border-color: #f2f2f2 transparent transparent transparent;
    }

    .minimize-item-dimmed::before {
      border-width: 0 12px 12px 12px;
      border-color: transparent transparent var(--color-action-default) transparent;
    }

    .minimize-item-dimmed::after {
      left: 2px;
      top: 2px;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent #f2f2f2 transparent;
    }

    .checkbox-items-list .checkbox-item {
      background-color: var(--color-contrast-white) !important;
    }
  }
  `

export const StyledExpandButton = styled.span``

export default StyledSuggestionsListHeader
