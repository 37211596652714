import PropTypes from "prop-types"
import { useState, useEffect } from 'react';
import intl from 'react-intl-universal'
import Loading from "components/center-loading";
import { ClbFullPageFlow } from 'libs/celebration';
import { useLoadBuildingContext } from 'features/load-building/context';
import { LoadBuildingFlowLoadSettings } from './load-settings';
import { LoadBuildingFlowReviewLoads } from './load-review';
import { getCalculatedVolumesOptimization } from 'features/load-building/load-building.service';
import { initialLoadSettingsData } from 'features/load-building/utils';
import { withRouter } from 'react-router-dom';
import { generateQueryParams } from 'utils/custom-functions';
import { useGlobalContext } from 'hooks/useGlobalContext';
import { useToastContext } from 'hooks/useToastContext';
import { formatErrorMessage } from 'utils/handle-error';
import { getMaxDateInList, getMinDateInList } from 'utils/date';
import { normalizeLoadsToRequestBuild, sendListIndividualRequests, verifyVolumeOrdersToUpdate } from './utils';
import { updatePendingVolumes } from 'services/volume-orders.service';

const LoadBuildingFullPageFlow = ({ history }) => {
  const { showFeedback } = useGlobalContext();
  const { openToast } = useToastContext();
  const { openFlowBuild, setOpenFlowBuild, volumesSelected, filter, loadsSending, setLoadsSending, loadsOptimized, setLoadsOptimized } = useLoadBuildingContext();
  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [loadSettingsData, setLoadSettingsData] = useState(initialLoadSettingsData);

  const handleBack = () => {
    if (activeStep === 1) {
      setOpenFlowBuild(false);
    } else {
      setActiveStep(prevState => (prevState - 1));
    }
  }

  const handleStepOne = async () => {
    setIsLoading(true);
    const _params = {
      Door: loadSettingsData.Door == 'N/A' || loadSettingsData.Door == intl.get('commons.undefined') ? null : loadSettingsData.Door,
      TruckSize: Number(loadSettingsData.TruckSize),
      AvailableDeliverySlots: loadSettingsData.AvailableDeliverySlots,
      VolumeOrderIds: volumesSelected
    };
    const _loadsOptimized = await getCalculatedVolumesOptimization(_params);
    setLoadsOptimized(_loadsOptimized);
    setActiveStep(prevState => (prevState + 1));
  }

  const handleStepTwo = async () => {
    const _data = [
      ...loadsOptimized.LoadCompositions,
      ...loadsOptimized.LoadNotFull
    ];
    const _loads = normalizeLoadsToRequestBuild(_data);

    setLoadsSending(_loads);
    const results = await sendListIndividualRequests(_loads, setLoadsSending, openToast);
    setLoadsSending([]);

    const _volumesToUpdate = verifyVolumeOrdersToUpdate(loadsOptimized)
    if (results.length && !!_volumesToUpdate.length) {
      updatePendingVolumes(_volumesToUpdate);
    }

    if (results.length) {
      const minDeliveryDate = getMinDateInList(results.map(x => new Date(x.MinDeliveryDate)));
      const maxDeliveryDate = getMaxDateInList(results.map(x => new Date(x.MaxDeliveryDate)));
      const _infosToRoute = {
        originId: results[0].OriginId,
        destinyId: results[0].DestinyId,
        door: results[0].Door,
        minDeliveryDate: minDeliveryDate,
        maxDeliveryDate: maxDeliveryDate,
      }
      history.push(`/loadComposition?${generateQueryParams(_infosToRoute)}`);
    }
  }

  const handleNext = async () => {
    try {
      if (activeStep === 1) {
        await handleStepOne();
      } else if (activeStep == 2) {
        await handleStepTwo();
      }
    } catch (error) {
      showFeedback(formatErrorMessage(error));
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (filter.BusinessUnitId) {
      setLoadSettingsData(initialLoadSettingsData)
      setActiveStep(1);
    }
  }, [filter.BusinessUnitId, volumesSelected])

  return (
    <ClbFullPageFlow
      title={intl.get('commons.loadBuilding')}
      opened={openFlowBuild}
      onClbClose={() => setOpenFlowBuild(false)}
      activeStep={activeStep}
      steps={2}
      type='progress'
      dataTestHandleBack={handleBack}
      dataTestHandleNext={handleNext}
      buttonsList={[
        {
          id: "btn-back",
          label: intl.get('master-data.general.actions.back'),
          title: intl.get('master-data.general.actions.back'),
          dataTestid: "btn-back",
          callback: handleBack,
          disabled: isLoading || (loadsSending?.length > 0)
        },
        {
          id: "btn-next",
          label: intl.get('geral.buttonsDefault.next'),
          title: intl.get('geral.buttonsDefault.next'),
          dataTestid: "btn-next",
          callback: () => { handleNext() },
          disabled: isLoading || (loadsSending?.length > 0) || (activeStep == 2 && loadsOptimized?.LoadCompositions.length == 0 && loadsOptimized?.LoadNotFull.length == 0)
        },
      ]}
    >
      {activeStep === 1 &&
        <LoadBuildingFlowLoadSettings
          loadSettingsData={loadSettingsData}
          setLoadSettingsData={setLoadSettingsData}
        />
      }
      {activeStep === 2 &&
        <LoadBuildingFlowReviewLoads
          loadSettingsData={loadSettingsData}
        />
      }
      <Loading isLoading={isLoading} />
    </ClbFullPageFlow>
  )
};

LoadBuildingFullPageFlow.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
}

const LoadBuildingFullPageFlowWithRouter = withRouter(LoadBuildingFullPageFlow);
export { LoadBuildingFullPageFlowWithRouter as LoadBuildingFullPageFlow };