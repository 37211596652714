import React from "react"
import { useGlobalContext } from 'hooks/useGlobalContext';
import { useZsagContext } from "../context/zsagContext"
import ModalDelete from '../modal-delete'
import { Button } from 'components/uikit-adapter/index'
import BtnLess from 'images/btn-less.svg'
import { retornaObjetoComId } from "../auto-load-optimization.service"
import intl from "react-intl-universal"

const DeleteLine = () => {
  const { showFeedback } = useGlobalContext()
  const {
    volumes,
    setVolumes,
    selectedVolumes,
    setSelectedVolumes,
    setIsOpenModalDelete,
    isOpenModalDelete,
    setLinesForDelete,
    setHaveUnsavedChanges
  } = useZsagContext()

  const deleteLines = async () => {
    setIsOpenModalDelete(false)
    const normalizedLineDeletes = volumes.Schedules
      .filter(schedule => selectedVolumes.includes(schedule.Id) && schedule.Process !== "I")
      .map(schedule => (
        {
          ...schedule,
          Process: "D"
        }
      ));
    setLinesForDelete(normalizedLineDeletes);
    const schedules = volumes.Schedules.filter(volume => !selectedVolumes.includes(volume.Id));
    setVolumes({
      ...volumes,
      Schedules: schedules.map((value, index) => retornaObjetoComId(value, index))
    })
    setHaveUnsavedChanges(true)
    setSelectedVolumes([]);
    showFeedback(intl.get('feedbacks.deleteRegister'))
  }

  return (
    <React.Fragment>
      <div className='btn-wrapper' data-testid='wrapper-btn-remove'>
        <Button
          type="secondary"
          icon={BtnLess}
          className='btn-action'
          onClick={() => setIsOpenModalDelete(true)}
          disabled={selectedVolumes.length === 0}
        />
      </div>
      <ModalDelete
        open={isOpenModalDelete}
        onClose={() => setIsOpenModalDelete(false)}
        deleteRows={deleteLines}
      />
    </React.Fragment>
  )
}

export default DeleteLine


