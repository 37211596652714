import { getGeneralSettingValue } from "services/general-settings/general-settings";
import {
  isScheduleAgreementFlow,
  usesViewPriceColumnsOnLoadCompositionView,
} from "utils/validations-general-settings";
import intl from "react-intl-universal";
import { parseFloatByLocale } from "locales/utils";
import { compareJson, isString } from "utils/custom-functions";
import { generateRandomNumber } from "utils/calculations";

export const buildEmergencyRenegotiateDto = (loadComposition, orders) => ({
  IdComposicaoCarga: loadComposition.IdComposicao,
  DataColetaProposta: loadComposition.DataColeta,
  DataEntregaProposta: loadComposition.DataEntrega,
  HoraEntregaProposta: loadComposition.HoraEntrega,
  Observacao: loadComposition.Observacao,
  PedidosMetaData: {
    AlteracaoPedidos: orders
      .filter(
        (x) =>
          !x.IsDeleted && !x.IsAddedInNegotiation && !x.IsNew && !x.IdSugestao
      )
      .map((x) => ({
        IdPedido: x.IdPedido,
        DataColeta: loadComposition.DataColeta,
        DataEntrega: loadComposition.DataEntrega,
        HoraEntrega: loadComposition.HoraEntrega,
        Quantidade: isString(x.Quantidade)
          ? parseFloatByLocale(x.Quantidade)
          : x.Quantidade,
      })),
    RemocaoPedidos: orders
      .filter((x) => x.IsDeleted)
      .map((x) => ({
        IdPedido: x.IdPedido,
      })),
    AdicaoSugestoes: orders
      .filter((x) => x.IsNew || x.IdSugestao)
      .map((x) => ({
        IdSugestao: x.IdSugestao,
        DataColeta: loadComposition.DataColeta,
        DataEntrega: loadComposition.DataEntrega,
        HoraEntrega: loadComposition.HoraEntrega,
        Quantidade: isString(x.Quantidade)
          ? parseFloatByLocale(x.Quantidade)
          : x.Quantidade,
        IdUnidadeNegocio: loadComposition.IdUnidadeNegocio,
        IdFornecedor: loadComposition.IdFornecedor,
        IdMaterial: x.MaterialSelect.Id,
      })),
    AdicaoPedidos: orders
      .filter((x) => x.IsAddedInNegotiation && !x.IsDeleted)
      .map((x) => ({
        IdPedido: x.IdPedido,
        Quantity: parseFloatByLocale(x.Quantidade),
      })),
  },
});

export const generateNewItem = () => ({
  IdCard: generateRandomNumber(),
  EmNegociacaoEmergencial: false,
  Quantidade: 0,
  Lote: "-",
  Material: null,
  UnidadeMedida: "-",
  HoraEntrega: null,
  SchedulingAgreement: "-",
  IdItemPedidoSap: "-",
  UnidadeMedidaCentroMaterialFornecedor: "-",
  IsNew: true,
});

export const getHeaders = () => [
  {
    title: intl.get("commons.requestBuy"),
    visible: true,
  },
  {
    title: intl.get("commons.item"),
    visible: true,
  },
  {
    title: intl.get("commons.portalCode"),
    visible: true,
  },
  {
    title: intl.get("commons.material"),
    visible: true,
  },
  {
    title: intl.get("commons.netPrice"),
    visible: getGeneralSettingValue(
      usesViewPriceColumnsOnLoadCompositionView()
    ),
  },
  {
    title: intl.get("commons.unitPrice"),
    visible: getGeneralSettingValue(
      usesViewPriceColumnsOnLoadCompositionView()
    ),
  },
  {
    title: intl.get("commons.schedulingAgreement"),
    visible: getGeneralSettingValue(isScheduleAgreementFlow()),
  },
  {
    title: intl.get("commons.lot"),
    visible: true,
  },
  {
    title: intl.get("commons.amount"),
    visible: true,
  },
  {
    title: intl.get("master-data.general-configuration.um"),
    visible: true,
  },
  {
    title: "",
    visible: true,
  },
  {
    title: "",
    visible: true,
  },
];

export const getHasEditRenegotiation = (orders, initialOrders, loadComposition, initialLoadComposition) => {
  const _orders = orders.map((x) => ({
    ...x,
    Quantidade: isString(x.Quantidade) ? parseFloatByLocale(x.Quantidade) : x.Quantidade,
    IsEdited: null,
    IsDeleted: x.IsDeleted || false,
    MaterialSelect: {
      Descricao: x.MaterialSelect?.Descricao,
      Id: x.MaterialSelect?.Id,
    }
  }));
  const _initialOrders = initialOrders.map((x) => ({
    ...x,
    IsEdited: null,
    IsDeleted: x.IsDeleted || false,
    MaterialSelect: {
      Descricao: x.MaterialSelect?.Descricao,
      Id: x.MaterialSelect?.Id,
    }
  }));

  const hasEditOrders = !compareJson(_orders, _initialOrders);

  const hasEditLoad = loadComposition.DataEntrega != initialLoadComposition.DataEntrega ||
    loadComposition.DataColeta != initialLoadComposition.DataColeta ||
    loadComposition.HoraEntrega?.slice(0,5) != initialLoadComposition.HoraEntrega?.slice(0,5)

  return hasEditOrders || hasEditLoad;
};
