import moment from 'moment'
import intl from 'react-intl-universal'
import { StatusPedido } from '../../../models/pedidos/statusPedido';
import { StatusSugestao } from '../../../models/sugestao/tiposSugestoes';
import { ISO_DATE_FORMAT } from 'utils/format-date';

export const initialStateModal = () => ({ isOpen: false, isEditMode: false, isSelectedCard: false });

export const validateNewSuggestion = (sugestao) => {
  let result = {
    isValid: true,
    message: ''
  };
  const dataAtual = moment().format(ISO_DATE_FORMAT);
  const dataColeta = moment(sugestao.DataColeta).format(ISO_DATE_FORMAT);
  const dataEntrega = moment(sugestao.EntregaDe).format(ISO_DATE_FORMAT);

  if (!sugestao.DataColeta || !sugestao.EntregaDe) {
    result = {
      isValid: false,
      message: intl.get('stocks.timeline.feedback.attentionDeadlinesMustBeMet')
    }
  } else if (!sugestao.IdFornecedor) {
    result = {
      isValid: false,
      message: intl.get('stocks.timeline.feedback.attentionSupplierNotFilled')
    }
  } else if (dataColeta < dataAtual || dataEntrega < dataAtual) {
    result = {
      isValid: false,
      message: intl.get('stocks.timeline.feedback.noDateBeforeCurrentDayAllowed')
    }
  } else if (dataColeta > dataEntrega) {
    result = {
      isValid: false,
      message: intl.get('stocks.timeline.feedback.collectionDateMustBeLessThanDeliveryDate')
    }
  } else if (!sugestao.QuantidadeSugeridaDe) {
    result = {
      isValid: false,
      message: intl.get('stocks.timeline.feedback.attentionQuantityMustBeGreaterThanZero')
    }
  }
  return result
}

export const getCardName = (data) => {
  let cardName = ''
  if (data.IsNew) {
    cardName = intl.get("commons.supplierSuggestion");
  }
  else if (data.IdSugestao && data.DescricaoCard === StatusSugestao.SugestaoAguardandoComposicaoCarga) {
    cardName = intl.get('commons.orderSuggestionAwaitingLoadComposition')
  }
  else if (data.IdPedido && data.DescricaoCard === StatusPedido.AguardandoComposicaoCarga) {
    cardName = intl.get('commons.orderAwaitingLoadComposition')
  }
  else if (data.IdPedido) {
    cardName = `${intl.get('commons.order')} ${data.IdPedido}`
  }
  else {
    cardName = intl.get('commons.orderSuggestion')
  }

  return cardName
}

export const filterSelectedCards = (cards, selectedCards) => cards.filter(x => !selectedCards.some(y => y.IdCard === x.IdCard))

export const validateMode = (mode, data) => {
  const id = {
    name: Object.keys(data).includes("IdPedido") ? "IdPedido" : "IdSugestao",
    value: data.IdSugestao || data.IdPedido
  }
  return mode ? id : { name: 'IdSugestao', value: '' }
}

export const getDataEntrega = (model) => model.EntregaAte || model.DataEntrega
export const getDataColeta = (model) => model.DataColeta
export const getQuantidade = (model) => model.QuantidadeSugeridaDe || model.Quantidade
export const getPropUpdate = (isReleased) => isReleased ? "Pedidos" : "Sugestoes"

export const getCenterByParams = (params) => ({ Descricao: params.destinoName, IdUnidadeNegocio: parseInt(params.destinoId) })

export const isSimpleCompositionCancellation = (selectedLoads) => selectedLoads?.length == 0;
