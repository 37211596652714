import Fetch from "utils/fetch"

export const saveGeneralSettings = async (data) => {
  await Fetch.patch(`configuracaogeral/atualizar`, data)
}

export const loadGeneralSettings = () => Fetch.get("configuracaogeral/consultar").then((data) => data)
export const loadCompositionIsNewFlow = () => Fetch.get("configuracaogeral/consultar").then((data) => data.data[0].Valor === "0" ? false : true)
export const getGeneralSettings = () => Fetch.get("configuracaogeral/consultar").then(({data}) => data)
export const loadOrderCancellationJustifications = () => Fetch.get("orders:cancellation-justifications").then((data) => data)
export const loadSuggestionJustifications = () => Fetch.get("suggestions:justifications").then((data) => data)