import { useEffect, useState } from 'react';
import Loading from "components/center-loading";
import { ClbTable } from 'libs/celebration'
import { LoadBuildingTableHeader } from './header-table';
import { LoadBuildingTableBody } from './body-table';
import { useLoadBuildingContext } from 'features/load-building/context';
import { validateValidFilter } from 'features/load-building/utils';
import { getVolumesPaginated } from 'features/load-building/load-building.service';
import { Wrapper } from './styled';

export const LoadBuildingTable = () => {
  const { filter, setQuantityRegisters } = useLoadBuildingContext();
  const [isLoading, setIsLoading] = useState();
  const [data, setData] = useState([]);

  const loadVolumes = async () => {
    setIsLoading(true);
    const _data = await getVolumesPaginated(filter);
    setQuantityRegisters(_data.TotalItems)
    setData(_data);
    setIsLoading(false);
  }

  useEffect(() => {
    if (validateValidFilter(filter)) {
      loadVolumes();
    }
  }, [filter])

  return (
    <Wrapper>
      <ClbTable>
        <LoadBuildingTableHeader
          data={data}
        />
        <LoadBuildingTableBody
          data={data}
          hasMandatoryFilters={validateValidFilter(filter)}
        />
      </ClbTable>
      <Loading isLoading={isLoading} />
    </Wrapper >
  )
}