import React from "react"
import intl from "react-intl-universal"
import { useGlobalContext } from 'hooks/useGlobalContext';
import { useZsagContext } from "../context/zsagContext"
import { Button } from 'components/uikit-adapter/index'
import IconGenerateSAG from 'images/generate-szag-icon.svg'
import { generateZSAG } from "../auto-load-optimization.service"
import { errorMessage } from "../utils/validate"

const GenerateZsag = () => {
  const { showFeedback } = useGlobalContext()
  const {
    setLoading,
    volumes,
    setVolumes,
    linesForDelete,
    filters,
    setHaveUnsavedChanges
  } = useZsagContext()

  const handleGenerateZSAG = () => {
    setLoading(true)
    const schedules = [...volumes.Schedules.filter(schedule => schedule.Process), ...linesForDelete]

    generateZSAG(filters, schedules)
      .then(response => {
        setVolumes({ ...response });
        showFeedback(intl.get('autoLoadOptimization.feedbacks.successfullyGeneratedSag'));
        setHaveUnsavedChanges(false);
      })
      .catch((e) => processError(e))
      .finally(() => setLoading(false))
  }

  const processError = e => {
    showFeedback(errorMessage(e));
  }

  return (
    <div className='btn-wrapper' data-testid='wrapper-generate-sag'>
      <Button
        icon={IconGenerateSAG}
        type="primary"
        value={intl.get('autoLoadOptimization.generateZSAG')}
        className='btn-generate'
        onClick={handleGenerateZSAG}
      />
    </div>
  )
}

export default GenerateZsag
