import { AutoPilotPeriodEnum, TypeOfAutoPilotScheduling } from "enum/labeled-family-center/auto-pilot"
import { SchedulingAutoPilotGeneralDto } from "types/labeled-family-center/auto-pilot/scheduling-auto-pilot-general-dto"
import moment from "moment"
import intl from 'react-intl-universal'
import { IAutoPilotPeriod, IDaysWeek } from "features/auto-pilot/types"
import { DayOfWeek } from "enum"

export const getDaysWeekInitialValue = (idLabeledFamilyCenter: number): IDaysWeek[] =>
  [
    {
      IdLabeledFamilyCenter: idLabeledFamilyCenter,
      DayOfWeek: DayOfWeek.Sunday,
      Selected: false,
      NameDayOfWeek: 'Sunday',
      StartTime: "00:00",
      FinishTime: "12:00",
      AutoPilotPeriod: AutoPilotPeriodEnum.AllDay
    },
    {
      IdLabeledFamilyCenter: idLabeledFamilyCenter,
      DayOfWeek: DayOfWeek.Monday,
      Selected: false,
      NameDayOfWeek: 'Monday',
      StartTime: "00:00",
      FinishTime: "12:00",
      AutoPilotPeriod: AutoPilotPeriodEnum.AllDay
    },
    {
      IdLabeledFamilyCenter: idLabeledFamilyCenter,
      DayOfWeek: DayOfWeek.Tuesday,
      Selected: false,
      NameDayOfWeek: 'Tuesday',
      StartTime: "00:00",
      FinishTime: "12:00",
      AutoPilotPeriod: AutoPilotPeriodEnum.AllDay
    },
    {
      IdLabeledFamilyCenter: idLabeledFamilyCenter,
      DayOfWeek: DayOfWeek.Wednesday,
      Selected: false,
      NameDayOfWeek: 'Wednesday',
      StartTime: "00:00",
      FinishTime: "12:00",
      AutoPilotPeriod: AutoPilotPeriodEnum.AllDay
    },
    {
      IdLabeledFamilyCenter: idLabeledFamilyCenter,
      DayOfWeek: DayOfWeek.Thursday,
      Selected: false,
      NameDayOfWeek: 'Thursday',
      StartTime: "00:00",
      FinishTime: "12:00",
      AutoPilotPeriod: AutoPilotPeriodEnum.AllDay
    },
    {
      IdLabeledFamilyCenter: idLabeledFamilyCenter,
      DayOfWeek: DayOfWeek.Friday,
      Selected: false,
      NameDayOfWeek: 'Friday',
      StartTime: "00:00",
      FinishTime: "12:00",
      AutoPilotPeriod: AutoPilotPeriodEnum.AllDay
    },
    {
      IdLabeledFamilyCenter: idLabeledFamilyCenter,
      DayOfWeek: DayOfWeek.Saturday,
      Selected: false,
      NameDayOfWeek: 'Saturday',
      StartTime: "00:00",
      FinishTime: "12:00",
      AutoPilotPeriod: AutoPilotPeriodEnum.AllDay
    },
  ]

export const normalizeDaysWeek = (data: SchedulingAutoPilotGeneralDto, daysWeek: IDaysWeek[]) => {
  let normalizeScheduleDays: IDaysWeek[] = [];

  data.SchedulingAutoPilots.forEach(x => {
    let startTime = new Date();
    startTime.setHours(x.StartHour)
    startTime.setMinutes(x.StartMinute)

    let finishTime = new Date();
    finishTime.setHours(x.FinishHour);
    finishTime.setMinutes(x.FinishMinute)

    normalizeScheduleDays.push({
      ...x,
      StartTime: moment(startTime).format('HH:mm'),
      FinishTime: moment(finishTime).format('HH:mm')
    })
  });

  const oldData = daysWeek.filter(x => !data.SchedulingAutoPilots.some(d => d.DayOfWeek === x.DayOfWeek))

  normalizeScheduleDays = [
    ...normalizeScheduleDays,
    ...oldData
  ]

  const compareDate = (a: IDaysWeek, b: IDaysWeek) => a.DayOfWeek.toString().localeCompare(b.DayOfWeek.toString())

  const newData = [...normalizeScheduleDays].sort((a, b) => compareDate(a, b));

  const typeRecurrence = data.SchedulingAutoPilots.length > 0 ? TypeOfAutoPilotScheduling.Custom : data.TypeOfAutoPilotScheduling

  return { newData, typeRecurrence }
}

export const getAutoPilotPeriodOptions = (): IAutoPilotPeriod[] =>
  Object.keys(AutoPilotPeriodEnum).filter(x => isNaN(Number(x))).map((x, i) => ({
    Name: intl.get(`master-data.general.auto-pilot.autoPilotPeriod.${x}`),
    Id: i
  }))
