const IconLog = ({width, height}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={width ? width : 45}
    height={height ? height : 45}
    style={{
      enableBackground: "new 0 0 548.291 548.291",
    }}
    viewBox="0 0 548.291 548.291"
  >
    <g fill="var(--color-action-default)">
      <path d="M486.201 196.124h-13.166V132.59c0-.396-.062-.795-.115-1.196-.021-2.523-.825-5-2.552-6.963L364.657 3.677c-.033-.031-.064-.042-.085-.073-.63-.707-1.364-1.292-2.143-1.795-.229-.157-.461-.286-.702-.421a11.22 11.22 0 0 0-2.121-.892c-.2-.055-.379-.136-.577-.188A10.684 10.684 0 0 0 356.562 0H96.757C84.894 0 75.256 9.651 75.256 21.502v174.613H62.092c-16.971 0-30.732 13.756-30.732 30.733V386.66c0 16.968 13.761 30.731 30.732 30.731h13.164V526.79c0 11.854 9.638 21.501 21.501 21.501h354.776c11.853 0 21.501-9.647 21.501-21.501V417.392H486.2c16.966 0 30.729-13.764 30.729-30.731V226.854c.001-16.982-13.762-30.73-30.728-30.73zM96.757 21.502h249.054v110.009c0 5.939 4.817 10.75 10.751 10.75h94.972v53.861H96.757V21.502zm221.059 281.925c0 47.77-28.973 76.746-71.558 76.746-43.234 0-68.531-32.641-68.531-74.152 0-43.679 27.887-76.319 70.906-76.319 44.756 0 69.183 33.511 69.183 73.725zM82.153 377.79V232.085h33.073v118.039h57.944v27.66H82.153v.006zm369.381 143.172H96.757v-103.57h354.776v103.57zm9.642-149.87c-10.162 3.454-29.402 8.209-48.641 8.209-26.589 0-45.833-6.698-59.24-19.664-13.396-12.535-20.75-31.568-20.529-52.967.214-48.436 35.448-76.108 83.229-76.108 18.814 0 33.292 3.688 40.431 7.139l-6.92 26.37c-7.999-3.457-17.942-6.268-33.942-6.268-27.449 0-48.209 15.567-48.209 47.134 0 30.049 18.807 47.771 45.831 47.771 7.564 0 13.623-.852 16.21-2.152v-30.488h-22.478v-25.723h54.258v76.747z" />
      <path d="M212.533 305.37c0 28.535 13.407 48.64 35.452 48.64 22.268 0 35.021-21.186 35.021-49.5 0-26.153-12.539-48.655-35.237-48.655-22.265-.001-35.236 21.192-35.236 49.515z" />
    </g>
  </svg>
)
export default IconLog;