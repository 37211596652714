import styled from 'styled-components'

export const Wrapper = styled.div`
`

export const MessageAlert = styled.span`
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  font-size: 18px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--color-action-default);
  margin: 20px 0px 12px 0px;
  cursor: auto;
`
