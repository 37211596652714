import { forwardRef, useMemo } from 'react'
import CurrencyInput from 'react-currency-input-field'
import { getDecimalSeparator, getGroupSeparator, getLocaleCode } from 'locales';
import * as S from './style'
import { getGeneralSettingValue } from 'services/general-settings/general-settings';
import { GeneralSettingParameter } from 'models/generalSetting/generalSetting';
import PropTypes from 'prop-types'

const InputNumber = forwardRef(({ onChange, type, label, disabled, required, ...rest }, ref) => {
  const decimalQuantity = useMemo(() => getGeneralSettingValue(GeneralSettingParameter.QuantityDecimalPlaces), []);
  return (
    <S.Wrapper type={type} disabled={disabled}>
      <S.Label>{label}</S.Label>
      <CurrencyInput
        intlConfig={{ locale: getLocaleCode() }}
        decimalSeparator={getDecimalSeparator()}
        groupSeparator={getGroupSeparator()}
        allowNegativeValue={false}
        decimalsLimit={decimalQuantity}
        allowDecimals={decimalQuantity > 0}
        onValueChange={onChange}
        disabled={disabled}
        required={required}
        ref={ref}
        {...rest}
      />
    </S.Wrapper>
  )
}
);

InputNumber.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.any,
  disabled: PropTypes.bool
}

export default InputNumber
