const IconControls = ( {width, height}) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width={width ? width: "32px"}
      height={height ? height: "24px"}
      style={{
        enableBackground: "new 0 0 54 54",
      }}
      viewBox="0 0 54 54"
    >
      <g fill="var(--color-action-default)">
        <path
          d="M1 9h3v4a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9h35a1 1 0 1 0 0-2H18V3a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v4H1a1 1 0 1 0 0 2zm5-5h10v8H6V4zM53 26H34v-4a1 1 0 0 0-1-1H21a1 1 0 0 0-1 1v4H1a1 1 0 1 0 0 2h19v4a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-4h19a1 1 0 1 0 0-2zm-21 5H22v-8h10v8zM53 45h-3v-4a1 1 0 0 0-1-1H37a1 1 0 0 0-1 1v4H1a1 1 0 1 0 0 2h35v4a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-4h3a1 1 0 1 0 0-2zm-5 5H38v-8h10v8z"
          data-original="#000000"
        />
      </g>
    </svg>
  )
  export default IconControls