import React, { useEffect, useState } from 'react'
import intl from 'react-intl-universal'
import { Button } from 'components/uikit-adapter/index'
import { OrdersChangeDateModal } from './modal'
import { useOrdersContext } from '../../context'
import Session from 'utils/user-storage'
import { getValidationForNegotiationOrder, getValidationForTotalItems } from './utils'
import IconOrderCalendar from 'components/icons/icn-oder-calendar.icon'

export const OrdersChangeDate = () => {
  const { items, checkbox } = useOrdersContext()
  const [alterableItems, setAlterableItems] = useState([])
  const [checkedItems, setCheckedItems] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [alterableOrderStatus] = useState([1, 2, 7, 8, 13, 14])

  useEffect(() => {

    const checkedItems = items.filter(item => checkbox.includes(item.Id))
    setCheckedItems(checkedItems)
    
    const alterableItems = checkedItems
      .filter(item => alterableOrderStatus.includes(item.OrderStatus) 
      && getValidationForNegotiationOrder(item, Session.get()))
    
      setAlterableItems(alterableItems)

  }, [checkbox])

  return (
    <React.Fragment>
      <div className='btn-content' title={intl.get('orders.changeOrder')}>
        <Button
          buttonIcon={<IconOrderCalendar />}
          width='166px'
          type='secondary'
          buttonText={intl.get('orders.changeOrder')}
          onClick={() => setOpenModal(!openModal)}
          disabled={alterableItems.length === 0 || getValidationForTotalItems(checkedItems, alterableItems)}
        />
      </div>
      <OrdersChangeDateModal
        open={openModal}
        close={() => setOpenModal(!openModal)}
        data={alterableItems}
      />
    </React.Fragment>
  )
}
