import React, { useState } from 'react'
import { ClbButtonIcon, ClbIcon } from '@celebration/design-system-react'
import intl from 'react-intl-universal'
import * as S from './styled'

export const ConsolidatedStocksTooltip = () => {
  const [trigger, setTrigger] = useState(false)

  return (
    <S.Wrapper
      onMouseEnter={() => setTrigger(!trigger)}
      onMouseLeave={() => setTrigger(!trigger)}
    >
      <ClbButtonIcon
        icon={"Info"}
        size='lg'
        data-testid={'tooltip'}
      />
      {trigger &&
        <S.Content>
          <S.TextWhite className='heading'>{intl.get("consolidatedStocks.tooltip.heading1")}</S.TextWhite>
          <S.ColorCodeWrapper>
            <S.DisplayContent>
              <S.Legend className='danger' />
              <S.TextWhite>
                {intl.get("consolidatedStocks.tooltip.legendDanger")}
              </S.TextWhite>
            </S.DisplayContent>
            <S.DisplayContent>
              <S.Legend className='positive' />
              <S.TextWhite>
                {intl.get("consolidatedStocks.tooltip.legendPositive")}
              </S.TextWhite>
            </S.DisplayContent>
            <S.DisplayContent>
              <S.Legend className='warning' />
              <S.TextWhite>
                {intl.get("consolidatedStocks.tooltip.legendWarning")}
              </S.TextWhite>
            </S.DisplayContent>
            <S.DisplayContent>
              <S.Legend className='brand' />
              <S.TextWhite>
                {intl.get("consolidatedStocks.tooltip.legendBrand")}
              </S.TextWhite>
            </S.DisplayContent>
            <S.DisplayContent>
              <S.Legend className='neutral' />
              <S.TextWhite>
                {intl.get("consolidatedStocks.tooltip.legendNeutral")}
              </S.TextWhite>
            </S.DisplayContent>
          </S.ColorCodeWrapper>
          <S.TextWhite className='heading'>
            {intl.get("consolidatedStocks.tooltip.heading2")}
            </S.TextWhite>
          <S.IconsWrapper>
            <S.DisplayContent>
              <ClbIcon size='sm' icon={"Eye1"} />
              <S.TextWhite>
                {intl.get("consolidatedStocks.tooltip.icon1")}
              </S.TextWhite>
            </S.DisplayContent>
            <S.DisplayContent>
              <ClbIcon size='sm' icon={"Edit"} />
              <S.TextWhite>
                {intl.get("consolidatedStocks.tooltip.icon2")}
              </S.TextWhite>
            </S.DisplayContent>
            <S.DisplayContent>
              <ClbIcon size='sm' icon={"Refresh"} />
              <S.TextWhite>
                {intl.get("consolidatedStocks.tooltip.icon3")}
              </S.TextWhite>
            </S.DisplayContent>
          </S.IconsWrapper>
        </S.Content>
      }
    </S.Wrapper>
  )

}