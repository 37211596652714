/**
  * This file can be used to format values according to user languages
*/
import { getLocaleCode } from "locales"
import { GeneralSettingParameter } from "models/generalSetting/generalSetting";
import { getGeneralSettingValue } from "services/general-settings/general-settings";
import { getLocaleInfosByCountry } from "./locale-methods";

/**
  * @param {*} number Value to be formatted.
  * @returns Formats the value according to the language registered to the user and adds the number of decimal places according to the general configuration.
  * Example of use en-US: formatNumber(230000.980); /returns 230,000.980/
  * Example of use pt-BR: formatNumber(230000.980); /returns 230.000,980/
*/
export const formatNumber = (number, options = {}) => {
  const quantityDecimalPlaces = getGeneralSettingValue(GeneralSettingParameter.QuantityDecimalPlaces);
  const valueFormatted = new Intl.NumberFormat(getLocaleCode(), { maximumFractionDigits: quantityDecimalPlaces, minimumFractionDigits: quantityDecimalPlaces, ...options }).format(number);
  return valueFormatted;
}

/**
  * @param {*} value Value to be formatted.
  * @param {*} currency Currency of the currency to be formatted;
  * @returns Format monetary value according to the currency code received in the parameter.
  * Example of use pt-BR: formatValueByCurrency(100, 'BRL'); /returns => R$100.00/
*/
export const formatValueByCurrency = (value, currency) => {
  if(!currency) currency = "USD";
  const valueFormatted = new Intl.NumberFormat(getLocaleCode(), {
    style: 'currency',
    currency: currency
  }).format(value);

  return valueFormatted;
}

/**
  * @param {*} value Value to be formatted.
  * @param {*} currency Currency of the country to be formatted;
  * @returns Format monetary value according to the country received in the parameter.
  * Example of use 1 (pt-BR): formatValueByCurrency(100, 'BRL'); /returns => R$100.00/
*/
export const formatValueByCountry = (value, country) => {
  const localeInfosCountry = getLocaleInfosByCountry(country);
  const valueFormatted = new Intl.NumberFormat(localeInfosCountry.localeCode, {
    style: 'currency',
    currency: localeInfosCountry.currencyCode,
  }).format(value);

  return valueFormatted;
}

/**
  * @param {*} value Value to be formatted.
  * @returns Format value returning only numbers.
  * Example: formatOnlyNumber(12a34b56c); /returns => 123456
*/
export const formatOnlyNumber = (value = '') => value.toString().replace(/\D/g, '');

/**
  * @param {*} value Value to be formatted.
  * @returns Format value removing spaces.
  * Example: formatWithoutSpaces(A B C); /returns => ABC
*/
export const formatWithoutSpaces = (value = '') => value.replace( /\s/g, '');

/**
  * @param {*} value Value to be formatted.
  * @returns Format without leading zeros.
  * Example: formatWithoutLeadingZeros(000000028170280); /returns => 28170280
*/
export const formatWithoutLeadingZeros = (value = '') => value.replace(/^0+/, '')
