import React, { Component } from "react"
import PropTypes from 'prop-types'
import Dialog from "material-ui/Dialog"
import { Row, Col } from "react-flexbox-grid"
import { Input, Textarea } from "@hbsis.uikit/react"
import { Button } from "components/uikit-adapter"
import StyledModalRenegociacaoComposicaoCarga from "./modal-renegociacao-composicao-carga.styled"
import intl from 'react-intl-universal'
import { formatErrorMessage } from 'utils/handle-error';
import DateInput from "../../../../components/date-input"
import { renegociarComposicaoCarga } from './modal-renegociacao-composicao-carga.service'
import Message from 'components/message';
import Session from 'utils/user-storage';
import { getTextAreaStyle } from "components/suggestions-list/suggestions-list-item/suggestions-list-item.styled"
class ModalRenegociacaoComposicaoCarga extends Component {
  constructor() {
    super();
    this.state = {
      dataEntregaSugerida: '',
      horaEntregaSugerida: '',
      observacaoSugerida: '',
      showMsg: false,
      textMsg: '',
      titleMsg: '',
      requestCount: 0,
    }
  }

  componentDidMount() {

    this.setState({
      dataEntregaSugerida: this.props.composicaoCarga.DataEntrega,
      horaEntregaSugerida: this.props.composicaoCarga.HoraEntrega,
      observacaoSugerida: this.props.composicaoCarga.Observacao
    })
  }

  handleChangeValue = (name, value) => {
    this.setState({
      [name]: value
    }
    )
  }

  handleFetch = (isFetching = true) => {
    this.setState(prevState => ({
      requestCount: isFetching ? prevState.requestCount + 1 : prevState.requestCount - 1,
    }))
  }

  handleRenegociarComposicaoCarga = async () => {
    try {
      this.handleFetch();

      if (this.validateFields()) {
        const user = Session.get()

        const negociacaoComposicaoCarga = {
          LoadCompositionId: this.props.composicaoCarga.IdComposicao,
          SuggestedDeliveryDate: this.state.dataEntregaSugerida,
          SuggestedDeliveryTime: this.state.horaEntregaSugerida,
          SuggestedObservation: this.state.observacaoSugerida,
          UserId: user.Id
        }

        await renegociarComposicaoCarga(this.props.composicaoCarga.IdComposicao, negociacaoComposicaoCarga);
        this.showMessageRetorno(intl.get('commons.renegotiationLoadCompositionSent'))

        this.setState({
          handleClose: () => {
            this.setState({ showMsg: false })
            this.props.toggleOpenModalRenegociacaoComposicaoCarga()
            this.props.closeModalDetalhamentoComposicao()
          }
        })
      }
    }
    catch (error) {
      this.showMessageRetorno(formatErrorMessage(error))
    }
    finally {
      this.handleFetch(false);
    }
  }

  validateFields = () => {
    let isValid = true

    const { dataEntregaSugerida, horaEntregaSugerida, observacaoSugerida } = this.state
    const { DataEntrega, HoraEntrega, Observacao } = this.props.composicaoCarga

    if (dataEntregaSugerida === DataEntrega && horaEntregaSugerida === HoraEntrega && observacaoSugerida === Observacao) {
      this.showMessageRetorno(intl.get('commons.youNeedToFillAtLeastOneFieldToNegotiateTheOrder'))
      isValid = false
    }

    return isValid
  }

  showMessageRetorno = (data) => {
    this.setState({
      showMsg: true,
      titleMsg: intl.get("composition.form.note"),
      textMsg: data,
      handleClose: () => {
        this.setState({ showMsg: false })
      }
    })
  }

  render() {
    const {
      dataEntregaSugerida,
      horaEntregaSugerida,
      observacaoSugerida,
    } = this.state

    const {
      openModalRenegociacaoComposicaoCarga,
      toggleOpenModalRenegociacaoComposicaoCarga,
      composicaoCarga,
    } = this.props;

    return (
      <Dialog
        autoScrollBodyContent
        title={`${intl.get('commons.negotiationLoadComposition')} - ${composicaoCarga.IdComposicao}`}
        contentStyle={{ width: "600px" }}
        open={openModalRenegociacaoComposicaoCarga}
        onRequestClose={toggleOpenModalRenegociacaoComposicaoCarga}
      >
        <StyledModalRenegociacaoComposicaoCarga
        data-testid='modal-renegotiation-load-composition'>
          <Row>
            <Col xs={6} lg={6} md={6}>
              <DateInput
                id='data-entrega-sugerida'
                label={intl.get('modalRenegotiationLoadComposition.suggestedDeliveryDate')}
                value={dataEntregaSugerida}
                placeholder={intl.get('modalRenegotiationLoadComposition.suggestedDeliveryDate')}
                onChange={(date) => this.handleChangeValue('dataEntregaSugerida', date)}
              />
            </Col>
            <Col xs={6} lg={6} md={6}>
              <Input
                className='input'
                id='hora-entrega-sugerida'
                htmlType='time'
                label={intl.get('modalRenegotiationLoadComposition.suggestedDeliveryTime')}
                value={horaEntregaSugerida}
                placeholder={intl.get('modalRenegotiationLoadComposition.suggestedDeliveryTime')}
                onChange={event => this.handleChangeValue('horaEntregaSugerida', event.target.value)}
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={12} md={12}>
              <Textarea
                data-testid='load-composition-negotiate-observation-suggested'
                style={getTextAreaStyle()}
                id='observacao-sugerida'
                label={intl.get('modalRenegotiationLoadComposition.negotiationNotes')}
                value={observacaoSugerida || ''}
                onChange={(event) => this.handleChangeValue('observacaoSugerida', event.target.value)}
              />
            </Col>
          </Row>

          <div className="footer-dialog-buttons">
            <Button
              id='button-close'
              type="default"
              value={intl.get('geral.buttonsDefault.cancel')}
              onClick={toggleOpenModalRenegociacaoComposicaoCarga}
              className="button cancel"
            />
            <Button
              id='button-renegociar-composicao-carga'
              type="primary"
              value={intl.get('geral.buttonsDefault.send')}
              onClick={this.handleRenegociarComposicaoCarga}
              className="button send"
            />
          </div>
        </StyledModalRenegociacaoComposicaoCarga>
        <Message
          show={this.state.showMsg}
          text={this.state.textMsg}
          title={this.state.titleMsg}
          handleClose={this.state.handleClose}
        />
      </Dialog >
    )
  }
}

ModalRenegociacaoComposicaoCarga.propTypes = {
  closeModalDetalhamentoComposicao: PropTypes.func.isRequired,
  composicaoCarga: PropTypes.object.isRequired,
  openModalRenegociacaoComposicaoCarga: PropTypes.bool.isRequired,
  toggleOpenModalRenegociacaoComposicaoCarga: PropTypes.func.isRequired
}

export default ModalRenegociacaoComposicaoCarga;
