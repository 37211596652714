export const paisesMapper = (data) => data.map((item) => ({ Key: item.Id, Value: item.Descricao }))

export const getLocaleForLanguageSheet = (locale) => {
  switch (locale) {
    case 'pt-BR':
      return 'pt-BR'
    case 'en-US':
    case 'en-CA':
    case 'en-ZA':
    case 'en-GB':
      return 'en-US'
    case 'es-ES':
    case 'es-BO':
      return 'es-ES'
    default:
      return 'en-US'
  }
}

export const getDefaultLocale = (locale) => {
  switch (locale) {
    case 'pt-BR':
    case 'en-US':
    case 'en-CA':
    case 'en-ZA':
    case 'en-GB':
      return locale
    case 'es-ES':
    case 'es-BO':
      return 'es-ES'
    default:
      return 'en-US'
  }
}