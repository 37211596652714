const OrderStatus = ({
  AguardandoPcSap: 13,
  AguardandoFornecimento: 14,
  AguardandoAprovacaoGestor: 15
})

const OrderType = ({
  Compra: 1,
  Transferencia: 2,
  Other: 3 // oque é isso ?
})

const buyerGroupStatus = [
  OrderStatus.AguardandoPcSap,
  OrderStatus.AguardandoAprovacaoGestor,
  OrderStatus.AguardandoFornecimento
]

export const getDataToCancel = (order, forcedCancel) => {
  const orderType = order.OrderType ===
    OrderType.Compra || order.OrderType === OrderType.Other ? 'purchase' : 'transfer'

  let name = 'cancel'

  if (buyerGroupStatus.includes(order.OrderStatus) && order.OrderType === OrderType.Compra) {
    name = 'direct-cancel'
  }
  if (forcedCancel && order.OrderType === OrderType.Compra) {
    name = 'direct-cancel'
  }

  let data = {
    API: `orders/${orderType}/${order.Id}:${name}`,
    method: 'post',
    body: {
      CancellationJustification: 9 //outros
    },
    errorId: order.Id
  }

  return data

}