import Fetch from "utils/fetch";
import { generateQueryParams } from "utils/custom-functions";

export const getCenters = () => Fetch.get(`/unidadeNegocio/unidadegeral`).then(({ data }) => data);

export const getData = (dtoRequest) => (
  Fetch.get(`/cronogramaEntrega?${generateQueryParams(dtoRequest)}`).then(({ data }) => data)
)

export const saveChanges = (dataRequest) => (
  Fetch.post('/cronogramaEntrega', dataRequest).then(({ data }) => data)
)
