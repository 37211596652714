import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'
import SuppliersListItem from './suppliers-list-item'
import SuppliersListItemDesc from './desc'

class SuppliersList extends Component {
  constructor(props) {
    super(props)

    this.upItem = this.upItem.bind(this)
    this.downItem = this.downItem.bind(this)
  }

  upItem(index) {
    let { Fornecedores } = this.props

    if (index > 0) {
      const item1 = Fornecedores[index]
      const item2 = Fornecedores[index - 1]

      Fornecedores[index - 1] = item1
      Fornecedores[index] = item2

      this.props.updateSuppliers(Fornecedores)
    }
  }

  downItem(index) {
    let { Fornecedores } = this.props

    let lastItem = Fornecedores.length -2
    if (Fornecedores.length > 0)
    {
      lastItem = Fornecedores.length
    }

    if (index < lastItem) {
      const item1 = Fornecedores[index]
      const item2 = Fornecedores[index + 1]

      Fornecedores[index + 1] = item1
      Fornecedores[index] = item2

      this.props.updateSuppliers(Fornecedores)
    }
  }

  render() {
    const { Fornecedores } = this.props

    let itemsCount = Fornecedores.length

    if (Fornecedores.length >= 1)
      itemsCount = Fornecedores.length -1

    return (
      <div>
        <Row>
          <Col xs={1}>
            <SuppliersListItemDesc itemsCount={itemsCount}/>
          </Col>
          <Col xs={11}>
            {
              Fornecedores.map((v, i) => (
                <SuppliersListItem
                  item={v}
                  index={i}
                  upItem={this.upItem}
                  downItem={this.downItem}
                  toupDisabled={i == 0}
                  todownDisabled={i == itemsCount -1}
                  openForm={this.props.openForm}
                  deleteItem={this.props.deleteItem}
                />
              ))
            }
          </Col>
        </Row>
      </div>
    )
  }
}

SuppliersList.propTypes = {
  Fornecedores: PropTypes.array.isRequired,
  updateSuppliers: PropTypes.func.isRequired,
  openForm: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
}

export default SuppliersList
