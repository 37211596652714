import PropTypes from "prop-types"
import React, { createContext, useContext, useState } from 'react'
import Loading from 'components/center-loading'
import { ordersFiltersDefault } from './components/orders-filter/utils'
import StorePersist from 'utils/store-persist'
const OrdersContext = createContext()

const OrdersProvider = ({ children }) => {
  const [pagination, setPagination] = useState({
    CurrentPage: 0,
    TotalItems: 0,
    TotalPages: 0,
    size: 50
  })
  const [items, setItems] = useState([])
  const [checkbox, setCheckbox] = useState([])
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState({
    ...StorePersist.getValuesJSON("filtersOrdersMulti") ?? ordersFiltersDefault,
    UseFilterDefault: JSON.parse(localStorage.getItem("filterMyItemsOnly"))
  })

  return (
    <OrdersContext.Provider value={{
      items,
      setItems,
      pagination,
      setPagination,
      filter,
      setFilter,
      checkbox,
      setCheckbox,
      loading,
      setLoading,
    }}>
      {children}
      <Loading isLoading={loading} />
    </OrdersContext.Provider>
  )
}

OrdersProvider.propTypes = {
  children: PropTypes.any.isRequired
}

const useOrdersContext = () => useContext(OrdersContext)

export { OrdersProvider, OrdersContext, useOrdersContext }
