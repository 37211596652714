import { Component } from 'react'
import SnackBar from 'material-ui/Snackbar'
import ActionHome from 'material-ui/svg-icons/action/home'
import Fetch from 'utils/fetch'
import Loading from 'components/center-loading'
import HeaderIcon from 'components/header-icon'
import DeleteFamily from 'components/delete-dialog'
import ButtonNewItem from 'components/button-new-item'
import FamilyList from './family-list'
import FamilyForm from './family-form'
import intl from 'react-intl-universal'

import './family.css'
import { formatErrorMessage } from 'utils/handle-error';
import { profileAdminOrMasterData } from "services/permissions/getPermissions";
import { generateQueryParams } from 'utils/custom-functions'

class Family extends Component {
  constructor() {
    super()

    this.state = {
      isFetching: false,
      families: [],
      total: 0,
      openDelete: false,
      currentPage: 0,
      openForm: false,
      openEditMode: false,
      idSelected: 0,
      showFeedback: false,
      messageFeedBack: '',
      qtRegsPerPage: 100
    }
  }

  showFeedback = (message) => {
    this.setState({
      showFeedback: true,
      messageFeedBack: message
    })
  }

  closeFeedback = () => {
    this.setState({
      showFeedback: false
    })
  }

  openForm = () => {
    this.setState({
      openForm: true
    })
  }

  openNewForm = () => {
    this.setState({
      openEditMode: false
    }, () => this.openForm())
  }

  openEditForm = (row) => {
    const { families } = this.state;
    const selected = families[row]

    this.setState({
      openEditMode: true,
      idSelected: selected.Id
    }, () => this.openForm())
  }

  closeForm = () => {
    this.setState({
      openForm: false
    })
  }

  openDelete = (id) => {
    this.setState({
      openDelete: true,
      idSelected: id
    })
  }

  startFetching = () => {
    this.setState({
      isFetching: true
    })
  }

  stopFetching = () => {
    this.setState({
      isFetching: false
    })
  }

  proccessErro = (exception) => {
    this.stopFetching()
    this.showFeedback(formatErrorMessage(exception))
  }

  refreshSearch = () => {
    this.closeForm()
    this.searchFamily(this.state.currentPage)
  }

  searchFamily = (page) => {
    this.startFetching()

    const _body = {
      _page: page,
      _size: this.state.qtRegsPerPage
    };

    Fetch.get(`/families:paginated?${generateQueryParams(_body)}`)
      .then((response) => this.proccessData(response.data))
      .then(this.stopFetching)
      .then(() => this.setState({ currentPage: page }))
      .catch((e) => this.proccessErro(e))
  }

  proccessData = (data) => {
    this.setState({
      families: data.Data,
      total: data.TotalRecordCount
    })
  }

  closeDelete = () => {
    this.setState({
      openDelete: false
    })
  }

  deleteFamily = () => {
    const idFamily = this.state.idSelected

    Fetch.delete(`/families/${idFamily}`)
      .then(this.closeDelete)
      .then(() => this.searchFamily(this.state.currentPage))
      .then(() => this.showFeedback(intl.get('master-data.menus.feedback.familySuccessfullyDeleted')))
      .catch((e) => this.proccessErro(e))
  }

  render() {
    let {
      openForm,
      openEditMode,
      openDelete,
      idSelected,
      families,
      total,
      showFeedback,
      messageFeedBack,
      isFetching
    } = this.state

    return (
      <div className='family-container'>
        <Loading isLoading={isFetching} />

        <div className="family-header">
          <HeaderIcon
            title={intl.get('master-data.walletsAndFamilys.familys.header.title')}
            subtitle={intl.get('master-data.walletsAndFamilys.familys.header.subtitle')}
            icon={<ActionHome style={{ width: 45, height: 45 }} color='var(--color-brand-500)' />}
          >
            {profileAdminOrMasterData() &&
              <div title={intl.get('master-data.walletsAndFamilys.familys.actions.newFamily')}>
                <ButtonNewItem
                  className="general-button"
                  onClick={this.openNewForm}
                />
              </div>
            }
          </HeaderIcon>
        </div>

        <FamilyList
          data={families}
          countData={total}
          refreshSearch={this.searchFamily}
          handleEdit={this.openEditForm}
          handleDelete={this.openDelete}
          qtRegsPerPage={this.state.qtRegsPerPage}
          profileAdminOrMasterData={profileAdminOrMasterData()}
        />

        {openForm &&
          <FamilyForm
            idEdit={idSelected}
            open={openForm}
            editMode={openEditMode}
            handleClose={this.closeForm}
            handleRefresh={this.refreshSearch}
            handleFeedback={this.showFeedback}
            profileAdminOrMasterData={profileAdminOrMasterData()}
          />
        }

        <DeleteFamily
          open={openDelete}
          title={intl.get('master-data.walletsAndFamilys.familys.deleteFamilyModal.title')}
          handleClose={this.closeDelete}
          warningMessage={intl.get('master-data.walletsAndFamilys.familys.deleteFamilyModal.description')}
          handleConfirm={this.deleteFamily}
        />

        <SnackBar
          message={messageFeedBack}
          open={showFeedback}
          autoHideDuration={3000}
          onRequestClose={this.closeFeedback}
        />
      </div>
    )
  }
}

export default Family
