import intl from 'react-intl-universal';

export const getDoorsOptions = (doors) => {
  return doors?.length > 0 ? doors : [{ Name: 'N/A' }]
}

export const getDoorObjectByName = (doors, doorName) => {
  return doorName === 'N/A' ? { Name: 'N/A' } : doors?.find(d => d.Name === doorName);
}

export const isDoorsDisabled = (doors, doorName) => (!doors || doors?.length <= 0 || (doors?.length > 0 && doorName === 'N/A'));

export const getDoorName = (doors, doorName, prop = 'Name') => {
  const door = doors?.find(d => d[prop] === doorName);

  if (!doors || doors?.length <= 0 || (doorName && !door)) return 'N/A';

  if (!doorName && !door) return intl.get('commons.undefined');

  return door[prop];
}

export const getDoorsOptionsClb = (doors) => {
  if (!doors) return [];

  const _doorOptions = doors.map((value, index) => ({ key: index + 1, label: value, value: value }));

  const _defaultOption = { key: 0, label: getDoorName(doors, null, 'label'), value: getDoorName(doors, null, 'value') };
  _doorOptions.unshift(_defaultOption);

  return _doorOptions;
}

export const getDoorsOptionsClbByProps = (doors, nameLabel, idLabel) => {
  if (!doors) return [];

  const _doorOptions = doors.map((value) => ({ key: value[idLabel], label: value[nameLabel], value: value[idLabel].toString() }));

  const _defaultOption = { key: 0, label: getDoorName(doors, null, 'label'), value: '0' };
  _doorOptions.unshift(_defaultOption);
  
  return _doorOptions;
}
