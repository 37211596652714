import styled from "styled-components";

export const ActionsWrapper = styled.div`
  .label-white{
    span{
      color: var(--color-contrast-white);
    }
  }

  .tertiary{
    border: 1px solid var(--color-action-default) !important;
      span{
        color: var(--color-action-default) !important;
      }
  }

  > * {
        margin-top: 6px;
        :last-child{
          margin-top: 24px;
        }
    }
`

export const TitleWrapper = styled.div`
  padding: 20px;
  border-bottom: 1px solid #C5D0E0;
  color: #3B4A5D;
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  font-size: 18px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #3B4A5D;

  > {
      &:last-child{
        padding-left: 20px;
        margin-bottom: auto;
    }
  }
`

export const ContentWrapper = styled.div`
  padding: 20px;

  span{
    font-style: normal;
    font-weight: var(--font-weight-regular);
    font-size: 14px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: #3B4A5D;
  }

  table{
    margin-top: 12px;
  }

  td{
    background: #EDEDED;
    left: -1px;
    position: relative;
    font-weight: var(--font-weight-regular);
    font-size: 14px;
    color: #313131;
    padding: 6px 12px;
  }

  th{
    border-right: 1px solid #EDEDED;
    font-weight: var(--font-weight-regular);
    font-size: 12px;
    line-height: 12px;
    align-items: center;
    color: #A2A2A2;
    text-align: left;
    padding: 9.5px 12px;
  }
`
