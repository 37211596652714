import styled from 'styled-components'

export const Container = styled.div`
  min-height: calc(100vh - 225px);
`
export const Actions = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;

  .div-filters {
    width: auto;
    margin-right: 20px;

    .btn-tipo-visualizacao {
      padding-right: 0;
    }
  }

  .btn-import-export-order {
    width: 45px;
  }

  .action-buttons {
    div {
      margin: 3px;
    }
  }

`

export const ActionButtons = styled.div`
  display: flex;

  div {
    margin: 3px;
  }
`


