import React from 'react'

import './card-alert.css'

const CardAlert = () => (
  <div className='card-alert-container'>
    <span>!</span>
  </div>
)

export default CardAlert
