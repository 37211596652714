import styled from 'styled-components'

export const Header = styled.div`
  .image-status {
    img {
      width: 30px;
      height: 30px;
    }
  }
`
export const Wrapper = styled.div``
export const Body = styled.div`
  margin-top: 5px;
  width: calc(100% - 15px);
  padding-left: 10px;
  padding-right: 5px;
  gap: 5px;
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(0px,1fr));
`
export const Label = styled.div``
