export const firstSection = [
  { name: "Linha", translation: 'Line' },
  { name: "Status", translation: 'Status' },
  { name: "REL", translation: 'REL' },
  { name: "Material", translation: 'Material' },
  { name: "Trans ID", translation: 'Trans ID' },
  { name: "Acordo de Cronograma", translation: 'Schedule Agreement' },
  { name: "Item SL", translation: 'SLItem' },
  { name: "Paletes", translation: 'Pallets' },
  { name: "UOM", translation: 'UOM' },
  { name: "Quantidade", translation: 'Quantity' },
  { name: "Unidade", translation: 'Unit' },
  { name: "Data de entrega", translation: 'Delivery Date' },
  { name: "Hora de entrega", translation: 'Delivery Time' },
  { name: "Porta", translation: 'Door' },
  { name: "Comentários", translation: 'Comments' },
  { name: "Código do Fornecedor", translation: 'Supplier Code' },
  { name: "Nome do Fornecedor", translation: 'Supplier Name' },
  { name: "Ordem de Carregamento do Caminhão", translation: 'Truck Load Order' }
]
