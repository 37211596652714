import React from 'react'
import PropTypes from "prop-types"
import intl from 'react-intl-universal'
import moment from "moment"
import * as S from '../../styled'
import SupplierInfos from 'pages/load-composition/components/supplier-negotiation-infos'
import ButtonCancelLoadComposition from 'components/button-cancel-load-composition'
import { SituacaoComposicaoCarga } from 'models/composicaoCarga/situacaoComposicaoCarga'
import Feature from 'components/feature'
import { acceptLoadCompositionEmergencyNegotiation } from 'services/load-composition.service'
import { formatErrorMessage } from 'utils/handle-error'
import { ValidateUserLoggedByType } from 'utils/user-validations'
import { getValueOrDefault } from 'utils/custom-functions'
import { usesEmergencyNegotiation } from 'utils/validations-general-settings'

const ModalInfosNegotiationLoadComposition = ({
  composicao,
  handleClose,
  handleEditLoad,
  handleRefresh,
  handleFeedback,
  isLoading,
  setIsLoading
}) => {
  const handleCallbackConfirmCancel = () => {
    handleRefresh();
    handleClose();
  }

  const handleAccept = async () => {
    setIsLoading(true);
    try {
      await acceptLoadCompositionEmergencyNegotiation(composicao.IdComposicao);
    } catch (e) {
      handleFeedback(formatErrorMessage(e));
    }
    handleCallbackConfirmCancel();
    setIsLoading(false);
  }

  const isEmergencyRenegotiate = composicao.SituacaoValor === SituacaoComposicaoCarga.NegociacaoEmergencial && usesEmergencyNegotiation();

  return (
    <S.InfosNegotiationWrapper>
      <S.Title data-testid={`title`}>{intl.get('commons.negotiationLoadComposition')} - {composicao.IdComposicao}</S.Title>
      <S.InfosContentWrapper>
        <S.TitleWrapper>
          <S.InfosWrapper noScroll>
            <div style={{ display: 'flex' }}>
              <div>
                <div className='content' data-testid={`origin`}>
                  <span className='title'>{intl.get('stocks.timeline.origin')}</span>
                  <span className='description' data-testid={`origin-supplier`}>{composicao.Fornecedor} </span>
                </div>
                <div className='second-content'>
                  <span className='title'>{intl.get('modalRenegotiationLoadComposition.originalDeliveryDateTime')}</span>
                  <span className='description' data-testid='load-composition-delivery-date'>
                    {moment(getValueOrDefault(composicao?.OriginalCompositionValues?.DataEntrega, composicao.DataEntrega)).format("[DM]")}
                    {` ${intl.get('commons.at')} `}
                    {moment(getValueOrDefault(composicao?.OriginalCompositionValues?.HoraEntrega, composicao.HoraEntrega), "HH:mm:ss").format("LT")}
                  </span>
                </div>
              </div>
              <div>
                <div className='content content-right'>
                  <span className='title'>{intl.get('commons.destiny')}</span>
                  <span className='description' data-testid={`destination-brewery`}>{composicao.UnidadeNegocio}</span>
                </div>
              </div>
            </div>
          </S.InfosWrapper>
        </S.TitleWrapper>
        <SupplierInfos
          view
          composicao={composicao}
        />
      </S.InfosContentWrapper>
      <div className="footer-dialog-buttons">
        <div data-testid='load-composition-close'>
          <S.ButtonCancel
            id='button-close'
            type="default"
            value={intl.get('geral.buttonsDefault.close')}
            className="button-cancel"
            onClick={handleClose}
            disabled={isLoading}
          />
        </div>
        <Feature validation={!!isEmergencyRenegotiate && ValidateUserLoggedByType(composicao.UserTypeNextStep)}>
          <S.ButtonEdit
            type="primary"
            value={intl.get("geral.buttonsDefault.accept")}
            onClick={handleAccept}
            disabled={isLoading}
            padding='12px'
          />
        </Feature>
        <div data-testid='load-composition-edit'>
          <S.ButtonEdit
            id='button-renegociar-composicao-carga'
            type={isEmergencyRenegotiate ? "danger" : "primary"}
            value={intl.get(isEmergencyRenegotiate ? "commons.renegotiate" : "loadComposition.editLoadComposition")}
            data-testid='load-composition-delivery-date'
            onClick={handleEditLoad}
            disabled={isLoading}
          />
        </div>
        <div data-testid='load-composition-delete'>
          {!!isEmergencyRenegotiate && !ValidateUserLoggedByType(composicao.UserTypeNextStep) &&
            <ButtonCancelLoadComposition
              idComposicao={composicao.IdComposicao}
              handleCallback={handleCallbackConfirmCancel}
              handleFeedback={handleFeedback}
              disabled={isLoading}
            />
          }
        </div>
      </div>
    </S.InfosNegotiationWrapper>
  )
}

ModalInfosNegotiationLoadComposition.propTypes = {
  composicao: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleEditLoad: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  handleFeedback: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired
}

export default ModalInfosNegotiationLoadComposition;
