import PropTypes from "prop-types"
import React, { useState, useEffect } from "react";
import KcLogin from "features/login"
import "./app.css";
import "@hbsis.uikit/react/dist/uikit.css";
import { withRouter } from 'react-router-dom'
import KcAuthProvider from "providers/kcAuthProvider";

const KcApp = ({ history }) => {
  let redirectUri =  ''
  
  if (!window.location.href.includes('?state')) {
   redirectUri = window.location.pathname
  }
  
  const [realm, setRealm] = useState(null)
  const [isAuthenticated, setIsAuthenticated] = useState(null)

  const handleLogin = async (type) => {
    setRealm(type)
    localStorage.setItem('realm', type)
    setIsAuthenticated(true)
  }

  useEffect(() => {
    const keysInSession = Object.keys(sessionStorage).find(key => key.includes("oidc"))
    const keysInLocal = Object.keys(localStorage).find(key => key.includes("oidc"))
    const kcRealm = localStorage.getItem('realm')

    if (keysInSession || keysInLocal || kcRealm) {
      setIsAuthenticated(true)
    }

  }, [])

  return (
    !isAuthenticated ?
      <KcLogin
        toAmbev={handleLogin}
        toThird={handleLogin}
      /> :
      <KcAuthProvider
        realm={realm}
        history={history}
        url={redirectUri}
      />
  )
};

KcApp.propTypes = {
  history: PropTypes.any.isRequired
}

export default withRouter(KcApp);
