import { Component } from 'react'
import PropTypes from 'prop-types'
import StylesHintList from './styledHintList'
import { Button } from 'components/uikit-adapter/index'
import IconCopy from 'components/icons/icn-copy.icon'
import intl from 'react-intl-universal'

class HintList extends Component {

  constructor(props) {
    super(props)

    this.state = {
      buttonTitle: intl.get('commons.copy')
    }
  }

  copyToClipboard = (_poCodes) => {
    const _poCodesWithoutItemCode = _poCodes.map(codSap => codSap.split(" ")[0]);
    const _normalizedPoCodesWithoutItemCode = [...new Set(_poCodesWithoutItemCode)];
    const _codes = _normalizedPoCodesWithoutItemCode.join(', ');

    if (navigator.clipboard) {
      navigator.clipboard.writeText(_codes);
    }

    this.setState({
      buttonTitle: intl.get('commons.copied')
    })

    setTimeout(() => {
      this.setState({
        buttonTitle: intl.get('commons.copy')
      })
    }, 1000)
  }

  render() {
    return (
      <StylesHintList applyHover={this.props.applyHover} >
        {
          (!this.props.itens || this.props.itens.length === 0) &&
          <div className="hintListComponent notHover">-</div>
        }
        {
          !!this.props.itens && this.props.itens.length === 1 && !this.props.copyItens &&
          <div className="hintListComponent notHover">{this.props.children}</div>
        }
        {
          !!this.props.itens && this.props.itens.length === 1 && this.props.copyItens &&
          <div className="hintListComponent copyButton notHover">
            {this.props.children}
            <div title={this.state.buttonTitle}>
              <Button
                buttonIcon={<IconCopy width={"14px"} height={"14px"} />}
                type="hidden"
                onClick={() => this.copyToClipboard(this.props.itens)}
                width={"16px"}
                height={"16px"}
                className="button-copy-po"
              />
            </div>
          </div>
        }
        {
          !!this.props.itens && this.props.itens.length > 1 && !this.props.copyItens &&
          <div className="hintListComponent">
            <span className="hintListLabelComponent">{this.props.label}</span>
            <div className="hintListItensComponent">
              {this.props.children}
            </div>
          </div>
        }
        {
          !!this.props.itens && this.props.itens.length > 1 && this.props.copyItens &&
          <div className="hintListComponent">
            <div className="hintListLabelComponent">
              <span>
                {this.props.label}
              </span>
              <div title={this.state.buttonTitle}>
                <Button
                  buttonIcon={<IconCopy width={"14px"} height={"14px"} />}
                  type="hidden"
                  onClick={() => this.copyToClipboard(this.props.itens)}
                  width={"16px"}
                  height={"16px"}
                  className="button-copy-po"
                />
              </div>
            </div>
            <div className="hintListItensComponent">
              {this.props.children}
            </div>
          </div>
        }
      </StylesHintList>
    )
  }
}

HintList.defaultProps = {
  label: 'text',
  applyHover: true,
}

HintList.propTypes = {
  label: PropTypes.string,
  applyHover: PropTypes.bool,
  itens: PropTypes.array,
  copyItens: PropTypes.bool,
  children: PropTypes.array
}

export default HintList
