import React from 'react'
import PropTypes from 'prop-types'
import StyledSelectOptions from './styledSelectOptions'

class SelectOption extends React.Component {

  constructor(props) {
    super(props)
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props !== nextProps || this.state !== nextState
  }

  _renderTitles() {
    return (
      <ul className="tabs__labels">
        {
          this.props.children.map((child, indexChildren) => {
            return this.props.children[indexChildren];
          })
        }
      </ul>
    );
  }

  render () {
    return (
      <StyledSelectOptions>
        <div className="tabs">
          {this._renderTitles()}
        </div>
      </StyledSelectOptions>
    );
  }
}

SelectOption.propTypes = {
  selected: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element
  ]).isRequired
}

SelectOption.defaultProps = {
  selected: 0,
}

export default SelectOption

