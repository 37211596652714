import Session from "utils/user-storage";
import Fetch from "utils/fetch";
import { verifyUser } from "../config/user/user.service";

export const getUser = async () => {
  const user = await verifyUser();
  Session.save(user);

  const oldSettings = localStorage.getItem("settings");

  if (!oldSettings) {
    const settings = {
      Redirect: true,
    };
    localStorage.setItem("settings", JSON.stringify(settings));
  }

  return user;
};

export const getTokenAuthorization = () => {
  return Fetch.get(`/users:validation-token`).then((x) => x.data);
};

export const validateErrorSSO = (error) => {
  if (error && error.errorCode === "acquiretoken_progress_error") {
    localStorage.clear();
  }

  const codeCancelError = "AADB2C90091";
  return error && error.errorMessage.startsWith(codeCancelError);
};
