import { useSupplierProductionPlanContext } from "features/supplier-production-plan/context";
import { validatesArrayHasValue, verifyIsFiltered } from "utils/custom-functions";
import * as S from "./styled";
import intl from "react-intl-universal";
import SupplierProductionPlanTable from "../supplier-production-plan-table";

const SupplierProductionPlanContent = () => {
  const { filters, productionPlanData } = useSupplierProductionPlanContext();

  return (
    <>
      {!verifyIsFiltered(filters) && (
        <S.SelectFiltersMessage>{intl.get("supplierProductionPlan.content.selectAFilter")}</S.SelectFiltersMessage>
      )}

      {verifyIsFiltered(filters) && !validatesArrayHasValue(productionPlanData) && 
        <S.NoDataFound>{intl.get("commons.noRecordsFound")}</S.NoDataFound>
      }

      {verifyIsFiltered(filters) && validatesArrayHasValue(productionPlanData) && 
        productionPlanData?.map(plan => (
          <S.TableWrapper key={plan.SupplierId}>
            <S.TableTitle>{plan.SupplierDescription}</S.TableTitle>
            <SupplierProductionPlanTable data={plan?.Items} />
          </S.TableWrapper>
        )
        )
      }
    </>
  );
};

export default SupplierProductionPlanContent;
