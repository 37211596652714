import React from 'react'
import Pagination from 'components/pagination'
import { useLoadCompositionBuildingContext } from '../../context/load-composition-building-context';

function PaginationCards() {
  const {
    filter,
    handleChangePage,
  } = useLoadCompositionBuildingContext();

  const refreshPage = (page) => {
    handleChangePage(page + 1);
  }

  return (
    <Pagination
      page={filter.pagination.page - 1}
      pageSize={filter.pagination.take}
      amount={filter.pagination.total}
      refreshSearch={refreshPage}
    />
  )

}

export default PaginationCards;
