
import intl from 'react-intl-universal';

const supplierValues = {
  COMPRA_DE_INSUMOS: 1,
  TRANSFERENCIA: 2,
  COMPRA_ATG: 3
}

const supplierOrigin = {
  [supplierValues.COMPRA_DE_INSUMOS]: 'commons.supplierOrigin.inputPurchase',
  [supplierValues.TRANSFERENCIA]: 'commons.supplierOrigin.transfer',
  [supplierValues.COMPRA_ATG]: 'commons.supplierOrigin.atgPurchase',
  IsPurchase: (origin) => origin === supplierValues.COMPRA_DE_INSUMOS,
  IsTransfer:(origin) => origin === supplierValues.TRANSFERENCIA,
  IsAtgPurchase: (origin) => origin === supplierValues.COMPRA_ATG,
  getName: function (origin) {
    return intl.get(this[origin])
  },
};

export { supplierOrigin, supplierValues };
