import styled from 'styled-components'

const StyledFilterObsolete = styled.div`
  position: relative; 
    
  .footerFilter {
    position: relative;
    
    .footerFilterContainer {
      width: 100%;
      position: absolute;
      bottom: 0px;
      background: var(--color-contrast-white);
    }
  }
  
  .closeDialog{
    right: 4px;
    padding: 0;
    width: 14px;
    height: 14px;
  }

  .dropDownSearchWalletContainer{
    margin-left: auto;
    display: inline-flex;
    align-items: center;
    -webkit-box-align: center;
    padding-bottom: 15px;
  }

  .dropDownSearchWallet{  
    width: 300px;
  }

  .footerFilterButtons{
    padding: 0px 10px;
  }

  .filter-container {
    position: relative;

    .sideFilterFields {
      overflow-y: scroll;
      height: calc(100vh - 110px);
      background: var(--color-contrast-white);
      padding: 15px 20px 0;
    }

    .footerFilterContainer {
      width: 100%;
      position: absolute;
      bottom: 0px;
      background: var(--color-contrast-white);
      padding: 15px 5px 15px 20px;

      .filter-divisor {
        background-color: rgba(197,208,225,0.6);
        height: 1px;
        width: 100%;
        color: rgba(197,208,225,0.6);
        border: none;
      }
    }
  }
`
export default StyledFilterObsolete
