import styled from 'styled-components'

const RenegociacaoPedidoAtenderDemandaStyled = styled.div`
    padding: 0px 0px 0px 0px;

    div[class='row'] {
        padding-top: 0px !important;
    }

    .renegociacao-pedido-line-buttons {
        width: 100%;
        align-items: right;
        margin-top: 10px;
        margin-left: 40px;
        position: relative;
        display: inline-flex;
    }

    .renegociacao-pedido-line-buttons .button {
        width: 200px;
    }

    .renegociacao-pedido-line-volume {
        display: inline-flex;
        justify-content: right;
        width: 100%;
        margin-left: 50px;
        margin-top: 0px;
        position: relative;
    }
`

export default RenegociacaoPedidoAtenderDemandaStyled