import React, { useCallback, useEffect, useState } from 'react'
import intl from 'react-intl-universal'
import OrdersCards from '../orders-cards'
import OrdersList from '../orders-list'
import OrdersFilter from '../orders-filter'
import OrdersReview from '../orders-review'
import OrdersReviewRequest from '../orders-review-request'
import OrdersResend from '../orders-resend'
import OrdersCancel from '../orders-cancel'
import { OrdersChangeDate } from '../orders-change-date'
import Header from 'components/header'
import VisionOption from 'components/vision-option'
import Pagination from 'components/pagination'
import {
  userAmbev, userFornecedor,
  perfilPcpUnidadeLogado
} from 'services/permissions/getPermissions'
import { useOrdersContext } from '../../context'
import { getVisionType } from 'utils/visionType'
import { getOrders } from '../../services/orders.service'
import { useGlobalContext } from 'hooks/useGlobalContext'
import * as S from './styled'
import { isLoadCompositionFlowSupplierSide } from 'utils/validations-general-settings/index'

export const Orders = () => {
  const {
    items,
    setItems,
    pagination,
    setPagination,
    filter,
    setFilter,
    setLoading,
    setCheckbox
  } = useOrdersContext()

  const { operation } = useGlobalContext()

  const [visionType, setVisionType] = useState(
    localStorage.getItem("visionTypeOrders") || "Grid"
  )

  const handleVisionType = (newVisionType) => {
    setVisionType(newVisionType)
    localStorage.setItem("visionTypeOrders", newVisionType)
  }

  const fetchData = useCallback(async (newFilter = filter) => {
    setLoading(true)
    setCheckbox([])

    try {
      const { Data, CurrentPage, TotalItems, TotalPages } = await getOrders(newFilter)
      setItems(Data)
      setPagination(prevState => ({ ...prevState, CurrentPage, TotalItems, TotalPages }))

    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }, [filter])

  const paginate = (page) => {
    const newFilter = {
      ...filter,
      _page: page,
      _size: 50
    }
    setFilter(newFilter)
  }

  useEffect(() => {
    if (operation.isFinished) {
      fetchData()
    }
  }, [operation])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <React.Fragment>
      <Header title={intl.get('commons.orders')}>
        <S.Actions>
          {userFornecedor() && isLoadCompositionFlowSupplierSide() &&
            <S.ActionButtons >
              <OrdersReviewRequest />
              <OrdersReview />
            </S.ActionButtons>
          }
          <OrdersFilter />
        </S.Actions>
      </Header>
      {items.length > 0 ?
        <React.Fragment>
          <S.Actions>
            {getVisionType(visionType, 'List') &&
              <S.ActionButtons>
                {!perfilPcpUnidadeLogado() && <OrdersResend refresh={fetchData} />}
                <OrdersChangeDate />
                {userAmbev() && !perfilPcpUnidadeLogado()
                  && <OrdersCancel refresh={fetchData} />}
              </S.ActionButtons>
            }
            <VisionOption
              visionType={visionType}
              changeVision={newVisionType => handleVisionType(newVisionType)}
            />
          </S.Actions>
          <S.Container>
            {getVisionType(visionType, 'Grid') && <OrdersCards data={items} />}
            {getVisionType(visionType, 'List') && <OrdersList data={items} />}
            <Pagination
              refreshSearch={paginate}
              page={pagination.CurrentPage}
              pageSize={pagination.size}
              amount={pagination.TotalItems}
            />
          </S.Container>
        </React.Fragment>
        :
        <div style={{ textAlign: 'center', paddingTop: '15px' }}>
          {intl.get('commons.noRecordsFound')}
        </div>
      }
    </React.Fragment>
  )
}
