import { Component } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import moment from 'moment';
import StyledGradeExcedidaList from '../grade-excedida-list.styled';
import Intl from 'react-intl-universal'
import { formatNumber } from 'utils/format';
import PropTypes from 'prop-types'

class GradeExcedidalistItem extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const gradeData = this.props.gradeData;
    return (
      <div>
        <Row middle='xs' className='spotlight-header-row'>
          <Col xs={3}>
            <div className='header-item'>{gradeData.TipoGrade}</div>
          </Col>
          <Col xs={3}>
            <div className='header-item'>
              {gradeData.TipoGrade == 'Carregamento'
                ? gradeData.Fornecedor
                : gradeData.Familia}
            </div>
          </Col>
          <Col xs={2}>
            <div className='header-item'>
              {gradeData.DataColeta
                ? moment(gradeData.DataColeta).format('L')
                : ''}
            </div>
          </Col>
          <Col xs={2}>
            <div className='header-item'>
              {gradeData.DataEntrega
                ? moment(gradeData.DataEntrega).format('L')
                : ''}
            </div>
          </Col>
          <Col xs={2}>
            <div className='header-item item-align'>{gradeData.GradeUso}</div>
          </Col>
        </Row>

        <div>
          <StyledGradeExcedidaList className='grade-excedida-list-header isSubItem'>
            <div>
              <Row>
                <Col xs={1} />
                <Col xs={2}>
                  <div className='header-item'>Status</div>
                </Col>
                <Col xs={3}>
              <div className='header-item'>{Intl.get("manual-suggestion-order-form.amount")}</div>
                </Col>
                <Col xs={3}>
              <div className='header-item item-align'>{Intl.get("grid-vision.gradeExcedida.exceedingTrucks")}</div>
                </Col>
                <Col xs={3} />
              </Row>
            </div>
          </StyledGradeExcedidaList>

          {gradeData.Sugestoes?.map((sugestao, index) => (
            <div className='table-list-item-body' key = {index}>
              <Row middle='xs'>
                <Col xs={1} />
                <Col xs={2}>
                  <div title='Status'>{`${sugestao.SiglaStatusDescricao}`}</div>
                </Col>
                <Col xs={3}>
                  <div title='Quantidade'>{`${formatNumber(sugestao.Quantidade)} ${sugestao.UnidadeMedida}`}</div>
                </Col>
                <Col xs={3}>
                  <div title='Total de Carros Na Sugestão' className='item-align'>
                    {`${sugestao.TotalCarros}`}
                  </div>
                </Col>
                <Col xs={3} />
              </Row>
            </div>
          ))}
          <div>
            <Row>
              <Col xs={12} lg={12} md={12}>
                <hr className='filter-divisor' />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

GradeExcedidalistItem.propTypes = {
  gradeData: PropTypes.shape({
    TipoGrade: PropTypes.string,
    Fornecedor: PropTypes.string,
    Familia: PropTypes.string,
    DataColeta: PropTypes.string,
    DataEntrega: PropTypes.string,
    GradeUso: PropTypes.string,
    Sugestoes: PropTypes.array
  })
}

export default GradeExcedidalistItem;
