
/**
 * @param {*} data Dados da lista
 * @param {*} scope Escopo da tela (Pedido, PedidoVolume, Sugestão)
 * @returns Retorna validadores para os checkbox caso existam.
 * Caso precisar adicionar mais validators, criar nessa classe
 * uma implementação da sua validação para os Escopos necessários.
 */

export const rulesForCheckboxAll = (data, selected, scope) => {
  if (scope === 'OrderVolume') {
    const acceptableItems = data.filter(item => item.CanAccept)
    const checked = selected.length === acceptableItems.length
    return checked && acceptableItems.length > 0
  }

  if (scope === 'Orders' || scope === 'Suggestions') {
    return selected.length === data.length
  }

  return false
}

export const rulesForCheckbox = (record, selected, scope) => {
  if (scope === 'OrderVolume') {
    return selected.includes(record.Id) && record["CanAccept"]
  }

  if (scope === 'Orders' || scope === 'Suggestions') {
    return selected.includes(record.Id)
  }

  return false
}
