import styled from 'styled-components'

export const Wrapper = styled.div`
  button{
    margin-top: 0;
  }
  max-height: 550px;
  overflow-y: auto;
`
export const TitleWrapper = styled.div`
  position: sticky;
  background-color: white;
  z-index: 1;
  top: 0;
`

export const BottomWrapper = styled.div`
  position: sticky;
  background-color: white;
  z-index: 1;
  bottom: 0;
`
export const Title = styled.div`
  color: var(--Contrast-Brand, #3B495E);
  font-family: Arial;
  font-size: 24px;
  font-weight: var(--font-weight-semibold);
  line-height: normal;
`
export const Subtitle = styled.div`
  color: var(--Contrast-Brand, #3B495E);
  font-family: Arial;
  font-size: 16px;
  font-weight: var(--font-weight-semibold);
  line-height: normal;
`

export const WrapperContent = styled.div`
  display: flex;
  padding-top: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`

export const Row = styled.div`
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  display: grid;
  grid-auto-columns: 2;
  grid-template-columns: 150px 1fr;
`
export const Warning = styled.div`
  color: var(--Contrast-Brand, #3B495E);
  text-align: center;
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: var(--font-weight-regular);
  line-height: normal;
  padding: 16px 0px
`

export const Actions = styled.div`
  display: flex;
  justify-content: center;
`
export const RowList = styled.div`
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  display: grid;
  grid-template-columns: 1fr;

  .rounded{
    width: 5px;
    height: 5px;
    background-color: var(--color-neutral-400);
    border-radius: 50%;
    margin: 0px 5px;
    align-self: center;
  }

  span{
    color: var(--color-neutral-400);
    font-size: 12px;
    font-weight: 400;
  }
`

export const Flex = styled.div`
  display: flex;
`