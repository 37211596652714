import React, { Component } from 'react'
import PropTypes from 'prop-types'
import StepPoint from './../step-point'
import moment from 'moment'
import Fetch from 'utils/fetch'
import Session from 'utils/user-storage'
import { formatErrorMessage } from 'utils/handle-error'
import Message from 'components/message'
import NumeroSap from 'components/timeline/numero-sap'
import NotasFiscais from 'components/timeline/notas-fiscais'
import AceiteReadOnly from 'components/timeline/aceite-readonly'
import AceiteFornecedor from 'components/timeline/aceite-fornecedor'
import AtualizacaoVolume from 'components/timeline/atualizacao-volume'
import PedidoVolumeAmbev from 'components/timeline/pedido-volume-ambev'
import AlterarPedidoAmbev from 'components/timeline/alterar-pedido'
import PedidoVolumeReadOnly from 'components/timeline/pedido-volume-readonly'
import AceiteVolumeFornecedor from 'components/timeline/aceite-volume-fornecedor'
import AnteciparAtrasarPedido from 'components/timeline/antecipar-atrasar-pedido'
import LoadCompositionNumber from 'components/timeline/load-composition-number'
import LoadCompositionOrderNumber from 'components/timeline/load-composition-order-number'
import PickUpDeliveryDateDeliveryTime from 'components/timeline/pickup-delivery-date-delivery-time'
import SuggestedDeliveryDateTime from 'components/timeline/suggested-delivery-date-time'
import AlterarPedidoFornecedor from 'components/timeline/alterar-pedido-fornecedor'
import AguardandoComposicaoCarga from 'components/timeline/aguardando-composicaocarga'
import AguardandoAprovacaoGestor from 'components/timeline/aguardando-aprovacao-gestor'
import AguardandoCiencia from 'components/timeline/aguardando-ciencia'
import AceiteContraPropostaEmergencial from 'components/timeline/aceite-contraproposta-emergencial'
import OrigemAlteradaPedido from 'components/timeline/origem-alterada-pedido'
import DestinoAlteradoPedido from 'components/timeline/destino-alterado-pedido'
import MaterialAlteradoPedido from 'components/timeline/material-alterado-pedido'
import IncotermAlteradoPedido from 'components/timeline/incoterm-alterado-pedido'
import RenegociacaoPedidoAtenderDemanda from 'components/timeline/renegociacao-pedido-atender-demanda'
import AceiteReadOnlyRenegociacaoPedido from 'components/timeline/renegociacao-pedido-atender-demanda/aceite-read-only'
import EmCancelamentoEvent from 'components/timeline/em-cancelamento'
import intl from 'react-intl-universal'
import './event-timeline.css'
import { Button } from 'components/uikit-adapter/index'
import { confirmCancellationAwaitingCheck } from '../../../pages/stocks-detail/stocks-detail.service'
import AceiteAtualizado from '../components/pedidoVolume/aceiteAtualizado'
import { StatusPedido } from '../../../models/pedidos/statusPedido'
import { isLoadCompositionFlowBrewerySide, isScheduleAgreementFlow } from 'utils/validations-general-settings'
import { parseFloatByLocale } from 'locales/utils'
import { isUserSupplier } from 'utils/user'
import { OrderNegotiationTypes } from 'models/pedidos/orderNegotiationTypes'

const TIPO_PEDIDO_COMPRA = {
  PEDIDO_COMPRA: 1,
  PEDIDO_COMPRA_ATIVO_GIRO: 3
}

const TIPO_NEGOCIACAO_VOLUME = {
  VOLUME_NEGOCIACAO: 2,
  VOLUME_ACEITO_PARCIALMENTE: 3,
  AGUARDANDO_CONFIRMACAO_RECUSA_AMBEV: 4,
  VOLUME_RECUSADO: 5,
  AGUARDANDO_CONFIRMACAO_ACEITE_PARCIAL_AMBEV: 10,
  VOLUME_PEDIDO_ATUALIZADO: 13,
  VOLUME_NEGOCIACAO_VIA_AUTOPILOT: 14
}

class EventTimeline extends Component {
  constructor(props) {
    super(props)

    this.state = {
      actionType: '',
      showCounterOffer: false,
      acceptRequest: false,
      showMsg: false,
      textMsg: '',
      titleMsg: '',
      requestCounter: 0
    }
  }


  isShowUpdateOrder = (isSupplierUser, isCurrent, isPurchaseOrder, orderStatus) => {
    return !isSupplierUser
      && isCurrent
      && isPurchaseOrder
      && (!this.props.isNewFlow || orderStatus === StatusPedido.AguardandoComposicaoCarga);
  }

  isAutomaticQuantityUpdate = (orderType, orderStatus) =>
    (orderType === TIPO_PEDIDO_COMPRA.PEDIDO_COMPRA
      || orderType === TIPO_PEDIDO_COMPRA.PEDIDO_COMPRA_ATIVO_GIRO)
    && orderStatus === StatusPedido.AguardandoAvisoRecebimento;

  showNegotiationButtons = (orderType, orderStatus) => {
    return !(isLoadCompositionFlowBrewerySide() && isUserSupplier()) && !this.isAutomaticQuantityUpdate(orderType, orderStatus);
  }

  isDisableCounterProposal = (isSupplierUser) => {
    return this.props.isNewFlow && isSupplierUser;
  }

  isDisableAcceptDemand = (isSupplierUser) => {
    return this.props.isNewFlow && isSupplierUser;
  }

  isDisableAwaitingLoadComposition = (orderStatus) => {
    return this.props.isNewFlow && orderStatus === StatusPedido.AguardandoComposicaoCarga;
  }

  validateCounterProposalExibition = () => {
    return !(isLoadCompositionFlowBrewerySide() && isUserSupplier());
  }

  getDateFormatted = (negotiationType, dataColeta, dataEntrega) => negotiationType == OrderNegotiationTypes.AnteciparColetaPedido || negotiationType == OrderNegotiationTypes.AtrasarColetaPedido ? moment(dataColeta).format('L') : moment(dataEntrega).format('L');

  renderHeader = ({
    Quantidade,
    UnidadeMedida,
    NomeFornecedor,
    NomeFornecedorAnterior,
    DataColetaPrevista,
    DataEntregaPrevista,
    Observacao,
    DescricaoMaterialAnterior,
    DescricaoMaterialNovo,
    UnidadeNegocio,
    UnidadeNegocioAnterior,
    HoraEntrega,
    LinhaDeProducao,
    PortaWMS,
    Comentarios
  },

  ) => {
    return (
      <div style={{ marginLeft: '40px', marginBottom: '10px' }}>
        <AceiteReadOnly
          qtde={Quantidade}
          unidadeMedida={UnidadeMedida}
          nomeFornecedor={NomeFornecedor}
          nomeFornecedorAnterior={NomeFornecedorAnterior}
          dataColeta={moment(DataColetaPrevista).format('L')}
          data={moment(DataEntregaPrevista).format('L')}
          observacao={Observacao}
          material={DescricaoMaterialNovo}
          materialAnterior={DescricaoMaterialAnterior}
          unidadeNegocio={UnidadeNegocio}
          unidadeNegocioAnterior={UnidadeNegocioAnterior}
          horaEntrega={HoraEntrega}
          linhaDeProducao={LinhaDeProducao}
          portaWMS={PortaWMS}
          comentarios={Comentarios}
        />
      </div>
    )
  }

  formatDate = (date) => moment(date, 'DD/MM/YYYY');

  handleConfirmCancellationAwaitingCheck = async () => {
    const { idPedido, refreshTimeLine } = this.props
    await confirmCancellationAwaitingCheck(idPedido)
    refreshTimeLine()
  }

  loadEvent = (data) => {
    const user = Session.get()
    const USUARIO_AMBEV = user.TipoUsuario === 1
    const USUARIO_FORNECEDOR = user.TipoUsuario === 2
    const {
      Quantidade,
      QuantidadeAnterior,
      UnidadeMedida,
      DataColetaDesejavel,
      DataColetaPrevista,
      DataEntregaDesejavel,
      DataEntregaPrevista,
      Observacao,
      IdFornecedor,
      NomeFornecedor,
      IdComposicao,
      TipoUsuarioResponsavelProximaEtapa,
      TipoNegociacaoIndex,
      Atual,
      CodigoPedidoSap,
      CodigoPedidoSapItem,
      IsPedidoVolume,
      TipoPedido,
      NomeUsuario,
      IdUnidadeNegocio,
      IdMaterial,
      HoraEntrega,
      WmsTransactionId,
      IdLoadCompositionNegotiation,
      NomeFornecedorAnterior
    } = data
    const sapOrderCode = () => isScheduleAgreementFlow() ? WmsTransactionId : CodigoPedidoSap;
    const pedidoCompra = TipoPedido === TIPO_PEDIDO_COMPRA.PEDIDO_COMPRA

    let temAcao = !(!Atual || user.TipoUsuario !== TipoUsuarioResponsavelProximaEtapa);
    const showLoadComposition = () => ((USUARIO_FORNECEDOR || !pedidoCompra) && IdComposicao == null && Atual);

    // TIMELINE PARA VOLUME
    if (IsPedidoVolume) {
      switch (TipoNegociacaoIndex) {
        case TIPO_NEGOCIACAO_VOLUME.VOLUME_NEGOCIACAO_VIA_AUTOPILOT:
        case TIPO_NEGOCIACAO_VOLUME.VOLUME_NEGOCIACAO:
          return (
            <div>
              {!!temAcao && this.renderHeader(this.props.data)}
              {!!temAcao &&
                <div className="table-list-footer">
                  <AceiteVolumeFornecedor
                    {...this.props}
                    quantidade={Quantidade}
                    aceiteTotal={this.aceiteTotal}
                    recusa={this.recusa}
                    startLoading={() => this.props.handleFetch()}
                    stopLoading={() => this.props.handleFetch(false)}
                  />
                </div>
              }
            </div>
          )
        case TIPO_NEGOCIACAO_VOLUME.VOLUME_ACEITO_PARCIALMENTE:
        case TIPO_NEGOCIACAO_VOLUME.VOLUME_RECUSADO:
          return (
            <div>
              {!temAcao &&
                <div style={{ marginLeft: '40px', marginBottom: '10px' }}>
                  <PedidoVolumeReadOnly observacao={this.props.data.Observacao} />
                </div>
              }
            </div>
          )
        case TIPO_NEGOCIACAO_VOLUME.AGUARDANDO_CONFIRMACAO_RECUSA_AMBEV:
        case TIPO_NEGOCIACAO_VOLUME.AGUARDANDO_CONFIRMACAO_ACEITE_PARCIAL_AMBEV:
          return (
            <div>
              {!!temAcao && this.renderHeader(this.props.data)}
              {!!temAcao &&
                <div style={{ marginLeft: '40px', marginBottom: '10px' }}>
                  <PedidoVolumeAmbev
                    ciencia={this.ciencia}
                    idPedido={this.props.idPedido}
                  />
                </div>
              }
            </div>
          )
        case TIPO_NEGOCIACAO_VOLUME.VOLUME_PEDIDO_ATUALIZADO:
          return (
            <AceiteAtualizado
              data={this.props.data}
            />
          )
        default:
          return null
      }
    }

    // TIMELINE PARA PEDIDO
    switch (TipoNegociacaoIndex) {
      case 1: // EnviadoFornecedor
      case 54: // PedidoEditado
      case 55: // PedidoAtualizadoViaSap
        return (
          <div>
            {this.renderHeader(this.props.data)}
            {WmsTransactionId && !USUARIO_FORNECEDOR &&
              <AguardandoComposicaoCarga
                {...this.props}
                handleAcceptOffer={this.acceptOffer}
                handleAcceptCounterOffer={this.acceptCounterOffer}
                quantidade={Quantidade}
                unidadeMedida={UnidadeMedida}
                dataEntregaPrevista={DataEntregaPrevista}
                observacao={Observacao}
                idPedido={this.props.idPedido}
                canShowContraproposta={false}
                canShowUpdateOrder={this.isShowUpdateOrder(USUARIO_FORNECEDOR, Atual, pedidoCompra, this.props.data.StatusPedido)}
                canShowComposicaoCarga={showLoadComposition()}
                nomeFornecedor={NomeFornecedor}
                idFornecedor={IdFornecedor}
                horaEntrega={HoraEntrega}
              />
            }
            {temAcao && this.showNegotiationButtons(TipoPedido, this.props.data.StatusPedido) &&
              <AceiteFornecedor
                {...this.props}
                quantidade={Quantidade}
                unidadeMedida={UnidadeMedida}
                dataColetaPrevista={DataColetaPrevista}
                dataEntregaPrevista={DataEntregaPrevista}
                horaEntrega={HoraEntrega}
                handleUpdateActionType={this.updateActionType}
                observacao={Observacao}
                idFornecedor={IdFornecedor}
                disabledCounterProposal={this.isDisableCounterProposal(USUARIO_FORNECEDOR)}
                disabledAcceptDemand={this.isDisableAcceptDemand(USUARIO_FORNECEDOR)}
              />
            }
          </div>
        )
      case 3: //ContraProposta
        return (
          <div>
            {this.renderHeader(this.props.data)}
            {temAcao && this.showNegotiationButtons(TipoPedido, this.props.data.StatusPedido) &&
              <AceiteFornecedor
                {...this.props}
                quantidade={Quantidade}
                unidadeMedida={UnidadeMedida}
                dataColetaPrevista={DataColetaPrevista}
                dataEntregaPrevista={DataEntregaPrevista}
                horaEntrega={HoraEntrega}
                handleUpdateActionType={this.updateActionType}
                observacao={Observacao}
                idFornecedor={IdFornecedor}
              />
            }
          </div>
        )
      case 2: // AceitoFornecedor
      case 5: // Aprovado
      case 7: // AceiteContraPropostaEmergencial
      case 8: // Recusado
        return (
          <div>
            {this.renderHeader(this.props.data)}
            <AguardandoComposicaoCarga
              {...this.props}
              handleAcceptOffer={this.acceptOffer}
              handleAcceptCounterOffer={this.acceptCounterOffer}
              quantidade={Quantidade}
              unidadeMedida={UnidadeMedida}
              dataEntregaPrevista={DataEntregaPrevista}
              observacao={Observacao}
              idPedido={this.props.idPedido}
              canShowContraproposta={Atual && pedidoCompra && this.validateCounterProposalExibition()}
              disabledCounterProposal={this.isDisableAwaitingLoadComposition(this.props.data.StatusPedido)}
              canShowComposicaoCarga={showLoadComposition()}
              disabledLoadComposition={this.isDisableAwaitingLoadComposition(this.props.data.StatusPedido)}
              nomeFornecedor={NomeFornecedor}
              idFornecedor={IdFornecedor}
              horaEntrega={HoraEntrega}
            />
          </div>
        )
      case 4: // EmNegociacao
        return (
          <div>
            {this.renderHeader(this.props.data)}
          </div>
        )
      case 6: // ContraPropostaEmergencialEnviadoFornecedor
      case 14: // ContraPropostaEmergencialEnviadoAmbev
        return (
          <div>
            {this.renderHeader(this.props.data)}
            {temAcao &&
              <AceiteContraPropostaEmergencial
                qtde={Quantidade}
                unidadeMedida={UnidadeMedida}
                data={moment(DataEntregaPrevista).format('L')}
                observacao={Observacao}
                idPedido={this.props.idPedido}
                nomeFornecedor={NomeFornecedor}
                idFornecedor={IdFornecedor}
              />
            }
          </div>
        )
      case 10: // EmNegociacaoComposicaoCarga (Order inserted in load composition)
      case 11: // ContraPropostaComposicaoCargaAmbev (Order inserted in load composition)
      case 59: // OrderRemovedFromLoadComposition
      case 60: // LoadCompositionCanceled
      case 74: // Order Removed Through Emergency Negotiation
      case 75: // Order inserted in load composition Through EmergencyNegotiation 
        return (
          <div style={{ marginLeft: '40px', marginBottom: '10px' }}>
            <LoadCompositionNumber
              idLoadComposition={IdLoadCompositionNegotiation}
            />
          </div>
        )
      case 12: // AceiteComposicaoCarga
        return (
          <div style={{ marginLeft: '40px', marginBottom: '10px' }}>
            <LoadCompositionOrderNumber
              idLoadComposition={IdLoadCompositionNegotiation}
              idOrder={this.props.idPedido}
            />
          </div>
        )
      case 16: // OrderCanceledPortal
        return (
          <div>
            {CodigoPedidoSap &&
              <NumeroSap
                codigoPedidoSap={sapOrderCode()}
                codigoPedidoSapItem={CodigoPedidoSapItem}
              />
            }
          </div>
        )
      case 67: // OrderCanceledSap
        return (
          <NumeroSap
            codigoPedidoSap={sapOrderCode()}
            codigoPedidoSapItem={CodigoPedidoSapItem}
          />
        )
      case 18: // AnteciparColetaPedido
      case 19: // AtrasarColetaPedido
      case 20: // AnteciparEntregaPedido
      case 21: // AtrasarEntregaPedido
        return (
          <div style={{ marginLeft: '40px', marginBottom: '10px' }}>
            <AnteciparAtrasarPedido
              dataAnterior={this.getDateFormatted(TipoNegociacaoIndex, DataColetaDesejavel, DataEntregaDesejavel)}
              dataAtual={this.getDateFormatted(TipoNegociacaoIndex, DataColetaPrevista, DataEntregaPrevista)}
              horaEntrega={HoraEntrega}
            />
          </div>
        )
      case 22: // VolumeAtualizado
      case 69: // QuantityUpdated
        return (
          <div style={{ marginLeft: '40px', marginBottom: '10px' }}>
            <AtualizacaoVolume
              quantidadeAnterior={QuantidadeAnterior}
              quantidadeAtual={Quantidade}
            />
          </div>
        )
      case 28: // OrigemAlterada
        return (
          <div style={{ marginLeft: '40px', marginBottom: '10px' }}>
            <OrigemAlteradaPedido
              origemAnterior={NomeFornecedorAnterior}
              origemAtual={NomeFornecedor}
              usuario={NomeUsuario}
              codigoSap={CodigoPedidoSap}
            />
          </div>
        )
      case 23: // AguardandoPedidoSap
      case 24: // ErroAoCriarPedidoSap
      case 15: // NumeroSap
      case 29: // SolicitacaoUrgenteAlteracaoCancelada
      case 30: // SolicitacaoUrgenteAlteracaoRecusada
      case 31: // SolicitacaoUrgenteAlteracaoAceita
      case 34: // AnteciparColetaPedidoGrade
      case 35: // AtrasarColetaPedidoGrade
      case 36: // AnteciparEntregaPedidoGrade
      case 37: // AtrasarEntregaPedidoGrade
      case 992: // Aguardando Fornecimento
        return (
          <div>
            {TipoNegociacaoIndex == 15 &&
              <NumeroSap
                codigoPedidoSap={sapOrderCode()}
                codigoPedidoSapItem={CodigoPedidoSapItem}
              />
            }

            {USUARIO_AMBEV && Atual && (pedidoCompra || (!pedidoCompra && TipoNegociacaoIndex == 992)) &&
              <AlterarPedidoAmbev
                idPedido={this.props.data.IdPedido}
                materialAnterior={this.props.data.IdMaterial}
                materialAtivo={true}
                readOnly={false}
                handleFetch={this.props.handleFetch}
                handleFeedback={this.props.handleFeedback}
                refreshTimeLine={this.props.refreshTimeLine}
              />
            }
          </div>
        )
      case 27: //SolicitacaoUrgenteAlteracao
        return (
          <div>
            {USUARIO_FORNECEDOR && Atual && (
              <AlterarPedidoFornecedor
                handleAccept={this.aceitarSolicitacaoAlteracaoPedido}
                handleRefuse={this.recusarSolicitacaoAlteracaoPedido}
                idPedido={this.props.data.IdPedido}
                idMaterialAnterior={this.props.data.IdMaterialAnterior}
                descricaoMaterialAnterior={this.props.data.DescricaoMaterialAnterior}
                idMaterialNovo={this.props.data.IdMaterialNovo}
                descricaoMaterialNovo={this.props.data.DescricaoMaterialNovo}
              />
            )}

            {USUARIO_AMBEV && Atual &&
              <AlterarPedidoAmbev
                descricaoMaterialAnterior={this.props.data.DescricaoMaterialAnterior}
                descricaoMaterialNovo={this.props.data.DescricaoMaterialNovo}
                readOnly={true}
                idPedido={this.props.data.IdPedido}
                handleCancelar={this.cancelarSolicitacaoAlteracaoPedido}
              />
            }
          </div>
        )

      case 40: //DestinoAlterado
        return (
          <div style={{ marginLeft: '40px', marginBottom: '10px' }}>
            <DestinoAlteradoPedido
              unidadeNegocioAnterior={this.props.data.UnidadeNegocioAnterior}
              unidadeNegocio={this.props.data.UnidadeNegocio}
              usuario={NomeUsuario}
              codigoSap={CodigoPedidoSap}
            />
          </div>
        )

      case 41: //MaterialAlterado
        return (
          <div style={{ marginLeft: '40px', marginBottom: '10px' }}>
            <MaterialAlteradoPedido
              descricaoMaterialAnterior={this.props.data.DescricaoMaterialAnterior}
              descricaoMaterialNovo={this.props.data.DescricaoMaterialNovo}
              usuario={NomeUsuario}
              codigoSap={CodigoPedidoSap}
            />
          </div>
        )

      case 42:
        return (
          <div>
            <AguardandoAprovacaoGestor
              {...this.props}
              aprovarRecusarPedidoOver={this.aprovarRecusarPedidoOver}
              quantidade={Quantidade}
              unidadeMedida={UnidadeMedida}
              dataEntregaPrevista={DataEntregaPrevista}
              idPedido={this.props.idPedido}
              canShowContraproposta={(Atual && pedidoCompra && this.validateCounterProposalExibition())}
              canShowComposicaoCarga={showLoadComposition()}
              nomeFornecedor={NomeFornecedor}
              idFornecedor={IdFornecedor}
            />
          </div>
        )

      case 43:
        return (
          <div>
            <AguardandoCiencia
              {...this.props}
              registrarCiencia={this.registrarCiencia}
            />
          </div>
        )

      case 46: //Incoterm Alterado
        return (
          <div style={{ marginLeft: '40px', marginBottom: '10px' }}>
            <IncotermAlteradoPedido
              incotermAnterior={this.props.data.IncotermAnterior}
              incoterm={this.props.data.Incoterm}
            />
          </div>
        )

      case 993: // FaturamentoInterno
      case 994: // AvisoDeRecebimento
      case 995: // NotaRecebimentoInspecao
      case 996: // EntradaMercadoria
        return (
          <div>
            <NotasFiscais
              notasFiscais={this.props.data.NotasFiscais}
            />
          </div>
        )

      case 47: // RenegociacaoDePedidoParaAtenderDemanda
        return (
          <div>
            <div style={{ marginLeft: '40px', marginBottom: '10px' }}>
              <AceiteReadOnlyRenegociacaoPedido
                dataColeta={moment(DataColetaPrevista).format('L')}
                dataEntrega={moment(DataEntregaPrevista).format('L')}
                horaEntrega={HoraEntrega}
                quantidadeAnterior={QuantidadeAnterior}
                quantidadeAtual={Quantidade}
              />
            </div>
            {temAcao &&
              <RenegociacaoPedidoAtenderDemanda
                idPedido={this.props.idPedido}
                idOrigem={IdFornecedor}
                idDestino={IdUnidadeNegocio}
                idMaterial={IdMaterial}
                dataColeta={DataColetaPrevista}
                dataEntrega={DataEntregaPrevista}
                horaEntrega={HoraEntrega}
                quantidade={Quantidade}
                observacao={Observacao}
              />
            }
          </div>
        )

      case 48: // RenegociacaoDePedidoParaAtenderDemandaAceito
      case 49: // RenegociacaoDePedidoParaAtenderDemandaRecusado
        return (

          <div>
            <div style={{ marginLeft: '40px', marginBottom: '10px' }}>
              <AceiteReadOnlyRenegociacaoPedido
                dataColeta={moment(DataColetaPrevista).format('L')}
                dataEntrega={moment(DataEntregaPrevista).format('L')}
                horaEntrega={HoraEntrega}
                quantidadeAnterior={QuantidadeAnterior}
                quantidadeAtual={Quantidade}
              />
            </div>
          </div>
        )
      case 50:
        return (
          <EmCancelamentoEvent
            showActionsCancellation={this.showActionsCancellation(USUARIO_FORNECEDOR)}
            refreshTimeLine={this.props.refreshTimeLine}
            codigoPedidoSap={sapOrderCode()}
            codigoPedidoSapItem={CodigoPedidoSapItem}
            responseDeadline={this.formatDate(data.DataStatus).add('days', 3).format('L')}
            idPedido={this.props.idPedido}
          />
        )
      case 51:
        return (
          <EmCancelamentoEvent
            awaitingCheckRefused={this.showActionsCancellation(USUARIO_AMBEV)}
            showActionsCancellation={false}
            refreshTimeLine={this.props.refreshTimeLine}
            codigoPedidoSap={sapOrderCode()}
            codigoPedidoSapItem={CodigoPedidoSapItem}
            responseDeadline={this.formatDate(data.DataStatus).add('days', 3).format('L')}
            idPedido={this.props.idPedido}
          />
        )
      case 52: // PedidoCanceladoAguardandoCiencia
        return (
          <React.Fragment>
            <NumeroSap
              codigoPedidoSap={sapOrderCode()}
              codigoPedidoSapItem={CodigoPedidoSapItem}
            />
            {
              Atual && USUARIO_FORNECEDOR &&
              <Button
                value={'OK'}
                type="primary"
                width={"166px"}
                className="button_ok_cancel"
                onClick={this.handleConfirmCancellationAwaitingCheck}
              />
            }
          </React.Fragment>
        )
      case 53:
        return (
          <EmCancelamentoEvent
            showActionsCancellation={false}
            refreshTimeLine={this.props.refreshTimeLine}
            codigoPedidoSap={sapOrderCode()}
            codigoPedidoSapItem={CodigoPedidoSapItem}
            responseDeadline={this.formatDate(data.DataStatus).add('days', 3).format('L')}
            idPedido={this.props.idPedido}
          />
        )
      case 61: // OrderDatesTimeUpdateThroughLoadComposition
        return (
          <div style={{ marginLeft: '40px', marginBottom: '10px' }}>
            <PickUpDeliveryDateDeliveryTime
              pickUpDate={DataColetaPrevista}
              deliveryDate={DataEntregaPrevista}
              deliveryTime={HoraEntrega}
            />
          </div>
        )
      case 62: // SupplierNegotiationReceived
        return (
          <div style={{ marginLeft: '40px', marginBottom: '10px' }}>
            <SuggestedDeliveryDateTime
              suggestedDeliveryDate={DataEntregaPrevista}
              suggestedDeliveryTime={HoraEntrega}
            />
          </div>
        )
      case 73: // Order Changed Througt emergency negotiation
        return (
          <div>
            {this.renderHeader({ ...this.props.data, PortaWMS: null })}
            <AguardandoComposicaoCarga
              {...this.props}
              handleAcceptOffer={this.acceptOffer}
              handleAcceptCounterOffer={this.acceptCounterOffer}
              quantidade={Quantidade}
              unidadeMedida={UnidadeMedida}
              dataEntregaPrevista={DataEntregaPrevista}
              observacao={Observacao}
              idPedido={this.props.idPedido}
              canShowContraproposta={false}
              canShowUpdateOrder={false}
              canShowComposicaoCarga={false}
              nomeFornecedor={NomeFornecedor}
              idFornecedor={IdFornecedor}
              horaEntrega={HoraEntrega}
            />
          </div>
        )
      default:
        return null
    }
  }

  showActionsCancellation = (typeUserOk) => typeUserOk && this.props.data.Atual

  updateActionType = (actionType) => {
    this.setState({ actionType })
  }

  acceptOffer = (qtde, unidadeMedida, data, observacao, fornecedor) => {
    const user = Session.get();
    const USUARIO_AMBEV = user.TipoUsuario === 1;

    const endpoint = USUARIO_AMBEV
      ? '/timeline/aceite/comprador'
      : '/timeline/aceite/fornecedor';

    const {
      Quantidade,
      DataEntregaPrevista,
      Observacao,
      IdFornecedor
    } = this.props.data;

    const model = {
      IdPedido: this.props.idPedido,
      Quantidade: parseFloatByLocale(qtde || Quantidade),
      UnidadeMedida: unidadeMedida,
      DataEntrega: data || DataEntregaPrevista,
      IdUsuario: user.Id,
      Observacao: observacao || Observacao,
      IdFornecedor: fornecedor || IdFornecedor
    };

    Fetch.post(endpoint, model)
      .then(this.props.handleFeedback(intl.get('stocks.timeline.feedback.orderAccept')))
      .catch((e) => this.proccessErro(e))
      .finally(this.props.refreshTimeLine());
  }

  aprovarRecusarPedidoOver = (isAprovado, observacao) => {
    const user = Session.get()
    Fetch.post(`/pedido/aprovarRecusarPedidoOver`,
      {
        IdPedido: this.props.idPedido,
        isAprovado,
        observacao,
        IdUsuario: user.Id,
      })
      .then(this.props.refreshTimeLine())
      .then(this.props.handleFeedback(isAprovado ? intl.get('stocks.timeline.feedback.orderAprovedSuccess') : intl.get('stocks.timeline.feedback.orderRefuse')))
      .catch(e => this.proccessErro(e))
      .finally(() => this.props.handleFetch(false))
  }

  registrarCiencia = () => {
    const user = Session.get()
    Fetch.post(`/pedido/registrarCienciaPedidoOverRecusado`,
      {
        IdPedido: this.props.idPedido,
        IdUsuario: user.Id,
      })
      .then(this.props.refreshTimeLine())
      .then(this.props.handleFeedback(intl.get('stocks.timeline.feedback.scienceSuccessfullyRegistered')))
      .catch(e => this.proccessErro(e))
      .finally(() => this.props.handleFetch(false))
  }

  acceptCounterOffer = (qtde, unidadeMedida, data, observacao, fornecedor) => {
    const user = Session.get()
    const USUARIO_AMBEV = user.TipoUsuario === 1;
    const endpoint = USUARIO_AMBEV
      ? '/timeline/contraproposta/comprador'
      : '/timeline/contraproposta/fornecedor'

    const {
      Quantidade,
      DataEntregaPrevista,
      IdFornecedor
    } = this.props.data;

    const model = {
      IdPedido: this.props.idPedido,
      Quantidade: parseFloatByLocale(qtde || Quantidade),
      UnidadeMedida: unidadeMedida,
      DataEntrega: data || DataEntregaPrevista,
      IdUsuario: user.Id,
      Observacao: observacao || '',
      IdFornecedor: fornecedor || IdFornecedor
    };

    Fetch.post(endpoint, model)
      .then(this.props.refreshTimeLine())
      .then(this.props.handleFeedback(intl.get('stocks.timeline.feedback.sendCounterproposal')))
      .catch((e) => this.proccessErro(e))
  }

  aceiteTotal = (idPedido) => {
    const user = Session.get();

    this.props.handleFetch();

    Fetch.post(`/timeline/pedidoVolume/aceiteTotal/${user.Id}/${idPedido}`)
      .then(async (response) => {
        this.props.handleFeedback(intl.get('stocks.timeline.feedback.pendingVolumeSuccessfullyAccepted'))
        this.props.refreshTimeLine()
      })
      .catch((retorno) => { this.props.handleFeedback(retorno.response.data.Message) })
      .finally(this.props.handleFetch(false));
  }

  recusa = (idPedidoVolume, observacao) => {
    this.props.handleFetch();
    const user = Session.get();

    if (observacao == '') {
      this.props.handleFeedback(intl.get('stocks.timeline.feedback.observationMandatory'))
    } else {
      Fetch.post(`/timeline/pedidoVolume/recusa`,
        {
          IdUsuario: user.Id,
          IdPedidoVolume: idPedidoVolume,
          Observacao: observacao,
        })
        .then(() => { this.props.refreshTimeLine(); this.props.handleFeedback(intl.get('stocks.timeline.feedback.declineSent')) })
        .catch(e => this.proccessErro(e))
        .finally(() => this.props.handleFetch(false))
    }
  }

  ciencia = (idPedidoVolume) => {
    this.props.handleFetch()
    const user = Session.get()

    Fetch.post(`/timeline/pedidoVolume/confirmarciencia/${user.Id}/${idPedidoVolume}`)
      .then(() => { this.props.refreshTimeLine(); this.props.handleFeedback(intl.get('stocks.timeline.feedback.scienceConfirmed')) })
      .catch(e => this.proccessErro(e))
      .finally(() => this.props.handleFetch(false))
  }

  proccessErro = (exception) => {
    this.showFeedback(intl.get('stocks.timeline.feedback.errorFound'), (exception.response.status === 400) ? formatErrorMessage(exception) : intl.get('stocks.timeline.feedback.serverError'))
  }

  showFeedback = (title, message) => {
    this.setState({
      showMsg: true,
      titleMsg: title,
      textMsg: message,
    })
  }

  aceitarSolicitacaoAlteracaoPedido = (idPedido, idMaterialAnterior, idMaterialNovo, observacao) => {
    this.props.handleFetch()
    const user = Session.get()

    Fetch.post(`/timeline/aceitarSolicitacaoAlteracaoPedido`,
      {
        IdPedido: idPedido,
        IdUsuario: user.Id,
        IdMaterialAnterior: idMaterialAnterior,
        IdMaterialNovo: idMaterialNovo,
        Observacao: observacao
      })
      .then(this.props.refreshTimeLine())
      .then(this.props.handleFeedback(intl.get('stocks.timeline.feedback.orderChangeRequestAccepted')))
      .catch(e => this.proccessErro(e))
      .finally(() => this.props.handleFetch(false))
  }

  recusarSolicitacaoAlteracaoPedido = (idPedido, observacao) => {
    this.props.handleFetch()
    const user = Session.get()

    Fetch.post(`/timeline/recusarSolicitacaoAlteracaoPedido`,
      {
        IdPedido: idPedido,
        IdUsuario: user.Id,
        Observacao: observacao
      })
      .then(this.props.refreshTimeLine())
      .then(this.props.handleFeedback(intl.get('stocks.timeline.feedback.orderChangeRequestAccepted')))
      .catch(e => this.proccessErro(e))
      .finally(() => this.props.handleFetch(false))
  }

  cancelarSolicitacaoAlteracaoPedido = (idPedido) => {
    this.props.handleFetch()
    const user = Session.get()

    Fetch.post(`/timeline/cancelarSolicitacaoAlteracaoPedido`,
      {
        IdPedido: idPedido,
        IdUsuario: user.Id,
      })
      .then(this.props.refreshTimeLine())
      .then(this.props.handleFeedback(intl.get('stocks.timeline.feedback.canceledOrderChangeRequest')))
      .catch(e => this.proccessErro(e))
      .finally(() => this.props.handleFetch(false))
  }

  render() {
    let { data } = this.props
    let { actionType } = this.state

    return (
      <div className='item-timeline'>
        <div className='header-event-timeline'>
          <StepPoint stopNow={data.Atual} />
          <div className='container-status-timeline'>
            <div className='datetime-event'>
              <span>{this.formatDate(data.DataStatus).format('L')}</span>
              <span> - {moment(data.HoraStatus, 'HH:mm').format('LT')}</span>
              <span>: {data.NomeUsuario}</span>
            </div>
            <div className='status-timeline-id'>{data.IsPedidoVolume ? intl.get('stocks.volumeCode') : intl.get('stocks.portalCode')}
              <strong>{data.IsPedidoVolume && 'V'}{data.IdPedido}</strong>
            </div>
            <div className='status-timeline'>
              <span>{data.Status}</span>
              {!!actionType && <span>{` - ${actionType}`}</span>}
            </div>
            {data.Observacao &&
              <div className='status-timeline-observation'>
                <span>Obs: {data.Observacao}</span>
              </div>
            }
          </div>
        </div>

        <div className='body-event-timeline'>
          {this.loadEvent(data)}
        </div>
        <Message
          show={this.state.showMsg}
          text={this.state.textMsg}
          title={this.state.titleMsg}
          handleClose={() => { this.setState({ showMsg: false }) }}
        />
      </div>
    )
  }
}

EventTimeline.propTypes = {
  data: PropTypes.shape({
    Atual: PropTypes.bool.isRequired,
    CodigoPedidoSap: PropTypes.string,
    CodigoPedidoSapItem: PropTypes.string,
    Data: PropTypes.string,
    DataColetaDesejavel: PropTypes.string,
    DataColetaPrevista: PropTypes.string,
    DataEntregaDesejavel: PropTypes.string,
    DataEntregaPrevista: PropTypes.string,
    DataStatus: PropTypes.any,
    DescricaoMaterialAnterior: PropTypes.any,
    DescricaoMaterialNovo: PropTypes.any,
    HoraStatus: PropTypes.any,
    IdComposicao: PropTypes.number,
    IdFornecedor: PropTypes.number,
    IdMaterial: PropTypes.any,
    IdMaterialAnterior: PropTypes.any,
    IdMaterialNovo: PropTypes.any,
    IdPedido: PropTypes.any,
    Incoterm: PropTypes.any,
    IncotermAnterior: PropTypes.any,
    IsPedidoVolume: PropTypes.bool,
    NomeFornecedor: PropTypes.string,
    NomeUsuario: PropTypes.string,
    NotasFiscais: PropTypes.any,
    Observacao: PropTypes.string,
    Quantidade: PropTypes.number,
    QuantidadeAnterior: PropTypes.number,
    Status: PropTypes.string,
    StatusPedido: PropTypes.any,
    TipoNegociacaoIndex: PropTypes.number.isRequired,
    TipoUsuarioResponsavelProximaEtapa: PropTypes.number,
    UnidadeMedida: PropTypes.string,
    UnidadeNegocio: PropTypes.any,
    UnidadeNegocioAnterior: PropTypes.any,
    WmsTransactionId: PropTypes.string
  }).isRequired,
  handleFeedback: PropTypes.func.isRequired,
  handleFetch: PropTypes.func.isRequired,
  idPedido: PropTypes.number.isRequired,
  isNewFlow: PropTypes.any.isRequired,
  refreshTimeLine: PropTypes.func.isRequired
}

export default EventTimeline
