import React, { useState } from 'react'
import { Button } from 'components/uikit-adapter/index'
import OrdersAcceptModal from './modal'
import { CheckCircle } from 'components/icons/check-circle.icon'
import PropTypes from 'prop-types'

const ButtonOrderAccept = ({ disabled, accept, title }) => {
  const [showAlert, setShowAlert] = useState(false)

  const handleAccept = () => {
    setShowAlert(false)
    accept()
  }

  return (
    <React.Fragment>
      <div className="btn-content" title={title}>
        <Button
          buttonIcon={<CheckCircle />}          
          buttonText={title}
          className="btnAcept"
          width='166px'
          type="secondary"
          disabled={disabled}
          onClick={() => setShowAlert(true)}
        />
      </div>
      <OrdersAcceptModal
        onClick={handleAccept}
        open={showAlert}
        close={() => setShowAlert(false)}
      />
    </React.Fragment >
  )
}

ButtonOrderAccept.propTypes = {
disabled: PropTypes.bool,
accept: PropTypes.func,
title: PropTypes.string
}

export default ButtonOrderAccept
