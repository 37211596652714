import { Component } from 'react'
import PropTypes from 'prop-types'
import Fetch from 'utils/fetch'
import Dialog from 'material-ui/Dialog'
import { Input } from '@hbsis.uikit/react'
import { SelectBox, Button } from "components/uikit-adapter/index"


import intl from 'react-intl-universal'

import './family-form.css'
import { formatErrorMessage } from 'utils/handle-error';

class FamilyForm extends Component {
  constructor() {
    super()

    this.state = {
      family: {},
      wallets: []
    }
  }

  componentDidMount() {
    const editMode = this.props.editMode

    if (editMode) {
      this.searchFamilyById()
    }
    this.searchWallets()
  }

  proccessErro = (exception) => {
    this.props.handleFeedback(formatErrorMessage(exception))
  }

  searchFamilyById = () => {
    const idFamily = this.props.idEdit

    Fetch.get(`/families/${idFamily}`)
      .then((response) => this.proccessData(response.data))
      .catch((e) => this.proccessErro(e))
  }

  proccessData = (data) => {
    this.setState({ family: data })
  }

  searchWallets = () => {
    Fetch.get(`/material-groups:all`)
      .then((response) => this.processWalletsData(response.data))
      .catch((e) => this.proccessErro(e))
  }

  processWalletsData = (data) => {
    this.setState({
      wallets: data
    })
  }

  changeValue = (event) => {
    const prop = event.currentTarget.name
    const value = event.currentTarget.value

    this.setState(prevState => ({
      family: {
        ...prevState.family,
        [prop]: value
      }
    }))
  }

  updateSelectValue = (prop, value) => {
    this.setState(prevState => ({
      family: {
        ...prevState.family,
        [prop]: value
      }
    }))
  }

  save = () => {
    const editMode = this.props.editMode
    const model = this.state.family

    Fetch.post(`/families`, model)
      .then(this.props.handleRefresh)
      .then(() => this.props.handleFeedback(`${intl.get('feedbacks.register')} ${!editMode ? intl.get('feedbacks.saved') : intl.get('feedbacks.updated')} ${intl.get('feedbacks.withSuccess')}`))
      .catch((e) => this.proccessErro(e))
  }

  render() {
    let { family, wallets } = this.state
    let { open, editMode, profileAdminOrMasterData } = this.props

    return (
      <div>
        <Dialog
          title={editMode ? intl.get('master-data.walletsAndFamilys.familys.actions.editLabeledFamily') : intl.get('master-data.walletsAndFamilys.familys.actions.newFamily')}
          contentStyle={{ width: '600px' }}
          open={open}
        >
            <div className='line-1-columns'>

              <SelectBox
                required
                id="cbxMaterialGroupId"
                label={intl.get("commons.wallet")}
                placeholder={intl.get("commons.wallet")}
                valueKey='Id'
                labelKey='Description'
                value={family.MaterialGroupId}
                options={wallets}
                onChange={value => this.updateSelectValue('MaterialGroupId', value.Id)}
                disabled={!profileAdminOrMasterData}
                searchable
                openOnFocus
              />
            </div>

            <div className='line-1-columns'>
              <Input
                className="input"
                required
                name="Name"
                label={intl.get("master-data.general-configuration.name")}
                value={family.Name}
                onChange={this.changeValue}
                disabled={!profileAdminOrMasterData}
              />
            </div>

            <div className='footer-dialog-buttons'>
              <Button
                type="default"
                value={intl.get('geral.buttonsDefault.cancel')}
                className="button"
                onClick={() => { this.props.handleClose() }}
              />
              {profileAdminOrMasterData &&
                <Button
                  id="btnSave"
                  type="primary"
                  value={intl.get('geral.buttonsDefault.save')}
                  className="button"
                  onClick={this.save}
                />
              }
            </div>
        </Dialog>
      </div>
    )
  }
}

FamilyForm.propTypes = {
  editMode: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleFeedback: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  idEdit: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  profileAdminOrMasterData: PropTypes.func.isRequired
}

export default FamilyForm
