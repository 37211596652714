import Fetch from "utils/fetch";

export const searchPeriodicidades = () => {
    return Fetch.get(`/labeled-families:periodicities`).then(({ data }) => data)
}

export const searchWallets = () => {
    return Fetch.get(`material-groups:all`).then(({ data }) => data)
}

export const searchLabeledFamilyById = (idFamily) => {
    return Fetch.get(`/labeled-families/${idFamily}`).then(({ data }) => data)
}
