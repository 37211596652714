import { IProductionItem, IProductionPlan, IProductionPlanItem } from "features/supplier-production-plan/interface";
import fetch from "utils/fetch";

export const getSupplierProductionPlanItem = (query: string): Promise<IProductionPlanItem[]> => fetch.get(`supplier-production-plans?${query}`).then(({ data }) => data);

export const getSupplierProductionPlan = (query: string): Promise<IProductionPlan[]> => fetch.get(`supplier-production-plans:by-filters?${query}`).then(({ data }) => data);

export const deleteSupplierProductionPlan = (id: number) => fetch.delete(`supplier-production-plans?id=${id}`).then(({ data }) => data);

export const editSupplierProductionPlan = (id: number, _body: { supplierProductionPlan: IProductionItem }) => fetch.put(`supplier-production-plans?id=${id}`, _body.supplierProductionPlan).then(({ data }) => data);

export const createSupplierProductionPlan = (_body: { supplierProductionPlan: IProductionItem }) => fetch.post(`supplier-production-plans`, _body.supplierProductionPlan).then(({ data }) => data);