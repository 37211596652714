import PropTypes from "prop-types"
import React from "react"
import intl from "react-intl-universal"
import * as S from "./styled"

const Toggle = ({ onChangeIncluded, included }) => {
  return (
    <S.ToggleWrapper>
      <S.ToggleLabel>
        {intl.get("primaryMaterialScheduleList.arrivedLoads")}
      </S.ToggleLabel>
      <S.ToggleButton
        disabled={included}
        onClick={onChangeIncluded}
        value={intl.get("primaryMaterialScheduleList.included")}
      />
      <S.ToggleButton
        disabled={!included}
        onClick={onChangeIncluded}
        value={intl.get("primaryMaterialScheduleList.notIncluded")}
      />
    </S.ToggleWrapper>
  )
}

Toggle.propTypes = {
  included: PropTypes.any.isRequired,
  onChangeIncluded: PropTypes.any.isRequired
}

export default Toggle
