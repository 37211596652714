import PropTypes from "prop-types"
import React from "react";
import { Col } from "react-flexbox-grid";
import intl from 'react-intl-universal';
import InputNumber from "../../../input-number";

const InputQuantidade = ({ changeValue, quantidade }) => (
  <React.Fragment>
    <Col xs={6} className="components-childs">
      <InputNumber
        label={intl.get('commons.amount')}
        type="default"
        name="quantidade"
        value={quantidade}
        onChange={value => changeValue({ target: { value: value, name: "quantidade" } })}
        data-testid="input-quantidade"
        required
      />
    </Col>
  </React.Fragment>
)

InputQuantidade.propTypes = {
  changeValue: PropTypes.func.isRequired,
  quantidade: PropTypes.any.isRequired
}

export default InputQuantidade;
