import styled from 'styled-components'

const StyledSuppliersButtons = styled.div`
  .minimize-item,
  .maximize-item {
    position: relative;
    top: 2px;
    width: 0;
    height: 0;
    display: block;
    border-style: solid;
  }

  .maximize-item {
    margin-top: 11px;
    border-width: 0 9px 9px 9px;
    border-color: transparent transparent var(--color-action-default) transparent;
  }

  .maximize-item-disabled {
    border-color: transparent transparent var(--color-neutral-300) transparent!important;
  }

  .minimize-item {
    margin-top: 2px;
    border-width: 9px 9px 0 9px;
    border-color: var(--color-action-default) transparent transparent transparent;
  }

  .minimize-item-disabled {
    border-color: var(--color-neutral-300) transparent transparent transparent!important;
  }
`
export default StyledSuppliersButtons
