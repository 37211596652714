import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PADDING_LEFT } from "./../constants";

export const StyledRow = styled.div`
  width: 100%;
  padding-left: ${PADDING_LEFT}px;
  ${p => !p.hasChildren && "padding-left: 0;"};
  position: relative;

  ${p =>
    p.isExpanded &&
    p.hasChildren &&
    `
      &:before {
        content: "";
        position: absolute;
        left: 12px;
        top: 8px;
        bottom: 0;
      }
    `} .etb-info {
    display: flex;
    box-sizing: border-box;
  }

  .etb-column:not(:last-child), .etb-label {
    border-right: 1px solid #dbdbdb;
  }
  
  .etb-image-alert {
    margin-right: 4px;
  }

  .etb-children {
    display: none;
    flex-direction: column;
    
    &.expanded {
      display: flex;
    }
  }
`;

StyledRow.propTypes = {
  isExpanded: PropTypes.bool,
  hasChildren: PropTypes.bool
}

