
import PropTypes from "prop-types"
import React from 'react'
import { Row, Col } from "react-flexbox-grid";
import OptionGroup from 'components/option-group'

const RadioValues = ({ radioValues, handleChangeRadio }) => (
  <React.Fragment>
    <Row className='options-group-row'>
      <Col xs={12}>
        <OptionGroup
          handleSelect={event => handleChangeRadio(event.currentTarget.value, event.currentTarget.name)}
          options={radioValues[0]}
          className="optionGroup-origin"
        />
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <div className='last-option-group'>
          <OptionGroup
            handleSelect={event => handleChangeRadio(event.currentTarget.value, event.currentTarget.name)}
            options={radioValues[1]}
            className="optionGroup-origin"
          />
        </div>
      </Col>
    </Row>
  </React.Fragment>

)

RadioValues.propTypes = {
  handleChangeRadio: PropTypes.func.isRequired,
  radioValues: PropTypes.any.isRequired
}

export default RadioValues
