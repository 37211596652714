import React from 'react'
import moment from 'moment'
import intl from 'react-intl-universal'
import IconLabelRight from '../../../components/icon-label-right'
import { getCurrentStockForOrdersOrSuggestions } from '../../../components/table-list/utils'
import pedidoAguardoIcon from 'images/pedido-aguardo-red.png';
import { getValueForCondition } from "utils/custom-functions";
import { formatNumber, formatWithoutLeadingZeros } from 'utils/format'

export const filtersSuggestionDefault = {
  BusinessUnitIds: [],
  SupplierIds: [],
  MaterialNumber: [],
  DeliveryDateMin: '',
  DeliveryDateMax: '',
  DeliveryTimeMin: '',
  DeliveryTimeMax: '',
  MaterialGroupIds: [],
  Status: [],
  _page: 0,
  _size: 50,
  IsFiltered: false
}

export const builderSuggestionCard = (suggestion) => ({
  Tipo: { Titulo: intl.get('commons.orderSuggestion'), Etapa: 1, Prioridade: 0 },
  Material: {
    Nome: `${formatWithoutLeadingZeros(suggestion.MaterialNumber)} - ${suggestion.MaterialName.split("-")[1]}`,
    Estoque: {
      Prioridade: suggestion.StockUnitPriority,
      Suficiencia: getValueForCondition(suggestion.StockUnitSufficiency, moment(suggestion.StockUnitSufficiency).format("L"), 'N/A'),
      QuantidadeAtual: suggestion.StockUnitCurrentQuantity,
      UnidadeMedida: suggestion.MaterialUnitOfMeasure
    }
  },
  Fabrica: {
    Codigo: suggestion.BusinessUnitPlant,
    Nome: suggestion.BusinessUnitName
  },
  Fornecedor: suggestion.SupplierName,
  Status: suggestion.SuggestionStatusDescription,
  Sugestao: true,
  IdFamiliaRotuladaCentro: suggestion.LabeledFamilYCenterId,
  Id: suggestion.Id
})

export const builderSuggestionList = (suggestion) => ({
  ...suggestion,
  Step: getIconStep(),
  SuggestionCollectDate: formatMoment(suggestion.SuggestionCollectDate, "L"),
  SuggestionDeliveryDate: formatMoment(suggestion.SuggestionDeliveryDate, "L"),
  SuggestionDeliveryTime: formatMoment(suggestion.SuggestionDeliveryTime, "LT"),
  StockUnitSufficiency: formatMoment(suggestion.StockUnitSufficiency, "L"),
  BusinessUnit: `${suggestion.BusinessUnitPlant} - ${suggestion.BusinessUnitName}`,
  StockUnitCurrent: getCurrentStockForOrdersOrSuggestions(
    suggestion.StockUnitPriority,
    suggestion.StockUnitCurrentQuantity, suggestion.MaterialUnitOfMeasure),
  MaterialName: `${formatWithoutLeadingZeros(suggestion.MaterialNumber)} - ${suggestion.MaterialName.split("-")[1]}`,
  UrlRedirect: `/stocksDetail/${suggestion.LabeledFamilYCenterId}/${suggestion.Id}/S`,
  Quantity: `${formatNumber(suggestion.Quantity)} ${suggestion.QuantityUnitOfMeasure}`,
  Lot: suggestion.Lot == null ? '-' : `${suggestion.Lot} ${suggestion.LotRoudingUnit}`
})

const getIconStep = () => (
  <IconLabelRight
    src={pedidoAguardoIcon}
    label={intl.get('commons.orderSuggestion')}
    classNameDivImage='red-background'
    isAlert={false}
  />
)

const formatMoment = (value, format) => value ? moment(value).format(format) : '-';
