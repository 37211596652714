import Feature from "components/feature";
import * as S from './styled'

export const OrderListEditableHeader = ({ data }) => (
  data.map(x => (
    <Feature validation={x.visible}>
      <S.TableHeader key={x.title}>
        {x.title}
      </S.TableHeader>
    </Feature>
  ))
)