import styled from "styled-components"
import { Button as ButtonUI } from 'components/uikit-adapter/index'

export const ToggleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const ToggleButton = styled(ButtonUI)`
  width: 120px;
  height: 44px;
`
export const ToggleLabel = styled.span`
  position:absolute;
  margin-bottom: 65px;
  line-height: 1.8rem;
  font-size: 12px;
  font-weight: var(--font-weight-semibold);
  text-transform: uppercase;
  color: var(--color-contrast-brand);
`
