import { Component } from 'react'
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn
} from 'material-ui/Table'
import intl from 'react-intl-universal'
import { formatNumber } from 'utils/format'
import PropTypes from 'prop-types'

class PedidosComposicao extends Component {
  constructor(props) {
    super(props)

    this.state = {
      pedidos: props.pedidos
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const pedidosReprojetados = nextProps.pedidos
    if (pedidosReprojetados) {
      this.atualizarPrioridadeProjecao(pedidosReprojetados)
    }
  }

  atualizarPrioridadeProjecao = (pedidosReprojetados) => {
    const { pedidos } = this.state;
    const _pedidos = pedidos.map((pedido) => {
      const p = pedidosReprojetados.find(pedidoReprojetado => pedidoReprojetado.IdPedido === pedido.IdPedido);
      return {
        ...pedido,
        Prioridade: p ? p.Prioridade : -1
      }
    })

    this.setState({ pedidos: _pedidos })
  }

  render() {
    const { pedidos } = this.state

    return (
      <Table
        height='250px'
        wrapperStyle={{ height: '200px' }}
        bodyStyle={{ height: 'calc(100% - 60px)' }}
        fixedHeader
        selectable={false}
        multiSelectable={false}
      >
        <TableHeader
          displaySelectAll={false}
          adjustForCheckbox={false}
          enableSelectAll={false}
        >
          <TableRow>
            <TableHeaderColumn style={{ textAlign: 'center', width: '20%' }}>
              <span>{intl.get('commons.request')}</span>
            </TableHeaderColumn>
            <TableHeaderColumn style={{ textAlign: 'center', width: '50%' }}>
              <span>{intl.get('commons.material')}</span>
            </TableHeaderColumn>
            <TableHeaderColumn style={{ textAlign: 'center', width: '30%' }}>
              <span>{intl.get('commons.amount')}</span>
            </TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TableBody
          displayRowCheckbox={false}
          deselectOnClickaway={false}
          showRowHover
          stripedRows={false}
        >
          {pedidos.map((row, index) => (
            <TableRow key={index}>
              <TableRowColumn style={{ textAlign: 'center', width: '20%' }}>{row.IdPedido}</TableRowColumn>
              <TableRowColumn style={{ textAlign: 'center', width: '50%' }}>{row.Material}</TableRowColumn>
              <TableRowColumn style={{ textAlign: 'center', width: '30%' }}>{formatNumber(row.Quantidade)}</TableRowColumn>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )
  }
}

PedidosComposicao.propTypes = {
  pedidos: PropTypes.array
}

export default PedidosComposicao
