import Fetch from "utils/fetch"
import { convertToQueryParameters } from "utils/custom-functions"
import { sortTable } from "./utils/format"
import { fetchAdvancedPackagingMaterialScheduleMock } from "./tests/data-mock"

export const buscarFornecedores = () => {
  return Fetch.get(`/fornecedor/buscaFornecedores`).then(({ data }) => data)
}

export const fetchAdvancedPackagingMaterialSchedule = (filters) => {
  const lines = filters.productionLine.map(line => line.productionLine).join("&Lines=");
  const vendors = filters.vendor.map(line => line.CodigoFornecedor).join("&Vendors=");
  const materials = filters.material.map(line => line.MaterialCode).join("&Materials=");
  const advancedPackagingMaterialScheduleFilterDto = {
    Plant: filters.plant.Centro,
    ArriveLoads: filters.included,
    DateRangeFrom: filters.datas.deliveryDateFrom,
    DateRangeTo: filters.datas.deliveryDateTo
  }

  const normalizeUrlLists = `${lines && `&Lines=${lines}`} ${vendors && `&Vendors=${vendors}`} ${materials && `&Materials=${materials}`}`

  return Fetch.get(`/zapmsl/advancedpackagingmaterialschedule?${convertToQueryParameters(advancedPackagingMaterialScheduleFilterDto)}${normalizeUrlLists}`)
    .then(({ data }) => formatWithId(sortTable(data)))

}

export const updateAdvancedPackagingMaterialSchedule = (line) => {

  const Line = {
    Line: line
  }

  return Fetch.post(`/zapmsl/advpackagingmaterialschedulechangeupdate`, Line).then(({ data }) => data)
}

export const insertAdvancedPackagingMaterialSchedule = (line) => {
  const Line = {
    Line: line
  }
  return Fetch.post(`/zapmsl/advpackagingmaterialschedulechangeinsert`, Line).then(({ data }) => data)
}

export const deleteAdvancedPackagingMaterialSchedule = (line) => {
  const Line = {
    Lines: line
  }
  return Fetch.post(`/zapmsl/advpackagingmaterialschedulechangedelete`, Line).then(({ data }) => data)
}

export const formatWithId = (data) => {
  return data.map((_data, index) => ({ ..._data, Id: index }));
}

export const buscarMateriaisPrimarios = () => {
  return Fetch.get(`/materials:primary`).then(({ data }) => data)
}

export const buscarMateriaisPrimariosPorUnidadeNegocio = (idUnidadeNegocio) => {
  return Fetch.get(`/material/obterMateriaisPrimariosPorUnidadeDeNegocio/${idUnidadeNegocio}`).then(({ data }) => data)
}

export const getConfiguredColumnPrimaryMaterial = () => JSON.parse(localStorage.getItem("columnPrimaryMaterialConfiguration"));

export const assignSaveTransactionIds = (dto) => {
  return Fetch.post('/zapmsl/advancedpackagingmaterialscheduleassign', dto).then(({ data }) => data)
}

export const sendToIvec = (dto) => {
  return Fetch.post('/zapmsl/advpackagingmaterialschedulereleasesendivec', dto).then(res => res)
}

export const releaseSave = (dto) => {
  return Fetch.post('/zapmsl/advpackagingmaterialschedulereleasesaveschedule', dto).then(({ data }) => data)
}

export const sendBlockChange = (dto) => {
  return Fetch.post('/zapmsl/advpackagingmaterialschedulechangeblockchange', dto).then(({ data }) => data)
}

export const getSupplierScheduleAgreementByMaterial = (idMaterial) => (
  Fetch.get(`fornecedor/getSupplierScheduleAgreementByMaterial/${idMaterial}`).then(({ data }) => data)
)
