import React from 'react'
import DeliveryScheduleConfigurationComponent from './deliveryScheduleConfiguration'
import { DeliveryScheduleConfigurationProvider } from './context/deliveryScheduleConfigurationContext'

const DeliveryScheduleConfiguration = () => (
  <DeliveryScheduleConfigurationProvider>
    <DeliveryScheduleConfigurationComponent />
  </DeliveryScheduleConfigurationProvider>
)

export default DeliveryScheduleConfiguration;
