import Fetch from 'utils/fetch'
import { paisesMapper } from 'utils/userLanguage'

export const searchUserById = (userId) => {
    return Fetch.get(`/usuario/alteracao/${userId}`).then(({ data }) => data)
}

export const searchAllBudgets = () => {
    return Fetch.get(`/material-groups:all`).then(({ data }) => data)
}

export const searchAllCoordinations = () => {
    return Fetch.get(`/coordenacao`).then(({ data }) => data)
}

export const searchAllCountries = () => {
    return Fetch.get(`/countries:available`).then(({ data }) => paisesMapper(data))
}

export const searchAllPerfilAcessos = () => {
    return Fetch.get(`/usuario/ListaTiposPerfilAcesso`).then(({ data }) => data)
}

export const searchAllUnidadeNegocio = () => {
    return Fetch.get(`/unidadenegocio/`).then(({ data }) => data)
}

export const searchStatusTypes = () => {
    return Fetch.get(`/usuario/listaStatusUsuario`).then(({ data }) => data)
}

export const save = (userId, usuario) => {
    return Fetch.post(`/usuario/salvar/${userId}`, usuario).then(({ data }) => data)
}
