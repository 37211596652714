import Fetch from 'utils/fetch'

export const searchGradeDescarga = (idUnidadeNegocio) => {
    return Fetch.get(`/gradeDescarga/obterGradeDescargaUnidade/${idUnidadeNegocio}`).then(({ data }) => data)
}

export const searchGradeCarregamento = (idUnidadeNegocio) => {
    return Fetch.get(`/gradeCarregamento/obterGradeCarregamentoUnidade/${idUnidadeNegocio}`).then(({ data }) => data)
}

export const saveGradeDescarga = (idUnidadeNegocio, gradesPreenchidas) => {
    return Fetch.post(`/gradeDescarga/salvar/${idUnidadeNegocio}`, gradesPreenchidas)
}

export const saveGradeCarregamento = (idUnidadeNegocio, gradesPreenchidas) => {
    return Fetch.post(`/gradeCarregamento/salvar/${idUnidadeNegocio}`, gradesPreenchidas)
}