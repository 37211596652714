import React, { Component } from 'react'
import { Tabs, Pane } from '@hbsis.uikit/react'

import Supplier from 'pages/master-data/suppliers/supplier'
import SupplierGroup from 'pages/master-data/suppliers/supplier-group'
import intl from 'react-intl-universal'

class MasterSupplier extends Component {
  constructor() {
    super()
  }

  render() {
    return (
      <Tabs>
        <Pane label={intl.get('master-data.suppliers.ableSupplier.header.title')}>
          <Supplier />
        </Pane>
        <Pane label={intl.get('master-data.suppliers.usersGroupSupplier.header.title')}>
          <SupplierGroup />
        </Pane>
      </Tabs>
    )
  }
}

export default MasterSupplier
