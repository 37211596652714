import styled from 'styled-components'

const StyledMaterialSupplierForm = styled.div`
  .footer-dialog-buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: right;
    margin-top: 10px!important;
  }

  .footer-dialog-buttons .button {
    margin: 2px;
    width: 160px;
  }

  .Select-value,
  .Select-placeholder {
    padding: 0 10px!important;
  }

  .supplier-form {
    height: 500px;
  }

  .select-origem-row {
    margin: 10px 25px 6px 8px;
  }

  .select-origem-label {
    color: var(--color-contrast-brand);
    font-size: 12px;
    font-weight: var(--font-weight-semibold);
    text-transform: uppercase;
    margin-right: 10px;
  }

  .select-origem .radio-items-list .radio-item {
    margin: 0;
  }

  .select-origem .radio-items-list .radio-item input[type="radio"] + label {
    color: var(--color-contrast-brand);
    font-size: 12px;
    font-weight: var(--font-weight-semibold);
    text-transform: uppercase;
    margin-top:3px;
  }

  select-origem .radio-items-list .radio-item input[type="radio"] + label:before {
    width: 5px;
    height: 5px;
  }

  .line-separator {
    height: 1px;
    width: 100%;
    border: none;
    color: rgba(197,208,225,0.6);
    background-color: rgba(197,208,225,0.6);
  }

  .materialContainer {
    position: relative;
  }

  .supplierFormFields {
    background: var(--color-contrast-white);
    padding: 15px 20px 25px;
  }

  .footerMaterialContainer {
    width: 100%;
    position: absolute;
    bottom: 0px;
    background: var(--color-contrast-white);
    padding: 0px 0px 5px 0px;
    margin: 0;
  }

  .filterDivisor {
    background-color: rgba(197,208,225,0.6);
    height: 1px;
    width: 100%;
    color: rgba(197,208,225,0.6);
    border: none;
  }

  .container {
    width: 97% !important;
    padding: 7px;
    margin-top: -16px;
    border: 1px solid var(--color-neutral-300);
  }

  .label-style {
    background: white;
    margin-left: 10px;
  }

  .lineButtons {
    display: flex;
    justify-content: space-between;
    position: static;
    top: 16px;
    width: 535px;
    padding-left: 125px;
  }

  .container-action-buttons .button {
    margin: 2px;
    display: inline-block;
    width: 30% !important;
  }

  .height-container {
    height: 55px
  }
`;

export default StyledMaterialSupplierForm
