import PropTypes from "prop-types"
import React from 'react'
import { Row, Col } from "react-flexbox-grid";
import { Button } from "components/uikit-adapter/index"
import intl from 'react-intl-universal'

const ActionsFooterDialog = ({ save, close }) => (
  <React.Fragment>
    <Row between="xs" style={{ width: '100%' }}>
      <Col xs={4}>
        <Button
          type="default"
          className='btn-icon'
          value={intl.get('geral.buttonsDefault.cancel')}
          onClick={close}
        />
      </Col>
      <Col xs={4}>
        <Button
          type="primary"
          className='btn-icon'
          value={intl.get('geral.buttonsDefault.send')}
          onClick={save}
        />
      </Col>
    </Row>
  </React.Fragment>
)

ActionsFooterDialog.propTypes = {
  close: PropTypes.any.isRequired,
  save: PropTypes.any.isRequired
}

export default ActionsFooterDialog;
