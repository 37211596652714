const IconTruckPlus = () => (
    <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" 
    width={ "28px" } 
    height={ "9px"}  >
      <path
        fill="var(--color-action-default)"
        d="M3.716 7.781V1.22H4.83V7.78H3.716ZM.99 5.057V3.943h6.563v1.114H.991Z"
      />
      <path
        fill="var(--color-action-default)"
        d="M10.8 7.773h.474a1.663 1.663 0 0 0 3.208 0h7.598a1.663 1.663 0 0 0 3.208 0h1.305a.414.414 0 0 0 .416-.41V4.91a.352.352 0 0 0-.034-.156.487.487 0 0 0-.091-.135l-2.078-2.045a.399.399 0 0 0-.291-.118H22.02V1.227A1.24 1.24 0 0 0 20.775 0H10.8a1.24 1.24 0 0 0-1.247 1.227v5.318c0 .676.56 1.228 1.246 1.228Zm12.883.409a.827.827 0 0 1-.83-.818c0-.45.373-.819.83-.819.458 0 .832.369.832.819 0 .45-.374.818-.832.818Zm-1.662-4.91h2.323l1.833 1.805v1.878h-.89a1.663 1.663 0 0 0-3.207 0h-.059V3.273Zm-9.143 4.91a.827.827 0 0 1-.831-.818c0-.45.374-.819.831-.819.457 0 .831.369.831.819 0 .45-.374.818-.83.818Zm-2.493-6.955c0-.225.187-.409.415-.409h9.974c.229 0 .416.184.416.41V4.5H10.385V1.227Zm0 4.091H21.19v1.637h-6.708a1.663 1.663 0 0 0-3.208 0H10.8a.414.414 0 0 1-.415-.41V5.318Z"
      />
    </svg>
  )
  export default IconTruckPlus