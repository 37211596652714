import styled from 'styled-components'

export const Title = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  color: var(--color-contrast-brand);
  display: flex;
  white-space: break-spaces;
`

export const Wrapper = styled.div`
  padding: 20px 12px 18px 12px;
  z-index: 20;

  .exit{
    display: flex;
    justify-content: right;
    padding: 0px 12px 26px 0px;
  }
`

export const Content = styled.div`
  display: flex;
  margin-top: 18px;
  flex-direction: column;

  .rowFilterButtons{
    margin-top: 18px;
  }

  .clean-search{
    background: var(--color-contrast-white);
  }
`
export const MaterialLabel = styled.div`
  margin-top: 8px;
`

export const Exit = styled.img`
  cursor: pointer;
  width: 10px;
`
