export const formataLinhasDeProducao = (linhasDeProducao) => {
  const linhas = linhasDeProducao.includes(";") ? linhasDeProducao.split(";") : [linhasDeProducao]
  return linhas.map(l => {
    return { name: l }
  })
}

export const formataLinhasDeProducaoParaSalvar = (linhasDeProducao) => {
  return linhasDeProducao.map(l => l.name)
}

export const obtemInformacoesLinhaDeProducao = (dadosLinhaProducao) => {
  return {
    LinhasDeProducao: dadosLinhaProducao && dadosLinhaProducao.ProductionLine.length > 0 ?
      formataLinhasDeProducao(dadosLinhaProducao.ProductionLine) : [],
    IdLinhaDeProducao: dadosLinhaProducao ? dadosLinhaProducao.Id : undefined
  }
}

export const formataModelLinhasDeProducao = (unit) => {
  return {
    Id: unit.IdLinhaDeProducao,
    UnidadeNegocioId: unit.Id,
    Centro: unit.Centro,
    LinhaProducao: formataLinhasDeProducaoParaSalvar(unit.LinhasDeProducao)
  }
}

export const getDoorsReactTagFormat = (data) => {
  if (data && data.length > 0 ){
    return data.map((door) => ({ name: door }))
  }
  return []
}
