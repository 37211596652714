import React from 'react'
import { OrderVolume as OrderVolumeComponent, OrderVolumeProvider } from 'features/order-volume'

const OrderVolume = () => (
  <OrderVolumeProvider>
    <OrderVolumeComponent />
  </OrderVolumeProvider>
)

export default OrderVolume
