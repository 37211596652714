import styled from 'styled-components'
import StyledGrid from 'components/styled-grid'

const styledListTasks = styled(StyledGrid)`
  width: 100%;
  padding: 0 33px 0 33px;

  .align-center {
    text-align: center;
  }

  .align-left {
    padding-left: 20px;
    text-align: left;
  }

  .containerListaTask {
    height: calc(100vh - 241px);
    overflow-y: auto;
  }

  .etapa-color {
    font-weight: 800;
    font-size: 11px;
  }

  .hint {
    background: var(--color-feedback-danger-100);
    width: 100%;
    border-radius: 23px;
    margin: 0 auto;
    padding: 5px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .containerHeader {
    font-size: 0.7rem;
    color: #9ba2ac;
    background: var(--color-contrast-white);
    padding: 10px 0;
    border-bottom: 1px dashed var(--color-neutral-300);
    padding-left: 4px;
  }


  .red-background {
    background: var(--color-feedback-danger-100);
  }

  .yellow-background {
    background: var(--color-feedback-warning-100);
  }

  .green-background {
    background: var(--color-feedback-positive-100);
  }

  .red-color {
    color: #de4246;
  }

  .yellow-color {
    color: #fbba49;
  }

  .red-stock {
    color: #de4246;
  }

  .green-stock {
    color: #6f9e32;
  }

  .red-border {
    box-shadow: inset 4px 0 #f9575b;

    &:hover {
      box-shadow: inset 4px 0 var(--color-feedback-danger-600);
    }
  }

  .green-border {
    box-shadow: inset 4px 0 #6f9e32;

    &:hover {
      box-shadow: inset 4px 0 var(--color-feedback-positive-600);
    }
  }

  .yellow-border {
    box-shadow: inset 4px 0 #FFC107;

    &:hover {
      box-shadow: inset 4px 0 var(--color-feedback-warning-600);
    }
  }

  .rowOrders {
    min-height: 55px;
    max-height: 55px;
  }

  .width-content-etapa {
    width: 100%!important;
  }

  .check-box-container{
    width: 35px!important;
    padding-left: 22px;
  }

  .container-etapa {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .align-etapa {
    width: 90%;
  }

  .align-check {
    width: 10%;
  }

  .container-check-etapa {
    display: flex;
    align-items: center;
  }

  .iconImage {
    img {
      width: 30px;
      height: 30px;
    }
  }
`

export default styledListTasks
