import { getLoadsOptimizedByVolumes } from "features/load-building/load-building.service";
import { StatusRequest } from "features/load-building/utils";
import { formatErrorMessage } from "utils/handle-error";

export const normalizeLoadsToRequestBuild = (data) => data.map((x, i) => ({
  LoadCompositions: [x],
  Status: StatusRequest.NotStarted,
  Id: i + 1
}));

export const sendListIndividualRequests = async (_loads, setLoadsSending, openToast) => {
  let results = [];

  let i = 0;
  for (const _load of _loads) {
    try {
      _loads[i] = {
        ..._loads[i],
        Status: StatusRequest.Loading
      }
      setLoadsSending([..._loads])
      const _result = await getLoadsOptimizedByVolumes(_load);
      _result.Message.forEach(element => {
        const resultType = _result.Success ? "positive" : "warning";
        openToast(element, resultType);
      });
      results.push(_result);
      _loads[i] = {
        ..._loads[i],
        Status: StatusRequest.Success
      }
    } catch (e) {
      _loads[i] = {
        ..._loads[i],
        Status: StatusRequest.Error
      }
      openToast(formatErrorMessage(e), 'negative');
    }
    setLoadsSending([..._loads])
    i++
  }

  return results;
}

export const verifyVolumeOrdersToUpdate = (loads) => {
  const loadNotFull = loads?.LoadNotFull;
  let orderVolumeIds = [];

  for (const _load of loadNotFull) {
    _load.Orders.map((item) => {
      orderVolumeIds = [...orderVolumeIds, ...item.OrderVolumeId]
    });
  }

  const _volumesForUpdate = loads.VolumeOrders.filter((item) => !orderVolumeIds.includes(item.OrderVolumeId));

  return _volumesForUpdate;
}