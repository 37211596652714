import styled from "styled-components";

export const SelectFiltersMessage = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 8%;
  color: #0E0E0E;
  font-size: 16px;
  font-style: italic;
  font-weight: var(--Neutral-600, #0E0E0E);
  line-height: 150%; /* 24px */
  font-weight: var(--font-weight-semibold);
`

export const NoDataFound = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 8%;
  color: #0E0E0E;
  font-size: 16px;
  font-style: italic;
  font-weight: var(--Neutral-600, #0E0E0E);
  line-height: 150%; /* 24px */
  font-weight: var(--font-weight-medium);
`

export const TableTitle = styled.div`
  width: 100%;
  padding: 4px 0px 12px 0px;
  color: #0E0E0E;
  font-size: 16px;
  line-height: 150%; /* 24px */
  font-weight: var(--font-weight-semibold);
`

export const TableWrapper = styled.div`
  padding: 24px;

  input {
    border: 1.6px solid var(--color-action-default, #2870B2) !important;
    box-shadow: none !important;
  }

  .react-datepicker-popper{
    z-index: 4;
  }
`