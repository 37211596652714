import { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Card from '../../../../components/card'
import { SnackBar } from 'components/uikit-adapter/index'
import StyledIntegracoes from "./styledIntegracoes";
import Loading from 'components/center-loading'
import HeaderIcon from 'components/header-icon'
import { IntegrationIcon } from 'components/icons/icn-index.icon'
import intl from 'react-intl-universal'
import { getIntegracoes, getWallets, getLabeledFamilies } from "./integrations.service";

class ConfiguracoesIntegracoes extends Component {
  constructor() {
    super()

    this.state = {
      isFetching: false,
      showFeedback: false,
      messageFeedback: '',
      integracoes: [],
      carteiras: [],
      familiasRotuladas: [],
    }
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  componentDidMount() {
    this.searchLabeledFamily();
    this.searchWallets();
    this.obterIntegracoes();

    this.timerID = setInterval(() => { this.obterIntegracoes() }, 60000);
  }

  obterIntegracoes = async () => {
    this.startFetching();
    try {
      let integracoes = await getIntegracoes();
      this.setState({ integracoes });
    } catch (e) {
      this.proccessFeedback(e);
    }
    this.stopFetching()
  }

  startFetching = () => {
    this.setState({
      isFetching: true
    })
  }

  stopFetching = () => {
    this.setState({
      isFetching: false
    })
  }

  searchWallets = async () => {
    this.startFetching();
    try {
      const carteiras = await getWallets();
      this.setState({ carteiras });
    } catch (e) {
      this.proccessFeedback(e);
    }
    this.stopFetching()
  }

  searchLabeledFamily = async () => {
    this.startFetching();
    try {
      const familiasRotuladas = await getLabeledFamilies();
      this.setState({ familiasRotuladas });
    } catch (e) {
      this.proccessFeedback(e);
    }
    this.stopFetching()
  }

  proccessFeedback = (message) => {
    this.setState({
      isFetching: false,
      showFeedback: true,
      messageFeedback: message,
    })
  }

  closeFeedback = () => {
    this.setState({
      showFeedback: false,
      messageFeedback: ''
    })
  }

  render() {
    const {
      integracoes,
      carteiras,
      familiasRotuladas,
      messageFeedback,
      showFeedback,
      isFetching
    } = this.state;

    return (
      <StyledIntegracoes>
        <Loading isLoading={isFetching} />

        <div className="integracoes-header">
          <HeaderIcon
            title={intl.get('master-data.configurations.integrations.header.title')}
            subtitle={intl.get('master-data.configurations.integrations.header.subtitle')}
            icon={<IntegrationIcon width="45px" height="45px" color='var(--color-brand-500)' />}
          />
        </div>

        <SnackBar
          message={messageFeedback}
          open={showFeedback}
          autoHideDuration={4000}
          onRequestClose={this.closeFeedback}
          style={{ width: '100%', textAlign: 'center', display: 'block' }}
        />

        {integracoes && integracoes.map((v, i) => (
          <Card
            key={i}
            dataCard={v}
            typeCard={'CONFIGURACAO_INTEGRACAO'}
            carteiras={carteiras}
            familiasRotuladas={familiasRotuladas}
            proccessFeedback={this.proccessFeedback}
            obterIntegracoes={this.obterIntegracoes}
          />
        ))}
      </StyledIntegracoes>
    );
  }
}

export default withRouter(ConfiguracoesIntegracoes)
