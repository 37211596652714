import styled from 'styled-components'
import StyledGrid from 'components/styled-grid'
import PropTypes from 'prop-types'

const StyledComposicaoList = styled(StyledGrid)`
  width: 100%;
  padding: 8px 8px 0 0;

  .containerLista {
    height: 38vh;
    overflow-y: auto;
    color: black;
  }

  input {
    &.input-checkbox {
      outline: none;
      -webkit-appearance: none;
      border: 1px solid #d2d6da;
      background: white;
      display: block;
      padding: 7px;
      cursor: pointer;

      -webkit-user-select: none;/* Chrome all / Safari all */
      -moz-user-select: none;/* Firefox all */
      -ms-user-select: none;/* IE 10+ */
      user-select: none;

      &:checked {
        background: #78cdef;
      }
    }
  }

  .rowListHeader{
    width: 100%;
  }

  .rowList, .rowListSelected {
    font-size: 0.8rem;
    padding: 5px 0;

    ${props => props.withHover && `
      cursor: pointer;
    `}

    min-height: 50px;
    max-height: 50px;

    display: flex;
    align-items: center;
    justify-content: center;

    padding-left: 4px;
    border-bottom: 1px dashed var(--color-neutral-300);

    ${props => props.withBorder && `
      box-shadow: inset 4px 0 ${props.borderColor};
    `}
  }

  .rowList {
    background: var(--color-contrast-white);

    ${props => props.withHover && `
      &:hover {
        background: #f4f5f8;
        ${props.withBorder && `
          box-shadow: inset 4px 0 ${props.borderColorHover};
        `}
      }
    `}
  }

  .rowListSelected {
    background: #f4f5f8;

    ${props => props.withBorder && `
      box-shadow: inset 4px 0 ${props.borderColorHover};
    `}
  }

  .no-results {
    font-size: 14px;
  }

  .pt-10 {
    padding-top: 10px;
  }

  .pb-10 {
    padding-bottom: 10px;
  }

  .column-date,
  .column-um,
  .column-task,
  .column-codigo,
  .column-item,
  .column-time,
  .column-sa,
  .column-lote,
  .column-quantidade,
  .column-liquidPrice,
  .column-unitPrice {
    width:100px;
    flex-shrink: 0;
  }

  .column-material {
    flex: 1;
    display: grid;
  }

  .column-checkbox {
    margin-left: 5px;
    margin-right: 0px;
  }

  .column-indicator {
    width: 15px;
    font-size: 18px;
    font-weight: bold;
    color: red;
    text-decoration-color: red;
  }

  .column-codigo-link {
    font-size: 15px;
    font-weight: bold;
    color: var(--color-action-default);
    text-decoration: underline;
  }

`
StyledComposicaoList.propTypes = {
  withBorder: PropTypes.bool,
  withHover: PropTypes.bool,
  borderColor: PropTypes.string,
  borderColorHover: PropTypes.string,
}

export const RowWrapper = styled.div`
  display: contents;
  div{
    ${p => p.isDeleted && 'text-decoration:line-through'}
  }
`

export const ColumnPortalCode = styled.div``

export default StyledComposicaoList
