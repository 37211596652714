import React from 'react'
import intl from 'react-intl-universal'
import {
  getColorCell,
  getColorSpan,
  getFilterCheckbox,
} from './utils'
import * as S from './styled'

const medida = {
  Milheiros: 1,
  Hectolitros: 2
}

const label = (value) => value === medida.Milheiros ? 'TH' : 'HL'
const renderWithCalc = (data, value, option) => option === medida.Hectolitros ? data * value : data

export const renderData = (data, checkboxs, optionsMedida, hectoliterPerThousand) => {
  return (
    Object.keys(data).
      filter(prop => (getFilterCheckbox(checkboxs).includes(prop)))
      .map(prop => (
        prop === 'PlannedEntry' ?
          renderPlannedEntry(data[prop], hectoliterPerThousand, optionsMedida)
          :
          <S.TableCell
            background={getColorCell(prop, data[prop])}
            color={getColorSpan(prop, data[prop])}>
            <span className='title'>{intl.get(`bottles.keys.${prop}`)}:</span>
            <span>{renderWithCalc(data[prop], hectoliterPerThousand, optionsMedida)} {label(optionsMedida)}</span>
          </S.TableCell>
      ))
  )
}


export const renderTotalData = (data, checkboxs, hectoliterPerThousand, optionsMedida) => (
  Object.keys(data[0]).
    filter(prop => getFilterCheckbox(checkboxs).includes(prop))
    .map(prop => (
      prop.includes("PlannedEntry") ?
        renderTotalEntrada(data, prop, hectoliterPerThousand, optionsMedida)
        :
        <S.TableCell background={getColorCell(prop)} color={getColorSpan(prop)}>
          <span className='title'>{intl.get(`bottles.keys.${prop}`)}:</span>
          <span>
            {renderWithCalc(data.reduce((total, item) => total + item[prop], 0), hectoliterPerThousand, optionsMedida)}
            {' '} {label(optionsMedida)}
          </span>
        </S.TableCell>
    ))
)

export const renderTotalEntrada = (data, prop, hectoliterPerThousand, optionsMedida) => {
  const plannedEntrys = data.map((entrys) => entrys.PlannedEntry)
  let total = 0

  plannedEntrys.map((entry) => {
    let valor = entry.reduce((total, e) => total + e.Quantity, 0)
    total = total + valor
  })

  return (
    <S.TableCell background={getColorCell("Entry")} color={getColorSpan("Entry")}>
      <span className='title'>{intl.get(`bottles.keys.${prop}`)}:</span>
      <span>{renderWithCalc(total, hectoliterPerThousand, optionsMedida)} {label(optionsMedida)}</span>
    </S.TableCell>
  )
}

export const renderPlannedEntry = (data, hectoliterPerThousand, optionsMedida) =>
  <S.TableCellEffect>
    <S.TableCell id='resumo' background={getColorCell("Entry")} color={getColorSpan("Entry")}>
      <span className='title'>{intl.get(`bottles.keys.PlannedEntry`)}:</span>
      <span>
        {renderWithCalc(data.reduce((total, item) => total + item.Quantity, 0), hectoliterPerThousand, optionsMedida)}
        {' '}{label(optionsMedida)}
      </span>
    </S.TableCell>
    <S.TableCell id='detail' background={getColorCell("Entry")} color={getColorSpan("Entry")}>
      <span className='title'>{intl.get(`bottles.keys.PlannedEntry`)}:</span>
      <S.TableColumn>
        {data.map(x => (
          <S.TableRow>
            <span
              title={x.SupplierName}
              className='entry-title'>
              {x.SupplierName}
            </span>
            <span>
              {renderWithCalc(x.Quantity, hectoliterPerThousand, optionsMedida)}
              {' '}{label(optionsMedida)}
            </span>
          </S.TableRow>
        ))}
      </S.TableColumn>
    </S.TableCell>
  </S.TableCellEffect>
