export const onChange = (value, index, field) => {
  if (value.length < 10 && value.length !== 0 && field === "CodigoImobilizado") {
    return {
      valor: 'error',
      posicao: index,
      tipo: "type"
    }
  }
  else {
    if (field === "CodigoImobilizado") {
      return {
        valor: value,
        posicao: index,
        tipo: field
      }
    }
    return {
      valor: value,
      posicao: index,
      tipo: field
    }
  }
}

