import PropTypes from "prop-types"
import React, { Component } from 'react'
import './notas-fiscais.css'

class NotasFiscais extends Component {
  static propTypes = {
    notasFiscais: PropTypes.shape({
      map: PropTypes.func
    }).isRequired
  }

  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className='ajuste-margin'>
        {
          this.props.notasFiscais.map((notaFiscal, index) => (
            <div className='negotiation-read-offer'>
              <div className='negotiation-read-column'>
                <span>{`${notaFiscal.TituloDocumento}: `}</span>
                <span>{notaFiscal.NumeroDocumento}</span>
              </div>
              {(notaFiscal.TituloNotaFiscal || notaFiscal.NumeroNotaFiscal) &&
                <div className='negotiation-read-column'>
                  <span>{`${notaFiscal.TituloNotaFiscal}: `}</span>
                  <span>{notaFiscal.NumeroNotaFiscal}</span>
                </div>
              }
            </div>
          ))
        }
      </div>
    )
  }
}

export default NotasFiscais
