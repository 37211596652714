import PropTypes from "prop-types"
import React, { useRef } from 'react'
import intl from 'react-intl-universal'
import { formatNumber } from 'utils/format';
import { DELIVERY_OPTION } from '../..';
import InputNumber from '../../../../../components/input-number';

import * as S from '../pedidos/styled'

const RowDataPedido = ({ data, handleChangePedido }) => {
  const inputRef = useRef(null);

  const handleSelectPartialDelivery = () => {
    handleChangePedido(data.IdPedido, 'deliveryOption', DELIVERY_OPTION.PartialDelivery)
    inputRef.current.focus();
  }

  return (
    <tr className="rowListHeader content">
      <td>{data.IdPedidoSap}</td>
      <td>{data.IdItemPedidoSap}</td>
      <td>{data.IdPedido}</td>
      <td>{data.Material}</td>
      <td>{data.Lote}</td>
      <td className="quantity">{formatNumber(data.Quantidade)}</td>
      <td>
        <S.Flex>
          <S.PartialFullDelivery
            isSelected={data.deliveryOption === DELIVERY_OPTION.PartialDelivery}
            onClick={handleSelectPartialDelivery}
          >
            {intl.get('commons.partialDelivery')}
          </S.PartialFullDelivery>
          <S.PartialFullDelivery
            isSelected={data.deliveryOption === DELIVERY_OPTION.FullDelivery}
            onClick={() => handleChangePedido(data.IdPedido, 'deliveryOption', DELIVERY_OPTION.FullDelivery)}
          >
            {intl.get('commons.fullDelivery')}
          </S.PartialFullDelivery>
        </S.Flex>
      </td>
      <td>
        <S.QuantityReveived
          visible={data.deliveryOption === DELIVERY_OPTION.FullDelivery}
          className="quantity-received">
          {formatNumber(data.Quantidade)}
        </S.QuantityReveived>
        <S.InputWrapper
          visible={data.deliveryOption === DELIVERY_OPTION.PartialDelivery}
        >
          <InputNumber
            value={data.quantidadeGRN}
            onChange={value => handleChangePedido(data.IdPedido, 'quantidadeGRN', value)}
            ref={inputRef}
            className='input-received-quantity'
            data-testid='input-quantity-grn'
          />
        </S.InputWrapper>
      </td>
    </tr>
  )
}

RowDataPedido.propTypes = {
  data: PropTypes.shape({
    IdItemPedidoSap: PropTypes.any,
    IdPedido: PropTypes.any,
    IdPedidoSap: PropTypes.any,
    Lote: PropTypes.any,
    Material: PropTypes.any,
    Quantidade: PropTypes.any,
    deliveryOption: PropTypes.any,
    quantidadeGRN: PropTypes.any
  }).isRequired,
  handleChangePedido: PropTypes.func.isRequired
}
export default RowDataPedido;
