import { Row, Col } from "react-flexbox-grid"
import { getDataFormat, DATA_FORMAT_TYPES } from "utils/format-date";

import { formatNumber } from "utils/format"
import * as S from './suggestions-list-header-content-styled'
import { formatHourTimeOrDefault } from "utils/delivery-time";
import PropTypes from 'prop-types'

const SuggestionsListHeaderContent = ({ isOrder, isOrderBulk, item, valueWmsTransactionIdOrPO, isHourlyPlan }) => {

  return (
    <Row middle="xs">
      <Col xs={isOrderBulk ? 2 : 1}>
        <div className="header-item" title={item.Status}>
          <S.InitialStatus alertOn={item.IntStatus === 18}> {item.StatusSigla} </S.InitialStatus>
        </div>
      </Col>
      <Col xs={isOrderBulk ? 2 : 1}>
        <div className="header-item" title={item.TipoDescricao}>
          {item.Tipo}
        </div>
      </Col>
      <Col xs={isHourlyPlan ? 1 : 2}>
        <div
          title={getDataFormat(item.Coleta, DATA_FORMAT_TYPES.WITH_YEAR)}
          className="header-item"
        >
          {getDataFormat(item.Coleta, DATA_FORMAT_TYPES.WITHOUT_YEAR)}
        </div>
      </Col>
      <Col xs={isHourlyPlan ? 1 : 2}>
        <div
          title={getDataFormat(
            item.DataEntregaDe,
            DATA_FORMAT_TYPES.WITH_YEAR
          )}
          className="header-item"
        >
          {getDataFormat(
            item.DataEntregaDe,
            DATA_FORMAT_TYPES.WITHOUT_YEAR
          )}
        </div>
      </Col>
      {isHourlyPlan && <Col xs={2}>
        <div
          title={item.HoraEntrega}
          className="header-item"
        >
          {formatHourTimeOrDefault(item.HoraEntrega)}
        </div>
      </Col>
      }
      <Col xs={2}>
        <div className="header-item" title={formatNumber(item.Total)}>
          {formatNumber(item.Total)}
        </div>
      </Col>
      <Col xs={2}>
        <div className="header-item">
          {isOrder ? item.Id : `V${item.Id}`}
        </div>
      </Col>
      {
        !isOrderBulk &&
        <Col xs={2}>
          <div className="header-item" title={valueWmsTransactionIdOrPO(item)}>
            {valueWmsTransactionIdOrPO(item)}
          </div>
        </Col>
      }
    </Row>
  )
}

SuggestionsListHeaderContent.propTypes = {
  isOrderBulk: PropTypes.bool,
  valueWmsTransactionIdOrPO: PropTypes.func,
  isHourlyPlan: PropTypes.bool,
  isOrder: PropTypes.bool,
  item: PropTypes.shape({
    Status: PropTypes.string,
    StatusSigla: PropTypes.string,
    IntStatus: PropTypes.number,
    TipoDescricao: PropTypes.string,
    Tipo: PropTypes.string,
    Coleta: PropTypes.string,
    DataEntregaDe: PropTypes.string,
    HoraEntrega: PropTypes.string,
    Total: PropTypes.number,
    Id: PropTypes.string,
    
  })
}

export default SuggestionsListHeaderContent
