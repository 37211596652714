import { parseFloatByLocale } from "locales/utils";
import { compareJson } from "utils/custom-functions";

export const getEmergencyNegotiationModel = (_infosRoute, _model, _dataDeColeta, _ordersMetadata, selectedLoads) => {
  return {
    IdComposicaoCarga: parseInt(_infosRoute.composicaoId),
    DataColetaProposta: _dataDeColeta,
    DataEntregaProposta: _model.dataEntregaComposicao,
    HoraEntregaProposta: _model.horaEntregaComposicao,
    Observacao: _model.breweryNote,
    PedidosMetaData: formatEmergencyNegotiationMetadata(_ordersMetadata, _dataDeColeta, _infosRoute, selectedLoads)
  }
};

export const formatEmergencyNegotiationMetadata = (_ordersMetadata, _dataDeColeta, _infosRoute, selectedLoads) => {
  const _renegociacaoAlteracaoPedidos = _ordersMetadata.AlteracaoPedidos
    .filter(x => !_ordersMetadata.AdicaoPedidos.includes(x.IdPedido)
      && !_ordersMetadata.RemocaoPedidos.includes(x.IdPedido))
    .map(x => ({
      IdPedido: x.IdPedido,
      DataColeta: _dataDeColeta,
      DataEntrega: _infosRoute.dia,
      HoraEntrega: _infosRoute.hora,
      Quantidade: x.Quantidade,
      Observacao: x.Observacao,
    }));

  const _renegociacaoAdicaoSugestao = _ordersMetadata.AdicaoSugestoes?.map(x => ({
    IdSugestao: x.IdSugestao,
    IdMaterial: x.IdMaterial,
    IdUnidadeNegocio: x.IdUnidadeNegocio,
    IdFornecedor: x.IdFornecedor,
    DataColeta: _dataDeColeta,
    DataEntrega: _infosRoute.dia,
    HoraEntrega: _infosRoute.hora,
    Quantidade: x.Total || x.Quantidade,
    Observacao: x.Observacao,
  }));

  const _renegociacaoAdicaoPedido = _ordersMetadata.AdicaoPedidos?.map(x => ({ IdPedido: x, Quantity: parseFloatByLocale(selectedLoads.find(y => y.IdPedido === x).Quantidade) }));
  const _renegociacaoRemocaoPedido = _ordersMetadata.RemocaoPedidos?.map(x => ({ IdPedido: x }));

  return {
    AdicaoPedidos: _renegociacaoAdicaoPedido,
    AlteracaoPedidos: _renegociacaoAlteracaoPedidos,
    RemocaoPedidos: _renegociacaoRemocaoPedido,
    AdicaoSugestoes: _renegociacaoAdicaoSugestao
  }
}

export const validateHasChangedInRenegotiation = (initialMetadata, loadComposition, ordersMetadata, infosRoute) => {
  const _newDate = {
    DataEntrega: infosRoute.dia?.slice(0, 10),
    HoraEntrega: infosRoute.hora?.slice(0, 5)
  }

  const _initialDate = {
    DataEntrega: loadComposition.DataEntrega?.slice(0, 10),
    HoraEntrega: loadComposition.HoraEntrega?.slice(0, 5)
  }

  const hasChangedLoadComposition = !compareJson(_newDate, _initialDate);

  const normalizedOrderMetadata = {
    AdicaoPedidos: ordersMetadata.AdicaoPedidos,
    AlteracaoPedidos: ordersMetadata.AlteracaoPedidos.sort((a, b) =>
      a.IdPedido.toString().localeCompare(b.IdPedido.toString())).map(x => ({
        IdPedido: x.IdPedido,
        Quantidade: x.Quantidade
      })),
    RemocaoPedidos: ordersMetadata.RemocaoPedidos,
    AdicaoSugestoes: ordersMetadata.AdicaoSugestoes.map(x => ({
      Quantidade: x.Quantidade,
      IdMaterial: x.IdMaterial,
      IdSugestao: x.IdSugestao
    })),
  }

  const normalizedInitialMetadata = {
    ...initialMetadata,
    AlteracaoPedidos: initialMetadata.AlteracaoPedidos.sort((a, b) =>
      a.IdPedido.toString().localeCompare(b.IdPedido.toString()))
  }

  const hasChangedOrders = !compareJson(normalizedInitialMetadata, normalizedOrderMetadata);
  return hasChangedOrders || hasChangedLoadComposition;
}