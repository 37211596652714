import { Component } from 'react'
import SnackBar from 'material-ui/Snackbar'
import ActionShoppingCart from 'material-ui/svg-icons/action/shopping-cart'
import Fetch from 'utils/fetch'
import Loading from 'components/center-loading'
import HeaderIcon from 'components/header-icon'
import DesabilitarMaterial from 'components/modal-message-dialog'
import MaterialList from './material-list'
import MaterialForm from './material-form'
import Message from 'components/message'
import Filtro from 'components/filters/filter-material'
import StorePersist from 'utils/store-persist'
import { Button, Layout } from 'components/uikit-adapter/index'
import ButtonNewItem from 'components/button-new-item'
import IconEmpty from 'images/iconEmpty.icon'
import intl from 'react-intl-universal'
import ConsultaContratosMaterial from 'components/modal-material-contract-list'

import './material.css'
import { formatErrorMessage } from '../../../utils/handle-error'
import { profileAdminOrMasterData } from "services/permissions/getPermissions";
import { IconFilterActive } from 'components/icons/icn-filter-active.icon'
import { IconFilterInactive } from 'components/icons/icn-filter-inactive.icon'

class Material extends Component {
  constructor() {
    super()

    this.state = {
      isFetching: false,
      materials: [],
      total: 0,
      currentPage: 0,
      qtRegsPerPage: 50,
      openForm: false,
      openEditMode: false,
      openDelete: false,
      openContracts: false,
      idSelected: 0,
      idMaterial: 0,
      showFeedback: false,
      messageFeedBack: '',
      showMsg: false,
      textMsg: '',
      showFilterMaterial: false,
      filters: {
        IdMaterial: null,
        IdFamilia: null,
        IdFamiliaRotulada: null,
        IdCarteira: null
      }
    }
  }

  UNSAFE_componentWillMount() {
    const filters = StorePersist.getValuesJSON('filtersMaterial')
    this.setState(prevState => ({ filters: filters ?? prevState.filters }))
    this.buscarMateriais(this.state.currentPage)
  }

  searchFilters = (filters) => {
    StorePersist.setValuesJSON('filtersMaterial', filters);
    this.showFilterMaterial()
    this.setState({
      currentPage: 0,
      filters
    }, () => this.buscarMateriais(this.state.currentPage))
  }

  showFilterMaterial = () => {
    this.setState(prevState => ({
      showFilterMaterial: !prevState.showFilterMaterial,
    }))
  }

  showFeedback = (message) => {
    this.setState({
      showFeedback: true,
      messageFeedBack: message
    })
  }

  closeFeedback = () => {
    this.setState({
      showFeedback: false
    })
  }

  openForm = () => {
    this.setState({
      openForm: true
    })
  }

  openNewForm = () => {
    this.setState({
      openEditMode: false
    }, () => this.openForm())
  }

  openEditForm = (row) => {
    const { materials } = this.state;
    const selected = materials[row]

    this.setState({
      openEditMode: true,
      idSelected: selected.Id
    }, () => this.openForm())
  }

  closeForm = () => {
    this.setState({
      openForm: false
    })
  }

  openDelete = (id) => {
    this.setState({
      openDelete: true,
      idSelected: id
    })
  }

  openContracts = (id) => {
    this.setState({
      openContracts: true,
      idMaterial: id
    })
  }

  closeDelete = () => {
    this.setState({
      openDelete: false
    })
  }

  closeContracts = () => {
    this.setState({
      openContracts: false
    })
  }

  handleFetch = (isFetching = true) => {
    this.setState(prevState => ({
      requestCount: isFetching ? prevState.requestCount + 1 : prevState.requestCount - 1,
    }))
  }

  refreshSearch = () => {
    this.closeForm()
    this.buscarMateriais(this.state.currentPage)
  }

  proccessData = (data) => {
    this.setState({
      materials: data.Data,
      total: data.TotalRecordCount
    })
  }

  buscarMateriais = (page) => {
    this.handleFetch()
    const nextPage = page == undefined ? this.state.currentPage : page;
    Fetch.post(`/material/consultapaginacaohabilitado/${this.state.qtRegsPerPage}/${nextPage}`, this.state.filters)
      .then((response) => this.proccessData(response.data))
      .then(() => this.setState({ currentPage: nextPage }))
      .catch((e) => console.log(e))
      .finally(() => this.handleFetch(false))
  }

  desabilitarMaterial = () => {
    const numeroMaterial = this.state.idSelected
    this.handleFetch()
    Fetch.post(`/material/desabilitar/${numeroMaterial}`)
      .then(() => this.buscarMateriais(this.state.currentPage))
      .then(this.closeDelete)
      .then(() => this.showFeedback(intl.get('master-data.feedback.materialSuccessfullyDisabled')))
      .catch((e) => this.proccessErro(e))
      .finally(() => this.handleFetch(false))
  }

  proccessErro = (ex) => {
    this.showFeedback(formatErrorMessage(ex))
  }

  verifyFilter = () => {
    return this.state.filters.IdCarteira > 0
      || this.state.filters.IdMaterial > 0
      || this.state.filters.IdFamilia > 0
      || this.state.filters.IdFamiliaRotulada > 0
  }

  render() {
    const {
      openForm,
      openEditMode,
      openDelete,
      openContracts,
      idSelected,
      idMaterial,
      materials,
      total,
      showFeedback,
      messageFeedBack,
      isFetching,
      showMsg,
      textMsg,
      showFilterMaterial,
      filters
    } = this.state

    return (
      <div className='material-container'>
        <Loading isLoading={isFetching} />

        <div className="material-header">
          <HeaderIcon
            title={intl.get('master-data.materials.header.title')}
            subtitle={intl.get('master-data.materials.header.subtitle')}
            icon={<ActionShoppingCart style={{ width: 45, height: 45 }} color='var(--color-brand-500)' />}
          >
            {profileAdminOrMasterData() &&
              <div title={intl.get('master-data.materials.actions.ableMaterial')}>
                <ButtonNewItem
                  className="general-button"
                  onClick={this.openNewForm}
                />
              </div>
            }
            <div title={intl.get('master-data.materials.actions.filterMaterial')} className='filterContainer'>
              <Button
                onClick={this.showFilterMaterial}
                type='default'
                buttonText={intl.get('filters.filter')}
                buttonIcon={this.verifyFilter() ? <IconFilterActive /> : <IconFilterInactive />}
                className={"button-filter"}
              />
            </div>
          </HeaderIcon>
        </div>
        <Layout.Sidebar width="450px"
          visible={showFilterMaterial}
          triggerClose={this.showFilterMaterial}
          background="var(--color-contrast-white)"
          offsetTop="96px"
          side="right"
          block
          float
          icon={<IconEmpty />}
        >
          <Filtro showFilter={showFilterMaterial} initialData={filters} handleClose={this.searchFilters} />
        </Layout.Sidebar>
        <MaterialList
          data={materials}
          currentPage={this.state.currentPage}
          countData={total}
          qtRegsPerPage={this.state.qtRegsPerPage}
          refreshSearch={this.buscarMateriais}
          handleEdit={this.openEditForm}
          handleDelete={this.openDelete}
          handleContracts={this.openContracts}
          profileAdminOrMasterData={profileAdminOrMasterData()}
          isFetching={this.state.isFetching}
        />
        {openForm &&
          <MaterialForm
            idEdit={idSelected}
            open={openForm}
            editMode={openEditMode}
            handleClose={this.closeForm}
            handleRefresh={this.refreshSearch}
            handleFeedback={this.showFeedback}
            profileAdminOrMasterData={profileAdminOrMasterData()}
          />
        }
        <DesabilitarMaterial
          open={openDelete}
          title={intl.get('master-data.materials.actions.disableMaterial')}
          principalButtonMessage={intl.get('master-data.materials.actions.disabled')}
          handleClose={this.closeDelete}
          warningMessage={intl.get('master-data.suppliers.ableSupplier.actions.disabledMessage')}
          handleConfirm={this.desabilitarMaterial}
        />
        {openContracts &&
          <ConsultaContratosMaterial
            open={openContracts}
            idMaterial={idMaterial}
            close={this.closeContracts}
          />
        }
        <Message
          show={showMsg}
          text={textMsg}
          title='Erro!'
          handleClose={() => this.setState({ showMsg: false })}
        />
        <SnackBar
          message={messageFeedBack}
          open={showFeedback}
          autoHideDuration={4000}
          onRequestClose={this.closeFeedback}
          bodyStyle={{ maxWidth: '700px', width: 'max-content' }}
        />
      </div>
    )
  }
}

export default Material
