export const getType = (value) => {
  let type = typeof(value)

  if (type === "object") {
    if (Array.isArray(value)) {
      type = "Array"
    }
  }
  return type
}
