import PropTypes from "prop-types"
import React, { useState } from 'react'
import intl from 'react-intl-universal'
import SearchIcon from 'images/search-icon.svg'
import { ExitNewTabInput, NewTabWrapper, NewTabInput } from '../bottles-tabs/styled'
import { BottlesInputRegisteredSupplierList } from '../bottles-input-registered-supplier-list'
import * as S from './styled'

export const BottlesInputRegisteredSupplier = ({ close, onKeyUp }) => {
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <React.Fragment>
      <NewTabWrapper>
        <NewTabInput
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder={intl.get('commons.supplier')}
        />
        <S.Container>
          <S.Message>
            <img src={SearchIcon} />
            <span>{intl.get('bottles.searchBarText')}</span>
          </S.Message>
          <BottlesInputRegisteredSupplierList
            handleEvent={onKeyUp}
            searchTerm={searchTerm}
          />
        </S.Container>
      </NewTabWrapper>
      <ExitNewTabInput onClick={close}>X</ExitNewTabInput>
    </React.Fragment>
  )
}

BottlesInputRegisteredSupplier.propTypes = {
  close: PropTypes.any.isRequired,
  onKeyUp: PropTypes.any.isRequired
}

