import style from 'styled-components'

export const Button = style.div`
  width: 40px;
  height: 40px;
  line-height: 38px;
  text-align: center;
  color: var(--color-brand-300);
  font-weight: var(--font-weight-semibold);
  font-size: 14px;
  background-color: var(--color-contrast-white);
  margin-top: 5px;
  border-radius: 2px;
  box-shadow: inset -1px 0 0 0 var(--color-neutral-300), inset 0 -1px 0 0 var(--color-neutral-300), inset 1px 0 0 0 var(--color-neutral-300), inset 0 2px 0 0 var(--color-action-hover);
  cursor: pointer;
`

export const GridButton = style.div`
  color: var(--color-action-default);
  background-color: var(--color-neutral-200);
  border: solid 1px var(--color-action-hover);
  width: 48px;
  line-height: 16px;
  text-align: center;
  font-size: 13px;
  font-weight: var(--font-weight-semibold);
  padding: 11px;
  cursor: pointer;
  border-radius: 2px;

  :hover {
    background-color: var(--color-neutral-200);
    border: solid 1px var(--color-brand-200);
  }
`