import styled from "styled-components";

export const StyledDatePicker = styled.div`
    label {
        line-height: 1.8rem;
        font-size: 12px;
        font-weight: var(--font-weight-semibold);
        text-transform: uppercase;
        color: var(--color-contrast-brand);
    }
    .picker-container {
        position: relative;
    }
    .react-datepicker-wrapper {
        width: 100%;
    }
    .date-picker {
        background-color: ${props => props.disabled ? 'var(--color-neutral-200)' : 'var(--color-contrast-white)'};
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
        box-sizing: border-box;
        font-size: 14px;
        color: var(--color-contrast-brand);
        height: 40px;
        outline: 0;
        border: 1px;
        border-radius: 2px;
        box-shadow: inset -1px 0 0 0 var(--color-neutral-300), inset 0 -1px 0 0 var(--color-neutral-300), inset 1px 0 0 0 var(--color-neutral-300), inset 0 2px 0 0 ${props => props.disabled ? 'var(--color-neutral-300)' : 'var(--color-action-hover)'};
        -webkit-transition: .25s box-shadow;
        transition: .25s box-shadow;
    }

    .react-datepicker__day--selected {
        background-color: var(--color-brand-300);
    }
    
    .react-datepicker__day--keyboard-selected {
        background-color: var(--color-brand-300);
    }

    .react-datepicker__close-icon::after {
        background-color: var(--color-brand-300);
    } 

    ${p => p.lastInput && '.react-datepicker { left: -40px; }'}
`;

