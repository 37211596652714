import { IProductionItem } from "features/supplier-production-plan/interface";
import moment from "moment";
import intl from "react-intl-universal";
import { compareDates } from "utils/date";

export const validateSupplierProductionPlan = (plan: IProductionItem) => {
  return new Promise<void>((resolve, reject) => {
    if(noValueFilledInBySupplier(plan)) reject(new Error(intl.get('supplierProductionPlan.validationMessages.enterLeastOnePropertie')));
    else if(!Number(plan.PendingQuantity)) reject(new Error(intl.get('supplierProductionPlan.validationMessages.pendingQuantityFieldUnIsMandatory')));
    else if(!Number(plan.PendingQuantityPallets)) reject(new Error(intl.get('supplierProductionPlan.validationMessages.pendingQuantityFieldPlIsMandatory')));
    else if(!plan.ProductionDate) reject(new Error(intl.get('supplierProductionPlan.validationMessages.productionDateFieldIsMandatory')));
    else if(!!plan.ProductionDate && isEarlierThanToday(plan.ProductionDate)) reject(new Error(intl.get('supplierProductionPlan.validationMessages.productionDateCannotEarlierThanCurrentDay')));
    else if(!Number(plan.ProductionQuantity)) reject(new Error(intl.get('supplierProductionPlan.validationMessages.producedQuantityFieldIsMandatory')));
    else resolve();
  });
}

const noValueFilledInBySupplier = (plan: IProductionItem) => !(Number(plan.PendingQuantity) || Number(plan.PendingQuantityPallets) || plan.ProductionDate || Number(plan.ProductionQuantity));

const isEarlierThanToday = (_productionDate: string) => {
  const _comparationResult = compareDates(_productionDate, moment().format('YYYY-MM-DD')) ?? -1;

  return _comparationResult < 0;
}
