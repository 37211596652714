import { GeneralSettingParameter } from "models/generalSetting/generalSetting";
import { getGeneralSettingValue } from "services/general-settings/general-settings";
import intl from 'react-intl-universal';

export const isLoadCompositionFlowBrewerySide = () => getGeneralSettingValue(GeneralSettingParameter.LoadCompositionNewFlow);
export const isLoadCompositionFlowSupplierSide = () => !getGeneralSettingValue(GeneralSettingParameter.LoadCompositionNewFlow);

export const isScheduleAgreementFlow = () => getGeneralSettingValue(GeneralSettingParameter.OrderSettings);
export const isPurchaseOrderFlow = () => !getGeneralSettingValue(GeneralSettingParameter.OrderSettings);
export const getListCodeHeaderAbbreviated = () => isScheduleAgreementFlow() ? intl.get('commons.tranId') : intl.get('commons.po');

export const usesJustificationValidationAcceptingSuggestion = () => getGeneralSettingValue(GeneralSettingParameter.ValidateJustification);

export const usesSharedWeeklyForecastWithSupplier = () => getGeneralSettingValue(GeneralSettingParameter.UseSharedWeeklyForecastWithSupplier);

export const usesPrimaryMaterial = () => getGeneralSettingValue(GeneralSettingParameter.UsePrimaryMaterial);

export const usesAutoLoadOptimization = () => getGeneralSettingValue(GeneralSettingParameter.UseAutoLoadOptimization);

export const usesPrimaryMaterialSchedule = () => getGeneralSettingValue(GeneralSettingParameter.UsePrimaryMaterialSchedule);

export const showInboundTransfer = () => getGeneralSettingValue(GeneralSettingParameter.ShowInboundTransfer);

export const usesCreateGRN = () => getGeneralSettingValue(GeneralSettingParameter.UseCreateGRN);

export const usesTransferOrder = () => getGeneralSettingValue(GeneralSettingParameter.UseTransferOrder);

export const usesTmsIntegration = () => getGeneralSettingValue(GeneralSettingParameter.UseTMSIntegration);

export const hideSomeSettingsLabeledFamily = () => getGeneralSettingValue(GeneralSettingParameter.HideSomeSettingsLabeledFamily);

export const usesReturnableAssets = () => getGeneralSettingValue(GeneralSettingParameter.UseReturnableAssets);

export const usesOORRelevance = () => getGeneralSettingValue(GeneralSettingParameter.UseOORRelevance);

export const usesContingencyRequest = () => getGeneralSettingValue(GeneralSettingParameter.UseContingencyRequest);

export const usesBottlesPlan = () => getGeneralSettingValue(GeneralSettingParameter.UseBottlesPlan);

export const usesViewPriceColumnsOnLoadCompositionView = () => getGeneralSettingValue(GeneralSettingParameter.ViewPriceColumnsOnLoadCompositionView);

export const usesLoadOptimizationViaPortal = () => getGeneralSettingValue(GeneralSettingParameter.UseLoadOptimizationViaPortal);

export const usesVolume = () => getGeneralSettingValue(GeneralSettingParameter.UseVolume);

export const usesEmergencyNegotiation = () => getGeneralSettingValue(GeneralSettingParameter.NewFlowEmergencyRenegotiation);

export const usesHourlyPlan = () => getGeneralSettingValue(GeneralSettingParameter.UseHourlyPlan);