import PropTypes from "prop-types"
import { Fragment, useState } from 'react'
import * as S from './styled'
import intl from 'react-intl-universal'
import { Droppable } from 'react-beautiful-dnd';
import { useGlobalContext } from 'hooks/useGlobalContext';
import { formatErrorMessage } from '../../../../utils/handle-error'
import CancelDialog from '../cancel-modal'
import { useLoadCompositionBuildingContext } from '../../context/load-composition-building-context'
import Loading from 'components/center-loading'
import SupplierInfos from '../../../load-composition/components/supplier-negotiation-infos'
import ButtonAccept from './components/buttonAccept'
import { SituacaoComposicaoCarga } from '../../../../models/composicaoCarga/situacaoComposicaoCarga'
import CardsContent from './components/cardsContent'
import DestinationBooking from './components/destinationBooking';
import { isScheduleAgreementFlow } from 'utils/validations-general-settings';
import { cancelLoadComposition } from 'pages/load-composition-building/load-composition-building.service';
import { isSimpleCompositionCancellation } from 'pages/load-composition-building/utils';
import { parseFloatByLocale } from "locales/utils";
import { getValueOrDefault } from 'utils/custom-functions';

const Sidebar = ({ data, history, openModal, queryParams }) => {
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [showLoad, setShowLoad] = useState(false)

  const {
    isEditDateMode,
    params,
    pushPath,
    selectedLoads,
    isEmergencyNegotiation,
    loadComposition
  } = useLoadCompositionBuildingContext();
  const { showFeedback } = useGlobalContext();

  const isEditLoadComposition = !!params.composicaoId;

  const getQuantidadeLotes = () => {
    const quantidade = data.map(x => parseFloatByLocale(x.TotalPallets)).reduce((x, y) => Math.ceil(parseFloatByLocale(x)) + Math.ceil(parseFloatByLocale(y)));
    return `${quantidade} ${getValueOrDefault(data[0].LotesUnidadeMedida, "")}`
  }

  const cancel = () => {
    isEditLoadComposition
      ? setShowCancelModal(true)
      : cancelModalValidations()
  }

  const cancelModalValidations = () => {
    if (data && data.length > 0) {
      setShowCancelModal(true)
    } else {
      history.push(pushPath)
    }
  }

  const proccessError = (e) => {
    showFeedback(formatErrorMessage(e))
  }

  const getOriginLabel = () => data[0] ? data[0].Origem : ' - ';

  const isShowSuppliersNotes = (compositionStatus) => {
    return isEditLoadComposition && (compositionStatus == SituacaoComposicaoCarga.EmNegociacao || compositionStatus == SituacaoComposicaoCarga.NegociacaoEmergencial);
  }

  const isSimpleCompositionCancellationMessage = () => isSimpleCompositionCancellation(selectedLoads)
    ? intl.get("loadComposition.cancelModal.messageEmptyComposition")
    : intl.get("loadComposition.cancelModal.message");

  const isSimpleCompositionCancellationAction = async () => {
    if (isSimpleCompositionCancellation(selectedLoads)) {
      const url = isScheduleAgreementFlow() ? `/load-compositions:cancel-by-schedule-agreement` : `load-compositions:cancel-by-purchase-order`
      await cancelLoadComposition(url, { LoadCompositionId: loadComposition.IdComposicao });
    }

    history.push(pushPath);
  }

  const isValidDay = () => params.dia !== '0000-00-00';

  return (
    <Fragment>
      <Loading isLoading={showLoad} />
      <Droppable droppableId="loads-suggestions-selecteds">
        {(provided) => (
          <S.Wrapper className="loads-suggestions-selecteds" {...provided.droppableProps} innerRef={provided.innerRef}>
            <S.WrapperHeader>
              <S.Title data-testid={`load-composition-${params.composicaoId}`}>{intl.get('commons.composition')}{isEditLoadComposition && ` - ${params.composicaoId}`}</S.Title>
              {isEmergencyNegotiation && <S.Subtitle data-testid="emergency-negotiation-text">{intl.get('commons.emergencyNegotiation')}</S.Subtitle>}
              <S.InfosWrapper>
                <div className='last-content'>
                  <span className='title'>{intl.get("stocks.suggestionsListItem.origin")}</span>
                  <span className='description' data-testid={`load-composition-origin`}>{getOriginLabel()}</span>
                </div>
              </S.InfosWrapper>
              <DestinationBooking
                isValidDay={isValidDay()}
                queryParams={queryParams}
              />
            </S.WrapperHeader>
            {isShowSuppliersNotes(loadComposition.SituacaoValor) &&
              <S.SupplierInfosWrapper>
                <SupplierInfos
                  composicao={loadComposition}
                  showDrop
                />
              </S.SupplierInfosWrapper>
            }
            <CardsContent
              isEditLoadComposition={isShowSuppliersNotes(loadComposition.SituacaoValor)}
              providedDragPlaceholder={provided.placeholder}
              openModal={openModal}
              data={data}
            />
            <S.WrapperFooter>
              <S.InfosWrapper isFooter>
                <div className='content'>
                  <span className='title'>{intl.get('loadComposition.building.totalPalletCount')}</span>
                  <span className='description' data-testid={`load-composition-total-palets`}>{data.length > 0 ? getQuantidadeLotes() : '-'}</span>
                </div>
              </S.InfosWrapper>
              <S.ActionsWrapper className='card-label'>
                <S.ButtonEdit
                  className='load-composition-cancel'
                  onClick={cancel}
                  type='default'
                  value={intl.get('geral.buttonsDefault.cancel')}
                />
                <ButtonAccept
                  isEditDateMode={isEditDateMode}
                  data={data}
                  setShowLoad={setShowLoad}
                  infosRoute={params}
                  proccessError={proccessError}
                  pushPath={pushPath}
                  history={history}
                  composicao={loadComposition}
                  disabled={!isValidDay()}
                  queryParams={queryParams}
                />
              </S.ActionsWrapper>
            </S.WrapperFooter>
          </S.Wrapper >
        )}
      </Droppable>
      {showCancelModal &&
        <CancelDialog
          title={intl.get("loadComposition.cancelModal.title")}
          open={showCancelModal}
          confirm={() => isSimpleCompositionCancellationAction()}
          close={() => setShowCancelModal(false)}
          message={isSimpleCompositionCancellationMessage()}
        />
      }
    </Fragment>
  )
}

Sidebar.propTypes = {
  data: PropTypes.shape({
    length: PropTypes.number,
    map: PropTypes.func
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  openModal: PropTypes.any.isRequired,
  queryParams: PropTypes.any.isRequired
}

export default Sidebar;
