import styled from 'styled-components'
import * as Flexbox from "react-flexbox-grid";

export const Wrapper = styled.div`
  .suggestion-list-header {
    box-sizing: border-box;
    padding-bottom: 5px;
  }

  .stock-content{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 15px;
    color: var(--color-contrast-brand);
  }

  border-bottom: ${props => !props.itemExpanded ? '1px solid #EDEEEE' : '1px none #EDEEEE'};
  margin-bottom: 10px;
`

export const StockTitle = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
  color: #9AA4AD;
`

export const DepositoTitle = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 13px;
  color: #63676B;
`

export const TitlesWrapper = styled.div`
  width: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  padding: 0px 5px;
  margin-bottom: 10px;
`

export const ContentWrapper = styled.div`
  display: ${props => props.itemExpanded ? 'flex' : 'none'};
  padding: 0 5px;
  flex-direction: column;
`

export const ContentRow = styled(Flexbox.Row)`
  width: 100%;
  padding: 0px 0px 10px 10px;
`

export const LastUpdate = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 10px;
  color: #8A8E94;
`

export const InfosWrapper = styled.div`
  display: flex;
  flex-direction: column;

  span{
    margin: 0px;
    padding: 10px 5px 0px 0px;
  }
`
export const TitleCatergory = styled.span`
   font-style: normal;
   font-weight: bold;
   font-size: 15px;
   line-height: 13px;
   color: var(--color-action-default);
`
export const Row = styled(Flexbox.Row)`
  width: 100%;
`
export const ColLast = styled(Flexbox.Col)`
    border-bottom: 1px solid #EDEEEE;
    padding-bottom: 5px;
    display: flex;
    justify-content: end;
`
export const ColFirst = styled(Flexbox.Col)`
    border-bottom: 1px solid #EDEEEE;
    padding-bottom: 5px;
`
export const MessageFeedback = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 10px;
  color: #8A8E94;
  margin: 0px 0px 10px 20px;
`
