import {
  ClbTag
} from '@celebration/design-system-react'
import { Wrapper } from './styled';
import PropTypes from 'prop-types'

export const StatusStock = ({ status }) => {
  let title, iconType, color;
  switch (status) {
    case 0:
      iconType = 'positive'
      title = "Ok";
      color = getComputedStyle(document.body).getPropertyValue('--color-feedback-positive-600');
      break;
    case 1:
      iconType = 'warning'
      title = "Over";
      color = getComputedStyle(document.body).getPropertyValue('--color-feedback-warning-600');
      break;
    case 2:
      iconType = 'negative'
      title = "Out";
      color = getComputedStyle(document.body).getPropertyValue('--color-feedback-danger-600');
      break;
    case 4:
    default:
      iconType = ''
      title = "Inactive";
      color = getComputedStyle(document.body).getPropertyValue('--color-contrast-brand');
      break;
  }

  return (
    <Wrapper color={color}>
      <ClbTag id="tag" label={title} type={iconType} />
    </Wrapper>
  )
}

StatusStock.propTypes = {
  status: PropTypes.number
}