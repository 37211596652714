import React from "react"
import PropTypes from "prop-types"
import intl from 'react-intl-universal'
import moment from "moment"
import * as S from '../../styled'
import PedidosAprovados from "pages/composicao-carga/pedidos-aprovados"
import { Row, Col } from 'react-flexbox-grid';
import { Input } from '@hbsis.uikit/react';
import DateInput from 'components/date-input';
import Loading from "components/center-loading";
import { SelectBox } from "components/uikit-adapter"
import { showDeliveryTime } from "utils/delivery-time"
import { getObservationLabelAbbreviated, getObservationValue } from "pages/load-composition/utils/observation"
import { SituacaoComposicaoCarga } from "models/composicaoCarga/situacaoComposicaoCarga"

const ModalInfosLoadComposition = ({
  unidadeNegocio,
  fornecedor,
  pedidos,
  isLoading,
  composicao,
  redirectRoute }) => (
  <S.InfosCompositionWrapper>
    <Row>
      <Col xs={12}>
        <S.SecondTitle>
          {`${intl.get('menu.subMenuGeneral.loadComposition')}: ${composicao.IdComposicao}`}
          {SituacaoComposicaoCarga.NegociacaoEmergencial == composicao.SituacaoValor &&
            <label className='warning-waiting-return'>{intl.get("commons.emergencyNegotiation")} - {intl.get("commons.waitingReturn")}</label>
          }
        </S.SecondTitle>
      </Col>
    </Row>
    <Row className="row-wrapper">
      <Col className="col-select-box">
        <SelectBox
          disabled
          name='unidadeNegocio'
          label={intl.get('commons.destiny')}
          valueKey='Id'
          labelKey='Descricao'
          options={unidadeNegocio}
          value={unidadeNegocio[0]}
          data-testid='modal-load-composition-destination'
        />
      </Col>
      <Col className="col-select-box">
        <SelectBox
          disabled
          name='fornecedor'
          label={intl.get('transports.compoCharge.actions.unityBussines')}
          valueKey='Id'
          labelKey='Descricao'
          options={fornecedor}
          value={fornecedor[0]}
          data-testid='modal-load-composition-supplier'
        />
      </Col>
      <Col className="col-date" data-testid='modal-load-composition-pickup-date'>
        <DateInput
          disabled
          label={intl.get('commons.colDate')}
          value={composicao.DataColeta}
          minDate={moment().format("YYYY-MM-DD")}
          maxDate="9999-12-31"
          data-testid='modal-load-composition-teste'
        />
      </Col>
      <Col className="col-date" data-testid='modal-load-composition-delivery-date'>
        <DateInput
          disabled
          label={intl.get('commons.deliveryDate')}
          value={composicao.DataEntrega}
          minDate={moment().format("YYYY-MM-DD")}
          maxDate="9999-12-31"
        />
      </Col>
      {
        showDeliveryTime(composicao.HoraEntrega) &&
        <Col className='col-time' data-testid='modal-load-composition-delivery-time' style={{ paddingLeft: '2px', paddingRight: '2px' }}>
          <Input
            disabled
            label={intl.get('commons.deliveryTime')}
            name='HoraEntrega'
            htmlType='time'
            value={composicao.HoraEntrega}
            className='input'
          />
        </Col>
      }
      <Col className="col-observation" data-testid='modal-load-composition-brewere-notes'>
        <Input
          disabled
          name="txbObservacao"
          label={getObservationLabelAbbreviated()}
          title={getObservationValue(composicao.BreweryNote, composicao.Observacao)}
          value={getObservationValue(composicao.BreweryNote, composicao.Observacao)}
          width="90%"
          style={{ textOverflow: 'ellipsis' }}
          className='input'
        />
      </Col>
    </Row>
    {pedidos.length > 0 &&
      <div className='list-pedidos' data-testid='list-order'>
        <PedidosAprovados
          pedidos={pedidos}
          enableSelected={false}
          redirectRoute={redirectRoute}
        />
      </div>
    }
    <Loading
      isLoading={isLoading}
      fullHeight
    />
  </S.InfosCompositionWrapper>
)

ModalInfosLoadComposition.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  unidadeNegocio: PropTypes.array.isRequired,
  fornecedor: PropTypes.array.isRequired,
  pedidos: PropTypes.array.isRequired,
  composicao: PropTypes.object.isRequired,
  redirectRoute: PropTypes.func.isRequired
};

export default ModalInfosLoadComposition;
