export const Incoterms = [
    {
        Key: 0,
        Value: '',
    },
    {
        Key: 1,
        Value: 'CIF',
    },
    {
        Key: 2,
        Value: 'FOB',
    },
    {
        Key: 3,
        Value: 'EXW',
    },
    {
        Key: 4,
        Value: 'FCA',
    },
    {
        Key: 5,
        Value: 'FAS',
    },
    {
        Key: 6,
        Value: 'CPT',
    },
    {
        Key: 7,
        Value: 'CIP',
    },
    {
        Key: 8,
        Value: 'CFR',
    },
    {
        Key: 9,
        Value: 'DAP',
    },
    {
        Key: 10,
        Value: 'DPU',
    },
    {
        Key: 11,
        Value: 'DDP',
    },
];
