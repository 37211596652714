import { Button, TextButton } from '../404.styled';
import intl from 'react-intl-universal';
import { filtersClearAllFilters } from 'utils/filters';
import Session from 'utils/user-storage';
import { authProvider } from 'providers/authProvider';

const LogoutFromB2C = () => {  
  const logout = () => {
    Session.clear();
    filtersClearAllFilters();
    authProvider().logout();
  }

  return (
    <Button
      className='logout__button'
      style={{ width: '50%' }}
      type='button'
      onClick={logout}
    >
      <TextButton>{intl.get('errorPage.button')}</TextButton>
    </Button>
  )
}

export default LogoutFromB2C;