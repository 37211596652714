import { useState } from "react";
import Header from "components/header";
import intl from 'react-intl-universal'
import { LoadBuildingFilter } from "../load-building-filter";
import { IconWrapper, Wrapper } from "./styled";
import { ClbButtonIcon } from "libs/celebration";
import { LoadBuildingDrawerFilter } from "../load-building-filter/load-building-drawer-filter";
import { LoadBuildingTable } from "../load-building-table";
import { LoadBuildingPagination } from "../load-building-pagination";
import { LoadBuildingFullPageFlow } from "../load-building-full-page-flow";

export const LoadBuilding = () => {
  const [isOpenDrawerFilter, setIsOpenDrawerFilter] = useState(false);
  return (
    <>
      <Header
        title={intl.get('commons.loadBuilding')}
        titleWidth={460}
        noBackground
      >
        <IconWrapper>
          <ClbButtonIcon
            icon="Filter"
            size="lg"
            data-testid='btn-drawer'
            onClbClick={() => setIsOpenDrawerFilter(true)}
          />
          <LoadBuildingDrawerFilter
            isOpen={isOpenDrawerFilter}
            handleClose={() => setIsOpenDrawerFilter(false)}
          />
        </IconWrapper>
      </Header>
      <Wrapper>
        <LoadBuildingFilter />
        <LoadBuildingTable />
        <LoadBuildingPagination />
      </Wrapper>
      <LoadBuildingFullPageFlow />
    </>
  )
}
