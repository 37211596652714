import { useConsolidatedStocksContext } from 'features/consolidated-stocks/context'
import { LinesPerPageFilter } from './lines-per-page-filter'
import Pagination from 'components/pagination'
import * as S from './styled'
import { initialFilterValues } from '../consolidated-stocks-filter/utils'

export const ConsolidatedStocksPagination = () => {
  const { filter, setFilter, quantityRegisters } = useConsolidatedStocksContext();

  const handlePagination = (value) => {
    setFilter(prevState => ({
      ...prevState,
      _page: value
    }))
  }

  const handleChangeLinesPerPage = (e) => {
    setFilter(prevState => ({
      ...prevState,
      _size: e.value,
      _page: initialFilterValues._page
    }))
  }

  return (
    <S.Wrapper>
      <S.Content>
        <LinesPerPageFilter
          onChange={handleChangeLinesPerPage}
          value={filter._size}
          menuPlacement="top"
        />
        <Pagination
          refreshSearch={handlePagination}
          page={filter._page}
          pageSize={filter._size}
          amount={quantityRegisters}
        />
      </S.Content>
    </S.Wrapper>
  )
}