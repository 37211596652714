export const convertToQueryParameters = (obj) => {
  let str = [];
  for (let p in obj)
    if (obj.hasOwnProperty(p) && (obj[p] != undefined)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  return str.join("&");
}

export const downloadFile = (filename, extension, fileContent) => {
  const linkDownload = document.createElement('a')
  linkDownload.href = `data:attachment/${extension};base64,${fileContent}`
  linkDownload.download = `${filename}`
  linkDownload.click()
}


export const generateQueryList = (array, prop) => {
  if (array.filter(Boolean).length <= 0) {
    return ''
  }

  const values = array.map(x => `${prop}=${x}`)
  return values.length === 1 ? `${values[0]}&` : `${values.join("&")}&`
}

export const generatePaginatedQuery = (page, size) => `_page=${page}&_size=${size}`;
export const generateOrderQuery = (order) => `_order=${order}`;

export const generateQueryParams = (obj) => {
  let _params = '';

  Object.keys(obj).forEach((key) => {
    if (Array.isArray(obj[key])) {
      _params = _params.concat(generateQueryList(obj[key], key))
    } else {
      _params = _params.concat(!isEmpty(obj[key]) ? `${key}=${obj[key]}&` : '')
    }
  });

  return _params;
}

export const downloadFileTxt = (filename, text) => {
  const element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export const getValueOrDefault = (value, defaultValue) => value ? value : defaultValue;

export const getValueForCondition = (condition, trueOption, falseOption) => condition ? trueOption : falseOption;

export const verifyIsFiltered = (filters) =>
  Object.values({ ...filters, _size: null, _page: null, _order: null }).some(x => {
    if (Array.isArray(x)) {
      return x.length > 0
    }
    if (typeof x === 'object') {
      return objectIsNotEmpty(x);
    }
    return x;
  });

export const validatesArrayHasValue = (data) => {
  return !!data && data.length > 0
}

export const isString = (value) => (typeof value) === 'string'

export const compareJson = (arg1, arg2) => {
  if (Object.prototype.toString.call(arg1) === Object.prototype.toString.call(arg2)) {
    if (Object.prototype.toString.call(arg1) === '[object Object]' || Object.prototype.toString.call(arg1) === '[object Array]') {
      if (Object.keys(arg1).length !== Object.keys(arg2).length) {
        return false;
      }
      return (Object.keys(arg1).every(function (key) {
        return compareJson(arg1[key], arg2[key]);
      }));
    }
    return (arg1 === arg2);
  }
  return false;
}

const isEmpty = (value) => {
  return (value == null || (typeof value === "string" && value.trim().length === 0));
}

export const objectIsNotEmpty = (obj) => {
  if(obj == null || obj == undefined) return false;
  
  return !!Object.values(obj).length;
}