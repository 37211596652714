import Fetch from 'utils/fetch';

export const postAcceptOrders = (idUsuario, model) => Fetch.post(`/timeline/aceiteemmassa/fornecedor/${idUsuario}`, model).then(({ data }) => data);

export const getOrderDownloadFile = () => Fetch.get(`orders:export-order-negotiation`).then(({ data }) => data);

export const getRevisionDownloadFile = () => Fetch.get(`orders:export-order-review`).then(({ data }) => data)

export const aceiteAtualizado = (dto) => Fetch.post("/timeline/pedidoVolume/aceiteAtualizado", dto).then(({ data }) => data);

export const downloadPedidoVolumesEmNegociacao = (idsPedidoVolume) => Fetch.put('pedidoVolume/export/pedidoVolumeEmNegociacao', { Ids: idsPedidoVolume })
  .then(({ data }) => data)
