import { useSupplierProductionPlanContext } from "features/supplier-production-plan/context";
import { useEffect, useState } from "react";
import { SelectBox, Button } from "components/uikit-adapter";
import { Layout } from "@hbsis.uikit/react";
import * as S from "./styled";
import { Row, Col } from "react-flexbox-grid";
import intl from "react-intl-universal";
import { supplierProductionPlanFiltersDefault, supplierProductionPlanSelectsFilters, validateFilters } from "features/supplier-production-plan/utils";
import { searchBusinessUnit, searchMaterials, searchSuppliers, searchSupplierUsers } from "services/filters.service";
import { getUserId, isUseFilterDefault, isUserSupplier } from "utils/user";
import PropTypes from 'prop-types';
import { generateQueryList, generateQueryParams, objectIsNotEmpty, validatesArrayHasValue, verifyIsFiltered } from "utils/custom-functions";
import storePersist from "utils/store-persist";

const SidebarFilter = ({ open, closeFilter, search }) => {
  const { filters, setFilters, loading, setLoading, setProductionPlanData } = useSupplierProductionPlanContext();
  const selectsFilters = supplierProductionPlanSelectsFilters();
  const [optionsSelectsFilters, setOptionsSelectsFilters] = useState({});
  const [filtersSelected, setFiltersSelected] = useState(filters);

  const loadSupplierUsersOptions = async (_filters) => {
    try {
      setLoading(true);
      const _supplierUsers = await searchSupplierUsers();

      if(isUserSupplier()){
        const _userId = getUserId();
        const _supplierUser = _supplierUsers?.find(user => user.Id === _userId);

        if(_filters.SupplierGroupId?.Id !== _supplierUser.Id || !_filters.SupplierGroupId?.Id) {
          setFiltersSelected({
            ...supplierProductionPlanFiltersDefault,
            SupplierGroupId: _supplierUser,
          });
          handleSearch({ SupplierGroupId: _supplierUser})
        }

        setOptionsSelectsFilters({
          ...supplierProductionPlanFiltersDefault,
          SupplierGroupId: _supplierUsers            
        });
      } else {
        setOptionsSelectsFilters(prevState => ({
          ...prevState,
          SupplierGroupId: _supplierUsers
        }));
      }
    } catch(e){
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  const loadSuppliersOptions = async (id) => {
    if(!id){
      setFiltersSelected(prevState => ({
        ...prevState,
        Suppliers: null,
        Materials: null,
        BusinessUnits: null
      }));

      setOptionsSelectsFilters(prevState => ({
        ...prevState,
        Suppliers: null,
        Materials: null,
        BusinessUnits: null
      }));
    } else {
      try {
        setLoading(true);
        const _query = `UserId=${id}`
        const _suppliers = await searchSuppliers(isUseFilterDefault(), _query);

        setFiltersSelected(prevState => ({
          ...prevState,
          Suppliers: validateFilters(filtersSelected.Suppliers, _suppliers)
        }));

        setOptionsSelectsFilters(prevState => ({
          ...prevState,
          Suppliers: _suppliers,
          Materials: null,
          BusinessUnits: null
        }))
      } catch(e){
        console.error(e);
      } finally {
        setLoading(false);
      }
    }
  }

  const loadMaterialsOptions = async (value) => {
    if(!validatesArrayHasValue(value)){
      setFiltersSelected(prevState => ({
        ...prevState,
        Materials: null,
        BusinessUnits: null
      }));

      setOptionsSelectsFilters(prevState => ({
        ...prevState,
        Materials: null,
        BusinessUnits: null
      }));
    } else {
      try {
        setLoading(true);
        const _suppliersIds = value?.map((v) => v.Id);
        const _query = generateQueryList(_suppliersIds, 'SupplierId')

        const _materials = await searchMaterials(isUseFilterDefault(), _query);
        const _formatMaterials = _materials?.map(material => ({ ...material, Id: material.MaterialNumber}));

        setFiltersSelected(prevState => ({
          ...prevState,
          Materials: validateFilters(filtersSelected.Materials, _formatMaterials)
        }));

        setOptionsSelectsFilters(prevState => ({
          ...prevState,
          Materials: _formatMaterials,
          BusinessUnits: null
        }));
      } catch(e){
        console.error(e);
      } finally {
        setLoading(false);
      }
    }
  }

  const loadBusinessUnitsOptions = async (value) => {
    if(!validatesArrayHasValue(value)){
      setFiltersSelected(prevState => ({
        ...prevState,
        BusinessUnits: null
      }));

      setOptionsSelectsFilters(prevState => ({
        ...prevState,
        BusinessUnits: null
      }));
    } else {
      try {
        setLoading(true);
        const _suppliersIds = filtersSelected?.Suppliers?.map((v) => v.Id);
        const _materialsNumbers = value?.map((v) => v.Id);
      
        const _queryObject = {
          'SupplierId': _suppliersIds,
          'MaterialNumber': _materialsNumbers
        }
        const _query = generateQueryParams(_queryObject);
      
        const _businessUnits = await searchBusinessUnit(isUseFilterDefault(), _query);

        setFiltersSelected(prevState => ({
          ...prevState,
          BusinessUnits: validateFilters(filtersSelected.BusinessUnits, _businessUnits)
        }));
        
        setOptionsSelectsFilters(prevState => ({
          ...prevState,
          BusinessUnits: _businessUnits
        }))
      } catch(e){
        console.error(e);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleChange = (value, field) => {
    setFiltersSelected(prevState => ({
      ...prevState,
      [field]: value
    }))

    switch (field) {
      case 'SupplierGroupId':
        loadSuppliersOptions(value?.Id);
        break;
      case 'Suppliers':
        loadMaterialsOptions(value);
        break;
      case 'Materials':
        loadBusinessUnitsOptions(value);
        break;
    }
  }

  const handleSearch = (filters) => {
    setFilters(filters);
    search(filters);
  };

  const clearFilter = () => {
    setOptionsSelectsFilters({});
    setFiltersSelected(supplierProductionPlanFiltersDefault);
    setFilters(supplierProductionPlanFiltersDefault);
    setProductionPlanData(null);
    storePersist.setValuesJSON("filtersSupplierProductionPlan", supplierProductionPlanFiltersDefault);
    loadSupplierUsersOptions(supplierProductionPlanFiltersDefault);
    
    closeFilter();
  };

  useEffect(() => {
    async function loadFilters(){
      if(verifyIsFiltered(filtersSelected)) handleSearch(filtersSelected);
      await loadSupplierUsersOptions(filtersSelected);
    }
    
    loadFilters();
  }, []);

  useEffect(() => {
    async function loadFiltersOptions() {
      if(filtersSelected.SupplierGroupId?.Id && !optionsSelectsFilters.Suppliers) await loadSuppliersOptions(filtersSelected.SupplierGroupId.Id);
      else if(filtersSelected.Suppliers && !optionsSelectsFilters.Materials) await loadMaterialsOptions(filtersSelected.Suppliers);
      else if(filtersSelected.Materials && !optionsSelectsFilters.BusinessUnits) await loadBusinessUnitsOptions(filtersSelected.Materials); 
    }

    if(objectIsNotEmpty(optionsSelectsFilters) && !loading){
      loadFiltersOptions();
    }
  }, [optionsSelectsFilters])

  return (
    selectsFilters && (
      <Layout.Sidebar
        width="450px"
        visible={open}
        triggerClose={() => closeFilter}
        background="var(--color-contrast-white)"
        offsetTop="96px"
        side="right"
        block
        float
      >
        <S.FilterContainer>
          <S.FilterContainerFields>
            {selectsFilters.map(
              (selectFilter) =>
                !selectFilter.hidden && (
                  <SelectBox
                    key={selectFilter.name}
                    {...selectFilter}
                    value={filtersSelected[selectFilter.name]}
                    onChange={(value) => handleChange(value, selectFilter.name)}
                    options={optionsSelectsFilters[selectFilter.name]}
                  />
                )
            )}
          </S.FilterContainerFields>

          <S.FilterContainerFooter>
            <Row>
              <Col xs={6} lg={6} md={6}>
                <Button
                  type="primary"
                  value={intl.get("filters.search")}
                  onClick={() => handleSearch(filtersSelected)}
                  disabled={!verifyIsFiltered(filtersSelected)}
                />
              </Col>
              <Col xs={6} lg={6} md={6}>
                <Button
                  type="secondary"
                  value={intl.get("filters.cleanSearch")}
                  onClick={clearFilter}
                />
              </Col>
            </Row>
          </S.FilterContainerFooter>
        </S.FilterContainer>
      </Layout.Sidebar>
    )
  );
};

SidebarFilter.propTypes = {
  open: PropTypes.bool,
  closeFilter: PropTypes.func,
  search: PropTypes.func
}

export default SidebarFilter;
