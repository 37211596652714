import styled from "styled-components";

export const DialogHeader = styled.div`
  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title {
    color: var(--color-contrast-brand);
    font-size: 24px;
    font-weight: var(--font-weight-semibold);
  }

  .content div {
    display: flex;
    align-items: center;
  }

  .content div:first-child {
    margin-right: 8px;
  }
`;

export const DialogContent = styled.div`
  margin-top: 40px;

  .column-row {
    display:flex;
  }

  .horizontal {
    display: flex;
    margin-top: 8px;
  }

  .column-flow {
    display: flex;
    flex-flow: column;
    margin-bottom: 15px;
    margin-right: 10px;
  }

  .column-header {
    color: var(--color-neutral-500);
    font-size:16px;
  }

  .column-text {
    color: var(--color-contrast-brand);
    font-weight: var(--font-weight-medium);
    font-size: 16x;
  }

  .main-header {
    color: var(--color-action-default);
    font-size: 21px;
    font-weight: var(--font-weight-semibold);
  }

  .separator {
    width: 2px;
    height: 35px;
    background: var(--color-neutral-300) 0% 0% no-repeat padding-box;
    opacity: 1;
    margin-right: 10px;
  }

  hr {
    width: 100%;
    background-color: var(--color-neutral-500);
    opacity: .3;
    height: 1px;
    display: block;
    margin-top: 10px;
  }

`;

export const Badge = styled.div`  
  padding: 8px;
  border-radius: 10px;
  background-color: var(--color-neutral-200);

  .text {
    font-size: 14px;
    color: var(--color-brand-400);
    font-weight: var(--font-weight-semibold)
  }
`;

export const Divider = styled.div`  
  width: 100%;
  background-color: var(--color-neutral-500);
  opacity: .3;
  height: 2px;
  display: block;
  margin-top: 20px;
  position: absolute;
  left: 0;

`;

export const BottomDivider = styled.div`
  width: 100%;
  background-color: var(--color-neutral-500);
  opacity: .3;
  height: 2px;
  display: block;
  margin-bottom: 4px;
  position: absolute;
  left: 0;
  bottom: 80px;
`