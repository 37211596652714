const IconStop = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      className="hovered-paths"
      viewBox="1 1 512 512"
    >
      <g fill="var(--color-action-default)">
        <path
          d="M256 0C114.613 0 0 114.613 0 256s114.613 256 256 256 256-114.613 256-256C511.832 114.684 397.316.168 256 0zm0 480C132.29 480 32 379.71 32 256S132.29 32 256 32s224 100.29 224 224c-.133 123.656-100.344 223.867-224 224zm0 0"
          className="active-path"
          data-old_color="var(--color-contrast-brand)"
          data-original="var(--color-contrast-brand)"
        />
        <path
          d="M380.45 131.55c-6.25-6.245-16.38-6.245-22.626 0L256 233.376 154.176 131.551c-6.14-6.356-16.27-6.531-22.625-.39-6.356 6.136-6.531 16.265-.39 22.62.128.133.257.266.39.395L233.375 256 131.551 357.824c-6.356 6.137-6.531 16.266-.39 22.625 6.136 6.356 16.265 6.531 22.62.39.133-.128.266-.257.395-.39L256 278.625l101.824 101.824c6.356 6.137 16.485 5.961 22.621-.394 5.989-6.2 5.989-16.032 0-22.23L278.625 256l101.824-101.824c6.246-6.246 6.246-16.375 0-22.625zm0 0"
          className="active-path"
          data-old_color="var(--color-contrast-brand)"
          data-original="var(--color-contrast-brand)"
        />
      </g>
    </svg>
  )
  export default IconStop