import styled from 'styled-components'
import { Input as InputComponent } from 'components/uikit-adapter/index'

export const WrapperFilter = styled.div`
  display: flex;
  padding: 20px;
`
export const Input = styled(InputComponent)`
  width: 268px;
`
