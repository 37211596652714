import ReactECharts from 'echarts-for-react';
import { getOption } from 'components/chart-stocks/utils';
import { generateRandomNumber } from 'utils/calculations';
import * as S from './styled'
import { useState } from 'react';
import FullScreenIcon from 'components/icons/full-screen-icon';

interface IChart {
  echart: object,
  style?: React.CSSProperties
  lazyUpdate?: boolean,
  notMerge?: boolean
}

export const Chart = ({ echart, ...rest }: IChart) => {
  const idChart = `chart-container-${generateRandomNumber()}`
  const [isHovered, setIsHovered] = useState(false);

  const handleFullScreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.getElementById(idChart)!.requestFullscreen();
    }
  }

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = event.target as HTMLElement;
    const rect = target.getBoundingClientRect();
    const mouseY = event.clientY - rect.top;

    if (mouseY < 340) {
      setIsHovered(true);
    } else {
      setIsHovered(false);
    }
  };

  return (
    <S.Wrapper id={idChart}>
      <S.Content>
        {echart &&
          <>
            <S.IconFullScreen
              data-testid='icn-full-screen'
              onClick={handleFullScreen}
            >
              <FullScreenIcon />
            </S.IconFullScreen>
            <S.WrapperChart
              isHovered={isHovered}
              onMouseMove={handleMouseMove}
            >
              <ReactECharts
                option={getOption(echart)}
                style={{ height: '400px' }}
                {...rest}
              />
            </S.WrapperChart>
          </>
        }
      </S.Content>
    </S.Wrapper>
  )
}