import styled from "styled-components";

const StyledForm = styled.div`
  position: relative;

 .sideFilterFields {
    overflow-y: scroll;
    background: var(--color-contrast-white);
    height: calc(100vh - 231px);
    padding: 15px 20px 0;
  }

  .footerFilterContainer {
    width: 100%;
    position: absolute;
    bottom: 0px;
    background: var(--color-contrast-white);
    padding: 15px 5px 15px 20px;

    .filter-divisor {
      background-color: rgba(197, 208, 225, 0.6);
      height: 1px;
      width: 100%;
      color: rgba(197, 208, 225, 0.6);
      border: none;
    }
  }

  .container {
    width: 97% !important;
    padding: 7px;
    margin-top: -16px;
    border: 1px solid var(--color-neutral-300) !important;
  }

  fieldset {
    color: var(--color-contrast-brand);
    width: 95.5%;
    height: 70px;
    font-size: 14px;
    border: 1px solid var(--color-neutral-300);
    margin-left: 0;
  }

  fieldset h5 {
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    margin: 0.3em;
    font: 400 13.3333px Arial;
  }

  legend {
    line-height: 1.8rem;
    font-size: 12px;
    font-weight: var(--font-weight-semibold);
    text-transform: uppercase;
    color: var(--color-contrast-brand);
  }

  .fieldsetMateriais {
    overflow-y: scroll;
    width: 100%;
    height: 40px;
  }

  .auto-complete {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  ::-webkit-inner-spin-button {
    display: none;
  }

  .span-message {
    font-size: 14px;
    float: right;
    margin-top: -10px;
    color: #e8b007;
  }
`;
export default StyledForm;
