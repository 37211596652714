import React from 'react'
import StyledCardDivisor from './styled'

const CardDivisor = () => (
  <StyledCardDivisor className="divisorContainer">
    <StyledCardDivisor className="cardDivisor" />
  </StyledCardDivisor>
)

export default CardDivisor
