import { firstSection } from "./firstSection";
import { secondSection } from "./secondSection";

const keyName = "columnConfigurationItensCriticos";

/**
 * Este js será usado apenas enquanto não tiver está logica no backend
 */
const visible = [...firstSection, ...secondSection];

const hidden = [
  { name: "Motivo", translation: "Reason" },
  { name: "Coordenação", translation: "Coordination" },
  { name: "Família", translation: "Family" },
  { name: "Família Rotulada", translation: "Labeled Family" },
];

const ColumnsItensCriticos = {
  Visible: visible,
  Hidden: hidden,
};

export default ColumnsItensCriticos;
export const saveConfiguredColumn = (cols) =>
  localStorage.setItem(keyName, JSON.stringify(cols));
