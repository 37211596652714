import React from 'react'
import PropTypes from 'prop-types'
import intl from 'react-intl-universal'

const LoadCompositionNumber = ({
  idLoadComposition
}) => (
  <div>
    <div className="negotiation-read-offer">
      <div className="negotiation-read-column">
        <span>{intl.get('menu.subMenuGeneral.loadComposition')}:</span>
        <span>{idLoadComposition}</span>
      </div>
    </div>
  </div>
);

LoadCompositionNumber.propTypes = {
  idLoadComposition: PropTypes.number.isRequired,
}

export default LoadCompositionNumber
