import { SchedulingAutoPilotGeneralDto } from "types/labeled-family-center/auto-pilot/scheduling-auto-pilot-general-dto"
import Fetch from "utils/fetch"

export const getSchedulingAutoPilot = (id: number): Promise<SchedulingAutoPilotGeneralDto> => {
  return Fetch.get(`labeled-family-centers/${id}:auto-pilot-schedule`).then(({ data }) => data)
}

export const saveSchedulingAutoPilot = (id: number, body: SchedulingAutoPilotGeneralDto): Promise<null> => {
  return Fetch.post(`labeled-family-centers/${id}:save-auto-pilot-schedule`, body).then(() => null)
}
