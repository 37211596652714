import { firstSection } from "./first-section";
import { secondSection } from "./secondSection";
const keyName = "columnConfiguration";

/**
 * Esse js apenas será usado enquanto não houver logica de colunas salvas no backend
 */
const visible = [...firstSection, ...secondSection];

const hidden = [
  { name: "Estoque Liberado Valor", translation: "Available Stock Value" },
  { name: "Estoque Bloqueado Valor", translation: "Blocked Stock Value" },
  { name: "Riscos Passivos Quantidade", translation: "Passive Risks Quantity" },
  { name: "Riscos Passivos Valor", translation: "Passive Risks Value" },
];

const Columns = {
  Visible: visible,
  Hidden: hidden,
};

export default Columns;
export const saveConfiguredColumn = (cols) =>
  localStorage.setItem(keyName, JSON.stringify(cols));
