import Fetch from "utils/fetch";

export const fetchImo = idBusinessUnit => (
  Fetch.get(`imobilizado/obterImobilizados/${idBusinessUnit}`).then(x => x.data)
)

export const saveImo = data => (
  Fetch.post(`imobilizado/salvar`, { Imobilizados: data })
)

export const getImobilizadoDownloadFile = () => {
  return Fetch.get(`imobilizado/export`).then(({ data }) => data)
}