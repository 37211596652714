
import PropTypes from "prop-types"
import intl from 'react-intl-universal'
import {
  ClbTableTHeadCell,
  ClbText,
} from '@celebration/design-system-react'
import moment from 'moment'

import * as S from './styled'

export const MainTableHead = ({ data }) => (
  <S.WrapperScrollableTable className='scroller'>
    {
      data?.StockProjections.map(x => (
        <ClbTableTHeadCell>
          <ClbText slot="content">
            <S.SlotScrollableCell>
              <S.SlotDateCell>
                <span className='head'>{intl.get(`weekdays.${moment(x.Date).weekday()}`)}</span>
                <span className='head'>{moment(x.Date).format("L")}</span>
              </S.SlotDateCell>
            </S.SlotScrollableCell>
          </ClbText>
        </ClbTableTHeadCell>
      ))
    }
  </S.WrapperScrollableTable>
)

MainTableHead.propTypes = {
  data: PropTypes.shape({
    StockProjections: PropTypes.shape({
      map: PropTypes.func
    })
  }).isRequired
}