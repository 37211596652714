import styled from "styled-components";

export const Wrapper = styled.div`
  margin-top: 72px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const WrapperDatePicker = styled.div`
  display: flex;
  gap: 16px;

  .datepicker{
    width: 50%;
  }
`