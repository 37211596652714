import styled from "styled-components"

export const TabsWrapper = styled.div`
  background-color: white;
  margin: 12px 12px;

  .active {
    background-color: var(--color-brand-400);
    padding: 8px;
    border-radius: 3px 3px 0 0;
    color: white;
    font-weight: bold;
  }

  .no-event {
    background-color: #C2C5C9;
    pointer-events: none;
  }
`
export const TabsTitle = styled.div`
  padding: 12px 12px 8px 0;
  font-size: 14px;
  font-weight: bold;
  color: var(--color-action-default);
  display: flex;
  justify-content: space-between;

  div {
    display: flex
  }
`

export const TabsHeader = styled.div`
  display: flex;
  align-items: center;

  #loading-tab{
    position: relative;
    width: auto;
    height: 0px;
    margin-left: 7px;
    transform: scale(0.75);
  }

  .react-tabs__tab-list {
    padding: 0;
    margin: 0;
    display: flex;
    list-style-type: none;
    font-size: 14px;
  }

  .react-tabs__tab {
    display: flex;
    padding: 8px;
    cursor: pointer;
  }
`
export const TabsContent = styled.div`
`
export const NewTabButton = styled.div`
  margin-left: 5px;
  padding: 3px;
  width: 19px;
  background-color: var(--color-brand-400);
  font-size: 16px;
  font-weight: 800;
  border-radius: 50%;
  text-align: center;
  color: white;
  cursor: pointer;
`
export const NewTabInput = styled.input`
  background-color: var(--color-neutral-200);
  border: 1px solid #C2C5C9;
  border-radius: 3px;

  &:focus {
    outline: none;
  }
`
export const NewTabWrapper = styled.div`
  background-color: var(--color-brand-400);
  padding: 6px;
  border-radius: 3px 3px 0 0;
  margin-left: 10px;
`
export const ExitNewTabInput = styled.div`
  position: relative;
  right: 18px;
  font-size: 12px;
  cursor: pointer;
`
export const DeleteSimulation = styled.div`
  color: white;
  font-weight: bold;
  padding-left: 5.5px;
`
export const TabTitle = styled.div`
  text-align: center;
  padding: 3px;
  color: ${p => p.color};
`
export const TabTitleText = styled.div`
  align-items: center;
  padding-left: 8px;
`
export const TabTitleTextWarning = styled.div`
  align-items: center;
  padding-left: 8px;
  color: #FFAA00;
  font-weight: var(--font-weight-regular);
  display: flex;
`

