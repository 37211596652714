import React, { useState, useEffect } from 'react'
import intl from 'react-intl-universal'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { BottleTable } from '../../bottles-table'
import { useBottlesContext } from '../../../context'
import * as S from '../styled'

export const BottlesBreweryTabs = () => {
	const { data } = useBottlesContext()
	const [tabIndex, setTabIndex] = useState(0)
	const [factories, setFactories] = useState([])

	useEffect(() => {
		if (data) {
			setFactories(data.factories)
		}
	}, [data])

	return (
		<S.TabsWrapper>
			<Tabs
				selectedTabClassName="active"
				selectedIndex={tabIndex}
				onSelect={index => setTabIndex(index)}
			>
				<S.TabsTitle>
					<div>
						<S.TabTitleText>{intl.get('bottles.factories')}</S.TabTitleText>
					</div>
				</S.TabsTitle>
				<S.TabsHeader>
					<TabList >
						{
							factories.map((tab, index) => (
								<Tab key={index} data-testid={`tab__${index}`}>
									<S.TabTitle>
										{tab.Name}
									</S.TabTitle>
								</Tab>
							))
						}
					</TabList>
				</S.TabsHeader>
				<S.TabsContent>
					{
						factories.map((row, index) => (
							<TabPanel key={index}>
								<BottleTable
									data={row.Data}
									simulado={row.IsGhostSupplier}
									type={'factorie'}
								/>
							</TabPanel>
						))
					}
				</S.TabsContent>
			</Tabs >
		</S.TabsWrapper >
	)
}