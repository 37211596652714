import React from 'react'
import { ZsagProvider } from './context/zsagContext'
import AutoLoadOptimization from './autoLoadOptimization'

const AutoLoadOptimizationList = () => (
  <ZsagProvider>
    <AutoLoadOptimization />
  </ZsagProvider>
)

export default AutoLoadOptimizationList
