import intl from 'react-intl-universal'

export const validationMessage = (isOrder, item, origem, origemUnidades) => {
  if (isOrder && !item.IdUnidadeNegocio) {
    return intl.get('manual-suggestion-order-form.formValidation.destiny')
  }
  else if (isOrder && !item.IdFamiliaRotulada) {
    return intl.get('manual-suggestion-order-form.formValidation.labeled-family')
  }
  else if (!item.IdMaterial) {
    return intl.get('manual-suggestion-order-form.formValidation.material')
  }
  else if (!item.IdFornecedor) {
    return intl.get('manual-suggestion-order-form.formValidation.supplier')
  }
  else if (!item.Coleta) {
    return intl.get('manual-suggestion-order-form.formValidation.collect')
  }
  else if (!item.DataEntregaDe) {
    return intl.get('manual-suggestion-order-form.formValidation.deliveryDate')
  }
  else if (!item.Quantidade) {
    return intl.get('manual-suggestion-order-form.formValidation.quantity')
  }
  else if (origem == origemUnidades.value && !item.OrganizacaoCompras) {
    return intl.get('manual-suggestion-order-form.formValidation.purchasingOrganization')
  }
  else if (origem == origemUnidades.value && !item.GrupoCompradores) {
    return intl.get('manual-suggestion-order-form.formValidation.buyerGroup')
  }
  else if (origem == origemUnidades.value && !item.CodigoImposto) {
    return intl.get('manual-suggestion-order-form.formValidation.taxCode')
  }
  return null;
}