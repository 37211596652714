import React from 'react'
import PropTypes from 'prop-types'
import StyledOption from './styledTabs'

class Option extends React.Component {

  constructor(props) {
    super(props)
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props !== nextProps || this.state !== nextState
  }

  render () {
    return (
      <StyledOption
        {...this.props}
        className={`${this.props.active} ${this.props.className}`}
        onClick={(e) => this.props.handleClickIcon(e, this.props.value)}
      >
        {this.props.children}
      </StyledOption>
    );
  }
}

Option.propTypes = {
  active: PropTypes.string,
  value: PropTypes.string,
  handleClickIcon: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element
  ]).isRequired
}

Option.defaultProps = {
  selected: 0,
}

export default Option

