import { Component } from 'react'
import PropTypes from 'prop-types'
import Fetch from 'utils/fetch'
import Dialog from 'material-ui/Dialog'

import { Input } from '@hbsis.uikit/react'
import { Button } from 'components/uikit-adapter'
import intl from 'react-intl-universal'

import './coordination-form.css'
import { formatErrorMessage } from 'utils/handle-error';

class CoordinationForm extends Component {
  constructor() {
    super()

    this.state = {
      coordenacao: {}
    }
  }

  proccessErro = (exception) => {
    this.props.handleFeedback(formatErrorMessage(exception))
  }

  searchCoordinationById = () => {
    const idcoordenacao = this.props.idEdit

    Fetch.get(`/coordenacao/detail/${idcoordenacao}`)
      .then((response) => this.proccessData(response.data))
      .catch((e) => this.proccessErro(e))
  }

  proccessData = (data) => {
    this.setState({ coordenacao: data })
  }

  componentDidMount() {
    const { editMode } = this.props
    if (editMode) {
      this.searchCoordinationById()
    }
  }

  changeValue = (event) => {
    const prop = event.currentTarget.name
    const value = event.currentTarget.value

    this.setState(prevState => ({
      coordenacao: {
        ...prevState.coordenacao,
        [prop]: value
      }
    }))
  }

  save = () => {
    const editMode = this.props.editMode
    const coordinationId = this.props.idEdit

    let model = this.state.coordenacao
    let coordinationsModel = {
      Description: model.Description
    }

    const promisse = !editMode
      ? Fetch.post(`/coordenacao`, model)
      : Fetch.put(`/coordinations/${coordinationId}`, coordinationsModel)

    promisse.then(this.props.handleRefresh)
      .then(() => this.props.handleFeedback(`${intl.get('feedbacks.register')} ${!editMode ? intl.get('feedbacks.saved') : intl.get('feedbacks.updated')} ${intl.get('feedbacks.withSuccess')}`))
      .catch((e) => this.proccessErro(e))
  }

  render() {
    let { coordenacao } = this.state
    let { open, editMode, profileAdminOrMasterData } = this.props

    return (
      <div>
        <Dialog
          title={editMode ? intl.get('master-data.coordinationsAndUsers.coordinations.actions.editCoordination') : intl.get('master-data.coordinationsAndUsers.coordinations.actions.newCoordination')}
          contentStyle={{ width: '600px' }}
          open={open}
          autoScrollBodyContent
        >

            <div className='line-1-columns'>
              <Input
                className='input'
                required
                name='Description'
                label={intl.get('commons.description')}
                value={coordenacao.Description}
                onChange={this.changeValue}
                disabled={!profileAdminOrMasterData}
              />
            </div>

            <div className='footer-dialog-buttons'>
              <div className='line-buttons'>
                <Button
                  type="default"
                  value={intl.get('geral.buttonsDefault.cancel')}
                  className="button"
                  onClick={() => { this.props.handleClose() }}
                />

              { profileAdminOrMasterData &&
                <Button
                  type="primary"
                  value={intl.get('geral.buttonsDefault.save')}
                  className="button"
                  onClick={this.save}
                />
              }
              </div>
            </div>
        </Dialog>
      </div>
    )
  }
}

CoordinationForm.propTypes = {
  editMode: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleFeedback: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  idEdit: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  profileAdminOrMasterData: PropTypes.func.isRequired
}

export default CoordinationForm
