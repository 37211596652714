import React, { Component } from 'react'
import { Tabs, Pane } from 'components/uikit-adapter/index'
import GradeContent from './component/grade-content'
import { GRADE_DESCARGA, GRADE_CARREGAMENTO } from './constants'
import {
  searchGradeDescarga,
  searchGradeCarregamento,
  saveGradeDescarga,
  saveGradeCarregamento
} from './grade.service'

import GradeStyle from './grade.styled'
import intl from 'react-intl-universal'

class Grade extends Component {
  constructor() {
    super()
  }

  render() {
    return (
      <GradeStyle>
        <Tabs selected={0}>
          <Pane label={intl.get('master-data.managementUnity.grid.dischargeDegree.header.title')}>
            <GradeContent
              type={GRADE_DESCARGA}
              filters="filtersGradeDescarga"
              searchGrade={searchGradeDescarga}
              saveGrade={saveGradeDescarga}
              findIndexUpdateList={(listaGrade, grade) => listaGrade.findIndex(g => g.IdFamilia == grade.IdFamilia)}
              saveMessageSucess={intl.get('master-data.managementUnity.grid.dischargeDegree.header.messageSuccess')}
              title={intl.get('master-data.managementUnity.grid.dischargeDegree.header.title')}
              subTitle={intl.get('master-data.managementUnity.grid.dischargeDegree.header.subtitle')}
            />
          </Pane>
          <Pane label={intl.get('master-data.managementUnity.grid.loadingGrid.header.title')}>
            <GradeContent
              type={GRADE_CARREGAMENTO}
              filters="filtersGradeCarregamento"
              searchGrade={searchGradeCarregamento}
              saveGrade={saveGradeCarregamento}
              findIndexUpdateList={(listaGrade, grade) => listaGrade.findIndex(g => g.IdOrigem == grade.IdOrigem)}
              saveMessageSucess={intl.get('master-data.managementUnity.grid.loadingGrid.header.messageSuccess')}
              title={intl.get('master-data.managementUnity.grid.loadingGrid.header.title')}
              subTitle={intl.get('master-data.managementUnity.grid.loadingGrid.header.subtitle')}
            />
          </Pane>
        </Tabs>
      </GradeStyle>
    )
  }
}

export default Grade
