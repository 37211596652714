import React from 'react'
import { ZapmslProvider } from './context/zapmslContext'
import PrimaryMaterialSchedule from './primaryMaterialScheduleList'

const PrimaryMaterialScheduleList = () => (
  <ZapmslProvider>
    <PrimaryMaterialSchedule />
  </ZapmslProvider>
)

export default PrimaryMaterialScheduleList;
