import React, { useEffect, useState } from 'react'
import intl from 'react-intl-universal'
import * as S from './styled'
import Loading from 'components/center-loading'
import HeaderIcon from 'components/header-icon'
import {
  PermissionsProfileItem
} from 'components/icons/icn-index.icon'
import ListPermissionsSettings from './list'
import {
  getEnumProfilePermission,
  getEnumProfileTypesAccess,
  getAccessProfile,
  postAccessProfile
} from './permissions-settings.service'
import SnackBar from 'material-ui/Snackbar'
import { formatErrorMessage } from 'utils/handle-error'

const PermissionsSettings = () => {
  const [profilePermissions, setProfilePermissions] = useState([]);
  const [profileTypesAccess, setProfileTypesAccess] = useState([]);
  const [accessProfile, setAccessProfile] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [feedback, setFeedback] = useState({ showFeedback: false, messageFeedback: '' })

  const changeCheckBoxValue = (profilePermission, profileTypeAccess) => {
    const data = accessProfile;
    const index = accessProfile.findIndex(x => x.TipoPerfilAcesso === profileTypeAccess);
    const isChecked = accessProfile.some(x => x.TipoPerfilAcesso === profileTypeAccess && x.ProfilePermissions.includes(profilePermission));
    if (isChecked) {
      data[index].ProfilePermissions = data[index].ProfilePermissions.filter(x => x !== profilePermission);
    }
    else {
      if (index >= 0) {
        data[index].ProfilePermissions.push(profilePermission);
      }
      else {
        const newAccessProfile = {
          TipoPerfilAcesso: profileTypeAccess,
          ProfilePermissions: [profilePermission]
        }
        data.push(newAccessProfile);
      }
    }
    setAccessProfile([...data]);
  }

  const save = async () => {
    try {
      setIsLoading(true)
      await postAccessProfile({ AccessProfileRequestDto: accessProfile })
      showFeedback(intl.get('master-data.feedback.savedSuccessfully'));
    }
    catch (e) {
      showFeedback(formatErrorMessage(e));
    }
    finally {
      setIsLoading(false);
    }
  }

  const searchEnumProfilePermission = async () => {
    const data = await getEnumProfilePermission();
    setProfilePermissions(data);
  }

  const searchEnumProfileTypesAccess = async () => {
    const data = await getEnumProfileTypesAccess();
    setProfileTypesAccess(data);
  }

  const searchAccessProfiles = async () => {
    try {
      setIsLoading(true)
      const data = await getAccessProfile();
      setAccessProfile(data);
    }
    catch (e) {
      showFeedback(formatErrorMessage(e));
    }
    finally {
      setIsLoading(false);
    }
  }

  const showFeedback = (message) => setFeedback({ showFeedback: true, messageFeedback: message });
  const closeFeedback = () => setFeedback({ showFeedback: false, messageFeedback: '' });

  useEffect(() => {
    searchEnumProfilePermission();
    searchEnumProfileTypesAccess();
    searchAccessProfiles();
  }, [])

  return (
    <S.Container>
      <Loading isLoading={isLoading} />
      <S.HeaderContainer>
        <HeaderIcon
          title={intl.get('master-data.permissions-settings.title')}
          subtitle={intl.get('master-data.permissions-settings.subtitle')}
          icon={<PermissionsProfileItem width='40px' height='40px' />}
        />
      </S.HeaderContainer>
      <ListPermissionsSettings
        profilePermissions={profilePermissions}
        profileTypesAccess={profileTypesAccess}
        accessProfile={accessProfile}
        onChanceCheckBox={changeCheckBoxValue}
        save={save}
      />
      <SnackBar
        message={feedback.messageFeedback}
        open={feedback.showFeedback}
        autoHideDuration={3000}
        onRequestClose={closeFeedback}
      />
    </S.Container>
  )
}

export default PermissionsSettings;
