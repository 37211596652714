import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'
import { Radio, RadioGroup } from '@hbsis.uikit/react'
import StyledOption from './styledTextInput'

class OptionGroup extends Component {

  render() {
    const {
      title,
      handleSelect,
      options,
      dataTestId,
      disabled
    } = this.props

    return (
      <StyledOption>
      <div className='select-origem-row'>
      <Row>
        <label className='select-origem-label'>{title}</label>
      </Row>
        <Row>

          <Col>
            <div className='select-origem'>
              <RadioGroup
                directionItems='row'
                label="Origem"
                name={`Options${options.id}`}
                onChange={handleSelect}
              >
                {!options.optionOne.hideOption ? (<Radio
                  checked={options.optionOne.isChecked}
                  text={options.optionOne.name}
                  value={options.optionOne.value}
                  data-testid={`${dataTestId}-first`}
                  disabled={disabled}
                />): <div></div>}
                {!options.optionTwo.hideOption ? (<Radio
                  checked={options.optionTwo.isChecked}
                  text={options.optionTwo.name}
                  value={options.optionTwo.value}
                  data-testid={`${dataTestId}-second`}
                  disabled={disabled}
                />): <div></div>}
              </RadioGroup>
            </div>
          </Col>
        </Row>
      </div>
      </StyledOption>
    )
  }
}

OptionGroup.propTypes = {
  title: PropTypes.string.isRequired,
  handleSelect: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  dataTestId: PropTypes.string,
  disabled: PropTypes.bool
}

export default OptionGroup
