
import React from 'react'
import PropTypes from 'prop-types'
import intl from 'react-intl-universal'
import './origem-alterada-pedido.css'

const OrigemAlteradaPedido = ({
  origemAnterior,
  origemAtual,
  usuario,
  codigoSap
}) => (
  <div>    
    <div className="negotiation-read-offer">
      <div className="negotiation-read-column">
        <span>{intl.get('stocks.timeline.previousOrigin')}</span>
        <span>{origemAnterior}</span>
      </div>
      <div className="negotiation-read-column">
        <span />
        <span />
      </div>
    </div>   
    <div className="negotiation-read-offer">
      <div className="negotiation-read-column">
        <span>{intl.get('stocks.timeline.currentOrigin')}</span>
        <span>{origemAtual}</span>
      </div>
      <div className="negotiation-read-column">
        <span />
        <span />
      </div>
    </div>   
    {codigoSap &&
    <div className="negotiation-read-offer">
      <div className="negotiation-read-column">
        <span>{intl.get('stocks.timeline.sapCode')}</span>
        <span>{codigoSap}</span>
      </div>
      <div className="negotiation-read-column">
        <span />
        <span />
      </div>
    </div>
    }
  </div>
  );

OrigemAlteradaPedido.propTypes = {
  origemAnterior: PropTypes.string.isRequired,
  origemAtual: PropTypes.string.isRequired,  
  usuario: PropTypes.string.isRequired,  
  codigoSap: PropTypes.string.isRequired
}

export default OrigemAlteradaPedido