export const getColorCell = (column) => {
  switch (column) {
    case "Consumption":
    case "Transfer":
      return getComputedStyle(document.body).getPropertyValue('--color-brand-100')
    case "InitialStock":
    case "Entry":
      return getComputedStyle(document.body).getPropertyValue('--color-neutral-200');
  }
  return 'var(--color-contrast-white)';
}

export const getColorSpan = (column) => {
  switch (column) {
    case "Consumption":
    case "Transfer":
      return getComputedStyle(document.body).getPropertyValue('--color-brand-500')
    case "InitialStock":
    case "Entry":
      return getComputedStyle(document.body).getPropertyValue('--color-contrast-brand');
  }
  return 'var(--color-contrast-white)';
}

export const getColorToFinalStock = (finalStock, minStock, maxStock) => {
  if (finalStock > maxStock) {
    return getComputedStyle(document.body).getPropertyValue('--color-feedback-warning-100');
  } else if (finalStock < minStock) {
    return getComputedStyle(document.body).getPropertyValue('--color-feedback-danger-100');
  }
  return getComputedStyle(document.body).getPropertyValue('--color-feedback-positive-100')
}

export const getColorSpanToFinalStock = (finalStock, minStock, maxStock) => {
  if (finalStock > maxStock) {
    return getComputedStyle(document.body).getPropertyValue('--color-feedback-warning-600');
  } else if (finalStock < minStock) {
    return getComputedStyle(document.body).getPropertyValue('--color-feedback-danger-600');
  }
  return getComputedStyle(document.body).getPropertyValue('--color-feedback-positive-600')
}