import styled from 'styled-components'

export const AlertIcon = styled.div`
  color: red;
  font-size: 20px ;
  top: 2px;
  left: 39px;
`
export const Container = styled.div`
  display:flex;
  align-items: center;
`
