import intl from 'react-intl-universal'
import React, { useState } from 'react'
import { Input, SelectBox, Checkbox } from "components/uikit-adapter/index";
import { Col, TableRow, } from '../../styled';
import { SchedulingAutoPilotGeneralDto } from 'types/labeled-family-center/auto-pilot/scheduling-auto-pilot-general-dto';
import { SchedulingAutoPilotDto } from 'types/labeled-family-center/auto-pilot/scheduling-auto-pilot-dto';
import { AutoPilotPeriodEnum, TypeOfAutoPilotScheduling } from 'enum/labeled-family-center/auto-pilot/'
import { getAutoPilotPeriodOptions } from './utils';
import { IAutoPilotPeriod, IDaysWeek } from 'features/auto-pilot/types';
interface IAutoPilotScheduleConfigurationTableLine {
  index: number,
  data: SchedulingAutoPilotGeneralDto,
  setData: React.Dispatch<React.SetStateAction<SchedulingAutoPilotGeneralDto>>,
  daysWeek: IDaysWeek[],
  setDaysWeek: React.Dispatch<React.SetStateAction<IDaysWeek[]>>
}
export const AutoPilotScheduleConfigurationTableLine = ({ index, data, setData, daysWeek, setDaysWeek }: IAutoPilotScheduleConfigurationTableLine) => {
  const day = daysWeek[index]
  const [autoPilotPeriod] = useState<IAutoPilotPeriod[]>(getAutoPilotPeriodOptions());
  const isTypeOfAutoPilotSchedulingDefault = data.TypeOfAutoPilotScheduling == TypeOfAutoPilotScheduling.Default
  const isTypeOfAutoPilotSchedulingCustom = data.TypeOfAutoPilotScheduling == TypeOfAutoPilotScheduling.Custom

  const updateValueGeneric = <T,>(prop: keyof T, value: T[keyof T]) => {
    let oldValues = daysWeek as T[];
    oldValues[index][prop] = value;
    const _daysWeek = oldValues as IDaysWeek[];
    setDaysWeek([..._daysWeek]);
    save(_daysWeek);
  }

  const updateValue = updateValueGeneric<IDaysWeek>;

  const updatePeriod = (value: string | number | boolean) => {
    updateValue("AutoPilotPeriod", value)

    if (value === AutoPilotPeriodEnum.AllDay) {
      updateValue("StartTime", "00:00")
      updateValue("FinishTime", "12:00")
    }
  }

  const save = (_daysWeek: IDaysWeek[]) => {
    let normalizeScheduleDays: SchedulingAutoPilotDto[] = [];

    _daysWeek.forEach(x => {
      const startHour = x.StartTime.split(":");
      const finishHour = x.FinishTime.split(":");

      normalizeScheduleDays.push({
        ...x,
        StartHour: Number(startHour[0]),
        StartMinute: Number(startHour[1]),
        FinishHour: Number(finishHour[0]),
        FinishMinute: Number(finishHour[1]),
        IdLabeledFamilyCenter: data.IdLabeledFamilyCenter,
      })
    });

    setData(prevState => ({
      ...prevState,
      SchedulingAutoPilots: normalizeScheduleDays
    }))
  }

  return (
    <TableRow >
      <Col>
        <Checkbox
          text={intl.get(`weekdays.${day.NameDayOfWeek.toLowerCase()}`)}
          name={`general-${day.NameDayOfWeek}`}
          data-testid={`general-${day.NameDayOfWeek}`}
          checked={day.Selected || isTypeOfAutoPilotSchedulingDefault}
          onChange={() => updateValue("Selected", !day.Selected)}
          disabled={!(isTypeOfAutoPilotSchedulingCustom && data.AutoPilot)}
        />
      </Col>
      <Col>
        <div className='select-box'>
          <SelectBox
            label='period-options'
            dontShowLabel={true}
            name={`select-${day.NameDayOfWeek}`}
            placeholder=""
            valueKey='Id'
            labelKey='Name'
            value={day.AutoPilotPeriod}
            options={autoPilotPeriod}
            onChange={(value: any) => updatePeriod(value.Id)}
            disabled={!(day.Selected && isTypeOfAutoPilotSchedulingCustom && data.AutoPilot)}
          />
        </div>
      </Col>
      <Col>
        <Input
          className="input"
          style={{ textAlign: 'center', height: '30px' }}
          value={day.StartTime}
          htmlType="time"
          onChange={(e: any) => updateValue("StartTime", e.target.value)}
          data-testid={`input-startTime-${day.NameDayOfWeek}`}
          disabled={!(day.AutoPilotPeriod && day.Selected && isTypeOfAutoPilotSchedulingCustom && data.AutoPilot)}
        />
      </Col>
      <Col>
        <Input
          className="input"
          style={{ textAlign: 'center', height: '30px' }}
          value={day.FinishTime}
          htmlType="time"
          onChange={(e: any) => updateValue("FinishTime", e.target.value)}
          data-testid={`input-finishTime-${day.NameDayOfWeek}`}
          disabled={!(day.AutoPilotPeriod && day.Selected && isTypeOfAutoPilotSchedulingCustom && data.AutoPilot)}
        />
      </Col>
    </TableRow>
  )
}