import InputMultiEmail from 'components/Adapters/input-multi-email'
import { isValidValue } from './validate'
import PropTypes from 'prop-types'

export const InputMulti = ({ value, ...rest }) => (
  <InputMultiEmail
    emails={value}
    validateEmail={(_value) => !!isValidValue(_value)}
    {...rest}
  />
);

InputMulti.propTypes = {
  value: PropTypes.string
}
