import styled from 'styled-components'

export const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  max-height: calc(100% - 92px);
`
