import React, { Component } from 'react'
import PropTypes from 'prop-types'

import StyledSuppliersButtons from './styledSuppliersButtons'
import intl from 'react-intl-universal'

class SuppliersButtons extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { toup, todown, toupDisabled, todownDisabled } = this.props


    return (
      <StyledSuppliersButtons>
        <span
          onClick={!toupDisabled ? toup : null}
          className={!toupDisabled ? "maximize-item" : "maximize-item maximize-item-disabled"}
          title={!toupDisabled ? intl.get('master-data.general.materials.supplier.tooltipUp') : ""}
        />
        <span
          onClick={!todownDisabled ? todown : null}
          className={!todownDisabled ? "minimize-item" : "minimize-item minimize-item-disabled"}
          title={!todownDisabled ? intl.get('master-data.general.materials.supplier.tooltipUp') : ""}
        />
      </StyledSuppliersButtons>
    )
  }
}

SuppliersButtons.propTypes = {
  toup: PropTypes.func,
  todown: PropTypes.func,
  toupDisabled: PropTypes.bool,
  todownDisabled: PropTypes.bool
}


export default SuppliersButtons
