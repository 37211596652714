import styled from 'styled-components'

export const DialogActions = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  padding: 5px 16px 16px 22px;

  button {
    margin-bottom: 8px;
  }

  .checkbox-items-list {
    margin-bottom: 5px;
  }

  .billed-events {
    margin-bottom: 10px;
    
    button:last-child {
      border-color: #DE4246;
      color: #DE4246;
    }
  }

`
export const DialogTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px;
  font-size: 18px;
`

export const DialogTitleText = styled.span`
  color: #3B4A5D;
  font-weight: var(--font-weight-semibold);
`
export const DialogTitleIcon = styled.span`
  font-weight: var(--font-weight-semibold);

  ::before {
    content:'X';
  }
`
export const DialogContentText = styled.div`
  color: #3B4A5D;
  font-size: 18px;
  font-weight: var(--font-weight-semibold);
  margin-bottom: 8px;
`

export const DialogContentSection = styled.div`
  display: flex;
  align-items: center;
  margin: 8px auto;
  justify-content: space-between;

  p {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: var(--font-weight-regular);
    font-size: 14px;
    color: #3B4A5D;
  }
`


