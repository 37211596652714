import React from 'react'
import intl from 'react-intl-universal'
import * as S from './styled'

export const NoAccessSupplierPage = () => {

  return (
    <S.Wrapper>
      <S.Text>
        {intl.get('orders.noAccessValidation')}
      </S.Text>
    </S.Wrapper>
  ) 

}