import { validateFeatureFlag } from "services/general-settings/general-settings";

interface IFeature {
  flags?: any,
  children?: any,
  hideFlags?: any,
  validation?: any
}

const Feature = ({ flags, children, hideFlags, validation }: IFeature) => {
  const _features = validateReceivedProp(flags) || validateFeatureFlag(flags);
  const _hideFlags = validateReceivedProp(hideFlags) || !validateFeatureFlag(hideFlags);
  const _validation = validateReceivedProp(validation) || !!validation;

  let validate = true;

  if (!_hideFlags || !_features || !_validation) {
    validate = false;
  }

  if (validate) {
    return children;
  }

  return null;
};

const validateReceivedProp = (prop: any) => typeof (prop) === 'undefined'

export default Feature;
