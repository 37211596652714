import React from "react";
const IconTruck = ({ width, height}) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={ width ? width: "32px"}
    height={ height ? height:"24px"}
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    viewBox="0 0 512 512"
  >
    <path
      fill="var(--color-action-default)"
      d="M119.467 337.067c-28.237 0-51.2 22.963-51.2 51.2 0 28.237 22.963 51.2 51.2 51.2s51.2-22.963 51.2-51.2c0-28.237-22.964-51.2-51.2-51.2zm0 85.333c-18.825 0-34.133-15.309-34.133-34.133 0-18.825 15.309-34.133 34.133-34.133s34.133 15.309 34.133 34.133c0 18.824-15.309 34.133-34.133 34.133zM409.6 337.067c-28.237 0-51.2 22.963-51.2 51.2 0 28.237 22.963 51.2 51.2 51.2 28.237 0 51.2-22.963 51.2-51.2 0-28.237-22.963-51.2-51.2-51.2zm0 85.333c-18.825 0-34.133-15.309-34.133-34.133 0-18.825 15.309-34.133 34.133-34.133 18.825 0 34.133 15.309 34.133 34.133 0 18.824-15.308 34.133-34.133 34.133z"
      data-old_color="var(--color-contrast-brand)"
      data-original ="var(--color-contrast-brand)"
    />
    <path
      fill="var(--color-action-default)"
      d="m510.643 289.784-76.8-119.467a8.535 8.535 0 0 0-7.177-3.917H332.8a8.53 8.53 0 0 0-8.533 8.533v213.333a8.525 8.525 0 0 0 8.533 8.533h34.133v-17.067h-25.6V183.467h80.674l72.926 113.442v82.825h-42.667V396.8h51.2a8.525 8.525 0 0 0 8.533-8.533V294.4a8.51 8.51 0 0 0-1.356-4.616z"
      data-old_color="var(--color-contrast-brand)"
      data-original ="var(--color-contrast-brand)"
    />
    <path
      fill="var(--color-action-default)"
      d="M375.467 277.333V217.6h68.267v-17.067h-76.8a8.53 8.53 0 0 0-8.533 8.533v76.8a8.525 8.525 0 0 0 8.533 8.533h128v-17.067H375.467zM332.8 106.667H8.533A8.536 8.536 0 0 0 0 115.2v273.067a8.53 8.53 0 0 0 8.533 8.533H76.8v-17.067H17.067v-256h307.2v256H162.133V396.8H332.8a8.525 8.525 0 0 0 8.533-8.533V115.2a8.53 8.53 0 0 0-8.533-8.533z"
      data-old_color="var(--color-contrast-brand)"
      data-original ="var(--color-contrast-brand)"
    />
    <path
      fill="var(--color-action-default)"
      d="M8.533 345.6h51.2v17.067h-51.2zM179.2 345.6h145.067v17.067H179.2zM469.333 345.6h34.133v17.067h-34.133zM34.133 140.8H332.8v17.067H34.133zM110.933 379.733H128V396.8h-17.067zM401.067 379.733h17.067V396.8h-17.067zM34.133 72.533H153.6V89.6H34.133zM0 72.533h17.067V89.6H0z"
      data-old_color="var(--color-contrast-brand)"
      data-original ="var(--color-contrast-brand)"
    />
  </svg>
  )
  export default IconTruck
  