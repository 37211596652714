import React, { Component } from 'react'
import { Row, Col } from "react-flexbox-grid"
import { Textarea } from '@hbsis.uikit/react'
import { Button } from 'components/uikit-adapter'
import { withRouter } from 'react-router-dom'
import Session from 'utils/user-storage'
import intl from 'react-intl-universal'
import moment from 'moment'
import { StatusPedido } from "models/pedidos/statusPedido";

import './aguardando-aprovacao-gestor.css'
import { formatNumber } from 'utils/format'
import TipoPerfilAcesso from 'models/usuarios/tipo-perfil-acesso'
import { getTextAreaStyle } from 'components/suggestions-list/suggestions-list-item/suggestions-list-item.styled'
import PropTypes from 'prop-types'

class AguardandoAprovacaoGestor extends Component {
  static propTypes = {
    aprovarRecusarPedidoOver: PropTypes.func.isRequired,
    data: PropTypes.shape({
      Atual: PropTypes.any,
      DataEntrega: PropTypes.any,
      EstoqueAtual: PropTypes.any,
      EstoqueProjetado: PropTypes.any,
      JustificativaPedidoEmOver: PropTypes.any,
      QuantidadePedido: PropTypes.any,
      StatusPedido: PropTypes.any
    }).isRequired
  }

	constructor(props) {
		super(props)

		this.state = {
			observacao: ''
		}
	}

	changeValue = (event) => {
		const prop = event.target.name
		let value = event.target.value

		this.setState({
			[prop]: value
		});
	}

	aprovar = (e) => {
		this.props.aprovarRecusarPedidoOver(true, this.state.observacao);
	}

	recusar = (e) => {
		this.props.aprovarRecusarPedidoOver(false, this.state.observacao);
	}

	enableOrderPendingManagerApproval = () => {
		return this.props.data.Atual &&
			Session.get().PerfilAcesso == TipoPerfilAcesso.Gestor &&
			this.props.data.StatusPedido == StatusPedido.AguardandoAprovacaoGestor;
	}

	render() {
		let {
			observacao
		} = this.state;
		let {
			JustificativaPedidoEmOver,
			EstoqueAtual,
			EstoqueProjetado,
			DataEntrega,
			QuantidadePedido
		} = this.props.data;
		return (
			<div className='event-action__approval'>
				<Row>
					<Col xl={12} className='reticencias'>
						<div className='negotiation-read-column'>
							<span> {`Just.: `}</span>
							<span>{JustificativaPedidoEmOver}</span>
						</div>
						<div className='negotiation-read-column'>
							<span>{intl.get('stocks.timeline.currentInventory')}</span>
							<span>{formatNumber(EstoqueAtual)}</span>
						</div>
						<div className='negotiation-read-column'>
							<span>{intl.get('stocks.timeline.projectedInventory')}</span>
							<span>{formatNumber(EstoqueProjetado)}</span>
						</div>
						<div className='negotiation-read-column'>
							<span>{intl.get('stocks.timeline.deliveryDate')}</span>
							<span>{moment(DataEntrega).format('L')}</span>
						</div>
						<div className='negotiation-read-column'>
							<span>{intl.get('stocks.timeline.orderQuantity')}</span>
							<span>{formatNumber(QuantidadePedido)}</span>
						</div>
					</Col>
				</Row>
				{this.enableOrderPendingManagerApproval() &&
					<>
						<Row>
							<Col xl={12} className='notes'>
								<Textarea
									style={getTextAreaStyle()}
									required
									name="observacao"
									label={intl.get('stocks.timeline.notes')}
									value={observacao || ''}
									onChange={this.changeValue}
								/>
							</Col>
						</Row>
						<Row>
							<Col xl={6} className='refuse-button'>
								<Button
									value={intl.get('stocks.timeline.refuse')}
									type="primary"
									className="button"
									onClick={this.recusar}
								/>
							</Col>
							<Col xl={6}>
								<Button
									value={intl.get('stocks.timeline.accept')}
									type="danger"
									className="button"
									onClick={this.aprovar}
								/>
							</Col>
						</Row>
					</>
				}
			</div>
		)
	}
}

export default withRouter(AguardandoAprovacaoGestor)
