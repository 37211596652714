import PropTypes from "prop-types"
import React, { useState } from 'react'
import intl from 'react-intl-universal'
import { BottlesInputRegisteredSupplier } from '../bottles-input-registered-supplier'
import { BottlesInputGhostSupplier } from '../bottles-input-ghost-supplier'
import { Dropdown } from '../bottles-dropdown'
import * as S from '../bottles-tabs/styled'

export const BottlesNewTab = ({ addNewTab }) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const [showNewTab, setShowNewTab] = useState(false)
  const [showInput, setShowInput] = useState(false)
  const handleShowNewTab = () => setShowNewTab(!showNewTab)
  const handleShowInput = () => setShowInput(!showInput)
  const handleShowDropdown = () => setShowDropdown(!showDropdown)

  const options = [
    { label: intl.get('bottles.addRegisteredSupplier'), action: 'Cadastrado' },
    { label: intl.get('bottles.addGhostSupplier'), action: 'Fantasma' }
  ]

  const handleNewTab = (event) => {
    if (event.key === 'Enter') {
      addNewTab(event)
      setShowNewTab(false)
    }
    setShowInput(false)
  }

  const handleAction = (action) => {
    const event = {
      'Fantasma': handleShowNewTab,
      'Cadastrado': handleShowInput
    }
    return event[action]()
  }

  return (
    <>
      {showNewTab &&
        <BottlesInputGhostSupplier
          onKeyUp={handleNewTab}
          close={handleShowNewTab}
        />
      }
      {showInput &&
        <BottlesInputRegisteredSupplier
          onKeyUp={handleNewTab}
          close={handleShowInput}
        />
      }
      <S.NewTabButton
        className={showNewTab ? "no-event" : ""}
        onClick={handleShowDropdown}
      >
        +
        {showDropdown &&
          <Dropdown
            options={options}
            action={(action) => handleAction(action)}
            close={handleShowDropdown}
          />
        }
      </S.NewTabButton>
    </>
  )
}

BottlesNewTab.propTypes = {
  addNewTab: PropTypes.func.isRequired
}


