import PropTypes from "prop-types"
import React, { Component } from 'react'
import StyledSuppliersListItemDesc from './styledSuppliersListItemDesc'

class SuppliersListItemDesc extends Component {
  static propTypes = {
    itemsCount: PropTypes.any.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      items: [
        "A", "B", "C", "D", "E", "F", "G", "H", "I", 
        "J", "K", "L", "M", "N", "O", "P", "Q", "R", 
        "S", "T", "U", "V", "W", "X", "Y", "Z"
      ]
    }
  }
  render() {
    const items = [...this.state.items.slice(0, this.props.itemsCount), ""]

    return (
      <StyledSuppliersListItemDesc>
        <ul className="itens-menu">
          {
            items.map((item, index) => (
              <li key={index.toString()}>{item} </li>
            ))
          }
        </ul>
      </StyledSuppliersListItemDesc>
    )
  }
}

export default SuppliersListItemDesc
