import React, { Component } from 'react'
import { Tabs, Pane } from 'components/uikit-adapter/index'

import Coordination from 'pages/master-data/coordination-user/coordination'
import User from 'pages/master-data/coordination-user/user'
import intl from 'react-intl-universal'

class CoordinationUser extends Component {
  constructor() {
    super()
  }

  render() {
    return (
      <Tabs selected={1}>
        <Pane label={intl.get('master-data.coordinationsAndUsers.coordinations.header.subtitle')}>
          <Coordination />
        </Pane>
        <Pane label={intl.get('master-data.coordinationsAndUsers.users.header.title')}>
          <User />
        </Pane>
      </Tabs>
    )
  }
}

export default CoordinationUser
