import styled from 'styled-components'


export const DialogActions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px 22px;
`

export const DialogTitleIcon = styled.span`
  font-weight: 700;

  ::before {
    content:'X';
  }
`

export const DialogTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px;
  font-size: 18px;
`

export const DialogTitleText = styled.span`
  color: #3B4A5D;
  font-weight: 700;
`
export const ContentChart = styled.div`
  background: #FFFF;
  padding: 10px;
  left: 588px;
  position: absolute;
  width: 700px;
`