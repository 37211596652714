
import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'components/uikit-adapter/index'
import intl from 'react-intl-universal'

import './pedido-volume-ambev.css'

class PedidoVolumeAmbev extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <div className="event-action-offer-volume">
          <Button
            value={intl.get('geral.buttonsDefault.ok')}
            onClick={() => this.props.ciencia(this.props.idPedido)}
            type="danger"
            className="button"
          />
        </div>
    );
  }
}

PedidoVolumeAmbev.propTypes = {
  ciencia: PropTypes.func.isRequired,
  idPedido: PropTypes.number.isRequired,
}

export default PedidoVolumeAmbev
