import styled from 'styled-components'

const StyledTitleSecond = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: var(--font-weight-medium);
  color: var(--color-action-default);
  padding-left: 0.5rem;
`

export default StyledTitleSecond
