import { Component } from 'react';
import { SnackBar } from 'components/uikit-adapter/index'
import Loading from 'components/center-loading'
import HeaderIcon from 'components/header-icon'
import { ReleaseVersionIcon } from 'components/icons/release-version.icn.icon'
import ReleaseVersionList from './list'
import ReleaseVersionForm from './form'
import ButtonNewItem from 'components/button-new-item'
import DeleteReleaseVersion from 'components/delete-dialog'
import {
  findReleaseVersion,
  deleteReleaseVersion
} from './release-version.service'
import intl from 'react-intl-universal'

import ReleaseVersionStyled from './release-version.styled.js'
import Session from 'utils/user-storage'
import { formatErrorMessage } from 'utils/handle-error';

class ReleaseVersion extends Component {
  constructor() {
    super()
    this.state = {
      isFetching: false,
      releaseVersionList: [],
      total: 0,
      currentPage: 0,
      openForm: false,
      openEditMode: false,
      idSelected: 0,
      showFeedback: false,
      messageFeedBack: '',
      qtRegsPerPage: 50,
      openDelete: false
    }
  }

  componentDidMount() {
    this.findReleaseVersion(this.state.currentPage)
  }

  findReleaseVersion = async (page) => {
    try {
      this.startFetching()
      const data = await findReleaseVersion(this.state.qtRegsPerPage, page)
      this.proccessData(data)
      this.setState({ currentPage: page })
    } catch (error) {
      this.proccessErro(error);
    } finally {
      this.stopFetching();
    }
  }

  showFeedback = (message) => {
    this.setState({
      showFeedback: true,
      messageFeedBack: message
    })
  }

  closeFeedback = () => {
    this.setState({
      showFeedback: false
    })
  }

  openForm = () => {
    this.setState({
      openForm: true
    })
  }

  openNewForm = () => {
    this.setState({
      openEditMode: false
    }, () => this.openForm())
  }

  openEditForm = (row) => {
    const { releaseVersionList } = this.state;
    const selected = releaseVersionList[row];
    this.setState({
      openEditMode: true,
      idSelected: selected.Id
    }, () => this.openForm())
  }

  closeForm = () => {
    this.setState({
      openForm: false
    })
  }

  startFetching = () => {
    this.setState({
      isFetching: true
    })
  }

  stopFetching = () => {
    this.setState({
      isFetching: false
    })
  }

  proccessErro = (exception) => {
    this.stopFetching()
    this.showFeedback(formatErrorMessage(exception))
  }

  refreshSearch = () => {
    this.closeForm()
    this.findReleaseVersion(this.state.currentPage)
  }

  proccessData = (data) => {
    this.setState({
      releaseVersionList: data.Data,
      total: data.TotalRecordCount
    })
  }

  openDelete = (id) => {
    this.setState({
      openDelete: true,
      idSelected: id
    })
  }

  closeDelete = () => {
    this.setState({
      openDelete: false
    })
  }

  deleteReleaseVersion = async () => {
    try {
      const idReleaseVersion = this.state.idSelected

      await deleteReleaseVersion(idReleaseVersion)
      this.closeDelete()
      this.refreshSearch()
      this.showFeedback(intl.get('master-data.version.deletedVersion'))
    } catch (error) {
      this.proccessErro(error);
    }
  }

  render() {
    let {
      openForm,
      openEditMode,
      openDelete,
      idSelected,
      releaseVersionList,
      total,
      showFeedback,
      messageFeedBack,
      isFetching
    } = this.state

    const { TipoUsuario } = Session.get();

    return (
      <ReleaseVersionStyled>
        <Loading isLoading={isFetching} />

        <div className='unidade-header'>
          <HeaderIcon
            title={intl.get('master-data.version.title')}
            subtitle={intl.get('master-data.version.subtitle')}
            icon={<ReleaseVersionIcon width="45px" height="45px" color='var(--color-brand-500)' />}
          >
            {
              TipoUsuario === 3 &&
              <div title={`${intl.get('commons.new')} ${intl.get('commons.version')}`}>
                <ButtonNewItem
                  className='general-button'
                  onClick={this.openNewForm}
                />
              </div>
            }
          </HeaderIcon>
        </div>

        <ReleaseVersionList
          data={releaseVersionList}
          countData={total}
          refreshSearch={this.findReleaseVersion}
          handleEdit={this.openEditForm}
          handleDelete={this.openDelete}
          qtRegsPerPage={this.state.qtRegsPerPage}
        />

        {openForm &&
          <ReleaseVersionForm
            idEdit={idSelected}
            open={openForm}
            editMode={openEditMode}
            handleClose={this.closeForm}
            handleRefresh={this.refreshSearch}
            handleFeedback={this.showFeedback}
          />
        }

        <DeleteReleaseVersion
          open={openDelete}
          title={`${intl.get('geral.buttonsDefault.delete')} ${intl.get('commons.version')}`}
          handleClose={this.closeDelete}
          warningMessage={intl.get('master-data.walletsAndFamilys.labeledFamily.deleteModal.description')}
          handleConfirm={this.deleteReleaseVersion}
        />

        <SnackBar
          message={messageFeedBack}
          open={showFeedback}
          autoHideDuration={3000}
          onRequestClose={this.closeFeedback}
        />
      </ReleaseVersionStyled>
    );
  }
}
export default ReleaseVersion
