
import React from 'react'
import StyledButtonNewItem from './styledButtonNewItem'
import AddIcon from 'material-ui/svg-icons/content/add'

import { Button } from 'components/uikit-adapter/index'

const ButtonNewItem = props => (
  <StyledButtonNewItem>
    <Button
      type="default"
      value={<AddIcon style={{ width: 39, height: 39 }} color='var(--color-brand-500)' />}
      width={"40px"}
      height={"40px"}
      className="button-new-item"
      {...props}
    />
  </StyledButtonNewItem>
)

export default ButtonNewItem
