import { useState, useEffect, useCallback } from 'react'
import { Row, Col } from 'react-flexbox-grid'
import StyledFilter from '../../StyledFilterObsolete'
import * as services from 'pages/stocks/stocks.service'
import intl from 'react-intl-universal'
import StorePersist from 'utils/store-persist'
import Session from 'utils/user-storage'
import './style.css'
import { Switcher } from 'components/toggle'
import { SelectBox, Button} from 'components/uikit-adapter'
import PropTypes from 'prop-types'

const FiltroGerencial = (props) => {
  const [IdUnidadeNegocio, setIdUnidadeNegocio] = useState(undefined);
  const [IdResponsavel, setIdResponsavel] = useState(undefined);
  const [responsaveis, setResponsaveis] = useState([]);
  const [IdsUnidadeNegocio, setIdsUnidadeNegocio] = useState([]);
  const [IdStatus, setIdStatus] = useState(props.filterDefault?.StatusStock);
  const [IdRisco, setIdRisco] = useState(props.filterDefault?.Risco);
  const [IdsFamilia, setIdsFamilia] = useState([]);
  const [IdsFamiliaRotulada, setIdsFamiliaRotulada] = useState([]);
  const [IdsCoordenacao, setIdsCoordencao] = useState([]);
  const [IdsCarteira, setIdsCarteira] = useState([]);
  const [unidadesNegocio, setUnidadesNegocio] = useState([]);
  const [familias, setFamilias] = useState([]);
  const [familiasRotuladas, setFamiliasRotuladas] = useState([]);
  const [coordenacoes, setCoordenacoes] = useState([]);
  const [carteiras, setCarteiras] = useState([]);
  const [usuario] = useState(() => Session.get());
  const [semRisco, setSemRisco] = useState(props.filterDefault?.ItensSemRisco);
  const [status] = useState([
    {
      Key: 1,
      Value: 'Ok'
    },
    {
      Key: 2,
      Value: 'Over'
    },
    {
      Key: 3,
      Value: 'Out'
    }
  ]);
  const [risco] = useState([
    {
      Key: 1,
      Value: intl.get("management.risk.nonCritical")
    },
    {
      Key: 2,
      Value: intl.get("management.risk.low")
    },
    {
      Key: 3,
      Value: intl.get("management.risk.medium")
    },
    {
      Key: 4,
      Value: intl.get("management.risk.high")
    }
  ]);

  const carregarFiltros = useCallback(async () => {
    const filtrarApenasMeusItens = StorePersist.getValuesJSON("filterMyItemsOnly");
    buscarUnidadesDeNegocio();
    buscarResponsaveis();
    buscarFamilias(filtrarApenasMeusItens);
    buscarFamiliasRotuladas(filtrarApenasMeusItens);
    buscarCordenacoes(filtrarApenasMeusItens);
    buscarCarteiras(filtrarApenasMeusItens);
  }, [])

  const buscarUnidadesDeNegocio = useCallback(async () => {
    const response = await services.getBusinessUnit(usuario.Id);
    setUnidadesNegocio(response);

    if(props.isPlanoDiario) {
      mudarValorAtualUnidadeNegocio(props.filterDefault?.Destinos);
    } else {
      setIdUnidadeNegocio(props.filterDefault?.Origem);
    }
  }, []);

  const buscarResponsaveis = useCallback(async () => {
    const response = await services.getUsersAmbev();
    setResponsaveis(response);
    setIdResponsavel(props.filterDefault?.IdUsuarioResponsavel);
  }, []);

  const buscarFamilias = useCallback(async (filtroPadraoUsuario) => {
    const response = await services.getFamily(filtroPadraoUsuario);
    setFamilias(response);
    const _filter = response.filter(x => props.filterDefault.Familias?.includes(x.Id));
    mudarValorAtualFamilias(_filter);
  }, [])

  const buscarFamiliasRotuladas = useCallback(async (filtroPadraoUsuario) => {
    const response = await services.getLabeledFamily(filtroPadraoUsuario, usuario.Id);
    setFamiliasRotuladas(response);
    const _filter = response.filter(x => props.filterDefault.FamiliasRotuladas?.includes(x.Id));
    mudarValorAtualFamiliasRotuladas(_filter);
  }, [])

  const buscarCordenacoes = useCallback(async (filtroPadraoUsuario) => {
    const response = await services.getCoordinations(filtroPadraoUsuario);
    setCoordenacoes(response);
    const _filter = response.filter(x => props.filterDefault.Coordenacoes?.includes(x.Id));
    mudarValorAtualCoordenacao(_filter);
  }, [])

  const buscarCarteiras = useCallback(async (filtroPadraoUsuario) => {
    const response = await services.getWallet(filtroPadraoUsuario, usuario.Id);
    setCarteiras(response);
    const _filter = response.filter(x => props.filterDefault.Carteiras?.includes(x.Id));
    mudarValorAtualCarteira(_filter);
  }, [])

  const mudarValorAtualFamilias = (valoresExternos) => {
    const valores = []
    valoresExternos?.map(valor => valores.push(valor))
    setIdsFamilia([...valores])
  }

  const mudarValorAtualFamiliasRotuladas = (valoresExternos) => {
    const valores = []
    valoresExternos?.map(valor => valores.push(valor))
    setIdsFamiliaRotulada(valores)
  }

  function mudarValorAtualCoordenacao(valoresExternos) {
    const valores = []
    valoresExternos?.map(valor => valores.push(valor))
    setIdsCoordencao(valores)
  }

  const mudarValorAtualCarteira = (valoresExternos) => {
    const valores = []
    valoresExternos?.map(valor => valores.push(valor))
    setIdsCarteira(valores)
  }

  const mudarValorAtualUnidadeNegocio = (valoresExternos) => {
    const valores = []
    valoresExternos?.map(valor => valores.push(valor))
    setIdsUnidadeNegocio(valores)
  }

  const handleCloseSearch = (value) => {
    const risco = (value.checked === undefined) ? semRisco : value.checked;

    const params = {
      Origem: IdUnidadeNegocio,
      StatusStock: IdStatus,
      Familias: IdsFamilia?.map(x => x.Id),
      FamiliasRotuladas: IdsFamiliaRotulada?.map(x => x.Id),
      Carteiras: IdsCarteira?.map(x => x.Id),
      Coordenacoes: IdsCoordenacao?.map(x => x.Id),
      Risco: IdRisco,
      ItensSemRisco: risco,
      Destinos: IdsUnidadeNegocio,
      IdUsuarioResponsavel: IdResponsavel
    }

    if(value) setSemRisco(risco);

    props.setFilters(params)
  }

  const handleCloseClean = () => {
    setIdsCoordencao([]);
    setIdUnidadeNegocio(undefined);
    setIdStatus(undefined);
    setIdRisco(undefined);
    setIdsFamilia([]);
    setIdsFamiliaRotulada([]);
    setIdsCarteira([]);
    setSemRisco(false);
    setIdsUnidadeNegocio([]);
    setIdResponsavel(undefined);

    props.setFilters({});
  }

  useEffect(() => {
    carregarFiltros()
  }, [])

  const validValueChangeSelectBox = (value, prop) => value ? value[prop] : null

  return (
    <StyledFilter>
      <div className='filter-container'>
        <div className='sideFilterFields'>

          <Row>
            {
              props.isItensCriticos &&
              <Col xs={12}>
                <Switcher
                  className='itensSemRisco'
                  name='itensSemRisco'
                  label={intl.get('commons.riskFree')}
                  value={semRisco}
                  onChange={(value) => handleCloseSearch(value)}
                />
              </Col>
            }
            <Col xs={12} lg={12} md={12}>
              <SelectBox
                name='responsavel'
                className="responsavelSelect"
                label={intl.get('commons.responsible')}
                value={IdResponsavel}
                options={responsaveis}
                valueKey='Id'
                labelKey='Nome'
                onChange={value => setIdResponsavel(validValueChangeSelectBox(value, "Id"))}
                placeholder={intl.get('commons.responsible')}
                searchable
                openOnFocus
                clearable
              />
            </Col>
            {
              props.isPlanoDiario ?
                <Col xs={12} lg={12} md={12}>
                  <SelectBox
                    name='unidadeNegocio'
                    className="unidadeNegocioSelect"
                    label={intl.get('commons.destiny')}
                    value={IdsUnidadeNegocio}
                    options={unidadesNegocio}
                    valueKey='IdUnidadeNegocio'
                    labelKey='Descricao'
                    onChange={(values) => mudarValorAtualUnidadeNegocio(values)}
                    placeholder={intl.get('commons.destiny')}
                    searchable
                    openOnFocus
                    clearable
                    multi
                  />
                </Col>
                :
                <Col xs={12} lg={12} md={12}>
                  <SelectBox
                    name='unidadeNegocio'
                    className="unidadeNegocioSelect"
                    label={intl.get('commons.source')}
                    value={IdUnidadeNegocio}
                    options={unidadesNegocio}
                    valueKey='IdUnidadeNegocio'
                    labelKey='Descricao'
                    onChange={value => setIdUnidadeNegocio(validValueChangeSelectBox(value, "IdUnidadeNegocio"))}
                    placeholder={intl.get('transports.compoCharge.actions.unityBussines')}
                    searchable
                    openOnFocus
                    clearable
                  />
                </Col>
            }
          </Row>
          <Row>
            <Col xs={12} lg={12} md={12}>
              <SelectBox
                name='coordenacao'
                className="coordenacaoSelect"
                label={intl.get('master-data.general-configuration.coordination')}
                value={IdsCoordenacao}
                options={coordenacoes}
                valueKey='Id'
                labelKey='Description'
                onChange={(values) => mudarValorAtualCoordenacao(values)}
                placeholder={intl.get('master-data.general-configuration.coordination')}
                searchable
                clearable
                openOnFocus
                multi
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={12} md={12}>
              <SelectBox
                name='carteiras'
                className="carteirasSelect"
                label={intl.get('commons.wallets')}
                value={IdsCarteira}
                options={carteiras}
                valueKey='Id'
                labelKey='Description'
                onChange={(values) => mudarValorAtualCarteira(values)}
                placeholder={intl.get('commons.wallets')}
                searchable
                clearable
                openOnFocus
                multi
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={12} md={12}>
              <SelectBox
                name='familia'
                className="familiaSelect"
                label={intl.get('commons.family')}
                value={IdsFamilia}
                options={familias}
                valueKey='Id'
                labelKey='Name'
                onChange={(values) => mudarValorAtualFamilias(values)}
                placeholder={intl.get('commons.family')}
                searchable
                clearable
                openOnFocus
                multi
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={12} md={12}>
              <SelectBox
                name='familiaRotulada'
                className="familiaRotuladaSelect"
                label={intl.get('commons.labeledFamily')}
                value={IdsFamiliaRotulada}
                options={familiasRotuladas}
                valueKey='Id'
                labelKey='Description'
                onChange={(values) => mudarValorAtualFamiliasRotuladas(values)}
                placeholder={intl.get('commons.labeledFamily')}
                searchable
                clearable
                openOnFocus
                multi
              />
            </Col>
          </Row>
          {
            !props.isPlanoDiario &&
            <Row>
              <Col xs={12} lg={12} md={12}>
                <SelectBox
                  name='status'
                  className="statusSelect"
                  label={intl.get('stocks.stockStatus')}
                  value={IdStatus}
                  options={status}
                  valueKey='Key'
                  labelKey='Value'
                  onChange={value => setIdStatus(validValueChangeSelectBox(value, "Key"))}
                  placeholder={intl.get('stocks.stockStatus')}
                  searchable
                  clearable
                  openOnFocus
                />
              </Col>
            </Row>
          }
          <Row>
            {
              props.isItensCriticos &&
              <Col xs={12} lg={12} md={12}>
                <SelectBox
                  name='risco'
                  className="riscoSelect"
                  label={intl.get('commons.risk')}
                  value={IdRisco}
                  options={risco}
                  valueKey='Key'
                  labelKey='Value'
                  onChange={value => value === null ? setIdRisco(null) : setIdRisco(value.Key)}
                  placeholder={intl.get('commons.risk')}
                  searchable
                  clearable
                  openOnFocus
                />
              </Col>
            }
          </Row>
        </div>
        <div className="footer-filter">
          <Row className='footerFilterContainer no-margin'>
            <Col xs={12}>
              <Row>
                <Col xs={12} lg={12} md={12}>
                  <hr className='filter-divisor' />
                </Col>
              </Row>
              <Row>
                <Col xs={6} lg={6} md={6}>
                  <Button
                    className="filter-search"
                    onClick={handleCloseSearch}
                    type='primary'
                    value={intl.get('filters.search')}
                  />
                </Col>
                <Col xs={6} lg={6} md={6}>
                  <Button
                    type='secondary'
                    value={intl.get('filters.cleanSearch')}
                    onClick={handleCloseClean}
                    className='clean-search'
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </StyledFilter>
  );
}

FiltroGerencial.propTypes = {
  filterDefault: PropTypes.shape({
    StatusStock: PropTypes.number,
    Risco: PropTypes.number,
    ItensSemRisco: PropTypes.bool,
    Destinos: PropTypes.array,
    Origem: PropTypes.string,
    IdUsuarioResponsavel: PropTypes.number,
    Familias: PropTypes.array,
    FamiliasRotuladas: PropTypes.array,
    Coordenacoes: PropTypes.array,
    Carteiras: PropTypes.array,
  }),
  isPlanoDiario: PropTypes.bool,
  setFilters: PropTypes.func,
  isItensCriticos: PropTypes.bool
}

export default FiltroGerencial
