import Fetch from 'utils/fetch';

export const getFamily = ({ quantityPerPage, page }) => {
  return Fetch.get(`/families:paginated?_size=${quantityPerPage}&_page=${page}`).then((response) => response.data)
}

export const getCriticalItemsByFamilyId = (id) => {
  return Fetch.get(`/configuracaoitenscritico/obterConfiguracaoItensCritico/${id}`).then((response) => response.data)
}

export const save = (data) => {
  return Fetch.post(`/configuracaoitenscritico/salvar`, data)
}