import { IProductionItem } from "features/supplier-production-plan/interface";
import { ClbTable } from "libs/celebration";
import Header from "./header";
import Body from "./body";

interface ISupplierProductionPlanTable {
  data: IProductionItem[];
}

const SupplierProductionPlanTable = ({ data }: ISupplierProductionPlanTable) => (
  <ClbTable>
    <Header />
    <Body data={data} />
  </ClbTable>
);

export default SupplierProductionPlanTable;
