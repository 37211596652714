import styled from "styled-components";

export const Wrapper = styled.div`
  border-radius: 300px;
  background: var(--color-brand-300, #2870B2);
  display: flex;
  padding: 1px;
  justify-content: center;
  width: 110px;
  margin: 0px 4px;

  ${p => p.color && `color: ${p.color} !important;`}
  ${p => p.background && `background: ${p.background} !important;`}

  transition: all 50ms ease-in-out;
  &:hover{
    transform: scale(1.1);
  } 
`