import moment from "moment";

export const ISO_DATE_FORMAT = 'YYYY-MM-DD';
export const DATA_FORMAT_TYPES = { WITH_YEAR: "L", WITHOUT_YEAR: "[DM]" };

/**
  * @param {*} date date to be formatted.
  * @param {*} formatDateCode code to format date.
  * @returns Returns the date formatted according to the user's language .
  * Example in pt-BR: getDataFormat('2020-05-02', DATA_FORMAT_TYPES.WITH_YEAR); /returns => "02/05/2020"
*/
export const getDataFormat = (date, formatDateCode) => {
  if (!date) {
    return '-'
  } else {
    return moment(date).format(formatDateCode);
  }
}
