import PropTypes from "prop-types"
import intl from 'react-intl-universal'
import * as S from './modal-review-emergency-renegotiate/styled'

export const TitleModalFeedback = ({ idLoadComposition }) => (
  <div>
    <S.Title style={{ fontSize: "17px" }}>{intl.get('commons.emergencyNegotiation')}</S.Title>
    <S.Subtitle style={{ fontSize: "12px" }}>{intl.get("commons.composition")} {idLoadComposition}</S.Subtitle>
  </div>
);

TitleModalFeedback.propTypes = {
  idLoadComposition: PropTypes.any.isRequired
}
