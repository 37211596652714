import { useState, useCallback, useEffect } from 'react';
import { Layout } from "@hbsis.uikit/react"
import { SelectBox, Button} from "components/uikit-adapter/index"

import { TableListPagination } from "@hbsis-planning/hbsis-planning-core";
import Moment from 'moment';
import { pegaItensCriticos, getConfiguredColumnItensCriticos, pegaJustificativas, pegaMotivos, convertToArrayQuery, salvaItemCritico } from '../gerencial.service';
import { saveConfiguredColumn } from './utils/columnConfigurationItensCriticos';
import StorePersist from "utils/store-persist";
import Header from '../../../components/header'
import Dialog from 'material-ui/Dialog/Dialog';
import DualList from '../components/dual'
import intl from 'react-intl-universal';
import IconFilterGray from "images/icn-filter-gray.svg";
import IconFilterBlue from "images/icn-filter-blue.svg";
import IconEmpty from 'images/iconEmpty.icon';
import iconeMais from 'images/adicionar.svg';
import '../riscos-passivos/styles.css';
import './styles.css';
import StyledTable from '../riscos-passivos/styledTable';
import FiltroGerencial from '../../../components/filters/filter-gerencial/gerencial';
import SortList from '../riscos-passivos/utils/sortList';
import Loading from 'components/center-loading'
import { transformToData } from './utils/transform'
import { formatNumber } from 'utils/format';
import { verifyIsFiltered } from 'utils/custom-functions';
import { StyledCellLabeledFamily } from './itens-criticos-styled';

const columnStyle = {
  dataStyle: { flex: 1 },
  headerStyle: { flex: 1 }
};

const limitMinimumDecimalInZero = { minimumFractionDigits: 0 }

const normalizeColumn = (element) => {
  return (
    <span className='main-span'>
      {element ? element : '-'}
    </span>
  )
}

const columnRisco = (element, risco) => {
  let backGroundColor = '';
  switch (risco) {
    case 4:
      backGroundColor = '#e74c3c'
      break;
    case 3:
      backGroundColor = '#f39c12'
      break;
    case 2:
      backGroundColor = '#2ecc71'
      break;
    case 1:
      backGroundColor = '#bdc3c7';
      break;
  }

  return (
    <span
      className='coluna-risco'
      style={{ color: "white", fontWeight: 'var(--font-weight-semibold)', backgroundColor: backGroundColor }}
    >
      {element}
    </span>
  )
}

const columnProximoPedido = (row) => {
  const newDate = row.DataProximoPedido ? Moment(new Date(row.DataProximoPedido), "DD/MM") : null;
  return (
    <span className='main-span'>
      {
        row.DataProximoPedido
          ? `
              ${formatNumber(row.VolumeProximoPedido)} ${row.UnidadeMedidaEstoqueDisponivel}
              ${Moment(newDate).format("[DM]")}
            `
          : '-'
      }
    </span>
  )
}

const columnStatus = (element) => {

  let color = '';
  switch (element) {
    case "Out":
      color = '#C00000'
      break;
    case "Over":
      color = '#5A7805'
      break;
  }

  return (
    <span className='main-span' style={{ color, fontWeight: 'var(--font-weight-semibold)' }}>
      {element}
    </span>
  )
}

const getConfiguration = (type) => {
  const configuration = getConfiguredColumnItensCriticos();
  return type === 1 ? configuration.Visible : configuration.Hidden;
}

const formatNumeroMaterial = (value) => {
  if (value) {
    const slice = value.slice(10);
    return slice;
  }
}

const criticalItemsFilterDefault = {
  Origem: null,
  StatusStock: null,
  Carteiras: [],
  Coordenacoes: [],
  Destinos: [],
  Familias: [],
  FamiliasRotuladas: [],
  Risco: null,
  ItensSemRisco: false,
  query: null
}

const ItensCriticos = () => {

  const [mostrarFiltro, setMostrarFiltro] = useState(false);
  const [itensCriticos, setItensCriticos] = useState(undefined);
  const [justificativas, setJustificativas] = useState(undefined);
  const [motivos, setMotivos] = useState(undefined);
  const [totalPaginas, setCountPage] = useState(50);
  const [ultimaAtualizacao, setLastUpdate] = useState(null);
  const [setRows] = useState([]);
  const [sortList, setSortList] = useState(new SortList());
  const [colunasVisiveis, setColunasVisiveis] = useState(() => getConfiguration(1));
  const [colunasInvisiveis, setColunasInvisiveis] = useState(() => getConfiguration(2));
  const [modal, setModal] = useState(false);
  const [pagina, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [filtros, setFilters] = useState(() => {
    const filterCriticalItemsMulti = StorePersist.getValuesJSON("filterCriticalItemsMulti");
    return filterCriticalItemsMulti ?? criticalItemsFilterDefault;
  })

  const loadData = useCallback(async () => {
    const IsFilterDefault = await StorePersist.getValuesJSON("filterMyItemsOnly");
    const sort = sortList.toUrl();
    const response = await pegaItensCriticos(
      totalPaginas,
      pagina,
      filtros,
      IsFilterDefault,
      sort
    );

    const obj = {
      data: response.Data.Items,
      totalCount: response.TotalCount,
      totalPages: response.TotalPages
    }

    setLastUpdate(Moment(response.Data.LastUpdate).format("[DM] LT"));
    setItensCriticos(obj);
    setLoading(false);
  },
    [pagina, totalPaginas, filtros, sortList]
  );

  const buscarJustificativas = useCallback(async () => {
    const response = await pegaJustificativas();
    setJustificativas(response.data);
  });

  const buscarMotivos = useCallback(async () => {
    const response = await pegaMotivos();
    setMotivos(response.data);
  })

  const manipulaColunasVisiveis = useCallback(col => {
    setColunasVisiveis(prev => {
      prev.push(col);

      return prev;
    });
    setColunasInvisiveis(prev =>
      prev.filter(item => JSON.stringify(item) !== JSON.stringify(col))
    );
  }, []);

  const manipulaColunasInvisiveis = useCallback(col => {
    setColunasInvisiveis(prev => {
      prev.push(col);

      return prev;
    });
    setColunasVisiveis(prev =>
      prev.filter(item => JSON.stringify(item) !== JSON.stringify(col))
    );
  }, []);

  const salvaColunasSelecionadas = useCallback(() => {
    const columns = {
      Hidden: colunasInvisiveis,
      Visible: colunasVisiveis
    }
    saveConfiguredColumn(columns);
    setModal(false);

  }, [colunasVisiveis, colunasInvisiveis]);


  const manipulaFiltros = (data) => {
    const stringQuery = convertToArrayQuery(
      [
        data.Coordenacoes,
        data.Carteiras,
        data.Familias,
        data.FamiliasRotuladas
      ],
      [
        'Coordenacoes',
        'Carteiras',
        'Familias',
        'FamiliasRotuladas'
      ]
    )

    const newFilter = { ...data, query: stringQuery };
    setFilters(newFilter);
    StorePersist.setValuesJSON("filterCriticalItemsMulti", newFilter);
    setMostrarFiltro(false);
    setLoading(true);
  }

  const handleTableSelectBox = useCallback(async (name, value, rowIndex) => {
    setLoading(true)
    const _itensCriticosData = itensCriticos?.data;
    let step = [..._itensCriticosData];

    const index = step.findIndex(item => item.Id === rowIndex);

    const prop = transformToData(value, name, step, index)
    const itemCritico = {
      IdItemCritico: step[index].Id,
      ...prop
    }

    const data = await salvaItemCritico(itemCritico)

    step[index][name] = value ? value.Id : data[name]
    step[index]['CriticarItem'] = data.CriticarItem
    step[index]['DescricaoCriticarItem'] = data.DescricaoCriticarItem
    step[index]['DescricaoRiscoItemCritico'] = data.DescricaoRiscoItemCritico
    step[index]['JustificativaItemCritico'] = data.JustificativaItemCritico
    step[index]['RiscoItemCritico'] = data.RiscoItemCritico

    setLoading(false)
    setRows(step);
  })


  const hasOrder = useCallback(() => {
    return sortList.hasOrders();
  }, [sortList]);

  const onSortList = useCallback(
    async ({ attribute }) => {
      attribute = attribute === "StatusPedidoDescricao" ? "StatusPedido" : attribute
      sortList.load(attribute);
      if (pagina !== 1) setPage(1);
      await loadData();
    },
    [sortList, pagina, loadData]
  );

  const clearOrder = useCallback(() => {
    setSortList(new SortList());
  }, [loadData]);

  const blankMessage = (<div></div>)

  const getFilterIcon = () => {
    if(localStorage.getItem("THEME") != 1 ){
      if(verifyIsFiltered(filtros))
        return IconFilterBlue;
      else 
        return IconFilterGray;
    }else
      return undefined;
  }

  useEffect(() => {
    buscarJustificativas();
    buscarMotivos();
    loadData();
  }, [loadData])

  return (
    <div>
      <Header
        title={intl.get('menu.subMenuGeneral.critical-itens')}
        titleWidth={250}
      >
        <div className='container-filtro-itens-criticos'>
          <Button
            onClick={() => setMostrarFiltro(!mostrarFiltro)}
            type='default'
            value={intl.get('filters.filter')}
            className='button-filter'
            icon={getFilterIcon()}
          /> 
        </div>
        <Layout.Sidebar
          width="450px"
          visible={mostrarFiltro}
          triggerClose={() => setMostrarFiltro(false)}
          background="var(--color-contrast-white)"
          offsetTop="96px"
          side="right"
          block
          float
          icon={IconEmpty}
        >
          <FiltroGerencial isItensCriticos={true} setFilters={manipulaFiltros} filterDefault={filtros} />
        </Layout.Sidebar>
      </Header>
      <div className="last-update">
        {intl.get('commons.lastUpdate')}: <span>{ultimaAtualizacao}</span>
      </div>
      <div className="colunas">
        <StyledTable>
          <Button
            width="50px"
            type="default"
            value={intl.get('management.columns')}
            icon={iconeMais}
            className="colunas-button-dual-list"
            onClick={() => setModal(true)}
          />
        </StyledTable>
      </div>
      {
        modal && (
          <Dialog
            contentStyle={{ width: "900px" }}
            open={modal}
            title={intl.get("management.visibleColumns")}
            autoScrollBodyContent
            onRequestClose={() => setModal(false)}
          >
            <DualList
              itemsList1={colunasInvisiveis}
              itemsList2={colunasVisiveis}
              onLinking={manipulaColunasVisiveis}
              onUnlink={manipulaColunasInvisiveis}
              title1={intl.get("management.hidden")}
              title2={intl.get("management.visible")}
              checkbox={false}
            />
            <div>
              <Button
                width="100px"
                className="dual-list-button"
                type="primary"
                value={intl.get("geral.buttonsDefault.save")}
                disabled={colunasVisiveis.length === 0}
                onClick={salvaColunasSelecionadas}
              />
            </div>
          </Dialog>
        )
      }
      <div className="table-content">
        {
          itensCriticos &&
          <StyledTable>
            <TableListPagination
              fetchedData={itensCriticos}
              itemsPerPageOptions={[50, 100, 200, 300]}
              perPage={50}
              columns={
                [
                  {
                    ...columnStyle,
                    labelHeader: intl.get("commons.risk"),
                    attribute: "DescricaoRiscoItemCritico",
                    align: "center",
                    sortable: true,
                    visible: colunasVisiveis.some((column) => column['name'] === 'Descrição Risco Item Crítico'),
                    renderCol: row =>
                      columnRisco(row.DescricaoRiscoItemCritico, row.RiscoItemCritico)
                  },
                  {
                    ...columnStyle,
                    labelHeader: intl.get("commons.specialist"),
                    attribute: "NomeUsuarioResponsavel",
                    align: "center",
                    sortable: true,
                    visible: colunasVisiveis.some((column) => column['name'] === 'Nome Usuário Responsável'),
                    renderCol: row =>
                      normalizeColumn(row.NomeUsuarioResponsavel)
                  },
                  {
                    ...columnStyle,
                    labelHeader: intl.get("commons.source"),
                    attribute: "Centro",
                    align: "center",
                    sortable: true,
                    visible: colunasVisiveis.some((column) => column['name'] === 'Centro'),
                    renderCol: row =>
                      normalizeColumn(row.Centro)
                  },
                  {
                    ...columnStyle,
                    labelHeader: intl.get("master-data.general-configuration.coordination"),
                    attribute: "Coordenacao",
                    align: "center",
                    sortable: true,
                    visible: colunasVisiveis.some((column) => column['name'] === 'Coordenação'),
                    renderCol: row =>
                      normalizeColumn(row.Coordenacao)
                  },
                  {
                    ...columnStyle,
                    labelHeader: intl.get("commons.family"),
                    attribute: "Familia",
                    align: "center",
                    sortable: true,
                    visible: colunasVisiveis.some((column) => column['name'] === 'Família'),
                    renderCol: row =>
                      normalizeColumn(row.Familia)
                  },
                  {
                    ...columnStyle,
                    labelHeader: intl.get('commons.labeledFamily'),
                    attribute: "FamiliaRotulada",
                    align: "center",
                    sortable: true,
                    visible: colunasVisiveis.some((column) => column['name'] === 'Família Rotulada'),
                    renderCol: row => {
                      return <StyledCellLabeledFamily
                        className="coluna-familiRotulada__itens-criticos"
                        onClick={() => window.open(`/stocksDetail/${row.IdFamiliaRotuladaCentro}`)}>
                        {row.FamiliaRotulada ? row.FamiliaRotulada : '-'}
                      </StyledCellLabeledFamily>
                    }
                  },
                  {
                    ...columnStyle,
                    labelHeader: intl.get("commons.material"),
                    attribute: "DescricaoMaterial",
                    align: "center",
                    sortable: true,
                    visible: colunasVisiveis.some((column) => column['name'] === 'Descrição Material'),
                    renderCol: row => {
                      let descricao = `${formatNumeroMaterial(row.CodigoMaterial)} - ${row.DescricaoMaterial}`
                      return normalizeColumn(descricao);
                    }
                  },
                  {
                    ...columnStyle,
                    labelHeader: intl.get("commons.availableStock"),
                    attribute: "EstoqueDisponivel",
                    align: "center",
                    sortable: true,
                    visible: colunasVisiveis.some((column) => column['name'] === 'Estoque Disponível'),
                    renderCol: row => (
                      normalizeColumn(formatNumber(row.EstoqueDisponivel, limitMinimumDecimalInZero))
                    )
                  },
                  {
                    ...columnStyle,
                    labelHeader: intl.get("management.stockBlocked"),
                    attribute: "EstoqueBloqueado",
                    align: "center",
                    visible: colunasVisiveis.some((column) => column['name'] === 'Estoque Bloqueado'),
                    sortable: true,
                    renderCol: row =>
                      normalizeColumn(formatNumber(row.EstoqueBloqueado, limitMinimumDecimalInZero))
                  }
                  ,
                  {
                    ...columnStyle,
                    labelHeader: () => <div title={intl.get("management.descriptionMin")}>{intl.get("management.min")}</div>,
                    attribute: "PoliticaEstoqueMinima",
                    align: "center",
                    sortable: true,
                    visible: colunasVisiveis.some((column) => column['name'] === 'PE Mínimo'),
                    renderCol: row => (
                      normalizeColumn(formatNumber(row.PoliticaEstoqueMinima, limitMinimumDecimalInZero))
                    )
                  },
                  {
                    ...columnStyle,
                    labelHeader: () => <div title={intl.get("management.descriptionMax")}>{intl.get("management.max")}</div>,
                    attribute: "PoliticaEstoqueMaxima",
                    align: "center",
                    sortable: true,
                    visible: colunasVisiveis.some((column) => column['name'] === 'PE Máximo'),
                    renderCol: row => (
                      normalizeColumn(formatNumber(row.PoliticaEstoqueMaxima, limitMinimumDecimalInZero))
                    )
                  },
                  {
                    ...columnStyle,
                    labelHeader: intl.get("management.statusStock"),
                    attribute: "DescricaoStatusEstoqueItemCritico",
                    align: "center",
                    sortable: true,
                    visible: colunasVisiveis.some((column) => column['name'] === 'Status Estoque'),
                    renderCol: row => (
                      columnStatus(row.DescricaoStatusEstoqueItemCritico)
                    )
                  },
                  {
                    ...columnStyle,
                    labelHeader: () => <div title={intl.get("management.descriptionRequirement")}>{intl.get("management.requirement")}</div>,
                    attribute: "ConsumoHorizonteProgramacao",
                    align: "center",
                    sortable: true,
                    visible: colunasVisiveis.some((column) => column['name'] === 'Necessidade 75 dias'),
                    renderCol: row => (
                      normalizeColumn(formatNumber(row.ConsumoHorizonteProgramacao, limitMinimumDecimalInZero))
                    )
                  },
                  {
                    ...columnStyle,
                    labelHeader: intl.get("management.complain"),
                    attribute: "DescricaoCriticarItem",
                    align: "center",
                    sortable: true,
                    visible: colunasVisiveis.some((column) => column['name'] === 'Criticar'),
                    renderCol: row =>
                      normalizeColumn(row.DescricaoCriticarItem)
                  },
                  {
                    ...columnStyle,
                    labelHeader: intl.get("management.missingDay"),
                    attribute: "Suficiencia",
                    align: "center",
                    sortable: true,
                    visible: colunasVisiveis.some((column) => column['name'] === 'Suficiência'),
                    renderCol: row => (
                      normalizeColumn(Moment(row.Suficiencia).format("[DM]"))
                    )
                  },
                  {
                    ...columnStyle,
                    labelHeader: intl.get("commons.sufficiency"),
                    attribute: "DiaFalta",
                    align: "center",
                    sortable: true,
                    visible: colunasVisiveis.some((column) => column['name'] === 'Dia Falta'),
                    renderCol: row => (
                      normalizeColumn(row.DiaFalta)
                    )
                  },
                  {
                    labelHeader: intl.get("management.nextOrder"),
                    attribute: "DataProximoPedido",
                    align: "center",
                    width: "4%",
                    sortable: true,
                    visible: colunasVisiveis.some((column) => column['name'] === 'Próximo Pedido'),
                    renderCol: row =>
                      columnProximoPedido(row)
                  },
                  {
                    ...columnStyle,
                    labelHeader: intl.get("commons.status"),
                    attribute: "StatusPedidoDescricao",
                    align: "center",
                    sortable: true,
                    visible: colunasVisiveis.some((column) => column['name'] === 'Status'),
                    renderCol: row =>
                      normalizeColumn(row.StatusPedidoDescricao)
                  },
                  {
                    ...columnStyle,
                    labelHeader: intl.get('commons.justification'),
                    attribute: "JustificativaItemCritico",
                    align: "center",
                    width: "10%",
                    sortable: true,
                    visible: colunasVisiveis.some((column) => column['name'] === 'Justificativa'),
                    renderCol: row =>
                      <SelectBox
                        name='justificativa'
                        className='justificativaSelect'
                        value={row.IdJustificativaItemCritico}
                        options={justificativas}
                        onChange={value => handleTableSelectBox('IdJustificativaItemCritico', value, row.Id)}
                        valueKey='Id'
                        labelKey='Descricao'
                        placeholder={intl.get('commons.justification')}
                        searchable
                        openOnFocus
                        clearable
                      />
                  },
                  {
                    ...columnStyle,
                    labelHeader: intl.get('commons.motive'),
                    attribute: "MotivoItemCritico",
                    align: "center",
                    sortable: true,
                    visible: colunasVisiveis.some((column) => column['name'] === 'Motivo'),
                    width: "10%",
                    renderCol: row =>
                      <SelectBox
                        name='motivo'
                        className='motivoSelect'
                        value={row.IdMotivoItemCritico}
                        options={motivos}
                        onChange={value => handleTableSelectBox('IdMotivoItemCritico', value, row.Id)}
                        valueKey='Id'
                        labelKey='Descricao'
                        placeholder={intl.get('commons.motive')}
                        searchable
                        openOnFocus
                        clearable
                      />
                  }
                ]
              }
              changePage={setPage}
              changeSize={setCountPage}
              showFooterColumns={false}
              customBlankState={blankMessage}
              showClearOrder={hasOrder()}
              onClickClearOrder={clearOrder}
              sortedFields={sortList}
              onSort={onSortList}
              intlText={true}
              clearOrderText={intl.get('capitalEmployed.clearOrderText')}
              clearFilterTex={intl.get('capitalEmployed.clearFilterText')}
            />
          </StyledTable>
        }
        <Loading isLoading={loading}></Loading>
        {
          (itensCriticos && itensCriticos.data.length === 0) &&
          <span className='no-records'>
            {intl.get('commons.noRecordsFound')}
          </span>
        }
      </div>
      {/* * Essas linhas devem ser removidas, após achar uma solução para multi async com teste. */}
      <button data-testid='teste-button__itens-criticos' className='test-button__itens-criticos' style={{ opacity: 0 }}
        onClick={() => handleTableSelectBox('IdJustificativaItemCritico', { Id: 1, Descricao: 'Teste' }, 1)} />
      <button data-testid='teste-button__itens-criticos__two' className='test-button__itens-criticos__two' style={{ opacity: 0 }}
        onClick={() => handleTableSelectBox('IdJustificativaItemCritico', null, 1)} />
    </div>
  )
}

export default ItensCriticos;
