import Fetch from 'utils/fetch'

export const searchFamily = (useFilterDefault) => {
  return Fetch.get(`/families:for-filters?UseFilterDefault=${useFilterDefault}`)
    .then(({ data }) => data)
}

export const searchLabeledFamily = (useFilterDefault) => {
  return Fetch.get(`/labeled-families:for-filters?UseFilterDefault=${useFilterDefault}`)
    .then(({ data }) => data)
}

export const searchMaterialGroup = (useFilterDefault) => {
  return Fetch.get(`/material-groups:for-filters?UseFilterDefault=${useFilterDefault}`)
    .then(({ data }) => data)
}

export const searchBusinessUnit = (useFilterDefault, queryFilters) => {
  return Fetch.get(`/business-units/for-filters?UseFilterDefault=${useFilterDefault}&${queryFilters ? queryFilters : ''}`)
    .then(({ data }) => data)
}

export const searchSuppliers = (useFilterDefault, queryFilters) => {
  return Fetch.get(`/suppliers:for-filters?UseFilterDefault=${useFilterDefault}&${queryFilters ? queryFilters : ''}`)
    .then(({ data }) => data)
}

export const searchCoordinations = (useFilterDefault) => {
  return Fetch.get(`/coordinations:for-filters?UseFilterDefault=${useFilterDefault}`)
    .then(({ data }) => data)
}

export const searchMaterials = (useFilterDefault, queryFilters) => {
  return Fetch.get(`/materials/for-filters?UseFilterDefault=${useFilterDefault}&${queryFilters ? queryFilters : ''}`)
    .then(({ data }) => data)
}

export const searchCountries = (useFilterDefault) => {
  return Fetch.get(`/countries:for-filters?UseFilterDefault=${useFilterDefault}`)
    .then(({ data }) => data)
}

export const searchSupplierUsers = () => Fetch.get('/supplier-users:for-filters').then(({ data }) => data)
