import Fetch from "utils/fetch";

export const searchBusinessUnitById = (id) => {
    return Fetch.get(`/unidadeNegocio/${id}`).then(({ data }) => data)
}

export const searchStatusTypes = () => {
    return Fetch.get(`/unidadeNegocio/listaStatusUnidade`).then(({ data }) => data)
}

export const searchTiposUnidade = () => {
    return Fetch.get(`/unidadeNegocio/listaTiposUnidade`).then(({ data }) => data)
}

export const saveUnit = (unit) => {
    return Fetch.post('/unidadeNegocio/salvar', unit)
}

export const getCountries = () => {
    return Fetch.get(`/countries:available`).then(({ data }) => data)
}