import { useState, useEffect, useCallback } from 'react';
import { Layout } from "@hbsis.uikit/react"
import { Button } from 'components/uikit-adapter';
import { TableListPagination } from "@hbsis-planning/hbsis-planning-core";
import Header from '../../../components/header';
import CapitalEmployedDialog from '../components/card';
import Loading from '../../../components/center-loading'
import DualList from '../components/dual'
import Dialog from 'material-ui/Dialog/Dialog';
import FiltroGerencial from 'components/filters/filter-gerencial/gerencial';
import StorePersist from "utils/store-persist";
import IconFilterGray from "images/icn-filter-gray.svg";
import IconFilterBlue from "images/icn-filter-blue.svg";

import capitalEmpregadoOKIcone from 'images/capital-empregado-ok.svg';
import capitalEmpregadoOportunidadeIcone from 'images/capital-empregado-oportunidade.svg';
import capitalEmpregadoRiscoIcone from 'images/capital-empregado-risco.svg'
import iconeMais from 'images/adicionar.svg';
import IconEmpty from "images/iconEmpty.icon";

import Moment from 'moment'
import intl from 'react-intl-universal';
import { saveConfiguredColumn } from './utils/columnConfiguration';

import { getEmployedCapital, convertToArrayQuery, getConfiguredColumn } from '../gerencial.service';
import SortList from './utils/sortList';

import './styles.css';
import StyledTable, { StyledTableCell, StyledTableCellRiscosPassivos } from './styledTable'
import { formatNumber, formatValueByCountry } from 'utils/format';
import { verifyIsFiltered } from 'utils/custom-functions';
import { capitalEmployedFilterDefault } from './utils/filter-utils';

const STATUS_ESTOQUE = {
  Ok: 1,
  Over: 2,
  Out: 3
}

const limitMinimumDecimalInZero = { minimumFractionDigits: 0 }

const STATUS_CAPITAL_EMPREGADO = {
  Risco: 1,
  Oportunidade: 2,
  SemEstoque: 3
}

const columnStyle = {
  dataStyle: { flex: 1 },
  headerStyle: { flex: 1 }
};

const getColumns = (visibleColumns, capitalEmployedHandler) => {
  return [
    {
      ...columnStyle,
      labelHeader: intl.get("commons.labeledFamily"),
      attribute: "FamiliaRotulada",
      align: "center",
      sortable: true,
      visible: visibleColumns.some((column) => column['name'] === 'Familia Rotulada'),
      renderCol: row =>
        normalizeColumn(capitalEmployedHandler, row, row.FamiliaRotulada, null, "FamiliaRotulada"),
    },
    {
      ...columnStyle,
      labelHeader: intl.get("commons.source"),
      attribute: "Centro",
      align: "center",
      sortable: true,
      visible: visibleColumns.some((column) => column['name'] === 'Origem'),
      renderCol: row =>
        <StyledTableCell onClick={capitalEmployedHandler.bind(null, row, null)} title={row.UnidadeNegocio}>{row.Centro}</StyledTableCell>
    },
    {
      ...columnStyle,
      labelHeader: intl.get("commons.status"),
      attribute: "StatusEstoqueDescricao",
      align: "center",
      sortable: true,
      visible: visibleColumns.some((column) => column['name'] === 'Status'),
      renderCol: row =>
        columnStatus(capitalEmployedHandler, row, row.StatusEstoqueDescricao, null, row.StatusEstoque)
    },
    {
      ...columnStyle,
      labelHeader: () => <div title={intl.get("management.releasedStock")}>{intl.get("management.releasedStockAbbreviated")}</div>,
      attribute: "EstoqueLiberado",
      align: "center",
      sortable: true,
      visible: visibleColumns.some((column) => column['name'] === 'Estoque Liberado'),
      renderCol: row => (
        <StyledTableCell onClick={capitalEmployedHandler.bind(null, row, null)}>{formatNumber(row.EstoqueLiberado, limitMinimumDecimalInZero)} {row.EstoqueLiberadoUM}</StyledTableCell>
      )
    },
    {
      ...columnStyle,
      labelHeader: intl.get("management.availableStockValue"),
      attribute: "ValorEstoqueLiberado",
      align: "center",
      sortable: true,
      visible: visibleColumns.some((column) => column['name'] === 'Estoque Liberado Valor'),
      renderCol: row =>
        normalizeColumn(capitalEmployedHandler, row, formatValueByCountry(row.ValorEstoqueLiberado, row.UnidadeNegocioPais), true)
    },
    {
      ...columnStyle,
      labelHeader: () => <div title={intl.get('management.stockBlocked')}>{intl.get('management.stockBlockedAbbreviated')}</div>,
      attribute: "EstoqueBloqueado",
      align: "center",
      sortable: true,
      visible: visibleColumns.some((column) => column['name'] === 'Estoque Bloqueado'),
      renderCol: row => (
        <StyledTableCell onClick={capitalEmployedHandler.bind(null, row, null)}>{formatNumber(row.EstoqueBloqueado, limitMinimumDecimalInZero)} {row.EstoqueBloqueadoUM}</StyledTableCell>
      )
    },
    {
      ...columnStyle,
      labelHeader: intl.get("management.blockedStockValue"),
      attribute: "ValorEstoqueBloqueado",
      align: "center",
      sortable: true,
      visible: visibleColumns.some((column) => column['name'] === 'Estoque Bloqueado Valor'),
      renderCol: row => normalizeColumn(capitalEmployedHandler, row, formatValueByCountry(row.ValorEstoqueBloqueado, row.UnidadeNegocioPais), true)
    },
    {
      ...columnStyle,
      labelHeader: intl.get("management.totalStock"),
      attribute: "EstoqueTotal",
      align: "center",
      sortable: true,
      visible: visibleColumns.some((column) => column['name'] === 'Estoque Total'),
      renderCol: row => (
        <StyledTableCell onClick={capitalEmployedHandler.bind(null, row, null)}>{formatNumber(row.EstoqueTotal, limitMinimumDecimalInZero)} {row.EstoqueTotalUM}</StyledTableCell>
      )
    },
    {
      ...columnStyle,
      labelHeader: intl.get("management.currencyValue"),
      attribute: "ValorEstoqueTotal",
      align: "center",
      visible: visibleColumns.some((column) => column['name'] === 'Valor Total'),
      sortable: true,
      renderCol: row =>
        normalizeColumn(capitalEmployedHandler, row, formatValueByCountry(row.ValorEstoqueTotal, row.UnidadeNegocioPais), true)
    }
    ,
    {
      ...columnStyle,
      labelHeader: () => <div title={intl.get("management.min-description")}>{intl.get("management.min")}</div>,
      attribute: "PoliticaEstoqueMinima",
      align: "center",
      sortable: true,
      visible: visibleColumns.some((column) => column['name'] === 'PE Mínimo'),
      renderCol: row => (
        <StyledTableCell onClick={capitalEmployedHandler.bind(null, row, null)}>{formatNumber(row.PoliticaEstoqueMinima, limitMinimumDecimalInZero)} {row.EstoqueTotalUM}</StyledTableCell>
      )
    },
    {
      ...columnStyle,
      labelHeader: () => <div title={intl.get("management.max-description")}>{intl.get("management.max")}</div>,
      attribute: "PoliticaEstoqueMaxima",
      align: "center",
      sortable: true,
      visible: visibleColumns.some((column) => column['name'] === 'PE Máximo'),
      renderCol: row => (
        <StyledTableCell onClick={capitalEmployedHandler.bind(null, row, null)}>{formatNumber(row.PoliticaEstoqueMaxima, limitMinimumDecimalInZero)} {row.EstoqueTotalUM}</StyledTableCell>
      )
    },
    {
      ...columnStyle,
      labelHeader: <div title={intl.get("management.consumption-description")}>{intl.get("management.consumption")}</div>,
      attribute: "ConsumoTotal",
      align: "center",
      sortable: true,
      visible: visibleColumns.some((column) => column['name'] === 'Consumo'),
      renderCol: row => (
        <StyledTableCell onClick={capitalEmployedHandler.bind(null, row, null)}>{formatNumber(row.ConsumoTotal, limitMinimumDecimalInZero)} {row.ConsumoTotalUM}</StyledTableCell>
      )
    },
    {
      ...columnStyle,
      labelHeader: intl.get("management.passiveRisksQuantity"),
      attribute: "RiscosPassivos",
      align: "center",
      sortable: true,
      visible: visibleColumns.some((column) => column['name'] === 'Riscos Passivos Quantidade'),
      renderCol: row => (
        <StyledTableCell onClick={capitalEmployedHandler.bind(null, row, null)}>{formatNumber(row.RiscosPassivos, limitMinimumDecimalInZero)} {row.RiscosPassivosUM}</StyledTableCell>
      )
    },
    {
      ...columnStyle,
      labelHeader: intl.get("management.passiveRisksValue"),
      attribute: "ValorRiscosPassivos",
      align: "center",
      sortable: true,
      visible: visibleColumns.some((column) => column['name'] === 'Riscos Passivos Valor'),
      renderCol: row =>
        normalizeColumn(capitalEmployedHandler, row, formatValueByCountry(row.ValorRiscosPassivos, row.UnidadeNegocioPais), true)
    },
    {
      ...columnStyle,
      labelHeader: intl.get("management.balanceFinal"),
      attribute: "SaldoFinal",
      align: "center",
      sortable: true,
      visible: visibleColumns.some((column) => column['name'] === 'Saldo Final'),
      renderCol: row => (
        <StyledTableCell onClick={capitalEmployedHandler.bind(null, row, null)}>{formatNumber(row.SaldoFinal, limitMinimumDecimalInZero)} {row.SaldoFinalUM}</StyledTableCell>
      )
    },
    {
      ...columnStyle,
      labelHeader: intl.get("management.balancePE"),
      attribute: "SaldoComPoliticaEstoque",
      align: "center",
      sortable: true,
      visible: visibleColumns.some((column) => column['name'] === 'Saldo Com PE'),
      renderCol: row =>
        <StyledTableCell onClick={capitalEmployedHandler.bind(null, row, null)}>{formatNumber(row.SaldoComPoliticaEstoque, limitMinimumDecimalInZero)} {row.SaldoComPoliticaEstoqueUM}</StyledTableCell>
    },
    {
      labelHeader: intl.get("management.capitalEmplyedBalancePER"),
      attribute: "ValorRiscosPassivosSaldoPoliticaEstoque",
      align: "center",
      width: "10%",
      sortable: true,
      visible: visibleColumns.some((column) => column['name'] === 'Riscos Passivos Final'),
      renderCol: row =>
        columnRiscosPassivos(capitalEmployedHandler, row, formatValueByCountry(row.ValorRiscosPassivosSaldoPoliticaEstoque, row.UnidadeNegocioPais), true, row.StatusCapitalEmpregado)
    }
  ];
};

const blankMessage = (<div></div>)
const getBoldColor = (bold) => bold ? '#000000' : null

const normalizeColumn = (capitalEmployedHandler, row, element, bold, linkTo) => {
  return (
    <StyledTableCell className="main-span"
      style={
        {
          color: linkTo ? 'var(--color-brand-300)' : getBoldColor(bold),
          fontWeight: linkTo || bold ? 'bold' : 'normal',
          textAlign: 'center',
        }
      }
      onClick={capitalEmployedHandler.bind(null, row, linkTo)}>
      {bold && element !== 0 ? bold : ''} {bold && element === 0 ? '-' : element}
    </StyledTableCell>
  )
}

const columnStatus = (capitalEmployedHandler, row, element, symbol, status) => {

  let color = '';

  switch (status) {
    case STATUS_ESTOQUE.Ok:
      color = '#383A3B';
      break;
    case STATUS_ESTOQUE.Over:
      color = '#00980A';
      break;
    case STATUS_ESTOQUE.Out:
      color = '#BF0909';
      break;
  }

  return (
    <StyledTableCell style={{ color: color, fontWeight: 'var(--font-weight-semibold)' }} onClick={capitalEmployedHandler.bind(null, row, null)}>
      {symbol ? symbol : ''} {element}
    </StyledTableCell>
  )
}

const columnRiscosPassivos = (capitalEmployedHandler, row, element, symbol, status) => {

  let color = '';
  let backGroundColor = '';
  let icone;

  switch (status) {
    case STATUS_CAPITAL_EMPREGADO.Risco:
      color = '#BF0909';
      backGroundColor = '#FC54572E';
      icone = capitalEmpregadoRiscoIcone
      break;
    case STATUS_CAPITAL_EMPREGADO.Oportunidade:
      color = '#00980A';
      backGroundColor = '#99D59B';
      icone = capitalEmpregadoOportunidadeIcone;
      break;
    case STATUS_CAPITAL_EMPREGADO.SemEstoque:
      color = '#383A3B';
      icone = capitalEmpregadoOKIcone;
      break;
  }

  return (
    <StyledTableCellRiscosPassivos className="coluna-riscos-passivos" style={{ color: color, backgroundColor: backGroundColor }} onClick={capitalEmployedHandler.bind(null, row, null)}>
      <div className="icone-coluna">
        <img className="icone" style={{ fill: color }} src={icone}></img>
      </div>
      <div className="valor-coluna">
        {symbol ? symbol : ''} {element}
      </div>
    </StyledTableCellRiscosPassivos>
  )
}

const getConfiguration = (type) => {
  const configuration = getConfiguredColumn();
  return type === 1 ? configuration.Visible : configuration.Hidden;
}

const RiscosPassivos = () => {

  const [capitalEmpregado, setCapitalEmpregado] = useState(undefined);
  const [countPage, setCountPage] = useState(50);
  const [page, setPage] = useState(1);
  const [cardContent, setCardContent] = useState({});
  const [showCard, setCardVisible] = useState(false);
  const [lastUpdate, setLastUpdate] = useState(null);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [sortList, setSortList] = useState(new SortList());
  const [visibleColumns, setVisibleColumns] = useState(() => getConfiguration(1));
  const [hiddenColumns, setHiddenColumns] = useState(() => getConfiguration(2));
  const [mostrarFiltro, setMostrarFiltro] = useState(false);
  const [filters, setFilters] = useState(() => {
    const filterCapitalEmployedMulti = StorePersist.getValuesJSON("filterCapitalEmployedMulti");
    return filterCapitalEmployedMulti ?? capitalEmployedFilterDefault;
  });

  const loadData = useCallback(async () => {
    const IsFilterDefault = await StorePersist.getValuesJSON("filterMyItemsOnly");
    const sort = sortList.toUrl();
    const response = await getEmployedCapital(
      countPage,
      page,
      filters,
      IsFilterDefault,
      sort
    );

    const obj = {
      data: response.Data,
      totalCount: response.TotalCount,
      totalPages: response.TotalPages
    }

    const dataGeracao = response.Data.length > 0 ? Moment(response.Data[0].DataGeracao, 'DD/MM HH:mm') : undefined;
    setLastUpdate(Moment(dataGeracao).format("[DM]"));
    setCapitalEmpregado(obj)
    setLoading(false);
  },
    [page, countPage, filters, sortList]
  );

  const capitalEmployedHandler = (row, linkTo = null) => {
    if (linkTo) {
      window.open(`/stocksDetail/${row.IdFamiliaRotuladaCentro}`)
    } else {
      setCardVisible(!showCard);
      if (row) setCardContent(row);
    }

  };

  const onColumnVisible = useCallback(col => {

    setVisibleColumns(prev => {
      prev.push(col);

      return prev;
    });
    setHiddenColumns(prev =>
      prev.filter(item => JSON.stringify(item.name) !== JSON.stringify(col.name))
    );
  }, []);

  const onColumnHidden = useCallback(col => {

    setHiddenColumns(prev => {
      prev.push(col);

      return prev;
    });
    setVisibleColumns(prev =>
      prev.filter(item => JSON.stringify(item.name) !== JSON.stringify(col.name))
    );
  }, []);

  const saveSelectedColumns = useCallback(() => {
    const columns = {
      Hidden: hiddenColumns,
      Visible: visibleColumns
    }
    saveConfiguredColumn(columns);
    setModal(false);

  }, [visibleColumns, hiddenColumns]);

  const hasOrder = useCallback(() => {
    return sortList.hasOrders();
  }, [sortList]);

  const onSortList = useCallback(
    async ({ attribute }) => {
      sortList.load(attribute);
      if (page !== 1) setPage(1);
      await loadData();
    },
    [sortList, page, loadData]
  );

  const clearOrder = useCallback(() => {
    setSortList(new SortList());
  }, [loadData]);

  const manipulaFiltros = (data) => {
    const stringQuery = convertToArrayQuery(
      [
        data.Coordenacoes,
        data.Carteiras,
        data.Familias,
        data.FamiliasRotuladas
      ],
      [
        'Coordenacoes',
        'Carteiras',
        'Familias',
        'FamiliasRotuladas'
      ]
    )

    const newFilter = { ...data, query: stringQuery };
    setFilters(newFilter);
    StorePersist.setValuesJSON("filterCapitalEmployedMulti", newFilter);
    setMostrarFiltro(false);
    setLoading(true);
  }

  const getFilterIcon = () => {
    if(localStorage.getItem("THEME") != 1 ){
       if(verifyIsFiltered(filters))
        return IconFilterBlue;
      else
        return IconFilterGray;
    }else
      return undefined;
  }

  useEffect(() => {
    loadData();
  }, [loadData])

  return (
    <div>
      <Header
        title={intl.get('commons.capitalEmployed')} titleWidth={250} >
        <div className="filterContainerCapitalEmpregado">
          <Button
            onClick={() => setMostrarFiltro(!mostrarFiltro)}
            type="default"
            value={intl.get('filters.filter')}
            className='button-filter'
            icon={getFilterIcon()}
          />
        </div>
        <Layout.Sidebar
          width="450px"
          visible={mostrarFiltro}
          className="sideBar"
          triggerClose={() => setMostrarFiltro(false)}
          background="var(--color-contrast-white)"
          offsetTop="96px"
          side="right"
          block
          float
          icon={IconEmpty}
        >
          <FiltroGerencial setFilters={manipulaFiltros} filterDefault={filters} />
        </Layout.Sidebar>
      </Header>
      <div className="last-update">
        {intl.get('commons.lastUpdate')}: <span>{lastUpdate}</span>
      </div>
      <div className="colunas">
        <StyledTable>
          <Button
            width="50px"
            type="default"
            value={intl.get('management.columns')}
            icon={iconeMais}
            className="colunas-button-dual-list"
            onClick={() => setModal(true)}
          />
        </StyledTable>
      </div>
      {
        modal && (
          <Dialog
            contentStyle={{ width: "900px" }}
            open={modal}
            title={intl.get("management.visibleColumns")}
            autoScrollBodyContent
            onRequestClose={() => setModal(false)}
          >
            <DualList
              itemsList1={hiddenColumns}
              itemsList2={visibleColumns}
              onLinking={onColumnVisible}
              onUnlink={onColumnHidden}
              title1={intl.get("management.hidden")}
              title2={intl.get("management.visible")}
              checkbox={false}
            />
            <div>
              <Button
                width="100px"
                className="dual-list-button"
                type="primary"
                value={intl.get("geral.buttonsDefault.save")}
                disabled={visibleColumns.length === 0}
                onClick={saveSelectedColumns}
              />
            </div>
          </Dialog>
        )
      }
      {showCard && (
        <CapitalEmployedDialog
          visible={showCard}
          data={cardContent}
          onClose={() => capitalEmployedHandler()}
        />
      )}
      <div className="table-content">
        {
          capitalEmpregado &&
          <StyledTable>
            <TableListPagination
              fetchedData={capitalEmpregado}
              itemsPerPageOptions={[50, 100, 200, 300]}
              perPage={50}
              columns={getColumns(visibleColumns, capitalEmployedHandler)}
              changePage={setPage}
              changeSize={setCountPage}
              showClearOrder={hasOrder()}
              onClickClearOrder={clearOrder}
              sortedFields={sortList}
              onSort={onSortList}
              showFooterColumns={false}
              customBlankState={blankMessage}
              intlText={true}
              clearOrderText={intl.get('capitalEmployed.clearOrderText')}
              clearFilterTex={intl.get('capitalEmployed.clearFilterText')}
            />
          </StyledTable>
        }
        <Loading isLoading={loading}></Loading>
        {
          (capitalEmpregado && capitalEmpregado.data.length === 0) &&
          <span className='no-records'>
            {intl.get('commons.noRecordsFound')}
          </span>
        }
      </div>
    </div>

  )
}

export default RiscosPassivos;
