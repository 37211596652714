import styled from 'styled-components'
import { Button } from 'components/uikit-adapter/index';

export const ButtonCancel = styled(Button)`
  width: 140px;
  margin: 0;
  margin-left: 12px;

  background-color: #FFFFFF !important;
  color: var(--color-feedback-danger-300) !important;
  border-radius: 3px;
  border: 1px solid var(--color-feedback-danger-300);
`
