import TipoTarefa from "../../models/tarefas/tipo-tarefa";
import TipoUsuario from "../../models/usuarios/tipo-usuario";

export const obterUrlTarefa = (tarefa, tipoUsuario) => {
  switch (tarefa.TipoTarefa) {
    case TipoTarefa.FuroColeta:
    case TipoTarefa.FuroEntrega:
    case TipoTarefa.RegraContrapropostaPendente:
    case TipoTarefa.PedidoAguardandoComposicaoCarga:
    case TipoTarefa.PedidoEmOverPendente:
    case TipoTarefa.AguardandoAnaliseCancelamento:
    case TipoTarefa.CancelamentoPedidoAguardandoCiencia:
    case TipoTarefa.CienciaPendente: {
      if (tipoUsuario === TipoUsuario.Fornecedor || !tarefa.MaterialAtivo) {
        return obterUrlPedido(tarefa.IdOrigem);
      } else {
        return obterUrlGeralComPedido(
          tarefa.IdFamiliaRotuladaCentro,
          tarefa.IdOrigem
        );
      }
    }
    case TipoTarefa.RenegociacaoEmergencialData:
    case TipoTarefa.RenegociacaoEmergencialDataRecusada:
    case TipoTarefa.PedidoAguardandoDescarga:
    case TipoTarefa.CancelamentoRecusadoAguardandoCiencia:
    case TipoTarefa.PedidoPendenteAnaliseComposicaoCargaAmbev: {
      if (tarefa.MaterialAtivo) {
        return obterUrlGeralComPedido(
          tarefa.IdFamiliaRotuladaCentro,
          tarefa.IdOrigem
        );
      } else {
        return obterUrlPedido(tarefa.IdOrigem);
      }
    }
    case TipoTarefa.InclusaoPedidoUrgente:
    case TipoTarefa.EstoqueEmOver:
    case TipoTarefa.EstoqueEmOverCritico:
      return obterUrlGeral(tarefa.IdFamiliaRotuladaCentro);
    case TipoTarefa.TarefaComposicaoEmNegociacao:
    case TipoTarefa.TarefaComposicaoEmRenegociacaoEmergencial:
      return getUrlLoadComposition(tarefa.IdOrigem);
    case TipoTarefa.VolumePendenteDeAceite:
    case TipoTarefa.VolumeCienciaPendente:{
      if (tipoUsuario === TipoUsuario.Fornecedor || !tarefa.MaterialAtivo) {
        return obterUrlVolume(tarefa.IdOrigem);
      } else {
        return obterUrlGeralComVolume(
          tarefa.IdFamiliaRotuladaCentro,
          tarefa.IdOrigem
        );
      }
    }     
    default:
      return "#";
  }
};

const obterUrlGeralComPedido = (idFamiliaRotuladaCentro, idPedido) => {
  return `/stocksDetail/${idFamiliaRotuladaCentro}/${idPedido}/P`;
};

const obterUrlGeralComVolume = (idFamiliaRotuladaCentro, idVolume) => {
  return `/stocksDetail/${idFamiliaRotuladaCentro}/${idVolume}/V`;
};

const obterUrlPedido = (idPedido) => {
  return `/ordersVisionDetail/${idPedido}/P`;
};

const obterUrlVolume = (idVolume) => {
  return `/ordersVisionDetail/${idVolume}/V`;
};

const obterUrlGeral = (idFamiliaRotuladaCentro) => {
  return `/stocksDetail/${idFamiliaRotuladaCentro}`;
};

const getUrlLoadComposition = (idComposition) => `/loadComposition?code=${idComposition}`
