import PropTypes from "prop-types"
import { useEffect, useState, Fragment } from 'react';
import intl from 'react-intl-universal';
import moment from 'moment';
import * as S from './table.styled';
import { dateNameToPtBr, parseInitialDate, returnToday } from '../../utils/date';
import PedidoPacoteAzul from 'images/pedido-pacote-azul.png'
import PedidoPacoteRoxo from 'images/pedido-pacote-roxo.png'
import Icon from 'components/icon';
import './style.css'
import { withRouter, Link } from 'react-router-dom'
import Session from 'utils/user-storage'
import { formatNumber } from 'utils/format';

const PERFIL_PCI_SOLUTIONS = 1
const PERFIL_MASTER_DATA_SOLUTIONS = 2
const PERFIL_GESTOR_SOLUTIONS = 5

const userAmbev = () =>
  Session.get().PerfilAcesso === PERFIL_PCI_SOLUTIONS ||
  Session.get().PerfilAcesso === PERFIL_MASTER_DATA_SOLUTIONS ||
  Session.get().PerfilAcesso === PERFIL_GESTOR_SOLUTIONS


const Table = ({ data, days, type }) => {
  const newDays = { ...days };
  const [normalized, setNormalized] = useState(undefined);
  const today = returnToday();
  const countDays = moment(Object.values(newDays)[1]).diff(moment(Object.values(newDays)[0]), 'days', true);
  const render = new Array(countDays + 1).fill('');

  const renderTableHeader = (index) => {
    const initialDay = parseInitialDate(Object.values(days)[0], '-');
    initialDay.setDate(initialDay.getDate() + index);

    return (
      <th>
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '8px' }}>
          <p style={{ color: 'var(--color-contrast-brand)', fontWeight: 'var(--font-weight-semibold)' }}>{today === initialDay.getTime() ? intl.get('weekdays.today') : dateNameToPtBr(moment(initialDay).format('ddd'))}</p>
          <p>{moment(initialDay).format('L')}</p>
        </div>
      </th>
    )
  }

  const renderTableCardItemQuantity = (item) => (
    item.Quantities.length > 0 ?
      item.Quantities.map((quantitie) => (
        <p>{formatNumber(quantitie.Quantity)} {quantitie.UnitMeasurement}</p>
      ))
      : <S.BlankLink />
  )
  const renderTableCardItemOrders = (item) => (
    item.Orders ?
      <p style={{ color: !item.Critical && item.Orders > 0 ? 'green' : 'red' }}>
        {item.Orders} {item.Orders === 1 ? intl.get('planningDaily.order') : intl.get('commons.orders')}
      </p>
      : null
  )

  const renderCardStyle = (item) => {
    let className = 'card';

    if (!item.Critical && item.Orders > 0) {
      className = 'card green'
    }
    if (item.Critical && item.Orders > 0) {
      className = 'card red'
    }
    return className
  }

  const normalizeData = () => {
    const factories = data.Factories.map((x) => {
      const row = {};
      row.Name = x.Name;
      row.Center = x.Center;
      row.LabeledFamilyGrid = x.LabeledFamilyGrid;
      return row
    });
    setNormalized(factories);
  }

  const getIconPedidoPacote = () => {
    const theme = localStorage.getItem('THEME');
    if(theme == 1){
      return PedidoPacoteRoxo;
    } else {
      return PedidoPacoteAzul;
    }
  }

  useEffect(() => {
    normalizeData();
  }, [data])

  return (
    <div
      className='scroll-bar-visibility'
      style={{ overflow: 'auto', margin: '16px', height: 'calc(100% - 100px)' }}>
      <S.TableStyled>
        <S.TableContentStyled>
          <S.TableHeaderStyled>
            <th>
              {intl.get('commons.destiny')}
            </th>
            <th>
              {intl.get('commons.labeledFamily')}
            </th>
            {render.map((item, index) => renderTableHeader(index))}
          </S.TableHeaderStyled>
          {
            normalized?.map((factorie) => (
              <Fragment key={factorie.Center}>
                <tr key={factorie.Center}>
                  <td className='source-column' rowSpan={factorie.LabeledFamilyGrid.length}>
                    {factorie.Center} - {factorie.Name}
                  </td>
                  <S.LabeledFamily>
                    <S.LabeledFamilyContainer>
                      <S.LabeledFamilyIcon>
                        <Icon src={getIconPedidoPacote()} label="Pacote" />
                      </S.LabeledFamilyIcon>
                      <button className='familia-rotulada__one' onClick={() => userAmbev() && window.open(`/stocksDetail/${factorie.LabeledFamilyGrid[0].IdLabeledFamilyCenter}`)}>
                        {factorie.LabeledFamilyGrid[0].LabeledFamily}
                      </button>
                    </S.LabeledFamilyContainer>
                  </S.LabeledFamily>

                  {
                    factorie.LabeledFamilyGrid.map((labeledFamily, index) => (
                      index === 0 &&
                      labeledFamily.Grids.map((item) => (
                        <td key={labeledFamily.IdLabeledFamilyCenter}>
                          <div
                            className={renderCardStyle(item)}>
                            <Link style={{ textDecoration: 'none' }} to={`/gridVision/${labeledFamily.IdLabeledFamilyCenter}/${item.Date}/${type}`}>
                              {renderTableCardItemQuantity(item)}
                              {renderTableCardItemOrders(item)}
                            </Link>
                          </div>
                        </td>
                      ))
                    ))
                  }
                </tr>
                {factorie.LabeledFamilyGrid.map((labeledFamily, index) => (
                  index !== 0 &&
                  <tr key={labeledFamily.IdLabeledFamilyCenter}>
                    <S.LabeledFamily>
                      <S.LabeledFamilyContainer>
                        <S.LabeledFamilyIcon>
                          <Icon src={PedidoPacoteAzul} label="Pacote" />
                        </S.LabeledFamilyIcon>
                        <button className='familia-rotulada__two' onClick={() => userAmbev() && window.open(`/stocksDetail/${labeledFamily.IdLabeledFamilyCenter}`)}>
                          {labeledFamily.LabeledFamily}
                        </button>
                      </S.LabeledFamilyContainer>
                    </S.LabeledFamily>
                    {
                      labeledFamily.Grids.map(item => (
                        <td>
                          <div className={renderCardStyle(item)}>
                            <Link style={{ textDecoration: 'none' }} to={`/gridVision/${labeledFamily.IdLabeledFamilyCenter}/${item.Date}/${type}`}>
                              {renderTableCardItemQuantity(item)}
                              {renderTableCardItemOrders(item)}
                            </Link>
                          </div>
                        </td>
                      ))
                    }
                  </tr>
                ))
                }
              </Fragment>
            ))
          }
        </S.TableContentStyled>
      </S.TableStyled >
    </div>
  )
}

Table.propTypes = {
  data: PropTypes.shape({
    Factories: PropTypes.shape({
      map: PropTypes.func
    })
  }).isRequired,
  days: PropTypes.any.isRequired,
  type: PropTypes.any.isRequired
}

export default withRouter(Table);
