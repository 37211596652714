import Fetch from "utils/fetch";

export const searchTransfers = (idFamiliaRotuladaCentro) => {
  return Fetch.get(`shelfLife/consultarExistenciaTransferencias/${idFamiliaRotuladaCentro}`).then(({ data }) => data)
}

export const searchChartData = (idFamiliaRotulada, model) => {
  return Fetch.post(`/visaoEstoque/consultaVisaoEstoqueCardDetalheGrafico/${idFamiliaRotulada}`, model).then(({ data }) => data)
}

export const searchVariableStockPolicy = (idFamiliaRotuladaCentro) => {
  return Fetch.get(`/visaoEstoque/obterPoliticaEstoqueVariavel/${idFamiliaRotuladaCentro}`).then(({ data }) => data);
}

export const searchCardDetail = (idFamiliaRotuladaCentro, date, datetype) => {
  return Fetch.get(`/pedido/familiaRotuladaCentro/${idFamiliaRotuladaCentro}?date=${date ? date : ''}&dateType=${datetype ? datetype : 0}`).then(({ data }) => data);
}

export const checkSuggestionInconsistencies = (idUser, idFamiliaRotuladaCentro, materials) => {
  return Fetch.post(`/sugestoes/verificarInconsistenciasDaSugestao/${idUser}/${idFamiliaRotuladaCentro}`, { Sugestoes: materials }).then(({ data }) => data);
}

export const searchJustificationsRequestOnOver = () => {
  return Fetch.get(`/sugestoes/listaJustificativasPedidoEmOver`).then(({ data }) => data);
}

export const changeRequest = (alteracaoPedido) => {
  return Fetch.post(`/pedido/alterarPedidoVisaoEstoqueDetalhe`, alteracaoPedido).then(({ data }) => data);
}

export const saveSuggestion = (sugestao) => {
  return Fetch.put(`/sugestoes/salvarSugestao`, sugestao);
}

export const deleteSuggestion = (idFamiliaRotuladaCentro, sugestoes) => {
  return Fetch.post(`/sugestoes/excluirSugestao/${idFamiliaRotuladaCentro}`, { Sugestoes: sugestoes });
}

export const cancelBalance = (idItemVolume, idFamiliaRotuladaCentro, userId) => {
  return Fetch.post(`/pedidoVolume/cancelarSaldo/${idItemVolume}/${idFamiliaRotuladaCentro}/${userId}`);
}

export const newManualSuggestion = (idFamiliaRotuladaCentro) => {
  return Fetch.get(`/sugestoes/criarNovaSugestaoManual/${idFamiliaRotuladaCentro}`).then(({ data }) => data);
}

export const cancelOrder = (idFamiliaRotuladaCentro, cancelamento) => {
  return Fetch.put(`/pedido/cancelar/${idFamiliaRotuladaCentro}`, cancelamento);
}

export const applyProgrammingInStock = (idFamiliaRotuladaCentro) => {
  return Fetch.post(`/visaoEstoque/aplicarProgramacaoNoEstoque/${idFamiliaRotuladaCentro}`);
}

export const saveOverviewStockInformation = (visaoEstoqueInformacoes) => {
  Fetch.post(`/visaoEstoque/salvarVisaoEstoqueInformacoes/`, visaoEstoqueInformacoes).then(() => Promise.resolve());
}

export const createSuggestionByShelfLife = (idFamiliaRotuladaCentro, itemsShelfLifeCheckedData) => {
  Fetch.post(`shelfLife/criarSugestaoViaShelfLife/${idFamiliaRotuladaCentro}`, itemsShelfLifeCheckedData).then(() => Promise.resolve());
}

export const composicaoCargaCancel = (loadCompositionId, cancelamento) => {
  Fetch.post(`load-compositions/${loadCompositionId}:cancel`, cancelamento);
}

export const lastUpdateOfStock = (idFamiliaRotuladaCentro) => {
  return Fetch.get(`/visaoEstoque/obterUltimaAtualizacao/${idFamiliaRotuladaCentro}`).then(({ data }) => data);
}
