import PropTypes from "prop-types"
import React from 'react'
import intl from 'react-intl-universal'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import moment from 'moment'
import { formatNumber } from 'utils/format'
import InputNumber from 'components/input-number'
import { getValidationForInTransitStatus } from './utils'

const StyledTableCell = withStyles(() => ({
  head: {
    borderBottom: 'none',
    backgroundColor: 'var(--color-action-default)',
    fontWeight: 'var(--font-weight-semibold)',
    fontSize: 12,
    color: 'white',
    borderRight: '1px solid var(--color-contrast-white)',
    lineHeight: '10px'
  },
  body: {
    fontSize: 14,
    color: '#313131',
    fontWeight: 'var(--font-weight-regular)',
    borderRight: '1px solid #EDEDED',
    lineHeight: '14px',
    padding: '10px'
  },
  root: {
    fontFamily: 'ProximaNova',
    padding: '8px',
    position: 'relative ! important'
  }
}))(TableCell)

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    padding: '8px'
  },
}))(TableRow)

const useStyles = makeStyles({
  root: {
    width: '100%',
    boxShadow: 'none',
  },
  container: {
    maxHeight: 215
  },
  head: {
    fontWeight: 'var(--font-weight-semibold)',
    color: '#A2A2A2'
  },
  bold: {
    fontWeight: 'bold',
    textAlign: 'center'
  }
})

export const OrdersChangeDateTable = ({ data, handleNewQuantity }) => {
  const classes = useStyles()

  const transformToValidCell = (prop) =>
    prop ? moment(prop).format('L') : '-'

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader>
          <TableHead className={classes.head}>
            <TableRow>
              <StyledTableCell>{intl.get('commons.orderCode')}</StyledTableCell>
              <StyledTableCell>{intl.get('commons.source')}</StyledTableCell>
              <StyledTableCell>{intl.get('commons.destiny')}</StyledTableCell>
              <StyledTableCell>{intl.get('commons.material')}</StyledTableCell>
              <StyledTableCell>{intl.get('commons.collectDate')}</StyledTableCell>
              <StyledTableCell>{intl.get('commons.deliveryDate')}</StyledTableCell>
              <StyledTableCell>{intl.get('commons.quantity')}</StyledTableCell>
              <StyledTableCell>{intl.get('stocks.informAdvanceOrder.newCollectionDate')}</StyledTableCell>
              <StyledTableCell>{intl.get('stocks.informAdvanceOrder.newDeliveryDate')}</StyledTableCell>
              <StyledTableCell>{intl.get('orders.newQuantity')}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <StyledTableRow key={row.Id}>
                <StyledTableCell>{row.Id}</StyledTableCell>
                <StyledTableCell>{row.SupplierName}</StyledTableCell>
                <StyledTableCell>{`${row.BusinessUnitPlant} - ${row.BusinessUnitName}`}</StyledTableCell>
                <StyledTableCell>{row.MaterialName}</StyledTableCell>
                <StyledTableCell>{moment(row.OrderCollectDate).format('L')}</StyledTableCell>
                <StyledTableCell>{moment(row.OrderDeliveryDate).format('L')}</StyledTableCell>
                <StyledTableCell>{`${formatNumber(row.OrderQuantity)} ${row.MaterialUnitOfMeasure}`}</StyledTableCell>
                <StyledTableCell className={classes.bold} data-testid='new-collection-date'>{transformToValidCell(row.newCollectDate)}</StyledTableCell>
                <StyledTableCell className={classes.bold} data-testid='new-delivery-date'>{transformToValidCell(row.newDeliveryDate)}</StyledTableCell>
                <StyledTableCell>
                  <InputNumber
                    value={data.newOrderQuantity}
                    onChange={value => handleNewQuantity(value, index)}
                    className='input-new-quantity'
                    type='default'
                    allowDecimals
                    decimalsLimit={3}
                    data-testid='input-new-quantity'
                    disabled={getValidationForInTransitStatus(row.OrderStatus)}
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

OrdersChangeDateTable.propTypes = {
  data: PropTypes.shape({
    map: PropTypes.func,
    newOrderQuantity: PropTypes.any
  }).isRequired,
  handleNewQuantity: PropTypes.func.isRequired
}
