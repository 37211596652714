import React, { Component } from 'react'
import PropTypes from 'prop-types'

class MapToHTML extends Component {

  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div>
        {
          !!this.props.itens &&
          this.props.itens.map((value, key) => (
            <div key={key}>{value}</div>
          ))
        }
      </div>
    )
  }
}

MapToHTML.defaultProps = {
  itens: [],
}

MapToHTML.propTypes = {
  itens: PropTypes.array,
}

export default MapToHTML
