import styled from 'styled-components'

export const StyledMenuLateral = styled.div`
  position: absolute;
  visibility: ${({ vis }) => vis ? "visible" : "hidden"};
  transition: visibility ease ${({ transitionTime }) => transitionTime}ms;
  z-index: 5;

  .align-center-full {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .formMenu {
    position: absolute;
    min-width: 250px;
    height: calc(100vh - 100px);
    overflow: hidden;
    z-index: 2200;

    -webkit-transition: background ${(props) => props.transitionTime}ms ease-in-out;
    -moz-transition: background ${(props) => props.transitionTime}ms ease-in-out;
    -ms-transition: background ${(props) => props.transitionTime}ms ease-in-out;
    -o-transition: background ${(props) => props.transitionTime}ms ease-in-out;
    transition: background ${(props) => props.transitionTime}ms ease-in-out;

    &.transition-in {
      & .sideFilter {
        transform: translateX(1.5rem);
      }
    }

    .row {
      margin: 15px 0;
    }

    .container-fluid {
      margin-left: -1.5rem;
      margin-right: -1.5rem;
    }

    .no-margin {
      margin: 0;
    }

    .no-padding {
      padding: 0;
    }
  }

  .formSempreExpandido {
    height: calc(100vh - 100px);
    background: transparent;
    z-index: 9000;

    -webkit-transition: background ${(props) => props.transitionTime}ms ease-in-out;
    -moz-transition: background ${(props) => props.transitionTime}ms ease-in-out;
    -ms-transition: background ${(props) => props.transitionTime}ms ease-in-out;
    -o-transition: background ${(props) => props.transitionTime}ms ease-in-out;
    transition: background ${(props) => props.transitionTime}ms ease-in-out;

    &.hidden {
      z-index: -1;
    }

    &.transition-in {
      background: rgba(255,255,255,0.75);

      & .sideFilter {
        transform: translateX(1.5rem);
      }
    }

    .row {
      margin: 15px 0;
    }

    .container-fluid {
      margin-left: -1.5rem;
      margin-right: -1.5rem;
    }

    .no-margin {
      margin: 0;
    }

    .no-padding {
      padding: 0;
    }
  }

  .footerFilter {
    position: relative;

    .footerFilterContainer {
      width: 100%;
      position: absolute;
      bottom: 0px;
      background: var(--color-contrast-white);
    }
  }

  .title-menu {
    width: 70%;
    font-weight: var(--font-weight-semibold);
    font-size: 14px;
    text-align: left;
    color: var(--color-neutral-100);
    margin-left: 10px;
  }

  .itens-menu {
    padding: 0;
    margin: 0;
    overflow-x: auto;
    height: calc(100vh - 117px);

    li {
      display: flex;
      align-items: center;
      padding-left: 15px;
      cursor: pointer;
      min-height: 40px;
      max-height: 50px;
    }

    li:hover, .selected {
      background: var(--color-brand-300);
    }

    .menu-selecionado {
      background: var(--color-brand-300);
    }

    .sub-menu {
      min-height: 30px;
      max-height: 40px;
      padding-left: 30px;
      background: var(--color-brand-400);
    }

    .menu-item-indicator{
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition:transform 300ms ease-in-out;
      transform:rotate(180deg);
      transform-origin:center center;

      &.open {
        transform:rotate(0deg);
      }
    }

  }

  .icon {
    width: 10%;
    display: flex;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .sideFilter {
    position: relative;
    height: calc(100vh - 100px);
    top: 0px;
    bottom: 0px;
    z-index: 12;
    background: var(--color-neutral-500);
    perspective: 1000px;
    min-width: 250px;
    overflow: hidden;
    transition: min-width 300ms ease 150ms;

    transform: translateX(calc(-100%));
    padding: 0;
    width: 250px;

    -webkit-transition: transform ${(props) => props.transitionTime}ms ease-in-out;
    -moz-transition: transform ${(props) => props.transitionTime}ms ease-in-out;
    -ms-transition: transform ${(props) => props.transitionTime}ms ease-in-out;
    -o-transition: transform ${(props) => props.transitionTime}ms ease-in-out;
    transition: transform ${(props) => props.transitionTime}ms ease-in-out;

    
    & {
      -ms-overflow-style: none;// IE 10+
      overflow: -moz-scrollbars-none;// Firefox
    }
    
    &::-webkit-scrollbar {
      display: none;// Safari and Chrome
    }

    .closeFilter {
      cursor: pointer;
      position: absolute;
      left: -30px;
      top: 10px;
    }
  }
`

export const MenuOverlay = styled.div`
    width: 100vw;
    position: absolute;
    background: rgba(255,255,255,0.75);
    height: calc(100vh - 100px);
    z-index: 4;
`

