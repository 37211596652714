import PropTypes from "prop-types"
import React from 'react'
import intl from 'react-intl-universal'
import { Button, SelectBox } from 'components/uikit-adapter/index'
import Dialog from "material-ui/Dialog";
import { Row, Col } from 'react-flexbox-grid';
import StyledPedidoEmOver from 'pages/stocks-detail/components/modal-pedido-em-over/pedido-em-over.styled'

export const ModalSuggestionsInOver = ({ open, close, justificationForOver, justificationsList, setJustificationForOver, acceptSuggestionsInMass, showFeedback }) => {
  const validateDataAndAccept = () => {
    if (!justificationForOver) showFeedback(intl.get('suggestions.modalSuggestionInOver.justificationRequired'));
    else acceptSuggestionsInMass();
  }

  return (
    <Dialog
      title={intl.get('suggestions.modalSuggestionInOver.title')}
      open={open}
      contentStyle={{ maxWidth: '600px' }}
      actionsContainerClassName="container-action-buttons-pedido-over"
      autoScrollBodyContent={false}
    >
      <StyledPedidoEmOver>
        <div className='baseModal'>
          <div className='alertMessage'>
            {intl.get(`suggestions.modalSuggestionInOver.messageWarning`)}
          </div>
          <Row className='labelVision'>
            <Col xs={12}>
              <SelectBox
                autosize={true}
                required
                name='JustificativasPedidoEmOver'
                label={intl.get('suggestions.modalSuggestionInOver.labelSelectboxJustification')}
                placeholder={intl.get('suggestions.modalSuggestionInOver.labelSelectboxJustification')}
                value={justificationForOver}
                options={justificationsList}
                onChange={value => setJustificationForOver(value.Key)}
                valueKey='Key'
                labelKey='Value'
                searchable
                openOnFocus
              />
            </Col>
          </Row>
        </div>
        <Row className="container-action-buttons-pedido-over">
          <Col>
            <Button
              type="primary"
              htmlType="button"
              className="button"
              value={intl.get('geral.buttonsDefault.cancel')}
              onClick={close}
            />
          </Col>
          <Col>
            <Button
              type='danger'
              htmlType="button"
              className="button"
              value={intl.get('geral.buttonsDefault.save')}
              onClick={validateDataAndAccept}
            />
          </Col>
        </Row>
      </StyledPedidoEmOver>
    </Dialog>
  )
};

ModalSuggestionsInOver.propTypes = {
  acceptSuggestionsInMass: PropTypes.func.isRequired,
  close: PropTypes.any.isRequired,
  justificationForOver: PropTypes.any.isRequired,
  justificationsList: PropTypes.any.isRequired,
  open: PropTypes.any.isRequired,
  setJustificationForOver: PropTypes.func.isRequired,
  showFeedback: PropTypes.func.isRequired
}
