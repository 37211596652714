import { GeneralSettingParameter } from "models/generalSetting/generalSetting";

export const normalizeGeneralSettingsToSetLocalStorage = (_generalSettings) => {
  const generalSettingsFormatted = normalizeAllGeneralSettingsToBoolean(_generalSettings);
  normalizeNonBooleanSettings(generalSettingsFormatted, _generalSettings);

  return JSON.stringify(generalSettingsFormatted);
}

const normalizeAllGeneralSettingsToBoolean = (_generalSettings) => {
  return _generalSettings.map(x => ({
    parameterValue: x?.Parametro,
    value: parseStringToBoolean(x?.Valor),
    description: x?.Descricao
  }));
}

const normalizeNonBooleanSettings = (generalSettingsFormatted, _generalSettings) => {
  const quantityDecimalPlacesIndex = generalSettingsFormatted.findIndex(x => x.parameterValue === GeneralSettingParameter.QuantityDecimalPlaces);
  const quantityDecimalPlacesInt = parseInt(_generalSettings[quantityDecimalPlacesIndex]?.Valor);

  generalSettingsFormatted[quantityDecimalPlacesIndex] = {
    ...generalSettingsFormatted[quantityDecimalPlacesIndex],
    value: isNaN(quantityDecimalPlacesInt) ? 0 : quantityDecimalPlacesInt,
  }
}

const parseStringToBoolean = (value) => !(value === 'false' || value === '0');