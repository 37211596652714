import { isUseFilterDefault } from 'utils/user';

export const selectPropsDefault = {
  valueKey: 'Id',
  labelKey: 'Descricao',
  value: [],
  options: [],
  searchable: true,
  clearable: true,
  multi: true,
  hidden: false
}

export const builderRequestDto = (filters) => ({
  ...filters,
  _page: filters._page,
  _size: filters._size,
  SupplierIds: filters.SupplierIds.map(x => x.Id),
  MaterialsNumber: filters.MaterialsNumber.map(x => x.MaterialNumber),
  BusinessUnitIds: filters.BusinessUnitIds.map(x => x.Id),
  MaterialGroupIds: filters.MaterialGroupIds.map(x => x.Id),
  Status: filters.Status.map(x => x.Id),
  UseFilterDefault: isUseFilterDefault(),
  DeliveryDateMin: filters.DeliveryDateMin,
  DeliveryDateMax: filters.DeliveryDateMax,
  DeliveryTimeMin: filters.DeliveryTimeMin,
  DeliveryTimeMax: filters.DeliveryTimeMax,
});
