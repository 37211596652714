import styled from 'styled-components'

const StyledSuppliersListItemDesc = styled.div`
  .itens-menu {
    padding: 0;
    margin: 0;
    overflow-x: auto;

    li {
      display: flex;
      align-items: center;
      padding-left: 15px;
      min-height: 50px;
    }
  }
`

export default StyledSuppliersListItemDesc
