import Fetch from "utils/fetch";

export const getAllSuppliersService = ( ) => {
  return Fetch.get(`/fornecedor/obterTodosFornecedoresComPedidosVolume`).then(({ data }) => data)
}

export const getAllBusinessUnitsService = ( ) => {
  return Fetch.get(`/unidadeNegocio/obterTodasAsUnidadesComPedidosVolume`).then(({ data }) => data)
}

export const getAllMaterialsService = ( ) => {
  return Fetch.get(`/materials:all-with-volume-orders`).then(({ data }) => data)
}

export const getAllStatusService = ( ) => {
  return Fetch.get(`/pedidoVolume/obterTodosOsStatus`).then(({ data }) => data)
}

export const getAllStepsService = ( ) => {
  return Fetch.get(`/pedidoVolume/obterTodasAsEtapas`).then(({ data }) => data)
}

export const getAllFamiliesService = ( ) => {
  return Fetch.get(`/families:all`).then(({ data }) => data)
}

export const getAllLabeledFamiliesService = ( ) => {
  return Fetch.get(`/labeled-families:for-filters`).then(({ data }) => data)
}