import React, { Component } from 'react'
import { Tabs, Pane } from '@hbsis.uikit/react'

import Budget from './budget'
import Family from './family'
import LabeledFamily from './labeled-family'
import intl from 'react-intl-universal'

class BudgetFamily extends Component {
  constructor() {
    super()
  }

  render() {
    return (
      <Tabs selected={2}>
        <Pane label={intl.get('master-data.walletsAndFamilys.wallets.header.title')}>
          <Budget />
        </Pane>
        <Pane label={intl.get('master-data.walletsAndFamilys.familys.header.title')}>
          <Family />
        </Pane>
        <Pane label={intl.get('master-data.walletsAndFamilys.labeledFamily.header.title')}>
          <LabeledFamily />
        </Pane>
      </Tabs>
    )
  }
}

export default BudgetFamily
