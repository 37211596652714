import React, { useState } from 'react'
import SuggestionsListHeader from "components/suggestions-list/suggestions-list-header";
import { Row, Col } from "react-flexbox-grid";
import intl from 'react-intl-universal'

import * as S from './styled'
import { formatNumber } from 'utils/format';
import { DATA_FORMAT_TYPES, getDataFormat } from 'utils/format-date';
import PropTypes from 'prop-types';

const ProductionPlan = ({ data, show }) => {
  const [isItemExpanded, setIsItemExpanded] = useState(false);
  const _productionPlanQuantityTotal = data.reduce((total, plan) => plan.ProductionQuantity + total, 0)

  const handleChangeExpandedItem = () => setIsItemExpanded(!isItemExpanded);

  return (
    <React.Fragment>
      {show && data.length > 0 &&
        <S.Wrapper itemExpanded={isItemExpanded}>
          <SuggestionsListHeader
            isItem
            hasIcon
            isOrder={true}
            itemsExpanded={isItemExpanded}
            checked={false}
            expandAllItems={handleChangeExpandedItem}
            hideMargin
          >
            <S.TitlesWrapper>
              <span className='production-plan-title'>{intl.get("stocks.productionPlan")}</span>
              <span className='production-plan-content'>{formatNumber(_productionPlanQuantityTotal)}</span>
            </S.TitlesWrapper>
          </SuggestionsListHeader>

          <S.ContentWrapper itemExpanded={isItemExpanded}>
            <Row className="content-row">
              {data.map((plan) => (
                <React.Fragment key={`${plan.SupplierDescription}-${plan.ProductionDate}`}>
                  <Col className='content-col' xs={4}>
                    <S.InfosWrapper>
                      <span className='supplier-title' title={plan.SupplierDescription}>{plan.SupplierDescription}</span>
                    </S.InfosWrapper>
                  </Col>
                  <Col className='content-col' xs={4}>
                    <S.InfosWrapper>
                      <span className='production-plan-title'>{intl.get('supplierProductionPlan.table.producedQuantity')}</span>
                      <span className='production-plan-content'>{formatNumber(plan.ProductionQuantity)}</span>
                    </S.InfosWrapper>
                  </Col>
                  <Col className='content-col' xs={4}>
                    <S.InfosWrapper>
                      <span className='production-plan-title'>{intl.get('supplierProductionPlan.table.productionDate')}</span>
                      <span className='production-plan-content'>{getDataFormat(plan.ProductionDate, DATA_FORMAT_TYPES.WITH_YEAR)}</span>
                    </S.InfosWrapper>
                  </Col>
                </React.Fragment>
              ))}
            </Row>
          </S.ContentWrapper>
        </S.Wrapper>
      }
    </React.Fragment>
  )
}

ProductionPlan.propTypes = { 
  data: PropTypes.arrayOf(PropTypes.shape({
    SupplierDescription: PropTypes.string,
    ProductionQuantity: PropTypes.number,
    ProductionDate: PropTypes.string
  })), 
  show: PropTypes.bool
}

export default ProductionPlan
