import moment from "moment"
import { ISO_DATE_FORMAT } from "utils/format-date"

export const getDateCacheFilter = () => {
  const infos = JSON.parse(localStorage.getItem("loadCompositionFilter"))
  return infos ? { de: infos.dataInicial, ate: infos.dataFinal } :
    formatDateFromTo();
}

export const formatDateFromTo = (date, daysToAdd = 7) =>
  ({ de: moment(getValidDate(date)).format(ISO_DATE_FORMAT), ate: moment(getValidDate(date)).add(daysToAdd, 'days').format(ISO_DATE_FORMAT) })

export const getValidDate = (date) =>
  (!date || date === '0000-00-00') ? undefined : date;
