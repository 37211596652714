import styled from 'styled-components'

export const WrapperAll = styled.div`
  display: flex;
  flex-direction: column;
`
export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 22px 1fr;
  padding: 6px;
  border-radius: 3px;
  width: 156px;
  min-height: 63px;
  margin: 4px 11px;
  align-items: center;
  justify-items: baseline;
  background: ${p => p.background};
  border: 1px solid ${p => p.color};
  cursor: pointer;
  span{
    font-size: 11px;
    text-transform: capitalize;
  }
`
export const Hora = styled.div`
  border-right: solid 1px #D1D4D7;
  span{
    font-weight: var(--font-weight-semibold);
    margin-right: 4px;
    font-size: 12px;
    line-height: 12px;
    display: flex;
    align-items: center;
    text-align: center;
    color: ${p => p.color ? p.color : 'var(--color-contrast-black)'} ;
  }
`

export const Content = styled.div`
  margin-left: 4px;
  grid-column: span 2;
  span{
    font-size: 11px;
    line-height: 12px;
    display: flex;
    align-items: center;
    color: ${p => p.color};
    text-transform: capitalize;
  }
`
