import intl from 'react-intl-universal'

export const getMessageReprojectModal = (period) => `
  ${intl.get('bottles.modal.reprojectPlanning.textConfirm')}
  ${period}${intl.get('bottles.modal.reprojectPlanning.textConfirm2')}
`

export const getMessageReplyModal = (period, nextPeriod) => `
${intl.get('bottles.modal.replicatePlanning.textConfirm')}
${period}${intl.get('bottles.modal.replicatePlanning.textConfirm2')}
${nextPeriod}${intl.get('bottles.modal.replicatePlanning.textConfirm3')}
`