import Fetch from 'utils/fetch';
import { generateQueryParams } from 'utils/custom-functions';

export const searchTasks = (model) => {
  return Fetch.get(`/tasks:cards-paginated?${generateQueryParams(model)}`).then(({ data }) => data)
}

export const searchCountTasks = (defaultFilter) => {
  return Fetch.get(`/tasks:cards-count?UseFilterDefault=${defaultFilter}`).then(({ data }) => data)
}

export const searchUsuariosMasterDataPCISolutions = (useFilterDefault, idUsuario) => {
  return Fetch.get(`/usuario/obterUsuariosMasterDataSolutionsPCISolutions/${useFilterDefault}/${idUsuario}`)
    .then(({ data }) => data)
}

export const searchTiposTarefas = () => {
  return Fetch.get(`/tasks/types-status`)
    .then(({ data }) => data)
}
