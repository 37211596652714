
import React from 'react'
import PropTypes from 'prop-types'
import intl from 'react-intl-universal'
import './destino-alterado-pedido.css'

const DestinoAlteradoPedido = ({
  unidadeNegocioAnterior,
  unidadeNegocio,
  codigoSap
}) => (
  <div>    
    <div className="negotiation-read-offer">
      <div className="negotiation-read-column">
        <span>{intl.get('stocks.timeline.previousDestination')}</span>
        <span>{unidadeNegocioAnterior}</span>
      </div>
      <div className="negotiation-read-column">
        <span />
        <span />
      </div>
    </div>   
    <div className="negotiation-read-offer">
      <div className="negotiation-read-column">
        <span>{intl.get('stocks.timeline.currentDestination')}</span>
        <span>{unidadeNegocio}</span>
      </div>
      <div className="negotiation-read-column">
        <span />
        <span />
      </div>
    </div>   
    {codigoSap &&
    <div className="negotiation-read-offer">
      <div className="negotiation-read-column">
        <span>{intl.get('stocks.timeline.sapCode')}</span>
        <span>{codigoSap}</span>
      </div>
      <div className="negotiation-read-column">
        <span />
        <span />
      </div>
    </div>
    }
  </div>
  );

DestinoAlteradoPedido.propTypes = {
  unidadeNegocioAnterior: PropTypes.string.isRequired,
  unidadeNegocio: PropTypes.string.isRequired,  
  codigoSap: PropTypes.string.isRequired
}

export default DestinoAlteradoPedido