import styled from 'styled-components'
import ScrollContainer from 'react-indiana-drag-scroll'

export const Wrapper =  styled(ScrollContainer)`
  display: flex;
  height: calc(100vh - 213px);
  overflow-x: scroll;
  overflow-y: auto;
  margin: 23px 0px 0px 0px;

  ::-webkit-scrollbar {
    display: none;
  }
`

export const WrapperContent =  styled.div`
  display: flex;
  background: var(--color-contrast-white);
  width: max-content;
  height: max-content;
`

export const WrapperColumn = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px dashed var(--color-neutral-300);
  background: var(--color-contrast-white);
`

export const Row = styled.div`
  display: flex;
  flex-direction: column;
`

export const TableHeading = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  background-color: var(--color-brand-400);
  color: white;
  font-weight: bold;
  font-size: 14px;
  box-shadow:5px 0px 16px -11px rgb(0 0 0 / 65%);
  padding: 9px;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  text-align: center;
  z-index: 1;
  span{
    cursor: auto;
  }
`
