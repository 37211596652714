import PropTypes from "prop-types"
import { Component } from 'react';
import Dialog from 'material-ui/Dialog';
import Loading from 'components/center-loading';
import { Button, Input } from 'components/uikit-adapter/index';
import moment from 'moment';
import { Row, Col } from "react-flexbox-grid";
import { Textarea } from '@hbsis.uikit/react';
import {
  searchReleaseVersionById,
  saveReleaseVersion
} from './release-version-form.service';
import ReleaseVersionFormStyled from './release-version-form.styled';
import intl from 'react-intl-universal';
import { formatErrorMessage } from 'utils/handle-error';
import DateInput from '../../../../../components/date-input';
import { getTextAreaStyle } from 'components/suggestions-list/suggestions-list-item/suggestions-list-item.styled';

class ReleaseVersionForm extends Component {
  static propTypes = {
    editMode: PropTypes.any.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleFeedback: PropTypes.func.isRequired,
    handleRefresh: PropTypes.func.isRequired,
    idEdit: PropTypes.any.isRequired,
    open: PropTypes.any.isRequired
  }

  constructor() {
    super()

    this.state = {
      ReleaseVersion: {},
      isSaving: false
    }
  }

  componentDidMount() {
    const editMode = this.props.editMode
    if (editMode) {
      this.searchReleaseVersionById()
    }
  }

  startSaving = () => {
    this.setState({
      isSaving: true
    })
  }

  stopSaving = () => {
    this.setState({
      isSaving: false
    })
  }

  proccessErro = (exception) => {
    try {
      const erro = formatErrorMessage(exception)
      this.props.handleFeedback(erro)
    } catch (e) {
      this.props.handleFeedback(intl.get('feedbacks.offServer'))
    }
  }

  searchReleaseVersionById = async () => {
    try {
      const idReleaseVersion = this.props.idEdit
      const data = await searchReleaseVersionById(idReleaseVersion)
      this.proccessData(data)
    } catch (error) {
      this.proccessErro(error)
    }
  }

  proccessData = (data) => {
    this.setState({
      ReleaseVersion: {
        Id: data.Id,
        NumeroVersao: data.NumeroVersao,
        DescricaoResumida: data.DescricaoResumida,
        DescricaoCompleta: data.DescricaoCompleta,
        DataLiberacao: data.DataLiberacao ? moment(data.DataLiberacao).format('YYYY-MM-DD') : null,
        DataHoraInicioVigenciaExibicao: data.DataHoraInicioVigenciaExibicao,
        DataHoraFinalVigenciaExibicao: data.DataHoraFinalVigenciaExibicao,
        DataInicioVigenciaExibicao: data.DataHoraInicioVigenciaExibicao ? moment(data.DataHoraInicioVigenciaExibicao).format('YYYY-MM-DD') : null,
        HoraInicioVigenciaExibicao: data.DataHoraInicioVigenciaExibicao ? moment(data.DataHoraInicioVigenciaExibicao).format('HH:mm') : null,
        DataFinalVigenciaExibicao: data.DataHoraFinalVigenciaExibicao ? moment(data.DataHoraFinalVigenciaExibicao).format('YYYY-MM-DD') : null,
        HoraFinalVigenciaExibicao: data.DataHoraFinalVigenciaExibicao ? moment(data.DataHoraFinalVigenciaExibicao).format('HH:mm') : null,
      }
    })
  }

  changeValue = (event) => {
    const prop = event.target.name
    let valueInitial = event.target.value

    const value = valueInitial
    this.setState(prevState => ({
      ReleaseVersion: {
        ...prevState.ReleaseVersion,
        [prop]: value
      }
    }))
  }


  updateSelectValue = (prop, value) => {
    this.setState(prevState => ({
      ReleaseVersion: {
        ...prevState.ReleaseVersion,
        [prop]: value
      }
    }))
  }

  save = async () => {
    try {
      const editMode = this.props.editMode
      let model = this.state.ReleaseVersion

      if (!!editMode) {
        model.Id = this.props.idEdit
      }

      if (this.validarReleaseVersion(this.state.ReleaseVersion)) {
        this.startSaving()

        model.DataHoraInicioVigenciaExibicao = moment(`${this.state.ReleaseVersion.DataInicioVigenciaExibicao} ${this.state.ReleaseVersion.HoraInicioVigenciaExibicao}`, 'YYYYMMDD_HHmm').format();
        model.DataHoraFinalVigenciaExibicao = moment(`${this.state.ReleaseVersion.DataFinalVigenciaExibicao} ${this.state.ReleaseVersion.HoraFinalVigenciaExibicao}`, 'YYYYMMDD_HHmm').format();

        await saveReleaseVersion(model);

        this.props.handleRefresh()
        this.props.handleFeedback(`${intl.get('feedbacks.register')} ${!editMode ? intl.get('feedbacks.saved') : intl.get('feedbacks.updated')} ${intl.get('feedbacks.withSuccess')}`)
      }
    }
    catch (error) {
      this.proccessErro(error)
    }
    finally {
      this.stopSaving()
    }
  }

  validarReleaseVersion(releaseVersion) {
    if (!releaseVersion.NumeroVersao) {
      this.props.handleFeedback(intl.get('feedbacks.messageReleaseVersion1'))
      return false
    } else if (!releaseVersion.DescricaoResumida) {
      this.props.handleFeedback(intl.get('feedbacks.messageReleaseVersion2'))
      return false
    } else if (!releaseVersion.DescricaoCompleta) {
      this.props.handleFeedback(intl.get('feedbacks.messageReleaseVersion3'))
      return false
    } else if (!releaseVersion.DataLiberacao) {
      this.props.handleFeedback(intl.get('feedbacks.messageReleaseVersion4'))
      return false
    } else if (!releaseVersion.DataInicioVigenciaExibicao) {
      this.props.handleFeedback(intl.get('feedbacks.messageReleaseVersion5'))
      return false
    } else if (!releaseVersion.HoraInicioVigenciaExibicao) {
      this.props.handleFeedback(intl.get('feedbacks.messageReleaseVersion6'))
      return false
    } else if (!releaseVersion.DataFinalVigenciaExibicao) {
      this.props.handleFeedback(intl.get('feedbacks.messageReleaseVersion7'))
      return false
    } else if (!releaseVersion.HoraFinalVigenciaExibicao) {
      this.props.handleFeedback(intl.get('feedbacks.messageReleaseVersion8'))
      return false
    } else if (releaseVersion.DataHoraInicioVigenciaExibicao > releaseVersion.DataHoraFinalVigenciaExibicao) {
      this.props.handleFeedback(intl.get('feedbacks.messageReleaseVersion9'))
      return false
    } else if (releaseVersion.DataHoraFinalVigenciaExibicao < releaseVersion.DataHoraInicioVigenciaExibicao) {
      this.props.handleFeedback(intl.get('feedbacks.messageReleaseVersion10'))
      return false
    }

    return true
  }

  render() {
    let { ReleaseVersion, isSaving } = this.state
    let { open, editMode } = this.props

    return (
      <div>
        <Loading isLoading={isSaving} />
        <Dialog
          title={editMode ? intl.get('commons.editVersion') : intl.get('commons.newVersion')}
          contentStyle={{ width: '600px', height: '700px' }}
          bodyClassName='modalBodyClass'
          open={open}
          autoScrollBodyContent
        >
          <ReleaseVersionFormStyled>
              <Row>
                <Col xs={12}>
                  <Input
                    required
                    name='NumeroVersao'
                    onChange={this.changeValue}
                    value={ReleaseVersion.NumeroVersao}
                    label={intl.get('commons.versionNumber')}
                    className="input-numero-versao input"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Textarea
                    style={getTextAreaStyle()}
                    required
                    name="DescricaoResumida"
                    label={intl.get('commons.resumeDescription')}
                    value={ReleaseVersion.DescricaoResumida}
                    onChange={this.changeValue}
                    rows={4}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Textarea
                    style={getTextAreaStyle()}
                    required
                    name="DescricaoCompleta"
                    label={intl.get('commons.completeDescription')}
                    value={ReleaseVersion.DescricaoCompleta}
                    onChange={this.changeValue}
                    rows={7}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <DateInput
                    label={intl.get('commons.dateLib')}
                    value={ReleaseVersion.DataLiberacao}
                    onChange={(date) => this.updateSelectValue('DataLiberacao', date)}
                    maxDate="9999-12-31"
                    className="input-data-liberacao"
                  />
                </Col>
              </Row>

              <label className="label-style">{intl.get('commons.dateRelease')}</label>

              <div className="container">
                <Row>
                  <Col xs={4}>
                    <DateInput
                      label={intl.get('commons.initDate')}
                      value={ReleaseVersion.DataInicioVigenciaExibicao}
                      onChange={(date) => this.updateSelectValue('DataInicioVigenciaExibicao', date)}
                      maxDate="9999-12-31"
                      className="input-data-inicio-vigencia-exibicao"
                    />
                  </Col>
                  <Col xs={2}>
                    <Input
                      name='HoraInicioVigenciaExibicao'
                      label={intl.get('commons.initHour')}
                      value={ReleaseVersion.HoraInicioVigenciaExibicao}
                      onChange={(event) => this.updateSelectValue(event.target.name, event.target.value)}
                      htmlType="time"
                      className="input-hora-inicio-vigencia-exibicao input"
                    />
                  </Col>
                  <Col xs={4}>
                    <DateInput
                      label={intl.get('commons.endDate')}
                      value={ReleaseVersion.DataFinalVigenciaExibicao}
                      onChange={(date) => this.updateSelectValue('DataFinalVigenciaExibicao', date)}
                      maxDate="9999-12-31"
                      className="input-data-final-vigencia-exibicao"
                    />
                  </Col>
                  <Col xs={2}>
                    <Input
                      name='HoraFinalVigenciaExibicao'
                      label={intl.get('commons.endHour')}
                      value={ReleaseVersion.HoraFinalVigenciaExibicao}
                      onChange={(event) => this.updateSelectValue(event.target.name, event.target.value)}
                      htmlType="time"
                      className="input-hora-final-vigencia-exibicao input"
                    />
                  </Col>
                </Row>
              </div>

              <div className='footer-dialog-buttons-release-version'>
                <Button
                  value={intl.get('geral.buttonsDefault.cancel')}
                  type="default"
                  onClick={() => { this.props.handleClose() }}
                  className="button btn-cancel"
                />
                <Button
                  value={intl.get('geral.buttonsDefault.save')}
                  type="primary"
                  className="button btn-save"
                  onClick={this.save}
                />
              </div>
          </ReleaseVersionFormStyled>
        </Dialog>
      </div>
    )
  }
}
export default ReleaseVersionForm
