import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import './style.css';
import editIcon from 'images/edit.svg';
import deleteIcon from 'images/bin.svg'

import PaginationFooter from 'components/pagination/components/pagination-footer'
import Session from 'utils/user-storage'

import intl from 'react-intl-universal';
import { IconRefresh } from 'components/icons/icn-index.icon';
import { RoundButton } from 'components/round-button';
import PropTypes from 'prop-types';
import { ActionsWrapper } from './styled';

const useStyles = makeStyles({
  root: {
    display: 'block'
  }
});

export default function ReleaseVersionList({ data, countData, refreshSearch, handleEdit, handleDelete, qtRegsPerPage }) {
  const classes = useStyles();
  const { TipoUsuario } = Session.get();

  return (
    <div>
      {
        data.map((v, i) => (
          <div className="margin" key={v.Id}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`row-${i}`}
                id={`header-${i}`}
                className="expansion-panel"
              >
                <div className='changelog'>
                  <div className="changelog-first-session">
                    <div className="refresh-icon">
                      <IconRefresh width={32} height={32} />
                    </div>
                    <div className='version'>
                      {intl.get('commons.version')}: {v.NumeroVersao}
                    </div>
                    <div className='separator'></div>
                    <div className="deployed-in">
                      {intl.get('commons.deployed-in')}: {moment(v.DataLiberacao).format("L")}
                    </div>
                  </div>
                  {
                    TipoUsuario === 3 &&
                    <ActionsWrapper className="changelog-second-session" onClick={(event) => event.stopPropagation()} onFocus={(event) => event.stopPropagation()}>
                      <RoundButton
                        className="icon"
                        id="delete"
                        aria-label="Delete"
                        title={intl.get('geral.buttonsDefault.delete')}
                        onClick={() => handleDelete(v.Id)}
                        style={{ width: 'auto' }}
                        iconHeight={'auto'}
                      >
                        <img className="btn-excluir" src={deleteIcon} alt='delete-icon' style={{ width: '28px', height: '28px' }} />
                      </RoundButton>
                      <RoundButton
                        className="icon"
                        id="edit"
                        aria-label="Edit"
                        title={intl.get('geral.buttonsDefault.edit')}
                        onClick={() => handleEdit(i)}
                        style={{ width: 'auto', marginLeft: '2px' }}
                        iconHeight={'auto'}
                      >
                        <img className="btn-edit" src={editIcon} alt='edit-icon' style={{ width: '28px', height: '28px' }} />
                      </RoundButton>
                    </ActionsWrapper>
                  }
                </div>
              </AccordionSummary>
              <AccordionDetails classes={{ root: classes.root }}>
                <div className="details">
                  <div className="details-first-session">
                    <span className="details-text">{intl.get('commons.releasedOn')}: </span>
                    {moment(v.DataLiberacao).format("L")}
                  </div>
                  <div className="details-second-session">
                    <div>
                      <span className="details-text">{intl.get('commons.startExhibition')}: </span>
                      {moment(v.DataHoraInicioVigenciaExibicao).format("L LTS")}
                    </div>
                    <div>
                      <span className="details-text">{intl.get('commons.endExhibition')}: </span>
                      {moment(v.DataHoraFinalVigenciaExibicao).format("L LTS")}
                    </div>
                  </div>
                </div>
                <span className="line"></span>
                <div className="details-summary">
                <h4 className="details-text">{intl.get("commons.resumeDescription")}</h4>
                  <p className="display-linebreak">
                    {v.DescricaoResumida}
                  </p>
                  <span className="line"></span>
                  <h4 className="details-text">{intl.get("commons.completeDescription")}</h4>
                  <p className="display-linebreak">
                    {v.DescricaoCompleta}
                  </p>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        ))
      }
      <div className="pagination">
        <PaginationFooter
          countPerPage={qtRegsPerPage}
          countData={countData}
          refreshSearch={refreshSearch}
        />
      </div>
    </div>
  )
}

ReleaseVersionList.propTypes = {
  data: PropTypes.array.isRequired, 
  countData: PropTypes.number.isRequired, 
  refreshSearch: PropTypes.func.isRequired, 
  handleEdit: PropTypes.func.isRequired, 
  handleDelete: PropTypes.func.isRequired, 
  qtRegsPerPage: PropTypes.number.isRequired
}