import React from 'react'
import PropTypes from 'prop-types'
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
  TableFooter
} from 'components/uikit-adapter/index'
import PaginationFooter from 'components/pagination/components/pagination-footer'
import intl from 'react-intl-universal'
import { usesTmsIntegration } from 'utils/validations-general-settings'

const BusinessUnitiesList = ({ data, countData, refreshSearch, handleEdit, qtRegsPerPage }) => (
  <Table
    fixedHeader
    fixedFooter
    multiSelectable={false}
    selectable={false}
    wrapperStyle={{ height: '100%' }}
    bodyStyle={{ height: 'calc(100% - 110px)', borderTop: '1px solid rgb(224, 224, 224)', backgroundColor: 'var(--color-contrast-white)' }}
    onCellClick={handleEdit}
  >
    <TableHeader
      displaySelectAll={false}
      adjustForCheckbox={false}
      style={{ borderBottom: 'none' }}>
      <TableRow style={{ borderBottom: 'none' }}>
        <TableHeaderColumn className='table-header__column'>{intl.get('master-data.general-configuration.company')}</TableHeaderColumn>
        <TableHeaderColumn className='table-header__column'>{intl.get('master-data.general-configuration.center')}</TableHeaderColumn>
        <TableHeaderColumn>{intl.get('master-data.general-configuration.name')}</TableHeaderColumn>
        <TableHeaderColumn>{intl.get('master-data.general-configuration.supplierSAP')}</TableHeaderColumn>
        {usesTmsIntegration() && <TableHeaderColumn>{intl.get('master-data.general-configuration.codeTMS')}</TableHeaderColumn>}
        <TableHeaderColumn>{intl.get('master-data.general-configuration.type')}</TableHeaderColumn>
        <TableHeaderColumn>Email</TableHeaderColumn>
        <TableHeaderColumn>Status</TableHeaderColumn>
      </TableRow>
    </TableHeader>
    <TableBody
      displayRowCheckbox={false}
      showRowHover
    >
      {data.map((v, i) => (
        <TableRow key={i} style={{ cursor: 'pointer' }}>
          <TableRowColumn>
            {v.Empresa}
          </TableRowColumn>
          <TableRowColumn>
            {v.Centro}
          </TableRowColumn>
          <TableRowColumn>
            {v.Nome}
          </TableRowColumn>
          <TableRowColumn>
            {v.FornecedorSap}
          </TableRowColumn>
          {
            usesTmsIntegration() &&
            <TableRowColumn>
              {v.CodigoTms}
            </TableRowColumn>
          }
          <TableRowColumn>
            {v.TipoUnidade}
          </TableRowColumn>
          <TableRowColumn>
            {v.Email ? v.Email.join('; ') : ''}
          </TableRowColumn>
          <TableRowColumn>
            {v.Status}
          </TableRowColumn>
        </TableRow>
      ))}
    </TableBody>
    <TableFooter>
      <PaginationFooter
        countPerPage={qtRegsPerPage}
        countData={countData}
        refreshSearch={refreshSearch}
      />
    </TableFooter>
  </Table>
)

BusinessUnitiesList.propTypes = {
  countData: PropTypes.number.isRequired,
  data: PropTypes.shape({
    map: PropTypes.func
  }).isRequired,
  handleEdit: PropTypes.func.isRequired,
  qtRegsPerPage: PropTypes.number.isRequired,
  refreshSearch: PropTypes.func.isRequired
}

export default BusinessUnitiesList
