import { StatusPedido } from "../../../models/pedidos/statusPedido"
import { StatusSugestao } from "../../../models/sugestao/tiposSugestoes"

export const builderLoadsReleasedDto = (params, filter) => ({
  ...filter,
  IdFornecedor: filter.idFornecedor,
  DestinoId: params.destinoId,
  Status: [StatusPedido.AguardandoComposicaoCarga],
  NumeroMaterial: filter.material,
  IdCarteira: filter.carteira,
  IdComposicao: params.composicaoId
})

export const builderLoadsSuggestionsDto = (params, filter) => ({
  ...filter,
  IdFornecedor: filter.idFornecedor,
  DestinoId: params.destinoId,
  DataEntrega: params.dia,
  Status: [
    StatusSugestao.Gerada,
    StatusSugestao.SugestaoManual,
    StatusSugestao.SugestaoAlterada
  ],
  NumeroMaterial: filter.material,
  IdCarteira: filter.carteira,
})

export const builderLoadsAwaintingLoadDto = (params, filter) => ({
  ...filter,
  IdFornecedor: filter.idFornecedor,
  DestinoId: params.destinoId,
  Status: [StatusSugestao.SugestaoAguardandoComposicaoCarga],
  NumeroMaterial: filter.material,
  IdCarteira: filter.carteira,
})

export const builderLoadOrderByIdDto = (orderId, params) => ({
  pagination: {
    page: 1,
    take: 50
  },
  DestinoId: params.destinoId,
  Status: [],
  IdPedido: orderId
})

export const builderLoadsSuggestionsByIdDto = (suggestionId, params) => ({
  pagination: {
    page: 1,
    take: 50
  },
  DestinoId: params.destinoId,
  Status: Object.values(StatusSugestao),
  SuggestionId: suggestionId,
  InitialDeliveryDate: '0001-01-01'
})

export const builderLoadOrdersComposition = (params, filter) => ({
  ...filter,
  DestinoId: params.destinoId,
  Status: [],
  IdComposicao: params.composicaoId,
  pagination: {
    page: 1,
    take: 50
  }
})
