import styled from "styled-components";

const StyledPedidoEmOver = styled.div`
  .baseModal {
    height: 125px;
  }

  .container-action-buttons-pedido-over {
      display: flex;
      float: right;
    }

  .labelVision {
    position: fixed;
    z-index: 1502;
    width: 100%;
    padding-right: 26px;
  }

  .alertMessage {
    padding-bottom: 10px;
  }
`;

export default StyledPedidoEmOver;
