import styled, { css } from 'styled-components';

const defaultDivStyle = css`
  text-align: center;
`;

const defaultHeaderStyle = css`
  font-weight: var(--font-weight-semibold);
  text-transform: uppercase;
  color: rgb(155, 162, 172);
  font-size: 0.7rem;
  height: 31px;
  background-color: rgb(250, 250, 252);
  border-top: 1px solid rgb(197, 208, 225);
  border-bottom: 1px solid rgb(197, 208, 225);
  border-right: 1px solid rgb(197, 208, 225);
`

export const TableHeaderStyled = styled.tr`
  
  ${defaultDivStyle}
  ${defaultHeaderStyle}

  th {
    position: sticky;
    top: 0;
    background-color: rgb(250, 250, 252)
  }

  th {
    border: 1px dashed;
    box-shadow: 
      inset 0px 8px 8px -10px rgb(197, 208, 225),
      inset 0px -8px 8px -10px rgb(197, 208, 225); 
  }
`;

export const TableContentStyled = styled.tbody`

  ${defaultDivStyle}
  font-size: 0.8rem;
  background-color: rgb(255, 255, 255);
  padding-left: 4px;
  margin: 0px;
  transition: background 500ms linear 0s;
  
  .source-column {
    border: 2px solid var(--color-neutral-300);
    border-right: 4px solid var(--color-action-hover);
  }

  height: 56px;
  td {
    padding: 5px;
    border: 1px dashed var(--color-neutral-300);
    max-width: 100px;
  }

  tr:last-child {
    td {
      border-bottom: none;
    };
  }

  .source-column:last-child {
    border-bottom: none;
  }
 
`;

export const TableStyled = styled.table`  
  border-spacing: 0;
  width: 100%;
  border-collapse: collapse;

  th:first-child {
    border-right: 4px solid var(--color-action-hover);
    border-left: 2px solid var(--color-neutral-300);
  }

  .card {
    height: 100%;
    padding: 5px;
    background-color: none;
    border-top: none;

    p {
      color: var(--color-contrast-brand);
      font-weight: var(--font-weight-semibold);
      font-size: 12px;
    }
  }

  .red {
    background-color: var(--color-feedback-danger-100);
    border-top: 2px solid #FC5457;
  }

  .green {
    background-color: #E7F7E7;
    border-top: 2px solid #5A7805;
  }
}
`;

export const LabeledFamily = styled.td`
  min-width: 250px;

`

export const LabeledFamilyContainer = styled.div`
  display: flex;
  align-items:center;
  justify-content: flex-start;

  div:first-child {
    margin-right: 5px;
    margin-left: 15px;
  }
`

export const LabeledFamilyIcon = styled.div`
  background-color: var(--color-neutral-200);
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
`
export const BlankLink = styled.div`
  color: var(--color-contrast-white);
  padding: 32px;
`