export const Labels = [
  {
    name: 'Tipo',
    key: 'Tipo',
    customStyle: {
      minWidth: '50px',
      maxWidth: '50px',
      textAlign: 'left',
      justifyContent: 'left',
    },
    customLabelStyle: {
      minWidth: '50px',
      maxWidth: '50px',
      textAlign: 'left',
      justifyContent: 'left',
    }
  },
  {
    name: 'Cód.Portal',
    key: 'Id',
    customStyle: {
      minWidth: '50px',
      maxWidth: '50px',
      textAlign: 'center',
      justifyContent: 'center'
    },
    customLabelStyle: {
      minWidth: '50px',
      maxWidth: '50px',
      textAlign: 'center',
      justifyContent: 'center'
    }
  },
  {
    name: 'Cód.SAP',
    key: 'CodigoSAP',
    customStyle: {
      minWidth: '100px',
      maxWidth: '100px',
      textAlign: 'center',
      justifyContent: 'center'
    },
    customLabelStyle: {
      minWidth: '100px',
      maxWidth: '100px',
      textAlign: 'center',
      justifyContent: 'center'
    }
  },
  {
    name: 'Origem',
    key: 'Fornecedor',
    customStyle: {
      minWidth: '150px',
      width: '100%',
      textAlign: 'left',
      justifyContent: 'left',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap'
    },
    customLabelStyle: {
      minWidth: '90px',
      width: '100%',
      textAlign: 'left',
      justifyContent: 'left'
    }
  },
  {
    name: 'Destino',
    key: 'Destino',
    customStyle: {
      minWidth: '150px',
      maxWidth: '150px',
      textAlign: 'left',
      justifyContent: 'left'
    },
    customLabelStyle: {
      minWidth: '150px',
      maxWidth: '150px',
      textAlign: 'left',
      justifyContent: 'left'
    }
  },
  {
    name: 'Coleta',
    key: 'DataColeta',
    customStyle: {
      minWidth: '85px',
      maxWidth: '85px',
      textAlign: 'right',
      justifyContent: 'right'
    },
    customLabelStyle: {
      minWidth: '85px',
      maxWidth: '85px',
      textAlign: 'right',
      justifyContent: 'right'
    }
  },
  {
    name: 'Entrega',
    key: 'DataEntrega',
    customStyle: {
      minWidth: '85px',
      maxWidth: '85px',
      textAlign: 'right',
      justifyContent: 'right'
    },
    customLabelStyle: {
      minWidth: '85px',
      maxWidth: '85px',
      textAlign: 'right',
      justifyContent: 'right'
    }
  },
  {
    name: 'Material',
    key: 'Material',
    customStyle: {
      minWidth: '150px',
      width: '100%',
      textAlign: 'left',
      justifyContent: 'left',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap'
    },
    customLabelStyle: {
      minWidth: '150px',
      width: '100%',
      textAlign: 'left',
      justifyContent: 'left'
    }
  },
  {
    name: 'Quantidade',
    key: 'Quantidade',
    customStyle: {
      minWidth: '100px',
      maxWidth: '100px',
      textAlign: 'center',
      justifyContent: 'center',
    },
    customLabelStyle: {
      minWidth: '100px',
      maxWidth: '100px',
      textAlign: 'center',
      justifyContent: 'center'
    }
  },      
  {
    name: 'Pendente',
    key: 'SaldoPendente',
    customStyle: {
      minWidth: '100px',
      maxWidth: '100px',
      textAlign: 'center',
      justifyContent: 'center'
    },
    customLabelStyle: {
      minWidth: '100px',
      maxWidth: '100px',
      textAlign: 'center',
      justifyContent: 'center'
    }
  },
  {
    name: 'Cancelado',
    key: 'SaldoCancelado',
    customStyle: {
      minWidth: '100px',
      maxWidth: '100px',
      textAlign: 'center',
      justifyContent: 'center'
    },
    customLabelStyle: {
      minWidth: '100px',
      maxWidth: '100px',
      textAlign: 'center',
      justifyContent: 'center'
    }
  },
  {
    name: 'Situação',
    key: 'SituacaoDesc',
    customStyle: {
      minWidth: '150px',
      maxWidth: '150px',
      textAlign: 'left',
      justifyContent: 'left'
    },
    customLabelStyle: {
      minWidth: '150px',
      maxWidth: '150px',
      textAlign: 'left',
      justifyContent: 'left'
    }
  },
  {
    name: 'Atualização',
    key: 'UltimaAtualizacao',
    customStyle: {
      minWidth: '100px',
      maxWidth: '100px',
      textAlign: 'right',
      justifyContent: 'right'
    },
    customLabelStyle: {
      minWidth: '100px',
      maxWidth: '100px',
      textAlign: 'right',
      justifyContent: 'right'
    }
  },
];
