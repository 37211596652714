import styled from 'styled-components'

export const Wrapper = styled.div``
export const DialogWrapper = styled.div`
  .btn-ar-out {
    display: none;
  }
`
export const WrapperHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Title = styled.div``
export const ActionButton = styled.div`

  display:flex;
  justify-content: flex-end;
  margin-right: 16px;
`
