const IconCalendarScheduleRefresh = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={width ? width : "32px"}
    height={height ? height : "24px"}
    style={{
      enableBackground: "new 0 0 60 60",
    }}
    viewBox="0 0 60 60"
  >
    <g fill="var(--color-action-default)">
      <path
        d="M56.699 48.02a1.002 1.002 0 0 0-1.18.781C54.403 54.303 50.074 58 44.749 58c-5.028 0-9.446-3.3-10.948-8H38.5v-2h-8v8h2v-3.848C34.669 56.833 39.415 60 44.749 60c6.304 0 11.42-4.341 12.731-10.801a1 1 0 0 0-.781-1.179zM56.5 36v4.308C54.25 35.855 49.796 33 44.749 33c-6.109 0-11.541 3.997-13.209 9.721a1 1 0 1 0 1.92.558C34.858 38.482 39.605 35 44.749 35c4.565 0 8.562 2.766 10.33 7H50.5v2h8v-8h-2z"
        data-old_color="#000000"
        data-original="#000000"
      />
      <path
        d="M37.5 32h9V21h-38v29h20V32h9zm0-9h7v7h-7v-7zm-18 0h7v7h-7v-7zm-9 0h7v7h-7v-7zm0 9h7v7h-7v-7zm7 16h-7v-7h7v7zm9 0h-7v-7h7v7zm0-9h-7v-7h7v7zm2-9v-7h7v7h-7z"
        data-old_color="#000000"
        data-original="#000000"
      />
      <path
        d="M25.5 55h-22V16h48v13a1 1 0 1 0 2 0V5a1 1 0 0 0-1-1h-5V1a1 1 0 0 0-1-1h-7a1 1 0 0 0-1 1v3h-22V1a1 1 0 0 0-1-1h-7a1 1 0 0 0-1 1v3h-5a1 1 0 0 0-1 1v51a1 1 0 0 0 1 1h23a1 1 0 1 0 0-2zm15-53h5v6h-5V2zm-31 0h5v6h-5V2zm-6 4h4v3a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V6h22v3a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V6h4v8h-48V6z"
        data-old_color="#000000"
        data-original="#000000"
      />
    </g>
  </svg>
);

export default IconCalendarScheduleRefresh;