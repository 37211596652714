import React from "react";

export const Happy = () => (
  <svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 559.464 559.464">
    <g>
      <g>
        <g id="group-6svg">
          <path
            d="M314.668,429.019h-48.477c-61.9,0-110.404-47.714-110.404-108.635c0-7.435,6.034-13.468,13.468-13.468
          c7.434,0,13.468,6.034,13.468,13.468c0,45.81,36.661,81.69,83.467,81.69h48.477c46.807,0,83.467-35.089,83.467-79.885
          c0-7.443,6.025-13.468,13.468-13.468c7.434,0,13.468,6.025,13.468,13.468C425.072,382.095,376.569,429.019,314.668,429.019z"
          />
          <path
            d="M227.205,204.889c-3.448,0-6.896-1.32-9.527-3.951c-12.768-12.759-33.859-13.217-46.106-0.979
          c-5.262,5.262-13.783,5.262-19.044,0s-5.262-13.792,0-19.053c22.555-22.555,61.11-22.115,84.195,0.988
          c5.262,5.253,5.262,13.792,0,19.044C234.092,203.569,230.644,204.889,227.205,204.889z"
          />
          <path
            d="M410.401,204.889c-3.448,0-6.896-1.32-9.527-3.951c-12.768-12.759-33.859-13.217-46.106-0.979
          c-5.253,5.262-13.783,5.262-19.044,0c-5.253-5.262-5.253-13.792,0-19.053c22.555-22.555,61.11-22.115,84.195,0.988
          c5.262,5.253,5.262,13.792,0,19.044C417.288,203.569,413.849,204.889,410.401,204.889z"
          />
          <path
            d="M279.732,559.464c-154.212,0-279.664-125.488-279.664-279.727C0.067,125.488,125.511,0,279.732,0
          c154.203,0,279.664,125.488,279.664,279.736C559.387,433.975,433.926,559.464,279.732,559.464z M279.732,26.946
          c-139.352,0-252.728,113.403-252.728,252.791S140.38,532.527,279.732,532.527c139.361,0,252.728-113.403,252.728-252.791
          S419.084,26.946,279.732,26.946z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const Surprised = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 559.464 559.464"
  >
    <g>
      <g>
        <g id="group-9svg">
          <path
            d="M411.101,393.094H159.693c-7.434,0-13.468-6.034-13.468-13.468c0-7.443,6.034-13.477,13.468-13.477
				h251.408c7.434,0,13.468,6.034,13.468,13.477C424.57,387.061,418.536,393.094,411.101,393.094z"
          />
          <path
            d="M404.107,206.415c0,17.796-14.42,32.216-32.207,32.216s-32.198-14.42-32.198-32.216
				c0-17.787,14.411-32.207,32.198-32.207C389.687,174.208,404.107,188.628,404.107,206.415z"
          />
          <path
            d="M218.936,206.415c0,17.796-14.42,32.216-32.198,32.216c-17.787,0-32.198-14.42-32.198-32.216
				c0-17.787,14.411-32.207,32.198-32.207S218.936,188.628,218.936,206.415z"
          />
          <path
            d="M279.732,559.464c-154.212,0-279.664-125.488-279.664-279.727C0.076,125.488,125.529,0,279.732,0
				s279.664,125.488,279.664,279.736C559.396,433.975,433.935,559.464,279.732,559.464z M279.732,26.946
				c-139.352,0-252.728,113.403-252.728,252.791S140.38,532.527,279.732,532.527c139.361,0,252.728-113.403,252.728-252.791
				S419.093,26.946,279.732,26.946z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const Sad = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 567.419 567.419"
  >
    <g>
      <g>
        <g id="group-3svg">
          <path
            d="M409.93,209.297c0,18.056-14.627,32.692-32.683,32.692c-18.047,0-32.683-14.636-32.683-32.692
				s14.636-32.683,32.683-32.683C395.303,176.614,409.93,191.241,409.93,209.297z"
          />
          <path
            d="M222.011,209.297c0,18.056-14.636,32.692-32.683,32.692s-32.683-14.636-32.683-32.692
				s14.636-32.683,32.683-32.683S222.011,191.241,222.011,209.297z"
          />
          <path
            d="M172.538,453.621c-7.434,0-13.468-6.025-13.468-13.468c0-58.138,46.762-105.439,104.245-105.439h45.397
				c57.483,0,104.245,47.04,104.245,104.855c0,7.443-6.025,13.477-13.468,13.477c-7.435,0-13.459-6.034-13.459-13.477
				c0-42.955-34.685-77.91-77.317-77.91h-45.397c-42.632,0-77.308,35.206-77.308,78.493
				C186.006,447.596,179.972,453.621,172.538,453.621z"
          />
          <path
            d="M283.714,567.419c-156.403,0-283.642-127.275-283.642-283.714C0.072,127.275,127.311,0,283.714,0
				c156.394,0,283.633,127.275,283.633,283.705C567.347,440.143,440.108,567.419,283.714,567.419z M283.714,26.946
				c-141.552,0-256.705,115.181-256.705,256.759c0,141.588,115.154,256.768,256.705,256.768
				c141.543,0,256.696-115.181,256.696-256.768C540.41,142.126,425.257,26.946,283.714,26.946z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const UnRated = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="78"
    height="78"
    viewBox="0 0 86.811 86.811"
  >
    <path
      fill="#001d30"
      d="M43.405,0A43.405,43.405,0,1,0,86.811,43.405,43.455,43.455,0,0,0,43.405,
      0Zm0,82.118A38.706,38.706,0,1,1,82.118,43.405,38.59,38.59,0,0,1,43.405,
      82.118ZM27.021,81.062l.212.093Z"
      transform="translate(0 0)"
    />
    <circle
      fill="#001d30"
      cx="4.693"
      cy="4.693"
      r="4.693"
      transform="translate(24.195 29.328)"
    />
    <circle
      fill="#001d30"
      cx="4.693"
      cy="4.693"
      r="4.693"
      transform="translate(53.23 29.328)"
    />
    <path
      fill="#001d30"
      d="M0,0A64.629,64.629,0,0,0,15.777,2.2,78.84,78.84,0,0,0,32.555,0V4.693a78.14,78.14,0,0,1-16.731,2.2A65.248,65.248,0,0,1,0,4.693Z"
      transform="translate(28.155 56.017)"
    />
  </svg>
);
