import styled from 'styled-components'

export const DropdownWrapper = styled.div`
  .dropdown {
    position: absolute;
    right: 15px;
    margin-top: 22px;
    display: flex;
    flex-flow: column;
    width: auto;
    padding: 0;
  }
`

export const DropdownContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    margin-right: 4px;
  }

`

export const DropdownButton = styled.img`
  margin-top: 2px;
  width: 10px;
  height: 8px;
`

