import { parseFloatByLocale } from 'locales/utils';
import moment from 'moment'
import intl from 'react-intl-universal'
import { getTodayDate } from 'utils/date';

const isoDateFormat = "YYYY-MM-DD";

export const validateDate = (loadComposition, orders) => {
  if (moment(loadComposition.DataColeta).format(isoDateFormat) > moment(loadComposition.DataEntrega).format(isoDateFormat)) {
    return intl.get("requestOrders.invalidDateRange")
  } else if (moment(loadComposition.DataColeta).format(isoDateFormat) < moment(getTodayDate()).format(isoDateFormat)) {
    return intl.get('stocks.informAdvanceOrder.feedback.collectionDateCannotEarlierThanCurrentDay');
  } else if (orders.some(x => !parseFloatByLocale(x.Quantidade))) {
    return intl.get("feedbacks.messageNewQuantityValidation");
  } else if (orders.some(x => !x.MaterialSelect && x.IsNew)) {
    return intl.get("feedbacks.messageMaterialBusiness1");
  } else if (orders.filter(x => !x.IsDeleted).length === 0) {
    return intl.get("feedbacks.ordersRequiredInLoad");
  }
  return null;
}