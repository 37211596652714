import React, { useState } from 'react'
import ModalLoadComposition from 'components/modal-load-composition';
import { LabelButton } from 'components/label-button';
import PropTypes from 'prop-types'

const initialLoadProps = {
  isOpen: false,
  IdComposicao: 0,
}

const LinkLoadCompositionForm = ({ idComposicao, modoLeitura, children }) => {
  const [loadCompositionForm, setLoadCompositionForm] = useState(initialLoadProps);

  const handleOpenLoadForm = () => {
    setLoadCompositionForm({
      isOpen: true,
      IdComposicao: idComposicao
    })
  }

  const redirectRoute = (_route) => {
    window.location.href = _route;
  }

  return (
    <React.Fragment>
      <LabelButton onClick={handleOpenLoadForm}>
        {children}
      </LabelButton>
      {loadCompositionForm.isOpen &&
        <ModalLoadComposition
          open={loadCompositionForm.isOpen}
          compositionId={loadCompositionForm.IdComposicao}
          redirectRoute={redirectRoute}
          handleRefresh={() => setLoadCompositionForm(initialLoadProps)}
          readingMode={modoLeitura}
          close={() => setLoadCompositionForm(initialLoadProps)}
        />
      }
    </React.Fragment>
  )
}

LinkLoadCompositionForm.propTypes = {
  idComposicao: PropTypes.number,
  modoLeitura: PropTypes.bool,
  children: PropTypes.array
}

export default LinkLoadCompositionForm;
