import styled from 'styled-components'
import StyledGrid from 'components/styled-grid'

const StyledLogList = styled(StyledGrid)`

  width: 100%;
  padding: 0px 8px 0 8px;

  .align-center {
    text-align: center;
  }

  .containerList {
    background-color: white;
    height: calc(100vh - 343px);
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .rowListHeader {
    width: 100%;
  }

  .pagination {
    justify-content: flex-end;
  }

  .no-results {
    font-size: 14px;
  }

  .pt-10 {
    padding-top: 10px;
  }

  .pb-10 {
    padding-bottom: 10px;
  }

  .hintList:hover .hintListComponent {
    display: block;
  }

  .text-format {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export default StyledLogList
