import styled from "styled-components";

export const Wrapper = styled.div`
  margin-right: 10px;
  .content-button{
    .icon{
        width: auto !important;
        height: 14px !important;
    }
 }
`
