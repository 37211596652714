import React, { Component } from "react";
import PropTypes from 'prop-types'
import Dialog from "material-ui/Dialog";
import { Row, Col } from "react-flexbox-grid";
import { Input, Checkbox } from "@hbsis.uikit/react";
import { Button } from "components/uikit-adapter";
import TitleHeader from 'components/title-header'
import StyledModalAjustesTarefa from "./styledFiltroIntegracaoGradeDescarga";
import intl from 'react-intl-universal'

class ModalAjustesTarefa extends Component {
  constructor() {
    super();
    this.state = { };
  }

  render() {
    const {
      openModalAjustesTarefa,
      toggleOpenModalAjustesTarefa,
      integracao,
      profileAdminOrMasterData,
      handleSaveAjustesTarefa,
      changeValueTarefas,
      configuracaoTarefa
    } = this.props;

    return (
      <Dialog
        contentStyle={{ width: "800px" }}
        open={openModalAjustesTarefa}
        onRequestClose={toggleOpenModalAjustesTarefa}
        autoScrollBodyContent
      >
        <TitleHeader className="headerModal">{integracao.DescricaoTipoIntegracao}</TitleHeader>
        <StyledModalAjustesTarefa>

          <Row>
            <Col xs={5} lg={5} md={5} className="colTitulo">
              {intl.get('master-data.configurations.integrations.integrationAdjustModal.baseLineHour')}
            </Col>
            <Col xs={7} lg={7} md={7} className="colCheckBox">
              <Checkbox
                text={intl.get('master-data.configurations.integrations.integrationAdjustModal.workdaysOnly')}
                name="checkbox-all"
                checked={configuracaoTarefa.baselineSomenteSemana}
                onChange={(event) => changeValueTarefas('baselineSomenteSemana', !configuracaoTarefa.baselineSomenteSemana)}
                disabled={!profileAdminOrMasterData}
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={12} md={12}>
              <Input
                label=""
                value={configuracaoTarefa.baseLineHorario}
                onChange={(event) => changeValueTarefas('baseLineHorario', event.target.value)}
                htmlType="time"
                disabled={!profileAdminOrMasterData}
                className='input'
              />
            </Col>
          </Row>

          <div className="footer-dialog-buttons">
            <Button
              type="default"
              value={intl.get('geral.buttonsDefault.close')}
              onClick={toggleOpenModalAjustesTarefa}
              className="button"
            />
            {profileAdminOrMasterData &&
              <Button
                type="primary"
                value={intl.get('geral.buttonsDefault.save')}
                onClick={handleSaveAjustesTarefa}
                className="button"
              />
            }
          </div>
        </StyledModalAjustesTarefa>
      </Dialog >
    )
  }
}


ModalAjustesTarefa.propTypes = {
  openModalAjustesTarefa: PropTypes.bool.isRequired,
  integracao: PropTypes.object.isRequired,
  toggleOpenModalAjustesTarefa: PropTypes.func.isRequired,
  handleSaveAjustesTarefa: PropTypes.func.isRequired,
  profileAdminOrMasterData: PropTypes.func.isRequired,
  configuracaoTarefa: PropTypes.object.isRequired,
  changeValueTarefas: PropTypes.func.isRequired
}

export default ModalAjustesTarefa;
