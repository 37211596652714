import styled from "styled-components";

const heightTableContainer = 'calc(100vh - 310px)';

export const WrapperNoContent = styled.div`
  height: ${heightTableContainer};
  top: 32px;
  position: relative;
  display: flex;
  justify-content: center; 
`

export const Wrapper = styled.div`
  height: ${heightTableContainer};
  overflow-y: auto;
  margin-top: 10px;
`

interface WrapperRowItemProps {
  isRead: boolean
}

export const WrapperRowItem = styled.div<WrapperRowItemProps>`
  display: contents;

  clb-table-tbody-cell {
    font-weight: ${p => !p.isRead ? 'bold' : 'normal'};
  }

`