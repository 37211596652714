import PropTypes from "prop-types"
import {
  createContext,
  useContext,
  useState,
} from "react";

const ThemeContext = createContext();
const ThemeProvider = ({ children }) => {
  const [switcher, setSwitcher] = useState(false)

  const changeTheme = () => {
    setSwitcher(!switcher)
    document.documentElement.setAttribute('data-theme', switcher ? 'l4' : 'l3')
  }

  return (
    <ThemeContext.Provider value={{ switcher, changeTheme }}>
      {children}
    </ThemeContext.Provider>
  )

}

ThemeProvider.propTypes = {
  children: PropTypes.any.isRequired
}
export const useThemeContext = () => useContext(ThemeContext);
export { ThemeProvider, ThemeContext };