import intl from 'react-intl-universal'

export const converteDataParaMultiSelect = (data, key, value) => {
  return data.map((d) => ({
    label: d[key],
    value: d[value]
  }))
}

export const overrideString = (campo) => ({
  "allItemsAreSelected": intl.get("bottles.filter.general.allItemsAreSelected"),
  "clearSearch": intl.get("bottles.filter.general.clearSearch"),
  "clearSelected": intl.get("bottles.filter.general.clearSelected"),
  "noOptions": intl.get("bottles.filter.general.noOptions"),
  "search": intl.get("bottles.filter.general.search"),
  "selectAll": intl.get("bottles.filter.general.selectAll"),
  "selectAllFiltered": intl.get("bottles.filter.general.selectAllFiltered"),
  "selectSomeItems": campo === 'destinos' ? intl.get("bottles.filter.general.selectDestiny") :
    intl.get("bottles.filter.general.selectLabeledFamily"),
  "create": intl.get("bottles.filter.general.create"),
})

export const getFieldValue = (field) => field ? [field.value] : []