import Header from "components/header";
import { NotificationCenterTable } from "../notification-center-table";
import { NotificationCenterFilter } from "../notification-center-filter";
import { NotificationCenterPagination } from "../notification-center-pagination";
import intl from 'react-intl-universal';

import * as S from "./styled"

export const NotificationCenter = () => (
  <>
    <Header
      title={intl.get("notifications.notificationCenter")}
      noBackground
    />
    <S.Wrapper>
      <NotificationCenterFilter />
      <NotificationCenterTable />
      <NotificationCenterPagination />
    </S.Wrapper>
  </>
);