import { Component } from "react";
import { Row, Col } from "react-flexbox-grid";
import { Checkbox } from "@hbsis.uikit/react";
import { SelectBox} from "components/uikit-adapter/index"
import StyledFiltroIntegracaoGradeDescarga from "./styledFiltroIntegracaoGradeDescarga";
class FiltroIntegracaoGradeDescarga extends Component {
  constructor() {
    super();

    this.state = {
      checkedAll: false
    };
  }

  checkedAll = () => {
    this.setState(prevState => ({
        checkedAll: !prevState.checkedAll
      }),
      () => this.props.changeValue("filters", this.state.checkedAll ? { TodasCarteiras: this.state.checkedAll} : null)
    );
  };

  changeValueWallets = event => {
    const value = [];
    event.map(v => value.push(v));
    this.props.changeValue(
      "filters",
      value == null || value.length === 0 ? null : { IdsCarteiras: value }
    );
  };

  render() {
    const { carteiras, IdsCarteiras } = this.props;

    return (
      <StyledFiltroIntegracaoGradeDescarga>
        <Row>
          <Col xs={5} lg={5} md={5} className="colCarteiras">
              {'Filtrar Carteiras'}
          </Col>
          <Col xs={7} lg={7} md={7} className="colCheckBox">
              <Checkbox
                text='Todas as Carteiras'
                name="checkbox-all"
                checked={this.state.checkedAll}
                onChange={this.checkedAll}
              />
          </Col>
        </Row>

        <Row>
          <Col xs={12} lg={12} md={12}>
            <SelectBox
              name="carteira"
              style={{height:'45px'}}
              value={IdsCarteiras}
              options={carteiras}
              valueKey="Id"
              labelKey="Description"
              onChange={this.changeValueWallets}
              placeholder={this.state.checkedAll ? "Todas as Carteiras Selecionadas" : "Selecione as Carteiras"}
              searchable
              openOnFocus
              clearable
              disabled={this.state.checkedAll}
              multi
            />
          </Col>
        </Row>
      </StyledFiltroIntegracaoGradeDescarga>
    );
  }
}

export default FiltroIntegracaoGradeDescarga;
