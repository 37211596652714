import styled from 'styled-components'

const StyledSteps = styled.div `
 * {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
body {
  font: 300 14px/1.4 'Roboto';
  background: #eee;
  margin: 0;
  padding: 0;
}

> .steps {
  background: var(--color-neutral-background);
  
  > .steps__labels {
    margin: 0;
    padding: 0;
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    
    > li {
      display: inline-block;
      display: flex;
      align-items: center;
      justify-content: center;
      
      &:not(:last-child)::after {
        content: '';
        width: 124px;
        height: 2px;
        background: var(--color-neutral-300);
        margin: 0;
        margin-top: -30px;
      }
      
      &:last-child {
        margin-right: 0;
      }
      
      > a {
        width: 36px;
        height: 60px;
        align-items: center;
        color: var(--color-action-default);
        font-size: 14px;
        font-weight: bold;
        text-decoration: none;
        ${props => props.blockedSteps === true && `
          cursor: default;
        `}
        
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex-flow: column-nowrap;
        height: 80px;
        
        & > .numberTab {
          width: 30px;
          height: 30px;
          color: var(--color-neutral-500);
          border: 2px solid var(--color-neutral-300);
          
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        
        & > .labelTab {
          color: var(--color-neutral-500);
          margin-top: 6px;
          width: 100px;
          text-align: center;
        }
      }
      
      &.active {    
        & > a {
          .numberTab {
            background: var(--color-neutral-200);
            border: 1px solid var(--color-brand-400);
            color: var(--color-contrast-black);
          }
          
          .labelTab {
            color: var(--color-contrast-black);
          }
        }
      }
      
      &.completed:not(.active) {  
        & > a {
          .numberTab {
            background: var(--color-neutral-200);
            background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTNweCIgaGVpZ2h0PSIxMHB4IiB2aWV3Qm94PSIwIDAgMTMgMTAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogc2tldGNodG9vbCA0NS4yICg0MzUxNCkgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+OEIwREU5QjYtNjREMy00NEVELUEwNTktNjYzMDUzM0QyOUU4PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBza2V0Y2h0b29sLjwvZGVzYz4KICAgIDxkZWZzPjwvZGVmcz4KICAgIDxnIGlkPSJTeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iaWNuLXdpenphcmQtY2hlY2siIGZpbGw9IiMyODcwQjIiPgogICAgICAgICAgICA8cGF0aCBkPSJNMy40Mzg3NSw5LjU2MDc1IEwwLjQzODc1LDYuNTYwNzUgQy0wLjE0NjI1LDUuOTc0NzUgLTAuMTQ2MjUsNS4wMjQ3NSAwLjQzODc1LDQuNDM4NzUgQzEuMDI0NzUsMy44NTM3NSAxLjk3NDc1LDMuODUzNzUgMi41NjA3NSw0LjQzODc1IEw0LjQ5OTc1LDYuMzc4NzUgTDEwLjQzODc1LDAuNDM4NzUgQzExLjAyNDc1LC0wLjE0NjI1IDExLjk3NDc1LC0wLjE0NjI1IDEyLjU2MDc1LDAuNDM4NzUgQzEzLjE0NTc1LDEuMDI0NzUgMTMuMTQ1NzUsMS45NzQ3NSAxMi41NjA3NSwyLjU2MDc1IEw1LjU2MDc1LDkuNTYwNzUgQzUuMjY3NzUsOS44NTM3NSA0Ljg4Mzc1LDkuOTk5NzUgNC40OTk3NSw5Ljk5OTc1IEM0LjExNTc1LDkuOTk5NzUgMy43MzE3NSw5Ljg1Mzc1IDMuNDM4NzUsOS41NjA3NSBaIiBpZD0iQ2hlY2siPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==');
            border: 1px solid var(--color-brand-400);
            color: var(--color-contrast-black);
            background-repeat: no-repeat;
            background-position: center center;
            color: transparent;
          }
        }
        
        &:not(:last-child)::after {
          content: '';
          width: 124px;
          height: 2px;
          background: var(--color-brand-400);
          margin: 0;
          margin-top: -30px;
        }
      }
    }
  }
}
`

export default StyledSteps
