import React from 'react'
import PropTypes from 'prop-types'
import intl from 'react-intl-universal'

import './atualizacao-volume.css'
import { formatNumber } from 'utils/format'

const AtualizacaoVolume = ({
  quantidadeAnterior,
  quantidadeAtual
}) => (
    <div>
      <div className="negotiation-read-offer">
        <div className="negotiation-read-column">
          <span>{intl.get('stocks.timeline.previousQty')}</span>
          <span>{formatNumber(quantidadeAnterior)}</span>
        </div>
        <div className="negotiation-read-column">
          <span>{intl.get('stocks.timeline.currentQty')}</span>
          <span>{formatNumber(quantidadeAtual)}</span>
        </div>
      </div>
    </div>
  );

  AtualizacaoVolume.propTypes = {
  quantidadeAnterior: PropTypes.number.isRequired,
  quantidadeAtual: PropTypes.number.isRequired,
}

export default AtualizacaoVolume
