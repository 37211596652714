import React, { useState } from 'react'
import { useZapmslContext } from '../../../../../context/zapmslContext';
import * as S from '../../styled'
import intl from 'react-intl-universal'
import { sendToIvec } from '../../../../../primary-material-schedule.service';
import { useGlobalContext } from 'hooks/useGlobalContext';
import ModalRegisterBolNumber from '../../../../modalRegisterBolNumber';
import { validSendToIvec } from '../../../../../utils/validate';
import { errorMessage } from '../../../../../utils/format';

const BtnSendToIvec = () => {
  const {
    selectedLines,
    startLoading,
    stopLoading,
    getCurrentFilterFormated,
    zapmslData,
    clearSelectedLines,
    updateTableWithData
  } = useZapmslContext();

  const {
    showFeedback
  } = useGlobalContext();

  const [openModalRegisterBolNumber, setOpenModalRegisterBolNumber] = useState(false)
  const openRegisterBolNumber = () => setOpenModalRegisterBolNumber(true);
  const closeRegisterBolNumber = () => setOpenModalRegisterBolNumber(false);

  const validateSendToIvec = () => {
    const dataLineSelected = zapmslData
      .filter(x => selectedLines.includes(x.Id));
    const validated = validSendToIvec(dataLineSelected)
        
    if (validated === "") {
      openRegisterBolNumber();
    } else {
      showFeedback(validated)
    }
  }

  const validateHandleSendToIvec = (bolNumber) => {
    if (bolNumber) {
      handleSendToIvec(bolNumber)
    } else {
      showFeedback(intl.get('primaryMaterialScheduleList.feedbacks.bolRequired'))
    }
  }

  const handleSendToIvec = async (bolNumber) => {
    try {
      closeRegisterBolNumber()
      const dataLineSelected = zapmslData
        .find(x => selectedLines.includes(x.Id))
      startLoading()
      const _filters = getCurrentFilterFormated();
      const dtoRequestSendToIvec = {
        ..._filters,
        DateTo: _filters.DateRangeTo,
        DateFrom: _filters.DateRangeFrom,
        Line: dataLineSelected.Line,
        TransactionId: dataLineSelected.WmsTransactionId,
        BolNumber: bolNumber
      }
      await sendToIvec(dtoRequestSendToIvec)
      showFeedback(intl.get('primaryMaterialScheduleList.feedbacks.ivecSuccessfully'))
    } catch (e) {
      showFeedback(errorMessage(e))
    }
    clearSelectedLines();
    updateTableWithData([...zapmslData])
    stopLoading()
  }

  return (
    <React.Fragment>
      <S.ButtonGenerate
        width="150px"
        value={intl.get('primaryMaterialScheduleList.list.header.btnSendIVEC')}
        onClick={validateSendToIvec}
      />
      {openModalRegisterBolNumber &&
        <ModalRegisterBolNumber
          open={openModalRegisterBolNumber}
          save={validateHandleSendToIvec}
          close={closeRegisterBolNumber}
        />
      }
    </React.Fragment>
  )
}

export default BtnSendToIvec
