import styled from "styled-components";

const MaterialBusinessUnitFormStyled = styled.div`
    position: relative;

    .footer-dialog-buttons{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: right;
    }

    .footer-dialog-buttons .button {
        margin: 2px;
        width: 160px;
    }

    .line-2-columns-form {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 6px;
    }

    .column{
        width: 48%;
    }

    .label-table {
        line-height: 1.8rem;
        font-size: 12px;
        font-weight: var(--font-weight-semibold);
        text-transform: uppercase;
        color: var(--color-contrast-brand);
    }

    .suppliers-list-container {
        overflow: hidden auto;
        height: 235px;
        width: 100%;
    }

    .line-separator {
        height: 1px; 
        width: 100%; 
        border: none; 
        color: rgba(197,208,225,0.6); 
        background-color: rgba(197,208,225,0.6);
    }

`;

export default MaterialBusinessUnitFormStyled;
