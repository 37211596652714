import Pagination from "components/pagination";
import { useNotificationContext } from "features/notifications/context";
import { Wrapper } from './styled'

export const NotificationCenterPagination = () => {
  const { filter, setFilter, quantityRegisters } = useNotificationContext();

  const handlePagination = (value) => {
    setFilter(prevState => ({
      ...prevState,
      _page: value
    }))
  }

  return (
    <Wrapper>
      <Pagination
        refreshSearch={handlePagination}
        page={filter._page}
        pageSize={filter._size}
        amount={quantityRegisters}
      />
    </Wrapper>
  );
};