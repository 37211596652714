import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import StyledButton from './styledButton'

const Button = ({ value, type, disabled = false, onClick, className, width, height }) => (
  <StyledButton
    className={cx(className, type)}
    width={width}
    height={height}
    onClick={onClick}
    disabled={disabled}
  >
    {value}
  </StyledButton>
)

Button.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  value: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    'Primary',
    'TextRed',
    'XmlNF',
  ]),
}

export default Button
