import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Dialog from 'material-ui/Dialog'
import { Textarea, Checkbox } from '@hbsis.uikit/react'
import { Button } from 'components/uikit-adapter'
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'
import intl from 'react-intl-universal'
import {
  ListStatusCancelNoticeSupplier,
  ListStatusCancellationSupplierApproval
} from '../../models/pedidos/statusPedido'
import './danger-dialog.css'
import Loading from 'components/center-loading';
import SessionUser from 'utils/user-storage';
import { perfilPermissaoCancelamentoForcado } from './utils'
import Feature from '../feature'
import { GeneralSettingParameter } from 'models/generalSetting/generalSetting'
import { getTextAreaStyle } from 'components/suggestions-list/suggestions-list-item/suggestions-list-item.styled'

class DangerDialog extends Component {
  constructor(props) {
    super(props)

    this.state = {
      show: props.show,
      selectedMessage: 0,
      messageError: 'Selecione uma Justificativa',
      messageUser: '',
      caseInCancellation: 0,
      forcedCancel: false,
    }
  }

  componentDidMount() {
    this.setState({
      caseInCancellation: this.validateCaseCancellationInProgress()
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      show: nextProps.show
    })
  }

  _onRequestClose = () => {
    this.setState({
      show: false
    })
  }

  _onClick = () => {
    const { caseInCancellation, selectedMessage, messageUser, forcedCancel } = this.state;
    const { handleConfirmRequestOrderCancellation, handleConfirm } = this.props;
    if (caseInCancellation === 2) {
      handleConfirmRequestOrderCancellation(selectedMessage, messageUser, forcedCancel);
    }
    else {
      handleConfirm(selectedMessage, messageUser, forcedCancel)
    }
  }

  _onCancelClick = () => {
    this.setState({
      selectedMessage: 0,
      messageUser: ''
    })
    this.props.handleClose()
  } 

  validateCaseCancellationInProgress = () => {
    const { statusPedidoEmCancelamento } = this.props;
    switch (true) {
      case ListStatusCancelNoticeSupplier.some(orderStatus => orderStatus === statusPedidoEmCancelamento):
        return 1
      case ListStatusCancellationSupplierApproval.some(orderStatus => orderStatus === statusPedidoEmCancelamento):
        return 2
      default:
        return 0
    }
  }

  _onChange = (event, value) => {
    const selectedMessage = value
    this.setState({ selectedMessage })
  }

  _onChangeCheckedForcedCancel = (e) => {
    this.setState({ forcedCancel: e.target.checked })
  }

  handleMessageUser = (e) => {
    this.setState({
      messageUser: e.target.value
    })
  }

  render() {
    const { title, labelButton, labelButtonClose, justificationMessages, isLoading } = this.props;
    const { show, selectedMessage, caseInCancellation } = this.state;

    return (
      <Dialog
        open={show}
        title={title}
        contentStyle={{ width: '455px' }}
        onRequestClose={this._onRequestClose}
      >
        <div className="container-dialog-danger">
          {caseInCancellation > 0 &&
            <div className='warning-message-wrapper'>
              <p className='title-warning-message'>{intl.get(`stocks.stockDetail.dangerDialog.warningTitleCase${caseInCancellation}`)}</p>
              <p className='description-warning-message'>{intl.get(`stocks.stockDetail.dangerDialog.warningDescriptionCase${caseInCancellation}`)}</p>
            </div>
          }
          <div className='select-dropdown'>
            <SelectField
              floatingLabelText={intl.get('stocks.suggestionsListItem.justification')}
              value={selectedMessage}
              onChange={this._onChange}
              fullWidth={true}
            >
              {justificationMessages && justificationMessages.map((v, i) => (
                <MenuItem value={v.Key} key={i} primaryText={v.Value} />
              ))}
            </SelectField>
          </div>
          {caseInCancellation > 0 &&
            <Textarea
              label={intl.get('stocks.stockDetail.dangerDialog.messageToSupplier')}
              name="messageUser"
              value={this.state.messageUser}
              onChange={this.handleMessageUser}
              style={getTextAreaStyle({ height: '100%'})}
            />
          }
          {(perfilPermissaoCancelamentoForcado.includes(SessionUser.get().PerfilAcesso)) &&
            <Feature hideFlags={GeneralSettingParameter.LoadCompositionNewFlow}>
              <div style={{ zIndex: 2 }}>
                <Checkbox
                  text={intl.get('stocks.stockDetail.dangerDialog.forcedCancel')}
                  name="forced-checkbox"
                  checked={this.state.forcedCancel}
                  onChange={this._onChangeCheckedForcedCancel}
                />
              </div>
            </Feature>
          }
          <div className="dialog-danger-button-container">
            <Button
              value={labelButtonClose}
              type="default"
              onClick={this._onCancelClick}
              width={"166px"}
              className="button__danger-dialog"
            />
            <Button
              value={labelButton}
              type="danger"
              onClick={this._onClick}
              width={"166px"}
              className="button__danger-dialog"
            />
          </div>
          <div className="testeLoad">
            <Loading isLoading={isLoading} fullHeightParent />
          </div>
        </div>
      </Dialog>
    )
  }
}

DangerDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  justificationMessages: PropTypes.array.isRequired,
  labelButton: PropTypes.string.isRequired,
  labelButtonClose: PropTypes.string.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirmRequestOrderCancellation: PropTypes.func,
  statusPedidoEmCancelamento: PropTypes.number,
  isLoading: PropTypes.bool
}

export default DangerDialog
