import React, { Component } from "react";
import PropTypes from 'prop-types'
import { Row, Col } from "react-flexbox-grid";
import intl from 'react-intl-universal'

import StyledLogList from './styledLogList'

class LogTableIntegracao extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { logs } = this.props;

    return (
      <StyledLogList>
        <Row className="rowListHeader">
    <Col xs={2} md={2} lg={2} className="align-center">{intl.get('master-data.configurations.integrations.dateHour')}</Col>
    <Col xs={10} md={10} lg={10}>{intl.get('commons.message')}</Col>
        </Row>
        <Row className="containerList">
          <Col xs={12} md={12} lg={12} className="pt-0 pb-0">
            {!logs &&
              <Row className="rowList">
                <Col xs={12} md={12} lg={12} className="align-center">
            <span className="no-results">{intl.get('master-data.configurations.integrations.searchLogs')}</span>
                </Col>
              </Row>
            }
            {(logs && logs.length === 0) &&
              <Row className="rowList">
                <Col xs={12} md={12} lg={12} className="align-center">
                  <span className="no-results">{intl.get('master-data.configurations.integrations.withoutLog')}</span>
                </Col>
              </Row>
            }
            {(logs && !!logs.length && logs.map((item, i) => (
                <Row key={i} className="rowList">
                  <Col xs={2} md={2} lg={2} className="align-center">{item.Date}</Col>
                  <Col xs={10} md={10} lg={10} className="text-format">{item.Message}</Col>
                </Row>
              )))
            }
          </Col>
        </Row>
      </StyledLogList>
    )
  }
}

LogTableIntegracao.propTypes = {
  logs: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired
}

export default LogTableIntegracao;
