import styled from 'styled-components'

export const Title = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 30px;
  color: var(--color-contrast-brand);
`

export const Wrapper = styled.div`
  padding: 27px 12px 18px 12px;
  white-space: normal;
`

export const Content = styled.div`
  display: flex;
  margin-top: 18px;
  flex-direction: column;
`

export const SubTitle = styled.span`
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: var(--color-action-default);
`


