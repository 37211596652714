export const mapperItemDataForLoadCompositionModal = (item, idUnidadeNegocio, isOrder, idSupplier) => (
  {
    ...item, Supplier: getSupplierDescription(item, idSupplier),
    Material: getMaterialDescription(item),
    IdUnidadeNegocioDestino: idUnidadeNegocio, isOrder: isOrder
  }
);

const getSupplierDescription = (item, idSupplier) => {
  const supplier = item.Materiais[0].Fornecedores.find(x => x.Id === idSupplier)
  return supplier ? supplier.Descricao : ''
}
const getMaterialDescription = (item) => {
  const material = item.Materiais[0]
  return material ? material.MaterialPesquisa : ''
}

export const shouldDisableTransferInputs = (origem, tipoUnidade) => {
  const ORIGEM_XADREZ = { value: 1 }
  const ORIGEM_UNIDADES = { value: 2 }
  const TIPO_UNIDADE_FORNECEDORA = { value: 2 }

  if (origem == ORIGEM_XADREZ.value) {
    return tipoUnidade === TIPO_UNIDADE_FORNECEDORA.value
  }

  if (origem == ORIGEM_UNIDADES.value) {
    return false
  }

}