import React, { useState } from "react"
import { Button } from 'components/uikit-adapter/index'
import { deleteAdvancedPackagingMaterialSchedule, formatWithId } from "../../../../../primary-material-schedule.service"
import { useZapmslContext } from "../../../../../context/zapmslContext"
import { useGlobalContext } from 'hooks/useGlobalContext';
import DeleteIcon from 'images/icn-trash-bin-red.svg'
import intl from "react-intl-universal"
import ModalDelete from "../../../../../../auto-load-optimization/modal-delete"
import { errorMessage } from "../../../../../../auto-load-optimization/utils/validate"
import { sortTable } from "../../../../../utils/format"

const BtnDeleteLine = () => {
  const {
    startLoading,
    stopLoading,
    zapmslData,
    updateTableWithData,
    selectedLines,
    clearSelectedLines,
    setNewLineInProgress
  } = useZapmslContext();

  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false)

  const handleCloseModalDelete = () => {
    setIsOpenModalDelete(false);
  }

  const {
    showFeedback
  } = useGlobalContext();

  const deleteLines = async () => {
    try {
      startLoading()
      handleCloseModalDelete()
      const linesForDelete = zapmslData
        .filter(line => selectedLines.includes(line.Id) && !line.isNewLine)
        .map(line => ({
          Plant: line.Plant,
          Material: line.MaterialNumber,
          SchedulingAgreement: line.ScheduleAgreement,
          ScheduleLineCounter: line.ScheduleLineCounter
        }))

      if (linesForDelete.length > 0) {
        await deleteAdvancedPackagingMaterialSchedule(linesForDelete)
      }
      if (zapmslData.find(line => selectedLines.includes(line.Id) && line.isNewLine)) {
        setNewLineInProgress(false)
      }
      const newDataTable = zapmslData
        .filter(line => !selectedLines.includes(line.Id))
      const sortData = sortTable(newDataTable)
      clearSelectedLines();
      updateTableWithData([...formatWithId(sortData)])
      showFeedback(intl.get('feedbacks.deleteRegister'))
    }
    catch (e) {
      processError(e)
    }
    stopLoading()
  }

  const processError = (message) => {
    showFeedback(errorMessage(message))
  }

  return (
    <React.Fragment>
      <div className='btn-wrapper' data-testid='wrapper-btn-remove' title={intl.get('geral.buttonsDefault.delete')}>
        <Button
          type="secondary"
          icon={DeleteIcon}
          className='btn-icon'
          onClick={() => setIsOpenModalDelete(!isOpenModalDelete)}
        />
      </div>
      <ModalDelete
        open={isOpenModalDelete}
        onClose={handleCloseModalDelete}
        deleteRows={deleteLines}
      />
    </React.Fragment>
  )
}

export default BtnDeleteLine
