import React from 'react'
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
  TableFooter,
  Button
} from 'components/uikit-adapter/index'
import PropTypes from 'prop-types'
import { Checkbox } from "@hbsis.uikit/react";
import intl from 'react-intl-universal'

const ListPermissionsSettings = ({ profilePermissions, profileTypesAccess, accessProfile, onChanceCheckBox, save }) => (
  <Table
    fixedHeader
    multiSelectable={false}
    selectable={false}
    wrapperStyle={{ height: '100%' }}
    bodyStyle={{ height: 'calc(100% - 135px)', borderTop: '1px solid #E0E0E0', backgroundColor: 'var(--color-contrast-white)' }}
  >
    <TableHeader
      displaySelectAll={false}
      adjustForCheckbox={false}
      style={{ borderBottom: 'none' }}
    >
      <TableRow style={{ borderBottom: 'none' }}>
        <TableHeaderColumn style={{ width: '100%' }}>{intl.get('master-data.permissions-settings.table.permissions')}</TableHeaderColumn>
        {profileTypesAccess.map(profileTypeAccess => (
          <TableHeaderColumn style={{ width: '100%' }}>{profileTypeAccess.Description}</TableHeaderColumn>
        ))}
      </TableRow>
    </TableHeader>
    <TableBody
      displayRowCheckbox={false}
      showRowHover
    >
      {profilePermissions.map((profilePermission, profilePermissionIndex) => (
        <TableRow key={profilePermissionIndex}>
          <TableRowColumn style={{ width: '100%' }}>
            {profilePermission.Description}
          </TableRowColumn>
          {profileTypesAccess.map((profileTypeAccess, profileTypeAccessIndex) => (
            <TableRowColumn style={{ width: '100%' }} key={profileTypeAccessIndex}>
              <Checkbox
                name={`checkbox-${profileTypeAccess.Id}-${profilePermission.Id}`}
                checked={accessProfile.find(x => x.TipoPerfilAcesso === profileTypeAccess.Id && x.ProfilePermissions.includes(profilePermission.Id))}
                onChange={() => onChanceCheckBox(profilePermission.Id, profileTypeAccess.Id)}
                data-testid={`checkbox-${profileTypeAccess.Id}-${profilePermission.Id}`}
              />
            </TableRowColumn>
          ))}
        </TableRow>
      ))}
    </TableBody>
    <TableFooter
      data-testid='table-footer'
      style={{
        borderTop: 'solid',
        borderColor: 'var(--color-neutral-300)',
        borderWidth: '1px'
      }}
    >
      <Button
        type="primary"
        value={intl.get('geral.buttonsDefault.save')}
        onClick={save}
        className="button"
      />
    </TableFooter>
  </Table >
)

ListPermissionsSettings.propTypes = {
  accessProfile: PropTypes.shape({
    find: PropTypes.func
  }).isRequired,
  onChanceCheckBox: PropTypes.func.isRequired,
  profilePermissions: PropTypes.array.isRequired,
  profileTypesAccess: PropTypes.shape({
    map: PropTypes.func
  }).isRequired,
  save: PropTypes.any.isRequired
}

export default ListPermissionsSettings;
