import styled from 'styled-components'

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 350px);
  grid-row-gap: 12px;
  grid-column-gap: 28px;
  justify-content: center;
  padding: 0px 72px 0px 92px;
`

export const Wrapper = styled.div`
  min-height: calc(100% - 145px);
`
