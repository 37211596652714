import { TableRowColumn } from 'material-ui/Table'
import PropTypes from 'prop-types'

const TableRownColumnUnclickable = ({ style, children }) => (
  <TableRowColumn style={style} onClick={e => e.stopPropagation()}>
    <div style={{ width: '100%' }} >
      {children}
    </div>
  </TableRowColumn>
)

TableRownColumnUnclickable.propTypes = {
  style: PropTypes.string,
  children: PropTypes.any
}

export default TableRownColumnUnclickable
