import PropTypes from "prop-types"
import { useEffect, useState } from 'react'
import { SelectBox } from 'components/uikit-adapter'
import CircularProgress from '@material-ui/core/CircularProgress'
import { getAllLinkedBreweryOnExternalWarehouse, getAllLinkedExternalWarehouseOnBrewery, searchChartData } from 'pages/stocks-detail/stocks-detail.service'
import { getLabelTitle, isBrewery, isExternalWarehouse, getButtonSVG } from './utils'
import * as S from './styled'
import { useGlobalContext } from 'hooks/useGlobalContext'
import { formatErrorMessage } from 'utils/handle-error'
import {  mountEchartData } from 'components/chart-stocks/utils'
import { Chart } from 'components/chart'


export const ExternalWarehouseBreweryCharts = ({ unitType, businessUnitId, labeledFamilyId, filters, wasUpdateGraph }) => {
  const [searchableData, setSearchableData] = useState([])
  const [selectedData, setSelectedData] = useState(undefined)
  const [echart, setEchartData] = useState(undefined)
  const [isLoadingChartData, setIsLoadingChartData] = useState(false)
  const { showFeedback } = useGlobalContext()

  const isComponentVisible = () => (isExternalWarehouse(unitType) || isBrewery(unitType))
    && searchableData.length > 0

  const getData = async () => {
    try {
      const query = {
        businessUnitId,
        labeledFamilyId
      }

      const data = isExternalWarehouse(unitType) ?
        await getAllLinkedBreweryOnExternalWarehouse(query) :
        await getAllLinkedExternalWarehouseOnBrewery(query)

      setSearchableData(data)
    } catch (error) {
      console.log(error)
    }
  }

  const getChartData = async () => {
    setIsLoadingChartData(true)

    try {
      const data = await searchChartData(selectedData?.LabeledFamilyCenterId, filters)
      setEchartData(mountEchartData(data))

    } catch (error) {
      showFeedback(formatErrorMessage(error))
    }
    setIsLoadingChartData(false)

  }

  useEffect(() => {
    if (selectedData) {
      getChartData()
    }
  }, [selectedData, filters, wasUpdateGraph])

  useEffect(() => {
    getData()
  }, [])

  return (
    isComponentVisible() ?
      <S.Wrapper>
        <S.Title>{getLabelTitle(unitType)}</S.Title>
        <S.Content>
          <S.Input>
            <SelectBox
              dontShowLabel
              label={'doubleChart'}
              clearable
              searchable
              options={searchableData}
              valueKey={'BusinessUnitId'}
              labelKey={'Name'}
              value={selectedData}
              onChange={(value) => setSelectedData(value)}
            />
          </S.Input>
          <S.Button onClick={() => selectedData && window.open(`/stocksDetail/${selectedData.LabeledFamilyCenterId}`)} data-testid='charts-button'>
            <S.Icon>
              {getButtonSVG(unitType)}
            </S.Icon>
          </S.Button>
        </S.Content>
        {
          isLoadingChartData ?
            <div className='circular-progress'>
              <CircularProgress style={{ color: 'var(--color-brand-300)' }} />
            </div>
            :
            <Chart
              echart={echart}
              notMerge={true}
              lazyUpdate={true}
            />
        }
      </ S.Wrapper>
      : null
  )
}

ExternalWarehouseBreweryCharts.propTypes = {
  businessUnitId: PropTypes.any.isRequired,
  filters: PropTypes.any.isRequired,
  labeledFamilyId: PropTypes.any.isRequired,
  unitType: PropTypes.any.isRequired,
  wasUpdateGraph: PropTypes.any.isRequired
}