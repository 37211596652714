import moment from 'moment';
import { DATA_FORMAT_TYPES, ISO_DATE_FORMAT, getDataFormat } from 'utils/format-date'
import intl from 'react-intl-universal'

const getDefaultDate = () => ({
  dataDe: moment().format(ISO_DATE_FORMAT),
  dataAte: moment().add(2, 'weeks').format(ISO_DATE_FORMAT),
})

export const loadFilter = () => {
  const filtro = localStorage.getItem("filterChart");
  if (filtro) {
    const _filtro = JSON.parse(filtro);
    if (moment(_filtro.dataDe, 'YYYY/MM/DD').format(ISO_DATE_FORMAT) < getDefaultDate().dataDe) {
      return getDefaultDate();
    }

    return JSON.parse(filtro);
  }
  else {
    return getDefaultDate();
  }
}

const hourlyPlanChartData = (data, especificDate) => {
  const dateValidation = data.Details.filter((item) => especificDate == item.Categoria.split('T')[0])
  return dateValidation.map((item) => moment(item.Categoria.split('T')[1], "HH:mm").format("LT"))
}

export const mountEchartData = (data, especificDate) => {
  const categorys = data.IsHourlyPlan
    ? hourlyPlanChartData(data, especificDate)
    : data.Details.map((item) => (getDataFormat(item.Categoria, DATA_FORMAT_TYPES.WITHOUT_YEAR)))

  const over = categorys.map(() => data.Over.De)
  const overAte = categorys.map(() => data.Over.Ate)
  const out = categorys.map(() => data.Out.Ate)

  const echart = {
    stockProjection: [],
    orders: [],
    suggestions: [],
    volumes: [],
    sap: [],
    ordersTransfEntrada: [],
    ordersTransfSaida: [],
    suggestionTransf: [],
    stockPolicyMax: [],
    targetStockPoint: [],
    isHourlyPlan: [data.IsHourlyPlan]
  }

  data.Details.forEach((item) => {
    echart.stockProjection.push(item.Estoque)
    echart.orders.push(item.Pedido)
    echart.suggestions.push(item.Sugestao)
    echart.volumes.push(item.Volume)
    echart.sap.push(item.Pcp)
    echart.ordersTransfEntrada.push(item.PedidoTransferencia)
    echart.ordersTransfSaida.push(item.Transferencia)
    echart.suggestionTransf.push(item.SugestaoTransferencia)
    echart.stockPolicyMax.push(item.PoliticaMaximaVariavel)
    echart.targetStockPoint.push(item.PoliticaObjetiva)
  })

  echart.categorys = categorys
  echart.over = over
  echart.out = out
  echart.max = overAte

  return echart
}

const graphLegends = (position, echart) => {
  const legendFixedFields = [
    { name: "stockProjection", title: intl.get('stocks.stockDetail.stockProjection') },
    { name: "sap", title: intl.get('stocks.stockDetail.necSAP') },
    { name: "targetStockPoint", title: intl.get('commons.objectivePolicy') },
  ]

  const legendFields = [
    ...legendFixedFields,
    { name: "orders", title: intl.get('commons.orders') },
    { name: "volumes", title: intl.get('commons.volumns') },
    { name: "ordersTransfEntrada", title: intl.get('commons.transfIn') },
    { name: "ordersTransfSaida", title: intl.get('commons.transfOut') },
    { name: "suggestionTransf", title: intl.get('commons.suggestedExits') },
    { name: "stockPolicyMax", title: intl.get('commons.peMaxVar') },
    { name: "suggestions", title: intl.get('modalShelfLife.suggestions') },
  ];

  const legendValidated = legendFixedFields.map(x => x.name)
  for (const [key, value] of Object.entries(echart)) {
    if (validateEmptyFields(value) !== false) {
      legendValidated.push(key)
    }
  }

  const legendLabels = []
  legendFields.forEach(x => {
    if (legendValidated.some(item => item === x.name)) {
      legendLabels.push(x.title)
    }
  })

  return position !== null ? Object.values(legendFields)[position]?.title : legendLabels
}

const validateEmptyFields = (item) => item?.some(x => (x !== 0 && x !== null));

export const getOption = (echart) => ({
  grid: {
    bottom: 90,
  },
  backgroundColor: '#fff',
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  legend: {
    bottom: 0,
    data: graphLegends(null, echart),
    textStyle: {
      fontSize: 9,
      color: 'black'
    }
  },
  xAxis: {
    data: echart.categorys,
    axisLine: {
      lineStyle: {
        color: 'black'
      }
    },
    axisLabel: {
      rotate: echart.isHourlyPlan[0] ? 90 : 0
    },
  },
  yAxis: [
    {
      type: 'value',
      splitLine: { show: false },
      axisLabel: {
        fontSize: 10,
        showMaxLabel: false
      },
      axisLine: {
        lineStyle: {
          color: '#000'
        }
      }
    },
    {
      name: 'Over',
      nameLocation: 'top',
      nameTextStyle: {
        padding: 50
      }
    },
    {
      name: 'Out',
      nameLocation: 'end',
      inverse: true,
      nameTextStyle: {
        color: 'red',
        padding: -35
      }
    }
  ],
  dataZoom: [
    {
      show: false,
      start: 0,
      end: 100,
    },
    {
      type: 'inside',
      start: 400,
      end: 500
    },
    {
      show: false,
      yAxisIndex: 0,
      filterMode: 'empty',
      width: 30,
      height: '90%',
      showDataShadow: false,
      left: '93%'
    }
  ],
  series: [
    {
      name: graphLegends(0, echart),
      type: 'line',
      smooth: true,
      showAllSymbol: true,
      symbol: 'emptyCircle',
      symbolSize: 5,
      data: echart.stockProjection,
      color: '#66AEDD',
      tooltip: {
        show: true
      }
    },
    {
      name: graphLegends(1, echart),
      type: 'line',
      smooth: true,
      showAllSymbol: true,
      symbol: 'emptyCircle',
      symbolSize: 5,
      data: echart.sap,
      color: '#2F4296',
      tooltip: {
        show: true
      }
    },
    {
      name: graphLegends(2, echart),
      type: 'line',
      showAllSymbol: false,
      symbol: 'line',
      data: echart.targetStockPoint,
      lineStyle: {
        normal: {
          color: '#7BEB00',
          width: 2,
          type: 'dashed'
        }
      },
      color: '#7BEB00',
      tooltip: {
        show: true,
      }
    },
    {
      z: 1,
      name: graphLegends(3, echart),
      type: 'bar',
      itemStyle: {
        borderRadius: 0,
        color: '#00BFFF'
      },
      data: echart.orders,
      stack: 'Graph',
      tooltip: {
        show: validateEmptyFields(echart.orders)
      }
    },
    {
      z: 1,
      name: graphLegends(4, echart),
      type: 'bar',
      itemStyle: {
        borderRadius: 0,
        color: '#BDCAD9'
      },
      data: echart.volumes,
      stack: 'Graph',
      tooltip: {
        show: validateEmptyFields(echart.volumes)
      }
    },
    {
      z: 1,
      name: graphLegends(5, echart),
      type: 'bar',
      itemStyle: {
        borderRadius: 0,
        color: '#2400FF'
      },
      data: echart.ordersTransfEntrada,
      stack: 'Graph',
      tooltip: {
        show: validateEmptyFields(echart.ordersTransfEntrada)
      }
    },
    {
      z: 1,
      name: graphLegends(6, echart),
      type: 'bar',
      itemStyle: {
        borderRadius: 0,
        color: '#01E7F2'
      },
      data: echart.ordersTransfSaida,
      stack: 'Graph',
      tooltip: {
        show: validateEmptyFields(echart.ordersTransfSaida)
      }
    },
    {
      z: 1,
      name: graphLegends(7, echart),
      type: 'bar',
      itemStyle: {
        borderRadius: 0,
        color: '#FF00D6'
      },
      data: echart.suggestionTransf,
      stack: 'Graph',
      tooltip: {
        show: validateEmptyFields(echart.suggestionTransf)
      }
    },
    {
      name: graphLegends(8, echart),
      type: 'line',
      smooth: true,
      showAllSymbol: true,
      symbol: 'emptyCircle',
      symbolSize: 7,
      data: echart.stockPolicyMax,
      color: '#081826',
      tooltip: {
        show: validateEmptyFields(echart.stockPolicyMax)
      }
    },
    {
      z: 1,
      name: graphLegends(9, echart),
      type: 'bar',
      itemStyle: {
        borderRadius: 0,
        color: '#AE00FF'
      },
      data: echart.suggestions,
      stack: 'Graph',
      tooltip: {
        show: validateEmptyFields(echart.suggestions)
      }
    },
    {
      z: -1,
      silent: true,
      name: 'Max Line',
      type: 'line',
      smooth: true,
      showAllSymbol: true,
      symbol: 'emptyCircle',
      symbolSize: 0,
      data: echart.max,
      color: '#E5EDF4',
      tooltip: {
        show: false
      },
      lineStyle: {
        opacity: 0,
      },
      areaStyle: {
        color: "#E5EDF4",
        opacity: 1,
      },
    },
    {
      z: -1,
      silent: true,
      name: 'Over',
      type: 'line',
      smooth: true,
      showAllSymbol: true,
      symbol: 'emptyCircle',
      symbolSize: 0,
      data: echart.over,
      color: '#E5EDF4',
      lineStyle: {
        opacity: 0
      },
      areaStyle: {
        color: "white",
        opacity: 1
      },
      tooltip: {
        show: false
      }
    },
    {
      z: -1,
      silent: true,
      name: 'Out',
      type: 'line',
      smooth: true,
      showAllSymbol: true,
      symbol: 'emptyCircle',
      symbolSize: 0,
      data: echart.out,
      lineStyle: {
        opacity: 0
      },
      areaStyle: {
        color: '#E5EDF4'
      },
      tooltip: {
        show: false
      }
    },
  ]
})