import React from "react";

const IconGarage = ({ width, height }) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ? width : "36px"}
    height={height ? height : "36px"}
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      fill="var(--color-action-default)"
      d="m19.658 5.458-9-3.429a.442.442 0 0 0-.316 0l-9 3.429C1.138 5.535 1 5.754 1 6v11.429c0 .315.224.571.5.571h18c.276 0 .5-.256.5-.571V5.999c0-.245-.138-.463-.342-.541ZM10.5 3.173 19 6.412v1.08l-8.342-3.177a.442.442 0 0 0-.316 0L2 7.493V6.412l8.5-3.239Zm5.5 9.113H5v-1.143h11v1.143ZM5 13.429h11v1.142H5v-1.143Zm0 2.285h11v1.143H5v-1.143Zm12 1.143v-6.286c0-.316-.224-.571-.5-.571h-12c-.276 0-.5.255-.5.571v6.286H2v-8.16l8.5-3.238L19 8.697v8.16h-2Z"
    />
  </svg>
  )
  export default IconGarage
  