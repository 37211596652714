const TipoPerfilAcesso = Object.freeze({
  PciSolutions: 1,
  MasterData: 2,
  Fornecedor: 3,
  PcpUnidade: 4,
  Gestor: 5,
  Administrador: 6,
});

export default TipoPerfilAcesso;
