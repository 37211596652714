import styled from 'styled-components'
import {
  Button as ButtonExterno
} from "@hbsis.uikit/react";

const StyledButton = styled.div`

  .button-value {
    display: flex;
    align-content: center;
  }

  .button-text {
    font-size: 14px;
    margin-top: 7px;
    color: var(--color-action-default);
  }

  .button-only-icon {
    width: 50px;
    height: 50px;
  }

  .button-filter {
    width: 150px;
  }
`
export const StyledButtonDS = styled(ButtonExterno)`
  .button-with-icon {
    display: flex;
    align-items: center;

    span{
      margin-left: 8px;
    }
  }

${props => props.type === 'primary' && `
    background-color: var(--color-action-default) !important;
    color: var(--color-contrast-brand) !important;
`  }
${props => (props.type === 'secondary' || props.type === 'default') && `
    background-color: var(--color-opacity-default) !important;
    color: var(--color-neutral-600) !important;
    border: var(--border-width-default) solid var(--color-neutral-600) !important;
`  }
${props => props.type === 'danger' && `
    background-color: var(--color-feedback-danger-300) !important;
    color: var(--color-contrast-white) !important;
    border-radius: var(--border-radius-default) !important;
    font-size: var(--font-size-default);
    line-height: var(--line-height-default);
`  }

`

StyledButton.defaultProps = {
}

export default StyledButton
