import { getConfiguredColumn, getConfiguredColumnItensCriticos } from "../../../pages/gerencial/gerencial.service";
import ColumnsItensCriticos from "../../../pages/gerencial/itens-criticos/utils/columnConfigurationItensCriticos";
import Columns from "../../../pages/gerencial/riscos-passivos/utils/columnConfiguration";
import { getConfiguredColumnPrimaryMaterial } from "../../../pages/primary-material-schedule/primary-material-schedule.service";
import ColumnsMaterialPrimary from "../../../pages/primary-material-schedule/utils/columnConfiguration";

export const LoadColumsTable = () => {
  const loadedColumns = getConfiguredColumn();
  const loadedColumnsItensCriticos = getConfiguredColumnItensCriticos();
  const LoadColumnPrimaryMaterialConfiguration = getConfiguredColumnPrimaryMaterial();

  /**
   * Código deste trecho será mantido apenas até criação do epico para
   * configurar colunas do menu gerencial e salvar no backend.
   */
  if (loadedColumns) {
    window.localStorage.setItem(
      "columnConfiguration",
      JSON.stringify(loadedColumns)
    );
  } else {
    window.localStorage.setItem(
      "columnConfiguration",
      JSON.stringify(Columns)
    );
  }

  if (loadedColumnsItensCriticos) {
    window.localStorage.setItem(
      "columnConfigurationItensCriticos",
      JSON.stringify(loadedColumnsItensCriticos)
    );
  } else {
    window.localStorage.setItem(
      "columnConfigurationItensCriticos",
      JSON.stringify(ColumnsItensCriticos)
    );
  }

  if (LoadColumnPrimaryMaterialConfiguration) {
    window.localStorage.setItem(
      "columnPrimaryMaterialConfiguration",
      JSON.stringify(LoadColumnPrimaryMaterialConfiguration)
    );
  } else {
    window.localStorage.setItem(
      "columnPrimaryMaterialConfiguration",
      JSON.stringify(ColumnsMaterialPrimary)
    );
  }
}
