const IconPackage = ({ width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 432.002 432.002",
    }}
    viewBox="0 0 432.002 432.002"
    width={width ? width : 50}
  >
    <path
      fill="var(--color-action-default)"
      d="M399.913 111.306c-.016-.232-.056-.464-.096-.704a8 8 0 0 0-.904-2.616v-.056c0-.056-.088-.088-.128-.144a7.816 7.816 0 0 0-1.744-1.928 6.845 6.845 0 0 0-.528-.416c-.144-.104-.264-.232-.416-.328l-176-104a8 8 0 0 0-8.144 0l-176 104a8 8 0 0 0-3.952 6.888v208a8 8 0 0 0 3.928 6.888l176 104c.206.1.417.188.632.264.232.112.456.208.696.296a7.096 7.096 0 0 0 5.488 0c.24-.088.464-.184.696-.296a6.11 6.11 0 0 0 .632-.264l176-104a8 8 0 0 0 3.928-6.888v-208c0-.24-.072-.464-.088-.696zM158.665 51.17l69.264 40.928a8 8 0 1 0 8.144-13.767l-61.672-36.448 41.6-24.584 160.272 94.704-41.6 24.584-74.592-44.08a8.002 8.002 0 0 0-8.144 13.776l67.008 39.6-41.6 24.584-160.28-94.704 41.6-24.593zm168 108.728v53.424l-41.328 24.424v-53.424l41.328-24.424zm57.336 155.536-160 94.544v-25.976a8 8 0 0 0-16 0v25.976l-160-94.544V116.57l53.336-31.52 160.264 94.712-45.6 26.944-144.848-85.6a8.002 8.002 0 0 0-8.144 13.776l144.992 85.688v131.432a8 8 0 0 0 16 0V220.57l45.336-26.792v57.984a8 8 0 0 0 12.072 6.888l57.328-33.848a8 8 0 0 0 3.928-6.888V150.45l41.336-24.432v189.416z"
      className="active-path"
      data-old_color="#000000"
      data-original="#000000"
    />
    <path
      fill="var(--color-action-default)"
      d="M72.001 264.002a8 8 0 0 0-8 8v32a8 8 0 0 0 16 0v-32a8 8 0 0 0-8-8zM104.001 280.002a8 8 0 0 0-8 8v32a8 8 0 0 0 16 0v-32a8 8 0 0 0-8-8z"
      className="active-path"
      data-old_color="#000000"
      data-original="#000000"
    />
  </svg>
);

export default IconPackage;