import { MsalAuthProvider, LoginType } from "react-aad-msal";
import { Logger, LogLevel } from "msal";
import {
  getRedirectUri,
  getLogoutRedirectUri,
  getAuthorityLoginPage,
  getAuthorityForgotPasswordPage,
  getClientId,
  getAuthScopes
} from "utils/config-environment";

const loggerCallback = (logLevel, message, containsPii) => {
  console.log(message);
};

export const authorityLoginPage = getAuthorityLoginPage()
export const authorityForgotPasswordPage = getAuthorityForgotPasswordPage()

const config = (authority) => ({
  auth: {
    authority: authority ? authority : authorityLoginPage,
    clientId: getClientId(),
    redirectUri: getRedirectUri(),
    postLogoutRedirectUri: getLogoutRedirectUri(),
    validateAuthority: false,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
    secureCookies: true,
  },
  system: {
    logger: new Logger(loggerCallback, {
      level: LogLevel.Warning,
      piiLoggingEnabled: false,
    }),
  },
});

export const authenticationParameters = {
  scopes: getAuthScopes()
};

const options = {
  loginType: LoginType.Redirect,
};

export const authProvider = (authority) => new MsalAuthProvider(
  config(authority),
  authenticationParameters,
  options
);
