import styled from 'styled-components'

const StyledOptimization = styled.div`
  .content {
    height: 100%;
    overflow-x: auto;
    padding: 17px 20px 0 20px;
    display: flex;
    justify-content: center;
  }

  .content-internal {
    width: 100%;
  }

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 70px;

    .header-icon {
      padding-left: 0;
    }
  }

  .button-container {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .button-selected {
    background-color: #b3cef4;
  }

  .center-align {
    width: 100%;
    text-align: center;
  }

  .button-filter-search {
    margin-right: 1px !important;
    width: 130px !important;
  }

  .button-filter-vision {
    width: 50px !important;
    margin-right: 10px !important;
  }

  .button-filter-vision svg {
    padding: 0px !important;
  }

  .column-codigo-link {
    font-weight: bold;
    color: var(--color-action-default);
    text-decoration: underline;
    cursor: pointer;
  }

  .column-alert-erro {
    color: #ff5e53;
  }
  `

export const CodigoLink = styled.div``

export default StyledOptimization