import { Button, TextButton } from '../404.styled';
import intl from 'react-intl-universal';
import { useAuth } from 'oidc-react';
import { filtersClearAllFilters } from 'utils/filters';
import Session from 'utils/user-storage';
import { getRedirectUri } from 'utils/config-environment';

const LogoutFromKc = () => {
  const auth = useAuth();
  
  const logout = () => {
    Session.clear();
    filtersClearAllFilters();
    auth.signOut();
    window.location.href = getRedirectUri();
  }

  return (
    <Button
      className='logout__button'
      style={{ width: '50%' }}
      type='button'
      onClick={logout}
    >
      <TextButton>{intl.get('errorPage.button')}</TextButton>
    </Button>
  )
}

export default LogoutFromKc;