import Fetch from "utils/fetch";
import { generateQueryParams } from "utils/custom-functions";

export const getConsolidatedStocks = (filter) =>
  Fetch.get(`/stocks/consolidated-vision:paginated?${generateQueryParams(filter)}`).then(({ data }) => data);


export const getSuggestionsList = (filter) => 
  Fetch.get(`/suggestions:paginated?${generateQueryParams(filter)}`).then(({data}) => data)

export const saveSuggestionListItem = (item) => 
  Fetch.put(`sugestoes/Editar`, item).then(({data}) => data)

export const acceptSuggestions = (items) => 
  Fetch.post(`suggestions:accept`, items).then(({data}) => data)