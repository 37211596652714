/**
 * Retorna objeto mapeado baseado nos atributos
 * @param {*} value valor da linha
 * @param {*} name nome do campo
 * @param {*} step lista de dados
 * @param {*} index indice do step
 */
export const transformToData = (value, name, step, index) => {
  return name === 'IdJustificativaItemCritico' ?
    { IdJustificativa: value ? value.Id : 0, IdMotivo: step[index].IdMotivoItemCritico } :
    { IdMotivo: value ? value.Id : 0, IdJustificativa: step[index].IdJustificativaItemCritico }
}