import styled from 'styled-components'

const StyledInputLabel = styled.div`
  > .elementContainer {
    position: relative;
    width: 100%;
    height: 40px;
    background: var(--color-contrast-white);
    display: flex;
    align-items: center;
    box-shadow: inset -1px 0 0 0 var(--color-neutral-300), inset 0 -1px 0 0 var(--color-neutral-300), inset 1px 0 0 0 var(--color-neutral-300), inset 0 2px 0 0 var(--color-action-hover);
    font-size: 14px;
    cursor: pointer;
    
    > .filename {
      width: 70%;
      text-align: left;
      padding: 11px 0 11px 15px;
      color: var(--color-neutral-500);
    }
    
    > .button-upload {
        width: 30%;
        height: 100%;
        font-size: 14px;
        background-color: var(--color-neutral-200);
        box-shadow: inset -1px 0 0 0 var(--color-neutral-300), inset 0 -1px 0 0 var(--color-neutral-300), inset 1px 0 0 0 var(--color-neutral-300), inset 0 2px 0 0 var(--color-action-hover);
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--color-action-default);
        font-weight: bold;
        
        > .label-button {
          margin-left: 10px;
        }
        
    }
    
  }
  
  input {    
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
  }

  input {
    height: 40px;

    &[type="number"],
    &[type="date"] {
      -moz-appearance:textfield !important;
    }
  }

  .message {
    display: block;
    margin-top: 2px;
    text-align: right;
    font-size: 12px;
    font-weight: var(--font-weight-semibold);

    &.error {
      color: #ec393d;
    }

    &.alert {
      color: #e8b007;
    }      
  }  
  
  label {
    line-height: 1.5rem;
    font-size: 12px;
    font-weight: var(--font-weight-semibold);
    text-transform: uppercase;
    color: var(--color-contrast-brand);
  }
  
   &.error {
    input,
    textarea {
      box-shadow: inset -1px 0 0 0 var(--color-neutral-300), inset 0 -1px 0 0 var(--color-neutral-300), inset 1px 0 0 0 var(--color-neutral-300), inset 0 2px 0 0 #ec393d;
    }
  }

  &.alert {
    input,
    textarea {
      box-shadow: inset -1px 0 0 0 var(--color-neutral-300), inset 0 -1px 0 0 var(--color-neutral-300), inset 1px 0 0 0 var(--color-neutral-300), inset 0 2px 0 0 #ffc107;
    }
  }

  &.success {
    input,
    textarea {
      box-shadow: inset -1px 0 0 0 var(--color-neutral-300), inset 0 -1px 0 0 var(--color-neutral-300), inset 1px 0 0 0 var(--color-neutral-300), inset 0 2px 0 0 #6f9e32;
    }
  }
`

export default StyledInputLabel
