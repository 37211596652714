import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  
  .select-origem
  {
    padding-bottom: 32px;
  }
`

export const Row = styled.div`
  align-items: center;
  justify-items: start;
  display: grid;
  grid-template-columns: repeat(2, 50%);
`

export const TableRow = styled.div`
  align-items: center;
  justify-items: start;
  display: grid;
  padding-bottom: 8px;
  grid-template-columns: ${'25% 35% 20% 20%'};
`

export const Col = styled.div`
  padding-left: 8px;

  
  .select-box
  {
    div:first-child
    {
      width: 150px;
    }
  }
`

export const WrapperComponent = styled.div`
  width: 280px;
`

export const WrapperAutomaticRoutines = styled.div`
    padding-right: 120px;
    width: 30%;
    h1{
      line-height: 1;
    }
`

export const WrapperScheduleConfiguration = styled.div`
    width: 40%;
    max-width: 490px;
`

export const TableTitle = styled.label`
  font-weight: 600;
`

export const SubTitle = styled.div`
  margin: .30rem auto;
  margin-top: 3.0rem;
  border-color: #bababa;
  color: black;
  font-weight: bold;
  border-bottom-width: 1px;
  padding-bottom: .3rem;
  font-size: 18px;
  padding-right: .10rem
`