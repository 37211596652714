export const OptionsPeriodFormat = {
  Daily: 1,
  Monthly: 2
}

export const OptionsPeriodTotal = {
  Hide: 1,
  Show: 2
}

export const OptionsMedidas = {
  Milheiros: 1,
  Hectolitros: 2
}
