import React, { useEffect, useState } from 'react'
import intl from 'react-intl-universal'
import LoadingProgress from 'components/circular-progress'
import { FilterViewMode } from '../bottles-filter/viewMode'
import { TableView } from '../bottles-table-view'
import { useBottlesContext } from '../../context'
import { getBottlesPlanViewMode } from '../../services/bottles-plan.service'
import * as S from './styled'

export const ViewScreen = () => {
  const {
    openFilter,
    setOpenFilter,
    filter
  } = useBottlesContext()

  const [data, setData] = useState(undefined)
  const [isLoading, setIsLoading] = useState(false)

  const bottlesPlanViewMode = async () => {
    setIsLoading(true)

    try {
      const data = await getBottlesPlanViewMode(filter)
      setData(data)
      setIsLoading(false)
    }
    catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (filter.labeledFamilysId.length > 0) {
      bottlesPlanViewMode()
    }

  }, [filter])

  if (isLoading) {
    return <LoadingProgress />
  }

  return (
    <React.Fragment>
      <div>
        <FilterViewMode
          open={openFilter}
          close={() => setOpenFilter(false)}
          handleFilter={bottlesPlanViewMode}
        />
        {filter.labeledFamilysId.length === 0 ?
          <S.DetailMessage>{intl.get('bottles.detailMessageEditMode')}</S.DetailMessage>
          :
          data &&
          <TableView
            data={data}
            width={"99%"}
          />
        }
      </div>
    </React.Fragment>
  )
}

