import Fetch from 'utils/fetch';

export const getEnumProfilePermission = () => {
  return Fetch.get('/accessprofile/profilepermissions').then(x => x.data);
}

export const getEnumProfileTypesAccess = () => {
  return Fetch.get('/accessprofile/profiletypesaccess').then(x => x.data);
}

export const getAccessProfile = () => {
  return Fetch.get('/accessprofile').then(x => x.data);
}

export const postAccessProfile = data => {
  return Fetch.post('/accessprofile', data)
}
