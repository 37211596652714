import PropTypes from "prop-types"
import React from 'react'
import Pagination from 'components/pagination';
import { useSuggestionContext } from '../../context/suggestionsContext';

const PaginationSuggestion = ({ stopLoading, startLoading }) => {
  const size = 50;
  const {
    suggestions,
    fetchNewPageSuggestions
  } = useSuggestionContext();

  const handleSearchPage = async (newPage) => {
    startLoading();
    try {
      const pageFilter = {
        _page: newPage,
        _size: size
      }
      await fetchNewPageSuggestions(pageFilter)
    }
    catch (e) {
      console.error(e)
    }
    stopLoading();
  }

  return (
    <Pagination
      refreshSearch={handleSearchPage}
      page={suggestions.CurrentPage}
      pageSize={size}
      amount={suggestions.TotalItems}
    />
  )
}

PaginationSuggestion.propTypes = {
  startLoading: PropTypes.func.isRequired,
  stopLoading: PropTypes.func.isRequired
}

export default PaginationSuggestion;
