import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-flexbox-grid";
import { Button } from 'components/uikit-adapter/index';
import intl from "react-intl-universal";
import "./date-range-picker.css";
import DateInput from "../date-input";

class DateRangerPicker extends Component {
  render() {
    const { title, dataDe, dataAte, dataMin } = this.props;

    return (
      <div>
        <div>
          <Row>
            <Col xs={12} lg={12} md={12}>
              <span className="drp-span-text">{title}</span>
            </Col>
          </Row>
        </div>
        <div>
          <Row>
            <Col className="drp-nomargin drp-nopaddinright drp-columnsSize">
              <DateInput
                className="drp-input-date drp-nomargin data-de-picker"
                value={dataDe}
                onChange={(date) => this.props.handleChange("dataDe", date)}
                canClear
                minDate={dataMin}
                maxDate={dataAte}
              />
            </Col>
            <Col className="drp-buttomdeadline">
              <Button
                value={intl.get("stocks.until")}
                width="40px"
                type="secondary"
                className="drp-nomargin"
              />
            </Col>
            <Col className="drp-nomargin drp-nopaddinleft drp-columnsSize">
              <DateInput
                className="drp-input-date drp-nomargin data-ate-picker"
                value={dataAte}
                onChange={(date) => this.props.handleChange("dataAte", date)}
                canClear
                minDate={dataDe}
                maxDate="9999-12-31"
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

DateRangerPicker.propTypes = {
  title: PropTypes.string.isRequired,
  dataDe: PropTypes.string.isRequired,
  dataAte: PropTypes.string.isRequired,
  dataMin: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
};

export default DateRangerPicker;
