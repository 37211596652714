import React from 'react'
import { Checkbox } from "@hbsis.uikit/react";
import { CheckboxStyle } from '../components/list/style';
import { validValue } from './format'
import intl from 'react-intl-universal'
import moment from 'moment'
import { getConfiguredColumnPrimaryMaterial } from '../primary-material-schedule.service';
import { formataExibicaoMaterialParaTabela } from '../../auto-load-optimization/list/utils/format';
import { formatNumber } from 'utils/format';

const normalizeCol = (value, customTitle) => (
  typeof value === 'function' ? value() :
    <span title={customTitle ? customTitle : value} >
      {validValue(value)}
    </span >
)

const normalizeDate = (value) => (
  typeof value === 'function' ?
    <span title=''>
      {value()}
    </span>
    : moment(value).format('L')
)

const normalizeDeliveryTime = (value) => (
  typeof value === 'function' ? value : formatDeliveryTime(value)
)

const formatDeliveryTime = (value) => {
  let split = ""
  if(value !== "") {
    split = value.split(":")
    split = moment(new Date().setHours(parseInt(split[0]), parseInt(split[1]))).format("hh:mm A")
  }
  return split
}



const checkBoxLineFormated = (selectAll) => ({
  labelHeader: <CheckboxStyle title="">
    <Checkbox
      name='checkbox-all'
      onChange={selectAll}
      data-testid='checkbox-select-all'
      id='checkbox-all'
    />
  </CheckboxStyle>,
  attribute: "selectBox",
  headerStyle: { flex: 0.45 },
  dataStyle: { flex: 0.45 },
  align: "left",
  sortable: true,
  visible: true,
  renderCol: row => row.checkBox()
})

export const getColumns = (selectAll) => {
  const configuration = getConfiguredColumnPrimaryMaterial();
  const colunasVisiveis = configuration.Visible;
  const checkBox = checkBoxLineFormated(selectAll);

  return (
    [
      checkBox,
      {
        labelHeader: intl.get('primaryMaterialScheduleList.list.columns.line'),
        attribute: "Line",
        headerStyle: { flex: 0.4 },
        dataStyle: { flex: 0.4 },
        align: "left",
        sortable: true,
        visible: colunasVisiveis.some((column) => column['name'] === 'Linha'),
        renderCol: row => normalizeCol(row.Line)
      },
      {
        labelHeader: intl.get('primaryMaterialScheduleList.list.columns.status'),
        attribute: "OrderStatus",
        headerStyle: { flex: 0.6 },
        dataStyle: { flex: 0.6 },
        align: "left",
        sortable: true,
        visible: colunasVisiveis.some((column) => column['name'] === 'Status'),
        renderCol: row => normalizeCol(row.OrderStatusAcronym, row.OrderStatusDescription)
      },
      {
        labelHeader: intl.get('primaryMaterialScheduleList.list.columns.rel'),
        attribute: "REL",
        headerStyle: { flex: 0.3 },
        dataStyle: { flex: 0.3 },
        align: "left",
        sortable: true,
        visible: colunasVisiveis.some((column) => column['name'] === 'REL'),
        renderCol: row => normalizeCol(row.ReleaseStatus ? 'X' : '-')
      },
      {
        labelHeader: intl.get('primaryMaterialScheduleList.list.columns.transactionId'),
        attribute: "WmsTransactionId",
        align: "left",
        sortable: true,
        visible: colunasVisiveis.some((column) => column['name'] === 'Trans ID'),
        headerStyle: { flex: 1 },
        dataStyle: { flex: 1 },
        renderCol: row => normalizeCol(row.WmsTransactionId)
      },
      {
        labelHeader: intl.get('primaryMaterialScheduleList.list.columns.material'),
        attribute: "Material",
        align: "left",
        sortable: true,
        visible: colunasVisiveis.some((column) => column['name'] === 'Material'),
        headerStyle: { flex: 3 },
        dataStyle: { flex: 3 },
        renderCol: row => normalizeCol(formataExibicaoMaterialParaTabela(row.MaterialNumber, row.MaterialDescription))
      },
      {
        labelHeader: intl.get('primaryMaterialScheduleList.list.columns.scheduleAgreement'),
        attribute: "ScheduleAgreement",
        align: "left",
        sortable: true,
        visible: colunasVisiveis.some((column) => column['name'] === 'Acordo de Cronograma'),
        headerStyle: { flex: 1 },
        dataStyle: { flex: 1 },
        renderCol: row => normalizeCol(row.ScheduleAgreement)
      },
      {
        labelHeader: intl.get('primaryMaterialScheduleList.list.columns.saLineItem'),
        attribute: "SaLineItem",
        align: "left",
        sortable: true,
        visible: colunasVisiveis.some((column) => column['name'] === 'Item SL'),
        headerStyle: { flex: 1 },
        dataStyle: { flex: 1 },
        renderCol: row => normalizeCol(row.SaLineItem)
      },
      {
        labelHeader: intl.get('primaryMaterialScheduleList.list.columns.pallets'),
        attribute: "Pallets",
        align: "left",
        sortable: true,
        visible: colunasVisiveis.some((column) => column['name'] === 'Paletes'),
        headerStyle: { flex: 0.2 },
        dataStyle: { flex: 0.2 },
        renderCol: row => normalizeCol(row.Pallets)
      },
      {
        labelHeader: intl.get('primaryMaterialScheduleList.list.columns.uom'),
        attribute: "Uom",
        align: "left",
        sortable: true,
        visible: colunasVisiveis.some((column) => column['name'] === 'UOM'),
        headerStyle: { flex: 0.2 },
        dataStyle: { flex: 0.2 },
        renderCol: row => normalizeCol(row.Uom)
      },
      {
        labelHeader: intl.get('primaryMaterialScheduleList.list.columns.quantity'),
        attribute: "Quantity",
        align: "left",
        sortable: true,
        visible: colunasVisiveis.some((column) => column['name'] === 'Quantidade'),
        headerStyle: { flex: 0.45 },
        dataStyle: { flex: 0.45 },
        renderCol: row => normalizeCol(formatNumber(row.Quantity))
      },
      {
        labelHeader: intl.get('primaryMaterialScheduleList.list.columns.unit'),
        attribute: "Unit",
        align: "left",
        sortable: true,
        visible: colunasVisiveis.some((column) => column['name'] === 'Unidade'),
        headerStyle: { flex: 0.45 },
        dataStyle: { flex: 0.45 },
        renderCol: row => normalizeCol(row.Unit)
      },
      {
        labelHeader: intl.get('primaryMaterialScheduleList.list.columns.deliveryDate'),
        attribute: "DeliveryDate",
        align: "left",
        sortable: true,
        visible: colunasVisiveis.some((column) => column['name'] === 'Data de entrega'),
        headerStyle: { flex: 1 },
        dataStyle: { flex: 1 },
        renderCol: row => normalizeCol(normalizeDate(row.DeliveryDate))
      },
      {
        labelHeader: intl.get('primaryMaterialScheduleList.list.columns.deliveryTime'),
        attribute: "DeliveryTime",
        align: "left",
        sortable: true,
        visible: colunasVisiveis.some((column) => column['name'] === 'Hora de entrega'),
        headerStyle: { flex: 1 },
        dataStyle: { flex: 1 },
        renderCol: row => normalizeCol(normalizeDeliveryTime(row.DeliveryTime))
      },
      {
        labelHeader: intl.get('primaryMaterialScheduleList.list.columns.door'),
        attribute: "Door",
        align: "left",
        sortable: true,
        visible: colunasVisiveis.some((column) => column['name'] === 'Porta'),
        headerStyle: { flex: 1 },
        dataStyle: { flex: 1 },
        renderCol: row => normalizeCol(row.Door)
      },
      {
        labelHeader: intl.get('primaryMaterialScheduleList.list.columns.comments'),
        attribute: "Comments",
        align: "left",
        sortable: true,
        visible: colunasVisiveis.some((column) => column['name'] === 'Comentários'),
        headerStyle: { flex: 1 },
        dataStyle: { flex: 1 },
        renderCol: row => normalizeCol(row.Comments)
      },
      {
        labelHeader: intl.get('primaryMaterialScheduleList.list.columns.supplierCode'),
        attribute: "SupplierCode",
        align: "left",
        sortable: true,
        visible: colunasVisiveis.some((column) => column['name'] === 'Código do Fornecedor'),
        headerStyle: { flex: 3 },
        dataStyle: { flex: 3 },
        renderCol: row => normalizeCol(row.SupplierCode)
      },
      {
        labelHeader: intl.get('primaryMaterialScheduleList.list.columns.supplierName'),
        attribute: "SupplierName",
        align: "left",
        sortable: true,
        visible: colunasVisiveis.some((column) => column['name'] === 'Nome do Fornecedor'),
        headerStyle: { flex: 1 },
        dataStyle: { flex: 1 },
        renderCol: row => normalizeCol(row.SupplierName)
      },
      {
        labelHeader: intl.get('primaryMaterialScheduleList.list.columns.truckLoadOrder'),
        attribute: "TruckLoadOrder",
        align: "left",
        sortable: true,
        visible: colunasVisiveis.some((column) => column['name'] === 'Ordem de Carregamento do Caminhão'),
        headerStyle: { flex: 0.3 },
        dataStyle: { flex: 0.3 },
        renderCol: row => normalizeCol(row.TruckLoadOrder)
      },
      {
        labelHeader: () => <div title="" style={{ paddingTop: '6px', paddingRight: '5px' }} />,
        attribute: "edit",
        headerStyle: { flex: 0.3 },
        dataStyle: { flex: 0.3 },
        align: "right",
        sortable: true,
        visible: true,
        renderCol: row => row.edit()
      },
    ]
  )
}
