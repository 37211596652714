import React from 'react'
import { Orders as OrderComponent, OrdersProvider } from 'features/orders'

const Orders = () => (
  <OrdersProvider>
    <OrderComponent />
  </OrdersProvider>
)

export default Orders
