import { validatesArrayHasValue } from "utils/custom-functions";

export const disableButtonAcceptSuggestions = (_selecteds, _sendingSuggestions) => (
  !(validatesArrayHasValue(_selecteds) && _selecteds.find(x => x.SuggestionStatus < 5) && !_sendingSuggestions)
)

export const getSuggestionsForAcceptance = (_selecteds) => {
  const _selectedSuggestions = _selecteds.filter(x => x.SuggestionStatus < 5);

  return _selectedSuggestions.map(item => ({ Id: item.Id, Over: isInOver(item.StockUnitPriority), LabeledFamilYCenterId: item.LabeledFamilYCenterId, ExternalSuggestion: item.ExternalSuggestion }));
}

export const getSuggestionsForAcceptanceInOver = (_selecteds) => {
  const _selectedSuggestions = getSuggestionsForAcceptance(_selecteds);

  return _selectedSuggestions.filter(x => x.Over);
}

export const isInOver = (_stockUnitPriority) => (_stockUnitPriority == 1);

export const getExternalSuggestionRequest = (_suggestion, _userId, _justificationForOver) => {
  const _body = {
    UserId: _userId,
    IdSuggestion: [_suggestion.Id],
    LabeledFamilyCenterId: _suggestion.LabeledFamilYCenterId,
    OverOrderOrMaximumStock: _suggestion.Over,
    JustificationTypeOverOrder: _suggestion.Over ? _justificationForOver : null
  }

  return {
    API: `suggestions/external-data:accept`,
    method: 'post',
    body: _body,
    errorId: _suggestion.Id
  }
}

export const getSuggestionRequest = (_suggestion, _userId, _justificationForOver) => {
  const _body = {
    UserId: _userId,
    Suggestions: [_suggestion.Id],
    LabeledFamilyCenterId: _suggestion.LabeledFamilYCenterId,
    OrderInOverOrMaxStock: _suggestion.Over,
    OrderInOverJustificationType: _suggestion.Over ? _justificationForOver : null,
  }

  return {
    API: `suggestions:accept`,
    method: 'post',
    body: _body,
    errorId: _suggestion.Id
  }
}
