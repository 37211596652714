import { NotificationAction } from "enum/notifications";
import { INotificationFilter } from "features/notifications/interface";
import { INotificationResponse } from "types/notifications/notification-response";
import { IPaginatedResult } from "types/pagination";
import { generateQueryParams } from "utils/custom-functions";
import Fetch from "utils/fetch";

export const getNotificationsNav = () => {
  return Fetch.get(`notifications:paginated?_page=0&_size=30`).then(({ data }) => data.Data);
};

export interface INotificationFilterRequest extends Omit<INotificationFilter, 'Unread'> {
  Unread: boolean | null,
}

export const getNotifications = (filter: INotificationFilterRequest): Promise<IPaginatedResult<INotificationResponse[]>> =>
  Fetch.get(`notifications:paginated?${generateQueryParams(filter)}`).then(({ data }) => data);

export const deleteNotifications = (body: { NotificationIds: Array<number> }) =>
  Fetch.delete('notifications', body).then(({ data }) => data);

export const getNewNotifications = (timeToCheck: number) => {
  let parameters = ``;

  if (timeToCheck != null) {
    parameters += `?TimeToCheck=${timeToCheck}`;
  }

  return Fetch.get(`notifications:quantity-new${parameters}`).then(({ data }) => data);
};

export const notificationsMarkAsRead = async (_body: { NotificationIds: Array<number> }) => {
  return Fetch.patch('notifications:mark-as-read', _body).then(({ data }) => data);
};

export const getNotificationsCriticality = () => Fetch.get('notifications/criticality').then(({ data }) => data);

interface NotificationTypeResponse {
  data: NotificationAction[];
}

export const getNotificationsTypeOptions = (): Promise<NotificationAction[]> => {
  return Fetch.get('notifications/type').then(({ data }: NotificationTypeResponse) => data);
};