import styled from 'styled-components'

const StyledButton = styled.button `
  border: 0;
  outline: 0;
  cursor: pointer;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  background-color: transparent;

  &:disabled {
    opacity: .5;
    cursor: not-allowed;
  }

  &.TextRed {
    text-transform: uppercase;
    color: #f16666;
  }

  &.Primary {
    color: var(--color-contrast-white);
    background-color: #139c8c;
  }

  &.XmlNF {
    color: #000000;
    border-radius: 2px;
    border: solid 1px var(--color-neutral-300);
    background-color: var(--color-contrast-white);
    display: inline-block;
    vertical-align: top;
    flex-direction: column;
    align-items: center;
    padding-top: 6px;
    margin-right: 10px;
  }
`

StyledButton.defaultProps = {
  width: 130,
  height: 40,
}

export default StyledButton
