import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
  margin-top: -7px;
`

export const Label = styled.label`
  margin-left: 15px;
`
