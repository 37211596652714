import styled from 'styled-components'


export const Wrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 49%;
`

export const Text = styled.span `
  font-size: 16px;
  font-weight: var(--font-weight-semibold);
`