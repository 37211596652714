import PropTypes from "prop-types"
import React, { useState } from 'react'
import intl from 'react-intl-universal'
import moment from 'moment'
import { BottlesViewOrderModal } from '../bottles-view-order-modal'
import { renderData, renderTotalData } from './render'
import { calcQuantityColumns, isFirstRender } from './utils'
import { OptionsPeriodFormat, OptionsPeriodTotal } from '../../models/viewModeModel'
import { Vector } from '../bottles-dropdown/vector'
import { useBottlesContext } from '../../context'
import AlertVector from 'images/AlertVector.svg'
import { DetailMessage } from '../bottles-edit-screen/styled'
import * as S from './styled'

export const TableView = ({ height, width, data }) => {
  const { checkboxs, viewMode, periodTotal, optionsMedida } = useBottlesContext()
  const showPeriod = periodTotal === OptionsPeriodTotal.Show
  const isMonthlyView = viewMode === OptionsPeriodFormat.Monthly
  const [openModal, setOpenModal] = useState({
    open: false,
    date: '',
    labeledFamilyCenterId: ''
  })

  const handleModal = (date, labeledFamilyCenterId) => {
    setOpenModal(prevState => ({
      open: !prevState.open,
      date,
      labeledFamilyCenterId
    }))
  }

  return (
    data.BusinessUnits.length > 0 ?
    <S.TableWrapper height={height} width={width}>
      <S.TableScrollWrapper>
        {data.BusinessUnits.map((d, idxData) => (
          <S.TableHeaders>
            {isFirstRender(idxData) && <S.TableHeading isHeader first width='99px'>{intl.get('commons.destiny')}</S.TableHeading>}
            <S.Title
              qnt={calcQuantityColumns(checkboxs)}
              length={d.LabeledFamilies.length}
              isFirst={isFirstRender(idxData)}
            >
              <div>
                {`${d.BusinessUnitPlant} - ${d.BusinessUnitDescription}`}
              </div>
            </S.Title>
            <S.TableColumn>
              {d.LabeledFamilies.map((familiaRotulada, idxFamiliaRotulada) => (
                <S.TableRow className='wrapper__row'>
                  {isFirstRender(idxData, idxFamiliaRotulada) && <S.TableHeading isHeader second width='125px'>{intl.get('commons.labeledFamily')}</S.TableHeading>}
                  <S.Title
                    qnt={calcQuantityColumns(checkboxs)}
                    isFirst={isFirstRender(idxData, idxFamiliaRotulada)}
                    isHeader
                  >
                    <S.TableHeaderLabeledFamily>
                      <div>
                        {familiaRotulada.LabeledFamilyName}
                      </div>
                      {
                        !familiaRotulada.MatchingStock &&
                        <div className='stock__checked'>
                          <Vector width={'14px'} heigth={'14px'} vector={AlertVector} />
                          {intl.get('bottles.stockMB52Message')}
                        </div>
                      }
                    </S.TableHeaderLabeledFamily>
                  </S.Title>
                  <S.TableContent viewMode={isMonthlyView}>
                    {familiaRotulada.Items.map(x => (
                      <React.Fragment>
                        <S.TableColumn>
                          {isFirstRender(idxData, idxFamiliaRotulada) &&
                            <S.TableHeading>
                              {isMonthlyView ?
                                <React.Fragment>
                                  <span className='monthly-title'>{moment(x.Date).format("MMMM")}</span>
                                  <span>{moment(x.Date).format("YYYY")}</span>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                  <span>{intl.get(`weekdays.${moment(x.Date).weekday()}`)}</span>
                                  <span>{moment(x.Date).format("L")}</span>
                                </React.Fragment>
                              }
                            </S.TableHeading>
                          }
                          <S.TableRow>
                            <S.GridWrapper
                              onClick={() => !isMonthlyView && handleModal(x.Date, familiaRotulada.LabeledFamilyCenterId)}
                              isFirst={isFirstRender(idxData, idxFamiliaRotulada)}
                              qnt={calcQuantityColumns(checkboxs)}
                            >
                              {renderData(x, checkboxs, optionsMedida, familiaRotulada.HectoliterPerThousand)}
                            </S.GridWrapper>
                          </S.TableRow>

                        </S.TableColumn>
                      </React.Fragment>
                    ))}
                    {showPeriod &&
                      <S.TotalColumnWrapper>
                        {isFirstRender(idxData, idxFamiliaRotulada) && <S.TableHeading>{intl.get(`bottles.periodTotal`)}</S.TableHeading>}
                        <S.TableRow isTotal>
                          <S.GridWrapper
                            qnt={calcQuantityColumns(checkboxs)}
                            isTotal
                            isFirst={isFirstRender(idxData, idxFamiliaRotulada)}
                          >
                            {renderTotalData(familiaRotulada.Items, checkboxs, familiaRotulada.HectoliterPerThousand, optionsMedida)}
                          </S.GridWrapper>
                        </S.TableRow>
                      </S.TotalColumnWrapper>
                    }
                  </S.TableContent>
                </S.TableRow>
              ))}
            </S.TableColumn>
          </S.TableHeaders>
        ))}
      </S.TableScrollWrapper>
      <BottlesViewOrderModal
        openModal={openModal.open}
        labeledFamilyCenterId={openModal.labeledFamilyCenterId}
        date={openModal.date}
        handleClose={() => setOpenModal(prevState => ({ ...prevState, open: false }))}
      />
    </S.TableWrapper>
    :
    <DetailMessage>{intl.get('bottles.noItemsValidationMessage')}</DetailMessage>
  )
}

TableView.propTypes = {
  data: PropTypes.shape({
    BusinessUnits: PropTypes.shape({
      length: PropTypes.number,
      map: PropTypes.func
    })
  }).isRequired,
  height: PropTypes.any.isRequired,
  width: PropTypes.any.isRequired
}
