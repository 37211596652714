import styled from 'styled-components'

const StyledModalShelfLife = styled.div`
  .content {
    height: 70vh;
    overflow-x: auto;
    padding-bottom: 2px;
  }

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 70px;

    .header-icon {
      padding-left: 0;
    }
  }

  .button-container {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .button-selected {
    background-color: #b3cef4;
  }
  `

export default StyledModalShelfLife