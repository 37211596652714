export const getFontColor = (simulado, isActive) => {

  if (simulado) {
    return '#FFAA00'
  } else if (isActive && !simulado) {
    return "white"
  } else {
    return "black"
  }
}

export const getKeys = (type) => {
  let supplierKeys = [
    "Date",
    "SupplierInitialStock",
    "SupplierProduction",
    "OutgoingMerchandiseSupplier",
    "SupplierFinalStock"
  ]

  let businesUnitKeys = [
    "Date",
    "BreweryInitialStock",
    "BreweryMerchandiseEntry",
    "RealProduction",
    "Necessity",
    "WeeklyPlan",
    "BreweryFinalStock"
  ]

  return type === 'supplier' ? supplierKeys : businesUnitKeys
}

export const shiftElement = (keys) => {
  const newKeys = [...keys]
  newKeys.shift()
  return newKeys
}

export const getSupplierName = (suppliers) =>  suppliers.map(supplier => supplier.Name.toUpperCase())
