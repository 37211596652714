import styled from 'styled-components'

const StyledProgressBar = styled.div`

    .vertical {
        display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: 50px;
        border: 2px solid var(--color-contrast-white);
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        box-shadow: inset 0px 4px 6px #ccc;

        &.Default {
            width: 40px;
            height: 40px;
        }

        &.Small {
            width: 30px;
            height: 30px;
        }
    }

    .progress-bar-info {
        background-color: #3ea8de;
    }

    .progress-bar {
        float: left;
        width: 50px;
        height: 100%;
        line-height: 20px;
        color: var(--color-contrast-white);
        text-align: center;
        -webkit-transition: width .6s ease;
        -o-transition: width .6s ease;
        transition: width .6s ease;
    }

    .progress {
        overflow: hidden;
        background-color: #f5f5f5;
        -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
        box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
    }
    
    div {
        display: block;
    }
`

export default StyledProgressBar
