import styled from "styled-components";

export const Wrapper = styled.div`
  clb-input-number {
    width: 340px;
    padding-bottom: 24px;
  }

  clb-select {
    display: block;
    width: 400px;
    color: var(--color-neutral-600);
  }
`;

export const Title = styled.div`
  color: var(--color-neutral-600);
  font-size: 24px;
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  line-height: 150%;
  padding-bottom: 12px;
`;

export const WrapperAvailableDeliverySlots = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-top: 24px;

  label{
    font-weight: bold;
  }
`;

export const WrapperInputNumber = styled.div`
  padding-bottom: 24px;

  p {
    display: inline-flex;
    flex-direction: row;
    color: var(--color-neutral-600);
    gap: var(--spacing-size-5xs);
    font-weight: var(--font-weight-semibold);
    margin: 0px 0px 8px 0px;
  }

  input {
    width: 340px;
    height: 48px;
    border-radius: 4px;
  }

  span {
    display: inline-flex;
    flex-direction: row;
    gap: var(--spacing-size-5xs);
    font-size: var(--font-size-sm);
    margin: 8px 0px 0px 0px;
    color: var(--color-neutral-600);
  }
`
