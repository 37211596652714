import styled from 'styled-components'

export const Wrapper = styled.div`
  height: calc(100vh - 100px);
  display: flex;
  justify-content: space-between;

  .content-button{
    span{
      margin-top: 0px !important;
      text-transform: capitalize;
      font-weight: var(--font-weight-semibold);
    }
  }
`

export const WrapperCards = styled.div`
  padding: 0px 0px 10px 0px;
  width: calc(100% - 427px);
  overflow-y: auto;

  .shadow {
    position: sticky;
    box-shadow: 0px -1px 2px 2px rgb(0 0 0 / 10%);
    top: 66px;
    z-index: 1;
    margin-top: -2px;
}
`
export const WrapperTable = styled.div`
  width: calc(100% - 427px);
`
