import React from 'react'
import { Checkbox } from "@hbsis.uikit/react";
import { EditIcon } from "components/icons/icn-index.icon";
import { CheckboxStyle } from '../styled';
import { formataExibicaoMaterialParaTabela, validValue } from './format'
import intl from 'react-intl-universal'
import moment from 'moment'
import { formatNumber } from 'utils/format';

const columnStyle = {
  dataStyle: { flex: 1 },
  headerStyle: { flex: 1 }
};

const normalizeCol = (value) => (
  typeof value === 'function' ? value() :
    <span title={value} >
      {validValue(value)}
    </span >
)

const normalizeDate = (value) => (
  typeof value === 'function' ?
    value : moment(value).format('L')
)

const normalizeQuantity = (value) => (
  <span title={value} >
    {formatNumber(value)}
  </span >
)

const normalizeInterval = (value) => (
  typeof value === 'function' ? value : formatInterval(value)
)

const formatInterval = (value) => {
  let split = ""
  if(value !== "") {
    split = value.split(":")
    split = `${split[0]}:${split[1]}`
  }
  return split
}


export const getColumns = (selectAll) => (
  [
    {
      ...columnStyle,
      labelHeader: <CheckboxStyle title="">
        <Checkbox
          name='checkbox-all'
          onChange={selectAll}
          data-testid='checkbox-select-all'
        />
      </CheckboxStyle>,
      attribute: "selectBox",
      headerStyle: { flex: 0.45 },
      dataStyle: { flex: 0.45 },
      align: "left",
      sortable: true,
      visible: true,
      renderCol: row => row.selectBox()
    },
    {
      ...columnStyle,
      labelHeader: intl.get('loadOptimization.list.columns.Plant'),
      attribute: "Plant",
      headerStyle: { flex: 0.6 },
      dataStyle: { flex: 0.6 },
      align: "left",
      sortable: true,
      visible: true,
      renderCol: row => normalizeCol(row.Plant)
    },
    {
      ...columnStyle,
      labelHeader: intl.get('loadOptimization.list.columns.Line'),
      attribute: "Line",
      headerStyle: { flex: 0.6 },
      dataStyle: { flex: 0.6 },
      align: "left",
      sortable: true,
      visible: true,
      renderCol: row => normalizeCol(row.Line)
    },
    {
      ...columnStyle,
      labelHeader: intl.get('loadOptimization.list.columns.Material'),
      attribute: "Material",
      align: "left",
      sortable: true,
      visible: true,
      headerStyle: { flex: 3 },
      dataStyle: { flex: 3 },
      renderCol: row => normalizeCol(formataExibicaoMaterialParaTabela(row.MaterialNumber, row.MaterialDescription))
    },
    {
      ...columnStyle,
      labelHeader: intl.get('loadOptimization.list.columns.QtyinPal'),
      attribute: "QtyinPal",
      headerStyle: { flex: 0.8 },
      dataStyle: { flex: 0.8 },
      align: "left",
      sortable: true,
      visible: true,
      renderCol: row => normalizeCol(row.QuantityPallets)
    },
    {
      ...columnStyle,
      labelHeader: intl.get('loadOptimization.list.columns.PalletUom'),
      attribute: "PalletUom",
      headerStyle: { flex: 0.7 },
      dataStyle: { flex: 0.7, color: 'var(--color-neutral-500)' },
      align: "left",
      sortable: true,
      visible: true,
      renderCol: row => normalizeCol(row.PalletUMS)
    },
    {
      ...columnStyle,
      labelHeader: intl.get('loadOptimization.list.columns.Quantity'),
      attribute: "Quantity",
      align: "left",
      dataStyle: { flex: 1, color: 'var(--color-neutral-500)' },
      sortable: true,
      visible: true,
      renderCol: row => normalizeQuantity(row.Quantity)
    },
    {
      ...columnStyle,
      labelHeader: intl.get('loadOptimization.list.columns.Unit'),
      attribute: "Unit",
      align: "left",
      headerStyle: { flex: 0.5 },
      dataStyle: { flex: 0.5, color: 'var(--color-neutral-500)' },
      sortable: true,
      visible: true,
      renderCol: row => normalizeCol(row.Unit)
    },
    {
      ...columnStyle,
      labelHeader: intl.get('loadOptimization.list.columns.DeliveryDate'),
      attribute: "DeliveryDate",
      align: "left",
      sortable: true,
      visible: true,
      renderCol: row => normalizeCol(normalizeDate(row.DeliveryDate))
    },
    {
      ...columnStyle,
      labelHeader: intl.get('loadOptimization.list.columns.Interval'),
      attribute: "Interval",
      align: "left",
      sortable: true,
      visible: true,
      renderCol: row => normalizeCol(normalizeInterval(row.Interval))
    },
    {
      ...columnStyle,
      labelHeader: intl.get('loadOptimization.list.columns.Shift'),
      attribute: "Shift",
      align: "left",
      headerStyle: { flex: 0.5 },
      dataStyle: { flex: 0.5 },
      sortable: true,
      visible: true,
      renderCol: row => normalizeCol(row.Shift)
    },
    {
      ...columnStyle,
      labelHeader: intl.get('loadOptimization.list.columns.Startup'),
      attribute: "Startup",
      align: "left",
      sortable: true,
      visible: true,
      renderCol: row => normalizeCol(row.Startup)
    },
    {
      ...columnStyle,
      labelHeader: intl.get('loadOptimization.list.columns.ScheduleAgreement'),
      attribute: "ScheduleAgreement",
      align: "left",
      sortable: true,
      visible: true,
      renderCol: row => normalizeCol(row.ScheduleAgreement)
    },
    {
      ...columnStyle,
      labelHeader: intl.get('loadOptimization.list.columns.SupplierName'),
      attribute: "SupplierName",
      align: "left",
      sortable: true,
      visible: true,
      renderCol: row => normalizeCol(row.SupplierName)
    },
    {
      ...columnStyle,
      labelHeader: intl.get('loadOptimization.list.columns.SupplierCode'),
      attribute: "SupplierCode",
      dataStyle: { flex: 1, color: 'var(--color-neutral-500)' },
      align: "left",
      sortable: true,
      visible: true,
      renderCol: row => normalizeCol(row.SupplierCode)
    },
    {
      ...columnStyle,
      labelHeader: intl.get('loadOptimization.list.columns.SupplierLocation'),
      attribute: "SupplierLocation",
      dataStyle: { flex: 1, color: 'var(--color-neutral-500)' },
      align: "left",
      sortable: true,
      visible: true,
      renderCol: row => normalizeCol(row.SupplierLocation)
    },
    {
      ...columnStyle,
      labelHeader: () => <div title="" style={{ paddingTop: '6px' }} ><EditIcon width="15px" height="15px" /></div>,
      attribute: "edit",
      headerStyle: { flex: 0.3 },
      dataStyle: { flex: 0.3 },
      align: "left",
      sortable: true,
      visible: true,
      renderCol: row => row.edit()
    },
  ]
)
