import PropTypes from "prop-types"
import React from 'react'
import { LoadCompositionBuildingProvider } from './context/load-composition-building-context';
import LoadCompositionBuilding from './loadCompositionBuilding';

const LoadCompositionBuildingIndex = ({ match, history }) => (
  <LoadCompositionBuildingProvider params={match.params} history={history}>
    <LoadCompositionBuilding />
  </LoadCompositionBuildingProvider>
)

LoadCompositionBuildingIndex.propTypes = {
  history: PropTypes.any.isRequired,
  match: PropTypes.shape({
    params: PropTypes.any
  }).isRequired
}

export default LoadCompositionBuildingIndex;
