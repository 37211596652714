import PropTypes from "prop-types"
import React, { Component } from 'react'
import ContraPropostaFornecedor from './../contraproposta-fornecedor'
import { Button } from 'components/uikit-adapter/index'
import { withRouter } from 'react-router-dom'
import intl from 'react-intl-universal'
import './aguardando-composicaocarga.css'
import EditarPedidoEmNegociacao from '../editar-pedido-em-negociacao'

class AguardandoComposicaoCarga extends Component {
  static propTypes = {
    canShowComposicaoCarga: PropTypes.any.isRequired,
    canShowContraproposta: PropTypes.any.isRequired,
    canShowUpdateOrder: PropTypes.any.isRequired,
    disabledCounterProposal: PropTypes.any.isRequired,
    disabledLoadComposition: PropTypes.any.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func
    }).isRequired,
    idPedido: PropTypes.any.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      showCounterOffer: false,
      showUpdateOrder: false
    }

    this.showCounterOffer = this.showCounterOffer.bind(this)
    this.hideCounterOffer = this.hideCounterOffer.bind(this)
    this.showUpdateOrder = this.showUpdateOrder.bind(this)
    this.hideUpdateOrder = this.hideUpdateOrder.bind(this)
    this.showDetailsComposition = this.showDetailsComposition.bind(this)
  }

  showCounterOffer() {
    this.setState({
      showCounterOffer: true
    })
  }

  hideCounterOffer() {
    this.setState({
      showCounterOffer: false
    })
  }

  showUpdateOrder() {
    this.setState({
      showUpdateOrder: true
    })
  }

  hideUpdateOrder() {
    this.setState({
      showUpdateOrder: false
    })
  }

  showDetailsComposition() {
    this.props.history.push(`/composicao/${this.props.idPedido}`)
  }

  render() {
    return (
      <div>
        {
          this.state.showCounterOffer &&
          <ContraPropostaFornecedor
            {...this.props}
            handleCancel={this.hideCounterOffer}
            isEmergencial
            idPedido={this.props.idPedido}
          />
        }

        {
          this.state.showUpdateOrder &&
          <EditarPedidoEmNegociacao
            {...this.props}
            handleCancel={this.hideUpdateOrder}
            idPedido={this.props.idPedido}
          />
        }

        {
          !this.state.showCounterOffer &&
          <div className='event-action'>
            {
              this.props.canShowContraproposta &&
              <Button
                disabled={this.props.disabledCounterProposal}
                value={intl.get('stocks.timeline.makeCounterproposal')}
                onClick={this.showCounterOffer}
                type="secondary"
                className="button button-counter-proposal"
              />
            }
            {
              this.props.canShowUpdateOrder && !this.state.showUpdateOrder &&
              <Button
                disabled={!this.props.canShowUpdateOrder}
                value={intl.get('stocks.timeline.editOrder')}
                onClick={this.showUpdateOrder}
                type="secondary"
                className="button"
              />
            }
            {
              this.props.canShowComposicaoCarga &&
              <Button
                disabled={this.props.disabledLoadComposition}
                value={intl.get('stocks.timeline.createLoadComposition')}
                onClick={this.showDetailsComposition}
                type="primary"
                className="button button-create-load-composition"
              />
            }
          </div>
        }
      </div>
    )
  }
}

export default withRouter(AguardandoComposicaoCarga)
