import { validatesArrayHasValue } from "utils/custom-functions"
import intl from 'react-intl-universal'

export const getDoorOptions = (center) => {
  if (!center || !validatesArrayHasValue(center.Docas)) {
    return [generateDoorOption("N/A")]
  }

  let data = []

  data = center.Docas.map(x => ({
    label: x,
    value: x
  }))

  return [generateDoorOption(intl.get('commons.undefined')), ...data]
}

export const generateDoorOption = (label, value) => ({
  label: label,
  value: value
})

export const getValueDoor = (filter) => {
  if (!filter?.center) {
    return generateDoorOption(intl.get("commons.pickBrewery"))
  }
  if (!filter?.door?.label) {
    return generateDoorOption(validatesArrayHasValue(filter.center.Docas) ? intl.get('commons.undefined') : "N/A")
  }
  return filter?.door
}

export const isDisabledSelectDoor = (filter) => {
  const label = getValueDoor(filter).label
  return label === intl.get("commons.pickBrewery") || label === 'N/A';
}