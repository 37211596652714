import React from "react";

const IconFilterInactive = () => (
  <svg xmlns="https://www.w3.org/2000/svg" width={24} height={24}>
    <title>{"089F7B8B-B2A5-4AFE-B5C0-19BA573D28AC"}</title>
    <path
      fill="var(--color-neutral-500)"
      fillRule="evenodd"
      strokeWidth={2}
      d="M16.084 11.723C14.165 14.6 13 16.426 13 17.5v1.692l-1 .5V17.5c0-1.074-1.165-2.9-3.084-5.777-.782-1.173-1.674-2.514-2.264-3.584 1.467.583 3.703.86 5.848.86s4.381-.277 5.848-.86c-.59 1.07-1.482 2.41-2.264 3.584M12.5 5C16.788 5 19 6.052 19 6.5c0 .449-2.212 1.5-6.5 1.5S6 6.949 6 6.5C6 6.052 8.212 5 12.5 5m0-1C8.888 4 5 4.783 5 6.5c0 1.074 1.165 2.899 3.084 5.778C9.383 14.227 11 16.652 11 17.5v3a.503.503 0 0 0 .5.5.507.507 0 0 0 .224-.052l2-1A.501.501 0 0 0 14 19.5v-2c0-.848 1.617-3.273 2.916-5.222C18.835 9.398 20 7.574 20 6.5 20 4.783 16.112 4 12.5 4"
    />
  </svg>
);
export { IconFilterInactive };