import React from 'react'
import { BottlesProvider, Bottles as BottlesComponent } from 'features/bottles'

const Bottles = () => (
  <BottlesProvider>
    <BottlesComponent />
  </BottlesProvider>
)

export default Bottles
