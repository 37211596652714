import { Checkbox } from '@hbsis.uikit/react'
import { Link } from 'react-router-dom'
import LinkCode from 'components/link-code-span'
import { rulesForCheckbox, rulesForCheckboxAll } from './rules'
import { getValue } from './utils'
import * as S from './styled'
import { ClbIcon, ClbTooltip } from '@celebration/design-system-react'
import PropTypes from 'prop-types'

const TableList = ({
  data,
  columns,
  onChange,
  borderColor,
  selected,
  action,
  scope
}) => {

  const renderItem = (item, record) => (
    <S.TableData key={item.prop}>
      {record[item.prop] && item.hasAction ?
        <Link to='#' style={{ display: 'contents' }}>
          <LinkCode onClick={() => action(record)}>
            {record[item.prop]}
          </LinkCode>
        </Link>
        :
        getValue(record[item.prop])
      }
    </S.TableData>
  )

  const renderColumn = (column) => {
    if(!column.tooltip){
      return <span>{column.name}</span>;
    }


    return (
      <S.TooltipColumn>
        <span>{column.name}</span>
        <ClbTooltip label={column.tooltip} position={column.tooltipPosition}>
          <ClbIcon
            icon={"Info"}
            size='sm'
            data-testid={'tooltip'}
          />
        </ClbTooltip>
      </S.TooltipColumn>
    );
  }

  return (
    < S.Table borderColor={borderColor} >
      <S.TableRow>
        {columns.map(column => (
          column.visible &&
          <S.TableHeader key={column.prop}>
            {
              column.prop === 'Checkbox' ?
                <S.Container>
                  <Checkbox
                    style={{backgroundColor: 'red'}}
                    className='checkbox'
                    role="checkbox-all"
                    name={`checkbox-all`}
                    checked={rulesForCheckboxAll(data, selected, scope)}
                    onChange={(e) => onChange(e.target.checked)}
                    data-testid="checkbox-all"
                  />
                </S.Container>
                :
                renderColumn(column)
            }
          </S.TableHeader>
        ))}
      </S.TableRow>
      {
        data.map((record) => (
          <Link
            key={record.Id}
            to={record.UrlRedirect}
            className='table-row-data'
          >
            {columns.map(column => (
              column.prop === 'Checkbox' ?
                <Link key={record.Id}
                  to='#'
                  style={{ display: 'contents' }}
                >
                  <S.TableData
                    displayTableCell
                    onClick={() => onChange(null, record)}
                  >
                    <S.Container>
                      <Checkbox
                        className='checkbox'
                        role={`checkbox-${record.Id}`}
                        name={`checkbox-${record.Id}`}
                        data-testid={`checkbox-${record.Id}`}
                        checked={rulesForCheckbox(record, selected, scope)}
                      />
                    </S.Container>
                  </S.TableData>
                </Link>
                : column.visible && renderItem(column, record)
            ))}
          </Link>
        ))
      }
    </S.Table >
  )
}

TableList.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  onChange: PropTypes.func,
  borderColor: PropTypes.string,
  selected: PropTypes.any,
  action: PropTypes.any,
  scope: PropTypes.any,
}

export default TableList
