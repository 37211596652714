import { useEffect, useState } from 'react'
import Filters from 'components/filter';
import * as service from 'components/filters/filter-cards/filtro-cards.service';
import { formatErrorMessage } from 'utils/handle-error';
import { builderRequestDto } from './utils';
import { dateRangeFilter, hourRangeFilter, inputsMultiFilter, selectsFilter, switchesFilter } from './data-filter';
import { isUseFilterDefault } from 'utils/user';
import { searchBusinessUnit, searchMaterialGroup, searchMaterials, searchSuppliers } from 'services/filters.service';
import { ordersFiltersDefault } from 'features/orders/components/orders-filter/utils';
import PropTypes from 'prop-types'

export const OrdersFilter = ({ handleSearch, isOrderVolume, filtersDefault }) => {
  const [selectsFilters, setSelectsFilters] = useState(selectsFilter());
  const [normalizedFilters, setNormalizedFilters] = useState(filtersDefault);

  const proccessData = (filtersData) => {
    const _filters = selectsFilters.map(filter => ({
      ...filter,
      options: filtersData.find(x => x.name === filter.name).data,
    }));

    setSelectsFilters([..._filters]);
  }

  const proccessErro = (e) => {
    console.error(formatErrorMessage(e));
  }

  const loadInitialData = async () => {
    try {
      const [status, units, suppliers, materiais, materialGroup] = await Promise.all([
        isOrderVolume ? service.searchOrdersVolumeStatus() : service.searchOrdersStatus(),
        searchBusinessUnit(isUseFilterDefault()),
        searchSuppliers(isUseFilterDefault()),
        searchMaterials(isUseFilterDefault()),
        searchMaterialGroup(isUseFilterDefault())
      ]);

      const materialFormat = materiais.map((_) => ({ ..._, Id: _.MaterialNumber }))

      const filtersData = [
        { name: 'BusinessUnitIds', data: units },
        { name: 'SupplierIds', data: suppliers },
        { name: 'MaterialsNumber', data: materialFormat },
        { name: 'Status', data: status },
        { name: 'MaterialGroupIds', data: materialGroup }
      ];

      proccessData(filtersData);

      if(!isOrderVolume && normalizedFilters !== ordersFiltersDefault) {
        const _filters = {
          BusinessUnitIds: units.filter(x => filtersDefault.BusinessUnitIds.includes(x.Id)),
          SupplierIds: suppliers.filter(x => filtersDefault.SupplierIds.includes(x.Id)),
          MaterialsNumber: materialFormat.filter(x => filtersDefault.MaterialsNumber.includes(x.MaterialNumber)),
          MaterialGroupIds: materialGroup.filter(x => filtersDefault.MaterialGroupIds.includes(x.Id)),
          Status: status.filter(x => filtersDefault.Status.includes(x.Id)),
          UseFilterDefault: filtersDefault.UseFilterDefault,
          OrdersId: filtersDefault.OrdersId,
          PendingItems: filtersDefault.PendingItems,
          DeliveryDateMax: filtersDefault.DeliveryDateMax,
          DeliveryDateMin: filtersDefault.DeliveryDateMin,
          CollectDateMax: filtersDefault.CollectDateMax,
          DeliveryTimeMin: filtersDefault.DeliveryTimeMin,
          DeliveryTimeMax: filtersDefault.DeliveryTimeMax,
          WmsTransactionsId: filtersDefault.WmsTransactionsId,
          PoNumber: filtersDefault.PoNumber,
          _page: filtersDefault._page,
          _size: filtersDefault._size
        }
        setNormalizedFilters(_filters)
      }
    } catch (e) {
      proccessErro(e);
    }
  }

  const handleClickSearch = (filters) => {
    const _filter = builderRequestDto(filters)
    handleSearch(_filter)
  }

  useEffect(() => {
    loadInitialData();
  }, [])

  return (
    <div>
      <Filters
        handleClear={() => handleSearch(ordersFiltersDefault)}
        handleSearch={handleClickSearch}
        selectsFilters={selectsFilters}
        filtersDefault={normalizedFilters}
        tooglesFilters={switchesFilter()}
        inputMultiFilters={inputsMultiFilter(isOrderVolume)}
        dateRangeFilters={dateRangeFilter()}
        hourRangeFilters={hourRangeFilter()}
      />
    </div>
  )
}

OrdersFilter.propTypes = {
  handleSearch: PropTypes.func,
  isOrderVolume: PropTypes.bool,
  filtersDefault: PropTypes.shape({
    BusinessUnitIds: PropTypes.array,
    SupplierIds: PropTypes.array,
    MaterialsNumber: PropTypes.array,
    MaterialGroupIds: PropTypes.array,
    Status: PropTypes.array,
    UseFilterDefault: PropTypes.bool,
    OrdersId: PropTypes.string,
    PendingItems: PropTypes.string,
    DeliveryDateMax: PropTypes.string,
    DeliveryDateMin: PropTypes.string,
    CollectDateMax: PropTypes.string,
    DeliveryTimeMin: PropTypes.string,
    DeliveryTimeMax: PropTypes.string,
    WmsTransactionsId: PropTypes.string,
    PoNumber: PropTypes.string,
    _page: PropTypes.string,
    _size: PropTypes.string,
  })
}
