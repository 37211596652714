import PropTypes from "prop-types"
import { useEffect, useState } from 'react'
import intl from 'react-intl-universal'
import { withStyles } from '@material-ui/styles'
import { Input } from '@hbsis.uikit/react'
import { Button } from 'components/uikit-adapter'
import DateRange from 'components/date-range'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { OrdersChangeDateTable } from './table'
import { useGlobalContext } from 'hooks/useGlobalContext'
import { ISO_DATE_FORMAT } from 'utils/format-date'
import moment from 'moment'
import * as S from './styled'
import { parseFloatByLocale } from 'locales/utils'
import { getValidationForInTransitStatus } from './utils'

const StyledDialogContent = withStyles(() => ({
  root: {
    borderBottom: 'none'
  }
}))(DialogContent)

export const OrdersChangeDateModal = ({ open, close, data }) => {
  const { startOperation, showFeedback } = useGlobalContext()

  const [alterableItems, setAlterableItems] = useState([])
  const [dates, setDates] = useState({
    collect: null,
    delivery: null
  })

  const [notes, setNotes] = useState('')
  const [updateQuantity, setUpdateQuantity] = useState(false);

  const handleNewCollectDate = (collect) =>
    setAlterableItems(alterableItems.map((row) => {
      const isInTransitStatus = getValidationForInTransitStatus(row.OrderStatus)
      return {
        ...row,
        newCollectDate: isInTransitStatus ? null : collect,
        newDeliveryDate: isInTransitStatus ? null : moment(collect).add(row.TransitTime, 'd')
      }
    }))

  const handleNewDeliveryDate = (delivery) =>
    setAlterableItems(alterableItems.map((row) => {
      const isInTransitStatus = getValidationForInTransitStatus(row.OrderStatus)
      return {
        ...row,
        newCollectDate: isInTransitStatus ? null : moment(delivery).subtract(row.TransitTime, 'd'),
        newDeliveryDate: delivery
      }
    }))

  const handleNewQuantity = (newOrderQuantity, index) => {
    setAlterableItems(alterableItems.map((row, _index) => ({
      ...row,
      newOrderQuantity: index === _index ? newOrderQuantity : alterableItems[_index].newOrderQuantity 
    })))
  }

  const cleanDates = () =>
    setAlterableItems(alterableItems.map(row => ({ ...row, newCollectDate: null, newDeliveryDate: null })))


  const clearInputs = () => {
    setDates({ collect: null, delivery: null })
    setNotes('')
    setUpdateQuantity(false)
  }

  const handleClose = () => {
    clearInputs()
    close()
  }

  const validateNewPickUpDate = () => {
    const isPickUpDateValid  = alterableItems.every(item => 
      {
        if(getValidationForInTransitStatus(item.OrderStatus))
        {
          return (item.newCollectDate == null);
        }
        else
        {
          if(!item.newCollectDate)
            return true;

          return moment(item.newCollectDate).format("YYYY-MM-DD") >= moment().format("YYYY-MM-DD");
        }
      })
    
    let _errorMessage = '';

    if(!isPickUpDateValid) 
      _errorMessage = intl.get("feedbacks.messageNewPickupDateValidation2");
    
    return {
      errorMessage: _errorMessage,
      result: isPickUpDateValid
    }
}

  const validateNewQuantity = () => {
    const isQuantityValid = !alterableItems.find((x) => parseFloatByLocale(x.newOrderQuantity) === 0);

    let _errorMessage = '';

    if(!isQuantityValid)
      _errorMessage = intl.get("feedbacks.messageNewQuantityValidation");

    return {
      errorMessage: _errorMessage,
      result: isQuantityValid
    }
  }

  const buildBody = (item) =>{
    let _body = { OrderId: item.Id, Status: item.OrderStatus };
        
    _body = {..._body,
      PickupDate: item.newCollectDate ? moment(item.newCollectDate).format() : null, 
      DeliveryDate: item.newDeliveryDate ? moment(item.newDeliveryDate).format() : null}

    if(notes) _body = { ..._body, Notes: notes };
    if(item.newOrderQuantity) _body = { ..._body, Quantity: parseFloatByLocale(item.newOrderQuantity) };

    return _body
  }

  const handleSave = () => {
    const isPickUpDateValid = validateNewPickUpDate();
    const isQuantityValid = validateNewQuantity();

    if(isPickUpDateValid.result && isQuantityValid.result) {
    let requests = alterableItems
    .map(item => {
      let _body = buildBody(item);
  
          if(Object.keys(_body).length === 1) return;
          
          return {
          API: `orders/${item.Id}:update-dates`,
          method: 'patch',
          body: _body,
          errorId: item.Id
          }
        });
  
      requests = requests.filter(item => item);
      const text = intl.get('orders.operationText01')
      startOperation(requests, text)
      clearInputs()
      close()
    } else {
      showFeedback(isPickUpDateValid.errorMessage, isQuantityValid.errorMessage);
    }
  }

  useEffect(() => {
    if (dates.collect) {
      handleNewCollectDate(dates.collect)
    }
    else if (dates.delivery) {
      handleNewDeliveryDate(dates.delivery)
    } else {
      cleanDates()
    }
  }, [dates])

  useEffect(() => {
    setAlterableItems(data)
  }, [data])

  useEffect(() => {
    const hasQuantityChanged = alterableItems.find(x => x.newOrderQuantity && parseFloatByLocale(x.newOrderQuantity) !== 0);
    setUpdateQuantity(!!hasQuantityChanged)
  }, [alterableItems])

  return (
    <Dialog
      PaperProps={{
        style: { overflow: 'visible' }
      }}
      fullWidth
      maxWidth='lg'
      open={open}
    >
      <S.DialogTitle>
        <S.DialogTitleText>
          {intl.get('orders.changeOrder')}
        </S.DialogTitleText>
        <S.DialogTitleIcon onClick={handleClose} />
      </S.DialogTitle>
      <StyledDialogContent style={{ overflow: 'visible' }} dividers>
        <S.DialogContentText>{intl.get('orders.selectedOrders')}:</S.DialogContentText>
        <OrdersChangeDateTable
          data={alterableItems}
          handleNewQuantity={handleNewQuantity}
        />
        <S.DialogContentSection>
          <div className='dialog__content--text'>
            <h4>{intl.get('orders.operationHeader')}</h4>
            <p>{intl.get('orders.operationContent')}</p>
          </div>
          <div className='dialog__content--action'>
            <DateRange
              labelDe={intl.get('stocks.informAdvanceOrder.newCollectionDate')}
              labelAte={intl.get('stocks.informAdvanceOrder.newDeliveryDate')}
              dataDe={dates.collect}
              dataAte={dates.delivery}
              onChangeDataDe={(date) => setDates({ collect: date, delivery: null })}
              onChangeDataAte={(date) => setDates({ collect: null, delivery: date })}
              minDate={moment().format(ISO_DATE_FORMAT)}
            />
            <Input
              className='input'
              label={intl.get('commons.observation')}
              value={notes}
              onChange={(event) => setNotes(event.target.value)}
              data-testid='input-note'
            />
          </div>
        </S.DialogContentSection>
      </StyledDialogContent>
      <S.DialogActions>
        <Button
          type='default'
          value={intl.get('geral.buttonsDefault.cancel')}
          onClick={handleClose}
        />
        <Button
          type='primary'
          value={intl.get('commons.confirmUpdate')}
          onClick={handleSave}
          disabled={(!dates.collect && !dates.delivery && !updateQuantity)}
        />
      </S.DialogActions>
    </Dialog>
  )
}

OrdersChangeDateModal.propTypes = {
  close: PropTypes.func.isRequired,
  data: PropTypes.any.isRequired,
  open: PropTypes.any.isRequired
}
