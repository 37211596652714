import React, { Component } from 'react'
import PropTypes from 'prop-types'

import intl from 'react-intl-universal';

import StyledIndicator from './styledIndicator.js'

class Indicator extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { value, total, type, legend } = this.props;

    return (
      <StyledIndicator title={legend}>
        <span className={`description ${type}`}><span className={`value-description ${type}`}>{value}</span> / {total}</span>
        <br />
      <span className={`description-taks ${type}`}>{intl.get("tasks.finished")}</span>
      </StyledIndicator>
    )
  }
}

Indicator.propTypes = {
  value: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  type: PropTypes.string,
  legend: PropTypes.string,
}

export default Indicator
