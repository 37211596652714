import styled from 'styled-components'

const StyledMaterialSupplierForm = styled.div`
  background: var(--color-contrast-white);
  padding: 15px 20px 0px;

  .Select-value,
  .Select-placeholder {
    padding: 0 10px!important;
  }

  .select-origem-label {
    color: var(--color-contrast-brand);
    font-size: 12px;
    font-weight: var(--font-weight-semibold);
    text-transform: uppercase;
    margin-left: 10px;
  }

  .select-origem .radio-items-list .radio-item {
    margin: 0;
  }

  .select-origem .radio-items-list .radio-item input[type="radio"] + label {
    color: var(--color-contrast-brand);
    font-size: 12px;
    font-weight: var(--font-weight-semibold);
    text-transform: uppercase;
    margin-top:3px;
  }

  select-origem .radio-items-list .radio-item input[type="radio"] + label:before {
    width: 5px;
    height: 5px;
  }

  .container {
    width: 97% !important;
    margin-top: -16px;
    border: 1px solid var(--color-neutral-300);
  }

  .label-style {
    background: white;
    margin-left: 10px;
  }

  .grupo-origem-interna {
    font-size: 11px;
  }

  .toogle-create-load-composition-automatic{
    position: relative;
    z-index: 0;
  }

  .nowrap-label {
    label{
      white-space: nowrap;
    }
  }
`;

export default StyledMaterialSupplierForm
