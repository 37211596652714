const EstoqueStatus = {
  0: {
    fontWeight: 'bold',
    color: '#2da78f'
  },
  1: {
    fontWeight: 'bold',
    color: '#393939'
  },
  2: {
    fontWeight: 'bold',
    color: '#ff5e53'
  },
  3: {
    fontWeight: 'normal',
    color: '#393939'
  }
}
export default EstoqueStatus
