import { SituacaoComposicaoCarga } from "models/composicaoCarga/situacaoComposicaoCarga"
import intl from 'react-intl-universal';

export const getLoadCompositionInfosCard = (loadCompositionStatus) => {
  let result = { title: '', color: '', background: '' };
  switch (loadCompositionStatus) {
    case SituacaoComposicaoCarga.Aceito:
      result = { title: intl.get('commons.scheduled'), color: 'var(--color-neutral-100)', background: 'var(--color-brand-300)' }
      break;
    case SituacaoComposicaoCarga.AguardandoRevisaoFornecedor:
      result = { title: intl.get("loadComposition.pendingSupplier"), color: 'var(--color-neutral-100)', background: 'var(--color-feedback-warning-300)' }
      break;
    case SituacaoComposicaoCarga.EmNegociacao:
      result = { title: intl.get("commons.inNegotiation"), color: 'var(--color-neutral-100)', background: 'var(--color-feedback-danger-300)' }
      break;
    case SituacaoComposicaoCarga.NegociacaoEmergencial:
      result = { title: intl.get('commons.emergencyNegotiation'), color: 'var(--color-feedback-danger-500)', background: 'var(--color-neutral-100)' }
      break;
  }
  return result;
}