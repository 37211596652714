import styled from 'styled-components'

 const StyledButtonNewItem = styled.div`  
  width: 40px;
  height: 40px;
    
  .button-new-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-left: 0;
  }
`
export default StyledButtonNewItem
