import React from 'react'
import intl from 'react-intl-universal'
import { Checkbox } from "@hbsis.uikit/react"
import * as S from './styled'

export const getCheckboxsColumn = (checkboxs, updateCheckboxs) => (
  <S.CheckboxsColumn>
    <S.CheckboxColumn>
      <Checkbox
        text={intl.get("bottles.filter.view.EntradaPlanejada")}
        name={intl.get("bottles.filter.view.EntradaPlanejada")}
        data-testid={"EntradaPlanejada"}
        checked={checkboxs.PlannedEntry}
        onChange={() => updateCheckboxs("PlannedEntry", !checkboxs.PlannedEntry)}
      />
      <Checkbox
        text={intl.get("bottles.filter.view.EntradaReal")}
        name={intl.get("bottles.filter.view.EntradaReal")}
        data-testid={"EntradaReal"}
        checked={checkboxs.RealEntry}
        onChange={() => updateCheckboxs("RealEntry", !checkboxs.RealEntry)}
      />
      <Checkbox
        text={intl.get("bottles.filter.view.Consumo")}
        name={intl.get("bottles.filter.view.Consumo")}
        data-testid={"Consumo"}
        checked={checkboxs.Consumption}
        onChange={() => updateCheckboxs("Consumption", !checkboxs.Consumption)}
      />
      <Checkbox
        text={intl.get("bottles.filter.view.Transferencias")}
        name={intl.get("bottles.filter.view.Transferencias")}
        data-testid={"Transferencias"}
        checked={checkboxs.Transfer}
        onChange={() => updateCheckboxs("Transfer", !checkboxs.Transfer)}
      />
    </S.CheckboxColumn>
    <S.CheckboxColumn>
      <Checkbox
        text={intl.get("bottles.filter.view.EstoqueInicial")}
        name={intl.get("bottles.filter.view.EstoqueInicial")}
        data-testid={"EstoqueInicial"}
        checked={checkboxs.InitialStock}
        onChange={() => updateCheckboxs("InitialStock", !checkboxs.InitialStock)}
      />
      <Checkbox
        text={intl.get("bottles.filter.view.EstoqueFinal")}
        name={intl.get("bottles.filter.view.EstoqueFinal")}
        data-testid={"EstoqueFinal"}
        checked={checkboxs.FinalStock}
        onChange={() => updateCheckboxs("FinalStock", !checkboxs.FinalStock)}
      />
    </S.CheckboxColumn>
  </S.CheckboxsColumn>
)
