import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { LABEL_WIDTH } from "./constants";

const StyledTableLabel = styled.div`
  display: flex;
  box-sizing: border-box;

  .etb-label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 5px;
    font-size: 14px;
    font-weight: bold;
    color: var(--color-contrast-white);
    min-width: ${LABEL_WIDTH}px;
    background-color: var(--color-brand-300);
    border-right: 1px solid var(--color-contrast-white);
  }

  .etb-values {
    width: 100%;
    display: flex;

    .etb-column {
      color: var(--color-contrast-white);
      border-right: 1px solid var(--color-contrast-white);
      border-bottom: 1px solid #dbdbdb;
      background-color: var(--color-brand-300);

      &:last-child {
        border-right: none;
      }
    }
  }
`;

const TableLabel = ({ label, labelsList }) => (
  <StyledTableLabel className="etb-row-labels">
    <div className="etb-label">{label}</div>
    <div className="etb-values">
      {labelsList.map((lb, index) => (
        <div key={index} className="etb-column" style={lb.customStyle}>
          {lb.name}
        </div>
      ))}
    </div>
  </StyledTableLabel>
);

TableLabel.propTypes = {
  label: PropTypes.string,
  labelsList: PropTypes.array
};

export default TableLabel;
