import PropTypes from "prop-types"
import React from 'react'
import Paper from 'material-ui/Paper'
import Divisor from '../../../../components/card/body-card/card-divisor'
import intl from 'react-intl-universal'
import moment from 'moment'
import StatusCard from '../../../../components/card/body-card/status-card'
import * as S from './styled'
import { formatNumber, formatWithoutLeadingZeros } from 'utils/format'
import { getCardName } from '../../utils'
import { TextSpan } from 'components/text-label'
import {formatHourTimeOrDefault } from 'utils/delivery-time'

const CardOrderSuggestionAwaitLoadComposition = ({ data, handleClick, openModal, codeDescription }) => {
  const tipo = {
    Etapa: 1,
    Prioridade: data.IdPedido ? 1 : 0,
    Titulo: getCardName(data)
  }

  return (
    <S.Wrapper>
      <Paper zDepth={1} className='card-shop-mester' style={{ height: `250px` }}>
        <div className='card-click-area'>
          <div className='header-card'>
            <StatusCard
              tipo={tipo}
              material={formatWithoutLeadingZeros(data.Material)}
            />
          </div>
          <Divisor />
          <React.Fragment>
            <div className='body-card'>
              <div className='card-label'>
                <TextSpan>{intl.get("stocks.suggestionsListItem.origin")}</TextSpan>
                <TextSpan style={{ maxWidth: '135px' }}>
                  {data.Origem}
                </TextSpan>
              </div>
              <div className='card-label'>
                <TextSpan>{intl.get('commons.destiny')}</TextSpan>
                <TextSpan>{data.Destino}</TextSpan>
              </div>
            </div>
            <Divisor />
          </React.Fragment>
          <div className='body-card'>
            <div className='card-label'>
              <TextSpan>{intl.get('loadComposition.building.orderAmmount')}</TextSpan>
              <TextSpan title={`${formatNumber(data.Quantidade)}${data.QuantidadeUnidadeMedida}-${data.TotalPallets}${data.LotesUnidadeMedida}`}
                className='description-bold'>
                {formatNumber(data.Quantidade)}{data.QuantidadeUnidadeMedida} {` - `}
                <TextSpan className='description-bold' style={{ color: 'var(--color-action-default)' }}>
                  {data.TotalPallets}{data.LotesUnidadeMedida}
                </TextSpan>
              </TextSpan>
            </div>
            <div className='card-label'>
              <TextSpan>{intl.get('stocks.timeline.currentInventory')}</TextSpan>
              <TextSpan className='description-bold'> {formatNumber(data.EstoqueAtual)}{data.EstoqueAtualUnidadeMedida} </TextSpan>
            </div>
          </div>
          <div>
            <Divisor />
            <div className='body-card'>
              <div className='card-label'>
                <TextSpan>{intl.get('commons.deliveryDate')}</TextSpan>
                <TextSpan className='description-bold'>{moment(data.DataEntrega).format("L")}</TextSpan>
              </div>
              <div className='card-label'>
                <TextSpan>{intl.get('commons.originalDeliveryTime')}</TextSpan>
                <TextSpan className='description-bold'>{formatHourTimeOrDefault(data.DeliveryTime)}</TextSpan>
              </div>
              {data.CodigoPedidoSap &&
                <div className='card-label'>
                  <TextSpan>{codeDescription}</TextSpan>
                  <TextSpan className='description-bold'>{data.CodigoPedidoSap}</TextSpan>
                </div>
              }

              <S.ActionsWrapper>
                <S.ButtonEdit
                  type='default'
                  value={intl.get('stocks.timeline.editOrder')}
                  onClick={openModal}
                  className='order-update'
                />
                <S.Button
                  type='primary'
                  value={intl.get('loadComposition.building.addLoad')}
                  onClick={handleClick}
                  className='add-suggestion-load-composition'
                />
              </S.ActionsWrapper>
            </div>
          </div>
        </div>
      </Paper>
    </S.Wrapper >
  )
}

CardOrderSuggestionAwaitLoadComposition.propTypes = {
  codeDescription: PropTypes.any.isRequired,
  data: PropTypes.shape({
    CodigoPedidoSap: PropTypes.any,
    DataEntrega: PropTypes.any,
    DeliveryTime: PropTypes.any,
    Destino: PropTypes.any,
    EstoqueAtual: PropTypes.any,
    EstoqueAtualUnidadeMedida: PropTypes.any,
    IdPedido: PropTypes.any,
    LotesUnidadeMedida: PropTypes.any,
    Material: PropTypes.any,
    Origem: PropTypes.any,
    Quantidade: PropTypes.any,
    QuantidadeUnidadeMedida: PropTypes.any,
    TotalPallets: PropTypes.any
  }).isRequired,
  handleClick: PropTypes.any.isRequired,
  openModal: PropTypes.any.isRequired
}

export default CardOrderSuggestionAwaitLoadComposition
