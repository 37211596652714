import React from "react";

export const ImageSuccess = props => (
  <svg
    {...props}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 507.2 507.2"
    style={{
      enableBackground: "new 0 0 507.2 507.2"
    }}
  >
    <circle fill="#32BA7C" cx="253.6" cy="253.6" r="253.6" />
    <path
      fill="#0AA06E"
      d="M188.8,368l130.4,130.4c108-28.8,188-127.2,188-244.8c0-2.4,0-4.8,0-7.2L404.8,152L188.8,368z"
    />
    <g>
      <path
        fill="var(--color-contrast-white)"
        d="M260,310.4c11.2,11.2,11.2,30.4,0,41.6l-23.2,23.2c-11.2,11.2-30.4,11.2-41.6,0L93.6,272.8
		c-11.2-11.2-11.2-30.4,0-41.6l23.2-23.2c11.2-11.2,30.4-11.2,41.6,0L260,310.4z"
      />
      <path
        fill="var(--color-contrast-white)"
        d="M348.8,133.6c11.2-11.2,30.4-11.2,41.6,0l23.2,23.2c11.2,11.2,11.2,30.4,0,41.6l-176,175.2
		c-11.2,11.2-30.4,11.2-41.6,0l-23.2-23.2c-11.2-11.2-11.2-30.4,0-41.6L348.8,133.6z"
      />
    </g>
  </svg>
);

export const ImageError = props => (
  <svg
    {...props}
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 50 50"
    style={{
      enableBackground: "new 0 0 50 50"
    }}
  >
    <circle fill="#D75A4A" cx="25" cy="25" r="25" />
    <polyline
      style={{
        fill: "none",
        stroke: "var(--color-contrast-white)",
        strokeWidth: 2,
        strokeLinecap: "round",
        strokeMiterlimit: 10
      }}
      points="16,34 25,25 34,16"
    />
    <polyline
      style={{
        fill: "none",
        stroke: "var(--color-contrast-white)",
        strokeWidth: 2,
        strokeLinecap: "round",
        strokeMiterlimit: 10
      }}
      points="16,16 25,25 34,34"
    />
  </svg>
);
