import PropTypes from "prop-types"
import React from 'react'
import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'
import intl from 'react-intl-universal'

const ModalDelete = ({ open, onClose, deleteRows }) => (
  <Dialog
    open={open}
    title={<h2 style={{ color: '#DE4246' }}>{intl.get('autoLoadOptimization.modalDelete.title')}</h2>}
    actions={
      <React.Fragment>
        <FlatButton label={intl.get("geral.buttonsDefault.cancel")} onClick={onClose} />
        <FlatButton label={intl.get('autoLoadOptimization.modalDelete.remove')} onClick={deleteRows} style={{ backgroundColor: '#DE4246', color: 'white' }} />
      </React.Fragment>
    }
    contentStyle={{ width: '30%' }}>
    <span>{intl.get('autoLoadOptimization.modalDelete.confirmRemove')}</span>
  </Dialog>

)

ModalDelete.propTypes = {
  deleteRows: PropTypes.any.isRequired,
  onClose: PropTypes.any.isRequired,
  open: PropTypes.any.isRequired
}

export default ModalDelete;
