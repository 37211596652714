import React from "react";

const WarningIcon = ({ width, height, color }) => (
  <svg xmlns="https://www.w3.org/2000/svg"
    width={width ? width : "24px"}
    height={height ? height : "24px"}
    viewBox="0 0 3.582 17.626"
  >
    <g transform="translate(-42.487)">
      <path className="a" d="M44.278,12.751A1.771,1.771,0,0,1,42.487,11V1.75a1.791,1.791,0,0,1,3.582,0V11A1.771,1.771,0,0,1,44.278,12.751Z"
        fill={color ? color : "var(--color-action-default)"}
      />
      <ellipse className="a" cx="1.535" cy="1.5" rx="1.535" ry="1.5" transform="translate(42.628 14.626)" fill={color ? color : "var(--color-action-default)"}/>
    </g>
  </svg>
);
export { WarningIcon };
