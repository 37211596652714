import styled from 'styled-components'

export const Title = styled.span`
  position: relative;
  top: 20px;
  color: var(--color-action-default);
  font-size: 13px;
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  line-height: normal;
  margin-bottom: 0;
`

export const Wrapper = styled.section`
  width: 90%;
  margin: 10px auto;

  .circular-progress {
    margin: 50px auto;
    width: 40px;
  }

`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
`

export const Input = styled.div`
  width: 80%;
`

export const Button = styled.div`
  display: flex;
  width: 8%;
  justify-content: flex-end;
  flex-flow: column;
`
export const Icon = styled.div`
  width: 37px;
  height: 37px;
  border-radius: 2px;
  border: 1px solid var(--color-neutral-300);
  background: var(--color-contrast-white);
  cursor: pointer;

`
export const Image = styled.img`
  width: 30px;
  padding: 3px;
`