import Fetch from 'utils/fetch'

export const getIncotermsByContracts = (idFornecedor) => {
  return Fetch.get(`/fornecedor/obterIncotermsViaContratos/${idFornecedor}`).then(({ data }) => data)
}

export const getAllExternalSuppliers = (idLabeledFamilyCenter, idMaterial) => {
  return Fetch.get(`/fornecedor/getListByContract/${idLabeledFamilyCenter}/${idMaterial}`).then(({ data }) => data)
}

export const getAllInternalSuppliers = (idLabeledFamilyCenter, idMaterial) => {
  return Fetch.get(`/fornecedor/getFornecedoresInternos/${idLabeledFamilyCenter}/${idMaterial}`).then(({ data }) => data)
}