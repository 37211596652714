
import React from 'react'
import PropTypes from 'prop-types'
import intl from 'react-intl-universal'
import './material-alterado-pedido.css'

const MaterialAlteradoPedido = ({
  descricaoMaterialAnterior,
  descricaoMaterialNovo,
  codigoSap
}) => (
  <div>    
    <div className="negotiation-read-offer">
      <div className="negotiation-read-column">
        <span>{intl.get('stocks.timeline.previousMaterial')}</span>
        <span>{descricaoMaterialAnterior}</span>
      </div>
      <div className="negotiation-read-column">
        <span />
        <span />
      </div>
    </div>   
    <div className="negotiation-read-offer">
      <div className="negotiation-read-column">
        <span>{intl.get('stocks.timeline.currentMaterial')}</span>
        <span>{descricaoMaterialNovo}</span>
      </div>
      <div className="negotiation-read-column">
        <span />
        <span />
      </div>
    </div>   
    {codigoSap &&
    <div className="negotiation-read-offer">
      <div className="negotiation-read-column">
        <span>{intl.get('stocks.timeline.sapCode')}</span>
        <span>{codigoSap}</span>
      </div>
      <div className="negotiation-read-column">
        <span />
        <span />
      </div>
    </div>
    }
  </div>
  );

MaterialAlteradoPedido.propTypes = {
  descricaoMaterialAnterior: PropTypes.string.isRequired,
  descricaoMaterialNovo: PropTypes.string.isRequired,  
  codigoSap: PropTypes.string.isRequired
}

export default MaterialAlteradoPedido