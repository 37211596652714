import { SelectWithSearchAndSelectAll } from "components/select-with-search-select-all";
import { Button } from "components/uikit-adapter";
import { useNotificationContext } from "features/notifications/context";
import { useEffect, useState } from "react";
import { validatesArrayHasValue, verifyIsFiltered } from "utils/custom-functions";
import { getStatusNotificationOptions, initialFilterValues } from "features/notifications/utils";
import { IconFilterActive } from "components/icons/icn-filter-active.icon";
import { IconFilterInactive } from "components/icons/icn-filter-inactive.icon";
import intl from 'react-intl-universal'
import { deleteNotifications, getNotificationsCriticality, getNotificationsTypeOptions } from "services/notifications.service";
import DeadlineControl from "components/deadline-control";
import { LabelButton } from "components/label-button";
import { useToastContext } from "hooks/useToastContext";

import * as S from './styled'
import { ClbButtonIcon } from "@celebration/design-system-react";

export const NotificationCenterFilter = () => {
  const {
    filter: filterContext,
    setFilter: setFilterContext,
    notificationSelected,
    setNotificationSelected,
    handleNotificationsMarkAsRead
  } = useNotificationContext();
  
  const { openToast } = useToastContext();
  const [filterOptions, setFilterOptions] = useState({
    Unread: [],
    Criticalities: [],
    Types: []
  });
  const [filter, setFilter] = useState({ ...filterContext })

  const handleFilter = () => {
    setFilterContext(prevState => ({
      ...filter,
      _page: 0,
      _size: prevState._size
    }))
  };

  const loadFilters = async () => {
    const [_criticality, _notificationType] = await Promise.all([
      getNotificationsCriticality(),
      getNotificationsTypeOptions()
    ]);

    setFilterOptions({
      Unread: getStatusNotificationOptions(),
      Criticalities: _criticality,
      Types: _notificationType
    })
  };

  const updateFilter = (prop, value) => {
    setFilter(prevState => ({ ...prevState, [prop]: value }))
  };

  const clearFilters = () => {
    setFilter({ ...initialFilterValues })
    setFilterContext(initialFilterValues)
  };

  const getIconFilter = () => {
    return verifyIsFiltered(filterContext) ? <IconFilterActive /> : <IconFilterInactive />;
  };

  const handleChangeDates = (minDate, maxDate) => {
    updateFilter("CreatedDateMin", minDate)
    updateFilter("CreatedDateMax", maxDate)
  }

  const handleMarkAsRead = async () => {
    await handleNotificationsMarkAsRead(notificationSelected);
    setNotificationSelected([]);
    openToast(intl.get("notifications.successOnReadNotifications"), "positive", 2000);
    setFilterContext(prevState => ({ ...prevState }));
  }

  const handleDelete = async () => {
    await deleteNotifications({ NotificationIds: notificationSelected })
    setNotificationSelected([]);
    openToast(intl.get("feedbacks.deleteRegister"), "positive", 2000);
    setFilterContext(prevState => ({ ...prevState }));
  }

  useEffect(() => { loadFilters() }, []);

  return (
    <>
      <S.WrapperActions>
        <LabelButton
          onClick={handleMarkAsRead}
        >
          {!validatesArrayHasValue(notificationSelected) ? intl.get("notifications.markAllNotificationsAsRead") : intl.get("notifications.markNotificationsAsRead")}
        </LabelButton>
        <ClbButtonIcon
          icon="Delete"
          size="sm"
          disabled={!validatesArrayHasValue(notificationSelected)}
          onClbClick={handleDelete}
          data-testid='icon-delete-item'
        />
      </S.WrapperActions>
      <S.Wrapper>
        <SelectWithSearchAndSelectAll
          label={intl.get("commons.status")}
          options={filterOptions.Unread}
          onChange={x => updateFilter("Unread", x)}
          values={filter.Unread}
          labelField='Description'
          valueField='Id'
        />
        <SelectWithSearchAndSelectAll
          label={intl.get("commons.criticality")}
          options={filterOptions.Criticalities}
          onChange={x => updateFilter("Criticalities", x)}
          values={filter.Criticalities}
          labelField='Description'
          valueField='Id'
        />
        <SelectWithSearchAndSelectAll
          label={intl.get("master-data.general-configuration.type")}
          options={filterOptions.Types}
          onChange={x => updateFilter("Types", x)}
          values={filter.Types}
          labelField='Description'
          valueField='Id'
        />
        <S.DeadlineControlWrapper>
          <span className="label">{intl.get("commons.period")}</span>
          <DeadlineControl
            TituloColeta='collectLabel'
            TituloEntrega=''
            OnChange={handleChangeDates}
            Coleta={filter.CreatedDateMin}
            DataEntregaDe={filter.CreatedDateMax}
            CadeadoDisabled
            noLockOption
            TransitTime={1}
            showSeparator
          />
        </S.DeadlineControlWrapper>

        <S.ButtonWrapper>
          <Button
            width='166px'
            type="default"
            buttonText={intl.get('filters.cleanSearch')}
            onClick={clearFilters}
            className='btn-filter'
          />
          <Button
            width='166px'
            type="primary"
            buttonText={intl.get('filters.generalFilter')}
            onClick={handleFilter}
            buttonIcon={getIconFilter()}
            className='btn-filter'
          />
        </S.ButtonWrapper>
      </S.Wrapper>
    </>
  );
};