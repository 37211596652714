import PropTypes from "prop-types"
import { useState, useEffect } from 'react'
import Loading from 'components/center-loading'
import { SelectBox, Button } from 'components/uikit-adapter/index'
import DateRangerPicker from 'components/date-range-picker'
import intl from 'react-intl-universal'
import * as S from './styled'
import {
  getBusinessUnitByLabeledFamily,
  getLabeledFamilyByBottle,
  getAllLabeledFamilies,
  getSuppliersByLabeledFamilyAndBusinessUnit,
  getMaterialGroups
} from '../../data-upload.service'
import Feature from 'components/feature'
import { ClbRadioButton, ClbRadioGroup } from '@celebration/design-system-react';

const FilterDataUpload = ({
  onClick,
  isBottles,
  useLabeledFamilies,
  useSuppliers,
  useBusinessUnit,
  useDateRange,
  useActiveMaterial,
  useMaterialGroup,
  limitOptions,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const [options, setOptions] = useState({
    materialGroup: [],
    labeledFamily: [],
    center: [],
    supplier: []
  })

  const [data, setData] = useState({
    materialGroup: [],
    labeledFamily: [],
    center: [],
    supplier: []
  })

  const [period, setPeriod] = useState({
    dataDe: undefined,
    dataAte: undefined
  })

  const [activeMaterial, setActiveMaterial] = useState("activeMaterial")

  const handleOptions = (option, name) =>
    setOptions(prevState => ({ ...prevState, [name]: option }))

  const handleData = (info, name) => {
    setData(prevState => ({ ...prevState, [name]: info }))
  }

  const handlePeriod = (prop, value) =>
    setPeriod(prevState => ({ ...prevState, [prop]: value }))

  const handleActiveMaterial = e => {
    setActiveMaterial(e.detail.value)
  }

  useEffect(() => {
    if (isBottles) {
      if (data.labeledFamily.length > 0) {
        loadCentersFilter(data.labeledFamily)
      }
      else {
        handleData([], 'center')
        handleData([], 'supplier')
      }
    }

  }, [data.labeledFamily])


  useEffect(() => {
    if (isBottles) {
      if (data.center.length > 0) {
        loadSuppliersFilter()
      }
      else {
        handleData([], 'supplier')
      }
    }

  }, [data.center])

  const loadMaterialGroupFilter = async () => {
    const params = {
      useFilterDefault: JSON.parse(localStorage.getItem('filterMyItemsOnly')),
    }
    const response = await getMaterialGroups(params)
    handleOptions(response, 'materialGroup')
  }

  const loadLabeledFamilies = async () => {
    const response = await (isBottles ? getLabeledFamilyByBottle() : getAllLabeledFamilies());
    handleOptions(response, 'labeledFamily')
  }

  const loadCentersFilter = async (labeledFamily) => {
    const response = await getBusinessUnitByLabeledFamily(labeledFamily)
    handleOptions(response, 'center')
  }

  const loadSuppliersFilter = async () => {
    const params = {
      useFilterDefault: JSON.parse(localStorage.getItem('filterMyItemsOnly')),
      labeledFamily: data.labeledFamily,
      businessUnit: data.center
    }

    const response = await getSuppliersByLabeledFamilyAndBusinessUnit(params)
    handleOptions(response, 'supplier')
  }

  useEffect(() => {
    loadLabeledFamilies()
    if (!isBottles) {
      loadCentersFilter([])
      loadSuppliersFilter()
      loadMaterialGroupFilter()
    }
  }, [])

  const handleClick = () => {
    const filters = {
      materialGroup: data.materialGroup.map(mg => mg.Id),
      labeledFamily: data.labeledFamily.map(family => family.Id),
      center: data.center.map(center => center.Id),
      supplier: data.supplier.map(supplier => supplier.Id),
      ...period,
      activeMaterial
    }
    setIsLoading(true)
    onClick(filters).finally(() => setIsLoading(false))
  }

  return (
    <S.Container>
      <Loading isLoading={isLoading} fullHeightParent />
      <Feature validation={useActiveMaterial}>
        <S.RadioButtonContainer>
          <ClbRadioGroup orientation='line'>
            <ClbRadioButton
              name="radio-01"
              value={"activeMaterial"}
              checked={activeMaterial == "activeMaterial"}
              onClbChange={handleActiveMaterial}>
              {intl.get('exportImportModal.masterData.onlyActiveMaterials')}
            </ClbRadioButton>
            <ClbRadioButton
              name="radio-02"
              value={"all"}
              checked={activeMaterial == "all"}
              onClbChange={handleActiveMaterial}>
              {intl.get('exportImportModal.masterData.allMaterials')}
            </ClbRadioButton>
          </ClbRadioGroup>
        </S.RadioButtonContainer>
      </Feature>
      <Feature validation={useMaterialGroup}>
        <SelectBox
          required
          name='Carteira'
          label={intl.get('commons.materialGroup')}
          placeholder={'-'}
          valueKey='Id'
          labelKey='Description'
          value={data.materialGroup}
          options={options.materialGroup}
          onChange={(materialGroup) => handleData(materialGroup, 'materialGroup')}
          searchable
          openOnFocus
          clearable
          data-testid="materialGroup"
          multi
          isOptionDisabled={() => limitOptions && data.materialGroup?.length >= limitOptions}
        />
      </Feature>
      <Feature validation={useLabeledFamilies}>
        <SelectBox
          required
          name='FamiliaRotulada'
          label={intl.get('commons.labeledFamily')}
          placeholder={'-'}
          valueKey='Id'
          labelKey='Description'
          value={data.labeledFamily}
          options={options.labeledFamily}
          onChange={(family) => handleData(family, 'labeledFamily')}
          searchable
          openOnFocus
          clearable
          data-testid="labeledFamily"
          multi
          isOptionDisabled={() => limitOptions && data.labeledFamily?.length >= limitOptions}
        />
      </Feature>
      <Feature validation={useBusinessUnit}>
        <SelectBox
          required
          name='Center'
          label={intl.get('commons.destiny')}
          placeholder={'-'}
          valueKey='Id'
          labelKey='Description'
          value={data.center}
          options={options.center}
          onChange={(center) => handleData(center, 'center')}
          searchable
          clearable
          multi
          disabled={isBottles && data.labeledFamily.length === 0}
          data-testid="center"
        />
      </Feature>
      <Feature validation={useSuppliers}>
        <SelectBox
          required
          name='Supplier'
          label={intl.get('commons.source')}
          placeholder={'-'}
          valueKey='Id'
          labelKey='Description'
          value={data.supplier}
          options={options.supplier}
          onChange={(supplier) => handleData(supplier, 'supplier')}
          searchable
          clearable
          multi
          disabled={isBottles && data.center.length === 0}
          data-testid="supplier"
        />
      </Feature>
      <Feature validation={useDateRange}>
        <DateRangerPicker
          title={intl.get('commons.period')}
          dataDe={period.dataDe}
          dataAte={period.dataAte}
          handleChange={handlePeriod}
        />
      </Feature>
      <S.ButtonContainer>
        <Button
          width='120px'
          type='secondary'
          value={intl.get('exportImportModal.butttons.download')}
          onClick={handleClick}
        />
      </S.ButtonContainer>
    </S.Container>
  )
}

FilterDataUpload.propTypes = {
  isBottles: PropTypes.any.isRequired,
  limitOptions: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired,
  useActiveMaterial: PropTypes.bool,
  useBusinessUnit: PropTypes.bool,
  useDateRange: PropTypes.bool,
  useLabeledFamilies: PropTypes.bool,
  useMaterialGroup: PropTypes.bool,
  useSuppliers: PropTypes.bool
}

FilterDataUpload.defaultProps = {
  useLabeledFamilies: false,
  useSuppliers: false,
  useBusinessUnit: false,
  useDateRange: false,
  useActiveMaterial: false,
  useMaterialGroup: false,
}

export { FilterDataUpload };
