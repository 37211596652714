import styled from "styled-components";

export const Wrapper = styled.div`
  height: calc(100vh - 335px);
  overflow-y: auto;
  input {
    border: 1.6px solid var(--color-action-default, #2870B2) !important;
    box-shadow: none !important;
  }
`

export const Content = styled.div`
  width: calc(100% - 65px);
  height: calc(100% - 360px);
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
`