import React, { useEffect } from 'react'
import { useGlobalContext } from 'hooks/useGlobalContext';
import { useZsagContext } from "./context/zsagContext"
import EditLine from './components/editLine'
import intl from 'react-intl-universal'
import Header from '../../components/header'
import FilterAutoLoadOptimization from './filter'
import ListZSAG from './list'
import * as S from './styled'
import noDataImage from 'images/no-data.svg'
import { Checkbox } from "@hbsis.uikit/react";
import Loading from "components/center-loading";
import {
  formatErrors,
  validVolume,
  validVolumeForColorRow,
  validSelectAll,
  errorMessage
} from './utils/validate'

import {
  initialVolumes,
} from './utils/models'

const AutoLoadOptimization = () => {
  const { showFeedback } = useGlobalContext()
  const {
    selectedVolumes,
    setSelectedVolumes,
    loading,
    setLoading,
    filters,
    setFilters,
    data,
    setData,
    volumes,
    setVolumes,
    setNewLineInProgress,
    setHaveUnsavedChanges,
    setMaterials
  } = useZsagContext()

  useEffect(() => {
    if (validVolume(volumes)) {
      const _volumes = normalizeVolumes(volumes.Schedules);
      const obj = {
        data: _volumes,
        totalCount: _volumes.length,
        totalPages: 1
      };
      setData({ ...obj });
    }
  }, [volumes])

  const handleGetVolumes = (volumesData, filter, materials) => {
    setFilters(filter)
    setMaterials(materials)
    setVolumes(volumesData)
  }

  const onChangeCheckBox = (volumesId, checked) => {
    if (checked) {
      setSelectedVolumes([...selectedVolumes, volumesId]);
    }
    else {
      setSelectedVolumes([...selectedVolumes.filter(value => value !== volumesId)]);
    }
    setVolumes({ ...volumes });
  }

  const normalizeColorRow = (volumesId) => {
    const volume = volumes.Schedules.find(x => x.Id === volumesId);
    return {
      color: validVolumeForColorRow(volume),
      errors: volume.Errors
    }
  }

  const CheckBoxRow = (volumesId) => {
    const statusRow = normalizeColorRow(volumesId);
    const errorsMessage = formatErrors(statusRow.errors)

    return (
      <S.CheckboxWrapper colorRow={statusRow.color} title={errorsMessage} >
        <span className='checkbox'>
          <Checkbox
            key={volumesId}
            name={`checkbox-${volumesId}`}
            checked={selectedVolumes.some(volume => volume === volumesId)}
            onChange={(e) => onChangeCheckBox(volumesId, e.target.checked)}
            data-testid={`checkbox-id-${volumesId}`}
            className='checkbox'
            style={{ cursor: "pointer" }}
          />
        </span>
      </S.CheckboxWrapper>
    )
  };

  const normalizeEditRow = (index, row) => (
    row.edit ? row.edit() : <EditLine idx={index} row={row} />
  )

  const normalizeVolumes = (volumesData) => (
    volumesData.filter(volume => volume.Process !== "D").map((row, index) => (
      {
        selectBox: () => CheckBoxRow(row.Id),
        ...row,
        edit: () => normalizeEditRow(index, row)
      }
    ))
  )

  const selectAll = (e) => {
    setSelectedVolumes(validSelectAll(e, volumes))
    setVolumes({ ...volumes });
  }

  const discardChanges = () => {
    setNewLineInProgress(false)
    setSelectedVolumes([]);
    setVolumes({ ...initialVolumes })
    setData(undefined);
    setHaveUnsavedChanges(false)
  }

  const processError = e => {
    showFeedback(errorMessage(e));
  }

  return (
    <React.Fragment>
      <Header
        title={intl.get("menu.subMenuGeneral.autoLoadOptimization")}
        titleWidth={600}
      />
      <FilterAutoLoadOptimization
        discardChanges={discardChanges}
        haveData={!!data}
        setLoading={setLoading}
        processError={processError}
        handleGetVolumes={handleGetVolumes}
      />
      <S.DataWrapper>
        <Loading isLoading={loading} fullHeightParent />
        {data ?
          <ListZSAG
            data={data}
            selectAll={selectAll}
            filters={filters}
            processError={processError}
            setLoading={setLoading}
          />
          :
          <S.NoDataWrapper>
            <div>
              <img className='no-data-image' src={noDataImage} />
              <h2>{intl.get('commons.noRecordsFound')}</h2>
            </div>
          </S.NoDataWrapper>
        }
      </S.DataWrapper>
    </React.Fragment>
  )
}

export default AutoLoadOptimization
