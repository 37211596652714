import * as S from './styled'
import Session from "utils/user-storage";
import { NavbarUploads } from '../navbar-upload-actions';
import { NavbarUserSettings } from '../navbar-user-settings';
import { NavbarNotifications } from '../navbar-notifications';

export const NavbarUserActions = () => {
  return (
    <>
      <S.Container>
        <S.Username data-testid='user-name-navbar'>{Session.get()?.Nome}</S.Username>
        <NavbarNotifications />
        <NavbarUploads />
        <NavbarUserSettings />
      </S.Container>
    </>
  )
}
