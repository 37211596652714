
import React from 'react'
import PropTypes from 'prop-types'
import intl from 'react-intl-universal'

import './pedido-volume-readonly.css'

const PedidoVolumeReadOnly = ({observacao}) => (
    <div>
      <div className="negotiation-read-column">
        <span>{intl.get('stocks.timeline.observation')}</span>
        <span>{observacao}</span>
      </div>
      <div className="negotiation-read-column">
        <span />
        <span />
      </div>
    </div>
);

PedidoVolumeReadOnly.propTypes = {
  observacao: PropTypes.string.isRequired,
}

export default PedidoVolumeReadOnly
