import PropTypes from "prop-types"
import React, { useState, useEffect } from 'react'
import { Layout } from "@hbsis.uikit/react"
import { SelectBox } from 'components/uikit-adapter/selectbox/index'
import { Row, Col } from 'react-flexbox-grid'
import intl from 'react-intl-universal'
import IconEmpty from "images/iconEmpty.icon"
import * as S from './styled'
import { getMateriais, getWallets, buscarFornecedores, getDoors } from '../../load-composition-building.service';
import ImgClose from '@hbsis.uikit/react/src/images/icn-close-filter.svg'
import Session from 'utils/user-storage'
import { useLoadCompositionBuildingContext } from '../../context/load-composition-building-context';
import { Button } from 'components/uikit-adapter'

const initialFilterMaterials = {
  material: null,
  carteira: null,
  idFornecedor: null,
  door: null,
  doorDescription: null,
}

const SideFilter = ({ open, close, businessUnitId }) => {
  const [materiais, setMateriais] = useState([]);
  const [carteiras, setCarteiras] = useState([]);
  const [filters, setFilters] = useState(initialFilterMaterials);
  const [fornecedores, setFornecedores] = useState([]);
  const [doors, setDoors] = useState([]);

  const {
    handleReloadDataCards,
    filter,
    selectedLoads,
    isEmergencyNegotiation
  } = useLoadCompositionBuildingContext();

  const loadOptionsFilter = () => {
    carregarMateriais();
    carregarCarteiras();
    loadSuppliers();
    loadDoors();
  }

  const carregarMateriais = () => {
    const model = {
      UseFilterDefault: false,
      UserId: Session.get().Id,
    }

    getMateriais(model).then(data => {
      const _data = data.map(item => ({ ...item, Id: item.MaterialNumber }));
      setMateriais(_data);
    })
  }

  const carregarCarteiras = () => getWallets().then(data => setCarteiras(data));

  const loadSuppliers = () => {
    buscarFornecedores().then(dataFornecedores => {
      setFornecedores(dataFornecedores);
    }).catch(e => console.error(e))
  }

  const loadDoors = () => getDoors(businessUnitId).then(data => setDoors(data))

  const handleChange = (value, label) => {
    setFilters(prevState => ({
      ...prevState,
      [label]: value ? value.Id : undefined,
      doorDescription: label === 'door' ? value?.Name : null,
    }))
  }

  const handleClear = () => {
    const _idFornecedor = isEmergencyNegotiation ? filters.idFornecedor : null;
    const _initialFilterMaterials = { ...initialFilterMaterials, idFornecedor: _idFornecedor};
    handleChange(null, "material")
    handleChange(null, "carteira")
    handleChange(null, "door")
    handleChange(_idFornecedor, "idFornecedor"); 
    handleReloadDataCards(_initialFilterMaterials);
    close();
  }

  const handleSearch = () => {
    handleReloadDataCards(filters);
    close();
  }

  useEffect(() => {
    loadOptionsFilter()
  }, []);

  useEffect(() => {
    if(filter.idFornecedor !== filters.idFornecedor){
      setFilters({
        ...filters,
        idFornecedor: Number(filter.idFornecedor)
      })
    }
  }, [filter])

  return (
    <Layout.Sidebar
      width="400px"
      visible={open}
      triggerClose={close}
      background="var(--color-contrast-white)"
      offsetTop="0px"
      side="right"
      block
      float
      icon={IconEmpty}
    >
      <S.Wrapper>
        <Row >
          <Col xs={10} lg={10} md={10}>
            <S.Title>{intl.get('loadComposition.building.titleFilterMaterialOrderSuggestion')}</S.Title>
          </Col>
          <Col xs={2} lg={2} md={2} className="exit">
            <S.Exit src={ImgClose} onClick={close} />
          </Col>
        </Row>
        <S.Content>
          <SelectBox
            label={intl.get("stocks.suggestionsListItem.origin")}
            placeholder={intl.get("stocks.suggestionsListItem.origin")}
            valueKey="Id"
            labelKey="Descricao"
            value={filters.idFornecedor}
            options={fornecedores}
            onChange={value => handleChange(value, 'idFornecedor')}
            searchable
            clearable
            disabled={selectedLoads.length!==0 || isEmergencyNegotiation}
            data-testid="selectbox-fornecedores"
          /> 
          <S.MaterialLabel>
            <SelectBox
              name='wallet'
              placeholder={intl.get('commons.wallet')}
              label={intl.get('commons.wallet')}
              valueKey="Id"
              labelKey="Description"
              value={filters.carteira}
              options={carteiras}
              onChange={value => handleChange(value, 'carteira')}
              searchable
              clearable
              data-testid="selectbox-carteiras"
            />
          </S.MaterialLabel>
          <S.MaterialLabel>
            <SelectBox
              name='material'
              placeholder={intl.get('commons.material')}
              label={intl.get('commons.material')}
              valueKey="Id"
              labelKey="Description"
              value={filters.material}
              options={materiais}
              onChange={value => handleChange(value, 'material')}
              searchable
              clearable
              data-testid="selectbox-materiais"
            />
          </S.MaterialLabel>
          <S.MaterialLabel>
            <SelectBox
              name='door'
              placeholder={intl.get('commons.door')}
              label={intl.get('commons.door')}
              valueKey="Id"
              labelKey="Name"
              value={filters.door}
              options={doors}
              onChange={value => handleChange(value, 'door')}
              searchable
              clearable
              data-testid="selectbox-doors"
            />
          </S.MaterialLabel>
          <Row className='rowFilterButtons'>
            <Col xs={6} lg={6} md={6}>
              <Button
                className="filter-search"
                type='primary'
                onClick={handleSearch}
                value={intl.get('loadComposition.building.applyButton')}
              />
            </Col>
            <Col xs={6} lg={6} md={6}>
              <Button
                type='secondary'
                value={intl.get('loadComposition.building.clearButton')}
                onClick={handleClear}
                className='clean-search'
                disabled={selectedLoads.length!==0}
              />
            </Col>
          </Row>
        </S.Content>
      </S.Wrapper>
    </Layout.Sidebar >
  )
}

SideFilter.propTypes = {
  businessUnitId: PropTypes.any.isRequired,
  close: PropTypes.func.isRequired,
  open: PropTypes.any.isRequired
}

export default SideFilter
