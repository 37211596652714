import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 170px;
  position: absolute;
  right: 20px;

  .sidebar-close{
    display: none;
  }
`
