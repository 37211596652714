
import React from 'react'
import intl from 'react-intl-universal'
import { BottleToggle } from '../bottles-toggle'
import { ConfigMode } from '../bottles-config-mode'
import * as S from "./styled"

export const BottlesHeader = () => (
  <S.BottlesHeader title={intl.get('bottles.title')}>
    <S.BottlesWrapper>
      <BottleToggle />
      <ConfigMode />
    </S.BottlesWrapper>
  </S.BottlesHeader>
)
