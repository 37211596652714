import Dialog from 'material-ui/Dialog'
import { ProjectICon } from '../icons/icn-index.icon'
import { getBuildDeployDate, getVersionName } from '../../utils/config-environment'
import moment from 'moment'
import intl from 'react-intl-universal'
import PropTypes from 'prop-types'

const ReleaseVersion = ({ open, handleClose }) => (
  <Dialog
    open={open}
    onRequestClose={handleClose}
  >
    <div>
      <ProjectICon width="38px" height="38px" />
      <label style={{ paddingLeft: '10px', position: 'relative', top: '-10px' }}>
        {`${getVersionName()} - ${intl.get('commons.buildDeployDate')}: ${moment(getBuildDeployDate()).format("L LT")}`}
      </label>
    </div>
  </Dialog>
)

ReleaseVersion.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.any
}

export default ReleaseVersion
