import styled from 'styled-components'
import * as Components from 'components/uikit-adapter/index'

export const Wrapper = styled.div`
  display: flex;
  z-index: 2;
  .buttomdeadline {
    margin-top: 0 !important;

    .date-separator-text {
      height: 95%;
    }
  }

  #row-deadline-control {
    position: relative;
    z-index: 100;
  }

  .label {
    line-height: 1.8rem;
    font-size: 12px;
    font-weight: var(--font-weight-semibold);
    text-transform: uppercase;
    color: var(--color-contrast-brand);
  }

  .last {
    justify-content: flex-end;
  }

  #row-deadline-control{
    display: flex;
    padding-top: 0px !important;
    flex-wrap: inherit;
  }

`

export const DeadlineControlWrapper = styled.div`
  margin-left: 12px;
`

export const Button = styled(Components.Button)`
  width: 200px;
  margin: 29px 20px 0px auto;
  display: none;
`
export const ButtonFilter = styled(Components.Button)`
  width: 40px !important;
  margin-left: 12px;
  margin-top: 29px;
`
