import { getType } from './types'

export const normalizeOptions = (options, labelKey, valueKey) => {
  const keys = [labelKey, valueKey]
  return options ? options.map((item) => ({ label: item[keys[0]], value: item[keys[1]], ...item })) : []
}

export const normalizeValue = (valor, labelKey, valueKey, options) => {
  const type = getType(valor)
  let valorSelect = {
    label: "",
    value: ""
  }

  if (validateIfValueIsDefault(valueKey, options, type, valor)) {
    return valorSelect
  }

  if (validateIsArray(options, type)) {
    switch (type) {
      case "object":
        if (valor) {
          valorSelect.label = valor[labelKey]
          valorSelect.value = valor[valueKey]
        }
        break;
      case "Array":
        valorSelect = []
        if (valor && valor.length > 0) {
          const arr = valor.filter(el => el !== null)
          valorSelect = arr.map((item) => ({ label: item[labelKey], value: item[valueKey], ...item }))
        }
        break;
      case "number":
      case "string":
        const filter = options.find((item) => item[valueKey] === valor)
        if (filter) {
          valorSelect.value = filter[valueKey]
          valorSelect.label = filter[labelKey]
        }
        break;
      default:
        break;
    }
  } else if (valor) {
    valorSelect.label = valor[labelKey]
    valorSelect.value = valor[valueKey]
  }

  return valorSelect
}

export const validateIfValueIsDefault = (valueKey, options, type, valor) => {
  let isDefault = false

  if (valueKey.includes("Id") && (options && options.length === 0) && type !== "Array") {
    isDefault = true
  }

  if ((valor && (valor === 0 || valor === "")) && (options && options.length === 0)) {
    isDefault = true
  }
  return isDefault
}

export const validateIsArray = (options, type) => {
  return ((options && options.length > 0) || type === "Array")
}
