import styled from 'styled-components'
import PropTypes from 'prop-types'

const validateFullHeight = (fullHeight) => fullHeight ? '100vh' : 'calc(100vh - 100px)'
const validateHeight = props => props.fullHeightParent ? '100%' : validateFullHeight(props.fullHeight);

const StyledCenterLoading = styled.div`
  height: ${props => validateHeight(props)};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  left: 0;
  position: fixed;
  z-index: 2000;
  background-color: rgba(255,255,255,0.4);
`

StyledCenterLoading.propTypes = {
  fullHeight: PropTypes.bool,
}

export default StyledCenterLoading
