import styled from 'styled-components'

export const CheckboxStyle = styled.div`
  padding: 5px 0px 0px 5px;
`
export const WrapperList = styled.div`
  padding: 0px 10px 0px 10px;

  .table{
    min-height: 500px;
  }
`
