import React from "react";
import PropTypes from "prop-types";
import { Toggle } from "react-powerplug";
import cx from "classnames";
import {
  StyledCheckbox,
  StyledHeader,
  StyledLabel,
  StyledRow,
  StyledValues
} from './components';

import { PlusIcon, MinusIcon, ShelfLifeIcon, DiscontinuedIcon } from 'components/icons/icn-index.icon'
import { getTitle } from "./utils";

const TableRow = ({
  childLevel,
  childrenKey,
  labelKey,
  code,
  label,
  item,
  labelsList,
  children,
  expandedItems = [],
  resolverValue,
  showCheckbox,
  showShelfLifeImage,
  showDiscontinuedImage,
  renderCustomAlert,
  onClick,
  onSelectItem,
  onExpandItem
}) => {
  const isExpanded = expandedItems.includes(code);



  return (
    <StyledRow isExpanded={isExpanded} hasChildren={children}>
      <div className="etb-info">
        <StyledHeader showCheckbox={!children}>
          {children && (
            <button className="etb-btn" onClick={() => onExpandItem(code)}>
              {isExpanded ? <MinusIcon width='15px' height='15px' /> : <PlusIcon width='15px' height='15px' />}
            </button>
          )}
          {!children && showCheckbox(item) && (
            <Toggle>
              {({ on, toggle }) => (
                <StyledCheckbox
                  on={on}
                  onClick={() => {
                    toggle();
                    onSelectItem(!on, item);
                  }}
                />
              )}
            </Toggle>
          )}

          <StyledValues
            title={getTitle(item, !!children)}
            onClick={!children && item.Bloqueado ? () => onClick(item) : undefined}>
            <StyledLabel
              className="etb-label"
              hasCheckbox={!children}
              childLevel={childLevel}
            >
              {!children && showShelfLifeImage(item) && (
                <div title="Risco de Vencimento" className="etb-image-alert">
                  <ShelfLifeIcon width='15px' height='15px' />
                </div>
              )}

              {!children && showDiscontinuedImage(item) && (
                <div title="Material Descontinuado" className="etb-image-alert">
                  <DiscontinuedIcon width='15px' height='15px' />
                </div>
              )}
              {!children && renderCustomAlert(item)}
              {label}
            </StyledLabel>

            <div className="etb-values">
              {labelsList.map((value, index) => (
                <div
                  key={index}
                  className="etb-column"
                  style={value.customStyle}
                >
                  {resolverValue
                    ? resolverValue(value.key, item)
                    : item[value.key]}
                </div>
              ))}
            </div>
          </StyledValues>
        </StyledHeader>
      </div>
      <div className={cx("etb-children", { expanded: isExpanded })}>
        {children &&
          children.length &&
          children.map((it, index) => (
            <TableRow
              key={`${code}-${it.Id}`}
              childLevel={childLevel + 1}
              code={`${code}-${it.Id}`}
              labelKey={labelKey}
              childrenKey={childrenKey}
              item={children[index]}
              label={it[labelKey]}
              labelsList={labelsList}
              children={it[childrenKey]}
              expandedItems={expandedItems}
              resolverValue={resolverValue}
              showCheckbox={showCheckbox}
              showShelfLifeImage={showShelfLifeImage}
              showDiscontinuedImage={showDiscontinuedImage}
              renderCustomAlert={renderCustomAlert}
              onClick={onClick}
              onSelectItem={onSelectItem}
              onExpandItem={onExpandItem}
            />
          ))}
      </div>
    </StyledRow>
  );
};

TableRow.defaultProps = {
  childLevel: 1
};

TableRow.propTypes = {
  childLevel: PropTypes.number,
  labelKey: PropTypes.string,
  childrenKey: PropTypes.string,
  code: PropTypes.string,
  label: PropTypes.string,
  item: PropTypes.object,
  labelsList: PropTypes.array,
  children: PropTypes.array,
  expandedItems: PropTypes.array,
  resolverValue: PropTypes.func,
  showCheckbox: PropTypes.func,
  showShelfLifeImage: PropTypes.func,
  showDiscontinuedImage: PropTypes.func,
  renderCustomAlert: PropTypes.func,
  onClick: PropTypes.func,
  onSelectItem: PropTypes.func,
  onExpandItem: PropTypes.func
};

export default TableRow;
