import PropTypes from "prop-types"
import React, { useState } from 'react'
import intl from 'react-intl-universal'
import * as S from '../modalLoadInNegotiation/styled'
import moment from 'moment'
import VectorNext from 'images/arrow.svg'
import { Vector } from '../../../../features/bottles/components/bottles-dropdown/vector'
import { formatNumber, formatWithoutLeadingZeros } from 'utils/format'
import { TextSpan } from 'components/text-label'
import Feature from 'components/feature'

const SupplierInfos = ({ composicao, showDrop }) => {
  const [hideInfos, setHideInfos] = useState(showDrop);
  const ordersNegotiation = composicao.OrdersNegotiations;
  return (
    <S.ObservacoesWrapper>
      <S.SecondTitleWrapper>
        <S.SecondTitle hideInfos={hideInfos}>{intl.get("loadComposition.supplierNotes")}</S.SecondTitle>
        <S.VectorWrapper
          onClick={() => setHideInfos(!hideInfos)}
          hideInfos={hideInfos}
          showDrop={showDrop}
        >
          <Vector vector={VectorNext} />
        </S.VectorWrapper>
      </S.SecondTitleWrapper>

      {!hideInfos &&
        <S.InfosWrapper>
          <div>
            <div className='content'>
              <span className='title'>{intl.get('modalRenegotiationLoadComposition.suggestedDeliveryDateTime')}</span>
              <span className='description'>
                {moment(composicao.DataEntregaSugerida).format("L")}
                {` ${intl.get('commons.at')} `}
                {moment(composicao.HoraEntregaSugerida, "HH:mm:ss").format("LT")}
              </span>
            </div>
            {!!ordersNegotiation &&
              <>
                <Feature validation={ordersNegotiation.ChangeOrders.filter(x => x.IsEdited).length > 0}>
                  <div className='content'>
                    <span className='title'>{intl.get('commons.editedOrders')}</span>
                    {ordersNegotiation.ChangeOrders.filter(x => x.IsEdited).map(x => (
                      <div key={x.IdOrder}>
                        <span className='description'>
                          {intl.get("commons.orderCode")}: {x.IdOrder}
                        </span>
                        <span className='description'>
                          {" - "}{intl.get('stocks.qty')}: {formatNumber(x.Quantity)}
                        </span>
                      </div>
                    ))}
                  </div>
                </Feature>
                <Feature validation={ordersNegotiation.AddingOrders.length > 0}>
                  <div className='content'>
                    <span className='title'>{intl.get('commons.addedOrders')}</span>
                    {ordersNegotiation.AddingOrders.map((x, i) => (
                      <div key={i} style={{ display: 'flex' }}>
                        <TextSpan className='description material-description'>
                          {formatWithoutLeadingZeros(x.MaterialNumber)} - {x.MaterialDescription}
                        </TextSpan>
                        <span className='description'>
                          {" - "}{intl.get('stocks.qty')}: {formatNumber(x.Quantity)}
                        </span>
                      </div>
                    ))}
                  </div>
                </Feature>
                <Feature validation={ordersNegotiation.SuggestionNewOrders.length > 0}>
                  <div className='content'>
                    <span className='title'>{intl.get('commons.addedSuggestions')}</span>
                    {ordersNegotiation.SuggestionNewOrders.map((x, i) => (
                      <div key={i} style={{ display: 'flex' }}>
                        <TextSpan className='description material-description'>
                          {formatWithoutLeadingZeros(x.MaterialNumber)} - {x.MaterialDescription}
                        </TextSpan>
                        <span className='description'>
                          {" - "}{intl.get('stocks.qty')}: {formatNumber(x.Quantity)}
                        </span>
                      </div>
                    ))}
                  </div>
                </Feature>
                <Feature validation={ordersNegotiation.DeletionOrders.length > 0}>
                  <div className='content'>
                    <span className='title'>{intl.get('commons.removedOrders')}</span>
                    {ordersNegotiation.DeletionOrders.map(x => (
                      <div key={x.IdOrder}>
                        <span className='description'>
                          {intl.get("commons.orderCode")}: {x.IdOrder}
                        </span>
                      </div>
                    ))}
                  </div>
                </Feature>
              </>
            }
            <div className='content'>
              <span className='title'>{intl.get('modalRenegotiationLoadComposition.negotiationNotes')}</span>
              <span className='description'>{composicao.ObservacaoSugerida || "-"}</span>
            </div>
          </div>
        </S.InfosWrapper>
      }
    </S.ObservacoesWrapper>
  )
}

SupplierInfos.propTypes = {
  composicao: PropTypes.shape({
    DataEntregaSugerida: PropTypes.any,
    HoraEntregaSugerida: PropTypes.any,
    ObservacaoSugerida: PropTypes.string,
    OrdersNegotiations: PropTypes.shape({
      AddingOrders: PropTypes.shape({
        length: PropTypes.number,
        map: PropTypes.func
      }),
      ChangeOrders: PropTypes.shape({
        filter: PropTypes.func
      }),
      DeletionOrders: PropTypes.shape({
        length: PropTypes.number,
        map: PropTypes.func
      }),
      SuggestionNewOrders: PropTypes.shape({
        length: PropTypes.number,
        map: PropTypes.func
      })
    })
  }).isRequired,
  showDrop: PropTypes.any.isRequired
}

export default SupplierInfos;
