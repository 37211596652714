import Styled from 'styled-components'

export const Title = Styled.h3`
    font-size: 18px;
    color: var(--color-action-default);
    margin: 10px 0px 10px 10px;
`

export const Description = Styled.h5`
    font-size: 14px;
    margin: 25px 3px 10px 10px;
    font-weight: var(--font-weight-semibold);
`

export const IconTitle = Styled.div`
    margin: 10px 0px 10px 5px;
    display: flex;
`