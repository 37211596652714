import PropTypes from "prop-types"
import React, {
  createContext,
  useContext,
  useEffect,
  useState
} from "react";
import {
  verifyFilterUser,
} from "../config/user/user.service";
import { LoadColumsTable } from "../config/user/utils/colums-tables";
import { loadOrderCancellationJustifications, loadSuggestionJustifications } from "../pages/master-data/configurations/general-settings/general-settings.service";
import { GlobalProvider } from "./globalContext";
import { Navbar } from "features/navbar";
import { perfilPcpUnidadeLogado } from "../services/permissions/getPermissions";
import Feature from "components/feature";
import SurveyNps from "features/survey-nps";
import { getShowNPS } from "utils/config-environment";
import { loadMonitoringApplications } from "providers/monitoring";
import { getUser } from "providers/authService";
import { getDefaultLocale, getLocaleForLanguageSheet } from "utils/userLanguage";
import intl from 'react-intl-universal'
import moment from 'moment'
import momentPtBr from "locales/moment/moment-pt-BR";
import momentEnUs from "locales/moment/moment-en-US";
import momentEsEs from "locales/moment/moment-es-ES";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import enUS from "date-fns/locale/en-US";
import es from "date-fns/locale/es";
import enGb from "date-fns/locale/en-GB"
import locales from "locales";
import { getDocumentTypes } from "utils/document-types";
import { initGeneralSettings } from "services/general-settings/general-settings";
import { MuiThemeProvider } from "material-ui/styles";
import ErrorPage from "pages/404";
import Session from 'utils/user-storage'
import { useAuth } from "oidc-react";
import { useThemeContext } from "./themeContext";
import { ToastProvider } from "./toastContext";
import momentEnGb from "locales/moment/moment-en-GB";

/**
 * Contexto criado para encapsular lógica de obtenção de dados do
 * usuário pós SSO para acesso a aplicação.
 */
const AppContextKc = createContext();
const AppProviderKc = ({ children }) => {
  const [initDone, setInitDone] = useState(false);
  const auth = useAuth()
  const [noAccount, setNoAccount] = useState(false)
  const { theme } = useThemeContext()

  const loadLocales = async (user) => {
    const language = getLocaleForLanguageSheet(user.LocaleCode);
    await intl.init({
      currentLocale: language,
      locales,
    });

    setUpLocalizationLibsSettings(user.LocaleCode);
  };

  const setUpLocalizationLibsSettings = (language) => {
    registerLocale("pt-BR", ptBR);
    registerLocale("es-ES", es);
    registerLocale("en-US", enUS);
    registerLocale("en-Gb", enGb);

    const _defaultLocale =  getDefaultLocale(language);
    setDefaultLocale(_defaultLocale);

    switch (language) {
      case "pt-BR":
        moment.updateLocale("pt-br", momentPtBr);
        break;
      case "es-ES":
      case "es-BO":
        moment.updateLocale("es", momentEsEs);
        break;
      case "en-GB":
        moment.updateLocale("en-gb", momentEnGb);
        break;
      case "en-US":
      default:
        moment.updateLocale("en", momentEnUs);
        break;
    }
  };

  const loadDocumentTypes = async () => {
    const documentTypes = await getDocumentTypes();
    localStorage.setItem("DocumentTypes", JSON.stringify(documentTypes));
  }

  const loadAppAndUserConfig = async () => {
    window.document.title = "Ontime MRP";
    loadMonitoringApplications()

    const activeUser = await getUser()
    await loadLocales(activeUser);
    
    if(!auth.isLoading && auth.userManager && !activeUser) {
      setNoAccount(true)
      return
    }

    if (activeUser) {
      await loadDocumentTypes();
      await initGeneralSettings();
      setInitDone(true)
    }

    LoadColumsTable();

    try {

      if (perfilPcpUnidadeLogado()) {
        localStorage.setItem("filterMyItemsOnly", true);
      } else {
        const myItemsOnly = localStorage.getItem('filterMyItemsOnly')

        if (myItemsOnly === null) {
          const filterMyItemsOnly = await verifyFilterUser(activeUser.Id);
          localStorage.setItem("filterMyItemsOnly", filterMyItemsOnly.data);
        }
      }

      const orderCancellationJustifications = await loadOrderCancellationJustifications();
      const suggestionJustifications = await loadSuggestionJustifications();

      const enumerators = {
        OrderCancellationJustifications: orderCancellationJustifications.data,
        SuggestionJustifications: suggestionJustifications.data
      };

      localStorage.setItem("enumerators", JSON.stringify(enumerators));

    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!initDone) {
      loadAppAndUserConfig()
    }
  })

  if (noAccount) {
    return (
      <MuiThemeProvider>
        <ErrorPage />
      </MuiThemeProvider>
    )
  }

  return (
    initDone && Session.get() && <GlobalProvider>
      <ToastProvider>
        <AppContextKc.Provider>
          <main className={`container-main ${theme}`}>
            <Navbar />
            {children}
            <Feature validation={getShowNPS()}>
              <SurveyNps />
            </Feature>
          </main>
        </AppContextKc.Provider >
      </ToastProvider>
    </GlobalProvider>
  )
};

AppProviderKc.propTypes = {
  children: PropTypes.any.isRequired
}

export const useAppContextKc = () => useContext(AppContextKc);
export { AppProviderKc, AppContextKc };