import intl from "react-intl-universal";
import * as permissions from "../../../services/permissions/getPermissions";
import { loadCompositionBrewerySideActive } from "services/general-settings/get-general-settings";
import {
  usesAutoLoadOptimization,
  usesBottlesPlan,
  usesPrimaryMaterialSchedule,
  usesTmsIntegration,
  usesVolume,
  usesLoadOptimizationViaPortal
} from "utils/validations-general-settings";
import TipoPerfilAcesso from "models/usuarios/tipo-perfil-acesso";

const showLoadCompositionMenu = () => loadCompositionBrewerySideActive() && permissions.userAmbev()

export const menuData = () => [
  {
    name: "tarefas",
    label: intl.get("menu.menuGeneral.tasks"),
    path: "/",
    visible: permissions.userAmbev() || permissions.userFornecedor(),
  },
  {
    name: "pedidos",
    label: intl.get("menu.menuGeneral.planning"),
    path: "",
    visible: permissions.userAmbev() || permissions.userFornecedor(),
    submenus: ordersSubmenus(),
    dataTestId: 'navbar-planning'
  },
  {
    name: "composicao",
    label: intl.get("menu.subMenuGeneral.loadComposition"),
    path: "/composicao",
    visible: permissions.userFornecedor(),
    dataTestId: 'navbar-transport-load-composition'
  },
  {
    name: "gerencial",
    label: intl.get("menu.menuGeneral.management"),
    path: "",
    visible: permissions.userAmbev(),
    submenus: managementSubmenus()
  },
  {
    name: "transportes",
    label: intl.get("menu.menuGeneral.transports"),
    path: "",
    visible: permissions.userAmbev() && (permissions.perfilPciLogado() || permissions.profileAdminOrMasterData() || permissions.perfilGestorLogado()),
    submenus: transportsSubmenus(),
    dataTestId: 'navbar-transports'
  },
  {
    name: "relatorios",
    label: intl.get("menu.menuGeneral.reports"),
    path: "/relatorios",
    visible: permissions.userAmbev() || permissions.userFornecedor() || permissions.perfilPcpUnidadeLogado(),
  },
  {
    name: "masterdata",
    label: intl.get("menu.menuGeneral.masterData"),
    path: "/master-data/general",
    visible: (permissions.userAmbev() || permissions.userSistema()) && !permissions.perfilPcpUnidadeLogado(),
    dataTestId: 'navbar-master-data'
  },
];

const ordersSubmenus = () => [
  {
    name: "estoque",
    label: intl.get("menu.menuGeneral.stocks"),
    path: "stocks",
    visible: !permissions.userFornecedor(),
  },
  {
    name: "pedidos",
    label: intl.get("menu.menuGeneral.orders"),
    path: "orders",
    visible: true,
    dataTestId: 'navbar-planning-orders'
  },
  {
    name: "volume",
    label: intl.get("commons.orderVolume"),
    path: "volume",
    visible: usesVolume(),
  },
  {
    name: "suggestions",
    label: intl.get("commons.suggestions"),
    path: "suggestions",
    visible: !permissions.userFornecedor(),
  },
  {
    name: "visao-grade",
    label: intl.get("menu.subMenuGeneral.grid-vision"),
    path: "gridVision",
    visible: true,
  },
  {
    name: "estoque-fornecedor",
    label: intl.get("menu.subMenuGeneral.supplierStock"),
    path: "estoqueFornecedor",
    visible: true,
  },
  {
    name: "auto-load-optimization",
    label: intl.get("menu.subMenuGeneral.autoLoadOptimization"),
    path: "autoLoadOptimization",
    visible: usesAutoLoadOptimization() && permissions.userAmbev(),
  },
  {
    name: "primary-material-schedule",
    label: intl.get("menu.subMenuGeneral.primaryMaterialScheduleList"),
    path: "primaryMaterialSchedule",
    visible: usesPrimaryMaterialSchedule() && permissions.userAmbev(),
  },
  {
    name: "bottles",
    label: intl.get("bottles.title"),
    path: "bottles",
    visible: usesBottlesPlan() && permissions.verifyUserByProfile([
      TipoPerfilAcesso.PciSolutions,
      TipoPerfilAcesso.MasterData,
      TipoPerfilAcesso.Gestor,
      TipoPerfilAcesso.Administrador,
    ]),
  },
  {
    name: "loadComposition",
    label: intl.get("menu.subMenuGeneral.loadCompositionPlanning"),
    path: "loadComposition",
    visible: showLoadCompositionMenu(),
    dataTestId: 'navbar-load-composition-planning'
  },
  {
    name: "consolidatedStocks",
    label: intl.get("commons.consolidatedStocks"),
    path: "consolidated-stocks",
    visible: !permissions.userFornecedor(),
  },
  {
    name: "supplier-production-plan",
    label: intl.get("menu.subMenuGeneral.supplierProductionPlan"),
    path: "supplier-production-plan",
    visible: true,
  },
]

const managementSubmenus = () => [
  {
    name: "riscos-passivos",
    label: intl.get("commons.capitalEmployed"),
    path: "riscos-passivos",
    visible: true,
  },

  {
    name: "itens-criticos",
    label: intl.get("menu.subMenuGeneral.critical-itens"),
    path: "itens-criticos",
    visible: true,
  },
]

const transportsSubmenus = () => [
  {
    name: "composicao",
    label: intl.get("menu.subMenuGeneral.loadComposition"),
    path: "composicao",
    visible:
      permissions.perfilPciLogado() ||
      permissions.profileAdminOrMasterData() ||
      permissions.perfilGestorLogado(),
    dataTestId: 'navbar-load-composition-transports'
  },
  {
    name: "otimizacaoCarga",
    label: intl.get("menu.subMenuGeneral.loadOptimization"),
    path: "otimizacaoCarga",
    visible:
      usesTmsIntegration() &&
      (permissions.perfilPciLogado() ||
        permissions.profileAdminOrMasterData() ||
        permissions.perfilGestorLogado()),
  },
  {
    name: "load-building",
    label: intl.get("menu.subMenuGeneral.loadBuilding"),
    path: "load-building",
    visible: usesLoadOptimizationViaPortal(),
    dataTestId: 'navbar-load-building-transports'
  }
]
