import Session from 'utils/user-storage'
import Fetch from 'utils/fetch'

export const claimSupplierHasAccessToInfo = async (supplierId) => {
  const { SupplierIds } = await getSupplierIdsByUser()
  const ids = SupplierIds.includes(',') ? SupplierIds.split(',') : SupplierIds
  const supId = parseInt(supplierId)
  return ids.includes(supId) || Session.get().TipoUsuario !== 2
}

export const getSupplierIdsByUser = () =>
  Fetch.get('/suppliers/get-supplier-ids-by-user').then(({ data }) => data)