import PropTypes from "prop-types"
import React from 'react'
export const Vector = ({ vector, alt, width, heigth }) =>
  (<img src={vector} alt={alt} style={{ width: width, height: heigth }} />)

Vector.propTypes = {
  alt: PropTypes.any.isRequired,
  heigth: PropTypes.any.isRequired,
  vector: PropTypes.any.isRequired,
  width: PropTypes.any.isRequired
}

