import PropTypes from "prop-types"
import React, { Component } from 'react'
import { Row, Col } from 'react-flexbox-grid'
import ColorPickers from '../../../colorpickers/index'
import ModalArNotCreated from 'components/modal-task-ar-not-created'

import IconLabelRight from 'components/icon-label-right'
import pedidoPacoteIcon from 'images/pedido-pacote.png'
import icnPedidoUrgente from 'images/icn-pedido-urgente.png'
import icnFuroColetaEntrega from 'images/icn-furo-coleta-entrega.png'
import icnCobrarRetorno from 'images/icn-cobrar-retorno.png'
import icnAguardandoDescarga from 'images/icn-aguardando-descarga.png'
import icnAguardandoCiencia from 'images/icn-aguardando-ciencia.svg'
import icnPedidoEmOver from 'images/icn-locked.svg'
import icnRenegociacao from 'images/icn-renegociacao.svg'
import icnArNaoCriado from 'images/ar-not-created.svg'
import icnLoadCompositionToBeReviewed from 'images/icn-load-composition-to-be-reviewed.svg'
import icnLoadPartiallyFilledAutopillot from 'images/icn-auto-pilot.svg'
import intl from 'react-intl-universal'

import StyledListTasks from './styledListTasks'

import { TIPOS_TAREFA } from '../index'
import Session from 'utils/user-storage';
import { obterUrlTarefa } from '../../../services/tarefas/gerador-url-tarefa';

class ListTasks extends Component {
  static propTypes = {
    data: PropTypes.shape({
      length: PropTypes.number,
      map: PropTypes.func
    }).isRequired,
    redirect: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      isOpenModalArNotCreated: false,
      modalData: []
    }

  }

  handleClickRow = (task) => {
    if (task.TipoTarefa === TIPOS_TAREFA.TAREFA_AR_NAO_CRIADO) {
      this.openModalArNotCreated(task)
    } else {
      this.props.redirect(this.getTaskUrl(task));
    }
  }

  getIcone = (TipoTarefa) => {

    switch (TipoTarefa) {
      case TIPOS_TAREFA.TAREFA_INCLUSAO_PEDIDO_URGENTE:
        return icnPedidoUrgente;
      case TIPOS_TAREFA.TAREFA_RENEGOCIACAO_EMERGENCIAL_DATA_RECUSADA:
        return icnRenegociacao;
      case TIPOS_TAREFA.TAREFA_PEDIDO_AGUARDANDO_DESCARGA:
        return icnAguardandoDescarga

      case TIPOS_TAREFA.TAREFA_FURO_COLETA:
      case TIPOS_TAREFA.TAREFA_FURO_ENTREGA:
        return icnFuroColetaEntrega

      case TIPOS_TAREFA.TAREFA_COBRANCA_ACEITE_FORNECEDOR:
      case TIPOS_TAREFA.TAREFA_PEDIDO_AGUARDANDO_COMPOSICAO_CARGA_FORNECEDOR:
      case TIPOS_TAREFA.TAREFA_PEDIDO_AGUARDANDO_COMPOSICAO_CARGA_AMBEV:
      case TIPOS_TAREFA.TAREFA_VOLUME_PENDENTE_ACEITE:
      case TIPOS_TAREFA.TAREFA_VOLUME_CIENCIA_PENDENTE:
        return icnCobrarRetorno

      case TIPOS_TAREFA.TAREFA_AGUARDANDO_CIENCIA_PEDIDO_OVER_RECUSADO:
        return icnAguardandoCiencia

      case TIPOS_TAREFA.TAREFA_ESTOQUE_EM_OVER:
      case TIPOS_TAREFA.TAREFA_ESTOQUE_EM_OVER_CRITICO:
      case TIPOS_TAREFA.TAREFA_PEDIDO_EM_OVER:
        return icnPedidoEmOver

      case TIPOS_TAREFA.TAREFA_RENEGOCIACAO_EMERGENCIAL_DATA:
        return icnAguardandoDescarga;

      case TIPOS_TAREFA.TAREFA_AR_NAO_CRIADO:
        return icnArNaoCriado;

      case TIPOS_TAREFA.TAREFA_COMPOSICAO_EM_NEGOCIACAO:
      case TIPOS_TAREFA.TAREFA_COMPOSICAO_CARGA_AGUARDANDO_REVISAO:
      case TIPOS_TAREFA.TAREFA_COMPOSICAO_EM_RENEGOCIACAO_EMERGENCIAL:
      case TIPOS_TAREFA.TAREFA_MASS_ARRIVAL_RISK:
        return icnLoadCompositionToBeReviewed

      case TIPOS_TAREFA.TAREFA_COMPOSICAO_PARCIALMENTE_PREENCHIDA_AUTOPILOT:
        return icnLoadPartiallyFilledAutopillot
      default:
        return pedidoPacoteIcon
    }
  }

  getColorIcone = (TipoTarefa) => {

    switch (TipoTarefa) {
      case TIPOS_TAREFA.TAREFA_RENEGOCIACAO_EMERGENCIAL_DATA_RECUSADA:
        return ColorPickers.cardLaranjaForte.ColorStatus

      case TIPOS_TAREFA.TAREFA_INCLUSAO_PEDIDO_URGENTE:
        return ColorPickers.cardLaranjaMedio.ColorStatus

      case TIPOS_TAREFA.TAREFA_PEDIDO_AGUARDANDO_DESCARGA:
        return ColorPickers.cardAzul.ColorStatus

      case TIPOS_TAREFA.TAREFA_FURO_COLETA:
      case TIPOS_TAREFA.TAREFA_FURO_ENTREGA:
        return ColorPickers.cardLilas.ColorStatus

      case TIPOS_TAREFA.TAREFA_COBRANCA_ACEITE_FORNECEDOR:
      case TIPOS_TAREFA.TAREFA_PEDIDO_AGUARDANDO_COMPOSICAO_CARGA_FORNECEDOR:
      case TIPOS_TAREFA.TAREFA_PEDIDO_AGUARDANDO_COMPOSICAO_CARGA_AMBEV:
      case TIPOS_TAREFA.TAREFA_VOLUME_PENDENTE_ACEITE:
      case TIPOS_TAREFA.TAREFA_VOLUME_CIENCIA_PENDENTE:
        return ColorPickers.cardMarron.ColorStatus

      case TIPOS_TAREFA.TAREFA_ESTOQUE_EM_OVER:
      case TIPOS_TAREFA.TAREFA_ESTOQUE_EM_OVER_CRITICO:
      case TIPOS_TAREFA.TAREFA_PEDIDO_EM_OVER:
      case TIPOS_TAREFA.TAREFA_AGUARDANDO_CIENCIA_PEDIDO_OVER_RECUSADO:
        return ColorPickers.cardAmareloVivo.ColorStatus

      case TIPOS_TAREFA.TAREFA_RENEGOCIACAO_EMERGENCIAL_DATA:
      case TIPOS_TAREFA.TAREFA_COMPOSICAO_PARCIALMENTE_PREENCHIDA_AUTOPILOT:
        return ColorPickers.cardAzulEscuro.ColorStatus;

      case TIPOS_TAREFA.TAREFA_AR_NAO_CRIADO:
      case TIPOS_TAREFA.TAREFA_COMPOSICAO_EM_RENEGOCIACAO_EMERGENCIAL:
      case TIPOS_TAREFA.TAREFA_MASS_ARRIVAL_RISK:
        return ColorPickers.cardVermelhoAr.ColorStatus

      case TIPOS_TAREFA.TAREFA_COMPOSICAO_EM_NEGOCIACAO:
      case TIPOS_TAREFA.TAREFA_COMPOSICAO_CARGA_AGUARDANDO_REVISAO:
        return ColorPickers.cardAmarelo.ColorStatus

      default:
        return ColorPickers.cardAzulPetroleo.ColorStatus
    }
  }

  openModalArNotCreated = (task) => this.setState({ isOpenModalArNotCreated: true, modalData: task.TarefaPaymatchInformation })

  getTaskUrl = (task) => {
    const user = Session.get();
    return obterUrlTarefa(task, user.TipoUsuario);
  };

  render() {
    const {
      data,
    } = this.props

    return (
      <div>
        <StyledListTasks>
          <Col xs={12} md={12} lg={12}>
            <Row className="containerHeader">
              <Col xs={2} md={2} lg={2} className="align-left">{intl.get('tasks.taskType')}</Col>
              <Col xs={2} md={2} lg={2} className="align-left"></Col>
              <Col xs={4} md={4} lg={4} className="align-center">{intl.get('tasks.situation')}</Col>
              <Col xs={4} md={4} lg={4} className="align-center">{intl.get('tasks.action')}</Col>
            </Row>
            <Row className="containerListaTask">
              <Col xs={12} md={12} lg={12} className="listNotasFiscais pt-0 pb-0">
                {(!data || data.length === 0) &&
                  <Row className="rowList rowTasks">
                    <Col xs={12} md={12} lg={12} className="align-center">
                      <span className="no-results">{intl.get('commons.noRecordsFound')}</span>
                    </Col>
                  </Row>
                }
                {data && !!data.length && data.map((task, i) => (
                  <Row
                    key={i}
                    className={`rowList rowTasks`}
                    onClick={() => this.handleClickRow(task)}
                    style={{ cursor: 'pointer', borderBottom: '1px dashed #98c1dc' }}>
                    <Col xs={2} md={2} lg={2} >
                      <IconLabelRight
                        src={this.getIcone(task.TipoTarefa)}
                        label={task.Titulo}
                        classNameDivImage={this.getColorIcone(task.TipoTarefa)}
                        isAlert={task.Alert}
                        />
                    </Col>
                    <Col xs={2} md={2} lg={2} className="align-left">{task.SubTitulo}</Col>
                    <Col xs={4} md={4} lg={4} className="align-center">{task.Situacao}</Col>
                    <Col xs={4} md={4} lg={4} className="align-center">{task.Acao}</Col>
                  </Row>
                ))
                }
              </Col>
            </Row>
          </Col>
        </StyledListTasks>
        <ModalArNotCreated
          tasks={this.state.modalData}
          open={this.state.isOpenModalArNotCreated}
          close={() => this.setState({ isOpenModalArNotCreated: false })}
        />
      </div>
    )

  }
}

export default ListTasks;
