import styled from 'styled-components'

const StyledSuggestionsListItem = styled.div`
  padding: 5px 0;

  .table-list-item-body {
    padding-top: 10px;
  }

  .table-list-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }

  .table-list-footer .msof-button {
    margin-left: 10px;
  }
`

export default StyledSuggestionsListItem
