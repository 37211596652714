import styled from "styled-components";

export const WrapperGeneral = styled.div`
  overflow-y: auto;
  height: 100%;

  .content{
    padding-bottom: 60px;
    height: -webkit-fill-available
  }
  
  .div-quantidade-lot {
    margin-top: 24px;
    position: absolute;
    bottom: 32px;
    height: 40px;
  }
`

export const WrapperAccordions = styled.div`
  margin-top: 8px;
`
export const WrapperTable = styled.table`
  width: 100%;
  border-collapse:collapse;
  position: relative;

  th,td {
    text-align: left;
    padding: 8px;
  }

  th{
    color: var(--color-contrast-brand, #081826);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; 
  }

  td{
    color: var(--Contrast-Brand, #081826);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  th.btnDelete, td.btnDelete {
    background-color: var(--color-neutral-background);
    border-bottom: none !important;
  }
`

export const AccordionHeader = styled.thead`
  background: #f2f5f9;
  top: 0;
  position: sticky;
`

export const AccordionContent = styled.tbody`
  position: relative;
  background-color: ${p => p.isLoading ? 'rgba(255, 255, 255, 0.4)' : '#FFFFFF'};

  .enumerator{
    color: var(--300,#2870B2);
    font-family: Arial;
    font-size: 16px;
    font-weight: 700;
    cursor: auto;
  }

  .clickable-line{
    cursor: pointer;
    height: 50px;
  }

  .limit-width{
    max-width: 180px;
    display: block;
  }

  td.firstLine{
    border-bottom: ${p => p.isExpanded ? "none" : "2px solid var(--color-neutral-300)"};
  }

  ${p => p.isExpanded && `tr:last-child{
    td:nth-last-child(-n + 11){
    border-bottom: 2px solid var(--color-neutral-300);
  }
  }
  `}

  ${p => p.isLoading && `
      td, span{
        opacity: 0.3;
      }
  `} 
`
export const Icon = styled.span`
  display: inline-flex;
  ${p => p.isExpanded && "transform: rotate(180deg);"}
`

export const BlankSpace = styled.div`
  height: ${p => p.height ? p.height : '6px'};
`

export const Title = styled.p`
  color: var(--color-neutral-600);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  margin: 0px;
`

export const SubTitle = styled.p`
  color: var(--color-contrast-brand);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin: 0px;
  margin-top: 8px;
`

export const SubTitleBold = styled.p`
  color: var(--color-contrast-brand);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin: 0px;
  margin-top: 8px;
`

export const Warning = styled.p`
  font-size: 12px;
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  line-height: normal;
  text-transform: uppercase;
  color: var(--color-feedback-danger-600);
  margin: 8px 0px 0px 0px;
`
export const WrapperRequestsLoading = styled.div`
  position: absolute;
  right: 50%;
  top: 0px;
  padding: 10px;

  img{
    height: 28px;
  }
`

export const WrapperSubtitle = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const TitleModal = styled.p`
  color: var(--color-contrast-brand);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  margin: 0px 0px 20px 0px;
`

export const TextModal = styled.p`
  color: var(--color-neutral-600);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`
