import PropTypes from "prop-types"
import React from "react";
import { Row, Col } from "react-flexbox-grid";
import intl from 'react-intl-universal';
import { Input } from '@hbsis.uikit/react';
import 'components/suggestions-list/suggestions-list-item/suggestions-list-item.css';

const StoInformationRow = ({ item, changeItem, isDisableStoFields }) => {
  return (
    <div style={{ paddingTop: '5px' }}>
      <label className="label-style">
        {intl.get("stocks.suggestionsListItem.driveTransferInformation")}
      </label>
      <div className="container">
        <Row>
          <Col xs={4}>
            <Input
              className='input'
              label={intl.get("stocks.suggestionsListItem.purchasingOrganization")}
              name="organizacaoCompras"
              htmlType="text"
              value={item.Fornecedor.Organization}
              onChange={(event) => changeItem('Organization', event.target.value.toUpperCase())}
              maxLength={4}
              disabled={isDisableStoFields}
              data-testid='input-organizacao-compras'
            />
          </Col>
          <Col xs={4}>
            <Input
              className='input'
              label={intl.get("stocks.suggestionsListItem.buyerGroup")}
              name="grupoCompradores"
              value={item.Fornecedor.BuyerGroup}
              onChange={(event) => changeItem('BuyerGroup', event.target.value.toUpperCase())}
              maxLength={3}
              disabled={isDisableStoFields}
              data-testid='input-grupo-compradores'
            />
          </Col>
          <Col xs={4}>
            <Input
              className='input'
              label={intl.get("stocks.suggestionsListItem.taxCode")}
              name="codigoImposto"
              value={item.Fornecedor.TaxCode}
              onChange={(event) => changeItem('TaxCode', event.target.value.toUpperCase())}
              maxLength={5}
              disabled={isDisableStoFields}
              data-testid='input-codigo-imposto'
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

StoInformationRow.propTypes = {
  changeItem: PropTypes.func.isRequired,
  isDisableStoFields: PropTypes.any.isRequired,
  item: PropTypes.shape({
    Fornecedor: PropTypes.shape({
      BuyerGroup: PropTypes.any,
      Organization: PropTypes.any,
      TaxCode: PropTypes.any
    })
  }).isRequired
}

export default StoInformationRow;
