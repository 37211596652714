import React, { Component } from 'react'
import PropTypes from 'prop-types'

import StyledStatusCard from './styledStatusCard'
import { TextSpan } from 'components/text-label'

class StatusCardGenerico extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const {
      Titulo,
      SubTitulo,
      Icon,
      Status,
    } = this.props

    return (
      <StyledStatusCard>
        <div className='status-container'>
          <div className='image-status' style={{ backgroundColor: Status.ColorStatus }}>
            <img src={Icon} alt='status' />
            <div
              className='arrow-status'
              style={{ borderColor: `transparent transparent ${Status.ColorStatusArrow} transparent` }}
            />
          </div>

          <div className='insumo-desc'>
            <TextSpan style={{ color: Status.ColorStatus }}            >
              {Titulo}
            </TextSpan>
            <TextSpan>
              {SubTitulo}
            </TextSpan>
          </div>
        </div>
      </StyledStatusCard>
    )
  }
}

StatusCardGenerico.propTypes = {
  Titulo: PropTypes.string.isRequired,
  SubTitulo: PropTypes.string.isRequired,
  Icon: PropTypes.object.isRequired,
  Status: PropTypes.shape({
    ColorStatus: PropTypes.string.isRequired,
    ColorStatusArrow: PropTypes.string.isRequired
  })
}

export default StatusCardGenerico
