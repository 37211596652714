import styled from 'styled-components'
import { Button as ButtonComponent } from "components/uikit-adapter/index"

export const Wrapper = styled.div`
  height: calc(100% - 75px);
  overflow-y: auto;
  overflow-x: hidden;
`
export const WrapperFilters = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;

  .react-multi-email{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
`
export const WrapperActions = styled.div`
  display: flex;
  position: absolute;
  bottom: 15px;
  height: 40px;
  width: 100%;
  justify-content: space-around;
  border-top: solid var(--color-neutral-300) 1px;
  padding-top: 15px;
`

export const Button = styled(ButtonComponent)`
  width: 200px;
`
