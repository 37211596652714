import { generateRandomNumber } from "utils/calculations";
import { getValueOrDefault } from "utils/custom-functions";
import { formatWithoutLeadingZeros } from "utils/format";

export const normalizeOrdersByNegotiation = (orders, ordersNegotiations) => {
  let _orders = [];

  const ordersDeleted = ordersNegotiations.DeletionOrders.map(x => {
    const _order = orders.find(y => y.IdPedido == x.IdOrder);
    return {
      ..._order,
      "IdPedido": x.IdOrder,
      "DataColeta": x.PickupDate,
      "DataEntrega": x.DeliveryDate,
      "HoraEntrega": x.DeliveryTime,
      "Quantidade": x.Quantity,
      "Material": x.MaterialDescription,
      "NumeroMaterial": x.MaterialNumber,
      "Lote": `${x.Lot} ${x.UnitOfMeasurementLot}`,
      "TotalPallets": x.Lot,
      "IsDeleted": true,
      "IdFamiliaRotuladaCentro": x.IdLabeledFamilyCenter
    };
  });
  _orders.push(...ordersDeleted);

  const ordersUpdated = ordersNegotiations.ChangeOrders.map(x => {
    const _order = orders.find(y => y.IdPedido == x.IdOrder);
    return {
      ..._order,
      "IdPedido": x.IdOrder,
      "DataColeta": x.PickupDate,
      "DataEntrega": x.DeliveryDate,
      "HoraEntrega": x.DeliveryTime,
      "Quantidade": x.Quantity,
      "Material": x.MaterialDescription,
      "NumeroMaterial": x.MaterialNumber,
      "Lote": `${x.Lot} ${x.UnitOfMeasurementLot}`,
      "TotalPallets": x.Lot,
      "IdFamiliaRotuladaCentro": x.IdLabeledFamilyCenter
    }
  });
  _orders.push(...ordersUpdated);

  const newSuggestionOrder = ordersNegotiations.SuggestionNewOrders.map(x => ({
    ...x,
    "IdSugestao": x.IdSuggestion,
    "IdCard": getValueOrDefault(x.IdCard, generateRandomNumber()),
    "NumeroMaterial": x.MaterialNumber,
    "Material": x.MaterialDescription,
    "MaterialSelect": { Id: x.IdMaterial, Descricao: `${formatWithoutLeadingZeros(x.MaterialNumber)} - ${x.MaterialDescription}` },
    "Quantidade": x.Quantity,
    "IsNew": !x.IdSuggestion,
    "TotalPallets": x.Lot,
    "QuantidadeUnidadeMedida": x.UnitOfMeasurement,
    "LotesUnidadeMedida": x.UnitOfMeasurementLot,
    "Lote": `${x.Lot} ${x.UnitOfMeasurementLot}`,
  }));
  _orders.push(...newSuggestionOrder);

  const newOrder = ordersNegotiations.AddingOrders.map(x => ({
    "IdPedidoSap": x.PurchaseOrder,
    "IdItemPedidoSap": x.PurchaseOrderItem,
    "IdCard": `${x.IdOrder}P`,
    "IdPedido": x.IdOrder,
    "Quantidade": x.Quantity,
    "Material": x.MaterialDescription,
    "NumeroMaterial": x.MaterialNumber,
    "TotalPallets": x.Lot,
    "QuantidadeUnidadeMedida": x.UnitOfMeasurement,
    "LotesUnidadeMedida": x.UnitOfMeasurementLot,
    "MaterialSelect": { Id: x.IdMaterial, Descricao: `${formatWithoutLeadingZeros(x.MaterialNumber)} - ${x.MaterialDescription}` },
    "IsAddedInNegotiation": true,
    "Lote": `${x.Lot} ${x.UnitOfMeasurementLot}`,
    "IdFamiliaRotuladaCentro": x.IdLabeledFamilyCenter
  }));
  _orders.push(...newOrder);

  return _orders;
}
