import { useEffect, useRef, useState } from 'react'
import DownArrow from 'images/down-arrow.svg'
import { transformPeriod } from './utils'
import useOutsideClick from '../../hooks/useOutsideClick'
import { useBottlesContext } from '../../features/bottles'
import moment from 'moment'
import * as S from './styled'

const InputPeriod = () => {
  const {
    filter,
    setFilter,
    setUpdateFilterForReplyPlanning,
    updateFilterForReplyPlanning
  } = useBottlesContext()
  const [period, setPeriod] = useState([])
  const [currentPeriod, setCurrentPeriod] = useState({})
  const [open, setOpen] = useState(false)
  const wrapperRef = useRef(null)

  useOutsideClick(wrapperRef, () => {
    setOpen(false)
  })

  const handleFilter = (data) =>
    setFilter(prevState =>
      ({ ...prevState, periodDate: { ...data, month: data.month } }))

  const handlePeriod = (data) => {
    setCurrentPeriod({ month: data.month, year: data.year })
    handleFilter(data)
    setOpen(false)
  }

  useEffect(() => {
    const dates = [
      moment().add(-2, 'month'),
      moment().add(-1, 'month'),
      moment().add(0, 'month'),
      moment().add(1, 'month')
    ]

    const periods = dates.map((date) => (
      {
        month: moment(date).toDate().getMonth(),
        year: moment(date).toDate().getFullYear()
      }
    ))

    setPeriod(periods)
    setCurrentPeriod(periods[2])
    handleFilter(periods[2])
  }, [])

  useEffect(() => {
    if(updateFilterForReplyPlanning) {
      handlePeriod(filter.periodDate)
      setUpdateFilterForReplyPlanning(false)
    }
  }, [updateFilterForReplyPlanning])

  return (
    <S.Select innerRef={wrapperRef}>
      <S.Input onClick={() => setOpen(prevState => !prevState)}>
        {period.length > 0 &&
          <span>{transformPeriod(currentPeriod)}</span>
        }
        <S.Carret>
          <S.ArrowDownIcon src={DownArrow} />
        </S.Carret>
      </S.Input>
      {open &&
        <S.Option>
          {period.map((p) => (
            <S.Period key={p.month+"-"+p.year} onClick={() => handlePeriod(p)}>
              {transformPeriod(p)}
            </S.Period>
          ))}
        </S.Option>
      }
    </S.Select>
  )
}

export default InputPeriod

