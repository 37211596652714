import PropTypes from "prop-types"
import ManualOrderForm from 'components/manual-order-form';
import ImportExport from 'components/import-export';
import intl from 'react-intl-universal'
import { DialogsUserSettingsOptions } from 'features/navbar/utils';
import { downloadOrderVolumeModel } from '../navbar-user-actions/navbar-user-actions.service';

export const DialogsNavbarUploads = ({ open, handleClose }) => (
  <>
    <ManualOrderForm
      open={open === DialogsUserSettingsOptions.MANUAL_ORDER}
      handleClose={handleClose}
    />
    <ImportExport
      title={intl.get("master-data.menus.importVolumn")}
      contextModal="stockView"
      open={open === DialogsUserSettingsOptions.IMPORT_EXPORT_ORDERS_VOLUME}
      handleClose={handleClose}
      downloadModelo={downloadOrderVolumeModel}
      importArquivo="pedidoVolume/import/pedidoVolume"
      type='csv'
      stepSelected={1}
      hiddenButtonExport
    />
  </>
)

DialogsNavbarUploads.propTypes = {
  handleClose: PropTypes.any.isRequired,
  open: PropTypes.any.isRequired
}
