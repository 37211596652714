export const UserCountry = [
  {
    Key: 1,
    Value: 'BR - Brasil'
  },
  {
    Key: 2,
    Value: 'US - United States'
  },
  {
    Key: 3,
    Value: 'ZA - South Africa'
  }
]

export const UserLanguage = [
  {
    Key: 6,
    Value: 'English (Canada)'
  },
  {
    Key: 7,
    Value: 'English (Great Britain)'
  },
  {
    Key: 4,
    Value: 'English (Southern Africa)'
  },
  {
    Key: 2,
    Value: 'English (United States)'
  },
  {
    Key: 3,
    Value: 'Español (Bolivia)'
  },
  {
    Key: 5,
    Value: 'Español (España)'
  },
  {
    Key: 1,
    Value: 'Português (Brasil)'
  }
]
