import styled from 'styled-components'

const StyledSelectOptions = styled.div `
> .tabs {
  > .tabs__labels {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: stretch;
    justify-content: flex-end;
  }
}

> .tabs__content {
  padding: 25px;
}
`

export default StyledSelectOptions
