import React from "react";

const IconRedirecionamentoPedidoTransferencia = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={20}
    height={20}
    style={{
      enableBackground: "new 0 0 52.495 52.495",
    }}
    viewBox="0 0 52.495 52.495"
  >
    <g fill="var(--color-action-default)">
      <path
        d="M31.113 16.038V3.99l19.971 20.08-19.971 20.08V32.102S13.378 27.81 1.264 48.166c0 0-3.02-15.536 10.51-26.794 0-.001 6.129-5.732 19.339-5.334z"
        data-old_color="#MATERI"
        data-original="#26B99A"
      />
      <path
        d="m.783 50.929-.5-2.573c-.031-.161-2.974-16.25 10.852-27.753.202-.191 6.116-5.585 18.674-5.585l.305.001V1.566L52.495 24.07 30.113 46.574V32.937a21.21 21.21 0 0 0-3.071-.213c-5.761 0-16.657 2.073-24.918 15.953L.783 50.929zm29.025-33.911c-11.776 0-17.297 5.033-17.352 5.084C2.911 30.046 1.878 40.274 2.004 45.149 14.365 27.091 31.127 31.077 31.348 31.13l.765.185v10.411L49.674 24.07 32.113 6.413v10.654l-1.03-.03a42.425 42.425 0 0 0-1.275-.019z"
        data-old_color="#MATERI"
        data-original="#26B99A"
      />
    </g>
  </svg>
)
export default IconRedirecionamentoPedidoTransferencia