
export const propsRenders = [
  "Consumption", "FinalStock", "InitialStock", "PlannedEntry", "RealEntry", "Transfer"
]

export const getColorCell = (column, value) => {
  if (column === "FinalStock" && value < 0) {
    return "#FFBA9C";
  }

  switch (column) {
    case "Consumption":
    case "Transfer":
      return '#FFF2D2'
    case "FinalStock":
    case "InitialStock":
      return "#F4F5F8"
    case "Entry":
    case "RealEntry":
      return "#DCECC9";
  }
  return '#FFFFFF';
}

export const getColorSpan = (column, value) => {
  if (column === "FinalStock" && value < 0) {
    return "#FA5A4B";
  }

  switch (column) {
    case "Consumption":
    case "Transfer":
      return '#B98B1F'
    case "InitialStock":
    case "FinalStock":
      return "#496072"
    case "Entry":
    case "RealEntry":
      return "#557C23";
  }
  return '#FFFFFF';
}

export const isFirstRender = (idxOne, idxTwo) => (!idxOne && !idxTwo)
export const calcQuantityColumns = (filters) => Math.round(Object.keys(filters).filter(prop => filters[prop] === true).length / 2)
export const getFilterCheckbox = (filters) => Object.keys(filters).filter(prop => filters[prop] === true)


