import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
  
  > * {
    padding: 0px 5px;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  margin-left: auto;
  .btn-filter{
    margin-top: 28px;
    height: 37px;
    width: 132px;
    margin-left: 5px;
  }
`
