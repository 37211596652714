import intl from 'react-intl-universal'
import * as S from './styled'
import PropTypes from 'prop-types'

export const FilterActions = ({ handleClickClean, handleClickSearch, disabledSearch }) => (
  <S.WrapperActions>
    <S.Button
      type="primary"
      onClick={handleClickSearch}
      value={intl.get('filters.search')}
      className="btn-close-search"
      disabled={disabledSearch}
    />
    <S.Button
      type="secondary"
      onClick={handleClickClean}
      value={intl.get('filters.cleanSearch')}
      className="btn-clean"
    />
  </S.WrapperActions>
)

FilterActions.propTypes = {
  disabledSearch: PropTypes.bool,
  handleClickSearch: PropTypes.func,
  handleClickClean: PropTypes.func
}