import { normalizeValueDatePicker } from "utils/celebration"

export const normalizeDateFilter = (filter) => {
  return {
    ...filter,
    _minDeliveryDate: normalizeValueDatePicker(filter._minDeliveryDate),
    _maxDeliveryDate: normalizeValueDatePicker(filter._maxDeliveryDate),
    _minCollectDate: normalizeValueDatePicker(filter._minCollectDate),
    _maxCollectDate: normalizeValueDatePicker(filter._maxCollectDate),
  }
}