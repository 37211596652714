import Styled from 'styled-components'

export const Container = Styled.div`
    margin-top: 15px;
    border: solid;
    border-color: var(--color-neutral-300);
    border-width: 1px;
`

export const Content = Styled.div`
    margin-top: 15px;
    border-top: solid;
    border-color: var(--color-neutral-300);
    border-width: 1px
`