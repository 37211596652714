import { generateRandomNumber } from "utils/calculations"
import { builderLoadsAwaintingLoadDto, builderLoadsReleasedDto, builderLoadsSuggestionsDto } from "../utils/builder"

export const typesSuggestion = {
  AwaitLoadComposition: 1,
  Suggestions: 2,
  Liberado: 3
}

export const filterDefault = {
  pagination: {
    page: 1,
    take: 20,
    total: 0
  },
  idFornecedor: null,
  carteira: null,
  material: null,
  typeSuggestionSelected: typesSuggestion.Suggestions
}

export const getParamsForRequestByCardType = (filter, params) => {
  let requestData = { dto: undefined, isReleased: false }
  switch (filter.typeSuggestionSelected) {
    case typesSuggestion.AwaitLoadComposition:
      requestData = { dto: builderLoadsAwaintingLoadDto(params, filter), isReleased: false }
      break;
    case typesSuggestion.Suggestions:
      requestData = { dto: builderLoadsSuggestionsDto(params, filter), isReleased: false }
      break;
    case typesSuggestion.Liberado:
      requestData = { dto: builderLoadsReleasedDto(params, filter), isReleased: true }
      break;
    default:
      break;
  }
  return requestData;
}

export const buildOrdersMetaDataDto = (ordersNegotiations) => ({
  AlteracaoPedidos: ordersNegotiations.ChangeOrders.map(x => ({
    IdPedido: x.IdOrder,
    Observacao: x.Observation,
    DataColeta: x.PickupDate,
    DataEntrega: x.DeliveryDate,
    HoraEntrega: x.DeliveryTime,
    Quantidade: x.Quantity
  })),
  RemocaoPedidos: ordersNegotiations.DeletionOrders.map(x => x.IdOrder),
  AdicaoSugestoes: ordersNegotiations.SuggestionNewOrders.map(x => ({
    IdSugestao: x.IdSuggestion,
    IdMaterial: x.IdMaterial,
    IdUnidadeNegocio: x.IdBusinessUnit,
    IdFornecedor: x.IdSupplier,
    DataColeta: x.PickupDate,
    DataEntrega: x.DeliveryDate,
    HoraEntrega: x.DeliveryTime,
    Quantidade: x.Quantity,
    Observacao: x.Observation,
    IdCard: x.IdCard
  })),
  AdicaoPedidos: ordersNegotiations.AddingOrders.map(x => x.IdOrder),
});

export const setIdCardSupplierSuggestions = (ordersNegotiations) => ({
  ...ordersNegotiations,
  SuggestionNewOrders: ordersNegotiations.SuggestionNewOrders.map(x => ({
    ...x,
    IdCard: `${x.IdSuggestion ? x.IdSuggestion : generateRandomNumber()}S`
  })),
})

export const normalizeOrdersMetadataByNegotiation = (ordersNegotiations) => {
  return {
    AdicaoPedidos: ordersNegotiations.AddingOrders?.map(x => ({
      IdPedido: x.IdOrder,
      Quantity: x.Quantity
    })),
    AlteracaoPedidos: ordersNegotiations.ChangeOrders?.map(x => ({
      IdPedido: x.IdOrder,
      Quantidade: x.Quantity
    })),
    RemocaoPedidos: ordersNegotiations.DeletionOrders?.map(x => ({
      IdPedido: x.IdOrder
    })),
    AdicaoSugestoes: ordersNegotiations.SuggestionNewOrders?.map(x => ({
      Quantidade: x.Quantity,
      IdMaterial: x.IdMaterial,
      IdSugestao: x.IdSuggestion
    })),
  }
}

export const normalizeOrdersMetadata = (_orders) => {
  return {
    AdicaoPedidos: [],
    AlteracaoPedidos: _orders?.map(x => ({
      IdPedido: x.IdPedido,
      Quantidade: x.Quantidade
    })),
    RemocaoPedidos: [],
    AdicaoSugestoes: []
  }
}