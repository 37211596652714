import PropTypes from "prop-types"
import React, { Component } from 'react'
import intl from 'react-intl-universal'
import './numero-sap.css'

class NumeroSap extends Component {
  static propTypes = {
    codigoPedidoSap: PropTypes.any.isRequired,
    codigoPedidoSapItem: PropTypes.any.isRequired
  }

  constructor (props) {
    super(props)
  }

  render () {
    return (
      <div className='ajuste-margin'>
        <div className='negotiation-read-offer'>
        </div>
        <div className='negotiation-read-offer'>
          <div className='negotiation-read-column'>
            <span>{intl.get('stocks.timeline.sapOrder')}</span>
            <span>{this.props.codigoPedidoSap}</span>
          </div>
        </div>
        <div className='negotiation-read-offer'>
          <div className='negotiation-read-column'>
            <span>{intl.get('stocks.timeline.sapOrderItem')}</span>
            <span>{this.props.codigoPedidoSapItem}</span>
          </div>
        </div>
      </div>
    )
  }
}

export default NumeroSap
