import styled from 'styled-components'
import Dialog from 'material-ui/Dialog'

export const CancelDialogWrapper = styled(Dialog)`
  h3 {
    color: #DE4246 !important;
    font-weight: var(--font-weight-semibold) !important;
    text-transform: uppercase;
  }

  .container-message {
    color: var(--color-contrast-brand);
    font-weight: var(--font-weight-semibold);
    font-size: 14px;
  }

`
