import styled from 'styled-components'

export const Wrapper = styled.div`
.row-delivery-date{
  padding-top: 0px !important;
  height: 50px;
}

.row-offset-delivery-time{
  padding-top: 0px !important;
  height: 50px;
}

.col-offset-delivery-time{
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.options-group-row{
  margin-top: 10px;
}

.last-option-group{
  margin-top: -20px;
}

`
