export const StatusPedido = Object.freeze({
  EmNegociacao: 1,
  AguardandoComposicaoCarga: 2,
  Finalizado: 3,
  Cancelado: 4,
  EmNegociacaoComposicaoCarga: 5,
  EmNegociacaoEmergencial: 6,
  AguardandoAvisoRecebimento: 7,
  AguardandoNotaRecebimentoInspecao: 8,
  AguardandoEntradaMercadoria: 9,
  AguardandoEntradaFatura: 10,
  AguardandoAceitacaoPagamento: 11,
  AguardandoConfirmacaoPagamento: 12,
  AguardandoPcSap: 13,
  AguardandoFornecimento: 14,
  AguardandoAprovacaoGestor: 15,
  AguardandoCiencia: 16,
  EmRenegociacao: 17
});

export const ListStatusCancelNoticeSupplier = [
  StatusPedido.EmNegociacao,
  StatusPedido.AguardandoComposicaoCarga,
  StatusPedido.EmNegociacaoComposicaoCarga,
  StatusPedido.EmNegociacaoEmergencial
]

export const ListStatusCancellationSupplierApproval = [
  StatusPedido.AguardandoAvisoRecebimento
]
