import {
  ConsolidatedStocks as ConsolidatedStocksComponent,
  ConsolidatedStocksProvider
} from 'features/consolidated-stocks'

const ConsolidatedStocks = () => (
  <ConsolidatedStocksProvider>
    <ConsolidatedStocksComponent />
  </ConsolidatedStocksProvider>
)

export default ConsolidatedStocks;