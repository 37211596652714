import styled from 'styled-components'
import * as Compoonents from 'components/uikit-adapter/index'

export const Wrapper = styled.div`
  background: var(--color-contrast-white);
  width: 427px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  position: absolute;
  right: 0;
  height: calc(100% - 100px);

  span {
    cursor: auto;
  }
`

export const WrapperHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 18px 0px 0px 24px;
  border-bottom: 1px solid var(--color-neutral-300);
  top: -3px;
  z-index: 10;
  background: var(--color-contrast-white);
  position: sticky;
`

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 210px);
  ${p => p.isEditDateMode && 'opacity: 0.75; pointer-events: none;'}
  ${p => p.isEditLoadComposition && 'height: calc(100% - 270px);'}
`

export const WrapperFooter = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--color-neutral-300);
  bottom: 0px;
  background: var(--color-contrast-white);
  z-index: 10;
  height: 60px;
  width: 100%;

  .content{
    margin: 17px 0px 0px 24px;
  }
`

export const Title = styled.span`
  font-size: 24px;
  line-height: 24px;
  color: var(--color-action-default);  
`

export const Subtitle = styled.span`
  font-size: 12px;
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  line-height: normal;
  text-transform: uppercase;
  color: #CB3C40;
  margin-top: 4px;
`

export const SupplierInfosWrapper = styled.div`
  padding: 2px 25px 14px 25px;
  z-index: 10;
  background: var(--color-contrast-white);
  border-bottom: 1px solid var(--color-neutral-300);
`

export const InfosWrapper = styled.div`  
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  .title{
    font-weight: var(--font-weight-semibold);
    font-size: 12px;
    line-height: 12px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #9CA4AC;    
  }

  button#edit{
    cursor: pointer !important;
    border: transparent;
    background-color: transparent;
    padding: 0px;
    margin: 0px;
  }

  .description, .description-notes, .description-date-time, .description-door{
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    color: var(--color-action-default);    
  }

  .content-date-time{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .destiny-door-content{
    display: flex;
    float: left;
  }

  .destiny-content{
    margin-bottom: 8px;
    width: 50%;
  }

  .door-content{
    margin-bottom: 8px;
    width: 50%;
  }

  ${p => p.isCentered && 'margin: 8px 0px 0px 0px;'}
  ${p => p.isLast && 'margin: 8px 0px 12px 0px;'}
  ${p => p.isFooter && 'margin-top: 0px;'}
`
export const WrapperEditDateActionsButtons = styled.div`
  display: flex;
`

export const ActionsWrapper = styled.div`
  display: flex;
  right: 10px;
  width: 100%;
  justify-content: end;
  position: relative;
  top: -32px;
  right: 24px;
`

export const Button = styled(Compoonents.Button)`
  width: 112px;
  height: 26px;
  margin-left: 6px;
  span{
    color: var(--color-contrast-white) !important;
    font-size: 10px !important;
  }
`
export const ButtonEdit = styled(Compoonents.Button)`
  width: 112px;
  height: 26px;
  border: 1px solid var(--color-action-default) !important;
  span{
    color: var(--color-action-default) !important;
    font-size: 10px !important;
  }
`
export const ButtonEditDateTimeDoor = styled(ButtonEdit)`
  position: absolute;
  left: 305px;
  top: 20px;
`

export const ButtonCancelEditDate = styled(ButtonEdit)`
  margin-left: 12px;
`

export const ButtonDoneEditDate = styled(Button)`
  margin-right: 20px;
`

export const ButtonAddNotes = styled(ButtonEdit)`
  width: 67px;
  margin-right: 134px;
`

export const ButtonDescription = styled.span`
  cursor: pointer !important;
  text-decoration-line: underline;
  text-decoration-style: dotted;
  text-underline-offset: 2px;      
`

export const DescriptionNotes = styled.span`
  text-decoration-line: underline;
  cursor: pointer !important;
`