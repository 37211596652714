import styled from 'styled-components'

 const StyledFiltroIntegracaoGradeDescarga = styled.div`
  
    padding-bottom: 20px;

    .colCarteiras {
        display: flex;
        align-items: center;
        justify-content: left;
        margin-bottom: 5px;
        line-height: 1rem;
        font-weight: var(--font-weight-semibold);
        text-transform: uppercase;
        color: var(--color-contrast-black);
        font-size: 14px;
    }

     .colCheckBox {
        display: inline-flex;
        justify-content: flex-end;
    }

    .checkbox-item {
        display: flex !important;
        align-items: center !important;
    }

    .checkbox-item label {
        margin-bottom: 5px !important;
        line-height: 1rem !important;
        font-weight: var(--font-weight-semibold) !important;
        text-transform: uppercase !important;
        color: var(--color-contrast-black) !important;
        font-size: 12px !important;
    }
`

 export default StyledFiltroIntegracaoGradeDescarga
