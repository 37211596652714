import PropTypes from "prop-types"
import React from 'react'
import TableList from 'components/table-list'
import { useOrderVolumeContext } from '../../context'
import { buildListItem } from '../../utils'
import { Columns } from './columns'
import { Grid } from './styled'

const OrderVolumeList = ({ data }) => {
  const { checkbox, setCheckbox } = useOrderVolumeContext()

  const handleChange = (checked, record) => {
    const id = record?.Id;
    
    if (id) {
      const canAccept = data.find((item) => item.Id === id)?.CanAccept
      
      if (!checkbox.includes(id) && canAccept) {
        setCheckbox(prevState => [...prevState, id])
      } else {
        const selecteds = checkbox.filter(item => item !== id)
        setCheckbox([...selecteds])
      }
    }

    if (checked && !id) {
      const acceptableItems = data.filter((item) => item.CanAccept)
      setCheckbox([...acceptableItems.map(item => item.Id)])
    }

    if (!checked && !id) {
      setCheckbox([])
    }
  }

  return (
    <Grid>
      <TableList
        scope='OrderVolume'
        columns={Columns()}
        data={data.map(item => buildListItem(item))}
        onChange={handleChange}
        selected={checkbox}
        borderColor={'#ffc107'}
      />
    </Grid>
  )
}

OrderVolumeList.propTypes = {
  data: PropTypes.shape({
    filter: PropTypes.func,
    find: PropTypes.func,
    map: PropTypes.func
  }).isRequired
}

export default OrderVolumeList
