import { isUseFilterDefault } from 'utils/user';
import moment from 'moment'

export const mapDtoRequestStocks = (_filters, schedulingFilters, user) => ({
  ..._filters,
  StepsIds: _filters.StepsIds.map(x => x.Id),
  StatusIds: _filters.StatusIds.map(x => x.Id),
  StockStatusIds: _filters.StockStatusIds.map(x => `${x.Id}`),
  SupplierIds: _filters.SupplierIds.map(x => x.Id),
  CoordinationIds: _filters.CoordinationIds.map(x => x.Id),
  MaterialGroupIds: _filters.MaterialGroupIds.map(x => x.Id),
  FamilyIds: _filters.FamilyIds.map(x => x.Id),
  LabeledFamilyIds: _filters.LabeledFamilyIds.map(x => x.Id),
  MaterialNumbers: _filters.MaterialNumbers.map(x => x.MaterialNumber),
  BusinessUnitIds: _filters.BusinessUnitIds.map(x => x.Id),
  UseFilterDefault: schedulingFilters.idUsuarioProgramado ? true
    : isUseFilterDefault(),
  IsScheduledViewOnly: schedulingFilters.programados,
  IsUnscheduledViewOnly: schedulingFilters.naoProgramados,
  UserId: schedulingFilters.idUsuarioProgramado ? schedulingFilters.idUsuarioProgramado : user.Id
});

export const mapDtoResponseStocks = (data) => ({
  Id: data.LabeledFamilyCenterId,
  LabeledFamily: data.LabeledFamilyDescription,
  MaterialAtivo: data.ActiveMaterial,
  ProximaDataEntregaPedido: normalizeDate(data.NextOrderDeliveryDate),
  ProximaQuantidadeEntrega: data.NextOrderDeliveryQuantity,
  Etapa: data.OrderStep,
  EtapaDescricao: data.OrderStepDescription,
  Status: data.OrderNegotiationType,
  StatusDescricao: data.OrderNegotiationTypeDescription,
  StatusEstoque: data.StockStatus,
  EstoqueValor: data.StockQuantity,
  EstoqueUnidadeMedida: data.StockUnitMeasurement,
  DataOut: normalizeDate(data.OutDate),
  Suficiencia: normalizeDate(data.SufficiencyDate),
  Programado: data.IsScheduledView,
  Fabrica: {
    Id: data.Factory.Id,
    Codigo: data.Factory.Center,
    Nome: data.Factory.Name
  }
});

const normalizeDate = (_date) => _date ? moment(_date).format("DD/MM") : null

export const statusStock = (status) => {
  const event = {
    0: 'Ok',
    1: 'Over',
    2: 'Out',
    4: 'None'
  }
  return event[status]
}
