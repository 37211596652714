import React, { useState, useRef } from 'react'
import intl from 'react-intl-universal'
import useOutsideClick from 'hooks/useOutsideClick'
import { useGlobalContext } from 'hooks/useGlobalContext'
import { Dropdown } from '../bottles-dropdown'
import { ModalConfirm } from '../bottles-modal'
import DownArrow from 'images/down-arrow-blue.svg'
import { useBottlesContext } from '../../context'
import { getMessageReplyModal, getMessageReprojectModal } from './utils'
import { transformPeriod } from 'components/input-period/utils'
import { replyPlanning, reprojectPlanning } from '../../services/bottles-plan.service'
import { formatErrorMessage } from 'utils/handle-error'
import * as S from './styled'

export const SupplierDropdown = () => {
  const { showFeedback } = useGlobalContext()
  const { filter, selectedSupplier, setFilter, setUpdateFilterForReplyPlanning } = useBottlesContext()
  const [isOpen, setIsOpen] = useState(false)
  const [showReplicateModal, setShowReplicateModal] = useState(false)
  const [showReprojectModal, setShowReprojectModal] = useState(false)
  const handleShowReplicateModal = () => setShowReplicateModal(!showReplicateModal)
  const handleShowReprojectModal = () => setShowReprojectModal(!showReprojectModal)
  const period = ({ year: new Date().getFullYear(), month: new Date().getMonth() })
  const nextPeriod = ({ year: new Date().getFullYear(), month: new Date().getMonth() + 1 })

  const options = [
    { label: 'Replicar Planejamento', action: 'replicar', disabled: false },
    { label: 'Reprojetar Planejamento', action: 'reprojetar', disabled: !selectedSupplier }
  ]

  const wrapperRef = useRef(null)
  useOutsideClick(wrapperRef, () => setIsOpen(!isOpen))

  const handleAction = (action) => {
    const event = {
      'replicar': handleShowReplicateModal,
      'reprojetar': handleShowReprojectModal
    }
    return event[action]()
  }

  const handleConfirmReprojectModal = async () => {
    try {
      const params = {
        LabeledFamilyId: filter.labeledFamilyId[0],
        SupplierId: selectedSupplier,
        Period: new Date(filter.periodDate.year, filter.periodDate.month, 1)
      }
      await reprojectPlanning(params)
      showFeedback('Reprojeção realizada com sucesso!')
      setFilter(prevState => ({ ...prevState }))

    } catch (err) {
      showFeedback(formatErrorMessage(err))
    }
  }

  const handleConfirmReplyPlanning = async () => {
    try {
      const params = {
        LabeledFamilyId: filter.labeledFamilyId[0]
      }
      
      await replyPlanning(params)
      setFilter(prevState => ({
        ...prevState,
        periodDate: { ...period, month: period.month + 1}
      }))
      
      showFeedback('Replicação realizada com sucesso!')
      setUpdateFilterForReplyPlanning(true)

    } catch (err) {
      showFeedback(formatErrorMessage(err))
    }
    handleShowReplicateModal()
  }

  return (
    <React.Fragment>
      <S.DropdownWrapper>
        <S.DropdownContainer onClick={() => setIsOpen(!isOpen)}>
          <span>Opções</span>
          <S.DropdownButton src={DownArrow} />
        </S.DropdownContainer>
        {isOpen &&
          <Dropdown
            className={'dropdown'}
            options={options}
            action={handleAction}
            close={() => setIsOpen(false)}
          />
        }
      </S.DropdownWrapper>
      <ModalConfirm
        open={showReplicateModal}
        close={handleShowReplicateModal}
        confirm={handleConfirmReplyPlanning}
        title={intl.get('bottles.replicatePlanning')}
        text={getMessageReplyModal(transformPeriod(period), transformPeriod(nextPeriod))}
      />
      <ModalConfirm
        open={showReprojectModal}
        close={handleShowReprojectModal}
        confirm={handleConfirmReprojectModal}
        title={intl.get('bottles.reprojectPlanning')}
        text={getMessageReprojectModal(transformPeriod(filter.periodDate))}
      />
    </React.Fragment>
  )
}
