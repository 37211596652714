import React from "react";
import { 
    Switcher as ToggleExterno
} from 'components/toggle'

const Toggle = ({...rest}) => {
    return (
        <ToggleExterno {...rest}>
        </ToggleExterno>
    )
}

export { Toggle }