import PropTypes from "prop-types"
import { ClbToast, ClbToastBase } from '@celebration/design-system-react';
import { createContext, useState } from 'react'
import { generateRandomNumber } from 'utils/calculations';
const ToastContext = createContext();

const ToastProvider = ({ children }) => {
  const [toast, setToast] = useState([])
  const handleCloseWithTimeout = (id, time) => setTimeout(() => handleClose(id), time);
  const handleClose = (id) => setToast(prevState => prevState.filter(x => x.id != id))

  const openToast = (message, type = "positive", time = 10000) => {
    setToast(prevState => {
      const id = generateRandomNumber()
      handleCloseWithTimeout(id, time);
      return ([...prevState, {
        open: true,
        message: message,
        type: type,
        id: id
      }])
    })
  }

  return (
    <ToastContext.Provider
      value={{
        openToast
      }}
    >
      {children}
      <ClbToast>
        <div style={{ display: 'flex', flexDirection: 'column-reverse' }}>
          {
            toast.map(x => <ClbToastBase
              type={x.type}
              description={x.message}
              open={x.open}
              onClbClickToastClose={() => handleClose(x.id)}
            />
            )
          }
        </div>
      </ClbToast>
    </ToastContext.Provider>
  )
}

ToastProvider.propTypes = {
  children: PropTypes.any.isRequired
}

export { ToastProvider, ToastContext }
