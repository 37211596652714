import PropTypes from "prop-types"
import React  from 'react'
import { TableListPagination } from "@hbsis-planning/hbsis-planning-core";
import intl from 'react-intl-universal'
import { WrapperList } from './styled';
import HeaderList from './header';
import { getColumns } from './utils/table-columns';

const ListZSAG = ({
  data,
  selectAll,
  filters,
  processError
}) => {
  return (
    <WrapperList>
      <HeaderList
        filters={filters}
        processError={processError}
      />
      <TableListPagination
        fetchedData={data}
        columns={getColumns(selectAll)}
        showFooterColumns={false}
        showClearOrder={false}
        renderPageSize={false}
        intlText={true}
        perPage={1000}
        clearOrderText={intl.get('capitalEmployed.clearOrderText')}
        clearFilterTex={intl.get('capitalEmployed.clearFilterText')}
      />
    </WrapperList>
  )
}

ListZSAG.propTypes = {
  data: PropTypes.any.isRequired,
  filters: PropTypes.any.isRequired,
  processError: PropTypes.any.isRequired,
  selectAll: PropTypes.any.isRequired
}

export default ListZSAG;
