import moment from 'moment';

export const getDaysByRange = (dataInicio, dataFim) => {
  const dates = [];
  let currentDate = moment(dataInicio);

  while (currentDate.isSameOrBefore(dataFim)) {
    dates.push(currentDate.format('YYYY-MM-DD'));
    currentDate.add(1, 'days');
  }

  return dates;
}

export const timeRange = {
  horaInicial: '00:00',
  horaFinal: '23:30'
}

export const typesSlots = ["FreeSlot", "ScheduledDelivery", "InNegotiation", "PendingReview", "EmergencyNegotiation"]
