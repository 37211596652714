import { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { StyledMenuLateral, MenuOverlay } from './styledMenuLateral'
import { Grid, Row, Col } from 'react-flexbox-grid'

import SubMenuIndicatorStyled from './submenu-indicator.styled';

const SubMenuIndicator = ({ open }) => (
  <SubMenuIndicatorStyled
    open={open}
    xmlns="https://www.w3.org/2000/svg"
    viewBox="0 0 9 6"
  >
    <polygon points="0,6 4.5,0 9,6" />
  </SubMenuIndicatorStyled>
)

class MenuLateral extends Component {
  constructor(props) {
    super(props)

    this.state = {
      itemSelecionado: ' ',
      listaMenus: []
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState(prevState => ({
      ...prevState,
      listaMenus: this.props.listaMenus.map(item => ({
        ...item,
        selecionado: prevState.itemSelecionado === item.titulo,
      })),
    }))
  }

  clickMenu = (indexSelecionado, rota, titulo) => {
    this.setState(prevState => ({
      itemSelecionado: titulo,
      listaMenus: prevState.listaMenus.map((itemMenu, index) => {
        if (indexSelecionado === index) {
          return { ...itemMenu, selecionado: !itemMenu.selecionado }
        }
        return { ...itemMenu, selecionado: false }
      }),
    }), () => {
      if (rota) {
        if (!this.props.menuSempreExpandido) {
          this.props.onHandleClickOver()
        }
        this.props.onChangeUrl(rota)
      }
    })
  }

  mountMenu = (index, itemMenu) => {
    const menuPrincipal = {
      selecionado: itemMenu.selecionado,
      titulo: itemMenu.titulo
    }

    const menu = [this.mountLineItem(index, itemMenu, false, menuPrincipal)]

    itemMenu.selecionado && itemMenu.subMenus && itemMenu.subMenus.filter(x => x.visivel).map((itemSubMenu, indexSubMenu) => {
        menu.push([this.mountLineItem(parseInt(`${index}${indexSubMenu}`), itemSubMenu, true, menuPrincipal)])
      }
    )

    return menu;
  }

  mountLineItem = (index, itemMenu, subMenu, menuPrincipal) => {
    return (
      <div>
        <li
          key={index}
          onClick={() => this.clickMenu(index, itemMenu.rota, menuPrincipal.titulo)}
          className={cx(`${subMenu && 'sub-menu'} ${menuPrincipal.selecionado && 'menu-selecionado'}`)}
          data-testid={itemMenu.dataTestId}
        >
          <h4 className="icon">{itemMenu.icone}</h4>
          <span className="title-menu">{itemMenu.titulo}</span>

          {itemMenu.subMenus &&
            <div className={cx({ open: menuPrincipal.selecionado }, "menu-item-indicator")}>
              <SubMenuIndicator open={menuPrincipal.selecionado} />
            </div>
          }
        </li>
      </div>
    )
  }

  render() {
    const { exibirMenu, hidden, onHandleClickOver, transitionTime, menuSempreExpandido } = this.props
    const { listaMenus } = this.state;
    return (
      <div>
        <StyledMenuLateral transitionTime={transitionTime} vis={exibirMenu} data-testid="menu-lateral">
          <div className={`${menuSempreExpandido ? 'formSempreExpandido' : 'formMenu'} ${exibirMenu ? 'transition-in' : 'transition-out'} ${hidden ? 'hidden' : ''}`}>
            <Grid fluid className="no-padding">
              <Row className="no-margin">
                <Col className="sideFilter">
                  <div className="sideFilterFields">
                    <ul className="itens-menu">
                      {listaMenus && listaMenus.map((itemMenu, index) => (
                        this.mountMenu(index, itemMenu)
                      ))}
                    </ul>
                  </div>
                </Col>
              </Row>
            </Grid>
          </div>
        </StyledMenuLateral>
        {exibirMenu && <MenuOverlay data-testid="menu-overlay" onClick={onHandleClickOver.bind()} />}
    </div>
    )
  }
}

MenuLateral.propTypes = {
  exibirMenu: PropTypes.bool.isRequired,
  hidden: PropTypes.bool.isRequired,
  onHandleClickOver: PropTypes.func,
  transitionTime: PropTypes.number.isRequired,
  listaMenus: PropTypes.array.isRequired,
  onChangeUrl: PropTypes.func.isRequired,
  menuSempreExpandido: PropTypes.bool
}

MenuLateral.defaultProps = {
  menuSempreExpandido: false
}

export default MenuLateral
