import { OrderNegotiationTypes } from 'models/pedidos/orderNegotiationTypes'
import { isLoadCompositionFlowSupplierSide } from 'utils/validations-general-settings/index'
import { getValidationForWaitingEntranceMerchandise } from "features/orders/components/orders-change-date/utils";

export const mapperItemDataForLoadCompositionModal = (item, isOrder) =>
  ({ ...item, Supplier: getSupplierDescription(item), Material: item.Material.MaterialPesquisa, isOrder: isOrder });

export const isDisableChangeOrderButton = (item) => {
  return !habilitarAntecipacaoAtrasoDoPedido(item.IdComposicao, item.NegociacaoStatus)
    || getValidationForWaitingEntranceMerchandise(item.IntStatus);
}

const getSupplierDescription = (item) => {
  const supplier = item.Fornecedores[0]
  return supplier ? supplier.Descricao : ''
}

const habilitarAntecipacaoAtrasoDoPedido = (idComposicao, lastNegociation) => (
  idComposicao !== null &&
  idComposicao > 0 &&
  lastNegociation !== OrderNegotiationTypes.RenegociacaoDePedidoParaAtenderDemanda &&
  lastNegociation !== OrderNegotiationTypes.EmNegociacaoComposicaoCarga &&
  isLoadCompositionFlowSupplierSide()
)
