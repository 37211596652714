import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { useLoadBuildingContext } from "features/load-building/context"
import { getAllMaterialsService } from "pages/optimization/filter/filter.service"
import { mapperOptionsToSelectBoxClb } from "utils/celebration"
import { normalizeDateFilter } from "./utils"
import { ClbDatePicker, ClbDrawer, ClbMultiSelect } from "libs/celebration"
import intl from 'react-intl-universal'

import * as S from "./styled"

const initialFilterComponent = {
  MaterialNumber: [],
  _minDeliveryDate: '',
  _maxDeliveryDate: '',
  _minCollectDate: '',
  _maxCollectDate: ''
}

export const LoadBuildingDrawerFilter = ({ isOpen, handleClose }) => {
  const [materials, setMaterials] = useState([]);
  const { setFilter, setVolumesSelected } = useLoadBuildingContext();
  const [filterComponent, setFilterComponent] = useState(initialFilterComponent)

  const loadFilters = async () => {
    const _materials = await getAllMaterialsService();
    setMaterials(mapperOptionsToSelectBoxClb(_materials, "Id", "Description"))
  }

  const handleUpdateFilter = (field, value) => {
    setFilterComponent(prevState => ({
      ...prevState,
      [field]: value
    }))
  }

  const handleFilter = (clearFilter) => {
    let _filterComponent = filterComponent;
    if (clearFilter) {
      setFilterComponent(initialFilterComponent)
      _filterComponent = initialFilterComponent
    }
    setVolumesSelected([]);
    setFilter(prevState => ({
      ...prevState,
      _page: 0,
      ...normalizeDateFilter(_filterComponent)
    }));
    handleClose();
  }

  useEffect(() => {
    loadFilters();
  }, [])

  return (
    <ClbDrawer
      title={intl.get('filters.filter')}
      opened={isOpen}
      onClbClose={handleClose}
      buttonsList={[
        {
          id: "btn-close-drawer",
          label: intl.get('master-data.general.actions.back'),
          title: intl.get('master-data.general.actions.back'),
          icon: "ChevronLeft",
          iconPosition: "left",
          dataTestid: "btn-close-drawer",
          callback: () => handleFilter(true),
        },
        {
          id: "btn-filter-drawer",
          label: intl.get('filters.filter'),
          title: intl.get('filters.filter'),
          dataTestid: "btn-filter-drawer",
          callback: () => handleFilter(false),
        },
      ]}
    >
      <S.Wrapper>
        <ClbMultiSelect
          label={intl.get('commons.material')}
          placeholder={intl.get('commons.material')}
          data={materials}
          value={filterComponent.MaterialNumber}
          onClbValueChange={(e) => handleUpdateFilter("MaterialNumber", e.detail.value)}
          data-testid='select-material-filter'
        />
        <S.WrapperDatePicker>
          <div className="datepicker">
            <ClbDatePicker
              id='date-picker-delivery-date-of'
              className='date-picker-clb date-of'
              label={intl.get('loadOptimization.filter.deliveryDateOf')}
              value={filterComponent._minDeliveryDate}
              onClbChangeDate={(e) => handleUpdateFilter("_minDeliveryDate", e.target.value)}
            />
          </div>
          <div className="datepicker">
            <ClbDatePicker
              id='date-picker-delivery-date-to'
              label={intl.get('loadOptimization.filter.deliveryDateBy')}
              className='date-picker-clb date-to'
              value={filterComponent._maxDeliveryDate}
              onClbChangeDate={(e) => handleUpdateFilter("_maxDeliveryDate", e.target.value)}
            />
          </div>
        </S.WrapperDatePicker>
        <S.WrapperDatePicker>
          <div className="datepicker">
            <ClbDatePicker
              id='date-picker-collect-date-of'
              label={intl.get('commons.collectDateDE')}
              className='date-picker-clb date-of'
              value={filterComponent._minCollectDate}
              onClbChangeDate={(e) => handleUpdateFilter("_minCollectDate", e.target.value)}
            />
          </div>
          <div className="datepicker">
            <ClbDatePicker
              id='date-picker-collect-date-to'
              label={intl.get('commons.collectDateAT')}
              className='date-picker-clb date-to'
              value={filterComponent._maxCollectDate}
              onClbChangeDate={(e) => handleUpdateFilter("_maxCollectDate", e.target.value)}
            />
          </div>
        </S.WrapperDatePicker>
      </S.Wrapper>
    </ClbDrawer>
  )
}

LoadBuildingDrawerFilter.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.any.isRequired
}
