import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Fetch from 'utils/fetch'
import Loading from 'components/center-loading'
import { Row, Col } from 'react-flexbox-grid'
import { SelectBox, Button} from "components/uikit-adapter/index"
import StyledFilterMaterial from './StyledFilterMaterial'
import intl from 'react-intl-universal'
import { formatErrorMessage } from 'utils/handle-error';

class FiltroMaterial extends Component {
  constructor(props) {
    super(props)

    this.state = {
      IdMaterial: '',
      materiais: [],
      IdCarteira: '',
      carteiras: [],
      IdFamilia: '',
      familias: [],
      IdFamiliaRotulada: '',
      familiasRotuladas: [],
      showFilter: props.showFilter,
      hidden: true,
      isFetching: false
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const hidden = !nextProps.showFilter

    this.setState({
      showFilter: nextProps.showFilter,
      hidden,
    })

    if (this.state.initialData !== nextProps.initialData && nextProps.showFilter) {
      this.setState({
        initialData: nextProps.initialData,
        IdFamilia: nextProps.initialData.IdFamilia,
        IdMaterial: nextProps.initialData.IdMaterial,
        IdCarteira: nextProps.initialData.IdCarteira,
        IdFamiliaRotulada: nextProps.initialData.IdFamiliaRotulada,
      }, () => {
        this.searchWallets()
        this.searchFamily()
        if (!(this.state.IdCarteira && !this.state.IdFamilia)) {
          this.searchLabeledFamily()
        }
        this.searchMaterial()
      })
    }
  }

  startFetching = () => {
    this.setState({
      isFetching: true
    })
  }

  stopFetching = () => {
    this.setState({
      isFetching: false
    })
  }

  proccessErro = (exception) => {
    this.setState({
      showMsg: true,
      titleMsg: intl.get('stocks.timeline.feedback.errorFound'),
      textMsg: (exception.response && exception.response.status === 400)
        ? formatErrorMessage(exception)
        : intl.get('stocks.timeline.feedback.serverErrorOrder'),
      handleClose: () => {
        this.setState({ showMsg: false })
      }
    })
  }

  changeValue = (name, value) => {
    this.setState({
      [name]: value,
    }, () => {
      if (name === 'IdCarteira' && value != null) {
        this.searchFamily()
        this.setState({
          IdFamilia: null,
          IdFamiliaRotulada: null,
          familiasRotuladas: []
        });
      } else if (name === 'IdFamilia' && value != null) {
        this.searchLabeledFamily()
        this.setState({
          IdFamiliaRotulada: null,
        });
      }
    })
  }

  handleCleanClick = () => {
    const params = {
      IdFamilia: null,
      IdMaterial: null,
      IdCarteira: null,
      IdFamiliaRotulada: null,
    }
    this.setState(params, () => this.props.handleClose(params))
  }

  searchMaterial = () => {
    this.startFetching()

    Fetch.get(`/materials:enabled`)
      .then((response) => this.processMaterialData(response.data))
      .then(this.stopFetching())
      .catch((e) => this.proccessErro(e))
  }

  searchWallets = () => {
    this.startFetching()

    Fetch.get(`/material-groups:all-with-active-materials`)
      .then((response) => this.processWalletsData(response.data))
      .then(this.stopFetching())
      .catch((e) => this.proccessErro(e))
  }

  searchFamily = () => {
    this.startFetching()

    Fetch.get(`/families:all-with-active-materials`)
      .then((response) => this.processFamiliesData(response.data))
      .then(this.stopFetching())
      .catch((e) => this.proccessErro(e))
  }

  searchLabeledFamily = () => {
    this.startFetching()

    Fetch.get(`/labeled-families:all-with-active-materials`)
      .then((response) => this.processLabeledFamiliesData(response.data))
      .then(this.stopFetching())
      .catch((e) => this.proccessErro(e))
  }

  processWalletsData = (data) => {
    this.setState({
      carteiras: data
    })
  }

  processFamiliesData = (data) => {
    this.setState({
      familias: data
    })
  }

  processLabeledFamiliesData = (data) => {
    this.setState({
      familiasRotuladas: data
    })
  }

  processMaterialData = (data) => {
    this.setState({
      materiais: data
    })
  }

  handleCloseSearch = () => {
    const params = {
      IdCarteira: this.state.IdCarteira,
      IdFamilia: this.state.IdFamilia,
      IdFamiliaRotulada: this.state.IdFamiliaRotulada,
      IdMaterial: this.state.IdMaterial,
    }
    this.props.handleClose(params)
  }

  render() {
    const { carteiras, familias, familiasRotuladas, materiais, isFetching } = this.state
    return (
      <StyledFilterMaterial>
        <Loading isLoading={isFetching} />
        <div className="filter-container">
          <div className="sideFilterFields">
            <Row>
              <Col xs={12} lg={12} md={12}>
                <SelectBox
                  name='carteira'
                  label={intl.get('commons.wallet')}
                  value={this.state.IdCarteira}
                  options={carteiras}
                  valueKey='Id'
                  labelKey='Description'
                  onChange={value => this.changeValue('IdCarteira', value === null ? null : value.Id)}
                  placeholder={intl.get('commons.wallet')}
                  searchable
                  openOnFocus
                  clearable
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={12} md={12}>
                <SelectBox
                  name='familia'
                  label={intl.get('commons.family')}
                  value={this.state.IdFamilia}
                  options={familias}
                  valueKey='Id'
                  labelKey='Name'
                  onChange={value => this.changeValue('IdFamilia', value === null ? null : value.Id)}
                  placeholder={intl.get('commons.family')}
                  clearValueText='Limpar'
                  searchable
                  openOnFocus
                  clearable
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={12} md={12}>
                <SelectBox
                  name='familiaRotulada'
                  label={intl.get('commons.labeledFamily')}
                  value={this.state.IdFamiliaRotulada}
                  options={familiasRotuladas}
                  valueKey='Id'
                  labelKey='Description'
                  onChange={value => this.changeValue('IdFamiliaRotulada', value === null ? null : value.Id)}
                  placeholder={intl.get('commons.labeledFamily')}
                  clearValueText='Limpar'
                  searchable
                  openOnFocus
                  clearable
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={12} md={12}>
                <SelectBox
                  name='material'
                  label={intl.get('commons.material')}
                  value={this.state.IdMaterial}
                  options={materiais}
                  valueKey='Id'
                  labelKey='MaterialSearch'
                  onChange={value => this.changeValue('IdMaterial', value === null ? null : value.Id)}
                  placeholder={intl.get('commons.material')}
                  clearValueText='Limpar'
                  searchable
                  openOnFocus
                  clearable
                />
              </Col>
            </Row>
          </div>

          <Row className="footerFilterContainer no-margin">
            <Col xs={12}>
              <Row>
                <Col xs={12} lg={12} md={12}>
                  <hr className="filter-divisor" />
                </Col>
              </Row>
              <Row>
                <Col xs={6} lg={6} md={6}>
                  <Button type="primary" onClick={this.handleCloseSearch} value={intl.get('filters.search')} />
                </Col>
                <Col xs={6} lg={6} md={6}>
                  <Button type="secondary" onClick={this.handleCleanClick} value={intl.get('filters.cleanSearch')} />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </StyledFilterMaterial>
    )
  }
}

FiltroMaterial.propTypes = {
  showFilter: PropTypes.bool,
  initialData: PropTypes.object,
  transitionTime: PropTypes.number,
  handleClose: PropTypes.func,
}

FiltroMaterial.defaultProps = {
  showFilter: false,
  transitionTime: 500,
  initialData: {}
}

export default FiltroMaterial
