import styled from "styled-components";

const StyledInformationOrder = styled.div`
  .span {
    line-height: 1.8rem;
    font-size: 12px;
    font-weight: var(--font-weight-semibold);
    text-transform: uppercase;
    color: var(--color-contrast-brand);
  }
  
  .span .content {
    font-size: 15px;
  }

  `;

export default StyledInformationOrder;