import Fetch from 'utils/fetch';

export const AcceptCancel = (idPedido) => {
  return Fetch.put(`/pedido/AcceptRequestCancellation/${idPedido}`).then(({ data }) => data);
}

export const RefuseCancel = (idPedido) => {
  return Fetch.put(`/pedido/RefuseRequestCancellation/${idPedido}`).then(({ data }) => data);
}

export const RefuseCheckCancel = (idPedido) => {
  return Fetch.put(`/pedido/ConfirmCancellationRefused/${idPedido}`).then(({ data }) => data);
}

