import PropTypes from "prop-types"
import {
  ClbTableTBodyCell
} from '@celebration/design-system-react'
import { StatusStock } from 'components/status-stock-tag';

const materials = (materials) => {
  const descriptions = materials.map(material => material.Description);
  return descriptions.join(', ');
}

export const HeaderTableBody = ({ data }) => (
  <>
    <ClbTableTBodyCell className='materialGroupDescriptionCell'>
      {data.MaterialGroupDescription}
    </ClbTableTBodyCell>
    <ClbTableTBodyCell className='statusStock'>
      <StatusStock status={data.StockProjectionStatus} />
    </ClbTableTBodyCell>
    <ClbTableTBodyCell className='destiny'>
      {data.Destiny}
    </ClbTableTBodyCell>
    <ClbTableTBodyCell>
      <div className='labeledFamilyDescription' title={materials(data?.Materials)}>
        {data.LabeledFamilyDescription}
      </div>
    </ClbTableTBodyCell>
  </>
)

HeaderTableBody.propTypes = {
  data: PropTypes.shape({
    Destiny: PropTypes.any,
    LabeledFamilyDescription: PropTypes.any,
    MaterialGroupDescription: PropTypes.any,
    Materials: PropTypes.any,
    StockProjectionStatus: PropTypes.any
  }).isRequired
}