import PropTypes from "prop-types"
import React, { useState } from 'react'
import TableList from 'components/table-list'
import { Columns } from './columns'
import { useOrdersContext } from '../../context'
import { buildListItem } from '../../utils'
import { Grid } from './styled'
import ModalLoadComposition from 'components/modal-load-composition'

const OrdersList = ({ data }) => {
  const {
    checkbox,
    setCheckbox
  } = useOrdersContext()

  const [modal, setShowModal] = useState(false)
  const [order, setOrder] = useState({})

  const handleAction = (record) => {
    setOrder(record)
    setShowModal(true)
  }

  const handleChange = (checked, record) => {
    const id = record?.Id;

    if (id) {
      if (!checkbox.includes(id)) {
        setCheckbox(prevState => [...prevState, id])
      } else {
        const selecteds = checkbox.filter(orderCheckbox => orderCheckbox !== id)
        setCheckbox([...selecteds])
      }
    }

    if (checked && !id) {
      setCheckbox([...data.map(i => i.Id)])
    }

    if (!checked && !id) {
      setCheckbox([])
    }
  }

  const handleRefresh = () => {
    setShowModal(false);
    window.location.reload();
  }

  const redirectRoute = (_route) => {
    window.location.href = _route;
  }

  return (
    <React.Fragment>
      <Grid>
        <TableList
          scope='Orders'
          columns={Columns()}
          data={data.map(item => buildListItem(item))}
          onChange={handleChange}
          selected={checkbox}
          borderColor={'#ffc107'}
          action={handleAction}
        />
      </Grid>
      {modal &&
        <ModalLoadComposition
          open={modal}
          compositionId={order.LoadCompositionId}
          redirectRoute={redirectRoute}
          handleRefresh={handleRefresh}
          readingMode={order.IsReadModeLoadComposition}
          close={() => setShowModal(false)}
        />
      }
    </React.Fragment>
  )
}

OrdersList.propTypes = {
  data: PropTypes.shape({
    map: PropTypes.func
  }).isRequired
}

export default OrdersList


