import { Container, Banner, Content } from "./404.styled";
import ambevHbsisLogo from '../../images/login/ambevHbsis.png';
import abOntimeLogo from '../../images/abOntimeLogo.png'
import slogan from '../../images/login/slogan.png';
import intl from 'react-intl-universal'
import { getAuthenticationProvider } from 'utils/config-environment';
import LogoutFromKc from "./components/logout-from-kc";
import LogoutFromB2C from "./components/logout-from-b2c";

const ErrorPage = () => {
  const provider = getAuthenticationProvider();

  return (
    <Container>
      <Banner>
        <img src={abOntimeLogo} id='ontime-logo' alt='ontime-logo' />
        <img src={slogan} id='slogan' alt='slogan' />
        <img src={ambevHbsisLogo} id='ambevHbsis-logo' alt='ambevHbsis-logo' />
      </Banner>
      <Content>
        <div style={{ display: 'flex', flexFlow: 'column', alignItems: 'center', margin: '2%', textAlign: 'center' }}>
          {intl.get('errorPage.title')}
          {provider !== 'B2C' ?
            <LogoutFromKc /> :
            <LogoutFromB2C />
          }
        </div>
      </Content>
    </Container >
  )
}

export default ErrorPage
