import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledIcon = styled.img`
  
`
const Icon = ({ src, className, ...rest }) => (
  <StyledIcon src={`${src}`} className={`${className}`} {...rest} />
)

Icon.defaultProps = {
  size: 14,
}

Icon.propTypes = {
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default Icon
