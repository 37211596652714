import { useEffect, useState } from 'react'
import Dialog from 'material-ui/Dialog'
import { Button } from 'components/uikit-adapter/index'
import Table from './table'
import * as S from './styled'
import intl from 'react-intl-universal'
import Loading from 'components/center-loading'

import {
  getMaterialContracts
} from 'pages/grid-vision/grid-vision.service.js'
import PropTypes from 'prop-types'

const Modal = ({ open, close, idMaterial }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [contracts, setContracts] = useState([])
  const searchContracts = async () => {
    setIsLoading(true)
    const data = await getMaterialContracts(idMaterial)
    setContracts(data)
    setIsLoading(false)
  }

  useEffect(() => {
    searchContracts()
  }, [])

  return (
    <S.DialogWrapper>
      <Dialog
        open={open}
        title={
          <S.WrapperHeader>
            <S.Title>
              {intl.get('master-data.general.materials.titleMaterialContracts')}
            </S.Title>
          </S.WrapperHeader>
        }
        actions={
          <S.ActionButton>
            <Button
              type="primary"
              value={intl.get('geral.buttonsDefault.close')}
              width='200px'
              onClick={close}
            />
          </S.ActionButton>
        }
        contentStyle={
          {
            maxWidth: '90%',
            width: '90%'
          }}>
        <Loading
          isLoading={isLoading}
          fullHeightParent
        />
        <S.Wrapper>
          <Table data={contracts} />
        </S.Wrapper>
      </Dialog>
    </S.DialogWrapper>
  )
}

Modal.propTypes = {
  open: PropTypes.bool,
  idMaterial: PropTypes.string,
  close: PropTypes.func
}

export default Modal
