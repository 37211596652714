import PropTypes from "prop-types"
import React, { useEffect, useState } from 'react'
import * as S from './styled'
import intl from 'react-intl-universal'
import { useLoadCompositionBuildingContext } from '../../context/load-composition-building-context'
import SideFilter from '../side-filter/index'
import { verifyIsFiltered } from 'utils/custom-functions'
import { typesSuggestion } from '../../context/utils'
import { IconFilterActive } from 'components/icons/icn-filter-active.icon'
import { IconFilterInactive } from 'components/icons/icn-filter-inactive.icon'

const HeaderCards = ({ openModal, businessUnitId }) => {
  const [openFilter, setOpenFilter] = useState(false);
  const [statusSelected, setStatusSelected] = useState();
  const {
    filter,
    handleReloadDataCards,
  } = useLoadCompositionBuildingContext()
  
  const statusList = [
    {
      statusCode: "AwaitLoadComposition",
      description: intl.get('loadComposition.building.awaitingLoadComp')
    },
    {
      statusCode: "Suggestions",
      description: intl.get('loadComposition.building.suggestions')
    },
    {
      statusCode: "Liberado",
      description: intl.get('loadComposition.building.released')
    }
  ]; 

  const handleChangeStatus = (value) => {
    setStatusSelected(value);
    handleReloadDataCards({ typeSuggestionSelected: typesSuggestion[value.statusCode] })
  }

  const openCloseFilter = () => setOpenFilter(prev => !prev);

  const getIconFilter = () => verifyIsFiltered({...filter, pagination: null, typeSuggestionSelected: null}) ? <IconFilterActive /> : <IconFilterInactive />;

  useEffect(() => {
    handleChangeStatus({
      statusCode: "Suggestions",
      description: intl.get('loadComposition.building.suggestions')
    })
  }, []);

  return (
    <React.Fragment>
      <S.Wrapper>
        <S.TitlesWrapper>
          <S.Title>
            {intl.get('loadComposition.building.orderTypes')}
          </S.Title>
        </S.TitlesWrapper>
        
        <S.ContentWrapper>
          <S.SelectBox
            name='status'
            valueKey="statusCode"
            labelKey="description"
            value={statusSelected}
            onChange={value => handleChangeStatus(value)}
            options={statusList}
            searchable
            openOnFocus
            data-testid='selectbox-order-status'
            width={'320px'}
          />

          <S.ButtonFilter
            onClick={openCloseFilter}
            type="default"
            buttonIcon={getIconFilter()}
            className='button-filter-icon'
          />

          <S.ButtonWrapper id='create-suggestion'>
            <S.Button
              type='default'
              value={intl.get('loadComposition.filter.buttonTitle')}
              onClick={() => openModal("", false)}
              hide={filter.typeSuggestionSelected === typesSuggestion.Liberado}
            />
          </S.ButtonWrapper>
        </S.ContentWrapper>
      </S.Wrapper>
    
      <SideFilter
        open={openFilter}
        close={openCloseFilter}
        businessUnitId={businessUnitId}
      />
    </React.Fragment>
  )
}

HeaderCards.propTypes = {
  businessUnitId: PropTypes.any.isRequired,
  openModal: PropTypes.func.isRequired
}

export default HeaderCards;
