import styled from 'styled-components'

export const DataWrapper = styled.div`
  width: 100vw;
  height: calc(100vh - 155px);

  span{
    cursor: auto;
  }

  input[type="date"] {
    padding:3px;
    margin: 0px;
    & ::-webkit-inner-spin-button,
    & ::-webkit-calendar-picker-indicator {
        margin: 0px;
        margin-right: 1px;
        cursor: pointer;
      }
    }
`

export const NoDataWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;

  h2{
    font-weight: var(--font-weight-regular);
  }

  img{
    width: 200px;
  }

  @media(max-height: 600px){
    .no-data-image{
      display: none;
    }
  }
`
export const CheckboxWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-left: solid 5px ${p => p.colorRow ? p.colorRow : "var(--color-action-hover)"};
  position: relative;
  right: 15px;

  .checkbox{
    position: relative;
    left: 11px;
  }
`
