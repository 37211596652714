import React from 'react'
import PropTypes from 'prop-types'
import intl from 'react-intl-universal'
import moment from 'moment'

const SuggestedDeliveryDateTime = ({
  suggestedDeliveryDate,
  suggestedDeliveryTime
}) => (
  <div>
    <div className="negotiation-read-offer">
      <div className="negotiation-read-column">
        <span>{intl.get('stocks.timeline.deliveryDate')}</span>
        <span>{moment(suggestedDeliveryDate).format('L')}</span>
      </div>
    </div>
    <div className="negotiation-read-column">
      <span>{intl.get('commons.deliveryTime')}:</span>
      <span>{moment(suggestedDeliveryTime, "HH:mm:ss").format("LT")}</span>
    </div>
  </div>
);

SuggestedDeliveryDateTime.propTypes = {
  suggestedDeliveryDate: PropTypes.string.isRequired,
  suggestedDeliveryTime: PropTypes.string.isRequired,
}

export default SuggestedDeliveryDateTime
