class StorePersist {
  getValue(name) {
    return localStorage.getItem(name)
  }

  getValuesJSON(name) {
    const value = localStorage.getItem(name);
    return value ? JSON.parse(value) : null
  }

  setValue(name, value) {
    localStorage.setItem(name, value)
  }

  setValuesJSON(name, values) {
    localStorage.setItem(name, JSON.stringify(values));
  }

  remove(name) {
    localStorage.removeItem(name);
  }
}

export default new StorePersist();
