import { getDefaultLocale } from "react-datepicker"

export const compareDates = (shouldBeBiggerDate, shouldBeLesserDate) => {
  if (!shouldBeBiggerDate || !shouldBeLesserDate)
    return undefined

  const date1 = new Date(shouldBeBiggerDate).getTime()
  const date2 = new Date(shouldBeLesserDate).getTime()

  if (date1 > date2) {
    return 1
  }
  if (date1 === date2) {
    return 0
  }
  return -1
}

export const getDateFormat = () => {
  switch (getDefaultLocale()) {
    case "pt-BR":
    case "es-ES":
    case "en-GB":
      return "dd/MM/yyyy";
    case "en-US":
    default:
      return "MM/dd/yyyy";
  }
}

export const getDatePlaceholder = () => {
  switch (getDefaultLocale()) {
    case "pt-BR":
    case "es-ES":
    case "en-GB":
      return "dd/mm/yyyy";
    case "en-US":
    default:
      return "mm/dd/yyyy";
  }
}

export const getTodayDate = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return today;
}

export const getMinDateInList = (list = []) => {
  const date = new Date((Math.min.apply(null, list)))
  if (!isNaN(date)) {
    return date.toISOString()?.slice(0, 10)
  } else {
    return null;
  }
}

export const getMaxDateInList = (list = []) => {
  const date = new Date((Math.max.apply(null, list)))
  if (!isNaN(date)) {
    return date.toISOString()?.slice(0, 10)
  } else {
    return null;
  }
}

/**
 * Converts a duration value from either days or hours based on the specified flag.
 *
 * @param {Number} value - The input value representing either days or hours.
 * @param {boolean} useHourlyPlan - A flag to indicate whether to convert to hours (true) or not (false).
 * @returns {Number} - The converted value.
 *
 */
export const convertTransitTimeToDaysByFlag = (value, useHourlyPlan) => {
  if (useHourlyPlan) {
    return parseInt(Number(value) / 24);
  }
  return value;
}