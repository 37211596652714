import React from "react"
import * as S from "./styled"

const InsumosInput =
  ({
    ...rest
  }) => (
  <S.InsumosInput
    {
      ...rest
    }
  />
)

export default InsumosInput
