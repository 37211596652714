import styled from 'styled-components'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 317px);
  grid-row-gap: 12px;
  grid-column-gap: 28px;
  justify-content: center;
  justify-items: center;
`
