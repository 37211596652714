import intl from 'react-intl-universal'
import * as permissions from 'services/permissions/getPermissions'

export const Columns = () => ([
  {
    prop: 'Checkbox',
    name: '',
    visible: true
  },
  {
    prop: 'Stage',
    name: intl.get("commons.stage"),
    visible: true
  },
  {
    prop: 'Id',
    name: intl.get("commons.orderCode"),
    visible: true
  },
  {
    prop: 'SupplierName',
    name: intl.get("commons.source"),
    visible: true
  },
  {
    prop: 'BusinessUnit',
    name: intl.get("commons.destiny"),
    visible: true
  },
  {
    prop: 'Material',
    name: intl.get("commons.material"),
    visible: true
  },
  {
    prop: 'VolumeOrderCollectDate',
    name: intl.get('commons.colDate'),
    visible: true
  },
  {
    prop: 'VolumeOrderDeliveryDate',
    name: intl.get("commons.deliveryDate"),
    visible: true
  },
  {
    prop: 'StockUnitCurrent',
    name: intl.get("requestOrders.accStock"),
    visible: permissions.userAmbev()
  },
  {
    prop: 'Quantity',
    name: intl.get("commons.quantity"),
    visible: permissions.userFornecedor()
  },
  {
    prop: 'VolumeOrderStatusDescription',
    name: intl.get("commons.status"),
    visible: true
  }
])
