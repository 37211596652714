import Session from 'utils/user-storage';
import TipoPerfilAcesso from "../models/usuarios/tipo-perfil-acesso";
import TipoUsuario from 'models/usuarios/tipo-usuario';


export const isUserAmbev = () => Session.get().PerfilAcesso === TipoPerfilAcesso.PciSolutions
  || Session.get().PerfilAcesso === TipoPerfilAcesso.MasterData
  || Session.get().PerfilAcesso === TipoPerfilAcesso.Gestor
  || Session.get().PerfilAcesso === TipoPerfilAcesso.Administrador;


export const isUseFilterDefault = () => {
  const isOnlyMyItems = localStorage.getItem('filterMyItemsOnly')
  return isOnlyMyItems && JSON.parse(isOnlyMyItems);
}

export const isUserSupplier = () => Session.get().TipoUsuario === TipoUsuario.Fornecedor;

export const getUserId = () =>  Session.get().Id;