import PropTypes from "prop-types"
import { useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { withStyles } from '@material-ui/core'
import intl from 'react-intl-universal'
import { ConsolidatedStocksSuggestionListHeader } from '../consolidated-stocks-suggestion-list/suggestion-list-header'
import { ConsolidatedStocksSuggestionListItem } from '../consolidated-stocks-suggestion-list/suggestion-list-item'
import { acceptSuggestions, getSuggestionsList } from 'features/consolidated-stocks/consolidated-stocks.service'
import StyledSuggestionList from 'components/suggestions-list/suggestions-list.styled'
import * as S from './styled'
import { Button } from 'components/uikit-adapter'
import Session from 'utils/user-storage'
import { ArrowDetail } from 'pages/load-composition-building/components/modal-create-suggestion/styled'
import { ContentChart } from '../consolidated-stocks-suggestions-modal/styled'
import ChartStocks from 'components/chart-stocks'
import { useGlobalContext } from 'hooks/useGlobalContext'
import { proccessErro } from './utils'
import CircularProgress from 'material-ui/CircularProgress'
import { runStockProjection } from 'services/labeled-family-center.service'

export const ConsolidatedStocksSuggestionsModal = ({ open, close, labeledFamilyCenterId }) => {
  const { showFeedback } = useGlobalContext()
  const [updateChart, setUpdateChart] = useState(labeledFamilyCenterId)
  const [isLoading, setIsLoading] = useState(false)

  const StyledDialogContent = withStyles(() => ({
    root: {
      zIndex: 800,
      borderBottom: 'none'
    }
  }))(DialogContent)

  const [data, setData] = useState([])
  const [checkAll, setCheckAll] = useState(false)

  const getModalData = async () => {
    const { Data } = await getSuggestionsList({
      Status: [1,3,4,5],
      LabeledFamilyCenterId: [labeledFamilyCenterId]
    })
    setData(Data)
  }

  const handleUpdateGraphic = (message) => {
    setUpdateChart(prevState => ({ ...prevState }))
    showFeedback(message)
  }

  const handleAcceptSuggestion = async () => {
    setIsLoading(true)
    try {
      const items = data.filter((item) => item.checked).map((_) => _.Id)
      await acceptSuggestions({
        UserId: Session.get().Id,
        LabeledFamilyCenterId: labeledFamilyCenterId,
        Suggestions: items
      })

      getModalData()
      await runStockProjection(labeledFamilyCenterId);
      handleUpdateGraphic(intl.get('stocks.timeline.feedback.successfullyAcceptedSuggestions'))
    } catch (error) {
      proccessErro(showFeedback, error)
    }
    setIsLoading(false)
  }

  const handleCheckAllItems = () => updateData()

  const updateData = (newItem, index) => {
    const newData = data.map((item, i) => {
      if (!newItem) {
        setCheckAll(!checkAll)
        return {
          ...item,
          checked: !checkAll
        }
      }
      if (index === i) {
        return {
          ...newItem
        }
      }
      else {
        return {
          ...item
        }
      }
    })
    setData(newData)
  }

  useEffect(() => {
    if (open) {
      getModalData()
    }
  }, [open])

  return (
    <Dialog
      PaperProps={{
        style: {
          overflow: 'visible',
          width: "560px",
          maxWidth: '720px',
          position: "relative",
          right: "250px",
          minHeight: "840px",
          maxHeight: "840px",
          zIndex: 999
        }
      }}
      open={open}
    >
      <S.DialogTitle>
        <S.DialogTitleText>
          {intl.get("stocks.stockDetail.accSuggestion")}
        </S.DialogTitleText>
        <S.DialogTitleIcon title='Fechar' onClick={() => close()} />
      </S.DialogTitle>
      <StyledDialogContent style={{ overflowY: 'scroll' }}>
        {
          isLoading ?
            <div className='circular-progress'>
              <CircularProgress style={{
                position: 'absolute',
                color: 'var(--color-brand-300)',
                top: '49%',
                left: '49%'
              }} />
            </div>
            :
            <>
              {
                data.length === 0 ?
                  <div style={{ textAlign: 'center' }}>
                    {intl.get('commons.noRecordsFound')}
                  </div>
                  :
                  <StyledSuggestionList>
                    <ConsolidatedStocksSuggestionListHeader
                      checked={checkAll}
                      checkAllItems={handleCheckAllItems}
                    />
                    {data.map((item, index) => (
                      <ConsolidatedStocksSuggestionListItem
                        key={item.Id}
                        item={item}
                        index={index}
                        checked={item.checked}
                        onSaveItem={updateData}
                        updateGraphic={handleUpdateGraphic}
                      />
                    ))}
                  </StyledSuggestionList>
              }
            </>
        }
      </StyledDialogContent>
      <S.DialogActions>
        <Button
          width="165px"
          type="primary"
          value={intl.get("stocks.stockDetail.accSuggestion")}
          onClick={() => handleAcceptSuggestion()}
          disabled={data.length === 0}
        />
      </S.DialogActions>
      <ContentChart>
        <ArrowDetail />
        <ChartStocks
          idFamiliaRotuladaCentro={labeledFamilyCenterId}
          updateChart={updateChart}
          hideLastUpdate
        />
      </ContentChart>
    </Dialog>
  )
}

ConsolidatedStocksSuggestionsModal.propTypes = {
  close: PropTypes.func.isRequired,
  labeledFamilyCenterId: PropTypes.any.isRequired,
  open: PropTypes.any.isRequired
}