function session() {
  const USER_STORAGE = "user";
  return {
    save: (data) => localStorage.setItem(USER_STORAGE, JSON.stringify(data)),
    get: () => JSON.parse(localStorage.getItem(USER_STORAGE)),
    clear: () => localStorage.removeItem(USER_STORAGE),
  };
}

export default session();
