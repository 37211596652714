import styled from 'styled-components'

const StyledModalCompleteTask = styled.div`

  .modal-button {
    width: 80px;
    float: right;
    margin-left: 5px;
  }
`

export default StyledModalCompleteTask