import "moment/locale/en-gb";

const momentEnGb = {
  parentLocale: "en-gb",
  longDateFormat: {
    "[DM]": "DD/MM",
    "[DDMMYY]": "DD/MM/YY"
  }
};

export default momentEnGb;
