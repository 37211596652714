export const transformPeriod = (data) => {
  const transform = {
    '0': 'Janeiro',
    '1': 'Fevereiro',
    '2': 'Março',
    '3': 'Abril',
    '4': 'Maio',
    '5': 'Junho',
    '6': 'Julho',
    '7': 'Agosto',
    '8': 'Setembro',
    '9': 'Outubro',
    '10': 'Novembro',
    '11': 'Dezembro'
  }

  const split = data.year.toString().split("0")[1]
  return `${transform[data.month]}/${split}`
}

