const TipoTarefa = Object.freeze({
  None: 0,
  FuroColeta: 1,
  FuroEntrega: 2,
  InclusaoPedidoUrgente: 3,
  PedidoAguardandoDescarga: 4,
  PedidoAguardandoComposicaoCarga: 5,
  RegraContrapropostaPendente: 6,
  PedidoPendenteAnaliseComposicaoCargaAmbev: 7,
  PedidoEmOverPendente: 8,
  EstoqueEmOver: 9,
  CienciaPendente: 10,
  RenegociacaoEmergencialDataRecusada: 11,
  RenegociacaoEmergencialData: 12,
  AvisoRecebimentoNaoCriado: 13,
  CancelamentoPedidoAguardandoCiencia: 14,
  AguardandoAnaliseCancelamento: 15,
  CancelamentoRecusadoAguardandoCiencia: 16,
  TarefaComposicaoCargaAguardardandoRevisao: 17,
  TarefaComposicaoEmNegociacao: 18,
  TarefaComposicaoEmRenegociacaoEmergencial: 19,
  EstoqueEmOverCritico: 20,
  LoadCompositionWithoutFullTruck: 21,
  VolumePendenteDeAceite: 22,
  VolumeCienciaPendente: 23,
});

export default TipoTarefa;
