import { StatusPedido } from "../../models/pedidos/statusPedido";
import TipoPerfilAcesso from "../../models/usuarios/tipo-perfil-acesso";

export const usuarioPodeCancelarPedido = (perfilUsuario, tipoPedido) => {
  switch (perfilUsuario) {
    case TipoPerfilAcesso.PciSolutions:
      return usuarioPciPodeCancelarPedido(tipoPedido);
    case TipoPerfilAcesso.Administrador:
    case TipoPerfilAcesso.MasterData:
    case TipoPerfilAcesso.Gestor:
      return usuarioAdminOuMasterDataPodeCancelarPedido(tipoPedido);
    default:
      return false;
  }
};

const usuarioPciPodeCancelarPedido = (tipoPedido) => {
  switch (tipoPedido) {
    case StatusPedido.EmNegociacao:
    case StatusPedido.AguardandoComposicaoCarga:
    case StatusPedido.EmNegociacaoComposicaoCarga:
    case StatusPedido.EmNegociacaoEmergencial:
    case StatusPedido.AguardandoAvisoRecebimento:
    case StatusPedido.AguardandoPcSap:
    case StatusPedido.AguardandoAprovacaoGestor:
    case StatusPedido.AguardandoCiencia:
      return true;
    default:
      return false;
  }
};

const usuarioAdminOuMasterDataPodeCancelarPedido = (tipoPedido) => {
  switch (tipoPedido) {
    case StatusPedido.EmNegociacao:
    case StatusPedido.AguardandoComposicaoCarga:
    case StatusPedido.EmNegociacaoComposicaoCarga:
    case StatusPedido.EmNegociacaoEmergencial:
    case StatusPedido.AguardandoAvisoRecebimento:
    case StatusPedido.AguardandoNotaRecebimentoInspecao:
    case StatusPedido.AguardandoEntradaMercadoria:
    case StatusPedido.AguardandoPcSap:
    case StatusPedido.AguardandoFornecimento:
    case StatusPedido.AguardandoAprovacaoGestor:
    case StatusPedido.EmRenegociacao:
      return true;
    default:
      return false;
  }
};
