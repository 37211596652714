import PropTypes from "prop-types"
import React, { createContext, useCallback, useState } from 'react'
import SnackBar from 'material-ui/Snackbar'
import { OperationProgress } from '../components/operation-progress'
const GlobalContext = createContext();

const GlobalProvider = ({ children }) => {
  const [feedback, setFeedback] = useState({
    open: false,
    message: ""
  })

  const [operation, setOperation] = useState({
    inProgress: false,
    isFinished: false,
    requests: [],
    text: ''
  })

  const showFeedback = useCallback((message) => {
    setFeedback({
      open: true,
      message: message
    })
  }, [])

  const hideFeedback = useCallback(() => {
    setFeedback({
      open: false,
      message: ""
    })
  }, [])

  /**
   *
   * @param {*} requests Objeto que contém todas as requests e seu corpo
   * Exemplo: {
   *   API: 'Rota com params se necessário',
   *   method: (get,post,put, patch, delete)
   *   body: Objeto contendo corpo da requisição caso necessário.
   *   errorId: Nome da prop, que da vinculo a linha que deu erro, caso haja log.
   *   text: Label que irá aparecer no tooltip da operação.
   * }
   * @returns
   */
  const startOperation = (requests, text) =>
    setOperation({
      inProgress: true,
      isFinished: false,
      requests,
      text
    })

  const endOperation = () =>
    setOperation({
      inProgress: false,
      isFinished: true
    })

  return (

    <GlobalContext.Provider
      value={{
        showFeedback,
        startOperation,
        endOperation,
        operation
      }}
    >
      {children}
      {operation.inProgress &&
        <OperationProgress
          requests={operation.requests}
          text={operation.text}
        />
      }
      <SnackBar
        message={feedback.message}
        open={feedback.open}
        autoHideDuration={3000}
        onRequestClose={hideFeedback}
        style={{ zIndex: '9999' }}
      />
    </GlobalContext.Provider>
  )
}

GlobalProvider.propTypes = {
  children: PropTypes.any.isRequired
}

export { GlobalProvider, GlobalContext }
