import moment from 'moment'
import intl from 'react-intl-universal'

export const transformOptions = (data) => {
  return data.filter(supplier => supplier.Data[0].SupplierId !== null)
    .map((_) => ({ label: _.Name }))
}

export const getPeriod = (filter) => ({
  startDate: moment([filter.periodDate.year, filter.periodDate.month]).startOf('month').format('YYYY-MM-DD'),
  endDate: moment([filter.periodDate.year, filter.periodDate.month]).endOf('month').format('YYYY-MM-DD')
})

export const getTextForDeleteModal = (selectedSupplier) => {
  const textConfirm = intl.get('bottles.modal.delete.textConfirm')

  if (selectedSupplier.Id !== null) {
    const subText = intl.get('bottles.modal.delete.textConfirm3')

    return `${textConfirm} ${subText} "${selectedSupplier.Name}"?`
  } else {
    const subText = intl.get('bottles.modal.delete.textConfirm2')

    return `${textConfirm} ${subText} "${selectedSupplier.Name}"?`
  }
}

export const getTextForReplicateModal = (selectedSupplier, nameOfSupplierToReplicate) => {
  const textConfirm = intl.get('bottles.modal.replicateSimulation.textConfirm')
  const firstSubText = intl.get('bottles.modal.replicateSimulation.to.Fornecedor')

  return `${textConfirm} "${selectedSupplier.Name}" ${firstSubText} "${nameOfSupplierToReplicate}"? ` 
}

export const getTextForAlert = (selectedSupplier, nameOfSupplierToReplicate) => {
  const textConfirm = intl.get('bottles.modal.replicateSimulation.alert.allData')
  const subText = intl.get('bottles.modal.replicateSimulation.alert.replace')

  return `${textConfirm} "${selectedSupplier.Name}" ${subText} "${nameOfSupplierToReplicate}"`
}