export const normalizeGettingData = (data) => {
  const _data = {
    Id: data.Id,
    IdFamiliaRotuladaCentro: data.LabeledFamilyCenterId,
    IdMaterial: data.MaterialId,
    NomeMaterial: data.MaterialName,
    MaterialAtivo: data.ActiveMaterial,
    MaterialEstoque: data.StockMaterial,
    DataDescontinuado: data.DiscontinuedDate,
    JustificativaDescontinuado: data.JustificationDiscontinued,
    Fornecedores: data.Suppliers.map((item) => ({
      Id: item.Id,
      CriarComposicaoCargaAutomatica: item.CreateAutomaticLoadComposition,
      Arredondamento: item.Rounding,
      LoteMinimo: item.MinimumLot,
      QuebraEntrega: item.DeliveryBreak,
      LoteMinimoErp: item.MinimumLotErp,
      Leadtime: item.Leadtime,
      PortToDoorTransitTime: item.PortToDoorTransitTime,
      ResponsavelEntrega: item.ResponsibleForDelivery,
      SLA: item.SLA,
      TransitTime: item.TransitTime,
      LeadtimeErp: item.LeadtimeErp,
      IdFornecedor: item.SupplierId,
      IdUnidadeArredondamento: item.RoundingUnitId,
      CodigoImposto: item.TaxCode,
      DescricaoUnidadeArredondamento: item.RoundingUnitDescription,
      GrupoCompradores: item.BuyersGroup,
      Incoterm: item.Incoterm,
      Nome: item.Name,
      OrganizacaoCompras: item.PurchasingOrganization,
      UsaAceiteAutomaticoVolume: item.UseAutomaticVolumeAcceptance,
      UseAcceptAutomaticOrder: item.UseAcceptAutomaticOrder,
      Tipo: item.Type
    })),
    IdUnidadeArredondamento: data.RoundingUnitId,
    DescricaoUnidadeArredondamento: data.RoundingUnitDescription,
    IdResponsavel: data.ResponsibleId,
    Responsavel: data.Responsible,
    CriarComposicaoCargaAutomatica: data.CreateAutomaticLoadComposition,
    Periodicity: data.Periodicity,
    OnlyTransferOrder: data.OnlyTransferOrder,
    Deflator: data.Deflator,
    DeflationPercentage: data.DeflationPercentage,
    UseHourlyPlan: data.UseHourlyPlan
  }

  return _data;
}

export const normalizeDataToSave = (data) => {
  const _data = {
    Id: data?.Id,
    ActiveMaterial: data?.MaterialAtivo,
    DiscontinuedDate: data?.DataDescontinuado,
    JustificationDiscontinued: data?.JustificativaDescontinuado,
    Deflator: data?.Deflator,
    DeflationPercentage: data?.DeflationPercentage,
    LabeledFamilyCenterId: data?.IdFamiliaRotuladaCentro,
    MaterialId: data?.IdMaterial,
    MaterialName: data?.NomeMaterial,
    ResponsibleId: data?.IdResponsavel,
    RoundingUnitId: data?.IdUnidadeArredondamento,
    RoundingUnitDescription: data?.DescricaoUnidadeArredondamento,
    StockMaterial: data?.MaterialEstoque,
    Suppliers: data?.Fornecedores.map((item) => ({
      Id: item?.Id,
      DeliveryBreak: item?.QuebraEntrega,
      Rounding: item?.Arredondamento,
      MinimumLot: item?.LoteMinimo,
      MinimumLotErp: item?.LoteMinimoErp,
      ResponsibleForDelivery: item?.ResponsavelEntrega,
      SLA: item?.SLA,
      Leadtime: item?.Leadtime,
      PortToDoorTransitTime: item?.PortToDoorTransitTime,
      LeadtimeErp: item?.LeadtimeErp,
      TransitTime: item?.TransitTime,
      CreateAutomaticLoadComposition: item?.CriarComposicaoCargaAutomatica,
      Name: item?.Nome,
      PurchasingOrganization: item?.OrganizacaoCompras,
      UseAutomaticVolumeAcceptance: item?.UsaAceiteAutomaticoVolume,
      UseAcceptAutomaticOrder: item?.UseAcceptAutomaticOrder,
      Type: item?.Tipo,      
      SupplierId: item?.IdFornecedor,
      RoundingUnitId: item?.IdUnidadeArredondamento,
      TaxCode: item?.CodigoImposto,
      RoundingUnitDescription: item?.DescricaoUnidadeArredondamento,
      BuyersGroup: item?.GrupoCompradores,
      Incoterm: item?.Incoterm,
    })),
    Responsible: data?.Responsavel,
    CreateAutomaticLoadComposition: data?.CriarComposicaoCargaAutomatica,
    Periodicity: data?.Periodicity,
    OnlyTransferOrder: data?.OnlyTransferOrder,
    UseHourlyPlan: data?.UseHourlyPlan
  }

  return _data;
}
