import intl from 'react-intl-universal'
import { perfilPcpUnidadeLogado } from 'services/permissions/getPermissions'

export const filtersStocksDefault = {
  StepsIds: [],
  StatusIds: [],
  SupplierIds: [],
  BusinessUnitIds: [],
  CoordinationIds: [],
  MaterialGroupIds: [],
  FamilyIds: [],
  LabeledFamilyIds: [],
  MaterialNumbers: [],
  StockStatusIds: [],
  DeliveryDateFrom: "",
  DeliveryDateTo: "",
  OutOfStockDateFrom: "",
  OutOfStockDateTo: "",
  BelowMinDateFrom: "",
  BelowMinDateTo: "",
}

export const statusStockOptions = [
  {
    Id: 0,
    Descricao: 'Ok'
  },
  {
    Id: 1,
    Descricao: 'Over'
  },
  {
    Id: 2,
    Descricao: 'Out'
  }
]

const selectPropsDefault = {
  valueKey: 'Id',
  labelKey: 'Descricao',
  value: [],
  options: [],
  searchable: true,
  clearable: true,
  multi: true,
  hidden: false
}

export const dateRangeFiltersInitial = () => ([
  {
    labelDe: intl.get('requestOrders.deliveryDateDE'),
    labelAte: intl.get('requestOrders.deliveryDateAT'),
    dataDe: 'DeliveryDateFrom',
    dataAte: 'DeliveryDateTo',
    noMinDate: true,
  },
  {
    labelDe: intl.get('stocks.sufficiencyDateDE'),
    labelAte: intl.get('stocks.sufficiencyDateAT'),
    dataDe: 'OutOfStockDateFrom',
    dataAte: 'OutOfStockDateTo',
    noMinDate: true,
  },
  {
    labelDe: intl.get('stocks.dateOutDE'),
    labelAte: intl.get('stocks.dateOutAT'),
    dataDe: 'BelowMinDateFrom',
    dataAte: 'BelowMinDateTo',
    noMinDate: true,
  }
])

export const selectsFiltersInitial = () => ([
  {
    ...selectPropsDefault,
    name: 'StockStatusIds',
    label: intl.get('stocks.stockStatus'),
    placeholder: intl.get('stocks.stockStatus')
  },
  {
    ...selectPropsDefault,
    name: 'SupplierIds',
    label: intl.get('commons.source'),
    placeholder: intl.get('commons.source'),
    labelKey: 'Description',
  },
  {
    ...selectPropsDefault,
    name: 'BusinessUnitIds',
    label: intl.get('requestOrders.destiny'),
    placeholder: intl.get('requestOrders.destiny'),
    labelKey: 'Description',
    disabled: perfilPcpUnidadeLogado(),
  
  },
  {
    ...selectPropsDefault,
    name: 'CoordinationIds',
    label: intl.get('master-data.general-configuration.coordination'),
    placeholder: intl.get('master-data.general-configuration.coordination'),
    labelKey: 'Description'
  },
  {
    ...selectPropsDefault,
    name: 'MaterialGroupIds',
    label: intl.get('commons.wallet'),
    placeholder: intl.get('commons.wallet'),
    labelKey: 'Description'
  },
  {
    ...selectPropsDefault,
    name: 'FamilyIds',
    label: intl.get('commons.family'),
    placeholder: intl.get('commons.family'),
    labelKey: 'Name'
  },
  {
    ...selectPropsDefault,
    name: 'LabeledFamilyIds',
    label: intl.get('commons.labeledFamily'),
    placeholder: intl.get('commons.labeledFamily'),
    labelKey: 'Description'
  },
  {
    ...selectPropsDefault,
    name: 'MaterialNumbers',
    label: intl.get('commons.activeMaterial'),
    placeholder: intl.get('commons.activeMaterial'),
    valueKey: 'MaterialNumber',
    labelKey: 'Description'
  }
]);

