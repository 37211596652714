import PropTypes from 'prop-types'
import { ReactMultiEmail } from 'react-multi-email';
import SelectStyled, { RemoveIcon } from './selectStyled'

const InputMultiEmail = ({ labelText, emails, ...rest }) => {
  const getEmailLabel = (email, index, removeEmail) => (
    email &&
    <div className='Select-value' data-tag key={index}>
      <RemoveIcon className='Select-value-icon' data-tag-handle onClick={() => removeEmail(index)}> × </RemoveIcon>
      {email}
    </div>
  )

  return (
    <SelectStyled>
      <div className="Select Select--multi is-searchable has-value">
        {
          labelText &&
          <label className='label-style-input'>{labelText}</label>
        }
        <div className='Select-control' style={{ width: '100%' }}>
          <ReactMultiEmail
            underlineFocusStyle={{ borderColor: 'var(--color-action-default)' }}
            emails={emails ?? []}
            getLabel={(email, index, removeEmail) => getEmailLabel(email, index, removeEmail)}
            {...rest}
          />
        </div>
      </div>
    </SelectStyled>
  )
}

InputMultiEmail.propTypes = {
  labelText: PropTypes.string,
  emails: PropTypes.object,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
}

export default InputMultiEmail
