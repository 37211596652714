
import React from 'react'
import { useDeliveryScheduleConfigurationContext } from '../../context/deliveryScheduleConfigurationContext'
import intl from 'react-intl-universal'
import * as S from './styled'
import moment from 'moment'

export const GetRows = (day, keys) => {
  const { handleChange, data } = useDeliveryScheduleConfigurationContext()

  const values = data.find(x => x.Dia === day)
  const getValueHours = (key) => {
    const value = values.Data.find(x => moment(x.Hora, "HH:mm:ss").format('HH:mm') === key);
    return value ? value.Quantidade : undefined
  }

  const onBlurChange = (value, key) => {
      handleChange(value, key, day)
  }

  const getDefaultValue = (key) => values ? getValueHours(key) : undefined;

  const normalizeNumber = (e) => {
    e.target.value = e.target.value.replace(/[^\d]/g, "");
  }

  return (
    <React.Fragment>
      <S.TableCell>
        <span>{intl.get(`weekdays.${day}`)}</span>
      </S.TableCell >
      {
        keys.map((key, index) => (
          <S.TableCell key={index}>
            <S.TableCellEditable
              data-testid={`input-edit-row-${index}`}
              defaultValue={getDefaultValue(key)}
              className='input'
              placeholder="-"
              onBlur={(e) => onBlurChange(e.target.value, key)}
              onChange={e => normalizeNumber(e)}
            />
          </S.TableCell>
        ))
      }
    </React.Fragment>
  )
}
