import styled from 'styled-components'
import StyledGrid from 'components/styled-grid'

export const Wrapper = styled.div`
  height: 38vh;
  margin-top: 12px;
`

export const Table = styled.table`
  tr, td{
    padding: 14px;
  }

  .content{
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: var(--font-weight-regular);
    font-size: 14px;
    line-height: 14px;
    color: #313131;
  }

  .quantity{
    font-weight: var(--font-weight-semibold);
    font-size: 14px;
    line-height: 14px;
    color: #54A490;
  }
`

export const Flex = styled.div`
  display: flex;
`

export const PartialFullDelivery = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px;
    cursor: pointer;

    background: ${p => p.isSelected ? 'var(--color-action-default)' : 'var(--color-contrast-white)'};
    border: 1px solid var(--color-action-default);
    border-radius: 3px 0px 0px 3px;

    font-style: normal;
    font-weight: var(--font-weight-regular);
    font-size: 14px;
    line-height: 14px;

    display: flex;
    align-items: center;

    color: ${p => p.isSelected ? 'var(--color-contrast-white)' : 'var(--color-action-default)'};
    text-transform: none;
    justify-content: center;
    white-space: nowrap;

`

export const InputWrapper = styled.div`
    top: ${p => p.visible ? '0' : '-99999px'};
    position: ${p => p.visible ? 'relative' : 'absolute'};

  .input-received-quantity{
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: var(--font-weight-regular);
    font-size: 14px;
    line-height: 14px;
    border: 1px solid var(--color-action-default);
    color: var(--color-action-default);
    max-width: 70px;
    height: 20px;
    &:focus {
      outline: none;
    }

  }
    `

export const QuantityReveived = styled.strong`
  font-weight: var(--font-weight-semibold);
  font-size: 14px;
  line-height: 14px;
  color: var(--color-action-default);
  display: ${p => p.visible ? 'initial' : 'none'};
`


export const Content = styled(StyledGrid)`
  width: 100%;
  padding: 8px 8px 0 0;
  height: calc(100% - 65px);
  overflow-y: auto;
  overflow-x: hidden;

  .header-row{
    position: sticky;
    top: -9px;
    z-index: 10;
  }
`

