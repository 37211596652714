import Fetch from "utils/fetch";
import { convertToQueryParameters } from "utils/custom-functions";

export const getEmployedCapital = (
  countPage,
  page,
  filters,
  IsFilterDefault,
  orderBy
) => {
  const filtroCapitalEmpregado = {
    OrderBy: orderBy,
    RecordsPerPage: countPage,
    PageNumber: page,
    IsFilterDefault,
    Origem: filters.Origem,
    StatusStock: filters.StatusStock,
  };

  const urlToFetch = filters.query
    ? `/estoque/get-capital-employeds?${convertToQueryParameters(
        filtroCapitalEmpregado
      )}&${filters.query}`
    : `/estoque/get-capital-employeds?${convertToQueryParameters(
        filtroCapitalEmpregado
      )}`;

  return Fetch.get(urlToFetch).then(({ data }) => data);
};

export const pegaItensCriticos = (
  countPage,
  page,
  filters,
  IsFilterDefault,
  orderBy
) => {
  const filtroItensCriticos = {
    OrderBy: orderBy,
    RecordsPerPage: countPage,
    PageNumber: page,
    IsFilterDefault,
    Origem: filters.Origem,
    StatusStock: filters.StatusStock,
    Risco: filters.Risco,
    ExibirItensSemRisco: filters.ItensSemRisco,
    IdUsuarioResponsavel: filters.IdUsuarioResponsavel,
  };

  const urlToFetch = filters.query
    ? `/configuracaoitenscritico/obterConfiguracaoItensCritico-filtros?${convertToQueryParameters(
        filtroItensCriticos
      )}&${filters.query}`
    : `/configuracaoitenscritico/obterConfiguracaoItensCritico-filtros?${convertToQueryParameters(
        filtroItensCriticos
      )}`;

  return Fetch.get(urlToFetch).then(({data}) => data);
};

export const salvaItemCritico = (itemCritico) => {
  return Fetch.post(
    "/configuracaoitenscritico/update-item-critico",
    itemCritico
  ).then(({ data }) => data);
};

export const pegaJustificativas = () => {
  return Fetch.get("/configuracaoitenscritico/obter-justificativas").then(
    (data) => data
  );
};

export const pegaMotivos = () => {
  return Fetch.get("/configuracaoitenscritico/obter-motivos").then(
    (data) => data
  );
};

//Utils do serviço
export const convertToArrayQuery = (array, props) => {
  let str = [];
  for (let i = 0; i < array.length; i++) {
    if (array[i] !== undefined) {
      let j = 0;
      for (let num of array[i]) {
        if (num !== undefined) {
          str.push(`${props[i]}[${j}]=${num}`);
        }
        j++;
      }
    }
  }
  if (str.length > 0) {
    return str.join("&");
  }
};

export const getConfiguredColumn = () => {
  return JSON.parse(localStorage.getItem("columnConfiguration"));
};

export const getConfiguredColumnItensCriticos = () => {
  return JSON.parse(localStorage.getItem("columnConfigurationItensCriticos"));
};
