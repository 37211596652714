import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ProgressBar from './progress-bar'
import Indicator from './indicator'
import StyledCounter from './styledCounter.js'

class Counter extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { value, total, type, legend } = this.props;
    const percent = (value * 100) / total;

    return (
      <StyledCounter>
        <div className={`description-type-taks ${type}`}>{legend}</div>
        <ProgressBar value={percent} type={type} legend={legend} />
        <Indicator value={value} total={total} type={type} legend={legend} />
      </StyledCounter>
    )
  }
}

Counter.propTypes = {
  value: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  legend: PropTypes.string,
  type: PropTypes.oneOf([
    'Default',
    'Small',
  ]),
}

Counter.defaultProps = {
  value: 0,
  total: 0,
  type: 'Default',
  legend: '',
}


export default Counter
