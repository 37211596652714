import styled from "styled-components";
import PropTypes from 'prop-types'

const LabelButtonStyled = styled.div`
  display: flex;
  justify-content: end;
  color: var(--color-action-default);
  cursor: pointer;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  ${p => p.disabled && `pointer-events: none; color: #cacaca;`}

  span {
      position: relative;

      &:hover{
          text-decoration: none;
      }

      &::after{
          border-radius: 1em;
          border-top: .1em solid #2F56B0;
          content: "";
          position: absolute;
          right: 100%;
          bottom: .14em;
          left: 0;
          transition: right .4s cubic-bezier(0,.5,0,1);
      }

      &:hover::after{
          right: 0;
      }
  }
`;

export const LabelButton = ({ children, ...rest }) => (
  <LabelButtonStyled {...rest}>
    <span>{children}</span>
  </LabelButtonStyled>
)

LabelButton.propTypes = {
  children: PropTypes.any
}