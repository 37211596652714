import { SelectBox } from "components/uikit-adapter"
import intl from 'react-intl-universal'

const optionsQuantity = [
  { value: 10 },
  { value: 20 },
  { value: 30 },
]

export const LinesPerPageFilter = ({ ...rest }) => {
  return (
    <div style={{ display: 'flex', height: '38px' }}>
      <SelectBox
        options={optionsQuantity}
        valueKey="value"
        labelKey="value"
        width={'100px'}
        {...rest}
      />
      <span className="text-lines-per-page">{intl.get('commons.linesPerPage')}</span>
    </div>
  )
}