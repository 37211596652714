import React, { Component } from 'react'
import PropTypes  from 'prop-types'
import Dialog from 'material-ui/Dialog'
import intl from 'react-intl-universal'

import { Button } from 'components/uikit-adapter/index'

import './modal-message-dialog.css'

class ModalMessageDialog extends Component {
  render() {
    return (
      <Dialog
        title={this.props.title}
        contentStyle={{ width: '400px' }}
        open={this.props.open}
        onRequestClose={this.props.handleClose}
        actionsContainerClassName="container-action-buttons modal-action-height"
        actions={
          [
            <Button
              value={intl.get('geral.buttonsDefault.cancel')}
              type='default'
              onClick={() => { this.props.handleClose() }}
              className="button"
            />,
            <Button
              value={this.props.principalButtonMessage}
              type='danger'
              onClick={() => { this.props.handleConfirm() }}
              className="button"
            />
          ]
        } >
        <div className='container-message'>
          {this.props.warningMessage}
        </div>
      </Dialog>
    )
  }
}

ModalMessageDialog.propTypes = {
  title: PropTypes.string.isRequired,
  principalButtonMessage: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  warningMessage: PropTypes.string.isRequired
}

export default ModalMessageDialog
