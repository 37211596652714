import React from 'react'
import {
  Table as T,
  TableBody,
  TableHeader,
  TableRow,
  TableRowColumn
} from 'material-ui/Table'
import intl from 'react-intl-universal'
import './style.css'
import moment from 'moment'
import PropTypes from 'prop-types'

const Table = ({ data }) => {

  return (
    <T
      height='900px'
      wrapperStyle={{ height: '600px' }}
      bodyStyle={{ height: 'calc(100% - 60px)' }}
      fixedHeader
      selectable={false}
      multiSelectable={false}
    >
      <TableHeader
        displaySelectAll={false}
        adjustForCheckbox={false}
        enableSelectAll={false}
      >
        <TableRow>
          <TableRowColumn title={intl.get('commons.plant')} className='headerColumn' style={{ textAlign: 'center', width: '5%', padding: '3px' }} >
            {intl.get('commons.plant')}
          </TableRowColumn>
          <TableRowColumn title={intl.get('commons.supplierUnit')} className='headerColumn' style={{ textAlign: 'center', width: '10%', padding: '3px' }} >
            {intl.get('commons.supplierUnit')}
          </TableRowColumn>
          <TableRowColumn title={intl.get('commons.materialCode')} className='headerColumn' style={{ textAlign: 'center', width: '8%', padding: '3px' }} >
            {intl.get('commons.materialCode')}
          </TableRowColumn>
          <TableRowColumn title={intl.get('commons.description')} className='headerColumn' style={{ textAlign: 'center', width: '22%', padding: '3px' }} >
            {intl.get('commons.description')}
          </TableRowColumn>
          <TableRowColumn title={`${intl.get('commons.contractNumber')} - ${intl.get('commons.contractItemNumber')}`} className='headerColumn' style={{ textAlign: 'center', width: '12%', padding: '3px' }} >
            {intl.get('commons.contractNumber')} - {intl.get('commons.contractItemNumber')}
          </TableRowColumn>
          <TableRowColumn title={intl.get('commons.amountPrevision')} className='headerColumn' style={{ textAlign: 'center', width: '8%', padding: '3px' }} >
            {intl.get('commons.amountPrevision')}
          </TableRowColumn>
          <TableRowColumn title={intl.get('commons.expirationDate')} className='headerColumn' style={{ textAlign: 'center', width: '8%', padding: '3px' }} >
            {intl.get('commons.expirationDate')}
          </TableRowColumn>
          <TableRowColumn title={intl.get('commons.supplier')} className='headerColumn' style={{ textAlign: 'center', width: '25%', padding: '3px' }} >
            {intl.get('commons.supplier')}
          </TableRowColumn>
        </TableRow>
      </TableHeader>
      <TableBody
        displayRowCheckbox={false}
        deselectOnClickaway={false}
        showRowHover
        stripedRows={false}>
        {
          data.length ?
            data.map((row, index) => (
              <TableRow key={index}>
                <TableRowColumn className='tableRow__infos' style={{ textAlign: 'center', width: '5%' }} title={row.Planta}>{row.Planta}</TableRowColumn>
                <TableRowColumn className='tableRow__infos' style={{ textAlign: 'center', width: '10%' }} title={row.Apelido}>{row.Apelido}</TableRowColumn>
                <TableRowColumn className='tableRow__infos' style={{ textAlign: 'center', width: '8%' }} title={parseInt(row.NumeroMaterial)}>{parseInt(row.NumeroMaterial)}</TableRowColumn>
                <TableRowColumn className='tableRow__infos' style={{ textAlign: 'center', width: '22%' }} title={row.CodigoMaterialAmbev}>{row.CodigoMaterialAmbev}</TableRowColumn>
                <TableRowColumn className='tableRow__infos' style={{ textAlign: 'center', width: '12%' }}
                  title={`${parseInt(row.NumeroContrato)} - ${row.NumeroItemContrato}`}>{`${parseInt(row.NumeroContrato)} - ${row.NumeroItemContrato}`}</TableRowColumn>
                <TableRowColumn className='tableRow__infos' style={{ textAlign: 'center', width: '8%' }} title={row.QuantidadePrevista}>{row.QuantidadePrevista}</TableRowColumn>
                <TableRowColumn className='tableRow__infos' style={{ textAlign: 'center', width: '8%' }} title={moment(row.FimValidade).format('L')}>{moment(row.FimValidade).format('L')}</TableRowColumn>
                <TableRowColumn className='tableRow__infos' style={{ textAlign: 'center', width: '25%' }}
                  title={`${parseInt(row.CodigoFornecedor)} - ${row.RazaoSocial} - ${row.Cnpj}`}>{`${parseInt(row.CodigoFornecedor)} - ${row.RazaoSocial} - ${row.Cnpj}`}</TableRowColumn>
              </TableRow>
            ))
            : <div className='no-records-message'>{intl.get('commons.noRecordsFound')}</div>
        }
      </TableBody>
    </T>
  )
}

Table.propTypes = {
  data: PropTypes.array
}

export default Table
