export const CountryList = [
  {
    name: 'None',
    value: 0,
    localeCode: 'en-US',
    currencyCode: 'USD'
  },
  {
    name: 'BR',
    value: 1,
    localeCode: 'pt-BR',
    currencyCode: 'BRL'
  },
  {
    name: 'US',
    value: 2,
    localeCode: 'en-US',
    currencyCode: 'USD'
  },
  {
    name: 'ZA',
    value: 3,
    localeCode: 'en-ZA',
    currencyCode: 'ZAR'
  },
  {
    name: 'BO',
    value: 4,
    localeCode: 'es-BO',
    currencyCode: 'BOB'
  },
  {
    name: 'AR',
    value: 5,
    localeCode: 'es-AR',
    currencyCode: 'ARS'
  },
  {
    name: 'CH',
    value: 6,
    localeCode: 'es-CL',
    currencyCode: 'CLP'
  },
  {
    name: 'PY',
    value: 7,
    localeCode: 'es-PY',
    currencyCode: 'PYG'
  },
  {
    name: 'UY',
    value: 8,
    localeCode: 'es-UY',
    currencyCode: 'UYU'
  },
  {
    name: 'CA',
    value: 9,
    localeCode: 'en-CA',
    currencyCode: 'CAD'
  },
];