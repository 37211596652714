const IconSaveBlue = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width={ "32px" }
      height={ "24px" }
      style={{
        enableBackground: "new 0 0 49 49",
      }}
      viewBox="0 0 49 49"
    >
      <g fill="var(--color-action-default)">
        <path
          d="M39.914 0H.5v49h48V8.586L39.914 0zM35.5 2v14h-24V2h24zm-26 45V28h29v19h-29zm37 0h-6V26h-33v21h-5V2h7v16h28V2h1.586L46.5 9.414V47z"
          data-old_color="#7A2323"
          data-original="#000000"
        />
        <path
          d="M13.5 33h7a1 1 0 1 0 0-2h-7a1 1 0 1 0 0 2zM23.5 35h-10a1 1 0 1 0 0 2h10a1 1 0 1 0 0-2zM25.79 35.29c-.181.189-.29.45-.29.71s.109.52.29.71c.189.18.439.29.71.29.26 0 .52-.11.71-.29.18-.19.29-.45.29-.71s-.11-.521-.29-.71c-.37-.37-1.05-.37-1.42 0zM33.5 4h-6v10h6V4zm-2 8h-2V6h2v6z"
          data-old_color="#7A2323"
          data-original="#000000"
        />
      </g>
    </svg>
  )
  export default IconSaveBlue