import PropTypes from "prop-types"
import { useState } from 'react';
import { ClbTableTBodyCell } from '@celebration/design-system-react'
import { getColorCell, getColorSpan, getColorSpanToFinalStock, getColorToFinalStock } from './utils';
import { BottlesViewOrderModal } from 'features/bottles/components/bottles-view-order-modal';
import intl from 'react-intl-universal'

import * as S from './styled'
import { formatNumber } from 'utils/format';

export const MainTableBody = ({ data, labeledFamilyCenterId }) => {
  const [viewOrdersModal, setViewOrdersModal] = useState({
    open: false,
    date: '',
    labeledFamilyCenterId: ''
  })

  return (
    <S.WrapperScrollableTable hideScrollbars={false} className='scroller'>
      {data?.StockProjections?.map(stockProjection => (
        <ClbTableTBodyCell>
          <S.SlotScrollableCell>
            <S.GridWrapper>
              <S.TableCell
                style={{ cursor: 'pointer' }}
                onClick={() => setViewOrdersModal({ open: true, date: stockProjection.Date, labeledFamilyCenterId: labeledFamilyCenterId })}
                background={getColorCell('Entry', stockProjection.Entry)}
                color={getColorSpan('Entry', stockProjection.Entry)}
              >
                <span className='title'>{intl.get(`bottles.keys.Entry`)}:</span>
                <span>{formatNumber(stockProjection.Entry)}</span>
              </S.TableCell>
              <S.TableCell
                background={getColorCell('Consumption', stockProjection.Consumption)}
                color={getColorSpan('Consumption', stockProjection.Consumption)}
              >
                <span className='title'>{intl.get(`bottles.keys.Consumption`)}:</span>
                <span>{formatNumber(stockProjection.Consumption)}</span>
              </S.TableCell>
              <S.TableCell
                background={getColorCell('InitialStock', stockProjection.InitialStock)}
                color={getColorSpan('InitialStock', stockProjection.InitialStock)}
              >
                <span className='title'>{intl.get(`bottles.keys.InitialStock`)}:</span>
                <span>{formatNumber(stockProjection.InitialStock)}</span>
              </S.TableCell>
              <S.TableCell
                background={getColorToFinalStock(stockProjection.FinalStock, data.MinStock, data.MaxStock)}
                color={getColorSpanToFinalStock(stockProjection.FinalStock, data.MinStock, data.MaxStock)}
              >
                <span className='title'>{intl.get(`bottles.keys.FinalStock`)}:</span>
                <span>{formatNumber(stockProjection.FinalStock)}</span>
              </S.TableCell>
            </S.GridWrapper>
          </S.SlotScrollableCell>
        </ClbTableTBodyCell>
      ))}
      <BottlesViewOrderModal
        openModal={viewOrdersModal.open}
        labeledFamilyCenterId={viewOrdersModal.labeledFamilyCenterId}
        date={viewOrdersModal.date}
        handleClose={() => setViewOrdersModal(prevState => ({ ...prevState, open: false }))}
        hideAddOrder
      />
    </S.WrapperScrollableTable>
  )
}

MainTableBody.propTypes = {
  data: PropTypes.shape({
    MaxStock: PropTypes.any,
    MinStock: PropTypes.any,
    StockProjections: PropTypes.shape({
      map: PropTypes.func
    })
  }).isRequired,
  labeledFamilyCenterId: PropTypes.any.isRequired
}