import Fetch from './fetch';
import { getValueOrDefault } from 'utils/custom-functions'

export const getDocumentTypes = () => Fetch.get("/orders:document-types").then((x) => x.data);

export const orderByDescription = (data) => {
  return data.sort(function (a, b) {
    if (a.DocumentTypeEnumDescription > b.DocumentTypeEnumDescription) return 1;
    if (a.DocumentTypeEnumDescription < b.DocumentTypeEnumDescription) return -1;

    return 0;
  });
}

export const getDocumentTypesByBusinessUnityCountry = async (id) => {

  try {
    const businessUnitCountry = await Fetch.get(`/unidadeNegocio/${id}`).then(({ data }) => data.PaisEnumValue)
    const allDocumentTypes = JSON.parse(localStorage.getItem('DocumentTypes'));
    const documentTypesByCountry = allDocumentTypes.filter((item) => item.Country === businessUnitCountry);
    const documentTypes = orderByDescription(documentTypesByCountry);
    return documentTypes;

  } catch (error) {
    console.error(error)
  }
}

export const getDefaultDocumentTypeByOrderType = (documentTypes, orderType) => {
  if(documentTypes && orderType){
    return getValueOrDefault(documentTypes.find((y) => y.Default && y.OrderType === orderType), { DocumentTypeEnumValue: null }).DocumentTypeEnumValue
  }
  return null;
}

export const getDocumentTypeByOrderType = (documentTypes, orderType) => {
  if(documentTypes && orderType){
    return documentTypes.filter((y) => y.OrderType === orderType)
  }
  return null;
}
