import * as S from '../../styled';
import { NavbarLogo } from '../navbar-logo';
import { NavbarMenu } from '../navbar-menu';
import { NavbarUserActions } from '../navbar-user-actions';

export const Navbar = () => (
  <S.Navbar id='navbar-wrapper'>
    <S.Main>
      <NavbarLogo />
      <NavbarMenu />
      <NavbarUserActions />
    </S.Main >
  </S.Navbar >
)

export default Navbar;
