import PropTypes from "prop-types"
import React from 'react'
import intl from 'react-intl-universal'
import * as S from '../bottles-tabs/styled'

export const BottlesInputGhostSupplier = ({ onKeyUp, close }) => {
  const handleNewTab = (event) => {
    onKeyUp({ ...event, simulado: true })
  }

  return (
    <React.Fragment>
      <S.NewTabWrapper>
        <S.NewTabInput
          onKeyUp={handleNewTab}
          placeholder={intl.get("commons.supplier")}
        />
      </S.NewTabWrapper>
      <S.ExitNewTabInput onClick={close}>X</S.ExitNewTabInput>
    </React.Fragment>
  )
}

BottlesInputGhostSupplier.propTypes = {
  close: PropTypes.any.isRequired,
  onKeyUp: PropTypes.func.isRequired
}

