import { CountryList } from "models/usuarios/country"

export const getLocaleInfosByCountry = (country) => {
  return CountryList.find(x => x.value === country) ?? CountryList[0];
}

export const getCurrencySymbolByCountry = (country) => {
  const localeInfosCountry = getLocaleInfosByCountry(country);
  const valueCurrency = new Intl.NumberFormat(localeInfosCountry.localeCode, {
    style: 'currency',
    currency: localeInfosCountry.currencyCode,
  }).formatToParts(10);

  return valueCurrency.find(x => x.type === "currency")?.value ?? '$';
}