import * as permissions from "services/permissions/getPermissions";
import { DialogsUserSettingsOptions } from "features/navbar/utils";
import intl from 'react-intl-universal'
import { usesContingencyRequest, usesTmsIntegration } from "utils/validations-general-settings";

export const dataUploads = () => [
  {
    name: "contingencyRequest",
    label: intl.get("commons.contingencyRequest"),
    dialogType: DialogsUserSettingsOptions.MANUAL_ORDER,
    visible: permissions.profileAdminOrMasterData() && usesContingencyRequest(),
  },
  {
    name: "uploadVolumes",
    label: intl.get("commons.uploadVolumes"),
    dialogType: DialogsUserSettingsOptions.IMPORT_EXPORT_ORDERS_VOLUME,
    visible: permissions.userAmbev() && usesTmsIntegration(),
  }
]
