const IconRefresh = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={width ? width : 24}
    height={height ? height : 24}
    style={{
      enableBackground: "new 0 0 344.37 344.37",
    }}
    viewBox="0 0 344.37 344.37"
  >
    <g fill="var(--color-action-default)">
      <path
        d="M334.485 37.463c-6.753-1.449-13.396 2.853-14.842 9.603l-9.084 42.391c-28.922-49.34-82.008-80.302-140.191-80.302-89.603 0-162.5 72.896-162.5 162.5 0 6.903 5.596 12.5 12.5 12.5 6.903 0 12.5-5.597 12.5-12.5 0-75.818 61.682-137.5 137.5-137.5 49.429 0 94.515 26.403 118.925 68.443l-41.674-8.931c-6.752-1.447-13.396 2.854-14.841 9.604-1.446 6.75 2.854 13.396 9.604 14.842l71.536 15.33a12.48 12.48 0 0 0 9.409-1.728 12.501 12.501 0 0 0 5.433-7.875l15.331-71.536c1.444-6.749-2.856-13.393-9.606-14.841zM321.907 155.271c-6.899.228-12.309 6.006-12.081 12.905 1.212 36.708-11.942 71.689-37.042 98.504-25.099 26.812-59.137 42.248-95.844 43.46-1.53.05-3.052.075-4.576.075-47.896-.002-92.018-24.877-116.936-65.18l43.447 11.65c6.668 1.787 13.523-2.168 15.311-8.837 1.788-6.668-2.168-13.522-8.836-15.312L34.686 213.59a12.497 12.497 0 0 0-15.311 8.836L.43 293.092c-1.788 6.668 2.168 13.522 8.836 15.311 1.085.291 2.173.431 3.245.431 5.518 0 10.569-3.684 12.066-9.267l10.649-39.717c29.624 46.647 81.189 75.367 137.132 75.365 1.797 0 3.604-.029 5.408-.089 43.381-1.434 83.608-19.674 113.271-51.362s45.209-73.031 43.776-116.413c-.227-6.898-6.008-12.325-12.906-12.08z"
        data-old_color="#000000"
        data-original="#000000"
      />
    </g>
  </svg>
)
export default IconRefresh;