import styled from 'styled-components'

export const HeaderContainer = styled.div`
    background-color: var(--color-neutral-background);
    display: flex;
    height: 100px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`

export const Container = styled.div`
    position: relative;
    height: calc(100% - 100px);
`
