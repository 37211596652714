import styled from 'styled-components'

const Styled = styled.div`
  background-color: #ebecec;
  
  &.cardDivisor {
    width: 90%;
    height: 1px;
  }

  &.divisorContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
`
export default Styled
