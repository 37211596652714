import styled from 'styled-components'

export const SuppliersList = styled.div`
`
export const SuppliersListItem = styled.div`
  padding: 15px;
  cursor: pointer;

  &:hover {
    background-color: #EFF5FE;
  }
`
