export const getValidationForInTransitStatus = (orderStatus) => {
  const orderStatusAfterInTransit = [8, 9, 10, 11, 12, 3]
  return orderStatusAfterInTransit.includes(orderStatus)
}

export const getValidationForWaitingEntranceMerchandise = (orderStatus) => {
  const orderStatusAfterEntranceMerchandise = [9, 10, 11, 12, 3]
  return orderStatusAfterEntranceMerchandise.includes(orderStatus)
}

export const getValidationForTotalItems = (checkedItems, alterableItems) => checkedItems.length !== alterableItems.length


export const getValidationForNegotiationOrder = (order, user) => {
  return order.OrderStatus === 1 ? order.NextStepUserType === user.TipoUsuario : true
}