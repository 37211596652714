import styled from 'styled-components'

 const StyledStatusCard = styled.div`
 
 .status-container {
    width: 100%;    
}

.image-status {    
    color: var(--color-contrast-white);
    width: 49px;
    height: 43px;
    box-shadow: 0.6px 1.9px 4px 0 rgba(108, 108, 238, 0.09);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: -8px;
    top: 4px;
}

.arrow-status {
    width: 0;
    height: 0;
    border: 1em solid;
    font-size: 6px;
    transform: rotate(45deg);
    position: absolute;
    bottom: -5px;
    left: 2px;
}

.insumo-desc {
    display: flex;
    flex-wrap: wrap;
    left: 50px;
    position: relative;
    top: 3px;
    font-style: normal;
}

.insumo-desc span:first-child {
    font-size: 14px;
    font-weight: bold;
    line-height: 150%;
    position: relative;
    width: 100%;
    text-transform: uppercase;
    width: calc(100% - 50px);
}

.insumo-desc span:last-child {
    font-size: 12px;
    font-weight: var(--font-weight-regular);
    line-height: 185%;
    color: var(--color-neutral-600);
    width: 80%;
}
    
`

 export default StyledStatusCard
