import Session from "utils/user-storage";
import { downloadData } from 'pages/master-data/configurations/data-upload/data-upload.service'
import { downloadFile } from 'utils/custom-functions'
import { filtersClearAllFilters, filtersClearChangeMyItemsOnly } from "utils/filters";
import { authProvider } from "providers/authProvider";
import { isUseFilterDefault } from "utils/user";

export const downloadOrderVolumeModel = async () => {
  const url = "/pedidoVolume/download/DownloadModeloPedidoVolume";
  const reportName = "PedidoVolumeModeloImportacao";
  const response = await downloadData(url).then(({ data }) => data);
  downloadFile(`${reportName}.csv`, 'csv', response);
};

export const logout = () => {
  Session.clear()
  filtersClearAllFilters()
  authProvider().logout()
}

export const handleChangeMyItemsOnly = () => {
  filtersClearChangeMyItemsOnly();
  const value = isUseFilterDefault();
  localStorage.setItem("filterMyItemsOnly", JSON.parse(!value));
  window.location.reload();
};
