import styled from "styled-components";

export const npsContainer = styled.div`
  position: fixed;
  font-family: "ProximaNova";
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  overflow: hidden;
  z-index: 20000;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  ::-webkit-scrollbar {
    background-color:#0000;
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background-color:var(--color-contrast-white)
  }
  ::-webkit-scrollbar-track:hover {
    background-color:#f4f4f4
  }

  ::-webkit-scrollbar-thumb:horizontal {
      background-color:#babac0;
      border-radius: 16px;
      border: 3px solid var(--color-contrast-white)
  }

  ::-webkit-scrollbar-thumb:vertical {
      background-color:#babac0;
      border-radius: 16px;
  }

  ::-webkit-scrollbar-thumb:hover {
      background-color:#a0a0a5;
      border: 0px solid #f4f4f4
  }

  ::-webkit-scrollbar-button {display:none}

  > .blockFrame {
    min-width: 500px;
    height: 100%;
    max-height: 666px;
    background: var(--color-contrast-white);
    color: var(--color-contrast-black);
    font-weight: var(--font-weight-regular);
    line-height: 0.8;
    border: 1px solid var(--color-neutral-200);
    box-shadow: 5px 5px 5px #777;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .survey-confirmation {
    .survey-header {
      flex: 1;
      justify-content: center;
    }
    .survey-footer {
      align-items: center;
    }
  }

  .survey-question {
    .survey-header {
      justify-content: space-evenly;
    }
    .survey-body {
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
    }
    .survey-footer {
      justify-content: space-between;
    }
  }

  .survey-score {
    text-align: center;
    padding-bottom: 20px;

    .pie-container {
      height: 262px;
      width: 262px;
      position: relative;
      margin: 0 auto;
      transition: background-color 0.3s ease-in-out;

      .inner-circle {
        position: absolute;
        width: 106px;
        height: 106px;
        background-color: #f8dc89;
        border-radius: 100%;
        top: 78px;
        left: 78px;
        color: var(--color-contrast-white);
        box-sizing: border-box;
        border: 15px var(--color-contrast-white) solid;
        transition: background-image 0.3s ease-in-out;
      }

      .slice {
        position: absolute;
        width: 262px;
        height: 262px;
        border-radius: 100%;
        clip: rect(0px, 262px, 87px, 134px);

        &:nth-child(11) {
          clip: rect(0px, 196px, 85px, 135px) !important;
        }

        .slice-inner {
          position: absolute;
          height: 262px;
          width: 262px;
          border-radius: 100%;
          clip: rect(0px, 131px, 86px, 0px);
          transform: rotate(29.7deg);
          display: block;
          cursor: pointer;
          transition: background-color 0.3s ease-in-out;

          &.unrated {
            background-color: var(--color-neutral-200) !important;

            &:hover {
              &.ok {
                background-color: #9dc76e !important;
              }
              &.normal {
                background-color: #f9c732 !important;
              }
              &.nok {
                background-color: #de6165 !important;
              }
            }
          }

          &.ok {
            background-color: #9dc76e;
          }

          &.normal {
            background-color: #f9c732;
          }

          &.nok {
            background-color: #de6165;
          }

          .slice-label {
            user-select: none;
            display: inline-block;
            position: relative;
            left: -22px;
            top: 36px;
            font-weight: var(--font-weight-semibold);
            }
          }
        }
      }
    }

  .survey-img-response {
    position: relative;
    width: 250px;
    transform-origin: center bottom;
    animation-name: bringToFront;
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.66, 0.03, 0.51, 1.66);
  }

  .survey-comment {
    padding-top: 10px;
    border: 1px solid rgb(197, 208, 225);
    width: 100%;
    resize: none;
    box-sizing: border-box;
    text-align: center;
  }

  .survey-header {

    h2 {
      margin: 8px;
    }
    
    position: relative;
    display: flex;
    flex: 2;
    flex-direction: column;
    align-items: center;
    padding: 12px;
  }

  .survey-body {
    position: relative;
    padding: 5px 15px;
    justify-content: center;
    display: flex;
    flex: 4;
  }

  .survey-footer {
    padding: 15px 15px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    box-shadow: inset 0px 1px 0px 0px #eee;
  }

  .survey-action {
    padding: 6px;
    width: 130px;
    height: 40px;
    cursor: pointer;
    transition: all 300ms ease-in-out;

    &.close {
      background-color: var(--color-contrast-white);
      border: var(--color-brand-400) 1px solid;
      color: var(--color-brand-400);
    }

    &.confirm {
      background-color: #6f9e32;
      border: var(--color-feedback-positive-600) 1px solid;
      color: var(--color-contrast-white);
    }

    &:hover {
      &.close {
        background-color: #f4f4f4;
      }

      &.confirm {
        background-color: var(--color-feedback-positive-600);
      }
    }

    &:disabled{
      &:hover {
        cursor: auto;
        border: 1px solid #999999;
        background-color: #cccccc;
        color: #666666;
        }
      cursor: auto;
      border: 1px solid #999999;
      background-color: #cccccc;
      color: #666666;
    }
  }

  .feedback-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .feedback-content {

    .text{
      max-width: 70%;
      display: inline-flex;
    }
    position: relative; 
    width: 700px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .feedback-comment {
    border: 1px solid rgb(45, 47, 51);
    width: 70%;
    resize: none;
    align-self: center;
    box-sizing: border-box;
    text-align: center;
  }

  .feedback-header {
    position: relative;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  .feedback-body {
    position: relative;
    padding: 5px 20px;
    justify-content: center;
    display: flex;
    flex: 4;
    width: 70%;
  }

  .feedback-footer-title {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .feedback-footer {
    position: relative;
    text-align: center;
    height: 100px;
  }

  .feedback-action {
    width: 50px;
    height: 50px;
    cursor: pointer;

    &.dislike {
      background-color: var(--color-contrast-white);
      border: none;
      color: #cf1616;
      margin-left: 25px;
    }

    &.like {
      background-color: var(--color-contrast-white);
      border: none;
      color: rgb(39, 150, 44);
      margin-right: 20px;
      margin-left: -20px;
    }

    &:focus{
        outline: thin dotted;
        outline: 0px auto -webkit-focus-ring-color;
        outline-offset: 0px;
      }
      &:hover {
        &.dislike {
          color: #d87575;
        }

        &.like {
          color: rgb(99, 163, 102);
        }
      }
  }

  .categories-button {
    padding: 6px;
    width: 170px;
    height: 40px;
    cursor: pointer;
    transition: all 300ms ease-in-out;
    background-color: #ffff;
    border-radius: 10px;
    color: #de6165;
    border: 2px solid #de6165;
    margin-right: 0pz;
    margin: 4px 8px 10px 0px;

    &:disabled{
      background-color :#FA8486;
      color: #FFFF;
      outline: thin dotted;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: 0px;
    }
    &:hover{
      background-color :#ecb0b1;
      border: 2px solid #de6165;
      }
  }

  .categories-container {
    position: relative;
    align-self: flex-end;
    max-width: 620px;
    padding-bottom: 12px;
    }

    @keyframes bringToFront {
      0% {
        visibility: hidden;
        opacity: 0;
        transform: scale(0.1);
      }
      100% {
        visibility: visible;
        opacity: 1;
        transform: scale(1);
      }
    }
`

export const StyledSliceContainer = styled.div`` 
