import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import intl from "react-intl-universal"
import { SelectBox, Button } from "components/uikit-adapter/index"
import Toggle from "../toggle"
import DeadlineControl from "components/deadline-control"
import { validarFiltros } from "../../utils/validate"
import { normalizaDadosDaLinhaProducaoCentro } from "../../utils/format"
import { ObterLinhaProducaoPorCentro } from "pages/master-data/unit-management/business-unit/business-unit.service"
import { getAllCenters } from "../../../../services/center.service"
import { buscarFornecedores, buscarMateriaisPrimarios, buscarMateriaisPrimariosPorUnidadeNegocio } from "../../primary-material-schedule.service"
import IconFilterWhite from 'images/icn-filter-white.svg'
import * as S from "./styled"

const Filter = ({ error, emitter }) => {
  const [unidadeNegocio, setUnidadeNegocio] = useState(undefined)
  const [linhaProducaoCentro, setLinhaProducaoCentro] = useState([])
  const [linhaProducaoCentroSelecionada, setLinhaProducaoCentroSelecionada] = useState([])
  const [unidadeNegocioSelecionada, setUnidadeNegocioSelecionada] = useState(undefined)
  const [fornecedores, setFornecedores] = useState([])
  const [fornecedoresSelecionados, setFornecedoresSelecionados] = useState([])
  const [materiaisPrimarios, setMateriaisPrimarios] = useState([])
  const [materiaisPrimariosSelecionados, setMateriaisPrimariosSelecionados] = useState([])
  const [included, setIncluded] = useState(false)
  const [datas, setDatas] = useState({
    deliveryDateFrom: '',
    deliveryDateTo: ''
  });

  const inicializaInputs = async () => {
    await carregarUnidadesDeNegocio()
    await carregarFornecedores()
    await carregarMateriaisPrimarios()
  }

  const carregarUnidadesDeNegocio = async () => {
    try {
      const data = await getAllCenters().then(x => x.data)
      setUnidadeNegocio(data)
    }
    catch (e) {
      error(e)
    }
  }

  const carregarLinhaProducaoPorCentro = async (un) => {
    try {
      const data = await ObterLinhaProducaoPorCentro(un.Centro)
      setUnidadeNegocioSelecionada(un)
      setLinhaProducaoCentroSelecionada([])
      setLinhaProducaoCentro(normalizaDadosDaLinhaProducaoCentro(data))
    }
    catch (e) {
      error(e)
    }
  }

  const carregarFornecedores = async () => {
    try {
      const data = await buscarFornecedores()
      setFornecedores(data)
    } catch (e) {
      error(e)
    }
  }

  const onChangeIncluded = () => setIncluded(!included);

  const carregarMateriaisPrimarios = async () => {
    try {
      const data = await buscarMateriaisPrimarios()
      setMateriaisPrimarios(data)
    } catch (e) {
      error(e)
    }
  }

  const mudarUnidadeNegocioSelecionada = planta => {
    carregarLinhaProducaoPorCentro(planta)
  }

  const mudarLinhaProducaoCentro = linhaProducao => {
    setLinhaProducaoCentroSelecionada(linhaProducao)
  }

  const mudarFornecedoresSelecionados = fornecedor => {
    setFornecedoresSelecionados(fornecedor)
  }

  const mudarMateriaisPrimariosSelecionados = materialPrimario => {
    setMateriaisPrimariosSelecionados(materialPrimario)
  }

  const mudarValorDatas = (coleta, entrega) => {
    setDatas({
      deliveryDateFrom: coleta,
      deliveryDateTo: entrega
    })
  }

  const filtrar = async () => {

    const filtros = {
      plant: unidadeNegocioSelecionada,
      productionLine: linhaProducaoCentroSelecionada,
      datas,
      vendor: fornecedoresSelecionados,
      material: materiaisPrimariosSelecionados,
      included: included
    }

    const validador = validarFiltros(filtros)

    const materials = validador === "" ? await buscarMateriaisPrimariosPorUnidadeNegocio(unidadeNegocioSelecionada?.Id) : ""
    validador === "" ? emitter(filtros, materials) : error(validador)
  }

  useEffect(() => {
    inicializaInputs()
  }, []);

  return (
    <S.FilterWrapper>
      <React.Fragment>
        <S.FirstSectionWrapper>
          <SelectBox
            required
            width="240px"
            name='UnidadeNegocio'
            label={intl.get('commons.plant')}
            placeholder={intl.get('commons.plant')}
            valueKey='Id'
            labelKey='Nome'
            value={unidadeNegocioSelecionada}
            options={unidadeNegocio}
            onChange={value => mudarUnidadeNegocioSelecionada(value)}
            className='plant'
            searchable
          />
          <SelectBox
            width="360px"
            disabled={!unidadeNegocioSelecionada}
            name='ProductionLine'
            label={intl.get('commons.productionLine')}
            placeholder={intl.get('commons.productionLine')}
            valueKey='key'
            labelKey='productionLine'
            value={linhaProducaoCentroSelecionada}
            options={linhaProducaoCentro}
            onChange={value => mudarLinhaProducaoCentro(value)}
            className='productionLine'
            clearable
            multi
          />
          <DeadlineControl
            TituloColeta={intl.get("stocks.from")}
            TituloEntrega={intl.get("stocks.until")}
            showSeparator={false}
            OnChange={(coleta, entrega) => mudarValorDatas(coleta, entrega)}
            Coleta={datas.deliveryDateFrom}
            DataEntregaDe={datas.deliveryDateTo}
            CadeadoDisabled
            className='date'
            noLockOption
            TransitTime={1}
            noMinDate
          />
        </S.FirstSectionWrapper>
        <S.SecondSectionWrapper>
          <SelectBox
            width="400px"
            name='Suppliers'
            label={intl.get('commons.supplier')}
            placeholder={intl.get('commons.supplier')}
            valueKey='Id'
            labelKey='Descricao'
            value={fornecedoresSelecionados}
            options={fornecedores}
            onChange={value => mudarFornecedoresSelecionados(value)}
            className='vendor'
            clearable
            multi
            data-testid='vendorSelectBox'
            searchable
          />
          <SelectBox
            width="240px"
            name='PrimaryMaterials'
            label={intl.get('master-data.general-configuration.material')}
            placeholder={intl.get('master-data.general-configuration.material')}
            valueKey='MaterialCode'
            labelKey='FullDescription'
            value={materiaisPrimariosSelecionados}
            options={materiaisPrimarios}
            onChange={value => mudarMateriaisPrimariosSelecionados(value)}
            className='primaryMaterial'
            clearable
            multi
            searchable
          />
          <S.ButtonSection>
            <Toggle onChangeIncluded={onChangeIncluded} included={included} />
            <div data-testid='btn-run-zapmsl-wrapper'>
              <Button
                type="primary"
                value={intl.get('filters.search')}
                className="button search"
                icon={IconFilterWhite}
                onClick={filtrar}
              />
            </div>
          </S.ButtonSection>
        </S.SecondSectionWrapper>
      </React.Fragment>
    </S.FilterWrapper>
  )
}

Filter.propTypes = {
  emitter: PropTypes.func.isRequired,
  error: PropTypes.func.isRequired
}

export default Filter
