import intl from 'react-intl-universal'
import { ClbSelect } from 'libs/celebration';
import { Switcher } from 'components/toggle';
import { useEffect, useState } from 'react'
import { ClbInputCounter, ClbInputNumber, ClbSelectChangeEvent, ClbMultiSelect, ClbRadioButton, ClbRadioGroup, ClbInputNumberChangeEvent } from '@celebration/design-system-react';
import TitleHeader from 'components/title-header'
import { Col, Row, SubTitle, WrapperAutomaticRoutines, WrapperComponent } from '../../styled';
import { isLoadCompositionFlowBrewerySide, isLoadCompositionFlowSupplierSide } from 'utils/validations-general-settings';
import Feature from 'components/feature';
import { SchedulingAutoPilotGeneralDto } from 'types/labeled-family-center/auto-pilot/scheduling-auto-pilot-general-dto';
import { AutoPilotOrderCutType } from 'enum/labeled-family-center/auto-pilot';
import { GetDoorsByBusinessUnitIdCommandResponse } from 'types/business-unit/get-doors-by-business-unit-id-command-response';
import { getDoorsByBusinessUnitId } from 'services/business-unit';
import { getOptionsDaysOfWeek, orderCutTypeOptions } from './utils';
import { getDoorsOptionsClbByProps } from 'utils/doors';

interface IAutoPilotAutomaticRoutines {
  data: SchedulingAutoPilotGeneralDto,
  setData: React.Dispatch<React.SetStateAction<SchedulingAutoPilotGeneralDto>>,
}

export const AutoPilotAutomaticRoutines = ({ data, setData }: IAutoPilotAutomaticRoutines) => {
  const [optionsDaysOfWeek, setOptionsDaysOfWeek] = useState(getOptionsDaysOfWeek(data))
  const [doors, setDoors] = useState<GetDoorsByBusinessUnitIdCommandResponse[]>([])
  const doorsOptions = getDoorsOptionsClbByProps(doors, "Name", "Id")
  const getDoors = async () => {
    const _doors = await getDoorsByBusinessUnitId(data.IdBusinessUnit);
    setDoors(_doors);
  }

  useEffect(() => {
    if (isLoadCompositionFlowBrewerySide()) {
      getDoors();
    }
  }, [])

  const onChangeDaysOfWeek = (value: string[]) => {
    setOptionsDaysOfWeek(prevState => prevState.map(x => ({
      ...x,
      isSelected: value.includes(x.value)
    })))
    updateValue<boolean[]>('DaysOfWeekEnable', optionsDaysOfWeek.map(x => value.includes(x.value)))
  }

  const updateValue = <TValue,>(field: string, value: TValue) => {
    setData(prevState => ({
      ...prevState,
      [field]: value
    }))
  }

  const updateAutoPilot = <TValue,>(field: string, value: TValue) => {
    setData(prevState => ({
      ...prevState,
      [field]: value,
    }))

    if (data.OverStockManagerIsActive) {
      setData(prevState => ({
        ...prevState,
        OverStockManagerIsActive: false
      }))
    }
  }

  const onChangeOrderCutType = (e: ClbSelectChangeEvent) => {
    if (e.detail.value === AutoPilotOrderCutType.Daily.toString()) {
      onChangeDaysOfWeek(optionsDaysOfWeek.map(x => x.value))
    }
  }

  const onChangeTruckSize = (e: ClbInputNumberChangeEvent) => {
    const re = /^(\d[1-9]+|[1-9]\d+|[1-9])$/;
    let _value = e.detail.value as string;
    if (!re.test(_value))
      _value = '1';
   
    updateValue<number | undefined | null | string>("TruckSize", Number(_value))
  }

  return (
    <WrapperAutomaticRoutines>
      <TitleHeader
        className="headerModal"
        color={"black"}
      >
        {intl.get('master-data.general.auto-pilot.automaticRoutines')}
      </TitleHeader>

      <SubTitle> {intl.get('master-data.configurations.general-settings.header.title')} </SubTitle>
      <Row style={{ marginTop: ".5rem" }}>
        <ClbRadioGroup orientation='line'>
          <ClbRadioButton id="radio-01" name="radio-01" disabled={true} checked={isLoadCompositionFlowSupplierSide()}>
            {intl.get('master-data.configurations.general-settings.content.span01')}
          </ClbRadioButton>

          <ClbRadioButton id="radio-02" name="radio-02" disabled={true} checked={isLoadCompositionFlowBrewerySide()}>
            {intl.get('master-data.configurations.general-settings.content.span02')}
          </ClbRadioButton>
        </ClbRadioGroup>
      </Row>

      <SubTitle> {intl.get('master-data.general.auto-pilot.statusAutomaticPilot')} </SubTitle>
      <Row>
        <Col>
          <label>{intl.get('master-data.general.auto-pilot.enableAutomaticPilot')}</label>
        </Col>
        <Col >
          <Switcher
            label={undefined}
            name="auto-pilot"
            value={data.AutoPilot}
            onChange={(value: any) => updateAutoPilot<boolean>('AutoPilot', value.checked)}
            data-testid="toggle-auto-pilot"
          />
        </Col>
      </Row>

      <Feature validation={isLoadCompositionFlowSupplierSide()}>
        <SubTitle> {intl.get("master-data.general.auto-pilot.orderCutConfiguration")} </SubTitle>
        <Row>
          <Col>
            <label>{intl.get('master-data.general.auto-pilot.overInventoryManagement')}</label>
          </Col>
          <Col>
            <Switcher
              label={undefined}
              name="over-inventory-management"
              value={data.OverStockManagerIsActive}
              onChange={(value: any) => updateValue<boolean>("OverStockManagerIsActive", value.checked)}
              disabled={!data.AutoPilot}
              data-testid='toogle-over-inventory-management'
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>{intl.get('master-data.general.auto-pilot.orderCutOver')}</label>
          </Col>
          <Col>
            <ClbInputCounter
              id="sb-input-counter-test"
              name="Storybook Input Counter component"
              size="sm"
              maxvalue={99}
              value={data.OverStockManagerDaysToSkip}
              disabled={!data.OverStockManagerIsActive}
              data-testid='input-number-overStockManagerDaysToSkip'
              onClbChange={e => updateValue<typeof e.detail.value>("OverStockManagerDaysToSkip", e.detail.value)}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <label>{intl.get('master-data.general.auto-pilot.orderCutOver')}</label>
          </Col>
          <Col>
            <ClbSelect
              id="select-id"
              size="sm"
              data-testid='order-cut-type-select'
              maxHeight={100}
              options={orderCutTypeOptions()}
              disabled={!data.OverStockManagerIsActive}
              onClbChange={onChangeOrderCutType}
              value={data.DaysOfWeekEnable.every(x => x) ? AutoPilotOrderCutType.Daily.toString() : AutoPilotOrderCutType.Weekly.toString()}
            />

            <ClbMultiSelect
              applyButtonLabel={intl.get('geral.buttonsDefault.apply')}
              clearButtonLabel={intl.get('geral.buttonsDefault.clear')}
              maxTagCount={0}
              data-testid='days-of-week-multi-select'
              size='sm'
              data={optionsDaysOfWeek}
              placeholder={intl.get('commons.select')}
              onClbValueChange={e => onChangeDaysOfWeek(e.detail.value)}
              disabled={!data.OverStockManagerIsActive}
              value={optionsDaysOfWeek.filter(x => x.isSelected).map(x => x.value)}
            />
          </Col>
        </Row>

        <SubTitle style={{ marginTop: ".5rem" }}>{intl.get('master-data.general.auto-pilot.arDateAdjustment')}</SubTitle>
        <Row>
          <Col>
            <label>{intl.get('master-data.general.auto-pilot.automaticDeliveryAdjustment')}</label>
          </Col>
          <Col>
            <Switcher
              label={undefined}
              name="automatic-delivery-adjustment"
              value={data.AutomaticDeliveryAdjustment}
              onChange={(value: any) => updateValue<boolean>('AutomaticDeliveryAdjustment', value.checked)}
              data-testid='automatic-delivery-adjustment'
            />
          </Col>
        </Row>
      </Feature>

      <SubTitle> {intl.get('master-data.general.auto-pilot.suggestionGeneration')}</SubTitle>
      <Row>
        <Col>
          <label>{intl.get('master-data.general.auto-pilot.suggestionsFromD-')}</label>
        </Col>
        <Col>
          <ClbInputCounter
            id="sb-input-counter-test"
            name="Storybook Input Counter component"
            data-testid='input-number-numberDaysAdvanceForSuggestionGeneration'
            size="sm"
            maxvalue={99}
            value={data.NumberDaysAdvanceForSuggestionGeneration}
            onClbChange={e => updateValue<typeof e.detail.value>("NumberDaysAdvanceForSuggestionGeneration", e.detail.value)}
          />
        </Col>
      </Row>

      <Feature>
        <SubTitle> {intl.get('commons.loadBuilding')} </SubTitle>
        <Row>
          <Col>
            <label>{intl.get('commons.door')}</label>
          </Col>
          <Col >
            <WrapperComponent>
              <ClbSelect
                size='xl'
                options={doorsOptions}
                data-testid='select-door'
                onClbChange={e => updateValue<number | undefined | null | string>("IdBusinessUnitDoor", Number(e.detail.value) ? Number(e.detail.value) : 0)}
                value={data.IdBusinessUnitDoor?.toString()}
                disabled={doorsOptions.length <= 1}
              />
            </WrapperComponent>
          </Col>
        </Row>
        <Row>
          <Col>
            <label>{intl.get('loadBuildTransportInput.truckSizeLabel')}</label>
          </Col>
          <Col >
            <WrapperComponent>
              <ClbInputNumber
                id="sb-input-number-truck-size"
                name="Input number truck size"
                input-mask="NONE"
                size="lg"
                data-testid='input-number-trucksize'
                value={data.TruckSize?.toString()}
                onClbChange={onChangeTruckSize}
              >
              </ClbInputNumber>
            </WrapperComponent>
          </Col>
        </Row>
      </Feature>
    </WrapperAutomaticRoutines>
  )
};
