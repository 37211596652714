import Dialog from 'material-ui/Dialog'
import { Button } from 'components/uikit-adapter/index'
import intl from 'react-intl-universal';
import PropTypes from "prop-types";
import React from 'react';

import StyledModalCompleteTask from './modal-complete-task.styled';

class ModalCompleteTask extends React.Component {
    render() {
        return (
            <Dialog
                className="dialog-modal"
                open={this.props.open}
                title={intl.get('tasks.completeTaskQuestion')}
                onRequestClose={this.props.onRequestClose}
            >
                <StyledModalCompleteTask>
                    <Button
                        className="modal-button"
                        type="primary"
                        value={intl.get("geral.modalConfirm.no")}
                        onClick={this.props.onRequestClose}
                    />
                    <Button
                        className="modal-button"
                        value={intl.get("geral.modalConfirm.yes")}
                        onClick={this.props.onCompleteTask}
                    />
                </StyledModalCompleteTask>
            </Dialog>
        )
    }
}

ModalCompleteTask.propTypes = {
    taskId: PropTypes.number.isRequired,
    onCompleteTask: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
}

export default ModalCompleteTask;