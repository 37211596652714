import styled from 'styled-components'

const StyledCardHeader = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  height: 45px;

  &.Card-header {
    margin-top: 10px;
    padding: 0 10px 0 50px;
  }

  &.imageStatus {
    color: var(--color-contrast-white);
    width: 49px;
    height: 43px;
    box-shadow: 0.6px 1.9px 4px 0 rgba(108,108,238,0.09);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: -8px;
    top: 4px;
    background-color: ${props => props.backgroundColor};

    img {
      max-width: 25px;
    }
  }

  .arrowStatus {
    width: 0;
    height: 0;
    border: 1em solid;
    font-size: 6px;
    transform: rotate(45deg);
    position: absolute;
    bottom: -5px;
    left: 2px;
    border-color: transparent transparent var(--color-brand-500) transparent;
  }

  .headerDesc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    top: 3px;
    width: 100%;
  }

  .contextMenu {
    margin-left: auto;
    display: flex;
    align-items: center;
    padding-right: 10px;
  }

  .cardTitle {
    font-size: 11px;
    font-weight: bold;
    position: relative;
    width: 100%;
    text-transform: uppercase;
    color: ${props => props.titleColor};
    margin-bottom: 2px;
  }

  .cardSubTitle {
    font-size: 14px;
    font-weight: var(--font-weight-regular);
    line-height: 1.07;
    color: #393939;
  }

  .notificationIcon-container {
    width: 60px;
    height: 60px;
    position: absolute;
    right: 0;
    top: -10px;
    overflow: hidden;

    .notificationIcon {
      width: 130px;
      height: 100%;
      transform: rotate(45deg) translate(-15px, -7px);
      box-shadow: 0 0 5px 0px #868788;
      background-color: var(--color-neutral-200);

      .ico {
        max-width: 25px;
        transform: rotate(-45deg) translate(8px, 52px);
      }
    }
  }

`
export default StyledCardHeader
