import styled from "styled-components"

export const Wrapper = styled.div`
  position: absolute;
  left: 26px;
  height: 45px;
  display: flex;
  align-items: center;
`

export const Logo = styled.img`
  height: 40%;
`

export const Separator = styled.div`
  height: 64%;
  border: solid 1px var(--color-contrast-white);
  margin: 0px 8px 0px 12px;
`

export const LogoOntime = styled.img`
    height: inherit;
`