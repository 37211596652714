import Fetch from "utils/fetch";
import { generateQueryParams } from "../../utils/custom-functions";

export const fetchSuggestions = (params) => Fetch.get(`/suggestions:cards-paginated?${generateQueryParams(params)}`)
  .then(({ data }) => data);

export const fetchStatusSuggestion = () => (
  Fetch.get('/suggestions/status').then(({ data }) => data)
)

export const searchJustificationsRequestInOver = () => (
  Fetch.get(`/sugestoes/listaJustificativasPedidoEmOver`).then(({ data }) => data)
)
