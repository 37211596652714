import { Component } from 'react'
import PropTypes from 'prop-types'
import Fetch from 'utils/fetch'
import Dialog from 'material-ui/Dialog'
import { Button, Input, SelectBox, Toggle } from 'components/uikit-adapter/index'
import Loading from 'components/center-loading'
import intl from 'react-intl-universal'

import './material-form.css'
import { formatErrorMessage } from 'utils/handle-error';
import { usesReturnableAssets } from 'utils/validations-general-settings'
import { generateQueryParams } from 'utils/custom-functions'

class MaterialForm extends Component {
  constructor() {
    super()

    this.state = {
      isFetching: false,
      material: {
        Insumo: {},
        Carteira: {},
        Familia: {},
        FamiliaRotulada: {},
        UnidadeArredondamento: {},
        AtivoGiro: false
      },
      materials: [],
      budgets: [],
      families: [],
      rotuladedFamily: [],
      unidadesArredondamento: [],
      units: []
    }
  }

  async componentDidMount() {
    const editMode = this.props.editMode
    if (editMode) {
      await this.searchMaterialById()
      this.loadFamilies(this.state.material.Carteira.Id)
      this.loadRotuladedFamilies(this.state.material.Familia.Id)
    } else {
      this.searchAllMaterials()
    }

    this.loadUnidadesArredondamento()
    this.searchAllBudgets()
  }

  searchAllMaterials = () => {
    this.startFetching()
    Fetch.get(`/materials:disabled`)
      .then((response) =>
        this.setState({ materials: response.data }))
      .catch((e) => this.proccessErro(e))
      .finally(() => this.stopFetching())
  }

  searchMaterialById = () => {
    return new Promise((resolve, reject) => {
      const idMaterial = this.props.idEdit

      this.startFetching()
      Fetch.get(`/material/detail/${idMaterial}`)
        .then((response) => {
          this.proccessData(response.data, resolve)
        })
        .catch((e) => this.proccessErro(e))
        .finally(() => this.stopFetching())
    })
  }

  proccessData = (data, callback) => {
    const { Id, Numero, Nome, IdCarteira, NomeCarteira, IdFamilia, NomeFamilia,
      IdFamiliaRotulada, NomeFamiliaRotulada, IdUnidadeArredondamento, NomeUnidadeArredondamento, AtivoGiro } = data
    this.setState({
      material: {
        Id: Id,
        Insumo: {
          Numero: Numero,
          Nome: Nome,
        },
        Carteira: {
          Id: IdCarteira,
          Descricao: NomeCarteira
        },
        Familia: {
          Id: IdFamilia,
          Nome: NomeFamilia
        },
        FamiliaRotulada: {
          Id: IdFamiliaRotulada,
          Descricao: NomeFamiliaRotulada
        },
        UnidadeArredondamento: {
          Id: IdUnidadeArredondamento,
          Descricao: NomeUnidadeArredondamento
        },
        AtivoGiro: AtivoGiro
      }
    }, () => {
      if (callback) callback()
    })
  }

  searchAllBudgets = () => {
    this.startFetching()
    Fetch.get(`/material-groups:all`)
      .then((response) => this.setState({ budgets: response.data }))
      .catch((e) => this.proccessErro(e))
      .finally(() => this.stopFetching())
  }

  loadFamilies = (id) => {
    this.startFetching()
    Fetch.get(`/families:by-material-group?MaterialGroupId=${id}`)
      .then((response) => this.setState({ families: response.data }))
      .catch((e) => this.proccessErro(e))
      .finally(() => this.stopFetching())
  }

  loadRotuladedFamilies = (id) => {
    this.startFetching()
    Fetch.get(`/labeled-families:by-family?${generateQueryParams({ familyId: id })}`)
      .then((response) => this.setState({ rotuladedFamily: response.data }))
      .catch((e) => this.proccessErro(e))
      .finally(() => this.stopFetching())
  }

  loadUnidadesArredondamento = () => {
    this.startFetching()
    Fetch.get(`/unidadeArredondamento/obterTodasUnidadesArredondamento`)
      .then((response) => this.setState({ unidadesArredondamento: response.data }))
      .catch((e) => this.proccessErro(e))
      .finally(() => this.stopFetching())
  }

  updateAtivoGiro = value => {
    this.setState(prevState => ({
      material: {
        ...prevState.material,
        AtivoGiro: value
      }
    }))
  }

  updateMaterialSelectValue = (prop, value) => {
    this.setState(prevState => ({
      material: {
        ...prevState.material,
        Id: value.Id,
        Insumo: {
          ...prevState.material.Insumo,
          Numero: value.Number
        },
        UnidadeArredondamento: {
          Id: value.RoundingUnitId
        }
      }
    }))
  }

  updateBudgetSelectValue = (prop, value) => {
    this.setState(prevState => ({
      material: {
        ...prevState.material,
        Carteira: {
          ...prevState.material.Carteira,
          Id: value
        },
        Familia: {
          ...prevState.material.Familia,
          Id: '',
          Nome: ''
        },
        FamiliaRotulada: {
          ...prevState.material.FamiliaRotulada,
          Id: '',
          FamiliaRotulada: ''
        }
      }
    }), () => this.loadFamilies(this.state.material.Carteira.Id))
  }

  updateFamilySelectValue = (prop, value) => {
    this.setState(prevState => ({
      material: {
        ...prevState.material,
        Familia: {
          ...prevState.material.Familia,
          Id: value
        },
        FamiliaRotulada: {
          ...prevState.material.FamiliaRotulada,
          Id: '',
          FamiliaRotulada: ''
        }
      }
    }), () => this.loadRotuladedFamilies(this.state.material.Familia.Id))
  }

  updateRotuladedFamilySelectValue = (prop, value) => {
    this.setState(prevState => ({
      material: {
        ...prevState.material,
        FamiliaRotulada: {
          ...prevState.material.FamiliaRotulada,
          Id: value
        }
      }
    }))
  }

  updateUnidadeArredondamentoSelectValue = (value) => {
    this.setState(prevState => ({
      material: {
        ...prevState.material,
        UnidadeArredondamento: {
          ...prevState.material.UnidadeArredondamento,
          Id: value
        }
      }
    }))
  }

  save = () => {
    const { editMode } = this.props
    const { material } = this.state
    const { Numero } = material.Insumo

    if (this.validateFields()) {
      const IdFamiliaRotulada = material.FamiliaRotulada.Id
      const IdUnidadeArredondamento = material.UnidadeArredondamento.Id
      const AtivoGiro = material.AtivoGiro

      const materialSave = {
        MaterialNumber: Numero,
        LabeledFamilyId: IdFamiliaRotulada,
        RoundingUnitId: IdUnidadeArredondamento,
        ReturnableAssets: AtivoGiro
      }

      this.startFetching();

      Fetch.patch('/materials', materialSave)
        .then(this.props.handleRefresh)
        .then(() => this.props.handleFeedback(`${intl.get('feedbacks.register')} ${!editMode ? intl.get('feedbacks.saved') : intl.get('feedbacks.updated')} ${intl.get('feedbacks.withSuccess')}`))
        .catch((e) => this.proccessErro(e))
        .finally(() => this.stopFetching())
    }
  }

  validateFields = () => {
    let isValid = true
    const { material } = this.state

    if (!material.Id) {
      this.props.handleFeedback(intl.get('feedbacks.messageMaterialForm1'))
      isValid = false
    }
    else if (!material.Carteira.Id) {
      this.props.handleFeedback(intl.get('feedbacks.messageMaterialForm2'))
      isValid = false
    }
    else if (!material.Familia.Id) {
      this.props.handleFeedback(intl.get('feedbacks.messageMaterialForm3'))
      isValid = false
    }
    else if (!material.FamiliaRotulada.Id) {
      this.props.handleFeedback(intl.get('feedbacks.messageMaterialForm4'))
      isValid = false
    }
    else if (!material.UnidadeArredondamento.Id) {
      this.props.handleFeedback(intl.get('feedbacks.messageMaterialForm5'))
      isValid = false
    }

    return isValid
  }

  proccessErro = (exception) => {
    try {
      const erro = formatErrorMessage(exception)
      this.props.handleFeedback(erro)
    } catch (e) {
      this.props.handleFeedback(intl.get('feedbacks.serverError'))
    }
  }

  startFetching = () => {
    this.setState({
      isFetching: true
    })
  }

  stopFetching = () => {
    this.setState({
      isFetching: false
    })
  }


  render() {
    const { budgets, families, rotuladedFamily, materials, material, unidadesArredondamento, isFetching } = this.state
    const { open, editMode, profileAdminOrMasterData } = this.props

    return (
      <div>
        <Loading isLoading={isFetching} />
        <Dialog
          title={editMode ? intl.get('master-data.materials.actions.editMaterial') : intl.get('master-data.materials.actions.ableMaterial')}
          contentStyle={{ width: '600px', transform: 'translate(0px, 30px)' }}
          open={open}
          autoScrollBodyContent
        >
          {editMode &&
            <Input
              disabled
              label={intl.get('commons.material')}
              name='Material'
              value={`${parseInt(material.Insumo.Numero)} - ${material.Insumo.Nome}`}
              className="input"
            />
          }
          {
            !editMode &&
            <SelectBox
              required
              name='Material'
              label={intl.get('commons.material')}
              placeholder={intl.get('commons.material')}
              valueKey='Id'
              labelKey='MaterialSearch'
              value={material.Id}
              options={materials}
              onChange={value => this.updateMaterialSelectValue('Material', value)}
              disabled={!profileAdminOrMasterData}
              searchable
              openOnFocus
            />
          }
          <SelectBox
            required
            name='Carteira'
            label={intl.get('commons.wallet')}
            placeholder={intl.get('commons.wallet')}
            valueKey='Id'
            labelKey='Description'
            value={material.Carteira.Id}
            options={budgets}
            onChange={value => this.updateBudgetSelectValue('Carteira', value.Id)}
            disabled={!profileAdminOrMasterData}
            searchable
            openOnFocus
          />
          <SelectBox
            required
            name='Familia'
            label={intl.get('commons.family')}
            placeholder={intl.get('commons.family')}
            valueKey='Id'
            labelKey='Name'
            value={material.Familia.Id}
            options={families}
            onChange={value => this.updateFamilySelectValue('Familia', value.Id)}
            disabled={!profileAdminOrMasterData}
            searchable
            openOnFocus
          />
          <SelectBox
            required
            name='FamiliaRotulada'
            label={intl.get('commons.labeledFamily')}
            placeholder={intl.get('commons.labeledFamily')}
            valueKey='Id'
            labelKey='Description'
            value={material.FamiliaRotulada.Id}
            options={rotuladedFamily}
            onChange={value => this.updateRotuladedFamilySelectValue('FamiliaRotulada', value.Id)}
            disabled={!profileAdminOrMasterData}
            searchable
            openOnFocus
          />
          <div className='selectBoxVisionMaterial'>
            <SelectBox
              required
              name='UnidadeArredondamento'
              label={intl.get('master-data.general.table.actions.editProvider.roundedUnity')}
              placeholder={intl.get('master-data.general.table.actions.editProvider.roundedUnity')}
              valueKey='Id'
              labelKey='Descricao'
              value={material.UnidadeArredondamento != undefined ? material.UnidadeArredondamento.Id : null}
              options={unidadesArredondamento}
              onChange={value => this.updateUnidadeArredondamentoSelectValue(value.Id)}
              disabled={!profileAdminOrMasterData}
              searchable
              openOnFocus
            />
          </div>
          {usesReturnableAssets() &&
            <div style={{ width: '99%' }}>
              <label className="label-style">{intl.get('master-data.general-configuration.generalSettings')}</label>
              <div className="container__general-form" style={{ display: 'flex', alignItems: 'center', minHeight: '30px' }}>
                <span style={{ whiteSpace: 'nowrap' }}>
                  <label className="title-ativo-giro">{intl.get('master-data.general.table.actions.editProvider.ativoGiro')}</label>
                </span>
                <Toggle
                  name="ativo-giro"
                  value={material.AtivoGiro}
                  onChange={value => this.updateAtivoGiro(value.checked)}
                  thumbSwitchedStyle={{ backgroundColor: 'var(--color-action-default)' }}
                  trackSwitchedStyle={{ backgroundColor: 'var(--color-neutral-200)' }}
                />
              </div>
            </div>
          }
          <div className='footer-dialog-buttons-material'>
            <Button
              type="default"
              value={intl.get('geral.buttonsDefault.cancel')}
              onClick={() => { this.props.handleClose() }}
              className="button"
            />
            {profileAdminOrMasterData &&
              <Button
                type="primary"
                value={intl.get('geral.buttonsDefault.save')}
                className="button"
                onClick={this.save}
              />
            }
          </div>
        </Dialog>
      </div>
    )
  }
}

MaterialForm.propTypes = {
  editMode: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleFeedback: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  idEdit: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  profileAdminOrMasterData: PropTypes.func.isRequired
}

export default MaterialForm
