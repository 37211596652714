import PropTypes from "prop-types"
import {
  Input as InputExterno
} from "@hbsis.uikit/react";
import TimeField from 'react-simple-timefield';

const Input = ({ mask = '', onChange, value, ...rest }) => {
  if (mask === "time") {
    return (
      <TimeField
        onChange={onChange}
        value={value}
        input={<InputExterno {...rest} />}
      />
    )
  }
  else {
    return (
      <InputExterno
        onChange={onChange}
        value={value}
        {...rest}
      />
    )
  }
}

Input.propTypes = {
  mask: PropTypes.string.isRequired,
  onChange: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

export { Input }
