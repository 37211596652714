import React, { useState } from 'react'
import intl from 'react-intl-universal'
import ImportExport from 'components/import-export'
import { Button } from 'components/uikit-adapter/index'
import { downloadFile } from 'utils/custom-functions'
import { formatErrorMessage } from 'utils/handle-error'
import { useGlobalContext } from 'hooks/useGlobalContext'
import { getOrderDownloadFile } from 'components/cards-obsolete/orders-vision.service'
import { GlobalProvider } from '../../../../context/globalContext'
import moment from 'moment'
import { NegotiationIcon } from 'components/icons/icn-index.icon'

const OrdersReviewRequest = () => {
  const { showFeedback } = useGlobalContext()
  const [open, setOpen] = useState(false)

  const downloadOrders = async () => {
    try {
      const data = await getOrderDownloadFile()
      const datafile = moment(new Date()).format('YYYYMMDD_HHmmss')
      downloadFile(`${datafile}_${intl.get("orders.reportNameOrderNegotiation")}.csv`, 'csv', data)
    }
    catch (err) {
      showFeedback(formatErrorMessage(err))
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <GlobalProvider>
      <div title={intl.get("orders.importOrderNegociation")}>
        <Button
          type="default"
          buttonIcon={<NegotiationIcon />}
          onClick={() => setOpen(true)}
          className='btn-import-export-order'
        />
      </div>
      <ImportExport
        title={intl.get('orders.importOrderNegociation')}
        contextModal="massTrading"
        open={open}
        handleClose={() => handleClose()}
        downloadArquivo={() => downloadOrders()}
        importArquivo="orders:import-order-negotiation"
        type='csv'
        noMessageExample
      />
    </GlobalProvider>
  )
}

export default OrdersReviewRequest
