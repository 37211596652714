import styled from 'styled-components'

const GradeContentStyled = styled.div`  

  width: 100%;

  .footer-dialog-buttons {
    margin-top: 5px;
    display: flex;
    justify-content: flex-end;
  }

  .btn-save-grade {
    width: 200px;
  }

  .head-buttons-filter {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .head-buttons-icon {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .head-buttons-filter > div {
    width: 100%;
  }
`

export default GradeContentStyled