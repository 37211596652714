export const firstSection = [
  {name:"Descrição Risco Item Crítico", translation: 'Risk Item Description' },
  {name: "Nome Usuário Responsável", translation: 'Specialist'},
  {name: "Centro", translation: 'Centers'},
  {name: "Descrição Material", translation: 'Material'},
  {name: "Estoque Disponível", translation: 'Available Stock'},
  {name: "Estoque Bloqueado", translation: 'Blocked Stock'},
  {name: "PE Mínimo", translation: 'MIN S.P.'},
  {name: "PE Máximo", translation: 'MAX S.P.'},
  {name: "Status Estoque", translation: 'Status Stock'}
]
