const attributeInList = (attr, list) => {
  return (
    list.filter(item => item.attribute && item.attribute === attr).length > 0
  );
};

const changeDirectionAttribute = direction => {
  if (direction === null) return "asc";
  if (direction === "asc") return "desc";
  if (direction === "desc") return null;
};

class SortList {
  list = [];
  constructor(list = []) {
    this.list = list;
  }

  load(attribute) {

    //atributo ja existe na lista?
    const alreadyInList = attributeInList(attribute, this.list);
    if (alreadyInList) {
      //atualiza

      //pega o index do attr
      const index = this.list.findIndex((item, index) => {
        return item.attribute === attribute;
      });

      const item = this.list[index];
      const newDirection = changeDirectionAttribute(item.direction); //descobre nova direção de sort
      item.direction = newDirection;

      this.list.splice(index, 1, item); //remove index da lista e add novo
    } else {
      //adiciona
      this.list = [
        ...this.list,
        {
          attribute,
          direction: "asc"
        }
      ];
    }
  }

  fields() {
    let fields = {};

    this.list
      .filter(item => item.direction !== null)
      .forEach(({ attribute, direction }) => {
        fields = {
          ...fields,
          [attribute]: direction
        };
      });

    return fields;
  }

  getValidOrders() {
    const _list = this.list.map(({ attribute, direction }) =>
      direction !== null ? `${attribute} ${direction}` : null
    );
    return _list.filter(x => x !== null);
  }

  hasOrders() {
    const list = this.getValidOrders();
    return list.length > 0;
  }

  clear() {
    this.list = [];
  }

  toUrl() {
    const list = this.getValidOrders();
    return list.join(",");
  }
}

export default SortList;
