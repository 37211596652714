import React, { Component } from 'react'
import SnackBar from 'material-ui/Snackbar'
import PersonIcon from 'material-ui/svg-icons/social/person'
import Fetch from 'utils/fetch'
import Filtro from 'components/filters/filter-user'
import Message from 'components/message'
import Loading from 'components/center-loading'
import HeaderIcon from 'components/header-icon'
import ButtonNewItem from 'components/button-new-item'
import UserList from './user-list'
import UserForm from './user-form'
import StorePersist from 'utils/store-persist'
import IconEmpty from 'images/iconEmpty.icon'
import { Button, Layout } from 'components/uikit-adapter/index'
import intl from 'react-intl-universal'
import { convertToQueryParameters } from 'utils/custom-functions'

import './user.css'
import { profileAdminOrMasterData } from "services/permissions/getPermissions";
import { IconFilterActive } from 'components/icons/icn-filter-active.icon'
import { IconFilterInactive } from 'components/icons/icn-filter-inactive.icon'

class User extends Component {
  constructor() {
    super()

    this.state = {
      isFetching: false,
      Users: [],
      total: 0,
      currentPage: 0,
      qtRegsPerPage: 50,
      openForm: false,
      openEditMode: false,
      idSelected: 0,
      showFilter: false,
      filters: {
        UserId: null,
        Email: null,
        WalletId: null,
        CoordinationId: null,
        UserType: null,
        UserAccessProfile: null,
        OnlyDisabledUsers: null
      },
      showMsg: false,
      messageFeedBack: '',
      showFeedback: false,
      textMsg: '',
    }
  }

  UNSAFE_componentWillMount() {
    const filters = StorePersist.getValuesJSON('filtersUsers')

    if (filters) {
      this.setState({ filters: filters })
    }
  }

  showFeedback = (message) => {
    this.setState({
      showFeedback: true,
      messageFeedBack: message
    })
  }

  closeFeedback = () => {
    this.setState({
      showFeedback: false
    })
  }

  openForm = () => {
    this.setState({
      openForm: true
    })
  }

  openNewForm = () => {
    this.setState({
      openEditMode: false
    }, () => this.openForm())
  }

  openEditForm = (row) => {
    const { Users } = this.state;
    const selected = Users[row]

    this.setState({
      openEditMode: true,
      idSelected: selected.Id
    }, () => this.openForm())
  }

  closeForm = () => {
    this.setState({
      openForm: false
    })
  }

  startFetching = () => {
    this.setState({
      isFetching: true
    })
  }

  stopFetching = () => {
    this.setState({
      isFetching: false
    })
  }

  proccessErro = (ex) => {
    this.showFeedback(ex.response.data.Message)
  }

  refreshSearch = () => {
    this.closeForm()
    this.searchUser(this.state.currentPage)
  }

  searchUser = async (page) => {
    this.startFetching()

    const filters = this.state.filters;
    const size = this.state.qtRegsPerPage;
    const nextPage = page == undefined ? this.state.currentPage : page;
    const filter = {
      ...filters,
      Email: filters.Email?.Email,
      _page: nextPage,
      _size: size,
    };

    await Fetch.get(`/users:except-supplier-paginated?${convertToQueryParameters(filter)}`)
      .then((response) => this.proccessData(response.data))
      .then(() => this.setState({ currentPage: nextPage }))
      .then(this.stopFetching)
      .catch((e) => this.proccessErro(e))
  }

  proccessData = (data) => {
    this.setState({
      Users: data.Data,
      total: data.TotalItems
    })
  }

  showFilter = () => { this.setState(prevState => ({ showFilter: !prevState.showFilter })) }

  verifyFilter = () => {
    return this.state.filters.UserId > 0
      || this.state.filters.Email?.Email
      || this.state.filters.WalletId > 0
      || this.state.filters.CoordinationId > 0
      || this.state.filters.UserType
      || this.state.filters.UserAccessProfile
      || this.state.filters.OnlyDisabledUsers
  }

  searchFilters = (filters) => {
    StorePersist.setValuesJSON('filtersUsers', filters);
    this.showFilter()
    this.setState({
      currentPage: 0,
      filters
    }, () => { this.searchUser(this.state.currentPage) })
  }

  render() {
    let {
      openForm,
      openEditMode,
      idSelected,
      Users,
      total,
      showFeedback,
      messageFeedBack,
      isFetching,
      showMsg,
      textMsg,
      showFilter,
      filters
    } = this.state

    return (
      <div className='supplier-list-container'>
        <Loading isLoading={isFetching} />

        <div className="user-header">
          <HeaderIcon
            title={intl.get('master-data.coordinationsAndUsers.users.header.title')}
            subtitle={intl.get('master-data.coordinationsAndUsers.users.header.subtitle')}
            icon={<PersonIcon style={{ width: 45, height: 45 }} color='var(--color-brand-500)' />}
          >
            {profileAdminOrMasterData() &&

              <div title={intl.get('master-data.coordinationsAndUsers.users.actions.newUser')}>
                <ButtonNewItem
                  className="user-button"
                  onClick={this.openNewForm}
                />
              </div>
            }
            <div title={intl.get('master-data.coordinationsAndUsers.users.actions.filter')} className='filterContainer'>
              <Button
                onClick={this.showFilter}
                type='default'
                buttonText={intl.get('filters.filter')}
                buttonIcon={this.verifyFilter() ? <IconFilterActive /> : <IconFilterInactive />}
                className={"button-filter"}
              />
            </div>
          </HeaderIcon>
        </div>

        <UserList
          data={Users}
          countData={total}
          refreshSearch={this.searchUser}
          handleEdit={this.openEditForm}
          qtRegsPerPage={this.state.qtRegsPerPage}
        />

        {openForm &&
          <UserForm
            idEdit={idSelected}
            open={openForm}
            editMode={openEditMode}
            handleClose={this.closeForm}
            handleRefresh={this.refreshSearch}
            handleFeedback={this.showFeedback}
            profileAdminOrMasterData={profileAdminOrMasterData()}
          />
        }

        <SnackBar
          message={messageFeedBack}
          open={showFeedback}
          autoHideDuration={3000}
          onRequestClose={this.closeFeedback}
        />

        <Message
          show={showMsg}
          text={textMsg}
          title='Erro!'
          handleClose={() => this.setState({ showMsg: false })}
        />

        <Layout.Sidebar width="450px"
          visible={showFilter}
          triggerClose={this.showFilter}
          background="var(--color-contrast-white)"
          offsetTop="96px"
          side="right"
          block
          float
          icon={<IconEmpty />}
        >
          <Filtro
            initialData={filters}
            handleClose={this.searchFilters}
            showFilter={showFilter}
          />
        </Layout.Sidebar>
      </div>
    )
  }
}

export default User
