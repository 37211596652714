import { Component } from 'react'
import SnackBar from 'material-ui/Snackbar'
import WalletIcon from 'material-ui/svg-icons/action/account-balance-wallet'
import Fetch from 'utils/fetch'
import DeleteBudget from 'components/delete-dialog'
import Loading from 'components/center-loading'
import HeaderIcon from 'components/header-icon'
import BudgetList from './list'
import BudgetForm from './form'
import ButtonNewItem from 'components/button-new-item'
import intl from 'react-intl-universal'
import { formatErrorMessage } from 'utils/handle-error';
import { profileAdminOrMasterData } from "services/permissions/getPermissions";
import './budget.css'
import { generatePaginatedQuery } from 'utils/custom-functions'

class Budget extends Component {
  constructor() {
    super()

    this.state = {
      isFetching: false,
      budgets: [],
      total: 0,
      openDelete: false,
      currentPage: 0,
      openForm: false,
      openEditMode: false,
      idSelected: 0,
      showFeedback: false,
      messageFeedBack: '',
      qtRegsPerPage: 100
    }
  }

  showFeedback = (message) => {
    this.setState({
      showFeedback: true,
      messageFeedBack: message
    })
  }

  closeFeedback = () => {
    this.setState({
      showFeedback: false
    })
  }

  openForm = () => {
    this.setState({
      openForm: true
    })
  }

  openNewForm = () => {
    this.setState({
      openEditMode: false
    }, () => this.openForm())
  }

  openEditForm = (row) => {
    const { budgets } = this.state;
    const selected = budgets[row]

    this.setState({
      openEditMode: true,
      idSelected: selected.Id
    }, () => this.openForm())
  }

  closeForm = () => {
    this.setState({
      openForm: false
    })
  }

  openDelete = (id) => {
    this.setState({
      openDelete: true,
      idSelected: id
    })
  }

  startFetching = () => {
    this.setState({
      isFetching: true
    })
  }

  stopFetching = () => {
    this.setState({
      isFetching: false
    })
  }

  proccessErro = (exception) => {
    this.stopFetching()
    this.showFeedback(formatErrorMessage(exception))
  }

  refreshSearch = () => {
    this.closeForm()
    this.searchBudgets(this.state.currentPage)
  }

  searchBudgets = (page) => {
    this.startFetching()

    Fetch.get(`/material-groups:paginated?${generatePaginatedQuery(page, this.state.qtRegsPerPage)}`)
      .then((response) => this.proccessData(response.data))
      .then(this.stopFetching)
      .then(() => this.setState({ currentPage: page }))
      .catch((e) => this.proccessErro(e))
  }

  proccessData = (data) => {
    this.setState({
      budgets: data.Data,
      total: data.TotalItems
    })
  }

  closeDelete = () => {
    this.setState({
      openDelete: false
    })
  }

  deleteBudget = () => {
    const idBudgets = this.state.idSelected

    Fetch.delete(`/carteira/${idBudgets}`)
      .then(this.closeDelete)
      .then(() => this.searchBudgets(this.state.currentPage))
      .then(() => this.showFeedback(intl.get('master-data.walletsAndFamilys.wallets.deleteModalWallet.walletSuccessfullyDeleted')))
      .catch((e) => this.proccessErro(e))
  }

  render() {
    let {
      openForm,
      openEditMode,
      openDelete,
      idSelected,
      budgets,
      total,
      showFeedback,
      messageFeedBack,
      isFetching
    } = this.state

    return (
      <div className='budget-container'>
        <Loading isLoading={isFetching} />

        <div className="budget-header">
          <HeaderIcon
            title={intl.get('master-data.walletsAndFamilys.wallets.header.title')}
            subtitle={intl.get('master-data.walletsAndFamilys.wallets.header.subtitle')}
            icon={<WalletIcon style={{ width: 45, height: 45 }} color='var(--color-brand-500)' />}
          >

            {profileAdminOrMasterData() &&

              <div title={intl.get('master-data.walletsAndFamilys.wallets.actions.newWallet')}>
                <ButtonNewItem
                  className="general-button"
                  onClick={this.openNewForm}
                />
              </div>
            }

          </HeaderIcon>
        </div>
        <BudgetList
          data={budgets}
          countData={total}
          refreshSearch={this.searchBudgets}
          handleEdit={this.openEditForm}
          handleDelete={this.openDelete}
          qtRegsPerPage={this.state.qtRegsPerPage}
          profileAdminOrMasterData={profileAdminOrMasterData()}
        />

        {openForm &&
          <BudgetForm
            idEdit={idSelected}
            open={openForm}
            editMode={openEditMode}
            handleClose={this.closeForm}
            handleRefresh={this.refreshSearch}
            handleFeedback={this.showFeedback}
            profileAdminOrMasterData={profileAdminOrMasterData()}
          />
        }

        <DeleteBudget
          open={openDelete}
          title={intl.get('master-data.walletsAndFamilys.wallets.deleteModalWallet.title')}
          handleClose={this.closeDelete}
          warningMessage={intl.get('master-data.general.materials.description')}
          handleConfirm={this.deleteBudget}
        />

        <SnackBar
          message={messageFeedBack}
          open={showFeedback}
          autoHideDuration={3000}
          onRequestClose={this.closeFeedback}
        />
      </div>
    )
  }
}

export default Budget
